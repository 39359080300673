import React from 'react';
import { useStyles } from '../style';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateOrder, addNewObj, deleteObjInArray } from '../../redux/actions';
import { colors } from '../../../../commonComponents/colors';

export default function Achievements(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const path = 'achievements.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };
  //eslint-disable-next-line
  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };
  //eslint-disable-next-line
  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };

  let { data } = props;

  const handledData = (() => {
    if (!data?.length) {
      return [
        {
          Description: '',
        },
      ];
    }

    // Check if data is Array of Strings
    if (typeof data[0] === 'string') {
      return [
        {
          Description: '',
        },
      ];
    }

    return data;
  })();

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Achievements
          </label>
        </div>
      </Grid>
      <Dnd
        data={handledData}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <div>
            <Text
              value={item?.Description || ''}
              placeholder="Awarded Most Improved Supplier by Dell"
              customclass={classes.input}
              path={`${path}.${index}.Description`}
            />
          </div>
        )}
      />
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more achievement
      </div>
    </>
  );
}
