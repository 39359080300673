export const linkedinReducer = (
  state = {
    linkedinData: {},
    skillsModal: false,
    educationModal: false,
    linkedInLoader: true,
    educationModalMode: 'edit',
    experienceModalMode: 'edit',
    certificationsModalMode: 'edit',
    projectModalMode: 'edit',
    projectModal: false,
    experienceModal: false,
    languagesModal: false,
    certificationsModal: false,
    patentsModal: false,
    summaryModal: false,
    publicationsModal: false,
    aboutModal: false,
    volunteerModal: false,
    volunteerModalMode: 'edit',
    coursesModal: false,
    coursesModalMode: 'edit',
    awardsModal: false,
    awardsModalMode: 'edit',
    testsModal: false,
    testsModalMode: 'edit',
    languagesModalMode: 'edit',
    organizationsModal: false,
    organizationsModalMode: 'edit',
    causesModal: false,
    causesModalMode: 'edit',
    securityCredsModal: false,
    securityCredsModalMode: 'edit',
    speakingModal: false,
    speakingModalMode: 'edit',
    trainingModal: false,
    trainingModalMode: 'edit',
  },
  action,
) => {
  switch (action.type) {
    case 'GET_LINKEDIN_DATA':
      return {
        ...state,
        linkedinData: action.payload,
      };
    case 'UPDATE_LINKEDIN_DATA':
      return {
        ...state,
        linkedinData: action.payload,
      };
    case 'UPDATE_LINKEDIN_LOADER':
      return {
        ...state,
        linkedInLoader: action.payload,
      };
    case 'SKILLS_MODAL':
      return {
        ...state,
        skillsModal: action.payload,
      };
    case 'EDUCATION_MODAL':
      return {
        ...state,
        educationModal: action.payload.open,
        educationModalMode: action.payload.mode,
      };
    case 'TRAINING_MODAL':
      return {
        ...state,
        trainingModal: action.payload.open,
        trainingModalMode: action.payload.mode,
      };
    case 'SECURITY_MODAL':
      return {
        ...state,
        securityCredsModal: action.payload.open,
        securityCredsModalMode: action.payload.mode,
      };
    case 'SPEAKING_MODAL':
      return {
        ...state,
        speakingModal: action.payload.open,
        speakingModalMode: action.payload.mode,
      };
    case 'EXPERIENCE_MODAL':
      return {
        ...state,
        experienceModal: action.payload.open,
        experienceModalMode: action.payload.mode,
      };
    case 'CERTIFICATIONS_MODAL':
      return {
        ...state,
        certificationsModal: action.payload.open,
        certificationsModalMode: action.payload.mode,
      };
    case 'AWARDS_MODAL':
      return {
        ...state,
        awardsModal: action.payload.open,
        awardsModalMode: action.payload.mode,
      };
    case 'TESTS_MODAL':
      return {
        ...state,
        testsModal: action.payload.open,
        testsModalMode: action.payload.mode,
      };
    case 'VOLUNTEER_MODAL':
      return {
        ...state,
        volunteerModal: action.payload.open,
        volunteerModalMode: action.payload.mode,
      };
    case 'COURSES_MODAL':
      return {
        ...state,
        coursesModal: action.payload.open,
        coursesModalMode: action.payload.mode,
      };
    case 'LANGUAGES_MODAL':
      return {
        ...state,
        languagesModal: action.payload.open,
        languagesModalMode: action.payload.mode,
      };
    case 'ORGANIZATIONS_MODAL':
      return {
        ...state,
        organizationsModal: action.payload.open,
        organizationsModalMode: action.payload.mode,
      };
    case 'CAUSES_MODAL':
      return {
        ...state,
        causesModal: action.payload.open,
        causesModalMode: action.payload.mode,
      };
    case 'PATENTS_MODAL':
      return {
        ...state,
        patentsModal: action.payload,
      };
    case 'PUBLICATIONS_MODAL':
      return {
        ...state,
        publicationsModal: action.payload,
      };
    case 'SUMMARY_MODAL':
      return {
        ...state,
        summaryModal: action.payload,
      };
    case 'ABOUT_MODAL':
      return {
        ...state,
        aboutModal: action.payload,
      };
    case 'PROJECT_MODAL':
      return {
        ...state,
        projectModal: action.payload.open,
        projectModalMode: action.payload.mode,
      };
    default:
      return state;
  }
};
