export const tipsList = {
  // Profile
  0: [
    "Use a professional personal email address. Don't use your current company provided email address.",
    "Try to add LinkedIn profile URL to look professional, create account if you don't have one, here is the link https://linkedin.com",
  ],
  // Education
  2: [
    'Start with your highest degree or the degree that is most relevant to the job you are applying for.',
    'Include the name of the institution, the degree title, and the date of graduation.',
  ],
  // Courses
  6: [
    'Start with the name of the course, the institution that provided it, and the date you completed it.',
    'Include details about the skills and knowledge you gained from the course that are relevant to the job you are applying for.',
    'If the course is not well-known, briefly describe its purpose and the skills it focuses on.',
    'Keep it concise and avoid going into too much detail about your coursework, unless it directly relates to the job you are applying for.',
  ],
  // Training
  8: [
    'Start with the name of the training program, the institution that provided it, and the date you completed it.',
    'Include details about the skills and knowledge you gained from the training program that are relevant to the job you are applying for.',
    'If the training program is not well-known, briefly describe the program and the skills it focuses on.',
  ],
  // Achievements
  9: [
    'Start by listing your achievements in bullet points.',
    "Use action verbs to describe your achievements and quantify them wherever possible (e.g., 'increased sales by 50%').",
    'Focus on achievements that are relevant to the job you are applying for, and highlight those that demonstrate your skills and experience in the field.',
  ],
  // Projects
  10: [
    'Start with the name of the project, the organization you worked for, and the date you completed the project.',
    'Include details about your role in the project and the skills you used or developed during the project.',
    "Describe the project's goals, outcomes, and impact, and quantify them wherever possible",
  ],
  // Patents
  11: [
    'Start with the name of the patent, the patent number, and the date it was granted.',
    'Include details about your role in developing the invention and the skills you used or developed during the process.',
    "Describe the invention's purpose, benefits, and impact.",
  ],
  // Publications
  12: [
    'Start with the name of the publication, the publication date, and the title of the article or chapter.',
    'Include details about your role in writing the publication and the skills you used or developed during the writing process.',
    "Describe the publication's purpose, findings, and impact.",
  ],
  // Speaking Engagements
  13: [
    'Start with the name of the event, the date, and the title of the presentation.',
    'Include details about the audience, the topic, and the impact of your presentation.',
    'If you have spoken at multiple events, list them in reverse chronological order.',
  ],
  // Security Credentials
  14: [
    'Start with the name of the certification or credential, the organization that provided it, and the date you obtained it.',
    'Include details about the skills and knowledge you gained from the certification or credential that are relevant to the job you are applying for.',
    'If the certification or credential is not well-known, briefly describe its purpose and the skills it focuses on.',
  ],
  // Military
  15: [
    'Start with the branch of the military you served in, your rank, and your dates of service.',
    'Include details about your duties and accomplishments during your military service.',
    'Highlight any skills or experiences you gained during your military service that are relevant to the job you are applying for',
  ], 
  // Job Search
  16: [
    "Start typing job title you plan to apply and select from the options. If not available in the list, simply type and press enter.",
    "Select Job Type - you can choose multiple.",
    "Based on your preference select location - you can choose multiple.",
    "You can search jobs based on skills they would list in the job listing, don't add too many skills.",
    "Pick geography where you plan to look.",
    "Search radius based on your needs.",
    "You can save the search after you click \"Search Now\" for daily notifications.",
  ],

};
