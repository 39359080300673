import axios from 'axios';
import toastr from 'toastr';
import url from '../../config/endpoint';

export const getJobs = (data, selectedJobTypes) => async (dispatch) => {
  try {
    let newCity = data?.city?.join() || '';
    let skills_search = '';
    if (
      selectedJobTypes?.includes('Onsite') &&
      selectedJobTypes?.includes('Remote')
    ) {
      skills_search =
        data?.country?.join() +
          ',' +
          'remote job' +
          ',' +
          'virtual job' +
          ',' +
          'onsite job' || '';
    } else if (selectedJobTypes?.includes('Remote')) {
      skills_search =
        data?.country?.join() + ',' + 'remote job' + ',' + 'virtual job' || '';
    } else {
      skills_search = data?.country?.join() || '';
    }

    const rad = data?.miles?.value || 100;

    const requestData = {
      skills_search: skills_search,
      location: newCity,
      radius: rad,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/job-finder`,
      requestData,
    );

    if (res?.data?.success) {
      dispatch({
        type: 'ALL_JOBS',
        payload: res?.data?.jobs || [],
      });
    } else {
      dispatch({
        type: 'JOBS_ERROR',
      });
      toastr.error(`Something went wrong`);
    }
  } catch (error) {
    console.log('Error', error);
    dispatch({
      type: 'JOBS_ERROR',
    });
  }
};

export const addSearchHistory = (data, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/job-finder-create`, data);
    if (res?.data?.status === 200) {
      dispatch({
        type: 'ADD_SEARCH_HISTORY',
        payload: data,
      });
      if (cb) {
        cb();
      }
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

export const getSavedSearchs = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/job-finder-search`);

    dispatch({
      type: 'GET_SEARCH_HISTORY',
      payload: res.data.data,
    });
  } catch (error) {
    toastr.error(error.message);
  }
};

export const deleteSearchHistory = (id) => async (dispatch) => {
  try {
    await axios.post(`${url}/job-finder-remove`, { id });
    //
    dispatch({
      type: 'DELETE_SEARCH_HISTORY',
      payload: id,
    });
  } catch (error) {
    toastr.error(error.message);
  }
};

export const updateSearchHistory = (data, setEditState) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/job-finder-update`, data);
    //
    if (res?.data?.status === 200) {
      dispatch({
        type: 'UPDATE_SEARCH_HISTORY',
        payload: data,
      });
      setEditState(false);
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

export const getSkills = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/get-job-search-details`);

    let skills = res.data.response.skillsData;
    let address = `${res.data.response.country}, ${res.data.response.city}`;
    let radius = res.data.response.jobRadius.split(' ')[0];
    let checkedSkills = res.data.response.profileSkills;

    let skillsData = {
      skills,
      address,
      radius,
      checkedSkills,
    };

    dispatch({
      type: 'SKILLS',
      payload: skillsData,
    });
  } catch (error) {
    if (error) {
    }
  }
};

export const SaveJobMatches = (data) => async (dispatch) => {
  dispatch({
    type: 'SUBMIT',
  });
  try {
    const resp = await axios.post(`${url}/job-matcher`, data);
    if (resp.data.status === 200) {
      toastr.success(resp.data.message);
      dispatch({
        type: 'SUBMIT_DONE',
      });
    } else {
      dispatch({
        type: 'SUBMIT_FAILED',
      });
    }
  } catch (error) {
    toastr.error(error);
    dispatch({
      type: 'SUBMIT_FAILED',
    });
    //
  }
};

export const GetJobMatches = () => async (dispatch) => {
  try {
    let getToken = localStorage.getItem('clientAccessToken');

    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }
    const filters =
      'resume.other_info.job_titles,job.CurrentLocation,resume.other_info.other_contact_methods';
    const resp = await axios.get(`${url}/get-latest-scan?filters=${filters}`);
    if (resp.data) {
      if (resp.data?.data) {
        dispatch({
          type: 'USER_LATEST_SCAN_JOB',
          payload: resp.data?.data,
        });
      }
    }
  } catch (error) {
    // toastr.error(error);
  }
};
