import React from 'react';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import { useDispatch } from 'react-redux';
import { styles } from './style';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';
import RichEditor from '../../formBuilder/helper/RichEditor';

function Patents(props) {
  const dispatch = useDispatch();
  const path = 'patents.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };

  let { data } = props;
  function handleStringItems() {
    if (!data?.items?.length) {
      return [
        {
          Description: '',
          Inventors: {
            InventorName: '',
          },
          PatentTitle: '',
        },
      ];
    }

    return data?.items;
  }
  const getData = handleStringItems();

  return (
    <Dnd
      data={getData}
      reorder={(e) => onOrderUpdate(e)}
      additem={_addNewItem}
      removeitem={(index) => _removeItem(index)}
      renderItem={(item, index) => {
        return (
          <div className="" style={{ marginTop: 30 }}>
            <div className="manager-template-row" style={{ marginBottom: 10 }}>
              <Text
                value={item.PatentTitle}
                path={`${path}.${index}.PatentTitle`}
                placeholder="Patent Title"
                customclass={
                  'manager-fName manager-template-text-bold manager-mb-bottom-0'
                }
              />
            </div>
            <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>Inventor Name: </p>
              <Text
                value={item.Inventors?.InventorName}
                path={`${path}.${index}.Inventors.InventorName`}
                placeholder=""
                customclass={'manager-fName'}
              />
            </div>

            <div>
              <strong>Description</strong>
              {/* <Text
                value={item.Description}
                path={`${path}.${index}.Description`}
                placeholder=""
                customclass={'manager-fName'}
                style={{ lineHeight: 1.6 }}
              /> */}
              <RichEditor
                value={item.Description}
                enableInline
                placeHolder="Description"
                path={`${path}.${index}.Description`}
                onBlurField={onBlurField}
                heightMax={true}
              />
            </div>
          </div>
        );
      }}
    />
  );
}

export default Patents;
