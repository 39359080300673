import {
  chonologicalTemplate,
  classicTemplate,
  cleanTemplate,
  entryLevelTemplate,
  executiveTemplate,
  freshGraduateTemplate,
  functionalTemplate,
  managerTemplate,
  midLevelTemplate,
  professionalTempate,
  resumeThumb,
} from '../../../constants/templates';

export const templateList = [
  {
    title: 'Clean',
    image: cleanTemplate,
    index: 0,
  },
  {
    title: 'Classic',
    image: classicTemplate,
    index: 1,
  },
  {
    title: 'Professional',
    image: professionalTempate,
    index: 2,
  },
  // {
  //   id: "resumeThumb",
  //   image: resumeThumb,
  //   title: "resumeThumb",
  // },
  // {
  //   id: "chonological",
  //   title: "CHONOLOGICAL",
  //   image: chonologicalTemplate,
  // },
  // {
  //   id: "entrylevel",
  //   title: "ENTRY LEVEL",
  //   image: entryLevelTemplate,
  // },
  // {
  //   id: "executive",
  //   title: "EXECUTIVE",
  //   image: executiveTemplate,
  // },
  // {
  //   id: "freshgraduate",
  //   title: "FRESH GRADUATE",
  //   image: freshGraduateTemplate,
  // },
  // {
  //   id: "functional",
  //   title: "FUNCTIONAL",
  //   image: functionalTemplate,
  // },
  // {
  //   id: "manager",
  //   title: "MANAGER",
  //   image: managerTemplate,
  // },
  // {
  //   id: "midlevel",
  //   title: "MID LEVEL",
  //   image: midLevelTemplate,
  // },
  // {
  //   id: "essential",
  //   title: "MODERN ESSENTIAL",
  //   image: modernEssentialTemplate,
  // },
  // {
  //   id: "stylish",
  //   title: "MODERN STYLISH",
  //   image: modernStylishTemplate,
  // },
  // {
  //   id: "minimal",
  //   title: "MODERN MINIMAL",
  //   image: modernMinimalTemplate,
  // },
  // {
  //   id: "popular",
  //   title: "MODERN POPULAR",
  //   image: modernPopularTemplate,
  // },
  // {
  //   id: "timeless",
  //   title: "MODERN TIMELESS",
  //   image: modernTimelessTemplate,
  // },
];
