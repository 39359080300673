import React from 'react';
import { Popup } from 'reactjs-popup';
import CancelIcon from '@material-ui/icons/Cancel';
import { styles } from './style';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UPGRADE_PLAN_MODAl } from '../modules/auth/redux/constants';
import { useWindowSize } from '@react-hook/window-size';

export default function UpgradeModal(props) {
  const [toggleModal, setToggleModal] = React.useState(true);
  const [width] = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();

  const onUpgrade = () => {
    dispatch({
      type: UPGRADE_PLAN_MODAl,
      payload: false,
    });
    if (props.cb) props.cb();
    history.push('/account?subscription');
  };
  return (
    <Popup
      open={toggleModal}
      // onClose={this.closeModal}
      contentStyle={{
        width: width < 768 ? '90%' : '50%',
        borderRadius: 10,
        padding: 10,
      }}
      modal
      position="center"
      closeOnDocumentClick={props.disableHide === true ? false : true}
    >
      <div style={{ alignSelf: 'center' }}>
        <>
          <span
            onClick={() => {
              if (props.cb) props.cb();
              setToggleModal(false);
            }}
          >
            {!props.disableHide && <CancelIcon style={styles.closeBtn} />}
          </span>

          <div className="upgrade-plane-main" style={{ minHeight: 200 }}>
            <h1 className="upgrade-heading f-s-23">
              You discovered a Premium feature!
            </h1>
            <h4 className=" f-s-19">
              Upgrading to
              <span className="upgrade-blue-text"> Premium</span> will unlock
              it.
            </h4>
            <Button
              onClick={onUpgrade}
              style={{
                marginTop: 30,
              }}
              color="primary"
              size={'large'}
            >
              Upgrade Now
            </Button>
          </div>
        </>
      </div>
    </Popup>
  );
}
