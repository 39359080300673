const generateUniqueId = (skill) => {
  return `unique_id_${skill.toLowerCase()}`;
};
export const addAdditionalSkills = (
  html,
  newSkills = "",
  fontFamily = "Times New Roman"
) => {
  try {
    if (!newSkills || !html) return null;
    const textStyle = `style="font-size:1.2vw;font-family:${fontFamily}"`;
    const textStyleHeading = `style="text-transform:'uppercase',font-size:1.2vw;font-weight:bold;font-family:${fontFamily}"`;
    const unique_id = generateUniqueId(newSkills);
    const mainDiv = document.createElement("div");
    const mainDivClassName = "additional_skills_main_div";
    mainDiv.className = mainDivClassName;
    mainDiv.innerHTML = html;
    const additional_skills = "additional_skills";
    const additional_skills_node =
      mainDiv?.getElementsByClassName(additional_skills);
    let skill_child = `<span ${textStyle}>${newSkills}</span>`;
    if (additional_skills_node.length) {
      if (mainDiv.getElementsByClassName(unique_id).length) {
        mainDiv.getElementsByClassName(unique_id)[0].remove();
      } else {
        const newSKill = document.createElement("span");
        newSKill.className = unique_id;
        // newSKill.style = { display: "flex" };
        newSKill.innerHTML = `<span style="padding:0px 10px"> | </span>${skill_child}`;
        mainDiv
          .getElementsByClassName(additional_skills)[0]
          .appendChild(newSKill);
      }
    } else {
      const additionSkillsDiv = document.createElement("div");
      skill_child = `<span ${textStyle} class="${unique_id}" >${skill_child}</span>`;
      additionSkillsDiv.innerHTML = `
          <div class="${additional_skills}_main"><pre><b ${textStyleHeading}>ADDITIONAL SKILLS</b></pre> <table class="${additional_skills}">${skill_child}</table></div>`;
      mainDiv.appendChild(additionSkillsDiv);
    }
    return mainDiv.innerHTML;
  } catch (e) {
    return html;
  }
};

export function matchWords(subject, words) {
  var regexMetaphors = /[(){[*+?.\\^$|]/g;

  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].replace(regexMetaphors, "\\$&");
  }

  var regex = new RegExp("\\b(?:" + words.join("|") + ")\\b", "gi");

  return subject.match(regex) || [];
}
