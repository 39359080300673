import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import Popup from 'reactjs-popup';
import uuid from 'react-uuid';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';

import { useWindowSize } from '@react-hook/window-size';
import { updateLinkedinData } from '../redux/actions';

export default function Languages(props) {
  const [width] = useWindowSize();

  const [language, setLanguage] = useState('');
  const [proficiency, setProficiency] = useState('');
  const [languageId, setLanguageId] = useState('');

  const [copied, setCopied] = useState([]);

  const dispatch = useDispatch();
  const linkedinData = props.linkedinData;
  const languagesModal = useSelector(
    (state) => state.linkedinReducer.languagesModal,
  );
  const languagesModalMode = useSelector(
    (state) => state.linkedinReducer.languagesModalMode,
  );
  useEffect(() => {
    if (languagesModalMode === 'new') {
      setLanguage('');
      setProficiency('');
    }
  }, [languagesModalMode]);

  const cb = (v) => {
    dispatch({
      type: 'LANGUAGES_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      toastr.success('Languages Updated');
    } else {
      toastr.error('Error Updating Languages');
    }
  };


  const handleUpdate = () => {
    if (language === '' || proficiency === '') {
      toastr.error('Please fill all the fields');
      return;
    }
    if (languagesModalMode === 'new'){

      const data = {
        id:uuid(),
        Language: language,
        Proficiency: proficiency,
      };
     
      let languageData=[...linkedinData?.foreignLanguage,data];
      dispatch(updateLinkedinData({...linkedinData, foreignLanguage : languageData }, cb));
    }
    else{
      const data = {
        id:languageId,
        Language: language,
        Proficiency: proficiency,
      };
      let languageData=[...linkedinData?.foreignLanguage];

     let allData=languageData?.map((item,index)=>{
        if(item?.id===languageId){
          return data;
        }
        else{
          return item;
        }
      })

      dispatch(updateLinkedinData({...linkedinData,  foreignLanguage : allData }, cb));
    }


    
  };

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };

  return (
    <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
      {linkedinData?.foreignLanguage?.length
        ? linkedinData?.foreignLanguage?.map((item, index) => {
            return (
              <Row key={index} className="mt-2">
                <Col
                  xs={10}
                  lg={11}
                  xl={11}
                  md={11}
                  className="border-bottom p-5"
                >
                  {item?.Language}
                </Col>
                <Col
                  xs={2}
                  lg={1}
                  xl={1}
                  md={1}
                  className="border-bottom p-2 d-flex align-items-center justify-content-center"
                >
                  <span
                    style={styles.copyIcon}
                    onClick={() => {
                      setLanguage(item?.Language);
                      setProficiency(item?.Proficiency);
                      setLanguageId(item?.id);
                      dispatch({
                        type: 'LANGUAGES_MODAL',
                        payload: {
                          open: true,
                          mode: 'edit',
                        },
                      });
                    }}
                  >
                    {props.stepIndex === 1 ? (
                      <CreateIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    ) : (
                      <FileCopyIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    )}
                  </span>
                </Col>
              </Row>
            );
          })
        : 'NO DATA AVAILABLE'}

      <Popup
        open={languagesModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'LANGUAGES_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'LANGUAGES_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5>Languages</h5>

        <Row>
          <Col>
            <Label for="exampleEmail">Language</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(language) ? (
                    <CopyToClipboard
                      text={language}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(language);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Proficiency
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={proficiency}
                onChange={(e) => setProficiency(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(proficiency) ? (
                    <CopyToClipboard
                      text={proficiency}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(proficiency);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={false}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  Save
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};
