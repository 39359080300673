import React from 'react'

export default function DownloadIcon() {
    return (
        <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="19" fill="#E5E5E5" />
            <path d="M210 -201H-132V73H210V-201Z" fill="white" />
            <path d="M14.9 0H7.6H7.5C7.5 0 7.5 0 7.4 0H7.3C7.1 0 7 0.100012 6.9 0.200012L6.5 0.600006L0.300003 6.5C0.200003 6.6 0.1 6.79999 0 6.89999C0 6.99999 0 7.10001 0 7.20001V17.8C0 18.3 0.5 18.8 1 18.8H15C15.5 18.8 16 18.3 16 17.8V1C15.9 0.4 15.4 0 14.9 0ZM14.9 17.9H0.900002V7.39999C1 7.29999 1.1 7.29999 1.1 7.29999H7.9V1H14.9V17.9Z" fill="#4A4A4A" />
            <path d="M11.7 13.3L10.2 14.8L9.9 15.1L9.6 14.8L8.1 13.3C7.9 13.1 7.9 12.9 8.1 12.7C8.3 12.5 8.5 12.5 8.7 12.7L9.5 13.5V9C9.5 8.8 9.7 8.60001 9.9 8.60001C10.1 8.60001 10.3 8.8 10.3 9V13.5L11.1 12.7C11.3 12.5 11.5 12.5 11.7 12.7C11.8 12.9 11.8 13.1 11.7 13.3Z" fill="#4A4A4A" />
            <path d="M13.3 14.5V16.9H6.5V14.5C6.5 14.3 6.7 14.1 6.9 14.1C7.1 14.1 7.3 14.3 7.3 14.5V16.1H12.5V14.5C12.5 14.3 12.7 14.1 12.9 14.1C13.1 14.1 13.3 14.3 13.3 14.5Z" fill="#4A4A4A" />
        </svg>
    )
}
