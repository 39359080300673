export const data = (username, date, mobile, email) => {
  return {
    template: `
    <strong>${date}</strong> <br />
  
    <br />
    <br />
    Dear Hiring Manager:
    <br />
    <br />
    <br />
    
    Upon learning of your search for an experienced, high-performing <strong>{generic job title}</strong> to add to your team, I submit my resume for your review. As an accomplished professional with proven experience in [sales/software development/marketing/customer service/HR ] within the [real estate] industry, I am prepared to significantly contribute to your company’s objectives.
    <br />
    <br />
    <br />
    My background includes [managing multiple escrows, compiling and assessing detailed demographic and market risk data, preparing real estate documents, and facilitating real estate purchases and sales to ensure goal achievement and maximum customer satisfaction. From developing a healthy client base to leading buyers and sellers through real estate transactions, I excel at assessing needs, collaborating with teams, and establishing solid and lasting relationships to propel business success].
    <br />
    <br />
    <br />
    My skills are further highlighted below:
    <br />
    <br />
    <br />
    •[Honing expertise in mortgage and title processes, complex negotiations, and client relations; remaining up-to-date on current real estate market trends and performance.] •[Continually achieving—and often surpassing—sales goals and quotas.] •[Demonstrating a steadfast dedication to providing outstanding client service; generating and sustaining a viable client base and achieving recognition as a top producer. ] •[Excelling at balancing multiple responsibilities within challenging, fast-paced environments while providing top-level time management, problem-solving, and communication skills. ]
    <br />
    <br />
    <br />
    When you go through my resume you will get to know how I have handled different roles to be an ideal candidate for <strong>{generic job title}</strong> . I enjoyed my status as a fast learning and efficient individual and possess the ability to easily create a good working relationship with co-workers and clients.
    <br />
    <br />
    <br />
    Thank you for your time and consideration. Look forward to your reply.
    <br />
  
    Best regards,
    <br />
  
    <strong>${username}</strong>
    <br />
    <strong> ${mobile}</strong>
  
    <br />
    <strong>  ${email}</strong>
  
  `,
  };
};
