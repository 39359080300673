import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardBody, Col } from 'reactstrap';
import { scanHistory, getResumaResults } from '../../commonRedux/scan/action';
import { currentLink } from '../../commonRedux/currentNav/action';
import DashBoard from '../recent-history';
import { Colxx } from '../../commonComponents/customBootstrap';
import DashboardTable from '../recent-history/table';
//eslint-disable-next-line
import { styles } from './style';
import DashBoardDataLoader from '../../commonComponents/dashboardCardLoader';
import RecentScanCard from '../../commonComponents/recentScanCard';
import TableLoader from '../../commonComponents/tableLoader';
import { Polar } from 'react-chartjs-2';
import { colors } from '../../commonComponents/colors';
import VideoModel from '../../commonComponents/videoModel';
import howToIcon from '../../assets/How-to.svg';
import InfoIcon from '../../commonComponents/InfoIcon';
import moment from 'moment';
import TabBar from './tabBar';

class ScanHistory extends Component {
  state = {
    loader: false,
    sliceMostRecent: [],
    improvements: 'N/A',
    modelOpen: false,
    MonthScanCount: [],
    historyData: [],
    TotalMonthScanCount: [],
  };

  getPlan = () => {
    let planName = '';
    let amount = '$0';
    if (!this.props.user?.subscription) {
      return {
        planName: 'FREE',
        amount: '$0',
      };
    } else {
      switch (this.props.user?.subscription_plan) {
        case '35/m':
        case 'resumas_25_plan':
          planName = 'Pro-1';
          amount = '$35';
          break;

        case '75/m':
        case 'pro3':
          planName = 'Pro-3';
          amount = '$75';
          break;

        default:
          if (this.props.user?.pay_type === 'Coupon') {
            planName = 'Gift Subscription';
            amount = '$150';
          } else if (this.props.user?.pay_type === 'msgCoupon') {
            planName = 'Pre-PAID subscription';
            amount = '$75';
          } else {
            planName = '14-Days Trial';
            amount = 'trial';
          }

          break;
      }
      return {
        planName,
        amount,
      };
    }
  };

  UNSAFE_componentWillMount = () => {
    this.props.currentLink(this.props.location.pathname);
    this.setState({ loader: true });
    this.props.scanHistory();
    this.props.getResumaResults();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.historyStauts === 'done') {
      this.setState({ loader: false });
    }
    if (nextProps.historyStauts === 'not done') {
      this.setState({ loader: false });
    }

    const sliceMostRecent = nextProps.data?.sort((a, b) => {
      return new Date(b?.createdAt) - new Date(a?.createdAt);
    });

    if (nextProps.data) {
      this.setState({
        sliceMostRecent: sliceMostRecent?.length
          ? sliceMostRecent.slice(0, 1)
          : [],
        historyData: sliceMostRecent,
      });
    }
  };

  getMaxScore = () => {
    var scores = [];

    if (this.props.data) {
      if (this.props.data.length === 0) {
        return 'N/A';
      } else
        this.props.data.foreach((obj) => {
          scores.push(obj.data.total_score);
        });
      return Math.max(...scores);
    } else {
      return 'N/A';
    }
  };

  getImprovemets = (data) => {
    if (data) {
      const initialScore = data?.data?.initialScore;
      const total_score = data?.data?.total_score;
      if (total_score > initialScore) {
        return `${total_score - initialScore}%`;
      }
    }
    return 'N/A';
  };

  render() {
    let MonthScanCount =
      this.state.historyData.filter(
        (scan) =>
          moment(scan.createdAt).format('MM YY') === moment().format('MM YY'),
      )?.length || 0;
    let TotalMonthScanCount = this.state.historyData?.length || 0;
    const jobAppliedCount = this.props.data
      ? this.props.data.filter((items) => {
          return items?.data?.jobApplied === 'yes';
        })
      : null;

    const InterViewCount = this.props.data
      ? this.props.data.filter((items) => {
          return items?.data?.isInterview === 'yes';
        })
      : null;

    const noResponseCount = this.props.data
      ? this.props.data.filter((items) => {
          return (
            items?.data?.jobApplied === 'yes' &&
            items?.data?.isInterview === 'no'
          );
        })
      : null;

    const RejectsCount = this.props.data
      ? this.props.data.filter((items) => {
          return items.data.rejected === 'yes';
        })
      : null;

    const jobMatchCount = Math.round(Math.random() * 20);

    const data = {
      labels: [
        'Jobs-Applied',
        'Jobs-Interviews',
        'Jobs-No Response',
        'Jobs-Rejects',
      ],
      datasets: [
        {
          label: '# of Votes',
          data: [
            jobAppliedCount ? jobAppliedCount.length : 0,
            InterViewCount ? InterViewCount.length : 0,
            noResponseCount ? noResponseCount.length : 0,
            RejectsCount ? RejectsCount.length : 0,
          ],
          backgroundColor: [
            colors.lightBlue,
            colors.darkYellow,
            colors.lightBrown,
            colors.green,
          ],
          borderWidth: 1,
        },
      ],
    };

    if (this.state.loader) {
      return (
        <>
          <div style={{ background: 'white', borderRadius: 5 }}>
            <DashBoardDataLoader />
          </div>
          <div
            style={{
              background: 'white',
              borderRadius: 5,
              marginTop: 30,
              padding: 16,
            }}
          >
            <RecentScanCard />
          </div>
          <div
            style={{
              background: 'white',
              borderRadius: 5,
              marginTop: 30,
            }}
          >
            <TableLoader />
          </div>
        </>
      );
    }
    let reverseData = this.props.data ? this.props.data : [];
    if (!reverseData.length && this.props.getScanHistoryStatus) {
      return (
        <>
          <div style={{ background: 'white', borderRadius: 5 }}>
            <DashBoardDataLoader />
          </div>
          <div
            style={{
              background: 'white',
              borderRadius: 5,
              marginTop: 30,
              padding: 16,
            }}
          >
            <RecentScanCard />
          </div>
          <div
            style={{
              background: 'white',
              borderRadius: 5,
              marginTop: 30,
            }}
          >
            <TableLoader />
          </div>
        </>
      );
    } else
      return (
        <div className="scan_history_main_container">
          <VideoModel
            open={this.state.modelOpen}
            setOpen={(open) => this.setState({ modelOpen: open })}
            video={this.props.VideoLinks?.DashboardPage}
          />
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              {/* <RightIconButton style={{
                marginLeft: '10px'
              }}
              onClick={() => this.setState({ modelOpen: true })}
            /> */}
              <InfoIcon
                onClick={() => this.setState({ modelOpen: true })}
                text={'How To'}
                icon={'Play'}
                height={18}
                width="70px"
                marginTop={0}
                marginRight={5}
              />
              <span style={styles.h1}>Dashboard</span>
            </div>
            {/* {this.props.user?.subscription === false && <UpgradeModal />} */}

            <Row
              style={{
                overflow: 'hidden',
              }}
            >
              <Col lg="6" className="pb-3">
                <Card
                  style={{
                    ...styles.borderRadius_75,
                    height: '94%',
                    minHeight: 400,
                  }}
                >
                  <CardBody>
                    <Polar
                      data={data}
                      options={{
                        legend: {
                          onClick: (e) => e.stopPropagation(),
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scale: {
                          ticks: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>

              <Colxx>
                <Row>
                  <Colxx lg="6" md="6">
                    <div className={`icon-row-item mb-4 hovi`}>
                      <Card style={styles.borderRadius_75}>
                        <CardBody className="text-center">
                          <h5
                            className="card-text font-weight-semibold mb-0"
                            style={styles.customTextHeight}
                          >
                            Monthly Reviews
                          </h5>
                          <h1
                            style={{ fontSize: 50 }}
                            className=" text-center font-weight-bold"
                          >
                            {MonthScanCount || 'N/A'}
                          </h1>
                        </CardBody>
                      </Card>
                    </div>
                  </Colxx>
                  <Colxx lg="6" md="6">
                    <div className={`icon-row-item mb-4 hovi`}>
                      <Card style={styles.borderRadius_75}>
                        <CardBody className="text-center">
                          <h5
                            className="card-text font-weight-semibold mb-0"
                            style={styles.customTextHeight}
                          >
                            Total Jobs Applied
                          </h5>
                          <h1
                            style={{ fontSize: 50 }}
                            className=" text-center font-weight-bold"
                          >
                            {jobAppliedCount.length || 'N/A'}
                          </h1>
                        </CardBody>
                      </Card>
                    </div>
                  </Colxx>
                  <Colxx lg="6" md="6">
                    <div className={`icon-row-item mb-4 hovi `}>
                      <Card style={{ ...styles.borderRadius_75, marginTop: 3 }}>
                        <CardBody className="text-center">
                          <h5
                            className="card-text font-weight-semibold mb-0"
                            style={styles.customTextHeight}
                          >
                            Total Interviews
                          </h5>
                          <h1
                            style={{ fontSize: 50 }}
                            className=" text-center font-weight-bold"
                          >
                            {InterViewCount.length || 'N/A'}
                          </h1>
                        </CardBody>
                      </Card>
                    </div>
                  </Colxx>
                  <Colxx lg="6" md="6">
                    <div className={`icon-row-item mb-4 hovi`}>
                      <Card style={{ ...styles.borderRadius_75, marginTop: 3 }}>
                        <CardBody className="text-center">
                          <h5
                            className="card-text font-weight-semibold mb-0"
                            style={styles.customTextHeight}
                          >
                            Total Resume Reviews
                          </h5>
                          <h1
                            style={{ fontSize: 50 }}
                            className=" text-center font-weight-bold"
                          >
                            {TotalMonthScanCount || 'N/A'}
                          </h1>
                        </CardBody>
                      </Card>
                    </div>
                  </Colxx>
                </Row>
              </Colxx>
            </Row>
            <div>
              <TabBar
                data={this.props.data}
                sliceMostRecent={this.state.sliceMostRecent}
                reverseData={reverseData}
              />
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = ({ fileScanReducer, authUser }) => {
  return {
    user: authUser.user,
    historyStauts: fileScanReducer.historyStauts,
    getUniqueResumas: fileScanReducer.userResumas,
    VideoLinks: fileScanReducer.videoLinks,
    data: fileScanReducer.historyData,
    updatortwo: fileScanReducer.updatortwo,
    getScanHistoryStatus: fileScanReducer.getScanHistoryStatus,
  };
};

export default connect(mapStateToProps, {
  scanHistory,
  currentLink,
  getResumaResults,
})(ScanHistory);
