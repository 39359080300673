import React, { useRef } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import styles from '../../modules/resumeBuilder/component/Text/text.module.scss';

export default function AutoComplete({
  value,
  handleChange,
  handleSelect,
  placeholder = 'Company Name',
  style = {},
  customClass = '',
  builder = false,
  getResumeBuilderChoice = 'form',
  onBlur,
  focus,
  ...props
}) {
  const inputRef = useRef(null);
  const [addBorder, setAddBorder] = React.useState(false);

  const inputWidth = inputRef?.current?.offsetWidth;
  const customStyles = {
    list: {
      position: 'absolute',
      zIndex: 999,
      backgroundColor: '#FFFFFF',
      maxHeight: 150,
      overflow: 'auto',
      cursor: 'pointer',
      padding: 5,
      border: '1px solid #ccc',
      boxSizing: 'border-box',
      width: inputWidth,
    },
    input: {
      border: '1px solid #ccc',
      marginRight: 0,
      width: '100%',
      height: 33,
      paddingLeft: 10,
    },
  };
  return (
    <PlacesAutocomplete
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        types: ['establishment'],
        componentRestrictions: { country: 'us' },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <div>
            <input
              ref={inputRef}
              style={{
                ...(!builder
                  ? customStyles?.input
                  : {
                    border:
                      getResumeBuilderChoice === 'form'
                        ? '1px solid '
                        : 'none',
                    borderColor: addBorder
                      ? '#4267b2'
                      : 'rgba(0, 0, 0, 0.23)',
                    color: 'black',
                    width: '100%',
                    fontSize: '14px',
                  }),
                ...(focus ? {
                  border:
                    getResumeBuilderChoice === 'form'
                      ? '1px solid '
                      : 'none',
                  borderColor: addBorder
                    ? '#4267b2'
                    : 'rgba(0, 0, 0, 0.23)',
                } : {}),
                ...style,
              }}

              {...getInputProps({
                placeholder: placeholder,
                className: builder
                  ? styles.contentEditableContainer + ' ' + customClass
                  : customClass,
                onClick: () => {
                  setAddBorder(true);
                },
                onBlur: (e) => {
                  setAddBorder(false);
                  onBlur && onBlur(e);
                },
              })}
            />
            <div
              className="autocomplete-dropdown-container"
              style={suggestions?.length ? customStyles?.list : {}}
            >
              {loading && <div>Loading...</div>}

              {suggestions
                ?.sort((a, b) => {
                  return (
                    a?.formattedSuggestion?.mainText?.length -
                    b?.formattedSuggestion?.mainText?.length
                  );
                })
                ?.map((suggestion) => {
                  const className = suggestion?.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = {
                    backgroundColor: suggestion.active ? '#fafafa' : '#FFFFFF',
                    cursor: 'pointer',
                    with: '100%',
                    padding: 5,
                  };
                  const mainText = suggestion?.formattedSuggestion?.mainText;
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{mainText}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
}
