import React, { Component, memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {
  registerUser,
  verifyCouponCodeAction,
  getUserIdData,
  sendVCode,
  verifyUserCode,
  changeEamil,
  verifyEmailOtp,
  sendOtpEmail,
} from '../redux/actions';
import { getSkills } from '../../../commonRedux/jobs/action';
import toastr from 'toastr';
import signupImage from '../../../assets/signup.webp';
import { BeatLoader, ClipLoader } from 'react-spinners';
import { MdCheck, MdClear } from 'react-icons/md';
import { css } from '@emotion/core';
import 'react-phone-number-input/style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { validateEmail } from '../../../utils/emailValidation';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { styles } from '../style';
import useDimenstion from '../useDimension';
import Countdown, { zeroPad } from 'react-countdown';
import Footer from '../../layout/authLayout/Footer';
import axios from 'axios';
import TopBar from '../../layout/authLayout/Topbar';
import './style.css';
import { colors } from '../../../commonComponents/colors';
import OtpInput from 'react-otp-input';
import { Button } from '@material-ui/core';

const renderer = ({ minutes, seconds }) => (
  <span style={{ width: 70 }}>
    {zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
);
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const CodeVerifyCompoenents = memo(
  ({
    type,
    handleVCode,
    handleConfirm,
    resendMail,
    email,
    phone,
    verificationLoading,
  }) => {
    const [vCode, setVCode] = useState('');
    const notMatch = false;
    const [showTimer, setTimer] = useState(false);
    const [countDownTimer, setCountDownTimer] = useState(0);

    const onChange = (e) => {
      setVCode(e.target.value.replace(' ', ''));
      handleVCode(e);
    };

    const setTimerHandler = () => {
      if (showTimer) {
        setTimer(false);

        setCountDownTimer(0);
      } else {
        setTimer(true);
      }
    };

    useEffect(() => {
      setCountDownTimer(Date.now() + 60000);
    }, [showTimer]);
    return (
      <div>
        <label>
          We sent a 4-digit verification code to{' '}
          {type === 'email' ? email : phone} <br />
          This code is valid for 30 minutes.
        </label>
        <div
          className="d-flex"
          style={{
            border: '1px solid #e6e6e6',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 10,
            paddingTop: 1,
            paddingBottom: 1,
            marginBottom: 5,
          }}
        >
          <i
            style={{ fontSize: 18 }}
            className="mdi mdi-account ml-3 icons"
          ></i>
          <input
            style={{
              fontSize: 13,
            }}
            type="number"
            className="form-control border-0 w-100"
            placeholder="Verification Code"
            onChange={onChange}
            value={vCode}
          />
          <button
            onClick={() => handleConfirm()}
            type="button"
            style={{
              backgroundColor: '#4267B2',
              color: 'white',
              border: 'none',
              fontSize: 16,
              width: '40%',
              height: '40px',
              borderRadius: 5,
            }}
          >
            {verificationLoading ? 'Loading...' : 'Verify'}
          </button>
        </div>
        {notMatch ? (
          <div style={styles.textAlignCenter}>Incorrect verification code.</div>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {showTimer ? (
            <Countdown
              onComplete={() => {
                setTimerHandler();
                setCountDownTimer(null);
              }}
              date={countDownTimer}
              renderer={renderer}
            />
          ) : (
            <a
              onClick={() => {
                resendMail();
                setTimerHandler();
              }}
              className="ml-1"
            >
              Resend
            </a>
          )}
        </div>
      </div>
    );
  },
);

const RightImage = ({ signupImage }) => {
  const size = useDimenstion();
  if (size.width < 991 && size.width > 700) {
    return null;
  }
  return (
    <Col
      lg={6}
      className="offset-lg-6 padding-less img order-1"
      style={{
        backgroundImage: `url(${signupImage})`,
        backgroundSize: 'contain',
      }}
    ></Col>
  );
};

class PageCoverSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      repassword: '',
      showPMessage: false,
      showEMessage: false,
      isEmailVerified: false,
      isPhoneVerified: false,
      vCode: '',
      notMatch: false,
      isTerms: false,
      planChoosed: 0,
      width: 0,
      height: 0,
      showMessage: false,
      couponCode: '',
      loading: false,
      invited_by: '',
      verificationLoading: false,
      passwordShowToggle: false,
      confirmPasswordShowToggle: false,
      canSendEmail: true,
      ipData: '',
      otpState: false,
      otpLoader: false,
      otp: '',
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleVCode = (e) => {
    this.setState({
      vCode: e.target.value.replace(' ', ''),
    });
  };

  handleChange(value) {
    if (this.state.selectedValue === value.target.id) {
      this.setState({
        selectedValue: '',
      });
    } else {
      this.setState({ selectedValue: value.target.id });
    }
  }

  handleChangeTerms = () => {
    this.setState({ isTerms: !this.state.isTerms });
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const urlCoupon =
      new URLSearchParams(this.props.location.search).get('coupon') || '';
    const urlCouponWithId =
      new URLSearchParams(this.props.location.search).get('id') || '';
    const firstName =
      new URLSearchParams(this.props.location.search).get('firstName') || '';
    const lastName =
      new URLSearchParams(this.props.location.search).get('lastName') || '';
    const email =
      new URLSearchParams(this.props.location.search).get('email') || '';
    const invited_by =
      new URLSearchParams(this.props.location.search).get('invited_by') || '';

    const subscriber_id =
      new URLSearchParams(this.props.location.search).get('SubscriberID') || '';
    if (subscriber_id !== '') {
      this.props.getUserIdData(subscriber_id, (data) => {
        this.setState({
          firstName: data.firstName,
          lastName: data.lastName,
        });
      });
    }

    if (urlCoupon !== '') {
      this.setState({
        couponCode: urlCoupon,
        showMessage: true,
        firstName: firstName,
        lastName: lastName,
        email: email,
      });
    }
    if (urlCouponWithId !== '') {
      this.setState({
        couponCode: urlCouponWithId,
        showMessage: true,
        firstName: firstName,
        lastName: lastName,
        email: email,
      });
    }
    if (invited_by !== '') {
      this.setState({
        invited_by: invited_by,
      });
    }
    try {
      const userIp = await axios.get(
        'https://www.cloudflare.com/cdn-cgi/trace',
        {
          headers: {},
        },
      );

      this.setState({ ipData: userIp?.data });
    } catch (error) {
      console.log(error);
    }

    if (email !== '') {
      this.setState({
        email: email,
      });
      //   this.handleEmailBlur();
    }
  }

  componentDidUpdate() {
    if ((Object.keys(this.props.user ?? {}) || [])?.length) {
      this.props.history.push('/app');
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handlePhone = (phone) => {
    this.setState({
      phone,
      isPhoneVerified: false,
    });
  };

  handleEmail = (e) => {
    this.setState({
      email: e.target.value,
      isEmailVerified: false,
      canSendEmail: true,
    });
    this.props.changeEamil();
  };

  handleConfirm = () => {
    let vCode = this.state.vCode;
    vCode = parseInt(vCode);
    this.setState({
      verificationLoading: true,
    });
    this.props.verifyUserCode(this.state.email, vCode, () => {
      this.setState({
        verificationLoading: false,
      });
    });
  };

  resendMail = () => {
    if (validateEmail(this.state.email)) {
      this.props.sendVCode('email', this.state.email);
      this.setState({
        showEMessage: true,
        canSendEmail: false,
        otp: '',
      });
    }
  };
  handleOtpSend = async () => {
    try {
      if (validateEmail(this.state.email)) {
        return await sendOtpEmail(this.state.email);
      } else {
        toastr.error('Please enter valid email');
      }
    } catch (error) {
      return error;
    }
  };

  handlePhoneBlur = () => {
    if (this.state.phone && this.state.phone.length) {
      this.props.sendVCode('mobile', this.state.phone);
      this.setState({
        showPMessage: true,
      });
    }
  };

  getValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleCodeBuilder = () => {
    this.setState({
      showMessage: true,
    });
  };

  signUpCallBack = (success) => {
    this.setState({ loading: false });
    // if (success) {
    //   this.props.history.push('/login');
    // }
  };

  handleGotoOtp = async () => {
    if (this.state.firstName === '' || this.state.lastName === '') {
      toastr.error('firstname and lastname can not be empty');
    } else if (!validateEmail(this.state.email)) {
      toastr.error('Email is not valid');
    } else if (this.state.password === '') {
      toastr.success('Password can not be empty');
    } else if (this.state.password.length < 8) {
      toastr.error('Password must be greater than 8 characters');
    } else if (this.state.repassword === '') {
      toastr.error('RE-Password can not be empty');
    } else if (this.state.password !== this.state.repassword) {
      toastr.error('Passwords must be matched');
    } else if (!this.state.isTerms) {
      toastr.error('Please read and agree terms and conditions');
    } else {
      this.setState({
        otpLoader: true,
      });
      const otpRes = await this.handleOtpSend();
      if (otpRes?.status === 200) {
        toastr.success('Verification code sent successfully');
        this.setState({
          otpState: true,
        });
        this.setState({
          otpLoader: false,
        });
      } else {
        toastr.error(otpRes.message);
        this.setState({
          otpLoader: false,
        });
      }
    }
  };

  onUserRegister = async () => {
    try {
      this.setState({
        loading: true,
      });

      const otpRes = await verifyEmailOtp(this.state.email, this.state.otp);

      if (otpRes?.status === 200) {
        let data = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          password: this.state.password,
          isStudent: this.state.selectedValue,
          pay_type: this.props.msgSignup === true ? 'msgSignup' : '',
          mobile: '',
          invited_by: this.state.invited_by,
          userIp: this.state.ipData,
          // mobile: this.state.phone,
        };
        if (this.state.couponCode !== '') {
          data = {
            ...data,
            employeeCoupon: this.state.couponCode,
          };
        }
        this.props.registerUser(data, this.signUpCallBack);
      } else {
        toastr.error(otpRes.message);
        this.signUpCallBack();
      }
    } catch (error) {
      toastr.error(error.message);
      this.signUpCallBack();
    }
  };

  onPlanChoosed = (planChoosed) => {
    if (planChoosed !== this.state.planChoosed) this.setState({ planChoosed });
  };
  verifyCode = () => {
    if (this.state.couponCode === '') {
      toastr.error('Please enter the code!');
    } else {
      let data = {
        couponId: this.state.couponCode,
        employerCoupon: true,
      };
      this.props.verifyCouponCodeAction(data);
    }
  };

  render() {
    return (
      <>
        <TopBar />
        <React.Fragment>
          <div
            className="back-to-home rounded d-none d-sm-block"
            style={{ paddingTop: 70 }}
          ></div>
          <section className="cover-user bg-white">
            <div className="container-fluid">
              <Row className="position-relative">
                <Col
                  lg={6}
                  className="cover-my-30 order-2  md_SignUpForm"
                  style={{ paddingBottom: 100 }}
                >
                  {this.state.otpState ? (
                    <div
                      style={{
                        // minHeight: '100vh',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 40,
                      }}
                    >
                      <div
                        style={{
                          flexDirection: 'column',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          maxWidth: 500,
                          padding: 20,
                        }}
                      >
                        <div>
                          <h1
                            style={{
                              fontSize: 30,
                              fontWeight: 'bold',
                              color: 'black',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Verify Email
                          </h1>
                        </div>
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              color: colors.fontGray,
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Almost there! An email containing verification code
                            was sent to {this.state.email}.
                          </p>
                        </div>
                        <div>
                          {/* OTP input */}

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              marginTop: 40,
                            }}
                          >
                            <OtpInput
                              value={this.state.otp}
                              onChange={(otp) => this.setState({ otp })}
                              numInputs={4}
                              renderSeparator={
                                <span
                                  style={{
                                    width: 20,
                                  }}
                                >
                                  &nbsp;
                                </span>
                              }
                              renderInput={(props) => (
                                <input
                                  {...props}
                                  onPaste={(e) => {
                                    const otp =
                                      e.clipboardData.getData('text/plain');
                                    this.setState({ otp });
                                    e.preventDefault();
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.ctrlKey && e.keyCode === 86) {
                                      console.log('Ctrl+V pressed');
                                    }
                                  }}
                                />
                              )}
                              inputStyle={{
                                width: 50,
                                height: 50,
                                borderRadius: 5,
                                border: 'none',
                                fontSize: 20,
                                fontWeight: 'bold',
                                color: '#000000',
                                backgroundColor: '#F5F5F5',
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                              }}
                              inputType="number"
                            />
                          </div>
                        </div>
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              color: colors.fontGray,
                              fontFamily: 'Montserrat',
                              marginTop: 70,
                            }}
                          >
                            Didn't receive code? check spam folder or {' '}
                            <span
                              style={{
                                color: colors.blue,
                                cursor: 'pointer',
                              }}
                              onClick={() => this.resendMail()}
                            >
                              Request again
                            </span>
                          </p>
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: 15,
                              color: colors.blue,
                              cursor: 'pointer',
                              fontFamily: 'Montserrat',
                            }}
                            onClick={() =>
                              this.setState({ otpState: false, otp: '' })
                            }
                          >
                            Change email here
                          </span>
                        </div>
                        <div>
                          <button
                            onClick={() => this.onUserRegister()}
                            disabled={this.state.loading}
                            type="button"
                            className="btn btn-primary w-fit"
                            style={{
                              marginTop: 40,
                            }}
                          >
                            {this.state.loading ? (
                              <BeatLoader
                                css={override}
                                sizeUnit={'px'}
                                size={10}
                                color={'white'}
                                loading={true}
                              />
                            ) : (
                              'Verify and Create Account'
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="text-center" style={styles.mt_15}>
                        <div
                          // className="mb-4"
                          style={{
                            paddingBottom: this.state.width < 701 ? 0 : 20,
                            color: '#000000',
                            fontWeight: 'bold',
                            fontSize: 25,
                            fontFamily: 'Montserrat',
                          }}
                        >
                          New SignUp
                        </div>
                      </div>
                      <div className="cover-user-img d-flex align-items-center">
                        <Row>
                          <Col style={styles.mb_15}>
                            <div className="login_page">
                              <form className="login-form">
                                <Row>
                                  <Col md={6}>
                                    <div className="form-group position-relative">
                                      <label>
                                        First name{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-account ml-3 icons"></i>
                                      <input
                                        type="text"
                                        className="form-control pl-5"
                                        placeholder="First Name"
                                        onChange={this.getValue}
                                        value={this.state.firstName}
                                        name="firstName"
                                        required=""
                                      />
                                    </div>
                                  </Col>

                                  <Col md={6}>
                                    <div className="form-group position-relative">
                                      <label>
                                        Last name{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-account ml-3 icons"></i>
                                      <input
                                        type="text"
                                        className="form-control pl-5"
                                        placeholder="Last Name"
                                        onChange={this.getValue}
                                        value={this.state.lastName}
                                        name="lastName"
                                        required=""
                                      />
                                    </div>
                                  </Col>

                                  <Col md={12}>
                                    <div className="form-group position-relative">
                                      <label>
                                        Your Email{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-account ml-3 icons"></i>
                                      <input
                                        type="email"
                                        className="form-control pl-5"
                                        placeholder="Email"
                                        // onBlur={
                                        //   this.state.showEMessage
                                        //     ? ''
                                        //     : this.handleEmailBlur
                                        // }
                                        onChange={this.handleEmail}
                                        value={this.state.email}
                                        name="email"
                                        required=""
                                      />
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    <div className="form-group position-relative">
                                      <label>
                                        Password{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-key ml-3 icons"></i>
                                      {this.state.passwordShowToggle ? (
                                        <VisibilityOffIcon
                                          style={{
                                            position: 'absolute',
                                            top: 42,
                                            marginRight: 10,
                                            right: 0,
                                            lineHeight: '100px',
                                            fontSize: '18px',
                                            color: 'black',
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              passwordShowToggle:
                                                !this.state.passwordShowToggle,
                                            })
                                          }
                                        />
                                      ) : (
                                        <VisibilityIcon
                                          style={{
                                            position: 'absolute',
                                            top: 42,
                                            marginRight: 10,
                                            right: 0,
                                            lineHeight: '100px',
                                            fontSize: '18px',
                                            color: 'black',
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              passwordShowToggle:
                                                !this.state.passwordShowToggle,
                                            })
                                          }
                                        />
                                      )}
                                      <input
                                        type={
                                          this.state.passwordShowToggle
                                            ? 'text'
                                            : 'password'
                                        }
                                        className="form-control pl-5"
                                        placeholder="Password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.getValue}
                                        required=""
                                      />
                                    </div>
                                  </Col>

                                  <Col md={12}>
                                    <div className="form-group position-relative">
                                      <label>
                                        Confirm Password{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-key ml-3 icons"></i>
                                      {this.state.confirmPasswordShowToggle ? (
                                        <VisibilityOffIcon
                                          style={{
                                            position: 'absolute',
                                            top: 42,
                                            marginRight: 10,
                                            right: 0,
                                            lineHeight: '100px',
                                            fontSize: '18px',
                                            color: 'black',
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              confirmPasswordShowToggle:
                                                !this.state
                                                  .confirmPasswordShowToggle,
                                            })
                                          }
                                        />
                                      ) : (
                                        <VisibilityIcon
                                          style={{
                                            position: 'absolute',
                                            top: 42,
                                            marginRight: 10,
                                            right: 0,
                                            lineHeight: '100px',
                                            fontSize: '18px',
                                            color: 'black',
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              confirmPasswordShowToggle:
                                                !this.state
                                                  .confirmPasswordShowToggle,
                                            })
                                          }
                                        />
                                      )}
                                      <input
                                        type={
                                          this.state.confirmPasswordShowToggle
                                            ? 'text'
                                            : 'password'
                                        }
                                        className="form-control pl-5"
                                        placeholder="Confirm Password"
                                        name="repassword"
                                        onChange={this.getValue}
                                        value={this.state.repassword}
                                        required=""
                                      />
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    <div className="form-group">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          checked={this.state.isTerms}
                                          className="custom-control-input"
                                          id="customCheck1"
                                          onChange={this.handleChangeTerms}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="customCheck1"
                                          style={styles.pt_3}
                                        >
                                          I agree to Resumas{' '}
                                          <Link
                                            to="/terms-of-services"
                                            className="text-primary"
                                          >
                                            Terms of services{' '}
                                          </Link>
                                          and{' '}
                                          <Link
                                            to="/page-privacy"
                                            className="text-primary"
                                          >
                                            Privacy Policy
                                          </Link>
                                        </label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    <button
                                      onClick={() => this.handleGotoOtp()}
                                      type="button"
                                      className="btn btn-primary w-100"
                                      disabled={this.state.otpLoader}
                                    >
                                      {this.state.planChoosed === 1
                                        ? 'Next'
                                        : 'Sign Up'}
                                    </button>
                                  </Col>

                                  <div className="mx-auto">
                                    <p className="mb-0 mt-3">
                                      <small className="text-dark mr-2">
                                        Already have an account ?
                                      </small>
                                      <Link
                                        to="/login"
                                        className="text-dark font-weight-bold"
                                      >
                                        Sign in
                                      </Link>
                                    </p>
                                  </div>
                                </Row>
                              </form>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </Col>
                <RightImage signupImage={signupImage} />
              </Row>
            </div>
          </section>
          <Footer removeContent />
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const {
    user,
    loading,
    pVCode,
    eVCode,
    couponVerification,
    msgSignup,
    couponExpiry,
  } = authUser;
  return {
    user,
    loading,
    pVCode,
    eVCode,
    couponVerification,
    msgSignup,
    couponExpiry,
  };
};

export default connect(mapStateToProps, {
  registerUser,
  getSkills,
  sendVCode,
  verifyCouponCodeAction,
  getUserIdData,
  verifyUserCode,
  changeEamil,
})(PageCoverSignup);
