import React from 'react';
import Select from 'react-select';
import { Button, CircularProgress } from '@material-ui/core';
import { styles } from '../MilitaryXWalkStyle';
import useMilitaryCrosswalk from '../useMilitaryCrosswalk';
import Chips from '../Chips';
import { GetStepImage } from '../../../utils/GetStepImage';
import AsyncSelect from 'react-select/async';
const StartMilitary = () => {
  const {
    isSmall,
    options,
    militaryData: { branch, code },
    subtitles,
    selectedSubtitles,
    onSearchHandler,
    onSelectMilitaryBranch,
    onChangeMilitaryCode,
    onAddSubtitle,
    onRemoveSubtitle,
    loadOptions,
    jobSubtitleLoader,
    isEmptyOccupations,
    width,
  } = useMilitaryCrosswalk();
  return (
    <div>
      <div
        style={{
          ...styles.infoHeading,
          flexDirection: isSmall ? 'column' : 'row',
        }}
      >
        <div style={{ width: isSmall ? '100%' : '40%', ...styles.formStyle }}>
          <GetStepImage step={1} style={{ marginBottom: -3, zIndex: 10 }} />
          <label style={styles.labelStyle} htmlFor="militaryBranch">
            Select Military Branch
          </label>
          <Select
            options={options}
            placeholder="Select Military Branch"
            styles={{
              width: '100%',
              height: '36px',
            }}
            value={branch}
            onChange={onSelectMilitaryBranch}
          />
        </div>
        <div style={{ ...styles.formStyle, width: isSmall ? '100%' : '40%' }}>
          <GetStepImage step={2} style={{ marginBottom: -3, zIndex: 10 }} />
          <label style={styles.labelStyle} htmlFor="militaryBranch">
            Search by MOC Code or Title
          </label>

          <AsyncSelect
            placeholder="MOC Code or Title"
            onChange={onChangeMilitaryCode}
            loadOptions={loadOptions}
            value={code}
            isDisabled={!branch?.value}
            escapeClearsValue={true}
            isClearable={true}
            style={{ ...styles.codeInput, width: '100%' }}
          />
        </div>
        <div
          style={{
            width: width <= 375 ? '100%' : width < 700 ? '40%' : '20%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <GetStepImage step={3} style={{ marginBottom: -10, zIndex: 10 }} />
          <Button
            style={styles.searchBtn}
            onClick={onSearchHandler}
            disabled={jobSubtitleLoader}
          >
            {jobSubtitleLoader ? (
              <CircularProgress {...styles.loader} />
            ) : (
              <b>Search</b>
            )}
          </Button>
        </div>
      </div>
      <p style={{ padding: '6px 0' }}>
        <b>You selected:</b>
        <br />
        <span>Military Branch: {branch?.value || ''}</span>
        <br />
        <span>MOC Code: {code?.code || ''}</span> <br />
        <span>Military Title: {code?.title || ''}</span> <br />
      </p>
      <Chips
        width={width}
        type={'object'}
        items={subtitles}
        selectedItems={selectedSubtitles}
        onAddItem={onAddSubtitle}
        onRemoveItem={onRemoveSubtitle}
        stepImg={true}
        isEmptyShow={isEmptyOccupations}
        stepNo={4}
        heading={'Civilian Job Occupation Catagories: Please Pick 1'}
        subtitle={`Explore a diverse range of career options and discover your interests by
        selecting 1 job title that resonate with you. Your choice
        will help tailor our recommendations to your preferences.`}
        emptyContent={
          isEmptyOccupations
            ? `Unfortunately, we don't have any civilian occupations that match your search in our database.`
            : `Please enter your Military Branch Name and Military Occupational Code
      (MOC) or Title above to retrieve a list of relevant job titles.`
        }
      />
    </div>
  );
};

export default StartMilitary;
