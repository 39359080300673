import React from 'react';
import { Text } from '../../component';
import { useSelector } from 'react-redux';
import { useWindowSize } from '@react-hook/window-size';
import { Row } from 'antd';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
export default function Profile(props) {
  const [width] = useWindowSize();
  let resumeData = useSelector((state) => state.editorReducer.resumeData);
  return (
    <>
      {props.templateSelect === 0 ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent:
                props.templateSelect === 0
                  ? 'flex-start'
                  : props.templateSelect === 2
                  ? 'flex-end'
                  : 'center',
              marginTop: 20,
            }}
          >
            <Row>
              <Text
                value={resumeData?.profile?.firstName}
                placeholder="First Name"
                customclass={'fullName'}
                path={'profile.firstName'}
              />
              &nbsp;
              <Text
                value={resumeData?.profile?.lastName}
                placeholder="Last Name"
                customclass={'fullName'}
                path={'profile.lastName'}
              />
            </Row>
          </div>
          <div className="manager-template-row manager-template-text-center">
            <div
              style={{
                display: 'flex',
                justifyContent:
                  props.templateSelect === 0
                    ? 'flex-start'
                    : props.templateSelect === 2
                    ? 'flex-end'
                    : 'center',
              }}
              className={
                width < 500
                  ? ''
                  : 'manager-template-row manager-template-text-center manager-f-sub-rowA'
              }
            >
              <div
                style={{
                  height: 45,
                  width: 150,
                  display: 'flex',
                  paddingTop: 14,
                  paddingLeft: 20,
                  paddingRight: 20,
                  backgroundColor: '#e1e1e1',
                  borderRadius: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #e1e1e1',
                }}
              >
                <LocalPhoneIcon
                  style={{
                    margin: '-13px 5px 0px -10px ',
                    fontSize: 18,
                  }}
                />
                <Text
                  value={resumeData?.profile?.phone}
                  placeholder="Phone"
                  customclass={'manager-fName full-width hide-scrollbar'}
                  path={'profile.phone'}
                />
              </div>
              <div
                style={{
                  height: 45,
                  width: 'fit-content',
                  paddingTop: 14,
                  paddingLeft: 20,
                  paddingRight: 20,
                  backgroundColor: '#e1e1e1',
                  borderRadius: 5,
                  display: 'flex',
                  alignItems: 'center',
                  // justifyContent: 'center',
                  border: '1px solid #e1e1e1',
                }}
              >
                <LocationOnIcon
                  style={{
                    margin: '-13px 5px 0px -10px ',
                    fontSize: 18,
                  }}
                />
                <Text
                  value={resumeData?.profile?.address?.city}
                  placeholder="City"
                  path={'profile.address.city'}
                  customclass={'manager-fName hide-scrollbar'}
                />
                <p className="aPadding">&nbsp;|&nbsp;</p>
                <Text
                  value={resumeData?.profile?.address?.state}
                  placeholder="State"
                  path={'profile.address.state'}
                  customclass={'manager-fName hide-scrollbar'}
                />
                <p className="aPadding">&nbsp;|&nbsp;</p>
                <Text
                  value={resumeData?.profile?.address?.zipcode}
                  placeholder="Zip"
                  customclass={'manager-fName hide-scrollbar'}
                  path={'profile.address.zipcode'}
                />
              </div>
              <div
                style={{
                  height: 45,
                  width:
                    resumeData?.profile?.email?.length > 30
                      ? 'fit-content'
                      : 220,
                  display: 'flex',
                  paddingTop: 14,
                  paddingLeft: 20,
                  paddingRight: 20,
                  backgroundColor: '#e1e1e1',
                  borderRadius: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #e1e1e1',
                  wordBreak: 'break-all',
                }}
              >
                <MailIcon
                  style={{
                    margin: '-13px 5px 0px -10px ',
                    fontSize: 18,
                  }}
                />
                <Text
                  value={resumeData?.profile?.email}
                  placeholder="Email"
                  customclass={'manager-fName full-width hide-scrollbar'}
                  path={'profile.email'}
                />
              </div>
              <div
                style={{
                  height: 45,
                  width:
                    resumeData?.profile?.email?.length > 30
                      ? 'fit-content'
                      : 220,
                  display: 'flex',
                  paddingTop: 14,
                  paddingLeft: 20,
                  paddingRight: 20,
                  backgroundColor: '#e1e1e1',
                  borderRadius: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #e1e1e1',
                  wordBreak: 'break-all',
                }}
              >
                <LinkedInIcon
                  style={{
                    margin: '-13px 5px 0px -10px ',
                    fontSize: 18,
                  }}
                />
                <Text
                  value={resumeData?.profile?.linkedinURL}
                  placeholder="LinkedIn"
                  customclass={'manager-fName full-width hide-scrollbar'}
                  path={'profile.linkedinURL'}
                />
              </div>
            </div>
          </div>
        </>
      ) : props.templateSelect === 1 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#e1e1e1',
            height: '100%',
            marginRight: 30,
            padding: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent:
                props.templateSelect === 0
                  ? 'flex-start'
                  : props.templateSelect === 2
                  ? 'flex-end'
                  : 'center',
              marginTop: 20,
            }}
          >
            <Row>
              <Text
                value={resumeData?.profile?.firstName}
                placeholder="First Name"
                customclass={'fullName'}
                path={'profile.firstName'}
              />
              &nbsp;
              <Text
                value={resumeData?.profile?.lastName}
                placeholder="Last Name"
                customclass={'fullName'}
                path={'profile.lastName'}
              />
            </Row>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              border: '1px solid #e1e1e1',
              marginTop: 10,
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: -13,
                marginRight: 10,
              }}
            >
              <LocalPhoneIcon
                style={{
                  fontSize: 18,
                }}
              />
            </div>
            <Text
              value={resumeData?.profile?.phone}
              placeholder="Phone"
              customclass={'manager-fName'}
              path={'profile.phone'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              border: '1px solid #e1e1e1',
              marginTop: 10,
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: -13,
                marginRight: 10,
              }}
            >
              <LocationOnIcon
                style={{
                  fontSize: 18,
                }}
              />
            </div>
            <Text
              value={resumeData?.profile?.address?.city}
              placeholder="City"
              path={'profile.address.city'}
              customclass={'manager-fName'}
            />
            <p className="aPadding">&nbsp;|&nbsp;</p>
            <Text
              value={resumeData?.profile?.address?.state}
              placeholder="State"
              path={'profile.address.state'}
              customclass={'manager-fName'}
            />
            <p className="aPadding">&nbsp;|&nbsp;</p>
            <Text
              value={resumeData?.profile?.address?.zipcode}
              placeholder="Zip"
              customclass={'manager-fName'}
              path={'profile.address.zipcode'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              border: '1px solid #e1e1e1',
              marginTop: 10,
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: -13,
                marginRight: 10,
                wordBreak: 'break-all',
              }}
            >
              <MailIcon
                style={{
                  fontSize: 18,
                }}
              />
            </div>
            <Text
              value={resumeData?.profile?.email}
              placeholder="Email"
              customclass={'manager-fName'}
              path={'profile.email'}
            />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              border: '1px solid #e1e1e1',
              marginTop: 10,
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: -13,
                marginRight: 10,
                wordBreak: 'break-all',
              }}
            >
              <LinkedInIcon
                style={{
                  fontSize: 18,
                }}
              />
            </div>
            <Text
              value={resumeData?.profile?.linkedinURL}
              placeholder="LinkedIn"
              customclass={'manager-fName'}
              path={'profile.linkedinURL'}
            />
          </div>
        </div>
      ) : props.templateSelect === 2 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#f9f9f9',
            height: '100%',
            padding: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: 10,
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: '50%',
                backgroundColor: '#454545',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: -13,
                marginRight: 10,
              }}
            >
              <LocalPhoneIcon
                style={{
                  fontSize: 18,
                  color: 'white',
                }}
              />
            </div>
            <Text
              value={resumeData?.profile?.phone}
              placeholder="Phone"
              customclass={'manager-fName'}
              path={'profile.phone'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: 10,
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: '50%',
                backgroundColor: '#454545',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: -13,
                marginRight: 10,
              }}
            >
              <LocationOnIcon
                style={{
                  fontSize: 18,
                  color: 'white',
                }}
              />
            </div>
            <Text
              value={resumeData?.profile?.address?.city}
              placeholder="City"
              path={'profile.address.city'}
              customclass={'manager-fName'}
            />
            <p className="aPadding">&nbsp;|&nbsp;</p>
            <Text
              value={resumeData?.profile?.address?.state}
              placeholder="State"
              path={'profile.address.state'}
              customclass={'manager-fName'}
            />
            <p className="aPadding">&nbsp;|&nbsp;</p>
            <Text
              value={resumeData?.profile?.address?.zipcode}
              placeholder="Zip"
              customclass={'manager-fName'}
              path={'profile.address.zipcode'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: 10,
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: '50%',
                backgroundColor: '#454545',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: -13,
                marginRight: 10,
              }}
            >
              <MailIcon
                style={{
                  fontSize: 18,
                  color: 'white',
                }}
              />
            </div>
            <Text
              value={resumeData?.profile?.email}
              placeholder="Email"
              customclass={'manager-fName'}
              path={'profile.email'}
            />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: 10,
            }}
          >
            <div
              style={{
                height: 30,
                width: 30,
                borderRadius: '50%',
                backgroundColor: '#454545',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: -13,
                marginRight: 10,
              }}
            >
              <LinkedInIcon
                style={{
                  fontSize: 18,
                  color: 'white',
                }}
              />
            </div>
            <Text
              value={resumeData?.profile?.linkedinURL}
              placeholder="LinkedIn"
              customclass={'manager-fName'}
              path={'profile.linkedinURL'}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
