import { colors } from "./colors";

export const styles = {
  closeBtn: {
    position: "absolute",
    right: 2,
    top: 2,
    zIndex: 99,
    cursor: "pointer",
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 50,
    color: colors.red,
  },
};
