import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { updateLinkedinData } from '../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '@react-hook/window-size';
import uuid from 'react-uuid';

export default function Volunteer(props) {
  const [width] = useWindowSize();
  const [organization, setOrganization] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState([]);

  const dispatch = useDispatch();
  const linkedinData = props.linkedinData;
  const organizationsModal = useSelector(
    (state) => state.linkedinReducer.organizationsModal,
  );
  const organizationsModalMode = useSelector(
    (state) => state?.linkedinReducer?.organizationsModalMode,
  );

  const cb = (v) => {
    dispatch({
      type: 'ORGANIZATIONS_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Organization Updated');
    } else {
      toastr.error('Error Updating Organization');
    }
  };


  const handleUpdate = () => {
    if (organization === '') {
      toastr.error('Please enter organization name');
      return;
    }
    setLoading(true);
    if(organizationsModalMode === 'new') {
      dispatch(
        updateLinkedinData(
          { ...linkedinData, affiliations: [...linkedinData?.affiliations, {id:uuid(), item:organization}] },
          cb,
        ),
      );
    } 
    else {
      dispatch(
        updateLinkedinData(
          {
            ...linkedinData,
            affiliations: linkedinData?.affiliations?.map((item) => {
              if (item?.id === organizationId) {
                return {id:organizationId, item:organization};
              }
              return item;
            }),
          },
          cb,
        ),
      );
    }

    setOrganization('');

  };

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };

  return (
    <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
      {linkedinData.affiliations ? (
        linkedinData.affiliations.map((affiliation)=>{
          return(
          <Row className="mt-2">
          <Col xs={10} lg={11} xl={11} md={11} className="border-bottom p-5">
            {affiliation.item}
          </Col>
          <Col
            xs={2}
            lg={1}
            xl={1}
            md={1}
            className="border-bottom p-2 d-flex align-items-center justify-content-center"
          >
            <span
              style={styles.copyIcon}
              onClick={() => {
                setOrganization(affiliation.item);
                setOrganizationId(affiliation.id);
                dispatch({
                  type: 'ORGANIZATIONS_MODAL',
                  payload: {
                    open: true,
                    mode: 'edit',
                  },
                });
              }}
            >
              {props.stepIndex === 1 ? (
                <CreateIcon style={styles.visibilityIcon} className="hovdel" />
              ) : (
                <FileCopyIcon
                  style={styles.visibilityIcon}
                  className="hovdel"
                />
              )}
            </span>
          </Col>
        </Row>
        )})
      ) : (
        'NO DATA AVAILABLE'
      )}

      <Popup
        open={organizationsModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'ORGANIZATIONS_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'ORGANIZATIONS_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <Row>
          <Col>
            <Label for="exampleEmail">Organization Name</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(organization) ? (
                    <CopyToClipboard
                      text={organization}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(organization);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={loading}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  {loading ? 'Saving' : 'Save'}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};
