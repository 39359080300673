export const styles = {
  background: {
    backgroundColor: 'white',
  },
  img: {
    width: 350,
    height: 500,
  },
  pb_10: {
    paddingBottom: 10,
  },
  pricing: {
    marginTop: 82,
    marginBottom: 82,
  },
  discount: {
    marginTop: 100,
    marginBottom: 100,
  },
  mt_30: {
    marginTop: '32px',
  },
  minWidth: {
    minWidth: '190px',
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    color: 'red',
  },
};
