import React from "react";
import { Row, Col } from "reactstrap";
import Editor from "./CoverEditor";
import InfoCard from "../../commonComponents/InfoCard";

function CoverLetterEditor({ defaultText, onSubmitFun, loader }) {
  return (
    <div>
      <Row>
        <Col lg="4" md="4" style={{ padding: "0px 0px 10px 10px" }}>
          <InfoCard text="After the introduction, focus on your history and qualifications." />
          <InfoCard text="Highlighting those skills and experience most suited to the position" />
          <InfoCard text="Use your cover letter to explain in the employment gaps in your work history, if any." />

          <InfoCard text="The final paragraph should summarize you interest and suggest to the next steps for proceeding with the application" />
        </Col>
        <Col lg="8" md="8" style={{ padding: "0px 0px 0px 30px" }}>
          <Editor
            loader={loader}
            onSubmitFun={onSubmitFun}
            description={defaultText}
          />
        </Col>
      </Row>
    </div>
  );
}

export default CoverLetterEditor;
