import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import { Colxx } from '../../commonComponents/customBootstrap';
import { MdClear, MdCheck } from 'react-icons/md';
import ModalRescan from '../save&rescan-modal';
import HelpIcon from '@material-ui/icons/Help';
import Megaphone from '../../assets/megaphone.webp';
import Arrow from '../../assets/arrow.webp';
import { GetStepImage } from '../../utils/GetStepImage';
import uniqid from 'uniqid';
import {
  addSkillsAction,
  removeSkillsAction,
  getSkillsAction,
  updateResumeTextAction,
} from '../../commonRedux/scan/action';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import Highlighter from 'react-highlight-words';
import { styles } from './style';
import { RESUME_COUNT } from '../../constants/sampleData';
import { addAdditionalSkills } from '../../utils';
import { stripHtml } from '../../utils/getFormatedTextFromBuilder';
import { colors } from '../../commonComponents/colors';
import checkAllIcon from '../../assets/checkAllIcon.png';
import duplicateIcon from '../../assets/duplicateIcon.png';
import { Cancel } from '@material-ui/icons';

class Hardskills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButtons: '',
      modalLarge: false,
      jobCount: '',
      resumeCount: '',
      skillKey: '',
      actionClicked: false,
      jobEditStatus: false,
      resumeEditStatus: false,
      softSkills: [],
      updateResumeText: '',
      changedResumeFormat: '',
      width: 0,
      height: 0,
      filteredSoftSkills: [
        ...new Set(
          (this.props.showOnlyMissing
            ? (Object.keys(this.props.skills?.soft_skills || {}) || [])?.filter(
                (key) => {
                  return this.props.skills?.soft_skills[key]?.missing === 'yes';
                },
              )
            : Object.keys(this.props.skills?.soft_skills || {}) || []
          )?.map((key) => key?.trim()),
        ),
      ],
      filteredHardSkills: [
        ...new Set(
          (this.props.showOnlyMissing
            ? (Object.keys(this.props.skills?.hard_skills || {}) || [])?.filter(
                (key) => {
                  return this.props.skills?.hard_skills[key].missing === 'yes';
                },
              )
            : Object.keys(this.props.skills?.hard_skills || {}) || []
          )?.map((key) => key?.trim()),
        ),
      ],
      // filteredSoftSkills: [],
      // filteredHardSkills: [],
      skillLoading: false,
    };
  }

  filterFineTuneSkills = (key) => {
    const result = this.props.fineTuneSkills?.filter((skill) => {
      return skill?.toLowerCase()?.trim() === key?.toLocaleLowerCase()?.trim();
    });
    return result;
  };

  componentDidMount() {
    this.props.getSkillsAction();
    this.setState({
      filteredSoftSkills: [
        ...new Set(
          (this.props.showOnlyMissing
            ? (Object.keys(this.props.skills?.soft_skills || {}) || [])?.filter(
                (key) => {
                  const formattedKeySoft = key?.includes('-')
                    ? key?.replace('-', ' ')
                    : key;
                  const isSoftIncluded =
                    this.filterFineTuneSkills(formattedKeySoft);
                  return (
                    this.props.skills?.soft_skills[key].missing === 'yes' &&
                    !isSoftIncluded?.length
                  );
                },
              )
            : Object.keys(this.props.skills?.soft_skills || {}) || []
          )?.map((key) => key?.trim()),
        ),
      ],
      filteredHardSkills: [
        ...new Set(
          (this.props.showOnlyMissing
            ? (Object.keys(this.props.skills?.hard_skills || {}) || [])?.filter(
                (key) => {
                  const formattedKeyHard = key.includes('-')
                    ? key.replace('-', ' ')
                    : key;
                  const isHardIncluded =
                    this.filterFineTuneSkills(formattedKeyHard);
                  return (
                    this.props.skills?.hard_skills[key].missing === 'yes' &&
                    !isHardIncluded?.length
                  );
                },
              )
            : Object.keys(this.props.skills?.hard_skills || {}) || []
          )?.map((key) => key?.trim()),
        ),
      ],
    });

    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  addSkills = (key, updateTo) => {
    this.setState({ skillLoading: true });
    const res = {
      oid: this.props.oid,
      skills: {
        hard_skills: { ...this.props.skills?.hard_skills },
        soft_skills: { ...this.props.skills?.soft_skills },
      },
    };

    res.skills[updateTo][key].exists = true;
    this.props.addSkillsAction(res, key, undefined, undefined, (val) => {
      this.setState({ skillLoading: val });
    });
    this.setState({ showButtons: '' });
  };

  removeSkills = (key, updateTo) => {
    this.setState({ skillLoading: true });
    const res = {
      oid: this.props.oid,
      skills: { ...this.props.skills },
    };

    res.skills[updateTo][key].exists = false;

    this.props.removeSkillsAction(res, key, undefined, (val) => {
      this.setState({ skillLoading: val });
    });
  };

  toggleLarge = (jCount, rCount, skillKey) => {
    const converted = stripHtml(this.props.description.resume);
    this.setState((prevState) => ({
      actionClicked: !prevState.actionClicked,
      modalLarge: !prevState.modalLarge,
      jobCount: jCount,
      resumeCount: rCount,
      skillKey: skillKey,
      changedResumeFormat: converted || this.props.description?.resume,
    }));
  };
  updateResumeTextFun = () => {
    let data = {
      oid: this.props.oid,
      resumeText: this.state?.updateResumeText,
    };
    this.props.updateResumeTextAction(data);
  };
  static getDerivedStateFromProps(nextProps) {
    if (nextProps.softSkills) {
      return {
        softSkills: nextProps.softSkills?.data,
      };
    }
  }
  removeAllSkills = () => {
    this.setState({ skillLoading: true });
    const res = {
      oid: this.props.oid,
      skills: { ...this.props.skills },
    };
    let resumeText = this.props.description?.resume;
    let fontFamily = this.props.description?.fontFamily;
    if (resumeText) {
      const additional_skills = [];

      Object.keys(res.skills['hard_skills']).forEach((key) => {
        if (res.skills['hard_skills'][key].exists) {
          res.skills['hard_skills'][key].exists = false;
          additional_skills.push({
            id: uniqid(),
            name: key,
          });
          resumeText = addAdditionalSkills(resumeText, key, fontFamily);
        }
      });

      Object.keys(res.skills['soft_skills']).forEach((key) => {
        if (res.skills['soft_skills'][key].exists) {
          res.skills['soft_skills'][key].exists = false;
          additional_skills.push({
            id: uniqid(),
            name: key,
          });
          resumeText = addAdditionalSkills(resumeText, key, fontFamily);
        }
      });

      if (additional_skills.length) {
        this.props.removeSkillsAction(res, additional_skills, true, (val) => {
          this.setState({ skillLoading: val });
        });
      } else {
        alert('All the skills has been already removed!');
      }

      // const key = "key"
      // toastr.success("Skills has been removed");
    }
  };
  addAllSkills = () => {
    this.setState({ skillLoading: true });
    const res = {
      oid: this.props.oid,
      skills: { ...this.props.skills },
    };
    // res.skills["hard_skills"][key].exists = true;
    let resumeText = this.props.description?.resume;
    let fontFamily = this.props.description?.fontFamily;
    if (resumeText) {
      const additional_skills = [];
      Object.keys(res.skills['hard_skills']).forEach((key) => {
        if (!res.skills['hard_skills'][key].exists) {
          res.skills['hard_skills'][key].exists = true;
          additional_skills.push({
            id: uniqid(),
            name: key,
          });
          resumeText = addAdditionalSkills(resumeText, key, fontFamily);
        }
      });

      Object.keys(res.skills['soft_skills']).forEach((key) => {
        if (!res.skills['soft_skills'][key].exists) {
          res.skills['soft_skills'][key].exists = true;
          additional_skills.push({
            id: uniqid(),
            name: key,
          });
          resumeText = addAdditionalSkills(resumeText, key, fontFamily);
        }
      });
      // const key = "key";
      if (additional_skills.length) {
        this.props.addSkillsAction(
          res,
          additional_skills,
          resumeText,
          true,
          (val) => {
            this.setState({ skillLoading: val });
          },
        );
      } else {
        alert('All the skills has been already added!');
      }
    } else {
      alert('Something wrong while adding skills');
    }
  };

  render() {
    const addedAll =
      Object.keys(this.props.skills?.hard_skills)?.every(
        (key) => this.props.skills?.hard_skills[key]?.exists === true,
      ) &&
      Object.keys(this.props.skills?.soft_skills)?.every(
        (key) => this.props.skills?.soft_skills[key]?.exists === true,
      );
    let tableCustomStyle = {};
    let headingStyle = {};
    let tableIconStyle = {};
    let CardStyles = {};
    if (this.props.showOnlyMissing) {
      tableCustomStyle = {
        maxHeight: 300,
        overflow: 'auto',
      };
      headingStyle = { fontSize: 16 };
      tableIconStyle = { fontSize: 13 };
      CardStyles = {
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 0,
        // paddingLeft: 10
      };
    }
    let hardSkillsLength = 0;
    let softSkillsLength = 0;
    this.state.filteredHardSkills.forEach((key) => {
      if (
        !this.props.skills?.hard_skills[key]?.exists &&
        this.props.skills?.hard_skills[key]?.missing === 'yes'
      ) {
        hardSkillsLength++;
      }
    });
    this.state.filteredSoftSkills.forEach((key) => {
      if (
        !this.props.skills?.soft_skills[key]?.exists &&
        this.props.skills?.soft_skills[key]?.missing === 'yes'
      ) {
        softSkillsLength++;
      }
    });
    return (
      <>
        <Modal
          isOpen={this.state.modalLarge}
          size="lg"
          toggle={this.toggleLarge}
        >
          <div
            id="alert-dialog-slide-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '20px 20px 0px',
            }}
          >
            <h5
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: 23,
                fontFamily: 'Nunito',
              }}
            >
              Skills Highlighter
            </h5>
            <Cancel
              style={{
                color: colors.red,
                cursor: 'pointer',
                fontSize: 24,
              }}
              onClick={this.toggleLarge}
            />
          </div>
          <ModalBody>
            <Row style={styles.row}>
              <Colxx
                lg={{
                  size: 6,
                }}
                md={{
                  size: 6,
                }}
                sm="12"
                xs="12"
              >
                <div>
                  <h3 style={styles.h3}>
                    <span>{`JOB COUNT: ${RESUME_COUNT.JobCount}`}</span>
                  </h3>
                  <div className="scrollBar" style={styles.scrollBar}>
                    <Highlighter
                      highlightClassName="YourHighlightClass"
                      className="white-space-pre"
                      searchWords={[this.state.skillKey]}
                      autoEscape={true}
                      textToHighlight={this.props.description.job}
                    />
                  </div>
                  {/* )} */}
                </div>
              </Colxx>
              <Colxx
                lg={{
                  size: 6,
                }}
                md={{
                  size: 6,
                }}
                sm="12"
                xs="12"
              >
                <div>
                  <h3 style={styles.h3}>
                    <span>
                      {' '}
                      {`RESUME COUNT: ${RESUME_COUNT.ResumeCount}`}
                      <EditIcon
                        onClick={this.props.handleMoveToCustomize}
                        style={{
                          fontSize: 14,
                          marginLeft: 10,
                          cursor: 'pointer',
                        }}
                      />
                    </span>
                  </h3>
                  <div className="scrollBar" style={styles.scrollBar}>
                    <Highlighter
                      highlightClassName="YourHighlightClass"
                      className="white-space-pre"
                      searchWords={[this.state.skillKey]}
                      autoEscape={true}
                      textToHighlight={this.state.changedResumeFormat}
                      caseSensitive={false}
                    />
                  </div>
                </div>
              </Colxx>
            </Row>
          </ModalBody>
          {this.state.jobEditStatus || this.state.resumeEditStatus ? (
            <ModalFooter style={{ padding: 5 }}>
              <Button
                className="footer-save-button"
                style={{ borderRadius: 0 }}
                disabled={this.props.updateResumeTextStatus ? true : false}
                onClick={this.updateResumeTextFun}
              >
                {this.props.updateResumeTextStatus ? 'loading...' : 'Save'}
              </Button>
            </ModalFooter>
          ) : null}
        </Modal>

        {this.props.rescanModal ? (
          <div>
            <Colxx xxs="12" sm="12" lg="12">
              <Table hover style={styles.hardSkill_table}>
                <thead>
                  <th style={styles.hardSkill_table}>
                    <th>SKILL</th>

                    <th style={styles.textAlignCenter}>RESUME</th>
                    <th style={styles.textAlignCenter}> JOB DESCRIPTION</th>
                  </th>
                </thead>
                <th>
                  {Object.keys(this.props.skills?.hard_skills)?.map(
                    (key, index) => {
                      return (
                        <tr
                          key={index}
                          onMouseEnter={() =>
                            this.setState({ showButtons: key })
                          }
                          onMouseLeave={() =>
                            this.setState({ showButtons: '' })
                          }
                        >
                          <td className="align-middle" style={styles.td}>
                            <span>
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </span>
                          </td>

                          <td
                            className="align-middle"
                            style={styles.textAlignCenter}
                          >
                            {this.props.skills?.hard_skills[key]
                              ?.ResumeCount === 0 ? (
                              <MdClear
                                color={colors.red}
                                style={styles.inline}
                              />
                            ) : (
                              this.props.skills?.hard_skills[key]?.ResumeCount
                            )}
                          </td>
                          <td
                            className="align-middle"
                            style={styles.textAlignCenter}
                          >
                            {this.props.skills?.hard_skills[key]?.JobCount}
                          </td>
                        </tr>
                      );
                    },
                  )}

                  {Object.keys(this.props.skills?.soft_skills)?.map(
                    (key, index) => {
                      return (
                        <tr
                          key={index}
                          onMouseEnter={() =>
                            this.setState({ showButtons: key })
                          }
                          onMouseLeave={() =>
                            this.setState({ showButtons: '' })
                          }
                        >
                          <td className="align-middle" style={styles.td}>
                            <span>
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </span>
                          </td>

                          <td
                            className="align-middle"
                            style={styles.textAlignCenter}
                          >
                            {this.props.skills?.soft_skills[key]
                              ?.ResumeCount === 0 ? (
                              <MdClear
                                color={colors.red}
                                style={styles.inline}
                              />
                            ) : (
                              this.props.skills?.soft_skills[key]?.ResumeCount
                            )}
                          </td>
                          <td
                            className="align-middle"
                            style={styles.textAlignCenter}
                          >
                            {this.props.skills?.soft_skills[key]?.JobCount}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </th>
              </Table>
            </Colxx>
          </div>
        ) : (
          <>
            {!Object.keys(this.props.skills?.soft_skills)?.length &&
            !Object.keys(this.props.skills?.hard_skills)?.length ? (
              ''
            ) : (
              <div style={{ marginBottom: 7 }}>
                <Card>
                  <CardBody
                    style={{
                      background:
                        this.props.showInfoCard === false ? 'white' : '#EDF0F7',
                      border:
                        this.props.showInfoCard === false
                          ? 'none'
                          : '1px solid lightgray',
                      ...CardStyles,
                    }}
                  >
                    <CardTitle
                      style={{
                        maxWidth: this.state.width < 500 ? '220px' : '400px',
                        ...styles.fontWeight_heading,
                        ...headingStyle,
                        marginBottom: 7,
                      }}
                    >
                      SKILLS MATCH RESULTS
                      <div style={{ position: 'absolute', right: 25, top: 20 }}>
                        {this.props.fromState === 'yes' ||
                        !this.props.goBuilder ? null : (
                          <ModalRescan
                            goBuilder={this.props.goBuilder}
                            percent={this.props.totalScore}
                            skills={this.props.skills}
                            description={{
                              resume: this.props.description.resume,
                              job: this.props.description.job,
                              is_parsed: this.props.description.is_parsed,
                            }}
                            oid={this.props.oid}
                            resumeId={this.props.resumeId}
                            company={this.props.company}
                            jobTitle={this.props.jobTitle}
                          />
                        )}
                      </div>
                    </CardTitle>
                    {this.props.showInfoCard === false ? null : (
                      <div style={{ marginBottom: '5px' }}>
                        {!Object.keys(this.props.skills?.hard_skills)
                          ?.length ? (
                          <p
                            style={{
                              ...styles.colorGrey,
                              fontWeight: 'bold',
                            }}
                          >
                            No,skills are missing from your resume.
                          </p>
                        ) : (
                          <p
                            style={{
                              ...styles.colorGrey,
                              fontWeight: 'bold',
                              display: 'flex',
                              paddingRight: '20px',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {addedAll ? (
                              <MdCheck size="18px" color={colors.green} />
                            ) : (
                              <MdClear size="18px" color={colors.red} />
                            )}
                            Resume missing {hardSkillsLength} important
                            hard-skills & {softSkillsLength} soft-skills.{' '}
                          </p>
                        )}
                      </div>
                    )}
                    {this.props.showInfoCard === false ? null : (
                      <Card
                        style={{
                          borderRadius: 10,
                          backgroundColor: 'white',
                          borderWidth: 0,
                          marginRight: 20,
                        }}
                      >
                        <CardBody style={{ padding: 3 }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              alt="Megaphone"
                              src={Megaphone}
                              style={{
                                height: 40,
                                marginRight: 20,
                                color: '#576baa',
                                backgroundColor: '"#576baa"',
                                paddingLeft: 25,
                              }}
                            />

                            <div>
                              <p
                                style={{
                                  fontSize: 12,
                                  color: '#576baa',
                                  marginTop: 20,
                                  fontWeight: 'bold',
                                }}
                              >
                                ADD ALL (Recommended) or select each skill
                                below.
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )}
                    <div style={tableCustomStyle}>
                      <Table
                        hover
                        responsive
                        style={{ ...styles.hardSkill_table2 }}
                      >
                        <thead
                          style={{
                            height: this.props.showOnlyMissing ? 90 : 'auto',
                          }}
                        >
                          <th>
                            {!this.props.showOnlyMissing && <p>SKILL</p>}
                            {this.props.showOnlyMissing && (
                              <div style={{ minWidth: '30px' }}>
                                {!addedAll ? (
                                  <img
                                    src={duplicateIcon}
                                    style={{
                                      cursor: 'pointer',
                                      marginLeft: -7,
                                      height: 28,
                                      width: 28,
                                    }}
                                    onClick={
                                      !this.state.skillLoading &&
                                      this.addAllSkills
                                    }
                                  />
                                ) : (
                                  <img
                                    src={checkAllIcon}
                                    style={{
                                      cursor: 'pointer',
                                      marginLeft: -7,
                                      height: 28,
                                      width: 28,
                                    }}
                                    onClick={
                                      !this.state.skillLoading &&
                                      this.removeAllSkills
                                    }
                                  />
                                )}
                                <div style={{ position: 'relative' }}>
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: -55,
                                      width: '130px',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <span>
                                      Add All
                                      <Tooltip
                                        title="Adds all missing keywords with “one-click” under “Additional Skills” section."
                                        arrow
                                      >
                                        <HelpIcon
                                          style={{
                                            ...styles.cursorPointer,
                                            ...tableIconStyle,
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                    <img
                                      alt=""
                                      src={Arrow}
                                      style={{
                                        position: 'absolute',
                                        bottom: -30,
                                        left: 20,
                                        width: 40,
                                        height: 40,
                                        marginRight: 20,
                                        color: '#576baa',
                                        backgroundColor: '"#576baa"',
                                        transform: 'rotate(271deg) scaleX(-1)',
                                      }}
                                    />
                                    <GetStepImage step={5} />
                                  </div>
                                </div>
                              </div>
                            )}
                          </th>
                          {this.props.showOnlyMissing ? (
                            <>
                              <th>Missing Skills</th>
                              <th
                                style={{
                                  textAlign: 'center',
                                }}
                              >
                                Job / Resume
                              </th>
                            </>
                          ) : (
                            <>
                              <th
                                style={{
                                  textAlign: 'center',
                                  width: '230px',
                                }}
                              >
                                <p>JOB</p>
                              </th>
                              <th
                                style={{
                                  textAlign: 'center',
                                  width: '230px',
                                }}
                              >
                                <p>RESUME</p>
                              </th>
                              <th
                                style={{
                                  textAlign: 'center',
                                  width: '230px',
                                }}
                              >
                                <p>TYPE</p>
                              </th>
                              <th
                                style={{
                                  textAlign: 'center',
                                  width: '230px',
                                }}
                              >
                                <p>VIEW</p>
                              </th>
                            </>
                          )}
                          {/* </div> */}
                        </thead>
                        <tbody>
                          {this.state.filteredHardSkills
                            ?.filter((n) => n.trim() !== 'none')
                            ?.filter(
                              (item) =>
                                !this.props.resume?.data?.skills?.items?.includes(
                                  item?.trim(),
                                ),
                            )
                            ?.map((key, index) => {
                              const isIncluded =
                                this.props.skills?.hard_skills[key]?.exists &&
                                this.props.skills?.hard_skills[key]?.missing ===
                                  'yes';
                              return (
                                <tr
                                  key={index}
                                  onMouseEnter={() =>
                                    this.setState({ showButtons: key })
                                  }
                                  onMouseLeave={() =>
                                    this.setState({ showButtons: '' })
                                  }
                                >
                                  {this.props.showOnlyMissing && (
                                    <td
                                      className="align-middle"
                                      style={styles.td}
                                    >
                                      <div
                                        style={{
                                          width: '28px',
                                          height: '28px',
                                          borderRadius: '50%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          background: '#4267b2',
                                          marginRight: 5,
                                          cursor: 'pointer',
                                          marginLeft: -7,
                                          marginTop: -5,
                                        }}
                                        onClick={() =>
                                          !this.state.skillLoading &&
                                          (!isIncluded
                                            ? this.addSkills(key, 'hard_skills')
                                            : this.removeSkills(
                                                key,
                                                'hard_skills',
                                              ))
                                        }
                                      >
                                        {!isIncluded ? (
                                          <AddIcon
                                            style={{
                                              color: '#FFFFFF',
                                              fontSize: 20,
                                            }}
                                          />
                                        ) : (
                                          <CheckIcon
                                            style={{
                                              color: '#FFFFFF',
                                              fontSize: 20,
                                            }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  )}

                                  <td
                                    className="align-middle"
                                    style={styles.td}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        textTransform: 'capitalize',
                                        textAlign: 'left !important',
                                      }}
                                    >
                                      {key.charAt(0) +
                                        key.slice(1).toLowerCase()}
                                    </p>
                                  </td>

                                  {this.props.showOnlyMissing ? (
                                    <td style={styles.textAlignCenter}>
                                      {RESUME_COUNT.JobCount}/
                                      {RESUME_COUNT.ResumeCount}
                                    </td>
                                  ) : (
                                    <>
                                      <td
                                        className="align-middle"
                                        style={styles.textAlignCenter}
                                      >
                                        {RESUME_COUNT.JobCount}
                                      </td>
                                      <td
                                        className="align-middle"
                                        style={styles.textAlignCenter}
                                      >
                                        {/* {  {RESUME_COUNT.ResumeCount}} */}
                                        {this.props.skills?.hard_skills[key]
                                          ?.exists
                                          ? '1'
                                          : '0'}
                                      </td>
                                    </>
                                  )}
                                  {!this.props.showOnlyMissing && (
                                    <>
                                      <td
                                        className="align-middle"
                                        style={
                                          {
                                            // width: "22%",
                                          }
                                        }
                                      >
                                        <p className="hard_skill m-auto">
                                          Hard
                                        </p>
                                      </td>
                                      <td
                                        className="align-middle"
                                        style={styles.textAlignCenter}
                                      >
                                        <VisibilityIcon
                                          className="hoveld"
                                          onClick={() => {
                                            this.toggleLarge(
                                              this.props.skills?.hard_skills[
                                                key
                                              ]?.JobCount,
                                              this.props.skills?.hard_skills[
                                                key
                                              ]?.ResumeCount,
                                              key,
                                            );
                                          }}
                                        />
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          {this.state.filteredSoftSkills
                            ?.filter((n) => n.trim() !== 'none')
                            ?.map((key) => key?.trim())
                            ?.filter(
                              (item) =>
                                !this.props.resume?.data?.skills?.items?.includes(
                                  item?.trim(),
                                ),
                            )
                            ?.filter(
                              (item) =>
                                !(
                                  this.state.filteredHardSkills || []
                                )?.includes(item),
                            )
                            ?.filter(
                              (item) =>
                                !this.props.resume?.data?.skills.items.includes(
                                  item?.trim(),
                                ),
                            )
                            ?.map((key, index) => {
                              const isExists =
                                this.props.skills?.soft_skills[key]?.exists;
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    opacity: this.state.skillLoading ? 0.5 : 1,
                                  }}
                                  onMouseEnter={() =>
                                    this.setState({ showButtons: key })
                                  }
                                  onMouseLeave={() =>
                                    this.setState({ showButtons: '' })
                                  }
                                >
                                  {this.props.showOnlyMissing && (
                                    <td
                                      style={{
                                        ...styles.td,
                                      }}
                                      className="align-middle"
                                    >
                                      <div
                                        style={{
                                          width: '28px',
                                          height: '28px',
                                          borderRadius: '50%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          background: '#4267b2',
                                          marginRight: 5,
                                          cursor: 'pointer',
                                          marginLeft: -7,
                                          marginTop: -5,
                                        }}
                                        onClick={() =>
                                          !this.state.skillLoading &&
                                          (!isExists
                                            ? this.addSkills(key, 'soft_skills')
                                            : this.removeSkills(
                                                key,
                                                'soft_skills',
                                              ))
                                        }
                                      >
                                        {!isExists ? (
                                          <AddIcon
                                            style={{
                                              color: '#FFFFFF',
                                              fontSize: 20,
                                            }}
                                          />
                                        ) : (
                                          <CheckIcon
                                            style={{
                                              color: '#FFFFFF',
                                              fontSize: 20,
                                            }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  )}
                                  <td
                                    style={styles.td}
                                    className="align-middle"
                                  >
                                    <p
                                      style={{
                                        textTransform: 'capitalize',
                                        margin: 0,
                                      }}
                                    >
                                      {key.charAt(0) +
                                        key.slice(1).toLowerCase()}
                                    </p>
                                  </td>

                                  {this.props.showOnlyMissing ? (
                                    <td style={styles.textAlignCenter}>
                                      {RESUME_COUNT.JobCount}/
                                      {RESUME_COUNT.ResumeCount}
                                    </td>
                                  ) : (
                                    <>
                                      <td style={styles.textAlignCenter}>
                                        {RESUME_COUNT.JobCount}
                                      </td>
                                      <td style={styles.textAlignCenter}>
                                        {this.props.skills?.soft_skills[key]
                                          ?.exists
                                          ? '1'
                                          : '0'}
                                      </td>
                                    </>
                                  )}

                                  {!this.props.showOnlyMissing && (
                                    <>
                                      <td className="align-middle">
                                        <span className="soft_skill m-auto">
                                          Soft
                                        </span>
                                      </td>

                                      <td
                                        className="align-middle"
                                        style={styles.textAlignCenter}
                                      >
                                        <VisibilityIcon
                                          className="hoveld"
                                          onClick={() => {
                                            this.toggleLarge(
                                              this.props.skills?.soft_skills[
                                                key
                                              ]?.JobCount,
                                              this.props.skills?.soft_skills[
                                                key
                                              ]?.ResumeCount,
                                              key,
                                            );
                                          }}
                                        />
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ fileScanReducer }) => {
  return {
    softSkills: fileScanReducer.softSkills,
    updateResumeTextStatus: fileScanReducer.updateResumeTextStatus,
  };
};
export default connect(mapStateToProps, {
  addSkillsAction,
  removeSkillsAction,
  updateResumeTextAction,
  getSkillsAction,
})(withRouter(Hardskills));
