import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import { updateLinkedinData } from '../redux/actions';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import { useWindowSize } from '@react-hook/window-size';

export default function Training(props) {
  const [width] = useWindowSize();
  const [name, setName] = useState('');

  const [copied, setCopied] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldIndex, setIndex] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();
  const linkedinData = props.linkedinData;
  const trainingModal = useSelector(
    (state) => state.linkedinReducer.trainingModal,
  );
  const trainingModalMode = useSelector(
    (state) => state.linkedinReducer.trainingModalMode,
  );
  useEffect(() => { }, [trainingModalMode]);
  const cb = (v) => {
    if (v === 'OK') {
      setLoading(false);
      setName('');
      setIndex();
      toastr.success('Volunteer Experience Updated');
    } else {
      toastr.error('Error Updating Volunteer Experience');
    }
  };
  const handleUpdate = () => {
    try {
      setLoading(true);
      if (trainingModalMode === 'edit') {
        linkedinData.trainingHistory.Trainings[fieldIndex] = {
          Text: name,

        };
      } else {
        if (Array.isArray(linkedinData.trainingHistory.Trainings)) {
          linkedinData.trainingHistory.Trainings.push({
            Text: name,

          });
        }
        else {
          linkedinData.trainingHistory.Trainings = [{
            Text: name,

          }];

        }
      }



        dispatch(updateLinkedinData(linkedinData, cb));
        setIsEdit(false);

        dispatch({
          type: 'TRAINING_MODAL',
          payload: {
            open: false,
            mode: 'edit',
          },
        });
      } catch (error) {
      }
      finally {
        setLoading(false)
      }
    };

    const handleCopied = (v) => {
      setCopied([...copied, v]);
    };
    const clearCopied = () => {
      setCopied([]);
    };

    return (
      <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
        {Array.isArray(linkedinData.trainingHistory?.Trainings) ? (
          linkedinData.trainingHistory?.Trainings?.map((item, index) => {
            return (
              <Row key={index} className="mt-2">
                <Col
                  xs={10}
                  lg={11}
                  xl={11}
                  md={11}
                  className="border-bottom p-5"
                >
                  {item.Text}
                </Col>
                <Col
                  xs={2}
                  lg={1}
                  xl={1}
                  md={1}
                  className="border-bottom p-2 d-flex align-items-center justify-content-center"
                >
                  <span
                    style={styles.copyIcon}
                    onClick={() => {
                      setName(item.Text);
                      setIndex(index);
                      dispatch({
                        type: 'TRAINING_MODAL',
                        payload: {
                          open: true,
                          mode: 'edit',
                        },
                      });
                    }}
                  >
                    {props.stepIndex === 1 ? (
                      <CreateIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    ) : (
                      <FileCopyIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    )}
                  </span>
                </Col>
              </Row>
            );
          })
        ) : linkedinData.trainingHistory?.Trainings ? (
          <Row className="mt-2">
            <Col xs={10} lg={11} xl={11} md={11} className="border-bottom p-5">
              {linkedinData.trainingHistory?.Trainings}
            </Col>
            <Col
              xs={2}
              lg={1}
              xl={1}
              md={1}
              className="border-bottom p-2 d-flex align-items-center justify-content-center"
            >
              <span
                style={styles.copyIcon}
                onClick={() => {
                  setName(linkedinData.trainingHistory?.Trainings);
                  setIndex(0);
                  dispatch({
                    type: 'TRAINING_MODAL',
                    payload: {
                      open: true,
                      mode: 'edit',
                    },
                  });
                }}
              >
                {props.stepIndex === 1 ? (
                  <CreateIcon style={styles.visibilityIcon} className="hovdel" />
                ) : (
                  <FileCopyIcon
                    style={styles.visibilityIcon}
                    className="hovdel"
                  />
                )}
              </span>
            </Col>
          </Row>
        ) : (
          'NO DATA '
        )}

        <Popup
          open={trainingModal}
          onClose={() => {
            clearCopied();
            dispatch({
              type: 'TRAINING_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
          contentStyle={{
            marginTop: 120,
            borderRadius: 5,
            padding: 30,
            width: width < 768 ? '90%' : '60%',
            maxHeight: width < 768 ? '90%' : '75%',

          }}
          modal
          position="center"
          closeOnDocumentClick
        >
          <span
            onClick={() => {
              clearCopied();
              dispatch({
                type: 'TRAINING_MODAL',
                payload: {
                  open: false,
                  mode: 'edit',
                },
              });
            }}
          >
            <CloseIcon style={styles.closeBtn} />
          </span>
          <h5>Trainings</h5>

          <Row>
            <Col>
              <Label for="exampleEmail">Title</Label>
              <InputGroup>
                <Input
                  style={{ height: '50px' }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />{' '}
                {props.stepIndex === 2 && (
                  <InputGroupText>
                    {!copied.includes(name) ? (
                      <CopyToClipboard
                        text={name}
                        onCopy={() => {
                          toastr.success('Text Copied!');
                          handleCopied(name);
                        }}
                      >
                        <FileCopyIcon className="hovdel" />
                      </CopyToClipboard>
                    ) : (
                      <CheckIcon style={{ color: 'green' }} />
                    )}
                  </InputGroupText>
                )}
              </InputGroup>

              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                {props.stepIndex === 1 && (
                  <Button
                    onClick={handleUpdate}
                    className="mt-4"
                    loading={false}
                    style={{ backgroundColor: '#4267b2', color: 'white' }}
                    shape="round"
                    size={'large'}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Popup>
      </Card>
    );
  }

  const styles = {
    copyIcon: {
      cursor: 'pointer',
      // position: "absolute",
      // right: "10px",
      // top: "10px",
      width: '10px',
    },
    visibilityIcon: {
      fontSize: 18,
      position: 'absolute',
      right: '40',
      top: '45px',
    },
    border_radius_5: {
      borderRadius: 5,
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      margin: 0,
    },
    sub: {
      margin: 0,
    },
    years: {
      color: 'grey',
      margin: 0,
    },
    closeBtn: {
      position: 'absolute',
      right: 10,
      top: 10,
      zIndex: 99,
      cursor: 'pointer',
      // backgroundColor: "black",
      width: 30,
      height: 30,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: 50,
      // color: "#ff0e0e",
    },
  };
