import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { BeatLoader } from 'react-spinners';
import { loginUser } from '../redux/actions';
import { getSkills } from '../../../commonRedux/jobs/action';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import loginImage from '../../../assets/signup.webp';
import { css } from '@emotion/core';
import { validateEmail } from '../../../utils/emailValidation';
import { styles } from '../style';
import useDimenstion from '../useDimension';
import { colors } from '../../../commonComponents/colors';
import Footer from '../../layout/authLayout/Footer';
import TopBar from '../../layout/authLayout/Topbar';

const RightImage = ({ signupImage }) => {
  const size = useDimenstion();
  if (size.width < 991 && size.width > 700) {
    return null;
  }
  return (
    <div
      style={{
        width: '45%',
        minWidth: '300px',
        maxWidth: '500px',
        height: '400px',
        backgroundImage: `url(${signupImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      &nbsp;
    </div>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class PageCoverLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      width: 0,
      height: 0,
      loading: false,
      passwordShowToggle: false,
      // email: "rana.navee.sarwar@gmail.com",
      // password: "12345678",
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // if (this.props.user) {
    //   this.props.history.push('/dashboards');
    // }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.props.history.push('/dashboards');
    }
  }

  getValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loginCallBack = () => {
    this.setState({ loading: false });
  };
  onUserLogin = () => {
    if (this.state.email === '') {
      toastr.error('Email can not be empty');
    } else if (!validateEmail(this.state.email.replace(/\s/g, ''))) {
      toastr.error('Email is not valid');
    } else if (this.state.password === '') {
      toastr.error('Password can not be empty');
    } else if (this.state.password.length < 8) {
      toastr.error('Password must be at least 8 characters');
    } else {
      let data = {
        email: this.state.email.replace(/\s/g, ''),
        password: this.state.password,
      };
      this.setState({ loading: true });
      this.props.loginUser(data, this.loginCallBack);
      this.props.getSkills();
    }
  };

  render() {
    return (
      <>
        <TopBar />
        <section
          className="cover-user"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            minHeight: '800px',
            width: '100%',
            background: 'white',
          }}
        >
          <div className="container-fluid">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap-reverse',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '45%',
                  minWidth: '300px',
                  maxWidth: '600px',
                }}
              >
                <div className="text-center">
                  <div
                    className="mb-4"
                    style={{
                      color: '#000000',
                      fontWeight: 800,
                      fontSize: 25,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    Login
                  </div>
                </div>
                <Row>
                  <Col lg={12}>
                    <div className="form-group position-relative">
                      <label>
                        Your Email <span className="text-danger">*</span>
                      </label>
                      <i className="mdi mdi-account ml-3 icons"></i>
                      <input
                        value={this.state.email}
                        onChange={this.getValue}
                        type="email"
                        className="form-control pl-5"
                        placeholder="Email"
                        name="email"
                        required=""
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form-group position-relative">
                      <label>
                        Password <span className="text-danger">*</span>
                      </label>
                      <i className="mdi mdi-key ml-3 icons"></i>

                      {this.state.passwordShowToggle ? (
                        <VisibilityOffIcon
                          style={{
                            position: 'absolute',
                            top: 42,
                            marginRight: 10,
                            right: 0,
                            lineHeight: '100px',
                            fontSize: '18px',
                            color: 'black',
                          }}
                          onClick={() =>
                            this.setState({
                              passwordShowToggle:
                                !this.state.passwordShowToggle,
                            })
                          }
                        />
                      ) : (
                        <VisibilityIcon
                          style={{
                            position: 'absolute',
                            top: 42,
                            marginRight: 10,
                            right: 0,
                            lineHeight: '100px',
                            fontSize: '18px',
                            color: 'black',
                          }}
                          onClick={() =>
                            this.setState({
                              passwordShowToggle:
                                !this.state.passwordShowToggle,
                            })
                          }
                        />
                      )}
                      <input
                        value={this.state.password}
                        onChange={this.getValue}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            this.onUserLogin();
                          }
                        }}
                        type={
                          this.state.passwordShowToggle ? 'text' : 'password'
                        }
                        name="password"
                        className="form-control pl-5"
                        placeholder="Password"
                        required=""
                      />
                    </div>
                  </Col>

                  <Col
                    lg={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <p style={{ ...styles.mt_3, alignSelf: 'flex-end' }}>
                      <Link
                        className="text-dark font-weight-bold"
                        to="/forget-password"
                      >
                        Forgot password ?
                      </Link>
                    </p>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox"></div>
                    </div>
                  </Col>

                  <Col lg={12} className="mb-0">
                    <button
                      onClick={this.onUserLogin}
                      type="button"
                      className="btn btn-primary w-100"
                    >
                      {this.state.loading ? (
                        <BeatLoader
                          css={override}
                          sizeUnit={'px'}
                          size={10}
                          color={'white'}
                          loading={true}
                        />
                      ) : (
                        'Sign in'
                      )}
                    </button>
                  </Col>
                  <Col className="text-center">
                    <p className="mb-0 mt-3">
                      <small className="text-dark mr-2">
                        Don't have an account ?
                      </small>{' '}
                      <Link to="/signup" className="text-dark font-weight-bold">
                        Sign Up
                      </Link>
                    </p>
                  </Col>
                </Row>
              </div>
              <RightImage signupImage={loginImage} />
            </div>
          </div>
        </section>
        <Footer removeContent />
      </>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(mapStateToProps, {
  loginUser,
  getSkills,
})(PageCoverLogin);
