import React, { useEffect } from "react";
import TopBar from "../../layout/authLayout/Topbar";
import TopNav from "../../layout/appLayout/topnav";
import { Row, Col, Card } from "reactstrap";

import "./style.css";
function Trade(props) {
    const comingFrom = props.history?.location?.state?.comingFrom || "";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Card className="privacy-page-main">
            {comingFrom === "TopNav" ? <TopNav /> : <TopBar />}
            <Row>
                <Col
                    style={{
                        backgroundColor: "#f7f9fe",
                        display: "flex",
                        justifyContent: "center",
                    }}
                    sm="12"
                    md="4"
                    xs="12"
                    lg="4"
                >
                    <img
                        src={require("../../../assets/optirblue.webp")}
                        alt=""
                        style={{
                            width: 200,
                            height: 110,
                            objectFit: "contain",
                            padding: 5,
                            position: "relative",
                            marginTop: 100,
                        }}
                    />{" "}
                </Col>
                <Col sm="12" md="8" xs="12" lg="8">
                    <h1 style={{ marginTop: 20 }}>
                        <strong>Third Party Copyright & Trademarks</strong>
                    </h1>
                    <dl style={{ fontFamily: "Nunito", fontSize: '16px' }}>
                        <dd>
                            All the following trademarks are the properties of their respective
                            owners as listed below. Should any trademark attribution be missing,
                            mistaken or erroneous, please contact us as soon as possible for
                            rectification at support@workscaler.com
                        </dd>
                        <br />
                        <dt>Adobe Systems, Inc.</dt>
                        <dd>Adobe&reg;, Adobe Flash&reg;, Adobe Reader&reg;, Adobe Photoshop&reg; and
                            their logos are registered trademarks of Adobe Systems Inc.
                            in the US and other countries.
                        </dd>
                        <br />
                        <dt>Amazon.com, Inc.</dt>
                        <dd>Amazon&reg;, Kindle&reg;, Fire&reg; and all related logos are trademarks
                            of Amazon.com, Inc. or its affiliates.
                        </dd>
                        <br />
                        <dt>Apple, Inc.</dt>
                        <dd>Apple&reg;, Mac&reg;, Mac OS&reg;, Macbook&reg;, Macbook Pro&reg;, iPhone&reg;, iPad&reg;,
                            iPad Air&reg;, iPod&reg;, iPod touch&reg;, iTunes&reg;, App Store&reg;,
                            Find My Friends&reg;, iMessage&reg;, QuickTime&reg; and their logos
                            are registered trademarks of Apple Inc. in the United States
                            and other countries.
                        </dd>
                        <br />
                        <dt>Facebook, Inc.</dt>
                        <dd>Facebook&reg; and its logo are trademarks of Facebook, Inc.,
                            registered in the United States and other countries.
                        </dd>
                        <br />
                        <dt>Google, Inc.</dt>
                        <dd>Google&trade;, Android&trade;, Google Maps&trade;, Google Play&trade;, Google Docs&trade;,
                            Google Picasa&trade;, Gmail&trade;, Google Chrome&trade;, Google Plus&trade;,
                            YouTube&trade; and their logos are trademarks of Google, Inc.
                            in the US and/or other countries.
                        </dd>
                        <br />
                        <dt>LinkedIn Corporation</dt>
                        <dd>LinkedIn&reg; and its logo are registered trademarks of LinkedIn
                            Corporation and its affiliates in the US and other countries.
                        </dd>
                        <br />
                        <dt>Microsoft Corporation</dt>
                        <dd>Microsoft&reg;, Windows&reg;, Windows XP&reg;, Windows Vista&reg;, Xbox&reg;,
                            Zune&reg;, SharePoint&reg;, Internet Explorer&reg;, Hotmail&reg;,
                            Bing&reg;, Office&reg;, Word&reg;, PowerPoint&reg;, Excel&reg;, Outlook&reg;
                            and their logos are registered trademarks of Microsoft
                            Corporation in the United States and other countries.
                        </dd>
                        <br />
                        <dt>Mozilla Foundation</dt>
                        <dd>Mozilla&reg;, Mozilla Firefox&reg; and their logos are registered
                            trademarks of the Mozilla Foundation, in the US and other countries.
                        </dd>
                        <br />
                        <dt>Skype</dt>
                        <dd>Skype&reg; and its logo are trademarks of Skype in the
                            United States and other countries.
                        </dd>
                        <br />
                    </dl>
                </Col>
            </Row>
        </Card>
    );
}

export default Trade;