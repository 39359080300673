import React from "react";
import ContentLoader from "react-content-loader";

export default function FormStepsLoader(props) {
  return (
    <ContentLoader viewBox="0 0 660 70" {...props}>
      <circle cx="12" cy="15" r="11" />
      <rect x="26" y="12" rx="4" ry="4" width="40" height="5" />

      <circle cx="80" cy="15" r="11" />
      <rect x="95" y="12" rx="4" ry="4" width="40" height="5" />

      <circle cx="150" cy="15" r="11" />
      <rect x="165" y="12" rx="4" ry="4" width="40" height="5" />

      <circle cx="220" cy="15" r="11" />
      <rect x="235" y="12" rx="4" ry="4" width="40" height="5" />

      <circle cx="290" cy="15" r="11" />
      <rect x="305" y="12" rx="4" ry="4" width="40" height="5" />

      <circle cx="361" cy="15" r="11" />
      <rect x="377" y="12" rx="4" ry="4" width="40" height="5" />

      <circle cx="433" cy="15" r="11" />
      <rect x="448" y="12" rx="4" ry="4" width="40" height="5" />

      <circle cx="505" cy="15" r="11" />
      <rect x="519" y="12" rx="4" ry="4" width="40" height="5" />

      <circle cx="577" cy="15" r="11" />
      <rect x="590" y="12" rx="4" ry="4" width="40" height="5" />

      <circle cx="645" cy="15" r="11" />
    </ContentLoader>
  );
}
