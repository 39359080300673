import React from "react";
import { Card, CardBody } from "reactstrap";

const GradientCard = ({ children }) => {
  return (
    <Card
      style={{ borderRadius: 0, height: 120, width: "auto" }}
      className="dashboard-sq-banner justify-content-center"
    >
      <CardBody
        style={{ width: "auto", padding: 0, backgroundColor: "#4267B2" }}
        className="justify-content-center d-flex flex-row"
      >
        {children}
      </CardBody>
    </Card>
  );
};
export default GradientCard;
