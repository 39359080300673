import { colors } from "../../commonComponents/colors";

export const styles = {
  flex: {
    display: "flex",
  },
  flexColumn: {
    flexDirection: "column",
  },
  wrap: {
    flexWrap: "wrap",
  },
  inline: {
    display: "inline",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  justifyContentEnd: {
    justifyContent: "flex-end",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  spaceAround: {
    justifyContent: "space-around",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  left50: { left: "50%" },
  width_100_percent: {
    width: "100%",
  },
  width_80_percent: {
    width: "80%",
  },
  fontWeight_400: {
    fontWeight: 400,
    minWidth: '160px',
  },
  fontWeight_500: {
    fontWeight: 500,
  },
  fontWeight_bold: {
    fontWeight: "bold",
  },
  fontSize_75_rem: {
    fontSize: ".75rem",
  },
  mt_5: {
    marginTop: 5,
  },
  mt_10: {
    marginTop: 10,
  },
  mt_20: {
    marginTop: 20,
  },
  mt_30: { marginTop: 30 },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_40: {
    marginBottom: 40,
  },
  mb_90: {
    marginBottom: 90,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  btn: {
    backgroundColor: "white",
    borderColor: "#4267B2",
    borderWidth: 1,
    color: "#4267B2",
    marginRight: 5,
  },
  matchScore: {
    paddingBottom: 5,
    color: "gray",
  },
  editor: {
    border: "1px solid #F1F1F1",
    height: "300px",
  },
  desc: {
    width: "100%",
    height: 189,
    overflowY: "scroll",
    padding: "8px",
    backgroundColor: "white",
  },
  actionIcon: {
    fontSize: "0.9rem",
  },
  modal: {
    maxWidth: "650px",
  },
  modalBtn: {
    backgroundColor: colors.blue,
    color: "white",
    height: "40px",
  },
  modalBody: {
    padding: 15,
    paddingBottom: 0,
  },
  cardBody: { padding: 14 },
  recruiter_cardBody: { fontSize: ".85rem", overflow: "auto" },
  recruiter_cardTitleDiv: {
    position: "absolute",
    right: 25,
    top: 20,
  },
  fontWeight_heading: {
    fontWeight: "700",
    fontSize: 20,
  },
  cursorPointer: {
    marginLeft: 5,
    marginBottom: 3,
    cursor: "pointer",
  },
  colorGrey: { color: "grey" },
  colorBlack: { color: "black" },
};
