export const formStepsKey = [
  'profile',
  'work',
  'education',
  'skills',
  'objective',
  'certifications',
  'courses',
  'associations',
  'training',
  'achievements',
  'projects',
  'patents',
  'publications',
  'speaking_engagements',
  'security_creds',
  'military',
  'custom',
  'additional_skills',
];

export const formStepsIndexes = {
  profile: 0,
  work: 1,
  education: 2,
  certifications: 5,
  skills: 3,
  objective: 4,
  courses: 6,
  associations: 7,
  training: 8,
  achievements: 9,
  projects: 10,
  patents: 11,
  publications: 12,
  speaking_engagements: 13,
  security_creds: 14,
  military: 15,
  custom: 16,
  additional_skills: 17,
};
export const formSteps = [
  { label: 'Contact Info ', visible: 1, index: 0 },
  { label: 'Work History ', visible: 1, index: 1 },
  { label: 'Education', visible: 0, index: 2 },
  { label: 'Skill', visible: 0, index: 3 },
  { label: 'Summary', visible: 1, index: 4 },
  { label: 'Certifications & Licenses', visible: 0, index: 5 },
  { label: 'Courses', visible: 0, index: 6 },
  { label: 'Associations', visible: 1, index: 7 },
  { label: 'Trainings', visible: 1, index: 8 },
  { label: 'Achievements ', visible: 1, index: 9 },
  { label: 'Projects', visible: 1, index: 10 },
  { label: 'Patents', visible: 1, index: 11 },
  { label: 'Publications', visible: 1, index: 12 },
  { label: 'Speaking Engagements ', visible: 1, index: 13 },
  { label: 'Security Credentials', visible: 1, index: 14 },
  { label: 'Military', visible: 1, index: 15 },
  { label: 'Custom', visible: 1, index: 16 },
  { label: 'Additional Skills', visible: 1, index: 17 },
  // { label: "Additional Skills", visible: 1, index: 16 },
];
