import React, { useState } from 'react';
import Lottie from 'lottie-react';
import { Input } from 'antd';
import Loader from 'react-loader-spinner';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';
import toastr from 'toastr';
import PopAnimation from '../../../assets/animations/popAnimation.json';
import { styles } from '../../resumeBuilder/formBuilder/savePageStyle';
import CopyIcon from '../../../assets/svg/CopyIcon';
import DownloadIcon from '../../../assets/svg/DownloadIcon';
import ScanIcon from '../../../assets/svg/ScanIcon';
import { useWindowSize } from '@react-hook/window-size';
import { colors } from '../../../commonComponents/colors';
import url from '../../../config/endpoint';
import { CircularProgress, Button } from '@material-ui/core';
import { AiOutlineMail } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { UPDATE_FILE_NAME } from '../redux/actions.constants';
import { styles as militaryStyle } from '../MilitaryXWalkStyle';
import InfoIcon from '../../../commonComponents/InfoIcon';
import WordIcon from '../../../assets/svg/WordIcon';
import PdfIcon from '../../../assets/pdfIcon.svg';
const EndMilXWalk = ({
  suggestedInformation,
  militaryData,
  user,
  milXWalkLoading,
  handleVideoModal,
}) => {
  const [pdfLink, setPdfLink] = useState(
    `${url}/${suggestedInformation?.pdfLink}`,
  );
  const [docLink, setDocLink] = useState(
    `${url}/${suggestedInformation?.docxLink}`,
  );
  const [emailLoading, setEmailLoading] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const [reportName, setReportName] = useState(suggestedInformation?.fileName);
  const [width] = useWindowSize();
  const dispatch = useDispatch();
  const openUrl = (type) => {
    if (type === 'pdf' && pdfLink) window.open(pdfLink);
    else if (type === 'doc' && docLink) window.open(docLink);
    else toastr.error('Something went wrong ,please try again later');
  };

  React.useEffect(() => {
    setPdfLink(`${url}/${suggestedInformation?.pdfLink}`);
    setDocLink(`${url}/${suggestedInformation?.docxLink}`);
  }, [suggestedInformation?.pdfLink, suggestedInformation?.docsLink]);
  const sendEmailHandler = async () => {
    try {
      setEmailLoading(true);
      const res = await Axios.post(`${url}/send-milXwalk-email`, {
        branch: militaryData?.branch?.value,
        code: militaryData?.code?.code,
        title: militaryData?.code?.title,
        email: user?.email,
        docLink,
        pdfLink,
      });
      if (res.data.status === 200) toastr.success('Email sent successfully');
      else toastr.error('Error while sending email');
    } catch (error) {
      toastr.error('Something went wrong, please try again later');
      console.log(error);
    } finally {
      setEmailLoading(false);
    }
  };
  const renameReportHandler = async () => {
    try {
      setRenameLoading(true);
      const res = await Axios.post(`${url}/rename-file-milXwalk`, {
        fileName: reportName,
        userId: user?._id,
      });
      if (res.data.status === 200) {
        dispatch({
          type: UPDATE_FILE_NAME,
          payload: reportName,
        });
        toastr.success('Report renamed successfully');
      } else toastr.error('Error while renaming report');
    } catch (error) {
      toastr.error('Something went wrong, please try again later');
      console.log(error);
    } finally {
      setRenameLoading(false);
    }
  };
  const { suggestedInformationLoader } = useSelector(
    (state) => state?.militaryReducer,
  );
  if (suggestedInformationLoader) {
    return (
      <div
        style={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={25} />
      </div>
    );
  }
  return (
    <div>
      <div
        style={{
          ...militaryStyle.heading(width),
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InfoIcon
          onClick={() => handleVideoModal(true)}
          text={'How To'}
          icon={'Play'}
          height={18}
          width="70px"
          marginRight={5}
        />{' '}
        Generated Report
      </div>
      <div style={militaryStyle.congratsContainer}>
        Congratulations
        <Lottie
          style={militaryStyle.animationStyle}
          animationData={PopAnimation}
          loop={true}
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        You have successfully created your Report! Download your Report down
        below.
      </div>
      <div style={{ textAlign: 'center' }}>
        You can download a word and pdf copy of your created Report.
      </div>
      <div style={militaryStyle.rootWrapper(width)}>
        <div style={militaryStyle.reportContainer(width)}>
          <div style={militaryStyle.reportWrapper(width)}>
            <div
              style={{
                padding: '10px 0px',
                ...styles.flex,
                ...styles.spaceBetween,
                ...styles.resumeTitleBar,
                ...styles.alignItemsCenter,
              }}
            >
              <span style={styles.resumeTitle}>REPORT INFO</span>
              <div
                style={{
                  paddingRight: '10px',
                  ...styles.flex,
                  ...styles.alignItemsCenter,
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    ...styles.flex,
                    ...styles.alignItemsCenter,
                    ...styles.justifyContentCenter,
                    ...styles.flexColumn,
                  }}
                >
                  <span
                    style={militaryStyle.emailText}
                    disabled={emailLoading}
                    onClick={sendEmailHandler}
                  >
                    {emailLoading ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : (
                      <AiOutlineMail color={colors.white} fontSize={25} />
                    )}
                  </span>
                  <span
                    style={{
                      fontSize: 14,
                    }}
                  >
                    Email Link
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                ...styles.spaceBetween,
                ...militaryStyle.savedNamedContainer(width),
              }}
            >
              <div style={{ ...styles.flex, ...styles.flexColumn }}>
                <span
                  style={{
                    ...styles.itemDesc,
                    ...militaryStyle.resumeNameText,
                  }}
                >
                  Saved Name
                </span>
                <Input.Group compact>
                  <Input
                    style={{
                      width: width <= 375 ? 195 : 300,
                    }}
                    value={reportName}
                    onChange={(e) => {
                      if (e.target.value.length < 40) {
                        setReportName(e.target.value);
                      }
                    }}
                  />
                  <Button
                    onClick={renameReportHandler}
                    disabled={renameLoading}
                    style={militaryStyle.renameLoading(renameLoading)}
                  >
                    {renameLoading ? (
                      <CircularProgress size={15} color="#fff" />
                    ) : (
                      'Rename'
                    )}
                  </Button>
                </Input.Group>
              </div>
              <div style={{ ...styles.flex, ...styles.flexColumn }}>
                <span
                  style={{
                    ...styles.itemDesc,
                    ...militaryStyle.copyUrl,
                  }}
                >
                  Copy URL
                </span>
                {false ? (
                  <Loader
                    type="ThreeDots"
                    color={colors.blue}
                    height={20}
                    width={20}
                  />
                ) : (
                  <Input.Group compact style={militaryStyle.inputContainer}>
                    <Input
                      readOnly
                      style={militaryStyle.inputUrl(width)}
                      value={`${window.location.origin}/download-report?oid=${user?._id}`}
                    />
                    <CopyToClipboard
                      text={`${window.location.origin}/download-report?oid=${user?._id}`}
                      onCopy={() => toastr.success('Report URL copied!')}
                    >
                      <span style={militaryStyle.copyIcon}>
                        <CopyIcon />
                      </span>
                    </CopyToClipboard>
                  </Input.Group>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={militaryStyle.newReportContainer(width)}>
          <div style={styles.tableTitle}>NEW REPORT</div>
          <div
            style={{
              ...styles.flex,
              ...styles.flexColumn,
              padding: width < 650 ? '16px : 10px' : '16px 40px',
            }}
          >
            <div style={militaryStyle.downloadWrapper}>
              <div
                style={{
                  ...styles.flex,
                  ...styles.flexRow,
                  ...styles.alignItemsCenter,
                }}
              >
                <span style={{ marginRight: '20px', marginLeft: 10 }}>
                  <DownloadIcon />
                </span>
                <span style={styles.itemDesc}>Download Report</span>
              </div>
              <div
                style={{
                  ...styles.flex,
                  ...styles.flexRow,
                  ...styles.alignItemsCenter,
                  padding: '18px 0',
                }}
              >
                {false ? (
                  <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={35}
                    width={35}
                    style={{ margin: '16px 28px' }}
                  />
                ) : (
                  <>
                    <span
                      onClick={() => {
                        openUrl('doc');
                      }}
                      style={militaryStyle.docIcon}
                    >
                      <WordIcon />
                    </span>
                    <span
                      onClick={() => {
                        openUrl('pdf');
                      }}
                      style={militaryStyle.pdfIcon(width)}
                    >
                      <img
                        src={PdfIcon}
                        alt="Pdf File"
                        height={60}
                        width={60}
                      />
                    </span>
                  </>
                )}
              </div>
            </div>

            <div style={{ marginTop: '16px' }}>
              <div
                style={{
                  ...styles.flex,
                  ...styles.flexRow,
                  ...styles.alignItemsCenter,
                }}
              >
                <span style={{ marginRight: '20px' }}>
                  <ScanIcon />
                </span>

                <span style={styles.itemDesc}>Scan QR</span>
              </div>
              <div style={{ margin: '30px 0' }}>
                {pdfLink ? (
                  <center className="HpQrcode">
                    <QRCode
                      value={`${window.location.origin}/download-report?oid=${user?._id}`}
                    />
                  </center>
                ) : (
                  <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={35}
                    width={35}
                    style={{ margin: '16px 28px' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndMilXWalk;
