import React from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from "moment";

export default function BillingHistory(props) {
  const { user } = props;
  return (
    <div style={{
      width: "100%",
      overflow: "auto",
    }}>
      <table style={{ width: '100%' }}>
        <thead>
          <tr style={{
            backgroundColor: '#f5f5f5',
            fontWeight: 'bold',
            borderBottom: '1px solid #ddd',
            minWidth: 700,
          }}>
            <th style={{ width: '150px', padding: '16px 0', textAlign: 'center' }}>Date</th>
            <th>Description</th>
            <th>Invoice</th>
            <th style={{ textAlign: 'center' }}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {user?.payDetails?.invoices?.data?.length ?
            user?.payDetails?.invoices?.data?.map((invoice, index) => {
              return (
                <tr style={{
                  borderBottom: '1px solid #ddd',
                  minWidth: 700,
                }} key={index}>
                  <td style={{
                    padding: '16px 0', textAlign: 'center'
                  }}>
                    <span>
                      {moment(invoice?.created * 1000)?.format("l")}
                      {""}
                    </span>
                  </td>
                  <td>
                    <span>
                      {invoice?.lines?.data?.[0]?.description}
                    </span>
                  </td>
                  <td style={{
                    textAlign: 'center',
                    color: "#625DF5",
                    cursor: "pointer",
                  }}>
                    <span
                      onClick={() => {
                        window.open(invoice?.hosted_invoice_url, "_blank");
                      }}
                      style={
                        {
                          display: "flex",
                        }
                      }
                    >
                      <OpenInNewIcon color="#625DF5" fontSize="12" />
                      <span >Get Invoice</span>
                    </span>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <span>
                      ${parseInt(invoice?.lines?.data?.[0]?.amount) / 100}
                    </span>
                  </td>
                </tr>
              );
            }) :
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                <span style={{fontWeight: 'bold'}}>No Billing History</span>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
}