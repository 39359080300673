import { skillMatcherForScorer } from './skillMatcherForScorer';

export const skillsCount = (skillsData) => {
  const { hard_skills, soft_skills } = skillsData;

  let count_hard_skills_false = 0;
  let count_hard_skills_true = 0;
  let i;
  for (i in hard_skills) {
    if (hard_skills[i].exists) {
      count_hard_skills_true += 1;
    } else {
      count_hard_skills_false += 1;
    }
  }
  let count_soft_skills_false = 0;
  let count_soft_skills_true = 0;
  let j;
  for (j in soft_skills) {
    if (soft_skills[j].exists) {
      count_soft_skills_true += 1;
    } else {
      count_soft_skills_false += 1;
    }
  }

  const notExists = count_soft_skills_false + count_hard_skills_false;
  const exists = count_hard_skills_true + count_soft_skills_true;

  return {
    exists: exists,
    'not-exists': notExists,
    total: exists + notExists,
  };
};

export const matchJobTitle = (work, jobTitle = '') => {
  let res = JSON.stringify(work).toLowerCase().includes(jobTitle.toLowerCase());
  return res;
};

const getPointOfManagement = (deg = '') => {
  if (deg === 'low') {
    return 1;
  } else if (deg === 'low-or-no-level') {
    return 2;
  } else if (deg === 'low-level') {
    return 3;
  } else if (deg === 'mid') {
    return 4;
  } else if (deg === 'mid-level') {
    return 5;
  } else if (deg === 'high') {
    return 6;
  } else if (deg === 'high-level') {
    return 7;
  } else if (deg === 'executive-level') {
    return 8;
  }
  return 0;
};

const checkWorkExpirence = (
  job_level = getPointOfManagement().toLowerCase(),
  resume_level = getPointOfManagement().toLowerCase(),
) => {
  if (!job_level && !resume_level) {
    return 1;
  } else if (!job_level) {
    return 1;
  } else if (!resume_level) {
    return 2;
  } else if (resume_level > job_level) {
    return 3;
  }
  // For Saticification
  else if (job_level === resume_level) {
    return 4;
  }
  // For Fail
  else if (resume_level < job_level) {
    return 5;
  } else {
    return 0;
  }
};

const getPointOfNumber = (deg) => {
  if (deg === 'lessThanHighSchool') {
    return 1;
  } else if (deg === 'specialeducation') {
    return 2;
  } else if (deg === 'someHighSchoolOrEquivalent') {
    return 3;
  } else if (deg === 'ged') {
    return 4;
  } else if (deg === 'certification') {
    return 5;
  } else if (deg === 'vocational') {
    return 6;
  } else if (deg === 'secondary') {
    return 7;
  } else if (deg === 'highSchoolOrEquivalent') {
    return 8;
  } else if (deg === 'intermediategraduate') {
    return 9;
  } else if (deg === 'associates') {
    return 10;
  } else if (deg === 'international') {
    return 11;
  } else if (deg === 'professional') {
    return 12;
  } else if (deg === 'postprofessional') {
    return 13;
  } else if (deg === 'bachelors') {
    return 14;
  } else if (deg === 'masters') {
    return 15;
  } else if (deg === 'doctorate') {
    return 16;
  } else if (deg === 'postdoctorate') {
    return 17;
  }
};

const matchDegree = (required = '', education = []) => {
  if (!required && !education?.length) {
    return 1;
  } else if (!required) {
    return 1;
  } else if (!education?.length) {
    return 0;
  } else {
    let eduNumber =
      education?.map((edu) => getPointOfNumber(edu?.toLowerCase())) ?? [];
    let requiredNumber = getPointOfNumber(required?.toLowerCase());
    let result = eduNumber?.some((num) => num >= requiredNumber) ? 1 : 0;
    return result;
  }
};

const countMgmtLevel = (managementScore = 0) => {
  if (managementScore < 0) {
    return 'low-or-no-level';
  } else if (managementScore < 30) {
    return 'low-level';
  } else if (managementScore >= 30 && managementScore < 60) {
    return 'mid-level';
  } else if (managementScore >= 60) {
    return 'high-level';
  }
};

export const scoreCalculator = (allData, section_percentage) => {
  const resume = allData.resume.data;

  const job_parsed = allData.job_parsed;

  const { hard_skills, soft_skills } = skillMatcherForScorer(
    allData.resume.data.skills.items,
    allData.job_parsed.skills,
    allData.soft_skills.skills,
    allData.resume.data?.additional_skills?.items || [],
    allData.userDefinedJobTitle,
    allData.job_parsed.softSkills
  );
  var total_value = 0;
  //   hard skills counting
  let count_hard_skills_false = 0;
  let count_hard_skills_true = 0;
  let i;

  for (i in hard_skills) {
    if (hard_skills[i].exists) {
      count_hard_skills_true += 1;
    } else {
      count_hard_skills_false += 1;
    }
  }

  var core_skills = section_percentage;
  if (!allData?.job_parsed?.CertificationsAndLicenses?.length) {
    core_skills = {
      ...core_skills,
      certs: 0,
      core_skills: section_percentage.core_skills + section_percentage.certs,
    };
  }

  var hard_skills_percentage =
    (core_skills.core_skills /
      (count_hard_skills_false + count_hard_skills_true)) *
    count_hard_skills_true;

  let count_soft_skills_false = 0;
  let count_soft_skills_true = 0;
  let j;
  for (j in soft_skills) {
    if (soft_skills[j].exists) {
      count_soft_skills_true += 1;
    } else {
      count_soft_skills_false += 1;
    }
  }
  var soft_skills_percentage =
    (section_percentage.soft_skills /
      (count_soft_skills_false + count_soft_skills_true)) *
    count_soft_skills_true;

  if (isNaN(soft_skills_percentage)) {
    soft_skills_percentage = 0;
  }

  
  total_value = soft_skills_percentage + hard_skills_percentage;

  if (resume.certifications.items.length) {
  
    total_value += core_skills.certs;
  }


  if (resume.education.items.length) {
   
    total_value += section_percentage.education;
  }
  const score_skills = {
    exists: count_hard_skills_true,
    'not-exists': count_hard_skills_false,
    total: count_hard_skills_true + count_hard_skills_false,
  };

  const other_info = allData.resume?.other_info;

  const job_years = parseInt(job_parsed['MinimumYears']);
  var ats_data = {
    job_title_matched: {
      requires: 'unknown',
      shows: 'unknown',
      isMatched: false,
    },
    education_match: {
      requires: 'unknown',
      shows: 'N/A',
      isMatched: false,
    },
    certifications_match: {
      requires: 'unknown',
      shows: 'unknown',
      isMatched: resume.certifications.items.length ? true : false,
    },
    job_level_match: {
      EmployerNames: job_parsed.EmployerNames,
      years_required: job_years,
      message: '',
      years_showed:
        Math.ceil(
          parseInt(other_info?.experience_summary?.MonthsOfWorkExperience) / 12,
        ) || 'N/A',
      isMatched: true,
    },
    creative_bullets: other_info?.creative_bullets || [],
    manage_level: {
      message: '',
      isMatched: true,
      jobLevel: '',
      resumeLevel: '',
    },
    sections:
      other_info['other_sections']?.map(function (e) {
        return e?.HeaderTextFound;
      }) ?? [],
  };

  try {
    const job_managements = job_parsed['ManagementLevel'];
    const resume_managements = other_info?.experience_summary[
      'CurrentManagementLevel'
    ]
      ? other_info?.experience_summary['CurrentManagementLevel']
      : countMgmtLevel(other_info?.experience_summary['ManagementScore']);
    let ans_type = 'Unknown';
    let ans_manage_type = 'Unknown';
    const expience = checkWorkExpirence(job_managements, resume_managements);

    if (expience === 1) {
      ans_manage_type = `Unknown! Not found in the Job Description and/or Resume.`;
    } else if (expience === 2) {
      ans_manage_type = `Unknown! Not found in the Job Description and/or Resume.`;
      ats_data['manage_level']['isMatched'] = false;
    } else if (expience === 3) {
      ans_manage_type = `Excellent! The job is ${job_managements} level Management position and your resume shows ${resume_managements} management experience.`;
    } else if (expience === 4) {
      ans_manage_type = `Satisfactory! The job is ${job_managements} level Management position and your resume shows ${resume_managements} management experience.`;
    } else if (expience === 5) {
      ans_manage_type = `Higher-Position The job is ${job_managements} level Management and your resume shows ${resume_managements} management experience.`;
      ats_data['manage_level']['isMatched'] = false;
    } else {
      ans_manage_type = `Unknown! Not found in the Job Description and/or Resume.`;
      ats_data['manage_level']['isMatched'] = false;
    }
    ats_data['manage_level']['message'] = ans_manage_type;
    ats_data['manage_level']['jobLevel'] = job_managements;
    ats_data['manage_level']['resumeLevel'] = resume_managements;

    const user_resume_years = Math.ceil(
      parseInt(other_info?.experience_summary?.MonthsOfWorkExperience) / 12,
    );
    const job_years = parseInt(job_parsed['MinimumYears']);
    if (!user_resume_years && isNaN(job_years)) {
      ans_type = `Unknown! Not found in the Job Description and/or Resume.`;
    } else if (isNaN(job_years)) {
      ans_type = `Unknown! Not found in the Job Description and/or Resume.`;
    } else if (!user_resume_years) {
      ans_type = `Unknown! Not found in the Job Description and/or Resume.`;
      ats_data['job_level_match']['isMatched'] = false;
    } else if (user_resume_years > job_years) {
      ans_type = `Excellent! The job requires ${
        job_years || 'Unknown'
      } years of experience and your resume shows ${
        user_resume_years || 'Unknown'
      } years of experience.`;
    } else if (user_resume_years === job_years) {
      ans_type = `Satisfactory! The job  requires  ${
        job_years || 'Unknown'
      } years and your resume shows ${
        user_resume_years || 'Unknown'
      } years of experience.`;
    } else if (user_resume_years < job_years) {
      ans_type = `Higher-Position The job requires ${
        job_years || 'Unknown'
      } years of experience and your resume shows ${
        user_resume_years || 'Unknown'
      }  years of experience.`;
      ats_data['job_level_match']['isMatched'] = false;
    } else {
      ans_type = `Unknown! Not found in the Job Description and/or Resume.`;
      ats_data['job_level_match']['isMatched'] = false;
    }

    ats_data['job_level_match']['message'] = ans_type;
  } catch (error) {
  }

  // Job title Calculation
  if (allData.userDefinedJobTitle) {
    total_value += section_percentage.title;
  }
  try {
    let w;
    for (w in resume['work']['items']) {
      if (
        job_parsed['MainJobTitle'].toLowerCase() ===
        resume['work']['items'][w]?.position.toLowerCase()
      ) {
   
        total_value += section_percentage.title;
        ats_data['job_title_matched']['requires'] = job_parsed['MainJobTitle'];
        ats_data['job_title_matched']['shows'] = resume['work']['items']
          .map((n) => n['position'])
          .join(',');
        ats_data['job_title_matched']['isMatched'] = true;
        break;
      }
    }
  } catch (error) {
  }

  if (!ats_data['job_title_matched']['isMatched']) {
    ats_data['job_title_matched']['requires'] = job_parsed['MainJobTitle'];
  }

  // const education = resume.education.items.map(function (e) {
  //   return e.title;
  // });
  const educationType = resume.education.items.map(function (e) {
    return e.type;
  });
  const degree_calculation =
    matchDegree(job_parsed['RequiredDegree'], educationType) ?? 'Unknown';
  if (degree_calculation === 1) {
    ats_data['education_match']['requires'] = job_parsed['RequiredDegree'];
    ats_data['education_match']['shows'] = educationType.join(',');
    ats_data['education_match']['isMatched'] = true;
  } else if (degree_calculation === 0) {
    ats_data['education_match']['requires'] = job_parsed['RequiredDegree'];
    if (educationType?.length) {
      ats_data['education_match']['shows'] = educationType.join(',');
    }
  } else {
    ats_data['education_match']['requires'] = job_parsed['RequiredDegree'];
    ats_data['education_match']['shows'] = 'N/A';
  }
  if(ats_data?.manage_level?.isMatched){
    total_value += section_percentage?.mgmt_level
  }
  if(ats_data?.job_level_match?.isMatched){
    total_value += section_percentage?.min_years_exp
  }

  return { total_value: total_value.toFixed(0), score_skills, ats_data };
};
