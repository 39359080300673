import { sampleData, softSkills } from '../constants/sampleData';

export const skillMatcherForScorer = (
  resume_skills = sampleData.resume.data.skills.items,
  job_skills = sampleData.job_parsed.skills,
  soft_skills = softSkills,
  resume_additional_skills = sampleData.resume.data.additional_skills.items,
  userDefinedJobTitle,
  job_parsed_softSkills = []
) => {
  job_parsed_softSkills = job_parsed_softSkills?.map((skill)=>skill.toLowerCase())
  // Resume Skills

  const resumeSkills = resume_skills.reduce(
    //eslint-disable-next-line
    (a, b) => ((a[b.toLowerCase()] = {}), a),
    {},
  );

  // Resume Additional Skills
  let resumeAdditionalSkills = {};
  try {
    resume_additional_skills.forEach((a) => {
      if (a.name) resumeAdditionalSkills[a.name.toLowerCase()] = {};
    });
  } catch (error) {
  }
  // Job Skills
  const jobSkills = job_skills.reduce(
    //eslint-disable-next-line
    (a, b) => ((a[b.toLowerCase()] = {}), a),
    {},
  );
  const softSkills = soft_skills.reduce(
    //eslint-disable-next-line
    (a, b) => ((a[b.toLowerCase()] = {}), a),
    {},
  );

  let skill;
  let final_skills = {
    hard_skills: {},
    soft_skills: {},
  };

  for (skill in jobSkills) {
    if (skill.toLowerCase() in resumeSkills) {
      if (soft_skills.indexOf(skill.toLowerCase()) !== -1) {
        final_skills['soft_skills'][skill] = {
          exists: true,
        };
      } else {
        final_skills['hard_skills'][skill] = {
          exists: true,
        };
      }
    } else if (skill.toLowerCase() in resumeAdditionalSkills) {
      if (soft_skills.indexOf(skill.toLowerCase()) !== -1) {
        final_skills['soft_skills'][skill] = {
          exists: true,
        };
      } else {
        final_skills['hard_skills'][skill] = {
          exists: true,
        };
      }
    } else {
      if (soft_skills.indexOf(skill.toLowerCase()) !== -1) {
        final_skills['soft_skills'][skill] = {
          exists: false,
        };
      } else {
        final_skills['hard_skills'][skill] = {
          exists: false,
        };
      }
    }
  }

  for (skill in softSkills) {
    if (skill.toLowerCase() in resumeAdditionalSkills) {
      final_skills['soft_skills'][skill] = {
        exists: true,
      };
    } else {
      final_skills['soft_skills'][skill] = {
        exists: true,
      };
    }
  }
  return final_skills;
};
