import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import MaterialButton from '@material-ui/core/Button';
import { Button as MButton, makeStyles } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { styles } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserAccount } from './redux/action';
import { useHistory } from 'react-router-dom';
import { colors } from '../../commonComponents/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import Lottie from 'lottie-react';
import sadEmoji from '../../assets/animations/sadEmoji.json';
import toastr from 'toastr';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#4267b3',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4267b3',
      color: 'white',
    },
  },
}));
export default function DeleteAccount(props) {
  const style = useStyles();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.authUser.user);
  const cb = (ok) => {
    if (ok === 'OK') {
      setLoader(false);
    }
  };

  function downgradeAccount() {

    if (user?.pay_type === 'ApplePay') {
      toastr.error('Subscriptions can only be cancelled from your IOS device');
      return;
    }
    else if (user?.pay_type === 'GooglePay') {
      toastr.error('Subscriptions can only be cancelled from your Android device');
      return;
    } else {
      setLoader(true);
      dispatch(deleteUserAccount(history, cb, user));
    }
  }

  return (
    <div className="change_font_style">
      {user?.subscription && (
        <>
          <div style={styles.fontWeight_bold}>Downgrade to Free Account</div>
          <p style={{paddingRight: 5 }}>
            You will loose all your scan history & your subscription will be
            cancelled
          </p>
        </>
      )}

      <div style={styles.bb_ligthGrey}></div>
      <Row style={(styles.mt_30, { marginLeft: 5, marginRight: 5 })}>
        <Col lg="12" style={{ ...styles.mb_10, ...styles.textAlignCenter }}>
          {!user?.subscription ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <h5>You are already using free plan.</h5>

              <MaterialButton
                onClick={() => props.handleChange('', 1)}
                size="medium"
                // color="secondary",
                className={style.button}
                style={{
                  ...styles.mb_5,
                }}
              >
                {'Upgrade Subsrciption'}
              </MaterialButton>
            </div>
          ) : (
            <MButton
              color="secondary"
              variant="contained"
              onClick={() => {
                setShowModal(true);
              }}
              style={styles.saveEduBtn}
            >
              {loader ? (
                <BeatLoader
                  css={override}
                  sizeUnit={'px'}
                  size={10}
                  color={'white'}
                  loading={true}
                />
              ) : (
                'Downgrade'
              )}
            </MButton>
          )}
        </Col>
      </Row>
      <Dialog
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          overflow: 'hidden',
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
            paddingTop: '10px',
          }}
        >
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => {
              setShowModal(false);
            }}
          />
        </div>
        <DialogContent
          style={{
            height: 390,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Lottie
            animationData={sadEmoji}
            style={{
              height: 165,
              width: 165,
            }}
          />

          <div
            style={{
              fontSize: 19,
              fontWeight: '700',
            }}
          >
            You will lose all your account data & history and your subscription
            will be cancelled.
          </div>
          <MButton
            style={{
              backgroundColor: colors.blue,
              width: 128,
              height: 37,
              borderRadius: 35,
              marginTop: 69,
              fontSize: '14px',
              color: colors.white,
            }}
            autoFocus
            onClick={() => {
              setShowModal(false);
              downgradeAccount();
            }}
          >
            Proceed
          </MButton>
        </DialogContent>
      </Dialog>
    </div>
  );
}
