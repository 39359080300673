import { DOCUMENT_EDITOR_INITIALIZE, UPDATE_FILE_NAME } from "./constants";

let initialState = {
    docEditor: null,
    fileName: "Resumás",
};

function docEditorReducer(state = initialState, action) {
    switch (action.type) {
        case DOCUMENT_EDITOR_INITIALIZE:
            return {
                ...state,
                docEditor: action.payload.docEditor,
            };
        case UPDATE_FILE_NAME:
            return {
                ...state,
                fileName: action.payload.fileName,
            };
        default:
            return state;
    }
}

export default docEditorReducer;