import React from 'react'

export default function ScanIcon() {
    return (
        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 10.5H0.5C0.2 10.5 0 10.3 0 10C0 9.7 0.2 9.5 0.5 9.5H22.5C22.8 9.5 23 9.7 23 10C23 10.3 22.8 10.5 22.5 10.5Z" fill="#4A4A4A" />
            <path d="M8 19.5H2.5V14C2.5 13.7 2.7 13.5 3 13.5C3.3 13.5 3.5 13.7 3.5 14V18.5H8C8.3 18.5 8.5 18.7 8.5 19C8.5 19.3 8.3 19.5 8 19.5Z" fill="#4A4A4A" />
            <path d="M20.5 19.5H15C14.7 19.5 14.5 19.3 14.5 19C14.5 18.7 14.7 18.5 15 18.5H19.5V14C19.5 13.7 19.7 13.5 20 13.5C20.3 13.5 20.5 13.7 20.5 14V19.5Z" fill="#4A4A4A" />
            <path d="M20 6.5C19.7 6.5 19.5 6.3 19.5 6V1.5H15C14.7 1.5 14.5 1.3 14.5 1C14.5 0.7 14.7 0.5 15 0.5H20.5V6C20.5 6.3 20.3 6.5 20 6.5Z" fill="#4A4A4A" />
            <path d="M3 6.5C2.7 6.5 2.5 6.3 2.5 6V0.5H8C8.3 0.5 8.5 0.7 8.5 1C8.5 1.3 8.3 1.5 8 1.5H3.5V6C3.5 6.3 3.3 6.5 3 6.5Z" fill="#4A4A4A" />
        </svg>
    )
}
