import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { educationInfo } from './redux/action';
import { currentUser } from '../auth/redux/actions';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { Select } from 'antd';
import { styles } from './style';
import TagsInput from 'react-tagsinput';
import { colors } from '../../commonComponents/colors';

const { Option } = Select;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Education extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownBasicOpen: false,
      dropDownValue: 'Select highest level of education',
      dropDownValue2: 'Select job Type',
      state: false,
      firstMajor: [],
      secondMajor: [],
      tags: ['nodejs', 'reactjs', 'php'],
      show_skills: false,

      skills: [],
    };
  }

  UNSAFE_componentWillMount() {
    let { user } = this.props;
    if (user) {
      let education = 'Select highest level of education';
      let firstMajor = [];
      let secondMajor = [];
      // eslint-disable-next-line
      let skills = [];
      // eslint-disable-next-line
      let job_location = [];
      if (user.education) {
        education = user.education;
      }

      if (user.skills) {
        skills = user.skills;
      }
      if (user.job_location) {
        job_location = user.job_location;
      }
      if (user.firstMajor) {
        firstMajor = user.firstMajor;
      }
      if (user.secondMajor) {
        secondMajor = user.secondMajor;
      }
      this.setState({
        dropDownValue: education,
        firstMajor: Array.isArray(firstMajor) ? firstMajor : [],
        secondMajor: Array.isArray(secondMajor) ? secondMajor : [],
      });
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.profileStatus === 'done') {
      this.props.currentUser();
      this.setState({ loader: false });
    }
    if (nextProps.profileStatus === 'not done') {
      this.setState({ loader: false });
    }
  };

  toggleBasic = () => {
    this.setState((prevState) => ({
      dropdownBasicOpen: !prevState.dropdownBasicOpen,
    }));
  };
  changeValue = (e) => {
    this.setState({ dropDownValue: e });
  };

  saveInfo = () => {
    this.setState({ loader: true });
    this.props.educationInfo({
      education: this.state.dropDownValue,
      firstMajor: this.state.firstMajor,
      secondMajor: this.state.secondMajor,
    });
  };
  render() {
    return (
      <div className="change_font_style">
        <div style={styles.fontWeight_bold}>EDUCATION</div>
        <span style={styles.eduSpan}>
          Education can help us provide more detailed analysis and recomended
          better jobs for you
        </span>
        <div style={styles.bb_ligthGrey}></div>
        <Row style={(styles.mt_30, { marginLeft: 5, marginRight: 5 })}>
          <Col sm="12" lg="12" xs="12" md="12" style={styles.mb_10}>
            <span>Specify:</span>
            <div
              style={{
                width: '100',
                border: '1px solid #ccc',
                paddingTop: 6,
                paddingBottom: 6,
              }}
            >
              <Select
                showSearch
                bordered={false}
                style={{
                  width: '100%',
                  borderColor: colors.red,
                }}
                optionFilterProp="children"
                value={this.state.dropDownValue}
                onChange={this.changeValue}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Undergraduate">Undergraduate</Option>
                <Option value="Postgraduate">Postgraduate</Option>
                <Option value="PhD">PhD</Option>
                <Option value="Post Doctorate">Post Doctorate</Option>
              </Select>
            </div>
          </Col>

          <Col lg="6" sm="12" md="6" xs="12" style={styles.mb_10}>
            <span>First Major:</span>
            <TagsInput
              style={styles.tagInput}
              value={this.state.firstMajor}
              onChange={(tags) => {
                this.setState({
                  firstMajor: tags,
                });
              }}
              addOnBlur
              inputProps={{
                placeholder: '',
                onChange: (e) => {
                  if (!this.state.show_skills) {
                    this.setState({
                      show_skills: true,
                    });
                  }
                  if (!e.target.value.replace(/\s/g, '').length) {
                    this.setState({
                      show_skills: false,
                    });
                  }
                },
              }}
            />
          </Col>
          <Col lg="6" sm="12" md="6" xs="12" style={styles.mb_10}>
            <span>Second Major:</span>
            <TagsInput
              style={styles.tagInput}
              value={this.state.secondMajor}
              onChange={(tags) => {
                if (this.state.show_skills) {
                  this.setState({
                    show_skills: false,
                  });
                }
                this.setState({
                  secondMajor: tags,
                });
              }}
              addOnBlur
              inputProps={{
                placeholder: '',
                onChange: (e) => {
                  if (!this.state.show_skills) {
                    this.setState({
                      show_skills: true,
                    });
                  }
                  if (!e.target.value.replace(/\s/g, '').length) {
                    this.setState({
                      show_skills: false,
                    });
                  }
                },
              }}
            />
          </Col>

          <Col lg="12" style={{ ...styles.mb_10, ...styles.textAlignCenter }}>
            <Button
              className="save_education_butn"
              onClick={this.saveInfo}
              style={styles.saveEduBtn}
            >
              {this.state.loader ? (
                <BeatLoader
                  css={override}
                  sizeUnit={'px'}
                  size={10}
                  color={'white'}
                  loading={true}
                />
              ) : (
                'Save'
              )}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = ({ authUser, profileReducer }) => {
  return {
    profileStatus: profileReducer.profileStatus,
    updator: profileReducer.updator,
    user: authUser.user,
  };
};

export default connect(mapStateToProps, { educationInfo, currentUser })(
  Education,
);
