import React from 'react';
import { Text, Dnd } from '../../component';
import { useStyles } from '../style';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateOrder, addNewObj, deleteObjInArray } from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import { colors } from '../../../../commonComponents/colors';

export default function Associations(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'associations.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;

  const handledData = (() => {
    if (data?.length === 0) {
      return [{ Name: '' }];
    }
    return data;
  })();

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ fontSize: 16, fontWeight: 'bold' }}
          >
            Associations
          </label>
        </div>
        <Dnd
          data={handledData}
          reorder={(e) => onOrderUpdate(e)}
          additem={_addNewItem}
          removeitem={(index) => _removeItem(index)}
          renderItem={(item, index) => (
            <div>
              <label className={classes.label}>Association Name</label>
              <Text
                value={item?.Name}
                placeholder="Association Name"
                customclass={classes.input}
                path={`${path}.${index}.Name`}
              />
            </div>
          )}
        />
        <div
          onClick={_addNewItem}
          style={{
            color: colors.blue,
            marginTop: 40,
            fontWeight: 'bold',
            fonSize: 18,
            cursor: 'pointer',
          }}
        >
          + Add one more association
        </div>
      </Grid>
    </>
  );
}
