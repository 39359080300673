import React, { Fragment, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { styles } from './style';
import { useWindowWidth } from '@react-hook/window-size';
import { useDispatch, useSelector } from 'react-redux';
import { AddSuggestions } from '../../commonRedux/scan/action';
import Megaphone from '../../assets/megaphone.webp';
import Arrow from '../../assets/arrow.webp';
import SolrAutoSuggestion from '../../commonComponents/jobSuggestion';
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';
import { GetStepImage } from '../../utils/GetStepImage';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function FineTuningCard(props) {
  const width = useWindowWidth();
  const dispatch = useDispatch();
  let CardStyles = {};
  let headingStyle = {};
  const [hoverItem, setHoverItem] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const { addedSuggestions, suggestionLoader, selectedTitle } = useSelector(
    (store) => store.fileScanReducer,
  );
  React.useEffect(() => {
    setJobTitle(selectedTitle);
  }, [selectedTitle]);
  const suggestionArr = props.suggestions?.filter(
    (item) => item !== ' ' && item !== '',
  );
  // props?.splited === 'splited'
  //   ? props?.suggestionsSkill
  //   : props?.suggestions?.split('|');
  const handleAddSkill = (newSuggestion, cardTitle) => {
    let updateData = [];
    if (addedSuggestions?.length) {
      const isIncluded = addedSuggestions.find(
        (item) =>
          item.title?.trim()?.toLowerCase() ===
          cardTitle?.trim()?.toLowerCase(),
      );
      if (isIncluded) {
        const index = addedSuggestions.findIndex(
          (item) => item.title === cardTitle,
        );
        if (addedSuggestions[index].suggestions.includes(newSuggestion)) {
          const suggestionIndex =
            addedSuggestions[index].suggestions.indexOf(newSuggestion);
          const suggestionArrLength =
            addedSuggestions[index].suggestions.length;
          if (suggestionArrLength === 1) {
            addedSuggestions.splice(index, 1);
          } else {
            addedSuggestions[index].suggestions.splice(suggestionIndex, 1);
          }
          updateData = addedSuggestions;
        } else {
          addedSuggestions[index].suggestions = [
            ...addedSuggestions[index].suggestions,
            newSuggestion,
          ];

          updateData = addedSuggestions;
        }
      } else {
        updateData = [
          ...addedSuggestions,
          {
            title: cardTitle,
            suggestions: [newSuggestion],
          },
        ];
      }
    } else {
      updateData = [{ title: cardTitle, suggestions: [newSuggestion] }];
    }
    const data = {
      oid: props.oid,
      data: updateData,
    };

    dispatch(
      AddSuggestions(data, (val) => {
        props.setSuggestionsLoader && props.setSuggestionsLoader(val);
      }),
    );
  };
  const markedCheckHandler = (suggestion, cardTitle) => {
    if (addedSuggestions.length) {
      const isIncluded = addedSuggestions.find(
        (item) =>
          item.title?.trim()?.toLowerCase() ===
          cardTitle?.trim()?.toLowerCase(),
      );
      if (isIncluded) {
        const index = addedSuggestions.findIndex(
          (item) => item.title === cardTitle,
        );
        if (addedSuggestions[index].suggestions.includes(suggestion)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <div style={{ marginBottom: 7 }}>
      <Card>
        <CardBody
          style={{
            background: props.showInfoCard === false ? 'white' : '#EDF0F7',
            border:
              props.showInfoCard === false ? 'none' : '1px solid lightgray',
            ...CardStyles,
            overflowX: 'auto',
            padding: 15,
          }}
        >
          {props.cardTitle === 'Industry Skills' ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <GetStepImage step={4} style={{ marginLeft: '30px' }} />

                <div
                  style={{
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <img
                      alt=""
                      src={Megaphone}
                      style={{
                        width: 40,
                        height: 40,
                        marginRight: 20,
                        color: '#576baa',
                        backgroundColor: '"#576baa"',
                      }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        marginTop: 8,
                        color: '#576baa',
                        fontSize: 12,
                        fontWeight: 'bold',
                      }}
                    >
                      In this step, your goal is to identify and fix any skill
                      gaps that may be related to the job title within your
                      particular industry.
                    </p>
                  </div>
                </div>
              </div>
              <img
                alt=""
                src={Arrow}
                style={{
                  position: 'absolute',
                  bottom: -30,
                  left: 80,
                  width: 40,
                  height: 50,
                  zIndex: 99,
                  marginRight: 20,
                  color: '#576baa',
                  backgroundColor: '"#576baa"',
                }}
              />
            </>
          ) : (
            ''
          )}
          <CardTitle
            style={{
              maxWidth: width < 500 ? '220px' : '400px',
              ...styles.fontWeight_heading,
              ...headingStyle,
              marginBottom: 7,
            }}
          >
            {props.cardTitle}
          </CardTitle>
          <div style={{ margin: '10px 0' }}>
            <SolrAutoSuggestion
              onChangeTitle={(title) => setJobTitle(title)}
              value={jobTitle}
            />
          </div>
          <div
            style={{
              height: 360,
              overflow: 'hidden',
              minWidth: 300,
              marginTop: 10,
            }}
          >
            {suggestionLoader ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <BeatLoader
                  css={override}
                  sizeUnit={'px'}
                  size={15}
                  color={'orange'}
                  loading={true}
                />
              </div>
            ) : (
              <Fragment>
                {selectedTitle ? (
                  <p>
                    Showing {suggestionArr?.length} results for{' '}
                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                      {selectedTitle}
                    </span>
                  </p>
                ) : (
                  <p>Ready-to-use suggestions</p>
                )}
                <div style={{ overflowY: 'auto', height: 300 }}>
                  {suggestionArr?.map((item, index) => {
                    const checkItem = markedCheckHandler(
                      item,
                      props.cardTitleKey,
                    );
                    return (
                      <Fragment key={index}>
                        <div
                          key={index}
                          onMouseEnter={() => setHoverItem(item)}
                          onMouseLeave={() => setHoverItem('')}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 10,
                            ...(hoverItem === item && {
                              backgroundColor: '#DBDEE5',
                            }),
                            ...(checkItem && { opacity: 0.5 }),
                            ...(props.loading && { opacity: 0.5 }),
                          }}
                        >
                          <div
                            onClick={() =>
                              !props.loading &&
                              handleAddSkill(item, props.cardTitleKey)
                            }
                            aria-disabled={props.loading}
                            style={{
                              width: '28px',
                              height: '28px',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              background: '#4267b2',
                              marginRight: 5,
                              cursor: 'pointer',
                            }}
                          >
                            {checkItem ? (
                              <CheckIcon
                                style={{ color: '#FFFFFF', fontSize: 20 }}
                              />
                            ) : (
                              <AddIcon
                                style={{ color: '#FFFFFF', fontSize: 20 }}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              marginLeft: 20,
                              color: '#285D8F',
                            }}
                          >
                            {item}
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0.2px',
                            backgroundColor: '#FFFFFF',
                            opacity: 0.4,
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </Fragment>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
