import React, { useState } from 'react';
import { Button, Input } from 'antd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import toastr from 'toastr';
import { styles } from './style';
import CancelIcon from '@material-ui/icons/Cancel';
import { data } from '../../constants/coverLetterTemplate';
import { useSelector } from 'react-redux';
import LockIcon from '@material-ui/icons/Lock';
import TagsInput from 'react-tagsinput';
import { InputGroup } from 'reactstrap';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import CoverLetterEditor from '../scan/coverLetterEditor';
import moment from 'moment';
import { colors } from '../../commonComponents/colors';
import './style.css';
// var coverLetterTemplate = require("../../constants/coverTemplate.html");
// let convertedNotification = '';

function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [tags, setTags] = React.useState([]);
  const [newText, setNewText] = useState('');
  const [clearText, setClearText] = useState(0);
  const [convertedNotification, setconvertedNotification] = React.useState('');
  const user = useSelector((state) => state.authUser.user);

  const handleClickOpen = () => {
    if (user?.subscription === false) {
      toastr.error('Please purchase subscription to add cover letters!');
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClear = () => {
    setconvertedNotification('');
    // convertedNotification = '';
    setTags([]);
    setTitle('');
    setClearText(clearText + 1);
  };
  const updateText = (text) => {
    setNewText(text);
  };

  React.useEffect(() => {
    if (props.resumeDescription.trim() !== '') {
      setLoader(false);
      // convertedNotification =;
      setconvertedNotification(`<pre>${props.resumeDescription}</pre>`);
    }
  }, [props.resumeDescription]);

  const handleFile = (e) => {
    if (['pdf'].includes(e.target.files[0].type.split('/')[1])) {
      props.fileParser(e.target.files[0]);
      setLoader(true);
    } else if (['docx'].includes(e.target.files[0].name.split('.')[1])) {
      props.fileParser(e.target.files[0]);
      setLoader(true);
    } else if (['doc'].includes(e.target.files[0].name.split('.')[1])) {
      props.fileParser(e.target.files[0]);
      setLoader(true);
    } else {
      toastr.error('please upload pdf, doc, docx and txt files only');
    }
  };

  const handleLoadTemplate = () => {
    let datetoday = moment().format('MM-DD-YYYY');
    // convertedNotification = data(
    //   user?.firstName + ' ' + user?.lastName,
    //   datetoday,
    //   user?.mobile,
    //   user?.email,
    // ).template;
    setconvertedNotification(
      data(
        user?.firstName + ' ' + user?.lastName,
        datetoday,
        user?.mobile,
        user?.email,
      ).template,
    );
    setNewText(
      data(
        user?.firstName + ' ' + user?.lastName,
        datetoday,
        user?.mobile,
        user?.email,
      ).template,
    );

    setClearText(clearText + 1);
  };

  const handleSubmit = () => {
    if (title === '') {
      toastr.error('Please add title!');
    } else if (newText === '') {
      toastr.error('Please add description!');
    } else {
      let data = {
        title: title,
        description: newText,
        tags: tags,
      };

      props.addCoverLetter(data);
      handleClear();
      handleClose();
    }
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{
          color: 'white',
          backgroundColor: '#4267B2',
          paddingLeft: 34,
          border: 0,
          paddingRight: 34,
          height: 38,
          borderRadius: 5,
        }}
      >
        {user?.subscription === false && (
          <LockIcon
            style={{
              position: 'absolute ',
              right: 5,
              top: 5,
              fontSize: 12,
            }}
          />
        )}
        Add
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div
            style={{
              width: '100%',

              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: 20,
                fontFamily: 'Nunito',
              }}
            >
              Add New Cover Letter
            </span>
            <span>
              <span
                className="image-upload mr-3 p-1 "
                style={{
                  borderRadius: 5,
                  background: colors.blue,
                }}
              >
                <label htmlFor="file-input" style={styles.imgUploadModel}>
                  Upload Cover Letter
                </label>

                <input id="file-input" type="file" onChange={handleFile} />
              </span>
              <span
                className="image-upload mr-5 p-1"
                style={{
                  borderRadius: 5,
                  background: colors.blue,
                }}
              >
                <label
                  // for="file-input"
                  style={styles.imgUploadModel}
                  onClick={handleLoadTemplate}
                >
                  Start from template
                </label>
              </span>
            </span>
          </div>
        </DialogTitle>
        <span onClick={handleClose}>
          <CancelIcon style={styles.closeBtn} />
        </span>
        <DialogContent>
          <Input
            value={title}
            placeholder="Add Title"
            onChange={(e) => setTitle(e.target.value)}
            style={{
              height: '40px',
            }}
          />
          <InputGroup style={{ width: '100%', marginTop: 10 }}>
            {/* <InputGroupAddon addonType="prepend">
              <span className="input-group-text">Tags</span>
            </InputGroupAddon> */}

            <TagsInput
              value={tags}
              onChange={(tags) => {
                setTags(tags);
              }}
              addOnBlur
              inputProps={{
                id: 'title',
                placeholder: 'Add Tags',
              }}
            />
          </InputGroup>
          <div style={styles.inputModel}>
            {/* <Col lg="12" style={{ padding: 0 }}> */}

            <CoverLetterEditor
              key={clearText}
              onSubmitFun={updateText}
              loader={loader}
              defaultText={convertedNotification}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              // backgroundColor: colors.blue,
              // color: "white",
              borderRadius: 5,
              padding: '5px 30px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleClear}
            color="primary"
          >
            Clear
          </Button>
          <Button
            style={{
              backgroundColor: colors.blue,
              color: 'white',
              borderRadius: 5,
              padding: '5px 30px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleSubmit}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;
