// React Basic and Bootstrap
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Alert } from "reactstrap";
import { validateEmail } from "../../../utils/emailValidation";
import { useDispatch } from "react-redux";
// import images
import { css } from "@emotion/core";

import recoveryimg from "../../../assets/recovery.webp";
import { forgetPassword } from "../redux/actions";
import { BeatLoader } from "react-spinners";
import toastr from "toastr";
import Footer from "../../layout/authLayout/Footer";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const PageRecoveryPassword = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoader] = useState("");
  const [Contactvisible, setContactvisible] = useState(false);

  const getValue = (e) => {
    setEmail(e.target.value);
  };

  const callBack = (success) => {
    if (success) {
      setContactvisible(true);
    }
    setLoader(false);
  };

  const onGoBack = () => {
    props.history.push("/");
  };

  const onSubmit = () => {
    if (loading) {
      return;
    }
    if (email === "") {
      toastr.error("Email can not be empty");
    } else if (!validateEmail(email)) {
      toastr.error("Email is not valid");
    } else {
      setLoader(true);
      dispatch(
        forgetPassword(
          {
            email: email,
          },
          callBack
        )
      );
    }
  };
  return (
    <React.Fragment>
      <div className="back-to-home rounded d-sm-block p-3">
        <Link to="/" className="text-white rounded d-inline-block text-center">
          <i className="mdi mdi-home"></i>
        </Link>
      </div>
      <section style={{ height: "90vh" }} className="bg-home">
        <div className="home-center">
          <div className="container">
            <Row className="align-items-center">
              <Col lg={8} md={6}>
                <div className="mr-lg-5">
                  <img src={recoveryimg} className="img-fluid" alt="" />
                </div>
              </Col>
              <Col lg={4} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="login_page bg-white shadow  p-4">
                  <div className="text-center">
                    <h4 className="mb-4">
                      {Contactvisible ? "Email Sent" : "Recover Account"}
                    </h4>
                  </div>
                  <form className="login-form">
                    <Alert
                      color="info"
                      isOpen={Contactvisible}
                      toggle={onGoBack}
                      style={{ paddingRight: "30px" }}
                    >
                      We have sent the email to your address please check your
                      email address to change your passsword.
                    </Alert>
                    {!Contactvisible &&
                      <Row>
                        <Col lg={12}>
                          <p className="text-muted">
                            Please enter your email address. You will receive a
                            link to create a new password via email.
                          </p>
                          <div className="form-group position-relative">
                            <label>
                              Email address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <i className="mdi mdi-account ml-3 icons"></i>
                            <input
                              type="email"
                              className="form-control pl-5"
                              placeholder="Enter Your Email Address"
                              name="email"
                              value={email}
                              onChange={getValue}
                              required=""
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <button
                            type="button"
                            onClick={onSubmit}
                            className="btn btn-primary w-100"
                          >
                            {loading ?
                              <BeatLoader
                                css={override}
                                sizeUnit={"px"}
                                size={10}
                                color={"white"}
                                loading={true}
                              />
                              :
                              "Recover Password"
                            }
                          </button>
                        </Col>
                      </Row>
                    }
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <Footer removeContent />
    </React.Fragment>
  );
};
export default PageRecoveryPassword;
