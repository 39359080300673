import React from 'react';

export default function NewMessageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="24px"
      height="24px"
    >
      <path d="M 3 7 L 3 25 L 16.0625 25 C 16.5625 28.933594 19.933594 32 24 32 C 28.40625 32 32 28.40625 32 24 C 32 21.480469 30.828125 19.25 29 17.78125 L 29 7 Z M 7.3125 9 L 24.6875 9 L 16 14.78125 Z M 5 9.875 L 15.4375 16.84375 L 16 17.1875 L 16.5625 16.84375 L 27 9.875 L 27 16.59375 C 26.070313 16.210938 25.066406 16 24 16 C 19.933594 16 16.5625 19.066406 16.0625 23 L 5 23 Z M 24 18 C 27.324219 18 30 20.675781 30 24 C 30 27.324219 27.324219 30 24 30 C 20.675781 30 18 27.324219 18 24 C 18 20.675781 20.675781 18 24 18 Z M 23 20 L 23 23 L 20 23 L 20 25 L 23 25 L 23 28 L 25 28 L 25 25 L 28 25 L 28 23 L 25 23 L 25 20 Z" />
    </svg>
  );
}
