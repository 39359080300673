export const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-z  A-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
  if (phone?.length < 7) return false;
  var re =
    /^(?:(?:(?=\+\d{1,15}(?:,\s*|\s*)))|(?=\d{1,15}(?:,\s*|\s*)))\+?\d{1,15}(?:,\s*|\s*)?(?=\+?\d{1,15}$)(?!.{16})(?!.*\b\d{1,6}\b)/;
  return re.test(phone);
};
