import { useState } from 'react';
import toastr from 'toastr';
import { sendSupportMessage } from './redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { CONTACT_MODAL } from '../auth/redux/constants';
import { handleHelpModal } from '../layout/redux/actions';
import { useWindowSize } from '@react-hook/window-size';

export function useHelp() {
  const dispatch = useDispatch();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const user = useSelector((state) => state.authUser.user);
  const versions = useSelector((state) => state.profileReducer?.versions);
  const { helpModal } = useSelector((state) => state.menu);
  const [width] = useWindowSize();

  const handleFile = (e) => {
    e.persist();
    setFile(e.target.files[0]);
  };
  const handleModal = () => dispatch(handleHelpModal(false));
  const cb = (v) => {
    if (v === 'OK') {
      setSubject('');
      setMessage('');
      setFile('');
    }
    handleModal();
    dispatch({
      type: CONTACT_MODAL,
      payload: false,
    });
    setLoading(false);
  };
  const sendMessage = async (onClose) => {
    var files = new FormData();

    try {
      setLoading(true);

      const body = `${message}`;

      files.append('attachment', file);
      files.append(
        'data',
        JSON.stringify({
          name: user.firstName + ' ' + user.lastName,
          subject: subject,
          email: user.email,
          message: body,
          from: 'Web - Contact Support',
        }),
      );
      dispatch(sendSupportMessage(cb, files, onClose));
    } catch (error) {}
  };

  return {
    subject,
    message,
    loading,
    versions,
    helpModal,
    width,
    setSubject,
    setMessage,
    handleFile,
    sendMessage,
    setLoading,
    handleModal,
  };
}
