import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box, Grid } from '@material-ui/core';
import { colors } from '../../../commonComponents/colors';
import { useDispatch } from 'react-redux';
import { MILITARY_TAB_VALUE } from '../redux/actions.constants';
import useMilitaryCrosswalk from '../useMilitaryCrosswalk';
import { CommonCard } from './ReviewMilitary';

//Tab Panel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingY={2}>{children}</Box>}
    </div>
  );
}

// Tab Panel Props
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

//A11y Props
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

//Custom Tab Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

//Custom Tab
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    color: colors.fontGray,
    backgroundColor: colors.lightGray,
    borderBottom: `2px solid ${colors.blue}`,
    '&:focus': {
      opacity: 1,
      color: colors.blue,
    },
    '&:hover': {
      color: colors.blue,
      opacity: 1,
    },
    '&$selected': {
      backgroundColor: colors.blue,
      color: theme.palette.common.white,
      // border: `2px solid ${colors.blue}`, // Add a border to create a box effect
      borderTopLeftRadius: '4px', // Round the corners to create a box effect
      borderTopRightRadius: '4px',
    },
  },
  selected: {},
}))((props) => (
  <Tab
    disableRipple
    style={{
      marginRight: props?.index === 2 ? 0 : 8,
      fontSize: props?.width <= 375 ? 10 : 16,
    }}
    {...props}
  />
));

//Custom Tabs
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      display: 'none',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

//Main Component
export default function MillXWalkTabs({
  data,
  Component,
  tabValue,
  milXwalkDetail,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    selectedSubtitles,
    militaryData: { branch, code },
    milXwalkDetailExist,
    width,
  } = useMilitaryCrosswalk();
  const handleChange = (event, newValue) => {
    dispatch({
      type: MILITARY_TAB_VALUE,
      payload: newValue,
    });
  };
  return data ? (
    <div className={classes.root}>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          backgroundColor: colors.lightGray,
        }}
      >
        <tr style={{}}>
          <td
            style={{
              padding: '10px',
              textAlign: 'center',
              backgroundColor: colors.grey,
              color: 'black',
              width: '15%',
              fontSize: width <= 375 ? 12 : 15,
              borderRadius: '5px 5px 0 0',
            }}
          >
            <b>Military</b>
          </td>
          <td
            style={{
              padding: '10px',
              textAlign: 'left',
              fontSize: width <= 375 ? 12 : 15,
              verticalAlign: 'top',
            }}
          >
            <b>Branch:</b> {branch?.value || ''}
          </td>
          <td
            style={{
              padding: '10px',
              textAlign: 'left',
              fontSize: width <= 375 ? 12 : 15,
              verticalAlign: 'top',
            }}
          >
            <b> MOC Code / Title:</b> {code?.code || ''} - {code?.title || ''}
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: '10px',
              textAlign: 'center',
              backgroundColor: colors.grey,
              color: 'black',
              width: '15%',
              fontSize: width <= 375 ? 12 : 15,
              borderRadius: '0 0 5px 5px',
            }}
          >
            <b>Civilian</b>
          </td>
          <td
            colSpan="2"
            style={{
              padding: '10px',
              textAlign: 'left',
              fontSize: width <= 375 ? 12 : 15,
              verticalAlign: 'top',
            }}
          >
            <b>Job Occupation:</b> {selectedSubtitles[0]?.subTitle}
          </td>
        </tr>
      </table>

      {milXwalkDetailExist && (
        <Grid container spacing={3} style={{ padding: '22px 0px' }}>
          <Grid xs={12} sm={12} lg={6} item>
            <CommonCard
              cardData={[milXwalkDetail?.Job_Occupation_Summary]}
              title={'Job Occupation Summary'}
            />
          </Grid>
          <Grid xs={12} sm={12} lg={6} item>
            <CommonCard
              cardData={milXwalkDetail?.Job_Education_Profile}
              title={'Job Education Profile'}
              type={'slider'}
              width={width}
            />
          </Grid>
          <Grid xs={12} sm={6} lg={6} item>
            <CommonCard
              cardData={milXwalkDetail?.Job_Task}
              title={'Job Tasks'}
            />
          </Grid>
          <Grid xs={12} sm={6} lg={6} item>
            <CommonCard
              cardData={milXwalkDetail?.Job_Data}
              title={'Job Data'}
              type={'keyvalue'}
              sourceText={
                <div>
                  Source:{' '}
                  <a
                    style={{ color: '#007bff', textDecoration: 'underline' }}
                    href="https://www.resumas.com"
                    target="_blank"
                  >
                    Resumas.com{' '}
                  </a>
                  proprietary algorithm; Department of Labor O*NET Data; Bureau
                  of Labor Statistics 2022 data{' '}
                  <a
                    style={{ color: '#007bff', textDecoration: 'underline' }}
                    href="https://www.bls.gov/oes/"
                    target="_blank"
                  >
                    BLS OES{' '}
                  </a>
                  Bureau of Labor Statistics Projections data 2021-2031
                  <a
                    style={{ color: '#007bff', textDecoration: 'underline' }}
                    href="https://www.bls.gov/emp/"
                    target="_blank"
                  >
                    {' '}
                    BLS Emp{' '}
                  </a>
                </div>
              }
            />
          </Grid>
        </Grid>
      )}
      <StyledTabs
        variant="fullWidth"
        value={tabValue}
        onChange={handleChange}
        aria-label="job occupation tabs"
      >
        {Object?.entries(data)?.map(([key, value], index) => (
          <StyledTab
            key={index}
            label={key}
            {...a11yProps(index)}
            index={index}
            width={width}
          />
        ))}
      </StyledTabs>
      {Object.entries(data)?.map(([key, value], index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          <Component data={value} />
        </TabPanel>
      ))}
    </div>
  ) : (
    'No data found'
  );
}
