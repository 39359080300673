import React from 'react';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import ReactPlayer from 'react-player';
import { Close } from '@material-ui/icons';
import { colors } from './colors';

export default function VideoModel({
  open = false,
  setOpen,
  closeAble = true,
  video = {
    autoPlay: true,
    loop: false,
    muted: false,
    closeAble: true,
    videoID: '630533349',
  },
  onEnded = () => {},
  ...props
}) {
  const width = useWindowWidth();
  const height = useWindowHeight();
  const isMobile = width < 540;
  const height750 = height < 750;
  const height670 = height < 670;

  return (
    <Dialog
      {...props}
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: isMobile
            ? 'calc(50% + 163px)'
            : height670
            ? 'calc(50% + 235px)'
            : height750
            ? 'calc(50% + 283px)'
            : 'calc(50% + 361px)',
          top: isMobile
            ? 'calc(50% - 85px)'
            : height670
            ? 'calc(50% - 122px)'
            : height750
            ? 'calc(50% - 150px)'
            : 'calc(50% - 192px)',
          transform: 'translate(-50%, -50%)',
          zIndex: 5,
        }}
      >
        <IconButton
          onClick={() => {
            setOpen(false);
            onEnded();
          }}
          style={{
            background: colors.blue,
            padding: '5px',
          }}
        >
          <Close
            style={{
              fontSize: '20px',
              color: 'white',
            }}
          />
        </IconButton>
      </div>
      <DialogContent
        style={{
          backgroundColor: 'transparent',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReactPlayer
          url={`https://player.vimeo.com/video/${video?.videoID}`}
          width={isMobile ? 300 : height670 ? 480 : height750 ? 540 : 700}
          height={isMobile ? 205 : height670 ? 280 : height750 ? 340 : 450}
          style={{
            paddingTop: '30px',
            position: 'relative',
          }}
          config={{
            playerOptions: {
              autoplay: video?.autoPlay,
              loop: video?.loop,
              autopause: false,
              muted: video?.muted,
              controls: video?.showControls,
            },
            vimeo: {
              title: 'Resumás',
            },
          }}
          onEnded={() => {
            setOpen(false);
            onEnded();
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
