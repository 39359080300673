import React, { Component } from 'react';
import { Progress as CircleProgress } from 'react-sweet-progress';
import { Progress, Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx } from '../../../commonComponents/customBootstrap';

import { MdDone, MdClear } from 'react-icons/md';
import { styles } from '../style';
import { colors } from '../../../commonComponents/colors';

export default class Jobmatchrate extends Component {
  state = {
    score: 0,
  };

  componentDidMount = () => {
    this.setState({
      score: this.props.matchRate.totalScore,
    });
  };
  render() {
    return (
      <div>
        <Row>
          <Colxx
            lg={{
              size: 5,
            }}
          >
            <div
              style={{
                ...styles.flex,
                ...styles.flexColumn,
                ...styles.width_100_percent,
                ...styles.alignItemsCenter,
              }}
            >
              <h1 className="text-center" style={styles.matchRate_h1}>
                ReviewScore
              </h1>
              <p className="text-primary text-center">Guide Me</p>
              <p className="text-center" style={styles.matchRate_p}>
                Add more missing skills (indicated by{' '}
                <MdClear color={colors.red} style={styles.inline} />) into your
                resume to increase your Review Score to 80% or above
              </p>
              <CircleProgress
                type="circle"
                width={220}
                percent={this.state.score}
              />
            </div>
          </Colxx>
          <Colxx
            lg={{
              size: 7,
            }}
          >
            <Card className="h-100">
              <CardBody>
                <CardTitle style={styles.fontWeight_400}>
                  Profile Status
                </CardTitle>
                {Object.keys(this.props.matchRate?.scores || {}).map((key, index) => {
                  return (
                    <div key={index} className="mb-4">
                      <p className="mb-2">
                        {key.toUpperCase()}
                        <span className="float-right text-muted">
                          {this.props.matchRate.scores[key].exists <
                          this.props.matchRate.scores[key]['not-exists'] ? (
                            <MdClear color={colors.red} style={styles.inline} />
                          ) : (
                            <MdDone
                              color={colors.green}
                              style={styles.inline}
                            />
                          )}
                          {this.props.matchRate.scores[key].exists}/
                          {this.props.matchRate.scores[key].total}
                        </span>
                      </p>
                      <Progress
                        value={
                          (this.props.matchRate.scores[key].exists /
                            this.props.matchRate.scores[key].total) *
                          100
                        }
                        style={styles.matchRate_progress}
                      />
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </div>
    );
  }
}
