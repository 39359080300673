import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../commonComponents/colors';

const flex = 'flex';
const justifyContentEnd = 'flex-end';
const fontWeight_bold = 'bold';
const textAlignCenter = 'center';
const mt_30 = 30;
const width_80_percent = '80%';
const mb_10 = 10;

export const styles = {
  flex: {
    display: 'flex',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  width_100_percent: {
    width: '100%',
  },
  width_80_percent: {
    width: '80%',
  },
  fontWeight_600: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  fontWeight_bold: {
    fontWeight: 'bold',
  },
  fontSize_85: {
    fontSize: '85%',
  },
  bb_ligthGrey: {
    border: '0.5px dashed lightgray',
  },
  mt_5: {
    marginTop: 5,
  },
  mt_30: { marginTop: 30 },
  mt_20: {
    marginTop: 20,
  },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_90: {
    marginBottom: 90,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  currentSubscription: {
    fontWeight: fontWeight_bold,
    textAlign: textAlignCenter,
    marginTop: mt_30,
  },
  changePass_Col: {
    display: flex,
    justifyContent: justifyContentEnd,
    alignItems: 'center',
    marginBottom: mb_10,
  },
  changePass_Col1: {
    marginBottom: mb_10,
    width: width_80_percent,
  },
  changePassBtn: {
    backgroundColor: colors.blue,
    color: 'white',
    paddingLeft: 40,
    paddingRight: 40,
    marginTop: 20,
    borderRadius: 5,
  },
  activeIcon: {
    backgroundColor: '#2ecc71',
    color: 'white',
    marginTop: 2,
    marginLeft: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 5,
    paddingBottom: 3,
    fontSize: 12,
    height: 30,
  },
  expiryStyle: {
    color: 'rgba(0,0,0,0.5)',
  },
  expiryStyle1: {
    color: 'rgba(0,0,0,0.5)',
    marginTop: -13,
  },
  valuelabel: {
    backgroundColor: '#ffbe61',
    width: '400px',
    height: '55%',
    position: 'absolute',
    right: -1,
    top: 0,
    clipPath: 'polygon(70% 0px, 77% 0px, 100% 22%, 100% 29%)',
  },
  valueLabelText: {
    position: 'absolute',
    top: '44px',
    right: '20px',
    rotate: '47deg',
    fontWeight: 'bold',
    color: '#fff',
    fontSize: '13px',
    textAlign: 'center',
  },
  planCardsContainer: (width) => {
    return {
      display: 'flex',
      flexDirection: width < 1190 ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 20,
    };
  },
  planCard: (width) => {
    return {
      borderRadius: 10,
      padding: 10,
      minHeight: 780,
      minWidth: 210,
      maxWidth: 500,
      flexDirection: 'column',
      width: width < 1190 ? '95%' : '30%',
      alignItems: 'center',
    };
  },
  mainContainer: (width) => {
    return {
      marginBottom: 90,
      display: 'flex',
      flexDirection: width < 700 ? 'column' : 'row',
      background: 'white',
    };
  },
  mainTab: (width) => {
    return {
      borderRight: width < 700 ? 'none' : '1px solid #dddddd',
      borderBottom: width > 700 ? 'none' : '1px solid #dddddd',
    };
  },
};

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
