import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../commonComponents/colors";

export const styles = {
  flex: {
    display: "flex",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
  },
  wrap: {
    flexWrap: "wrap",
  },
  inline: {
    display: "inline",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  justifyContentEnd: {
    justifyContent: "flex-end",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  spaceAround: {
    justifyContent: "space-around",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  left50: { left: "50%" },
  width_100_percent: {
    width: "100%",
  },
  width_80_percent: {
    width: "80%",
  },
  fontWeight_400: {
    fontWeight: 400,
  },
  fontWeight_500: {
    fontWeight: 500,
  },
  fontWeight_bold: {
    fontWeight: "bold",
  },
  fontSize_75_rem: {
    fontSize: ".75rem",
  },
  mt_5: {
    marginTop: 5,
  },
  mt_10: {
    marginTop: 10,
  },
  mt_11: { marginTop: 11 },
  mt_20: {
    marginTop: 20,
  },
  mt_30: { marginTop: 30 },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_40: {
    marginBottom: 40,
  },
  mb_90: {
    marginBottom: 90,
  },
  mb_120: { marginBottom: 120 },
  ml_5: {
    marginLeft: 5,
  },
  pb_15: { paddingBottom: 15 },
  pb_50: { paddingBottom: 50 },
  textAlignCenter: {
    textAlign: "center",
  },
  imageCard: {
    height: 400,
    borderRadius: 0,
  },
  imageCard_p: {
    position: "absolute",
    top: "49%",
    left: "49%",
    borderRadius: 0,
  },
  imageCardAction: {
    fontSize: ".7rem",
    cursor: "pointer",
  },
  imageActionColor: {
    color: "#4267B2",
  },
  scanMainContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
  },
  h1: {
    display: "inline-block",
    fontWeight: 800,
    fontSize: "22px",
    lineHeight: "34.13px",
    fontFamily: "Montserrat",
    color: "#4A4A4A",
    padding: "0px",
  },
  span: {
    fontSize: 13,
    color: "#909090",
    marginBottom: 25,
    width: "34%",
    margin: "auto",
    paddingBottom: 20,
  },
  spanSample: {
    fontSize: 14,
    color: "#909090",
    margin: "auto",
    paddingBottom: 10,
    marginTop: -10,
  },
  trySample: {
    textAlign: "center",
    marginBottom: "3%",
  },
  row: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  h3: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: 1.2,
    marginBottom: "50px",
  },
  span2: {
    color: "white",
    backgroundColor: "#4267B2",
    paddingLeft: 5,
    paddingRight: 5,
  },
  card: {
    width: "100%",
    borderRadius: 0,
    margin: "auto",
    flexWrap: "wrap",
    marginBottom: "3%",
  },
  col: {
    marginTop: 5,
    marginBottom: 5,
  },
  textArea: {
    width: "100%",
    height: "460px",
    padding: "10px",
    border: "1px solid #A9A9A9",
    fontSize: 13,
    color: "black",
  },
  h3_2: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: 1.2,
  },
  span3: {
    color: "white",
    backgroundColor: "#4267B2",
    paddingLeft: 5,
    paddingRight: 5,
  },
  span4: {
    height: "100%",
    color: "white",
    fontSize: "0.9rem",
    paddingLeft: "2.7%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: "2.7%",
    cursor: "pointer",
    borderRadius: 0,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    backgroundColor: "#4267b2",
  },
  span5: {
    cursor: "pointer",
    fontSize: ".8rem",
    fontWeight: 400,
  },
  scanReadContainer: {
    textAlign: "center",
    marginBottom: "3%",
  },
  scanReadCard: { cursor: "pointer", borderRadius: ".7rem" },
  scanReadImg: { width: "265px", height: "297px" },
  scanRead_h6: {
    fontWeight: 400,
    fontSize: ".8rem",
  },
  scanRead_h1: {
    fontSize: "1.75rem",
    paddingBottom: "10px",
    fontWeight: 500,
    display: "inline-block",
  },
  actionIcon: {
    cursor: "pointer",
    fontSize: "1.1rem",
    marginLeft: 7,
    marginRight: 6,
  },
  saveApply_yes: {
    color: "#4267B2",
    cursor: "pointer",
    padding: "5px 10px",
    fontSize: "12px",
    lineHeight: "16.37px",
  },
  saveApply_no: {
    color: "#9B9B9B",
    cursor: "pointer",
    borderLeft: "1px solid #F0F0F0",
    padding: "5px 10px",
    fontSize: "12px",
    lineHeight: "16.37px",
  },
  saveApply_title: {
    fonSize: "14px",
    lineHeight: "19.1px",
    fontWeight: 700,
    marginLeft: "10px",
    color: "#4A4A4A",
  },
  saveApply_h3: {
    color: "#145388",
    fontSize: "1.2rem",
  },
  saveApplyActionIcon: {
    fontSize: "1.1rem",
    marginLeft: 5,
    marginRight: 5,
  },
  zipSpan: {
    color: "#4267B2",
    textDecoration: "underline",
    cursor: "pointer",
  },
  visibilityIcon: {
    cursor: "pointer",
    fontSize: "1.1rem",
  },
  editorDiv: {
    border: "1px solid #F1F1F1",
    // height: "300px",
    marginTop: 15,
  },
  borderRadius_0: { borderRadius: 0 },
  cardImageOverlay: {
    borderRadius: 0,
    background: "black",
    opacity: 0.2,
  },
  colorGrey: {
    color: "gray",
  },
  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  folderOpen: {
    fontSize: "1.5rem",
    position: "relative",
    bottom: 2,
    color: "white",
  },
  clearSpan: {
    cursor: "pointer",
    fontSize: ".8rem",
    fontWeight: 400,
  },
  btnStart: {
    background: colors.blue,
    border: "none",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  activeStep_cardBody: { textAlign: "center", padding: 85 },
  activeStep_btn: {
    backgroundColor: "#4267B2",
    borderRadius: "0",
    color: "white",
  },
  activeStep_btn1: {
    backgroundColor: colors.blue,
    borderRadius: 5,
    color: "white",
  },
  activeStep_div: {
    display: "flex",
    justifyContent: "space-between",
    bottom: 13,
  },
  getIcon: { fontSize: "1.1rem" },
  phoneIcon: { cursor: "pointer", fontSize: "1.1rem" },
  descView: { color: "#4267B2", cursor: "pointer" },
  textArea1: {
    width: "100%",
    height: "460px",
    padding: "10px",
    border: "1px solid #A9A9A9",
    fontSize: 13,
    color: "black",
    resize: "none",
    overflow: "auto",
  },
  closeBtn: {
    position: "absolute",
    right: 2,
    top: 2,
    zIndex: 99,
    cursor: "pointer",
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 50,
    color: colors.red,
  },
  stepperContainer: {
    display: "flex",
    alignSelf: "center",
    alignItems: "flex-end",
    justifyContent: "center",
    maxWidth: "93%",
    width: "93%",
    marginLeft: 10,
    // height: 150,
    marginTop: 25,
    backgroundColor: "white",
    borderRadius: 10,
  },
  tableTitle: {
    borderBottom: "1px solid #F0F0F0",
    padding: "20px 30px",
    textTransform: "uppercase",
    boxSizing: "border-box",
    background: "#FAFAFA",
    color: "4A4A4A",
    fontSize: "14px",
    fontWeight: 700,
  },
  resumeTitleBar: {
    borderBottom: "1px solid #F0F0F0",
    boxSizing: "border-box",
    background: "#FAFAFA",
  },
  resumeTitle: {
    color: "4A4A4A",
    fontSize: "14px",
    fontWeight: 700,
    padding: "20px 30px",
  },
  itemDesc: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "19.1px",
    color: "#4A4A4A",
  },
  itemText: {
    fontSize: "14px",
    fontWeight: 'normal',
    lineHeight: "19px",
    color: "#4A4A4A",
  }
};

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    marginTop: "-28px",
    marginLeft: "-4%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  completed: {
    color: `${colors.green} !important`,
  }, //needed so that the &$active tag works
  connectorLine: {
    borderColor: "black",
    height: "50px",
  },
}));
