import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import QRCode from 'qrcode.react';
import {
  scanResultsReload,
  SaveActiveIndex,
  EmailParserAction,
  updateQuestionsA,
  sendTextToEmailOrMobile,
  downloadResumePdf,
  addNotesAction,
  updateMasterResuma,
} from '../../../commonRedux/scan/action';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Input, Button } from 'antd';
import {
  getCoverLetters,
  getPdf,
  setDefault,
  UpdateCoverLetter,
} from '../../../commonRedux/template/action';
import { saveToken, backupYouResumeAction } from '../../auth/redux/actions';
import toastr from 'toastr';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { styles } from './savePageStyle';

import { useWindowSize } from '@react-hook/window-size';
import CopyIcon from '../../../assets/svg/CopyIcon';
import FedexIcon from '../../../assets/fedex.webp';
import DownloadIcon from '../../../assets/svg/DownloadIcon';
import StoreIcon from '@material-ui/icons/Store';
import PrintIcon from '../../../assets/svg/PrintIcon';
import ScanIcon from '../../../assets/svg/ScanIcon';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from 'react-loader-spinner';

import Lottie from 'lottie-react';
import PopAnimation from '../../../assets/animations/popAnimation.json';
import Congrats from '../../../assets/congrats.webp';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button as Mbutton, CircularProgress } from '@material-ui/core';
import { getFormatedTextFromBuilder } from '../../../utils/getFormatedTextFromBuilder';
import { colors } from '../../../commonComponents/colors';
import { changeNameHandler } from '../redux/actions';
import { CircleLoader } from 'react-spinners';
import TxtIcon from '../../../assets/svg/TxtIcon';
import WordIcon from '../../../assets/svg/WordIcon';
import PdfIcon from '../../../assets/pdfIcon.svg';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

var pdfDetails = null;

function stripHtml(html, replaceText) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  let searchString = tmp.textContent || tmp.innerText || '';
  if (replaceText) {
    let regex = /Additional Skills/;
    searchString = searchString.replace(regex, 'Additional Skills\n');
  }
  return searchString;
}

const SavePage = (props) => {
  const selectedTemplate = '';
  const [resumeName, setResumeName] = React.useState(props?.resumeName);
  const [loader, setLoader] = React.useState(true);
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [docs, setDocs] = React.useState('');
  const [downloadLink, setDownloadLink] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [printModal, setPrintModal] = React.useState(false);
  const [printType, setPrintType] = React.useState('local');
  const [showConfetti, setShowConfetti] = React.useState(false);
  const [width, height] = useWindowSize();
  const [message, setMessage] = React.useState('');
  const [printLoader, setPrintLoader] = React.useState(false);
  // const width = useWindowWidth();
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleClickOpen = (open, message) => {
    setMessage(message);
    setOpen(open);
  };

  useEffect(() => {
    setResumeName(props?.resumeName);
  }, [props?.resumeName]);

  function downloadResume() {
    let ConstantArray = [
      'achievements',
      'additional_skills',
      'associations',
      'awards',
      'certifications',
      'custom',
      'education',
      'expertise',
      'extras',
      'hobbies',
      'languages',
      'military',
      'objective',
      'patents',
      'professionalAccomplishments',
      'profile',
      'projects',
      'publications',
      'references',
      'security_creds',
      'skills',
      'social',
      'speaking_engagements',
      'training',
      'volunteerExperience',
      'work',
    ];
    let newObj = {};
    ConstantArray.map((item) => {
      if (props.resumeData[item]?.visible) {
        newObj[item] = props.resumeData[item];
      }
    });
    const element = document.createElement('a');
    const file = new Blob(
      [stripHtml(getFormatedTextFromBuilder(newObj) || '', true)],
      {
        type: 'text/plain',
      },
    );
    element.href = URL.createObjectURL(file);
    element.download = `${resumeName}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  function openUrl(fileType) {
    if (fileType === 'pdf' && pdfUrl) {
      window.open(`${pdfUrl}`, '_blank');
    } else {
      if (docs) {
        window.open(`${docs}`, '_blank');
      }
    }
  }

  const shrinkText = (text) => {
    if (text?.length > 20) {
      return text.slice(0, 35) + '...';
    } else {
      return text;
    }
  };
  let PdFurl = () => {
    if (!!props?.oid) {
      // setResumeName(
      //   `${props.resumeName}_${moment(
      //     props.builderResumeData?.data?.createdAt,
      //   ).format('MMDDYY')}`?.replaceAll(' ', ''),
      // );
      dispatch(
        getPdf(
          {
            oid: props.oid,
            is_scan: 0,
            temp_id: 'manager',
            token: '',
          },
          (response) => {
            if (response) {
              downloadedPdfHandler(response);
            }
          },
        ),
      );
    }
  };
  React.useEffect(() => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (props?.oid) {
      PdFurl();
    }
  }, [props?.oid]);

  function downloadedPdfHandler(pdf) {
    if (pdf) {
      setLoader(false);
      pdfDetails = pdf;
      setPdfUrl(`${process.env.REACT_APP_API_URL}/${pdfDetails.pdf}`);
      setDocs(`${process.env.REACT_APP_API_URL}/${pdfDetails.doc}`);
      setDownloadLink(`${window.location.host}/${pdfDetails.download}`);
      if (navigator.clipboard) {
        navigator.clipboard.writeText(
          `${window.location.host}/${pdfDetails.download}`,
        );
      }
      props.setDefault();
    }
  }
  const resumeNameHandler = () => {
    const isNamePresent = props?.allBuildResumes?.some(
      (obj) => obj?.resumeName === resumeName,
    );
    if (isNamePresent) {
      toastr.error('Name Already Exists!');
      return;
    }
    dispatch(changeNameHandler(resumeName));
    dispatch(
      addNotesAction({
        type: '',
        oid: props.oid,
        resumeName: resumeName,
      }),
    );
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={printModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPrintModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            Print Options
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => {
              setPrintModal(false);
            }}
          />
        </div>
        <div
          style={{
            // width: 300,
            display: 'flex',
            flexDirection: 'column',
            // width: 500,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <span
            onClick={() => setPrintType('local')}
            style={{
              marginTop: 15,
              fontWeight: 'bold',
              border: printType === 'local' ? '1px solid #4267B2' : '',
              padding: 5,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <PrintIcon /> To Local Printer
          </span>
          <span
            onClick={() => setPrintType('staples')}
            style={{
              marginTop: 15,
              fontWeight: 'bold',
              border: printType === 'staples' ? '1px solid #4267B2' : '',
              padding: 5,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <StoreIcon /> Print to OfficeDepot or Staples (Any location)
          </span>
          <span
            onClick={() => setPrintType('fedex')}
            style={{
              marginTop: 15,
              fontWeight: 'bold',
              border: printType === 'fedex' ? '1px solid #4267B2' : '',
              padding: 5,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <img src={FedexIcon} width={30} height={15} /> Print to FedEx Office
            (Any location)
          </span>
        </div>
        <DialogActions>
          <Mbutton
            onClick={() => {
              if (printType === 'local') {
                openUrl('pdf');
                return;
              }
              let data = {
                is_scan: 1,
                oid: props.oid,
                temp_id: selectedTemplate,
                printType,
              };
              setPrintModal(false);
              props.EmailParserAction(data, handleClickOpen);
            }}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              height: '40px',
            }}
          >
            Print
          </Mbutton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            Message
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent>{message}</DialogContent>
      </Dialog>

      {/* model for jobDescription */}

      <Card className="saveContainer">
        <CardBody style={{ width: '95%', margin: 'auto' }}>
          <div
            style={{
              ...styles.flex,
              ...styles.justifyContentCenter,
              ...styles.mb_10,
            }}
          ></div>
          {/* {showConfetti && <Confetti width={width} height={height} />} */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Congratulations{' '}
              <Lottie
                style={{ width: 70, marginTop: -10 }}
                animationData={PopAnimation}
                loop={true}
              />
            </div>
            <div>
              You have successfully built your resume! Download your resume down
              below.
            </div>
            <div
              style={{
                marginBottom: '10px',
              }}
            >
              You can download a word copy of your created resume and edit and
              make changes as needed.
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: width < 1150 ? 'column' : 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              style={{
                width: width < 1150 ? '100%' : '60%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  marginTop: width < 1150 ? '30px' : '0',
                  border: '1px solid #F0F0F0',
                }}
              >
                <div
                  style={{
                    ...styles.flex,
                    ...styles.spaceBetween,
                    ...styles.resumeTitleBar,
                    ...styles.alignItemsCenter,
                  }}
                >
                  <span style={styles.resumeTitle}>RESUME INFO</span>
                  <div
                    style={{
                      paddingRight: '5px',
                      ...styles.flex,
                      ...styles.alignItemsCenter,
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: width < 650 ? 'flex' : 'block',
                    flexDirection: width < 650 ? 'column' : 'row',
                    ...styles.spaceBetween,
                    width: width < 1310 ? '100%' : '90%',
                    padding: '30px 25px',
                  }}
                >
                  <div style={{ ...styles.flex, ...styles.flexColumn }}>
                    <span
                      style={{
                        ...styles.itemDesc,
                        paddingBottom: '15px',
                        marginTop: 10,
                        fontSize: 16,
                      }}
                    >
                      Saved Name
                    </span>
                    <Input.Group compact>
                      <Input
                        style={{
                          width:
                            width < 650
                              ? 'calc(200px - 50px)'
                              : 'calc(308px - 50px)',
                        }}
                        value={resumeName}
                        onChange={(e) => {
                          if (e.target.value.length < 30) {
                            setResumeName(e.target.value);
                          }
                        }}
                      />
                      <Button
                        onClick={resumeNameHandler}
                        style={{
                          background: '#4B6FB6',
                          color: 'white',
                          padding: '0 10px',
                        }}
                      >
                        Rename
                      </Button>
                    </Input.Group>
                  </div>
                  <div style={{ ...styles.flex, ...styles.flexColumn }}>
                    <span
                      style={{
                        ...styles.itemDesc,
                        paddingBottom: '15px',
                        marginTop: 60,
                        fontSize: 16,
                      }}
                    >
                      Copy URL
                    </span>
                    {loader ? (
                      <Loader
                        type="ThreeDots"
                        color={colors.blue}
                        height={20}
                        width={20}
                      />
                    ) : (
                      <Input.Group
                        compact
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Input
                          readOnly
                          style={{
                            width:
                              width < 650
                                ? 'calc(200px - 50px)'
                                : 'calc(308px - 50px)',
                            color: colors.blue,
                          }}
                          value={downloadLink}
                        />
                        <CopyToClipboard
                          text={downloadLink}
                          onCopy={() => toastr.success('Resume URL copied!')}
                        >
                          <span
                            style={{ cursor: 'pointer', marginLeft: '20px' }}
                          >
                            <CopyIcon />
                          </span>
                        </CopyToClipboard>
                      </Input.Group>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: width < 1150 ? '30px' : '0',
                width: width < 1150 ? '100%' : '35%',
                border: '1px solid #F0F0F0',
              }}
            >
              <div style={styles.tableTitle}>NEW RESUME</div>
              <div
                style={{
                  ...styles.flex,
                  ...styles.flexColumn,
                  padding: width < 650 ? '16px : 10px' : '16px 40px',
                }}
              >
                <div style={{ borderBottom: '1px solid #F0F0F0' }}>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      ...styles.alignItemsCenter,
                    }}
                  >
                    <span
                      style={{
                        marginRight: '20px',
                        marginLeft: 10,
                        marginTop: 5,
                      }}
                    >
                      <CopyIcon />
                    </span>
                    <span style={styles.itemDesc}>Copy Resume</span>
                  </div>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      padding: '18px 0',
                    }}
                  >
                    <CopyToClipboard
                      text={stripHtml(
                        getFormatedTextFromBuilder(props.resumeData) || '',
                        true,
                      )}
                      onCopy={() => {
                        toastr.success('Resume copied for text file!');
                      }}
                    >
                      <span
                        style={{
                          paddingTop: '16px',
                          paddingBottom: '16px',
                          paddingRight: ' 28px',
                          paddingLeft: width < 650 ? '5px' : '28px',
                          cursor: 'pointer',
                        }}
                      >
                        <TxtIcon />
                      </span>
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                      text={stripHtml(
                        getFormatedTextFromBuilder(props.resumeData) || '',
                        true,
                      )}
                      onCopy={() => {
                        toastr.success('Resume copied for word file!');
                      }}
                    >
                      <span
                        style={{
                          padding: '16px 28px',
                          borderLeft: '1px solid #F0F0F0',
                          cursor: 'pointer',
                        }}
                      >
                        <img src={WordIcon} alt="Word File" />
                      </span>
                    </CopyToClipboard> */}
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: '1px solid #F0F0F0',
                    marginTop: '16px',
                  }}
                >
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      ...styles.alignItemsCenter,
                    }}
                  >
                    <span style={{ marginRight: '20px', marginLeft: 10 }}>
                      <DownloadIcon />
                    </span>
                    <span style={styles.itemDesc}>Download Resume</span>
                  </div>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      ...styles.alignItemsCenter,
                      padding: '18px 0',
                    }}
                  >
                    {loader ? (
                      <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={35}
                        width={35}
                        style={{ margin: '16px 28px' }}
                      />
                    ) : (
                      <>
                        <span
                          onClick={downloadResume}
                          style={{
                            // padding: "16px 28px",
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            paddingLeft: width < 650 ? '5px' : '28px',
                            paddingRight: '28px',
                            cursor: 'pointer',
                          }}
                        >
                          <TxtIcon />
                        </span>
                        <span
                          onClick={() => {
                            openUrl('doc');
                          }}
                          style={{
                            padding: '16px 28px',
                            borderLeft: '1px solid #F0F0F0',
                            cursor: 'pointer',
                          }}
                        >
                          <WordIcon />
                        </span>
                        <span
                          onClick={() => {
                            openUrl('pdf');
                          }}
                          style={{
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            paddingLeft: width < 650 ? '20px' : '28px',
                            borderLeft: '1px solid #F0F0F0',
                            cursor: 'pointer',
                          }}
                        >
                          <img
                            src={PdfIcon}
                            alt="Pdf File"
                            height={60}
                            width={60}
                          />
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: '1px solid #F0F0F0',
                    marginTop: '16px',
                  }}
                >
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      ...styles.alignItemsCenter,
                    }}
                  >
                    <span style={{ marginRight: '20px', marginLeft: 10 }}>
                      <PrintIcon />
                    </span>
                    <span style={styles.itemDesc}>Print Resume</span>
                  </div>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      padding: '18px 0',
                    }}
                  >
                    {props.fedexStatus ? (
                      <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={35}
                        width={35}
                        style={{ margin: '16px 28px' }}
                      />
                    ) : (
                      <span
                        onClick={() => {
                          setPrintModal(true);
                          // props.EmailParserAction(data, handleClickOpen);
                        }}
                        style={{
                          paddingTop: '16px',
                          paddingBottom: '16px',
                          paddingRight: '28px',
                          paddingLeft: width < 650 ? '5px' : '28px',
                          cursor: 'pointer',
                        }}
                      >
                        <img
                          src={PdfIcon}
                          alt="Pdf File"
                          height={60}
                          width={60}
                        />
                      </span>
                    )}
                  </div>
                </div>
                <div style={{ marginTop: '16px' }}>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      ...styles.alignItemsCenter,
                    }}
                  >
                    <span style={{ marginRight: '20px' }}>
                      <ScanIcon />
                    </span>

                    <span style={styles.itemDesc}>Scan QR</span>
                  </div>
                  <div style={{ margin: '30px 0' }}>
                    {downloadLink ? (
                      <center className="HpQrcode">
                        <QRCode value={downloadLink} />
                        {localStorage.setItem('link', downloadLink)}
                      </center>
                    ) : (
                      <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={35}
                        width={35}
                        style={{ margin: '16px 28px' }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = ({
  fileScanReducer,
  templateReducer,
  authUser,
  editorReducer,
}) => {
  return {
    builderResumeData: editorReducer.builderResumeData,
    scanResult: fileScanReducer.scanResult,
    resume: fileScanReducer.resume,
    resumaId: fileScanReducer.resumaId,
    coverLetters: templateReducer.coverLetters,
    pdf: templateReducer.pdfLink,
    getPdfStatus: templateReducer.getPdfStatus,
    fedexStatus: fileScanReducer.fedexStatus,
    sendTextToStatus: fileScanReducer.sendTextToStatus,
    user_template: authUser.selectedTemplate,
    user: authUser.user,
  };
};
export default connect(mapStateToProps, {
  scanResultsReload,
  SaveActiveIndex,
  EmailParserAction,
  getCoverLetters,
  saveToken,
  updateMasterResuma,
  UpdateCoverLetter,
  getPdf,
  setDefault,
  updateQuestionsA,
  sendTextToEmailOrMobile,
  backupYouResumeAction,
  downloadResumePdf,
})(withRouter(SavePage));
