import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';
import ResumeHistory from './resume_history';
import ScanHistory from './scan_history';
import { connect, useSelector } from 'react-redux';
import { saveToken } from '../auth/redux/actions';
import CoverLetterTable from './Cover-letter-table';
import { styles } from './style';
import UpgradeModal from '../../commonComponents/UpgradeModal';

import { colors } from '../../commonComponents/colors';
import howToIcon from '../../assets/How-to.svg';
import InfoIcon from '../../commonComponents/InfoIcon';
import VideoModel from '../../commonComponents/videoModel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
const clickTabStyle = {
  // border: `1.5px solid ${colors.blue}`,
  background: colors.blue,
  color: colors.white,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '&$selected': {
      color: '#ffffff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#ffffff',
    },
  },
  titleDiv: {
    paddingTop: '20px',
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 800,
    fontSize: '22px',
    lineHeight: '34.13px',
    fontFamily: 'Montserrat',
    color: '#4A4A4A',
  },
  manageTabs: {
    // fontSize: ".75rem",
    // fontWeight: 600,
  },
}));

function ManageTabsPanel(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const VideoLinks = useSelector((state) => state.fileScanReducer.videoLinks);
  const user = useSelector((state) => state.authUser.user);
  const [toggle, setToggle] = React.useState(0);
  const [modelOpen, setModelOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (props.location?.state !== undefined) {
      if (props.location?.state?.template === 'apply') {
        setValue(2);
      }
    }
  });

  return (
    <div className={classes.root}>
      <VideoModel
        open={modelOpen}
        setOpen={setModelOpen}
        video={VideoLinks?.Manage}
      />
      <div className={classes.titleDiv}>
        {/* <RightIconButton style={{
          marginTop: "7.5px",
          marginLeft: '10px'
        }}
        onClick={() => setModelOpen(true)}
      /> */}
        <InfoIcon
          onClick={() => setModelOpen(true)}
          text={'How To'}
          icon={'Play'}
          height={18}
          width="70px"
          marginTop={8}
          marginRight={5}
        />
        <span className={classes.title}>Manage</span>
      </div>
      {user?.subscription === false && <UpgradeModal />}
      <AppBar position="static" color="default" style={styles.zIndex}>
        <Tabs
          className="manage_tab_bar_cont"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          TabIndicatorProps={{
            style: {
              background: colors.blue,
              display: 'none',
            },
          }}
        >
          <Tab
            className={`resume_main_container ${classes.manageTabs}`}
            label="Cover Letter"
            style={toggle === 0 ? clickTabStyle : {}}
            {...a11yProps(2)}
            onClick={() => {
              setToggle(0);
            }}
          />

          <Tab
            className={`resume_main_container ${classes.manageTabs}`}
            label="Resume History"
            {...a11yProps(0)}
            style={toggle === 1 ? clickTabStyle : {}}
            onClick={() => {
              setToggle(1);
            }}
          />

          <Tab
            className={`resume_main_container ${classes.manageTabs}`}
            label="Review History"
            {...a11yProps(1)}
            style={toggle === 2 ? clickTabStyle : {}}
            onClick={() => {
              setToggle(2);
            }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <CoverLetterTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ResumeHistory />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ScanHistory />
      </TabPanel>
    </div>
  );
}

export default connect(null, { saveToken })(ManageTabsPanel);
