import { useWindowWidth } from '@react-hook/window-size';
import React from 'react';
import GoogleTrendsChart from '../googleTrend/GoogleTrend';
import styles from './infoModalStyle';

export default function JobView(props) {
  const { onetData } = props;
  const windowWidth = useWindowWidth();
  return (
    <>
      <p style={styles.para}>
        Job Title: <span style={styles.light}>{props.jobTitle}</span>
      </p>
      <p style={styles.para}>
        Occupation Category:{' '}
        <span style={styles.light}>{onetData['jobTitle'] ?? 'NA'}</span>
      </p>
      {/* <p style={styles.para}>
        O*NET SOC CODE:{' '}
        <span style={styles.light}>{onetData['onsc'] ?? 'NA'}</span>
      </p> */}
      <p style={{ ...styles.para, marginTop: 10 }}>Google Search Trends: </p>
      <div
        style={{
          ...(windowWidth < 500 && {
            width: 550,
            overflowX: 'scroll',
            overflowY: 'hidden',
          }),
        }}
      >
        <GoogleTrendsChart jobTitle={props.jobTitle} />
      </div>
    </>
  );
}
