import React from 'react'
import ReactPlayer from 'react-player'

export default function VimeoPlayer({
    video,
    onEnded = () => { },
    ...attr
}) {
    return (
        <ReactPlayer
            url={`https://player.vimeo.com/video/${video?.videoID}`}
            allowfullscreen
            config={{
                playerOptions: {
                    autoplay: video?.autoPlay,
                    loop: video?.loop,
                    autopause: false,
                    muted: video?.muted,
                    controls: video?.showControls,
                },
                vimeo: {
                    title: 'Resumás',
                }
            }}
            onEnded={onEnded}
            {...attr}
        />
    )
}
