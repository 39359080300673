export const jobRducer = (
  state = {
    jobs: [],
    jobsStatus: 'not done',
    jobUpdator: new Date(),
    status: false,
    jobmatches: [],
    userLatestScan: null,
    skills: {
      skills: ['Javascript', 'node', 'React', 'Php', 'C#', 'Swift'],
      address: 'Santa Monica, CA',
      radius: '25',
      checkedSkills: [],
    },
    searchList: [],
  },
  action,
) => {
  switch (action.type) {
    case 'SUBMIT':
      return {
        ...state,
        status: true,
      };
    case 'SUBMIT_DONE':
      return {
        ...state,
        status: false,
      };
    case 'SUBMIT_FAILED':
      return {
        ...state,
        status: false,
      };

    case 'USER_LATEST_SCAN_JOB':
      return {
        ...state,
        userLatestScan: action.payload,
      };

    case 'ALL_JOBS':
      return {
        ...state,
        jobs: action.payload,
        jobsStatus: 'done',
        jobUpdator: new Date(),
      };
    case 'GET_JOB_MATCHER':
      return {
        ...state,
        jobmatches: action.payload,
      };
    case 'JOBS_ERROR':
      return {
        ...state,
        jobs: [],
        jobsStatus: 'not done',
        jobUpdator: new Date(),
      };
    case 'SKILLS':
      return {
        ...state,
        skills: action.payload,
      };
    case 'GET_SEARCH_HISTORY':
      return {
        ...state,
        searchList: action.payload,
      };
    case 'ADD_SEARCH_HISTORY':
      return {
        ...state,
        searchList: state.searchList?.length
          ? [...state.searchList, action.payload]
          : [action.payload],
      };
    case 'DELETE_SEARCH_HISTORY':
      // eslint-disable-next-line no-case-declarations
      let filtered = state.searchList.filter(
        (item) => item.id !== action.payload,
      );
      return {
        ...state,
        searchList: filtered,
      };
    case 'UPDATE_SEARCH_HISTORY':
      // eslint-disable-next-line no-case-declarations
      let updatedList = state.searchList.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        searchList: updatedList,
      };

    default:
      return state;
  }
};
