import React, { memo, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import CompanyLogin from '../modules/auth/companies/login/Login';
import CompanySignup from '../modules/auth/companies/signup/PageCoverSignup';
import PastInvoices from '../modules/auth/companies/dashboard/PastInvoices';
import CompanyDashboard from '../modules/auth/companies/dashboard/Dashboard';
import Layout from '../modules/layout/authLayout';
import LandingPage from '../modules/landingPage';
import Login from '../modules/auth/login';
import ResetPassword from '../modules/auth/resetPassword';
import PageCoverSignup from '../modules/auth/signup/PageCoverSignup';
import PageCoverSignupFinal from '../modules/auth/signup/PageCoverSignupFinal';
import PageTerms from '../modules/auth/privacy/terms';
import Faq from '../modules/landingPage/Faq';
import PrivacyPage from '../modules/auth/privacy';
import { Elements } from '@stripe/react-stripe-js';
import PageCoverRePassword from '../modules/auth/recoverPassword';
import stripePromise from '../config/stripe';
import { currentUser } from '../modules/auth/redux/actions';
import { getSkills } from '../commonRedux/jobs/action';
import { connect, useDispatch, useSelector } from 'react-redux';
import ViewError from '../commonComponents/error';
import Scan from '../modules/scan';
import LinkedIn from '../modules/linkedin';
import ScanHistory from '../modules/scanhistory';
import JobMatcher from '../modules/jobmatcher';
import TemplatesComponent from '../modules/manage';
import help from '../modules/profile/help';
import InviteFriends from '../modules/profile/InviteFriends';
import DownloadAppPage from '../modules/landingPage/Testi';
import Profile from '../modules/profile';
import Account from '../modules/account';
import FinalResultPage from '../modules/finalResult';
import EditScan from '../modules/recent-history/editScan/scanresult';
import ScanResult from '../modules/scanresult/scanresult';
import Settings from '../modules/setting';
import PrivateRoute from './routes/privateRoutes';
import PublicRoute from './routes/publicRoutes';
import AppLayout from '../modules/layout/appLayout';
import resumeBuilder from '../modules/resumeBuilder/template';

import UpgradeModal from '../commonComponents/upgradeAccount';
import { OnboardingTaskCard } from '../commonComponents/onboardingTaskCard';
import Trade from '../modules/auth/privacy/trade';
import EditorWebView from '../modules/editorWebView/EditorWebView';
import CaliforniaPrivacy from '../modules/auth/privacy/californiaPrivacy';
import {
  getResumaResults,
  getSingleCoverLetter,
  getVideoLinks,
} from '../commonRedux/scan/action';
import DownloadUserDoc from '../modules/downloadUserDoc/DownloadUserDoc';
import EmployerPrivateRoute from './routes/employerPrivateRoute';
import HowToStart from '../modules/auth/howToStart/HowToStart';
import HighestEducation from '../modules/auth/howToStart/questions/HighestEducation';
import Questions from '../modules/auth/howToStart/questions/WorkExpriences';
import PDFUploader from '../modules/auth/howToStart/dropZone/DropZone';
import SaveAndApply from '../modules/scan/SaveAndApply';
import Terms from '../modules/auth/privacy/terms';
import JobInformation from '../modules/auth/howToStart/questions/JobInformation';
import { getVersions } from '../modules/profile/redux/action';
import MilitaryCrosswalk from '../modules/militaryXWalk/MilitaryCrosswalk';
import DownloadReport from '../modules/downloadReport/DownloadReport';
// import Personalized from '../modules/newResumeBuilder/personalized/Personalized';
// import WorkExperiences from '../modules/newResumeBuilder/workExperience/WorkExperience';
// import EditCompany from '../modules/newResumeBuilder/workExperience/editCompany/EditCompany';
// import AddCompany from '../modules/newResumeBuilder/workExperience/addCompany/AddCompany';

function withLayout(WrappedComponent) {
  // eslint-disable-next-line react/display-name
  return () => (
    <Layout>
      <WrappedComponent />
    </Layout>
  );
}

const Navigation = memo(({ currentUser, getVideoLinks, getResumaResults }) => {
  const user = useSelector((state) => state?.authUser?.user);
  const oid = useSelector((state) => state?.fileScanReducer?.scanResult?.oid);

  const getDataIfUser = () => {
    getResumaResults();
    getSingleCoverLetter(oid);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getVideoLinks();
    dispatch(getVersions());
    currentUser();
  }, []);

  useEffect(() => {
    if (oid) {
      getDataIfUser();
    }
  }, [oid]);

  return (
    <Elements stripe={stripePromise}>
      <Router basename={'/'}>
        <Switch>
          {/* <PublicRoute
            exact
            restricted={true}
            path="/"
            component={withLayout(LandingPage)}
          /> */}

          <Redirect exact from="/" to="/login" />
          <PublicRoute
            exact
            restricted={true}
            path="/login"
            component={Login}
          />
          <PublicRoute
            exact
            restricted={true}
            path="/employerLogin"
            component={CompanyLogin}
          />
          <EmployerPrivateRoute
            exact
            path="/employerDashboard"
            component={CompanyDashboard}
          />
          <PublicRoute exact path="/pastInvoices" component={PastInvoices} />
          <PublicRoute
            exact
            path="/linkedinAuth"
            component={LinkedInCallback}
          />
          <PublicRoute
            exact
            path="/reset-password/:reset_token"
            component={ResetPassword}
          />
          <PublicRoute
            exact
            restricted={false}
            path="/web-view-editor"
            component={EditorWebView}
          />

          <PublicRoute
            exact
            restricted={true}
            path="/signup"
            component={PageCoverSignup}
          />

          <PublicRoute
            exact
            restricted={true}
            path="/employerSignup"
            component={CompanySignup}
          />

          <PublicRoute
            restricted={true}
            path="/page-cover-signup-final"
            component={PageCoverSignupFinal}
          />
          <PublicRoute
            restricted={true}
            path="/forget-password"
            component={PageCoverRePassword}
          />
          <PublicRoute
            restricted={false}
            path="/page-terms"
            component={PageTerms}
          />
          <PublicRoute
            restricted={false}
            path="/page-privacy"
            component={PrivacyPage}
          />
          <PublicRoute restricted={false} path="/faq" component={Faq} />
          <PublicRoute
            restricted={false}
            path="/terms-of-services"
            component={withLayout(Terms)}
          />
          <PublicRoute
            restricted={false}
            path="/trademarks"
            component={withLayout(Trade)}
          />
          <PublicRoute
            restricted={false}
            path="/california-privacy-policy"
            component={withLayout(CaliforniaPrivacy)}
          />
          <PublicRoute
            restricted={false}
            path="/download"
            component={DownloadUserDoc}
          />
          <PublicRoute
            restricted={false}
            path="/download-report"
            component={DownloadReport}
          />

          <PublicRoute path="/error" restricted={false} component={ViewError} />
          <AppLayout>
            <div className="dashboard-wrapper">
              <PrivateRoute path={`/optimize`} component={Scan} />
              <PrivateRoute path={`/linkedin`} component={LinkedIn} />
              <PrivateRoute path={`/dashboards`} component={ScanHistory} />
              <PrivateRoute path={`/jobmatcher`} component={JobMatcher} />
              <PrivateRoute path={`/how-to-start`} component={HowToStart} />
              <PrivateRoute
                path={`/questionnaire/work_experience`}
                component={Questions}
              />
              <PrivateRoute
                path={`/questionnaire/job_information`}
                component={JobInformation}
              />
              <PrivateRoute
                path={`/questionnaire/highest_education`}
                component={HighestEducation}
              />
              <PrivateRoute
                path={`/resume_builder/drop_zone`}
                component={PDFUploader}
              />
              {/* <PrivateRoute
                path={`/resume_builder/personalized`}
                component={Personalized}
              />
              <PrivateRoute
                path={`/resume_builder/work_experience`}
                component={WorkExperiences}
              />
              <PrivateRoute
              path={`/resume_builder/work_experience/edit_company`}
              component={EditCompany}
              />
               <PrivateRoute
              path={`/resume_builder/work_experience/add_company`}
              component={AddCompany}
              /> */}
              <PrivateRoute
                path={`/templates`}
                component={TemplatesComponent}
              />
              <PrivateRoute path={`/help`} component={help} />
              <PrivateRoute path={`/InviteFriends`} component={InviteFriends} />
              <PrivateRoute path={`/DownloadApp`} component={DownloadAppPage} />

              <PrivateRoute path={`/profile`} component={Profile} />
              <PrivateRoute path={`/account`} component={Account} />

              <PrivateRoute
                path={`/final-result`}
                component={FinalResultPage}
              />

              <PrivateRoute path={`/edit-scan/:oid`} component={EditScan} />

              <PrivateRoute path={`/scanresult`} component={ScanResult} />
              <PrivateRoute path={`/setting`} component={Settings} />
              <PrivateRoute path={`/builder`} component={resumeBuilder} />
              <PrivateRoute path={`/save-and-apply`} component={SaveAndApply} />
              <PrivateRoute path={'/milXwalk'} component={MilitaryCrosswalk} />
              <UpgradeModal />
            </div>
          </AppLayout>
        </Switch>
        <OnboardingTaskCard />
      </Router>
    </Elements>
  );
});

Navigation.displayName = 'Navigation';

const mapStateToProps = ({ authUser }) => {
  const { user: loginUser } = authUser;
  return { loginUser };
};

export default connect(mapStateToProps, {
  currentUser,
  getSkills,
  getVideoLinks,
  getResumaResults,
})(Navigation);
