import React, { memo } from "react";
import { colors } from "../../../commonComponents/colors";
import GaugeChart from "../../../commonComponents/react-advanced-gauge-chart/dist/GaugeChart";
const DonutChart = memo(({ value }) => {

  return (
    <>
      <GaugeChart
        id="gauge-chart3"
        nrOfLevels={2}
        textColor={"#4c4c4c"}
        colors={[colors.red, colors.lightBlue]}
        arcsLength={[50, 50]}
        percent={value / 100}
      />
    </>
  );
})

export default DonutChart;
