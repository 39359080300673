import React from 'react';
import imgNotFound from '../../assets/not-found.svg';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useWindowSize } from '@react-hook/window-size';
const NotFound = () => {
  const [width] = useWindowSize();
  
  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
          padding: '10px 0px',
          fontWeight: 500,
          fontSize: width < 600 ? '1.2rem' : '2rem',
        }}
      >
        Oops! The page you are looking for does not exist.
      </h2>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <img
          src={imgNotFound}
          alt="not found"
          style={{
            width: '50%',
            maxWidth: '600px',
            backgroundPosition: 'contain',
          }}
        />
        <Link to="/optimize">
          <Button variant="contained" color="primary">
            Go to Home
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
