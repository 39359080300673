import React from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Breadcrumb,
  Card,
  CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MdCheck,
  MdClear,
  MdSentimentDissatisfied,
  MdSentimentSatisfied,
} from 'react-icons/md';
import Highlighter from 'react-highlight-words';
import Model from '../manage/notes-model';
import InfoIcon from '../../commonComponents/InfoIcon';
import { connect } from 'react-redux';
import {
  deleteHistoryItem,
  updateQuestionsAction,
  EmailParserAction,
  EmailParserForScanTables,
  addNotesAction,
  scanHistory,
  SaveActiveIndex,
} from '../../commonRedux/scan/action';
import { getPdf, setPdfStatusFalse } from '../../commonRedux/template/action';
import Chart from './chart';
import EditIcon from '@material-ui/icons/Edit';
import { Colxx } from '../../commonComponents/customBootstrap';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactHtmlParser from 'react-html-parser';
import { style, styles } from './style';
import { skillsCount } from '../../utils/scoreCalculator';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { colors } from '../../commonComponents/colors';
import QRCode from 'qrcode.react';
import BulbImage from '../../assets/bulb.webp';
import {
  Button as ButtonR,
  CircularProgress,
  DialogContent,
  Select,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clip from '../../assets/clipboard.png';
import { Visibility } from '@material-ui/icons';
import PrintIcon from '../../assets/svg/PrintIcon';
import FedexIcon from '../../assets/fedex.webp';
import StoreIcon from '@material-ui/icons/Store';
import CancelIcon from '@material-ui/icons/Cancel';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLarge: false,
      resume: '',
      jobdescription: '',
      jobtitle: '',
      link: 'highestMatch',
      animateClass: '',
      loader: false,
      open: false,
      isjobApplied: true,
      openOkDialog: false,
      fedexMessage: '',
      resumeTextInState: '',
      anchorEl: false,
      pdfDetails: '',
      downloadLink: '',
      statusUpdateModel: false,
      applyStatus: props.details?.data?.jobApplied,
      interviewStatus: props.details?.data?.isInterview,
      updateStatusLoader: false,
      oid: '',
      linkLoader: false,
      loader1: false,
      printModal: false,
      printType: 'local',
      openDelete: false,
    };
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: false });
  };

  componentDidMount() {
    this.Pdfurl();
  }

  componentDidUpdate(prevProps) {
    let { jobApplied: prevJob, isInterview: prevInterview } =
      prevProps?.details?.data;
    let { jobApplied, isInterview } = this.props.details?.data;
    if (prevJob !== jobApplied) {
      this.setState({ applyStatus: jobApplied });
    }
    if (prevInterview !== isInterview) {
      this.setState({ interviewStatus: isInterview });
    }
  }
  Pdfurl() {
    this.props.getPdf(
      {
        oid: this.props.details.oid,
        is_scan: 0,
        temp_id: 'manager',
        token: '',
      },
      (response) => {
        this.props.setPdfStatusFalse();
        this.downloadedPdfHandler(response);
      },
    );
  }

  downloadedPdfHandler(pdf) {
    if (pdf) {
      this.setState({ pdfDetails: pdf });
      this.setState({
        downloadLink: `${window.location.host}${this.state.pdfDetails.download}`,
      });
    }
  }

  stripHtml = (html) => {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };
  toggleLarge = (resume, jobTitle, jobDescription) => {
    this.setState((prevState) => ({
      resume: this.stripHtml(resume) || resume,
      jobdescription: jobDescription,
      jobtitle: jobTitle,
      modalLarge: !prevState.modalLarge,
    }));
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.historyDeleteStatus === 'not done') {
      this.setState({ loader: false });
    }
    if (nextProps.historyDeleteStatus === 'done') {
      this.setState({ loader: false });
    }
  };

  static getDerivedStateFromProps = (nextProps) => {
    let converted = ReactHtmlParser(nextProps.details.resumeText);

    if (nextProps.details) {
      return {
        resumeTextInState: converted?.[0]?.props
          ? converted?.[0]?.props?.children?.[0]
          : nextProps.details.resumeText,
      };
    }
    if (nextProps?.details?.data?.jobApplied === 'no') {
      return {
        isjobApplied: false,
      };
    } else {
      return {
        isjobApplied: true,
      };
    }
  };

  render() {
    const { details } = this.props;
    const { jobApplied, isInterview } = this.props.details?.data;

    const skills_counting = skillsCount(details?.data?.skillsData);
    const { classes } = this.props;
    const handleCloseOkDialog = () => {
      this.setState({
        openOkDialog: false,
      });
    };

    const handleClickOpen = () => {
      this.setState({ open: true });
    };

    const deleteFunction = () => {
      this.props.deleteHistoryItem(details.oid);
      this.setState({ open: false });
    };

    const questionsFunction = async (condition, type) => {
      let data = {
        type: type,
        oid: details.oid,
        isTrue: condition,
      };
      await this.props.updateQuestionsAction(data);
      this.props.scanHistory();
    };

    const handleClose = () => {
      this.setState({ open: false });
    };
    const statusCloseModel = () => {
      this.setState({
        statusUpdateModel: false,
        updateStatusLoader: false,
      });
    };
    const handleAppliedStatus = async () => {
      let jobAppliedData = {
        isTrue: this.state.applyStatus,
        oid: details.oid,
        type: 'jobApplied',
      };
      // check if jobApplied is "yes" then interview status is update otherwise it is "no" status
      let interviewData = {
        isTrue:
          this.state.applyStatus === 'yes' ? this.state.interviewStatus : 'no',
        oid: details.oid,
        type: 'interview',
      };
      this.setState({
        statusUpdateModel: true,
        updateStatusLoader: true,
      });
      await this.props.updateQuestionsAction(jobAppliedData);
      await this.props.updateQuestionsAction(interviewData);
      statusCloseModel();
      this.props.scanHistory();
    };
    const openPdf = (val, viewDownloadPage) => {
      if (val) {
        this.setState({
          linkLoader: false,
          printModal: false,
        });
        if (viewDownloadPage) {
          window.open(
            `${process.env.REACT_APP_WEB_URL}${val?.download}` ||
              this.props.pdf?.cloundinary,
            '_blank',
          );

          return;
        } else {
          window.open(
            `${process.env.REACT_APP_API_URL}/${val?.pdf}` ||
              this.props.pdf?.cloundinary,
            '_blank',
          );
        }
      }
      this.setState({
        linkLoader: false,
        printModal: false,
      });
      this.props.setPdfStatusFalse();
    };
    const generatePdfbyLink = async (id) => {
      const viewDownloadPage = true;

      this.setState({ linkLoader: true });
      this.props.getPdf(
        {
          oid: id,
          is_scan: 1,
          temp_id: 'functional',
          token: '',
        },
        (val) => openPdf(val, viewDownloadPage),
      );
    };
    const handleClickOpenOkDialog = (open, message) => {
      this.setState({
        openOkDialog: open,
        fedexMessage: message,
      });
    };
    const generatePdf = async (id) => {
      this.setState({
        loader1: false,
      });
      this.props.getPdf(
        {
          oid: id,
          is_scan: 1,
          temp_id: 'functional',
          token: '',
        },
        openPdf,
      );
    };
    const handleClickOpenDelete = (id) => {
      this.setState({ oid: id, openDelete: true });
    };

    const reviewSummaryHandler = async () => {
      await localStorage.setItem('myScanId', details?.oid);
      this.props.SaveActiveIndex(4);
      this.props.history.push('/optimize');
    };
    return (
      <>
        <Dialog
          open={this.state.openOkDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseOkDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.state.fedexMessage}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseOkDialog} className="ok-button-color">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <div className="most_recent_scan">
          <Dialog
            fullWidth
            maxWidth={'xs'}
            open={this.state.statusUpdateModel}
            TransitionComponent={Transition}
            keepMounted
            onClose={() =>
              this.setState(
                { statusUpdateModel: false },
                // () =>
                // this.props.getHistory(),
              )
            }
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <div
              id="alert-dialog-slide-title"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '10px 20px',
              }}
            >
              <h5
                style={{
                  fontFamily: 'Nunito',
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: 23,
                }}
              >
                Update Status
              </h5>
              <CancelIcon
                style={{
                  color: colors.red,
                  cursor: 'pointer',
                  fontSize: 24,
                }}
                onClick={() =>
                  this.setState(
                    { statusUpdateModel: false },
                    // () =>
                    // this.props.getHistory(),
                  )
                }
              />
            </div>
            <DialogContent>
              <Select
                fullWidth
                style={{ marginBottom: '20px' }}
                className="f-s-19"
                value={this.state.applyStatus}
                defaultValue={this.state.applyStatus}
                onChange={(e) => this.setState({ applyStatus: e.target.value })}
              >
                <MenuItem value={'yes'}>Applied</MenuItem>
                <MenuItem value={'no'}>Not Applied</MenuItem>
              </Select>
              {this.state.applyStatus === 'yes' && (
                <Select
                  fullWidth
                  style={{ marginBottom: '10px' }}
                  value={this.state.interviewStatus}
                  className="f-s-19"
                  onChange={(e) =>
                    this.setState({ interviewStatus: e.target.value })
                  }
                >
                  <MenuItem value={'yes'}>Interview</MenuItem>
                  <MenuItem value={'no'}>No Interview</MenuItem>
                </Select>
              )}
            </DialogContent>
            <DialogActions>
              {this.state.updateStatusLoader ? (
                <Button>
                  <CircularProgress />
                </Button>
              ) : (
                <Button
                  onClick={handleAppliedStatus}
                  color="primary"
                  variant="contained"
                  style={{
                    backgroundColor: colors.blue,
                    color: colors.white,
                    height: '40px',
                  }}
                >
                  Update
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth={'xs'}
            open={this.state.openDelete}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              this.setState({ openDelete: false });
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '10px',
                paddingTop: '10px',
              }}
            >
              <CancelIcon
                style={{
                  color: colors.red,
                  cursor: 'pointer',
                  fontSize: 24,
                }}
                onClick={() => {
                  this.setState({ openDelete: false });
                }}
              />
            </div>
            <DialogTitle
              style={{ ...styles.h3, padding: '0px 10px 0px 15px' }}
              className="f-s-19"
            >
              Are you sure, you want to delete?
            </DialogTitle>
            <DialogActions>
              <ButtonR
                onClick={deleteFunction}
                style={{
                  backgroundColor: colors.blue,
                  color: colors.white,
                  borderRadius: '3px',
                  fontSize: '14px',
                  margin: '0px 3px 6px 0px',
                }}
              >
                Delete
              </ButtonR>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="xs"
            open={this.state.printModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() =>
              this.setState({
                printModal: false,
              })
            }
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <div
              id="alert-dialog-slide-title"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '10px 20px',
              }}
            >
              <h5
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: 20,
                  fontFamily: 'Nunito',
                }}
              >
                Print Options
              </h5>
              <CancelIcon
                style={{
                  color: colors.red,
                  cursor: 'pointer',
                  fontSize: 24,
                }}
                onClick={() => {
                  this.setState({
                    printModal: false,
                  });
                }}
              />
            </div>
            <div
              style={{
                // width: 300,
                display: 'flex',
                flexDirection: 'column',
                // width: 500,
                justifyContent: 'center',
                padding: 20,
              }}
            >
              <span
                onClick={() => this.setState({ printType: 'local' })}
                style={{
                  marginTop: 15,
                  fontWeight: 'bold',
                  border:
                    this.state.printType === 'local' ? '1px solid #4267B2' : '',
                  padding: 5,
                  borderRadius: 10,
                  cursor: 'pointer',
                }}
              >
                <PrintIcon /> To Local Printer
              </span>
              <span
                onClick={() => this.setState({ printType: 'staples' })}
                style={{
                  marginTop: 15,
                  fontWeight: 'bold',
                  border:
                    this.state.printType === 'staples'
                      ? '1px solid #4267B2'
                      : '',
                  padding: 5,
                  borderRadius: 10,
                  cursor: 'pointer',
                }}
              >
                <StoreIcon /> Print to OfficeDepot or Staples (Any location)
              </span>
              <span
                onClick={() => this.setState({ printType: 'fedex' })}
                style={{
                  marginTop: 15,
                  fontWeight: 'bold',
                  border:
                    this.state.printType === 'fedex' ? '1px solid #4267B2' : '',
                  padding: 5,
                  borderRadius: 10,
                  cursor: 'pointer',
                }}
              >
                <img src={FedexIcon} width={30} height={15} /> Print to FedEx
                Office (Any location)
              </span>
            </div>
            <DialogActions>
              {this.state.loader1 || this.state.printLoader ? (
                <CircularProgress size={30} />
              ) : (
                <Button
                  onClick={() => {
                    if (this.state.printType === 'local') {
                      generatePdf(details.oid);
                      return;
                    } else {
                      let data = {
                        is_scan: 1,
                        oid: details.oid,
                        temp_id: '',
                        printType: this.state.printType,
                      };
                      this.setState({
                        printModal: false,
                      });
                      this.props.EmailParserForScanTables(
                        data,
                        handleClickOpenOkDialog,
                      );
                    }
                  }}
                  color="primary"
                  variant="contained"
                  style={{
                    backgroundColor: colors.blue,
                    color: colors.white,
                    height: '40px',
                  }}
                >
                  Print
                </Button>
              )}
              {/* <Button
            onClick={() => {
              setPrintModal(false);
            }}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button> */}
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth={'md'}
            open={this.state.modalLarge}
            TransitionComponent={Transition}
            keepMounted
            onClose={() =>
              this.setState({
                modalLarge: false,
              })
            }
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <div
              id="alert-dialog-slide-title"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '10px 20px',
              }}
            >
              <h5
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: 20,
                  fontFamily: 'Nunito',
                }}
              >
                Job Description
              </h5>
              <CancelIcon
                style={{
                  color: colors.red,
                  cursor: 'pointer',
                  fontSize: 24,
                }}
                onClick={() =>
                  this.setState({
                    modalLarge: false,
                  })
                }
              />
            </div>
            <DialogContent>
              <div
                style={{
                  height: 'auto',
                  padding: 20,
                  boxSizing: 'border-box',
                }}
              >
                <textarea
                  style={{
                    width: '100%',
                    height: '100%',
                    border: '1px solid #ccc',
                    outline: 'none',
                  }}
                  placeholder="Job Description"
                  rows={14}
                  value={
                    this.props.details?.data?.jobDescription ||
                    this.props.details?.jobDescription
                  }
                  readOnly
                />
              </div>
            </DialogContent>
          </Dialog>

          <div className="dashboard-container">
            <div className="recent-main">
              <div>
                <Breadcrumb>
                  <div
                    style={{
                      cursor: 'pointer',
                      fontSize: 13,
                      fontWeight: 'bold',
                      borderBottom:
                        this.state.link === 'highestMatch'
                          ? '3px solid'
                          : '0px',
                      borderColor:
                        this.state.link === 'highestMatch' ? '#4267B2' : 'grey',
                      color:
                        this.state.link === 'highestMatch'
                          ? '#4267B2'
                          : 'black',
                    }}
                    onClick={() => {
                      this.setState({
                        link: 'highestMatch',
                        animateClass: 'animate',
                      });
                      setTimeout(() => {
                        this.setState({
                          animateClass: '',
                        });
                      }, 900);
                    }}
                  >
                    <p style={{ fontWeight: 'bold', fontSize: 14 }}>
                      Last Review Summary
                    </p>
                  </div>
                </Breadcrumb>
              </div>
              {this.state.link === 'highestMatch' ? (
                <div
                  className={this.state.animateClass}
                  style={{ position: 'relative' }}
                >
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        this.setState({
                          statusUpdateModel: true,
                        });
                      }}
                    >
                      Update Status
                    </MenuItem>
                    <MenuItem>
                      <Model
                        oid={details?.resume?.oid}
                        type="resume"
                        handleClose={this.handleClose}
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        generatePdfbyLink(details?.oid);
                      }}
                    >
                      {this.state.linkLoader ? (
                        <Loader
                          style={{ alignSelfL: 'center', marginLeft: 10 }}
                          type="TailSpin"
                          color="#00BFFF"
                          height={15}
                          width={15}
                        />
                      ) : (
                        'Link'
                      )}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.setState({
                          printModal: true,
                        });
                      }}
                    >
                      Print
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleClickOpenDelete(details.oid)}
                    >
                      Delete
                    </MenuItem>
                    <MenuItem onClick={() => generatePdf(details.oid)}>
                      {this.state.loader1 ? (
                        <Loader
                          style={{ alignSelfL: 'center', marginLeft: 10 }}
                          type="TailSpin"
                          color="#00BFFF"
                          height={15}
                          width={15}
                        />
                      ) : (
                        'View PDF'
                      )}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        reviewSummaryHandler();
                        // this.props.history.push(
                        //   '/save-and-apply',
                        //   details,
                        //   // isScan: true,
                        //   // data: SAMPLE_RESUME,
                        //   // oid: items.oid,
                        //   // temp: "applied",
                        // );
                      }}
                    >
                      Review Summary
                    </MenuItem>
                    {/* <MenuItem>Save to Drive</MenuItem> */}
                  </Menu>

                  <MoreVertIcon
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    className="hovdel"
                    style={styles.mdDelete}
                  />

                  <Row style={{ padding: 10 }}>
                    <Col style={styles.col}>
                      <Chart value={details.data.total_score} />
                    </Col>
                    <Col style={{ textAlign: 'left', minWidth: '270px' }}>
                      {/* <span
                        style={styles.colSpan}
                        onClick={() =>
                          this.props.history.push('/scanresult', details)
                        }
                      >
                        <EditIcon
                          style={{
                            fontSize: 18,
                            color: colors.blue,
                          }}
                        />
                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_bold,
                            color: '#606060',
                            marginLeft: 10,
                          }}
                        >
                          Review info:{' '}
                        </span>
                      </span> */}
                      <div style={{ marginTop: 5 }}>
                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_bold,
                            color: '#606060',
                          }}
                        >
                          Company:{' '}
                        </span>
                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_500,
                            color: '#808080',
                          }}
                        >
                          {details.data.company || 'No Company'}
                        </span>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_bold,
                            color: '#606060',
                          }}
                        >
                          Job Title:{' '}
                        </span>

                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_500,
                            color: '#808080',
                          }}
                        >
                          {details.resume.MainJobTitle || 'No Job Title'}
                        </span>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_bold,
                            color: '#606060',
                          }}
                        >
                          Resume Name:{' '}
                        </span>

                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_500,
                            color: '#808080',
                            wordBreak: 'break-all',
                          }}
                        >
                          {details.resume.MainJobTitle
                            ? `${details.resume.MainJobTitle}_${
                                details.data.company
                              }_${moment(details.createdAt).format(
                                'MMDDYY',
                              )}`?.replaceAll(' ', '')
                            : `no_job_title_${details.data.company}_${moment(
                                details.createdAt,
                              ).format('MMDDYY')}`?.replaceAll(' ', '')}
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_bold,
                            color: '#606060',
                            paddingRight: 4,
                          }}
                        >
                          Review Date:{' '}
                        </span>

                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_500,
                            color: '#808080',
                          }}
                        >
                          {moment(details.createdAt).format('MMM DD, YYYY')}
                        </span>

                        {/* <img src={clip} height={30} width={30} style={{
                          marginLeft: 10
                        }} /> */}
                        <Model
                          oid={details?.resume?.oid}
                          clip="clip"
                          type="resume"
                        />
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <span
                          className={classes.textFont}
                          style={{
                            ...styles.fontWeight_bold,
                            color: '#606060',
                          }}
                        >
                          Job Description:{' '}
                        </span>
                        <Visibility
                          className="hoveld"
                          onClick={() =>
                            this.toggleLarge(
                              this.props.details?.resumeText,
                              this.props.details?.resume?.MainJobTitle,
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col
                      xs="6"
                      sm="3"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        // alignItems: "center",
                      }}
                    >
                      <QRCode value={this.state.downloadLink} />
                    </Col>
                    <Col xs="6" sm="3">
                      <div
                        style={{
                          ...styles.mt_10,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            ...styles.flex,
                            ...styles.spaceAround,
                            ...styles.wrap,
                            gap: 70,
                          }}
                        >
                          <span className={classes.questions}>
                            Job Applied?
                            <span
                              style={{
                                ...styles.flex,
                                ...styles.spaceAround,
                              }}
                            >
                              <IconButton
                                disabled={jobApplied === 'yes'}
                                size="small"
                                onClick={() =>
                                  questionsFunction('yes', 'jobApplied')
                                }
                              >
                                <span
                                  style={{
                                    color: colors.blue,
                                    cursor: 'pointer',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {jobApplied === 'yes' ? (
                                    <span style={{ color: '#9b9b9b' }}>
                                      Yes
                                    </span>
                                  ) : (
                                    <span>Yes</span>
                                  )}
                                </span>
                              </IconButton>
                              <span
                                style={{
                                  color: '#F0F0F0',
                                  border: '.5px solid #F0F0F0',
                                }}
                              />
                              <IconButton
                                disabled={jobApplied === 'no'}
                                size="small"
                                onClick={() =>
                                  questionsFunction('no', 'jobApplied')
                                }
                              >
                                <span
                                  style={{
                                    color: colors.blue,
                                    cursor: 'pointer',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {jobApplied === 'no' ? (
                                    <span style={{ color: '#9b9b9b' }}>No</span>
                                  ) : (
                                    <span>No</span>
                                  )}
                                </span>
                              </IconButton>
                            </span>
                          </span>
                          <span className={classes.questions}>
                            Interview?
                            <span
                              style={{
                                ...styles.flex,
                                ...styles.spaceAround,
                              }}
                            >
                              <IconButton
                                disabled={
                                  isInterview === 'yes' ||
                                  jobApplied === 'no' ||
                                  jobApplied === ''
                                }
                                size="small"
                                onClick={() =>
                                  questionsFunction('yes', 'interview')
                                }
                              >
                                <span
                                  style={{
                                    color: colors.blue,
                                    cursor: 'pointer',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {isInterview === 'yes' ||
                                  jobApplied === 'no' ||
                                  jobApplied === '' ? (
                                    <span style={{ color: '#9b9b9b' }}>
                                      Yes
                                    </span>
                                  ) : (
                                    <span>Yes</span>
                                  )}
                                </span>
                              </IconButton>
                              <span
                                style={{
                                  color: '#F0F0F0',
                                  border: '.5px solid #F0F0F0',
                                }}
                              />
                              <IconButton
                                disabled={
                                  isInterview === 'no' ||
                                  jobApplied === 'no' ||
                                  jobApplied === ''
                                }
                                size="small"
                                onClick={() =>
                                  questionsFunction('no', 'interview')
                                }
                              >
                                <span
                                  style={{
                                    color: colors.blue,
                                    cursor: 'pointer',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {isInterview === 'no' ||
                                  jobApplied === 'no' ||
                                  jobApplied === '' ? (
                                    <span style={{ color: '#9b9b9b' }}>No</span>
                                  ) : (
                                    <span>No</span>
                                  )}
                                </span>
                              </IconButton>
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            marginTop: 20,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <InfoIcon
                            onClick={() => this.setState({ modelOpen: true })}
                            text={'PRO TIP'}
                            icon={'Bulb'}
                            height={20}
                            width="80px"
                            marginTop={-4}
                          />
                          <span
                            style={{
                              marginLeft: '5px',
                              marginRight: '10px',
                              fontSize: '10px',
                              padding: '2px 5px',
                              borderRadius: '5px',
                              border: '1px solid gray',
                              backgroundColor: '#ffffff',
                              fontWeight: '900',
                              fontFamily: 'Roboto',
                            }}
                          >
                            Update status for accurate tracking
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : this.state.link === 'missingSkills' ? (
                <Row
                  className={this.state.animateClass}
                  style={{ padding: '20px' }}
                >
                  <Colxx lg={{ size: 5, offset: 3 }}>
                    <div>
                      <div className="text-center">
                        <p
                          className="lead text-center"
                          style={{
                            marginBottom: 0,
                            ...styles.flex,
                            ...styles.justifyContentCenter,
                            fontSize: 25,
                          }}
                        >
                          {details.data.ats.resumeSkillsMissing > 4 ? (
                            <MdSentimentDissatisfied size="1.2em" />
                          ) : (
                            <MdSentimentSatisfied size="1.2em" />
                          )}
                        </p>
                        <p className="font-weight-semibold mb-0">
                          Skills Missing
                        </p>
                        <p
                          className="lead text-center"
                          style={{
                            color:
                              skills_counting?.['not-exists'] > 4
                                ? colors.red
                                : colors.green,
                          }}
                        >
                          {skills_counting?.['not-exists']}
                        </p>
                      </div>
                    </div>
                  </Colxx>
                </Row>
              ) : (
                <Row
                  className={this.state.animateClass}
                  style={{ marginLeft: 5, marginRight: 5 }}
                >
                  <Colxx lg={{ size: 4 }}>
                    <Card>
                      <CardBody className="text-center">
                        <p className="card-text font-weight-semibold mb-0">
                          Skills
                        </p>
                        <p className="lead text-center">
                          {skills_counting.exists +
                            '/' +
                            skills_counting['total']}
                        </p>
                        <p
                          style={{
                            ...styles.flex,
                            marginBottom: 0,
                            ...styles.justifyContentCenter,
                          }}
                        >
                          {skills_counting.exists <
                          skills_counting['not-exists'] ? (
                            <MdClear size="1.7em" color={colors.red} />
                          ) : (
                            <MdCheck size="1.7em" color={colors.green} />
                          )}
                        </p>
                      </CardBody>
                    </Card>
                  </Colxx>
                  <Colxx lg={{ size: 4 }}>
                    <Card>
                      <CardBody className="text-center">
                        <p className="card-text font-weight-semibold mb-0">
                          ATS
                        </p>
                        <p className="lead text-center">
                          {details.data.scores.ats.exists +
                            '/' +
                            details.data.scores.ats['total']}
                        </p>
                        <p
                          style={{
                            ...styles.flex,
                            marginBottom: 0,
                            ...styles.justifyContentCenter,
                          }}
                        >
                          {details.data.scores.ats.exists <
                          details.data.scores.ats['not-exists'] ? (
                            <MdClear size="1.7em" color={colors.red} />
                          ) : (
                            <MdCheck size="1.7em" color={colors.green} />
                          )}
                        </p>
                      </CardBody>
                    </Card>
                  </Colxx>
                  <Colxx lg={{ size: 4 }}>
                    <Card>
                      <CardBody className="text-center">
                        <p className="card-text font-weight-semibold mb-0">
                          RFindings
                        </p>
                        <p className="lead text-center">
                          {details.data.scores.rfindings.exists +
                            '/' +
                            details.data.scores.rfindings['total']}
                        </p>
                        <p
                          style={{
                            ...styles.flex,
                            marginBottom: 0,
                            ...styles.justifyContentCenter,
                          }}
                        >
                          {details.data.scores.rfindings.exists <
                          details.data.scores.rfindings['not-exists'] ? (
                            <MdClear size="1.7em" color={colors.red} />
                          ) : (
                            <MdCheck size="1.7em" color={colors.green} />
                          )}
                        </p>
                      </CardBody>
                    </Card>
                  </Colxx>
                </Row>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ fileScanReducer, templateReducer }) => {
  return {
    historyDeleteStatus: fileScanReducer.historyDeleteStatus,
    updatortwo: fileScanReducer.updatortwo,
    fedexStatus: fileScanReducer.fedexStatus,
    pdf: templateReducer.pdfLink,
    getPdfStatus: templateReducer.getPdfStatus,
  };
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  deleteHistoryItem,
  EmailParserAction,
  updateQuestionsAction,
  getPdf,
  setPdfStatusFalse,
  addNotesAction,
  updateJobAppliedStatus: updateQuestionsAction,
  EmailParserForScanTables,
  scanHistory,
  SaveActiveIndex,
})(withRouter(withStyles(style)(Dashboard)));
