import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import About from './Modals/About';
import Summary from './Modals/Summary';
import Skills from './Modals/Skills';
import Education from './Modals/Education';
import SecurityCreds from './Modals/SecurityCreds';
import Speaking from './Modals/Speaking';
import Experience from './Modals/Experience';
import Certifications from './Modals/Certifications';
import Publications from './Modals/Publications';
import Volunteer from './Modals/Volunteer';
import Training from './Modals/Training';
import Courses from './Modals/Courses';
import Patents from './Modals/Patents';
import Projects from './Modals/Projects';
import InfoCard from './Modals/InfoCard';
import Awards from './Modals/Awards';
import TestScores from './Modals/TestScores';
import Languages from './Modals/Laguages';
import Organizations from './Modals/Organizations';
import Causes from './Modals/Causes';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '../../assets/infoIcon.webp';
import Tooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import TextIcon from '../../assets/text.webp';
import { getLinkedInData } from './redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '@react-hook/window-size';
import RightIconButton from '../../commonComponents/RightIconButton';
import { colors } from '../../commonComponents/colors';
import { Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subHeading: {
    fontSize: 18,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55vh',
  },
}));

export default function OptimizedProfile(props) {
  const [width] = useWindowSize();
  const classes = useStyles();
  const dispatch = useDispatch();
  const linkedinData = useSelector(
    (state) => state.linkedinReducer.linkedinData,
  );
  const loader = useSelector((state) => state.linkedinReducer.linkedInLoader);

  const user = useSelector((state) => state.authUser.user);

  const cb = () => {
    dispatch({
      type: 'UPDATE_LINKEDIN_LOADER',
      payload: false,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'UPDATE_LINKEDIN_LOADER',
      payload: true,
    });
    dispatch(getLinkedInData(cb));
  }, []);

  const RecruiterLabel = () => {
    return (
      <RightIconButton
        text="RECRUITER FOCUS"
        color={colors.darkYellow}
        imgsrc={TextIcon}
        style={{
          alignSelf: 'center',
          marginLeft: width < 500 ? 0 : 40,
          cursor: 'default',
        }}
      />
    );
  };
  return loader ? (
    <Box className={classes.loader}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      {props.stepIndex === 1 && (
        <>
          <h4 className="font-weight-bold">Review/Edit</h4>
          <h6>Below is the content to update your LinkedIn profile</h6>
        </>
      )}
      {props.stepIndex === 2 && (
        <>
          <h4 className="font-weight-bold">Ready to Copy</h4>
          <h6>Below is the content to update your LinkedIn profile</h6>
        </>
      )}

      <div
        className="mt-4"
        style={{
          display: 'flex',
          flexDirection: width < 991 ? 'column-reverse' : 'row',
          justifyContent: 'space-between',
          // alignItems: "center",
        }}
      >
        <Col
          style={{
            ...(width < 500 && { paddingLeft: 0, paddingRight: 0 }),
          }}
          lg={8}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: width < 500 ? 'column-reverse' : 'row',
                  alignItems: width < 500 ? 'flex-start' : 'center',
                  gap: width < 500 ? 6 : 4,
                }}
              >
                <div
                  style={{
                    fontSize: width < 500 ? 16 : 20,
                    fontWeight: 'bold',
                  }}
                >
                  Intro
                </div>
                <RecruiterLabel />
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',
                      zIndex: 99,
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    <span
                      style={{
                        width: '18px',
                      }}
                    >
                      <Tooltip
                        title="In Intro section,  LinkedIn gives you options to add your basic information. Your LinkedIn headline is the most important part to be optimized. In the Last name you can add all your degrees and certification (Ex: MS, PMP), in the Location (City/Add) add “Greater” or “Metro Area” option. Don’t forget to add email as contact."
                        arrow
                      >
                        <img alt="info" src={InfoIcon} width={'100%'} />
                      </Tooltip>
                    </span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={(event) => {
                        event.stopPropagation();
                        dispatch({
                          type: 'ABOUT_MODAL',
                          payload: true,
                        });
                      }}
                    >
                      <Tooltip title="Create">
                        <CreateIcon
                          style={{ fontSize: 20, marginLeft: 15 }}
                          className="hovdel"
                        />
                      </Tooltip>
                    </span>
                  </span>
                )}
              </div>
            </AccordionSummary>

            <About
              stepIndex={props.stepIndex}
              user={user}
              linkedinData={linkedinData}
            />
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {props.stepIndex === 1 && (
                <span
                  aria-label="Acknowledge"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onFocus={(event) => event.stopPropagation()}
                  style={{
                    position: 'absolute',
                    right: 50,
                    color: 'grey',
                    zIndex: 99,
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'center',
                  }}
                >
                  <span
                    style={{
                      width: '18px',
                    }}
                  >
                    <Tooltip
                      title="About is the summary section and the largest space available. What problem do you solve? This gives the recruiter an overview of your added value. * Introduce yourself and your expertise* Tell your story and build a personal connection.* Address your target audience. * Describe how you can solve problems. * Highlight your value proposition. * Add some credibility/achievement results.* Add your keywords in this section."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={'100%'} />
                    </Tooltip>
                  </span>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'SUMMARY_MODAL',
                        payload: true,
                      });
                    }}
                  >
                    <Tooltip title="Create">
                      <CreateIcon
                        style={{ fontSize: 20, marginLeft: 15 }}
                        className="hovdel"
                      />
                    </Tooltip>
                  </span>
                </span>
              )}

              <div
                style={{ fontSize: width < 500 ? 16 : 20, fontWeight: 'bold' }}
              >
                About
              </div>
            </AccordionSummary>
            <Summary
              stepIndex={props.stepIndex}
              user={user}
              linkedinData={linkedinData}
            />
          </Accordion>
          {/*  */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: width < 500 ? 'column-reverse' : 'row',
                  alignItems: width < 500 ? 'flex-start' : 'center',
                  gap: width < 500 ? 6 : 4,
                }}
              >
                <div
                  style={{
                    fontSize: width < 500 ? 16 : 20,
                    fontWeight: 'bold',
                  }}
                >
                  Background
                </div>
                <RecruiterLabel />
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 85,
                      color: 'grey',
                      zIndex: 99,
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    <span
                      style={{
                        width: '18px',
                      }}
                    >
                      <Tooltip
                        title="
                    The background is one of the core sections to fill out on your profile. This is where you should be adding your keywords."
                        arrow
                      >
                        <img alt="info" src={InfoIcon} width={'100%'} />
                      </Tooltip>
                    </span>
                  </span>
                )}
              </div>
            </AccordionSummary>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="Describe how you contributed, and also mention your keywords in your experience description. Your experience section is also a good place to talk about your work with clients and the company you were in.Focus on the best achievements related to your career."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'EXPERIENCE_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Work Experience</div>
              </AccordionSummary>
              <Experience
                stepIndex={props.stepIndex}
                workData={linkedinData?.work}
              />
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="
                      Add degree(s) here. Adding your education details adds value to your profile and also helps with the LinkedIn search. So do not skip this part."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'EDUCATION_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Education</div>
              </AccordionSummary>
              <Education
                stepIndex={props.stepIndex}
                educatioData={linkedinData?.educationHistory}
              />
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="If you’ve any special licenses or certifications that add value to your profile and profession, then make sure to add it to your profile."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'CERTIFICATIONS_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>
                  License & Certifications
                </div>
              </AccordionSummary>
              <Certifications
                stepIndex={props.stepIndex}
                certData={linkedinData?.certificationAndLicense}
              />
            </Accordion>
            {/* Volunteer */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="This may seem unnecessary, but adding your volunteer experience reflects your personality, and it just adds more value to your LinkedIn profile."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'VOLUNTEER_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Volunteer Experience</div>
              </AccordionSummary>
              <Volunteer
                stepIndex={props.stepIndex}
                linkedinData={linkedinData}
              />
            </Accordion>
            {/* Security Creds Starts */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="This may seem unnecessary, but adding your volunteer experience reflects your personality, and it just adds more value to your LinkedIn profile."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();

                      dispatch({
                        type: 'SECURITY_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Security Clearance</div>
              </AccordionSummary>
              <SecurityCreds
                stepIndex={props.stepIndex}
                linkedinData={linkedinData}
              />
            </Accordion>

            {/* Security Creds Ends */}
            {/* Speaking Creds Starts */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="This may seem unnecessary, but adding your volunteer experience reflects your personality, and it just adds more value to your LinkedIn profile."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();

                      dispatch({
                        type: 'SPEAKING_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Speaking</div>
              </AccordionSummary>
              <Speaking
                stepIndex={props.stepIndex}
                linkedinData={linkedinData}
              />
            </Accordion>

            {/* Speaking Creds Ends */}
            {/* Training Creds Starts */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="This may seem unnecessary, but adding your volunteer experience reflects your personality, and it just adds more value to your LinkedIn profile."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();

                      dispatch({
                        type: 'TRAINING_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Training</div>
              </AccordionSummary>
              <Training
                stepIndex={props.stepIndex}
                linkedinData={linkedinData}
              />
            </Accordion>

            {/* Training Ends */}
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              {' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: width < 500 ? 'column-reverse' : 'row',
                  alignItems: width < 500 ? 'flex-start' : 'center',
                  gap: width < 500 ? 6 : 4,
                }}
              >
                <div
                  style={{
                    fontSize: width < 500 ? 16 : 20,
                    fontWeight: 'bold',
                  }}
                >
                  Skills
                </div>
                <RecruiterLabel />
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',
                      zIndex: 99,
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    <span
                      style={{
                        width: '18px',
                      }}
                    >
                      <Tooltip
                        title="
                    Skills and endorsements help people know about the various skills you have related to your industry or profession. LinkedIn provides you with the option to add skills to your profile."
                        arrow
                      >
                        <img alt="info" src={InfoIcon} width={'100%'} />
                      </Tooltip>
                    </span>
                    {props.stepIndex === 1 && (
                      <span
                        aria-label="Acknowledge"
                        onClick={(event) => {
                          event.stopPropagation();
                          dispatch({ type: 'SKILLS_MODAL', payload: true });
                        }}
                        onFocus={(event) => event.stopPropagation()}
                        style={{ cursor: 'pointer' }}
                      >
                        <Tooltip title="Create">
                          <CreateIcon
                            style={{ fontSize: 20, marginLeft: 15 }}
                            className="hovdel"
                          />
                        </Tooltip>
                      </span>
                    )}
                  </span>
                )}
              </div>
            </AccordionSummary>
            <Skills
              stepIndex={props.stepIndex}
              skillsData={linkedinData?.skills}
            />
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {props.stepIndex === 1 && (
                <span
                  aria-label="Acknowledge"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onFocus={(event) => event.stopPropagation()}
                  style={{
                    position: 'absolute',
                    right: width < 500 ? 50 : 85,
                    color: 'grey',
                    zIndex: 99,
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'center',
                  }}
                >
                  <span
                    style={{
                      width: '18px',
                    }}
                  >
                    <Tooltip
                      title="
                    The accomplishments section is the place to let people know more about your specific details in areas not covered anywhere."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={'100%'} />
                    </Tooltip>
                  </span>
                </span>
              )}
              <div
                style={{ fontSize: width < 500 ? 16 : 20, fontWeight: 'bold' }}
              >
                Accomplishments
              </div>
            </AccordionSummary>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="List any publications from your work experience or from academic or research publications."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({ type: 'PUBLICATIONS_MODAL', payload: true });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Publications</div>
              </AccordionSummary>
              <Publications
                stepIndex={props.stepIndex}
                pubData={linkedinData?.publication}
              />
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="All Pending or awarded patents " arrow>
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({ type: 'PATENTS_MODAL', payload: true });
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Patents</div>
              </AccordionSummary>
              <Patents
                stepIndex={props.stepIndex}
                petData={linkedinData?.patents}
              />
            </Accordion>
            {/* Courses Here */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="This is the section where you want to list all important courses for college graduates with all keywords, or courses taken during work experience."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'COURSES_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => {
                      event.stopPropagation();
                    }}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Courses</div>
              </AccordionSummary>
              <Courses
                stepIndex={props.stepIndex}
                linkedinData={linkedinData}
              />
            </Accordion>
            {/* Projects Here */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="College graduates can list all projects done during your college years or list special projects during your work experience."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'PROJECT_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => {
                      event.stopPropagation();
                    }}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Projects</div>
              </AccordionSummary>
              <Projects
                stepIndex={props.stepIndex}
                projectData={linkedinData?.trainHist}
              />
            </Accordion>
            {/* Awards Here */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Any or all Honors & awards go here." arrow>
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'AWARDS_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => {
                      event.stopPropagation();
                    }}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Honors & Awards</div>
              </AccordionSummary>
              <Awards stepIndex={props.stepIndex} linkedinData={linkedinData} />
            </Accordion>
            {/* Test Scores Here */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="Highlight any test scores in this area."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'TESTS_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => {
                      event.stopPropagation();
                    }}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Test Scores</div>
              </AccordionSummary>
              <TestScores
                stepIndex={props.stepIndex}
                linkedinData={linkedinData}
              />
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="If you are multi-lingual this is the place to highlight your language skills"
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'LANGUAGES_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => {
                      event.stopPropagation();
                    }}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Languages</div>
              </AccordionSummary>
              <Languages
                stepIndex={props.stepIndex}
                linkedinData={linkedinData}
              />
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="List all organizations you are currently associated and in the past."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'ORGANIZATIONS_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => {
                      event.stopPropagation();
                    }}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Organizations</div>
              </AccordionSummary>
              <Organizations
                stepIndex={props.stepIndex}
                linkedinData={linkedinData}
              />
            </Accordion>
            {/* CAUSES Here */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    style={{
                      position: 'absolute',
                      right: 80,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip
                      title="Any work done for special causes go here."
                      arrow
                    >
                      <img alt="info" src={InfoIcon} width={20} />
                    </Tooltip>
                  </span>
                )}

                {props.stepIndex === 1 && (
                  <span
                    aria-label="Acknowledge"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch({
                        type: 'CAUSES_MODAL',
                        payload: {
                          open: true,
                          mode: 'new',
                        },
                      });
                    }}
                    onFocus={(event) => {
                      event.stopPropagation();
                    }}
                    style={{
                      position: 'absolute',
                      right: 50,
                      color: 'grey',

                      zIndex: 99,
                    }}
                  >
                    <Tooltip title="Add">
                      <AddIcon />
                    </Tooltip>
                  </span>
                )}

                <div className={classes.subheading}>Causes</div>
              </AccordionSummary>
              <Causes stepIndex={props.stepIndex} linkedinData={linkedinData} />
            </Accordion>
          </Accordion>
        </Col>
        <Col
          style={{
            ...(width < 500 && { paddingLeft: 0, paddingRight: 0 }),
          }}
          lg={4}
        >
          {props.stepIndex === 1 && (
            <InfoCard
              heading={'Time to edit content for your LinkedIn Profile'}
              message={
                "Based on your resume and reviews with job description(s), we pre-populated some fields already. But you need to review, edit and add content limited by word count per each field. Pay special attention to 'Recruiter' icon fields which are what recruiters look to search you. Take your time to complete all sections and click 'NEXT' to start the copying to your actual LInkedIn account."
              }
            />
          )}
          {props.stepIndex === 2 && (
            <InfoCard
              heading={'Copy content to your Linkedin profile NOW!!'}
              message={
                "Open LinkedIn.com in a new tab on your browser and go to Me>View Profile and update each section from Resumás. Start with Intro section and copy each item and paste into same section in the Linkedin profile. Double check to see if you copied everything and click 'NEXT' button."
              }
            />
          )}
        </Col>
      </div>
    </>
  );
}
