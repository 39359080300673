import React, { useEffect } from 'react';
import Chips from '../Chips';
import useMilitaryCrosswalk from '../useMilitaryCrosswalk';
import ScanAnimation from '../../../commonComponents/scanLoader';
const SelectOccupations = ({}) => {
  const {
    jobOccupation,
    jobOccupationLoader,
    selectedJobOccupations,
    onAddJobOccupation,
    onRemoveJobOccupation,
    width,
  } = useMilitaryCrosswalk();
  const [loader, setLoader] = React.useState(true);
  useEffect(() => {
    setLoader(true);
    if (jobOccupation?.length > 0) {
      setLoader(false);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 200);
    }
  }, []);
  return (
    <div>
      {jobOccupationLoader || loader ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40vh',
          }}
        >
          <ScanAnimation isJobTitle={true} />
        </div>
      ) : (
        <Chips
          width={width}
          type={'string'}
          items={jobOccupation}
          onRemoveItem={onRemoveJobOccupation}
          onAddItem={onAddJobOccupation}
          selectedItems={selectedJobOccupations}
          stepImg={true}
          stepNo={5}
          heading={'Civilian Job Titles: Please Pick 3'}
          subtitle={`Explore a diverse range of career options and discover your interests by
        selecting three job occupations that resonate with you. Your choices
        will help tailor our recommendations to your preferences.`}
          emptyContent={` Please enter your Military Branch Name and Military Occupational Code
      (MOC) or Title above to retrieve a list of relevant job occupations.`}
        />
      )}
    </div>
  );
};

export default SelectOccupations;
