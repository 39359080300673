import React, { forwardRef, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  ModalHeader,
  Modal,
  ModalBody,
  Col,
} from 'reactstrap';
import QRCode from 'qrcode.react';
import { BiCopy } from 'react-icons/bi';
import { Colxx } from '../../commonComponents/customBootstrap';
import USAFlag from '../../assets/usa.jpg';
import Play from '../../assets/play.png';
import {
  scanResultsReload,
  SaveActiveIndex,
  EmailParserAction,
  updateQuestionsA,
  sendTextToEmailOrMobile,
  downloadResumePdf,
  updateFileName,
  updateMasterResuma,
  sendMail,
  addNotesAction,
  generateCoverLetter,
  sendEmailCoverLetter,
  getSuggestionEmails,
  deleteSuggestionEmail,
  updateSuggestionEmail,
  getCompanyInfo,
  getOnetData,
  addEmailCoverLetter,
  sendAllEmailCoverLetter,
  getFollowUpEmail,
  sendMeEmail,
  getSingleCoverLetter,
} from '../../commonRedux/scan/action';
import {
  getCoverLetters,
  UpdateCoverLetter,
  getPdf,
  setDefault,
} from '../../commonRedux/template/action';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useLocation, withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Input, Button, Spin } from 'antd';
import Select from 'react-select';
import Model from '../manage/notes-model';
import { saveToken, backupYouResumeAction } from '../auth/redux/actions';
import ShowCover from './show-cover-letter';
import toastr from 'toastr';
import Dialog from '@material-ui/core/Dialog';
import MuiButton from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { styles } from './style';
import Switch from 'react-ios-switch';
import InfoIcon from '../../commonComponents/InfoIcon';
import { getScoreColor } from '../../utils/getScoreColor';
import { useWindowWidth, useWindowSize } from '@react-hook/window-size';
import FedexIcon from '../../assets/fedex.webp';
import CopyIcon from '../../assets/svg/CopyIcon';
import MobileIcon from '../../assets/mobileIcon.webp';
import MsgIcon from '../../assets/msgIcon.webp';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PdfIcon from '../../assets/pdfIcon.svg';
import DownloadIcon from '../../assets/svg/DownloadIcon';
import PrintIcon from '../../assets/svg/PrintIcon';
import StoreIcon from '@material-ui/icons/Store';
import ScanIcon from '../../assets/svg/ScanIcon';
import {
  IconButton,
  makeStyles,
  Tooltip,
  Button as Mbutton,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
import tickIcon from '../../assets/Check.webp';
import Loader from 'react-loader-spinner';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '../../commonComponents/colors';
import VideoModel from '../../commonComponents/videoModel';
import Lottie from 'lottie-react';
import PopAnimation from '../../assets/animations/popAnimation.json';
import Highlighter from 'react-highlight-words';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ViewIcon from '../../assets/svg/ViewIcon';
import InfoModal from '../../commonComponents/infoModal/InfoModal';
import { validateEmail } from '../../utils/emailValidation';
import { Cancel, MailOutline } from '@material-ui/icons';
import { getTimeProps } from 'antd/lib/date-picker/generatePicker';
import { AiOutlineMail } from 'react-icons/ai';
import NewMessageIcon from '../../assets/svg/NewMessageIcon';
import CancelIcon from '@material-ui/icons/Cancel';
import url from '../../config/endpoint';
import axios from 'axios';
import UpgradeModal from '../../commonComponents/UpgradeModal';
import WordIcon from '../../assets/svg/WordIcon';
import TxtIcon from '../../assets/svg/TxtIcon';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
const { TextArea } = Input;

const EmailComponent = forwardRef(
  (
    { item, updateEmailHandler, emailSend, onDeleteClick, sendingEmail },
    ref,
  ) => {
    const [email, setEmail] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [delId, setDelId] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleDelete = () => {
      setOpen(true);
      setDelId(item.mailId);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: 10,
          }}
          ref={ref}
        >
          {isEdit ? (
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          ) : (
            <span
              style={{
                fontSize: 14,
                color: 'black',
                cursor: 'pointer',
              }}
            >
              {item.senderMail}
            </span>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton>
              {isEdit ? (
                <DoneIcon
                  style={{
                    fontSize: '18px',
                    marginLeft: '5px',
                    color: 'green',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!email) {
                      toastr.error('Please enter email');
                      return;
                    }

                    if (!validateEmail(email)) {
                      toastr.error('Please enter valid email');
                      return;
                    }

                    updateEmailHandler({
                      email,
                      mailId: item.mailId,
                    });
                    setIsEdit(false);
                  }}
                />
              ) : (
                <EditIcon
                  style={{
                    fontSize: '18px',
                    marginLeft: '5px',
                    color: 'green',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setEmail(item.senderMail);
                    setIsEdit(true);
                  }}
                />
              )}
            </IconButton>
            <IconButton onClick={handleDelete}>
              <DeleteIcon
                style={{
                  color: colors.red,
                }}
              />
            </IconButton>
          </div>
        </div>

        <Dialog
          fullWidth
          maxWidth={'xs'}
          open={open}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div
            id="alert-dialog-slide-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '10px 20px',
            }}
          >
            <h5
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: 20,
                fontFamily: 'Nunito',
              }}
            >
              Delete Email
            </h5>
            <CancelIcon
              style={{
                color: colors.red,
                cursor: 'pointer',
                fontSize: 24,
              }}
              onClick={handleClose}
            />
          </div>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this email?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MuiButton
              onClick={() => {
                onDeleteClick(delId);
                handleClose();
                setDelId('');
              }}
              color="secondary"
              variant="contained"
              style={{
                backgroundColor: colors.red,
                height: 40,
              }}
            >
              Delete
            </MuiButton>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const { Option } = Select;

var pdfDetails = null;

function stripHtml(html, replaceText) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  let searchString = tmp?.textContent || tmp?.innerText || '';
  if (replaceText) {
    let regex = /Additional Skills/;
    searchString = searchString.replace(regex, 'Additional Skills\n');
  }
  return searchString;
}

const useToolTipStyles = makeStyles(() => ({
  tooltip: {
    border: '1px solid gray',
    backgroundColor: '#ffffff',
    color: '#000000',
  },
}));

const ViewButton = (props) => {
  return (
    <button
      style={{
        display: 'flex',
        padding: '3px 0',
        justifyContent: 'center',
        fontWeight: 'bold',
        borderRadius: 5,
        backgroundColor: '#F38322',
        color: '#FFFFFF',
        boxSizing: 'border-box',
        width: 135,
        marginTop: -13,
      }}
      onClick={() => props.handleInfoModal(props.jobKey)}
    >
      {props.title}
    </button>
  );
};

const SaveAndApply = (props) => {
  const location = useLocation();
  const [scanResult, setScanResult] = React.useState({});
  const [selectedTemplate, setselectedTemplate] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [title, setTitle] = React.useState('N/A');
  const [alreadySent, setAlreadySent] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [downloadLink, setDownloadLink] = React.useState('');
  const [savedName, setSavedName] = React.useState('');
  const [oidd, setOidd] = React.useState('');
  const [modalLarge, setmodalLarge] = React.useState(false);
  const [modalLarge2, setmodalLarge2] = React.useState(false);
  const [mobileLoader, setMobileLoader] = React.useState(false);
  const [emailLoader, setEmailLoader] = React.useState(false);
  const [docs, setDocs] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [width, height] = useWindowSize();
  const [message, setMessage] = React.useState('');
  const [showConfetti, setShowConfetti] = React.useState(false);
  const [printModal, setPrintModal] = React.useState(false);
  const [printType, setPrintType] = React.useState('local');
  const [modelOpen, setModelOpen] = React.useState(false);
  const [govtJobModalOpen, setGovtJobModal] = React.useState(false);
  const [isFollowUpVideo, setIsFollowUpVideo] = React.useState(false);
  // const [printLoader, setPrintLoader] = React.useState(false);
  const [skillKey, setSkillKey] = React.useState('');
  // new code
  const [open1, setOpen1] = React.useState(false);
  const [loader1, setLoader1] = React.useState(false);
  const [coverLetterLoader, setCoverLetterLoader] = React.useState(false);
  const [notes, setNote] = React.useState('');
  const [jobOpen, setJobOpen] = React.useState(false);
  const [newEmailLoader, setNewEmailLoader] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [isInfoOpen, setIsInfoOpen] = React.useState(false);
  const [infoKey, setInfoKey] = React.useState('');
  const [mailLoader, setMailLoader] = React.useState('');
  const [viewEmail, setViewEmail] = React.useState(false);
  const [previewContent, setPreviewContent] = React.useState('');
  const [subscriptionModal, setSubscriptionModal] = React.useState(false);
  const [govtJobFormModal, setGovtJobFormModal] = React.useState(false);
  const [places, setPlaces] = React.useState('');
  const [multiLocations, setMultiLocations] = React.useState([]);
  const [isFocused, setIsFocused] = React.useState(false);
  const { _id: userOid, ...user } = useSelector(
    (state) => state?.authUser?.user,
  );

  const [followUpLoader, setFollowUpLoader] = React.useState(false);
  const [sendMeFollowUpLoader, setSendMeFollowUpLoader] = React.useState(false);
  const [sendMeCoverLoader, setSendMeCoverLoader] = React.useState(false);
  const [followUpLoading, setFollowUpLoading] = useState(false);
  //
  const [govtJobTitle, setGovtJobTitle] = useState('');
  const [gradeApplying, setGradeApplying] = useState('');
  const [announcementNumber, setAnnouncementNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [countryOfCitizenship, setCountryOfCitizenship] = useState('USA');
  const [veteransPreferences, setVeteransPreferences] = useState('None');
  const [otherPreferences, setOtherPreferences] = useState('None');
  const [federalCivilianStatus, setFederalCivilianStatus] = useState('None');
  const [securityClearance, setSecurityClearance] = useState('None');
  const [hoursPerWeek, setHoursPerWeek] = useState('40');
  const [fileLinks, setFileLinks] = useState(null);
  const [fileLinkLoading, setFileLinksLoading] = useState(false);
  const [fileType, setFileType] = useState('pdf');
  const handleInputChange = (event, setter) => {
    setter(event.target.value);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleChange = (address) => {
    setPlaces(address);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        setMultiLocations([
          ...this.state.multiLocations,
          results[0].address_components[0].short_name,
        ]);
      })

      .catch((error) => console.error('Error', error));
  };
  const handleClose1 = () => {
    setOpen1(false);
    setLoader1(false);
  };
  const submit = () => {
    if (notes.trim() == '') {
      toastr.error('please add some note!');
    } else {
      setLoader1(true);
      let data = {
        type: props.type || 'resume',
        note: notes,
        oid: location?.state?.oid || props.scanResult?.oid,
      };
      props.addNotesAction(data, handleClose1);
    }
  };

  const VideoLinks = useSelector((state) => state.fileScanReducer?.videoLinks);
  const isMailSent = useSelector((state) => state.fileScanReducer?.isMailSent);
  const { companyInfo, infoLoader, onetData, followUpEmail } = useSelector(
    (state) => state.fileScanReducer,
  );
  const coverLetterData = useSelector(
    (state) => state?.fileScanReducer?.scanResult?.jobDescription,
  );
  const followUpEmailContent = useSelector(
    (state) => state?.fileScanReducer?.followUpEmail,
  );
  const userData = useSelector((state) => state?.authUser?.user);
  const { firstName, lastName } = userData || {};

  const [previewSubject, setPreviewSubject] = React.useState('');
  const suggestionEmails = useSelector(
    (state) => state?.authUser?.suggestionEmails,
  );
  const setPreviewSubjectHandler = () => {
    const subject = `${firstName} ${lastName}'s Application for ${
      props.scanResult?.ats?.ats_data?.job_title_matched?.requires || ''
    } Role`;
    setPreviewSubject(subject);
  };
  const setPreviewContentHandler = () => {
    if (!followUpEmailContent) return;
    setPreviewContent(stripHtml(followUpEmailContent));
  };
  useEffect(() => {
    setPreviewSubjectHandler();
  }, [
    firstName,
    lastName,
    props.scanResult?.ats?.ats_data?.job_title_matched?.requires,
  ]);
  const tooltipClasses = useToolTipStyles();
  const [shouldBlockNavigation, setshouldBlockNavigation] =
    React.useState(false);
  // const width = useWindowWidth();
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const onDeleteClick = (mailId) => {
    dispatch(deleteSuggestionEmail(mailId));
  };

  const updateEmailHandler = ({ email, mailId }) => {
    const data = {
      email,
      mailId,
    };
    dispatch(updateSuggestionEmail(data));
  };
  const handleClickOpen = (open, message) => {
    setMessage(message);
    setOpen(open);
  };

  function downloadResume() {
    const element = document.createElement('a');
    const file = new Blob(
      [stripHtml(props.scanResult?.CompletePlainText || '', true)],
      {
        type: 'text/plain',
      },
    );
    element.href = URL.createObjectURL(file);
    element.download = `${savedName}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
  function downloadCoverLetter() {
    if (props.resume?.coverLetter) {
      const element = document.createElement('a');
      const file = new Blob([props.resume?.coverLetter?.description || ''], {
        type: 'text/plain',
      });
      element.href = URL.createObjectURL(file);
      element.download = `${props?.resume?.jobTitle}.txt`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  }

  function printCoverLetter() {
    // Create a new window for printing
    if (props.resume?.coverLetter?.description) {
      var printWindow = window.open('', '');

      // Add the custom string to the new window in paragraph tags
      printWindow.document.write(
        '<p>' + props.resume?.coverLetter?.description + '</p>',
      );

      // Print the new window
    }
    printWindow.print();
  }

  function openUrl(fileType) {
    if (fileType === 'pdf' && pdfUrl) {
      window.open(`${pdfUrl}`, '_blank');
    } else {
      if (docs) {
        window.open(`${docs}`, '_blank');
      }
    }
  }

  const onChangeR = (i) => {
    if (i && props.resumaId !== i) {
      props.updateMasterResuma({ oid: i });
      localStorage.setItem('masterTemplate', i);
    }
  };
  const checkFollowUpEmail = async () => {
    try {
      let data = {
        resumeId: props.scanResult?.oid,
      };
      const res = await axios.post(`${url}/get-follow-up-email`, data);
      if (res?.data?.emailData?.content && res.status == 200) {
        setPreviewContent(res?.data?.emailData?.content);
        setPreviewSubject(res?.data?.emailData?.subject);
        dispatch({
          type: 'FOLLOWUP_EMAIL',
          payload: res?.data?.emailData?.content,
        });
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const convertResume = async () => {
    if (
      !url ||
      !oidd ||
      !govtJobTitle ||
      !gradeApplying ||
      !announcementNumber ||
      !phone ||
      !multiLocations ||
      !countryOfCitizenship ||
      !veteransPreferences ||
      !otherPreferences ||
      !federalCivilianStatus ||
      !securityClearance ||
      !hoursPerWeek
    ) {
      toastr.error('Please fill in all required fields.');
      return;
    }
    setFileLinksLoading(true);
    const data = {
      url,
      oid: oidd,
      govtJobTitle,
      gradeApplying,
      announcementNumber,
      phone,
      address: multiLocations?.value,
      countryOfCitizenship,
      veteransPreferences,
      otherPreferences,
      federalCivilianStatus,
      securityClearance,
      hoursPerWeek: hoursPerWeek + 'hrs',
      availability: 'now',
    };
    localStorage.setItem('govtResume', JSON.stringify(data));
    const res = await axios.post(`${url}/generate-government-resume`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('clientAccessToken')}`,
      },
    });
    if (res?.status === 200) {
      setFileLinks(res?.data);
      setFileLinksLoading(false);
      setGovtJobFormModal(false);
      setGovtJobTitle('');
      setGradeApplying('');
      setAnnouncementNumber('');
      setPhone('');
      setMultiLocations('');
      setCountryOfCitizenship('');
      setVeteransPreferences('');
      setOtherPreferences('');
      setFederalCivilianStatus('');
      setSecurityClearance('');
      setHoursPerWeek('');
    } else {
      toastr.error('Error in Generating Government Resume');
      setFileLinksLoading(false);
    }
  };
  const getGeneratedResume = async () => {
    if (!oidd) {
      toastr.error('oid Required');
      return;
    }
    const data = {
      isResumeConverted: true,
      oid: oidd,
    };
    const res = await axios.post(`${url}/generate-government-resume`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('clientAccessToken')}`,
      },
    });
    if (res?.status === 200) {
      setFileLinks(res?.data);
    } else {
      toastr.error('Error in Generating Government Resume');
    }
  };
  useEffect(() => {
    if (oidd && !govtJobFormModal) {
      getGeneratedResume();
    }
  }, [oidd, govtJobFormModal]);
  const downloadGeneratedResume = (type) => {
    if (type && oidd && fileLinks) {
      window.open(
        `${process.env.REACT_APP_API_URL}/${fileLinks[type]}`,
        '_blank',
      );
    }
  };
  React.useEffect(() => {
    if (props.scanResult?.oid && previewContent == '') {
      checkFollowUpEmail();
    }
  }, []);

  const updateFollowupEmail = async () => {
    try {
      setFollowUpLoading(true);
      let data = {
        resumeId: props.scanResult?.oid,
        data: {
          subject: previewSubject || '',
          content: previewContent || '',
        },
      };
      const res = await axios.post(`${url}/add-follow-up-email`, data);
      if (res) {
        toastr.success(res?.data?.message);
        setViewEmail(false);
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message);
    } finally {
      setFollowUpLoading(false);
    }
  };
  React.useEffect(() => {
    props.SaveActiveIndex(4);
  }, [props.SaveActiveIndex]);

  React.useEffect(() => {
    if (localStorage.getItem('myScanId')) {
      let get = localStorage.getItem('myScanId');
      props.scanResultsReload('not done', get);
    }
  }, [localStorage.getItem('myScanId')]);

  React.useEffect(() => {
    if (props.user_template) {
      setselectedTemplate(props.user_template);
    }
  }, [props.user_template]);

  React.useEffect(() => {
    props.getCoverLetters();
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (props.scanResult) {
      setScanResult(props.scanResult);
      setOidd(location?.state?.oid || props?.scanResult?.oid);
      setSavedName(props.resume?.resumeName);
    }
  }, [props.scanResult]);

  React.useEffect(() => {
    setTitle(
      props.resume?.coverLetter
        ? props.resume?.coverLetter?.title || props.resume?.jobTitle
        : 'N/A',
    );
  }, [props.resume?.coverLetter, props.resume?.jobTitle]);

  React.useEffect(() => {
    if (props.activeStep === 4 && oidd !== '') {
      if (pdfDetails) {
        setPdfUrl(
          `${process.env.REACT_APP_API_URL}/${pdfDetails.pdf}` ||
            pdfDetails?.cloundinary,
        );
        setDocs(`${process.env.REACT_APP_API_URL}/${pdfDetails.path_doc}`);
        setDownloadLink(`${window.location.host}/${pdfDetails.download}`);
      } else {
        generatePdf(oidd);
      }
    }
  }, [props.activeStep, oidd]);

  React.useEffect(() => {
    if (oidd) {
      generatePdf(oidd);
      dispatch(getSingleCoverLetter(oidd));
    }
  }, [oidd]);

  React.useEffect(() => {
    if (userOid) {
      dispatch(getSuggestionEmails({ userOid }));
    }
  }, [userOid]);

  React.useEffect(() => {
    if (oidd && downloadLink !== '' && !alreadySent) {
      sendResumeTo();
      setAlreadySent(true);
    }
  }, [downloadLink, oidd]);

  function downloadedPdfHandler(pdf) {
    setLoader(false);
    if (pdf) {
      pdfDetails = pdf;
      setPdfUrl(`${process.env.REACT_APP_API_URL}/${pdfDetails.pdf}`);
      setDocs(`${process.env.REACT_APP_API_URL}/${pdfDetails.path_doc}`);
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_API_URL}/${pdfDetails.pdf}`,
      );
      setDownloadLink(`${window.location.host}/${pdfDetails.download}`);
      props.setDefault();
    }
  }
  const checkIsPremium = () => {
    if (!user?.subscription || user?.payType === '' || !user?.isPremium) {
      return false;
    } else {
      return true;
    }
  };
  const toggleSubscription = () => setSubscriptionModal(!subscriptionModal);
  const generateCover = async () => {
    if (!checkIsPremium()) toggleSubscription();
    else {
      let fullName = `${userData?.firstName} ${userData?.lastName}`;

      let data = {
        oid: oidd,
        cover_letter: coverLetterData,
        name: fullName,
      };
      setCoverLetterLoader(true);

      dispatch(generateCoverLetter(data, setCoverLetterLoader));
    }
  };

  const emailSend = async (senderMail) => {
    let data = {
      senderMail: senderMail,
      userOid: userOid,
      oid: props.scanResult.oid,
    };

    setMailLoader(senderMail);

    if (props.resume?.response?.coverLetterId) {
      data['coverLetterOid'] = props.resume?.response?.coverLetterId;
    }
    dispatch(sendEmailCoverLetter(data, setMailLoader));
  };

  const sendMeFollowUpEmail = async (senderMail) => {
    setSendMeFollowUpLoader(true);
    let tempFollowUpEmail = '';

    if (previewContent) {
      tempFollowUpEmail = previewContent;
    } else {
      let role = props.scanResult?.ats?.ats_data?.job_title_matched?.requires;
      let resumeId = props.scanResult?.oid;
      tempFollowUpEmail = await props.getFollowUpEmail(resumeId, role);
    }

    await sendMeEmail(tempFollowUpEmail, previewSubject);
    setSendMeFollowUpLoader(false);
  };

  const sendMeCoverEmail = async (senderMail) => {
    setSendMeCoverLoader(true);
    await sendMeEmail(
      props.resume?.coverLetter
        ? stripHtml(props.resume?.coverLetter?.description)
        : '',
    );
    setSendMeCoverLoader(false);
  };

  const emailSendAll = async () => {
    let data = {
      userOid: userOid,
      oid: props.scanResult.oid,
    };

    if (props.resume?.response?.coverLetterId) {
      data['coverLetterOid'] = props.resume?.response?.coverLetterId;
    }
    dispatch(sendAllEmailCoverLetter(data));
  };

  const emailAdd = async () => {
    if (!email) {
      toastr.error('Please enter email');
      return;
    }

    if (!validateEmail(email)) {
      toastr.error('Please enter valid email');
      return;
    }

    if (
      !suggestionEmails?.every(
        (item) =>
          item?.senderMail?.trim()?.toLowerCase() !==
          email?.trim()?.toLowerCase(),
      )
    ) {
      toastr.error('Email already exist');
      return;
    }

    setOpen2(false);
    let data = {
      senderMail: email,
      userOid: userOid,
    };
    setNewEmailLoader(true);
    dispatch(addEmailCoverLetter(data, setNewEmailLoader));
    setEmail('');
  };

  const generatePdf = async (id, hideLoader) => {
    if (!hideLoader) {
      setLoader(true);
    }
    props.downloadResumePdf(
      {
        oid: id,
        is_scan: 1,
        temp_id: 'manager',
        token: '',
      },
      downloadedPdfHandler,
    );
  };
  const resumeNameHandler = () => {
    props.updateFileName(savedName, oidd);
    setTimeout(() => {
      generatePdf(oidd);
    }, 1000);
  };

  const toggleLarge = () => {
    setmodalLarge(!modalLarge);
  };
  const toggleGovtJobFormModal = () => {
    setGovtJobFormModal(!govtJobFormModal);
  };

  const toggleLarge2 = () => {
    setmodalLarge2(!modalLarge2);
  };

  const sendAndUpdateCoverLetter = async (title) => {
    // if (!props?.detail?.isDefault) {
    let data = {
      oid: props.resume?.oid,
      title: title,
      description: previewContent,
    };
    props.UpdateCoverLetter(data, () => {
      emailSend(user?.email);
    });
    // }
  };

  const getFollowUpEmail = async () => {
    setFollowUpLoader(true);
    let role = props.scanResult?.ats?.ats_data?.job_title_matched?.requires;
    let resumeId = props.scanResult?.oid;
    const res = await props.getFollowUpEmail(resumeId, role);
    setFollowUpLoader(false);
    return res;
  };

  const questionsFunction = (condition, type) => {
    let getId = localStorage.getItem('myScanId');
    let data = {
      type: type,
      oid: getId,
      isTrue: condition,
    };
    props.updateQuestionsA(data);
  };

  React.useEffect(() => {
    if (props.scanResult) {
      if (
        props.scanResult?.jobApplied === 'yes' ||
        props.scanResult?.jobApplied === 'no'
      ) {
        setshouldBlockNavigation(false);
      } else {
        setshouldBlockNavigation(true);
      }
    }
  }, [props.scanResult && props.scanResult?.jobApplied]);

  React.useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [shouldBlockNavigation]);

  const sendTextTo = async (type) => {
    if (type === 'mobile') {
      setMobileLoader(true);
      let data = {
        type: type,
        body: `Resume Name: ${savedName}\nReview Score:${
          scanResult.total_score
        }\nCreated At: ${scanResult.createdAt}\nUpdated At: ${
          scanResult.updatedAt
        }\nTemplate you choosed: ${selectedTemplate}\nPdf URL: ${pdfUrl.replaceAll(
          ' ',
          '%20',
        )}`,
      };
      props.sendTextToEmailOrMobile(data);
    } else if (type === 'email') {
      setEmailLoader(true);

      let html = `<html>
      <h1>Optimization Summary</h1>
      <table border="1" cellpadding="1" cellspacing="1" style="width: 500px">
        <tbody>
        
          <tr>
            <td colspan="2" style="text-align: center"><strong>Job Info</strong></td>
          </tr>
          <tr>
            <td style="text-align: center; width: 237px">
              <strong>Job Title</strong>
            </td>
            <td style="text-align: center; width: 249px">${
              props.scanResult?.ats?.ats_data?.job_title_matched?.requires
            }</td>
          </tr>
          <tr>
            <td style="text-align: center; width: 237px">
              <strong>Education</strong>
            </td>
            <td style="text-align: center; width: 249px">${
              props.scanResult?.ats?.ats_data?.education_match?.requires
            }</td>
          </tr>
          <tr>
            <td style="text-align: center; width: 237px">
              <strong>Employer</strong>
            </td>
            <td style="text-align: center; width: 249px">${
              props.scanResult?.ats?.ats_data?.job_level_match?.EmployerNames ||
              'N/A'
            }</td>
          </tr>
          <tr>
            <td style="text-align: center; width: 237px">
              <strong>Experience&nbsp;</strong>
            </td>
            <td style="text-align: center; width: 249px">${
              props.scanResult?.ats?.ats_data?.job_level_match?.years_required
            } years</td>
          </tr>
          <tr>
            <td colspan="2" style="width: 237px">
              <p><strong>RESEUME INFO</strong></p>
      
              <p><strong>Resume Used: </strong><u>${
                props.scanResult?.ats?.ats_data?.job_title_matched?.requires +
                '_' +
                moment(props.scanResult?.createdAt).format('YYYYMMDDTHHMMSS')
              }</u></p>
      
              <p><strong>Cover Letter Used:</strong> ${
                props.resume?.coverLetter ? 'Yes' : 'No'
              }</p>
      
              <p><strong>Cover Letter Name: </strong>${title}</p>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="width: 237px">
              <p><strong>Date Optimized</strong>: ${
                props.scanResult?.createdAt
              }</p>
      
              <p><strong>MatchScore</strong>: ${
                props.scanResult?.total_score
              }</p>
      
              <p><strong>Job Applied:</strong> ${
                props.scanResult?.jobApplied || 'N/A'
              } </p>
              <p>
              <a href="${process.env.REACT_APP_API_URL}/download-user-doc?oid=${
        location?.state?.oid || props.scanResult?.oid
      }&type=docx" download>Download Docx</a>
              </p>
              <p>
              <a href="${process.env.REACT_APP_API_URL}/download-user-doc?oid=${
        location?.state?.oid || props.scanResult?.oid
      }&type=pdf" download>Download PDF</a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      </html> 
      `;
      let data = {
        type: type,
        isSummary: true,
        body: html,
        subject: `Review | ${
          props.scanResult?.ats?.ats_data?.job_title_matched?.requires
        } | ${
          props.scanResult?.ats?.ats_data?.job_level_match?.EmployerNames ||
          'N/A'
        } | ${moment().format('MMDDYY')}`,
        resumeOid: props.scanResult?.oid,
      };
      props.sendTextToEmailOrMobile(data);
    }
  };
  const sendGovtGeneratedTextTo = async (type) => {
    const govtResumeData = JSON.parse(localStorage.getItem('govtResume'));
    if (type === 'mobile') {
      setMobileLoader(true);
      let data = {
        type: type,
        body: `Resume Name: ${savedName}\nReview Score:${
          scanResult.total_score
        }\nCreated At: ${scanResult.createdAt}\nUpdated At: ${
          scanResult.updatedAt
        }\nTemplate you choosed: ${selectedTemplate}\nPdf URL: ${pdfUrl.replaceAll(
          ' ',
          '%20',
        )}`,
      };
      props.sendTextToEmailOrMobile(data);
    } else if (type === 'email') {
      setEmailLoader(true);

      let html = `<html>
      <h1>Optimization Summary</h1>
      <table border="1" cellpadding="1" cellspacing="1" style="width: 500px">
        <tbody>
          <tr>
            <td style="text-align: center; width: 237px">
              <strong>Job Title</strong>
            </td>
            <td style="text-align: center; width: 249px">${
              props.scanResult?.ats?.ats_data?.job_title_matched?.requires
            }</td>
          </tr>
          <tr>
            <td style="text-align: center; width: 237px">
              <strong>Education</strong>
            </td>
            <td style="text-align: center; width: 249px">${
              props.scanResult?.ats?.ats_data?.education_match?.requires
            }</td>
          </tr>
          <tr>
            <td style="text-align: center; width: 237px">
              <strong>Employer</strong>
            </td>
            <td style="text-align: center; width: 249px">${
              props.scanResult?.ats?.ats_data?.job_level_match?.EmployerNames ||
              'N/A'
            }</td>
          </tr>
          <tr>
            <td style="text-align: center; width: 237px">
              <strong>Experience&nbsp;</strong>
            </td>
            <td style="text-align: center; width: 249px">${
              props.scanResult?.ats?.ats_data?.job_level_match?.years_required
            } years</td>
          </tr>
          <tr>
            <td colspan="2" style="width: 237px">
              <p><strong>RESEUME INFO</strong></p>
      
              <p><strong>Resume Used: </strong><u>${
                props.scanResult?.ats?.ats_data?.job_title_matched?.requires +
                '_' +
                moment(props.scanResult?.createdAt).format('YYYYMMDDTHHMMSS')
              }</u></p>
      
              <p><strong>Cover Letter Used:</strong> ${
                props.resume?.coverLetter ? 'Yes' : 'No'
              }</p>
      
              <p><strong>Cover Letter Name: </strong>${title}</p>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="width: 237px">
              <p><strong>GOVERNMENT DATA</strong></p>
              <p><strong>Government Job Title: </strong><u>${
                govtResumeData?.govtJobTitle
              }</u></p>
              <p><strong>Grade Applying:</strong> ${
                govtResumeData?.gradeApplying
              }</p>
              <p><strong>Announcement Number: </strong>${
                govtResumeData?.announcementNumber
              }</p>
              <p><strong>Phone: </strong>${govtResumeData?.phone}</p>
              <p><strong>Address: </strong>${govtResumeData?.address}</p>
              <p><strong>Country of Citizenship: </strong>${
                govtResumeData?.countryOfCitizenship
              }</p>
              <p><strong>Veteran’s Preferences: </strong>${
                govtResumeData?.veteransPreferences
              }</p>
              <p><strong>Other Preferences: </strong>${
                govtResumeData?.otherPreferences
              }</p>
              <p><strong>Federal Civilian Status: </strong>${
                govtResumeData?.federalCivilianStatus
              }</p>
              <p><strong>Security Clearance: </strong>${
                govtResumeData?.securityClearance
              }</p>
              <p><strong>Typical Hours per week worked: </strong>${
                govtResumeData?.hoursPerWeek
              }</p>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="width: 237px">
              <p><strong>Date Optimized</strong>: ${
                props.scanResult?.createdAt
              }</p>
      
              <p><strong>MatchScore</strong>: ${
                props.scanResult?.total_score
              }</p>
      
              <p><strong>Job Applied:</strong> ${
                props.scanResult?.jobApplied || 'N/A'
              } </p>
              <p>
              <a href="${
                process.env.REACT_APP_API_URL
              }/download-government-resume?oid=${
        location?.state?.oid || props.scanResult?.oid
      }&type=docx" download>Download Docx</a>
              </p>
              <p>
              <a href="${
                process.env.REACT_APP_API_URL
              }/download-government-resume?oid=${
        location?.state?.oid || props.scanResult?.oid
      }&type=pdf" download>Download PDF</a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      </html> 
      `;
      let data = {
        type: type,
        isSummary: true,
        body: html,
        subject: `Review | ${
          props.scanResult?.ats?.ats_data?.job_title_matched?.requires
        } | ${
          props.scanResult?.ats?.ats_data?.job_level_match?.EmployerNames ||
          'N/A'
        } | ${moment().format('MMDDYY')}`,
        resumeOid: props.scanResult?.oid,
      };
      props.sendTextToEmailOrMobile(data);
    }
  };

  const sendResumeTo = async () => {
    const emailSent = props.scanResult?.emailSent || false;
    if (props.user?.emailNotifications === true && !emailSent) {
      dispatch(sendMail());
      sendTextTo('email');
    }
    if (props.user?.smsNotifications === true) {
      sendTextTo('mobile');
    }
  };

  React.useEffect(() => {
    if (
      props.sendTextToStatus === 'done' ||
      props.sendTextToStatus === 'failed'
    ) {
      if (mobileLoader) {
        setMobileLoader(false);
      } else if (emailLoader) {
        setEmailLoader(false);
      }
    }
  }, [props.sendTextToStatus]);
  // React.useEffect(() => {
  //   if (props.scanResult.notes) {
  //     setNote(props.scanResult.notes)
  //   }

  // }, [
  //   props.scanResult.notes
  // ])
  const jobTitle = location?.state?.resume?.MainJobTitle
    ? location?.state?.resume?.MainJobTitle
    : scanResult?.ats?.ats_data?.job_title_matched?.requires
    ? scanResult?.ats?.ats_data?.job_title_matched?.requires
    : null;
  const CompanyTitle = location?.state
    ? location?.state?.data?.company
    : scanResult?.ats?.ats_data?.job_level_match?.EmployerNames
    ? scanResult?.ats?.ats_data?.job_level_match?.EmployerNames
    : null;
  const handleInfoModal = (key) => {
    setIsInfoOpen(true);
    setInfoKey(key);
    if (Object.keys(companyInfo).length === 0 && key === 'company') {
      dispatch(getCompanyInfo(CompanyTitle));
    }
    if (Object.keys(onetData).length === 0 && key === 'job') {
      dispatch(getOnetData(jobTitle));
    }
  };
  const onCloseInfoModal = (key) => {
    setIsInfoOpen(false);
    setInfoKey(key);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: 13,
      minHeight: '30px',
      // height: '30px',
      border: '0.5px solid #dee2e6',
      borderRadius: '10px',
      boxShadow: isFocused ? 'none' : 'none',
      '&:hover': {
        border: '0.5px solid #dee2e6',
        boxShadow: 'none',
      },
      '&:onFocus': {
        border: '0.5px solid #dee2e6',
        boxShadow: 'none',
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '43px',
      padding: '0 6px',
    }),
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled ? 'red' : blue,
        color: '#000',
        fontSize: 15,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
  };
  return (
    <>
      <InfoModal
        isOpen={isInfoOpen}
        onCloseModal={onCloseInfoModal}
        infoKey={infoKey}
        companyInfo={companyInfo}
        jobTitle={jobTitle}
        loader={infoLoader}
        onetData={onetData}
      />

      {subscriptionModal && <UpgradeModal cb={toggleSubscription} />}
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open1}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose1}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            &nbsp;
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={handleClose1}
          />
        </div>
        <DialogContent
          style={{
            marginBottom: '20px',
          }}
        >
          <TextArea
            value={notes}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Add Note"
            rows="4"
          />
        </DialogContent>
        <DialogActions>
          <Mbutton
            disabled={loader1}
            onClick={submit}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              height: '40px',
            }}
          >
            {loader1 ? 'Adding...' : 'Add'}
          </Mbutton>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth={'md'}
        open={jobOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setJobOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: width < 500 ? 18 : 23,
              fontFamily: 'Nunito',
            }}
          >
            Job Description
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: width < 500 ? 18 : 24,
            }}
            onClick={() => setJobOpen(false)}
          />
        </div>
        <DialogContent>
          <div
            style={{
              height: 'auto',
              padding: width < 500 ? 0 : 20,
              boxSizing: 'border-box',
            }}
          >
            <textarea
              style={{
                width: '100%',
                height: '100%',
                border: '1px solid #ccc',
                outline: 'none',
                fontSize: width < 500 ? 12 : 16,
              }}
              placeholder="Job Description"
              rows={height < 600 ? 9 : 13}
              value={stripHtml(
                props.scanResult?.jobDescription ||
                  location?.state?.data?.jobDescription,
              )}
              readOnly
            />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={printModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            Print Options
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => setPrintModal(false)}
          />
        </div>
        <div
          style={{
            // width: 300,
            display: 'flex',
            flexDirection: 'column',
            // width: 500,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <span
            onClick={() => setPrintType('local')}
            style={{
              marginTop: 15,
              fontWeight: 'bold',
              border:
                printType === 'local'
                  ? '1px solid #4267B2'
                  : '1px solid transparent',
              padding: 5,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <PrintIcon /> To Local Printer
          </span>
          <span
            onClick={() => setPrintType('staples')}
            style={{
              marginTop: 15,
              fontWeight: 'bold',
              border:
                printType === 'staples'
                  ? '1px solid #4267B2'
                  : '1px solid transparent',
              padding: 5,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <StoreIcon /> Print to OfficeDepot or Staples (Any location)
          </span>
          <span
            onClick={() => setPrintType('fedex')}
            style={{
              marginTop: 15,
              fontWeight: 'bold',
              border:
                printType === 'fedex'
                  ? '1px solid #4267B2'
                  : '1px solid transparent',
              padding: 5,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <img src={FedexIcon} width={30} height={15} /> Print to FedEx Office
            (Any location)
          </span>
        </div>
        <DialogActions>
          <Mbutton
            onClick={() => {
              if (printType === 'local') {
                openUrl('pdf');
                setPrintModal(false);
                return;
              }
              let data = {
                is_scan: 1,
                oid: location?.state?.oid || scanResult?.oid,
                temp_id: selectedTemplate,
                printType,
              };
              setPrintModal(false);
              props.EmailParserAction(data, handleClickOpen);
            }}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              height: '40px',
            }}
          >
            {/* {printLoader ? (
              <CircularProgress size={20} color="#fff" />
            ) : ( */}
            Print
            {/* )} */}
          </Mbutton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            Message
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent style={{ fontSize: '12.8px' }}>{message}</DialogContent>
      </Dialog>

      <Modal isOpen={modalLarge} size="lg" toggle={toggleLarge}>
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '20px 20px 0px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            {props.scanResult &&
              props.scanResult?.ats?.ats_data?.job_title_matched?.requires}
          </h5>
          <Cancel
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={toggleLarge}
          />
        </div>
        <ModalBody>
          <Row style={styles.row(width)}>
            <Colxx
              lg={{
                size: 12,
              }}
              md={{
                size: 12,
              }}
              sm="12"
              xs="12"
            >
              <div>
                <h3 style={styles.saveApply_h3}>
                  RESUME{' '}
                  <CopyToClipboard
                    text={stripHtml(
                      (props.scanResult && props.scanResult.resumeText, true) ||
                        '',
                    )}
                    onCopy={() => toastr.success('resume copied!')}
                  >
                    <FileCopyIcon
                      className="hovdel"
                      style={styles.actionIcon}
                    />
                  </CopyToClipboard>
                </h3>
                <textarea
                  className="scrollBar"
                  placeholder="Paste your resume"
                  style={styles.textArea1}
                  name="resume"
                  value={props.scanResult && props.scanResult.resumeText}
                />
              </div>
            </Colxx>
          </Row>
        </ModalBody>
      </Modal>

      {/* Government Jobs resume modal */}
      <Modal
        isOpen={govtJobFormModal}
        size="lg"
        toggle={toggleGovtJobFormModal}
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '20px 20px 0px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            Convert to Government Format
          </h5>
          <Cancel
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={toggleGovtJobFormModal}
          />
        </div>
        <ModalBody>
          {/* block 1 */}
          <span style={{ fontSize: 16, fontWeight: 700 }}>Job Info</span>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div className="form-group position-relative">
                <label>
                  Job Title <span className="text-danger">*</span>
                </label>
                <input
                  value={govtJobTitle}
                  onChange={(e) => handleInputChange(e, setGovtJobTitle)}
                  type="text"
                  className="form-control"
                  placeholder="Job Title"
                  name="Job Title"
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>
                  Grade applying <span className="text-danger">*</span>
                </label>
                <input
                  value={gradeApplying}
                  onChange={(e) => handleInputChange(e, setGradeApplying)}
                  type="text"
                  className="form-control"
                  placeholder="Grade applying"
                  name="Grade applying"
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>
                  Announcement Number <span className="text-danger">*</span>
                </label>
                <input
                  value={announcementNumber}
                  onChange={(e) => handleInputChange(e, setAnnouncementNumber)}
                  type="text"
                  className="form-control"
                  placeholder="Announcement Number"
                  name="Grade applying"
                  required=""
                />
              </div>
            </Col>
          </Row>
          {/* block 2 */}
          <span style={{ fontSize: 16, fontWeight: 700 }}>Personal Info</span>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div className="form-group position-relative">
                <label>
                  Phone <span className="text-danger">*</span>
                </label>
                <input
                  value={phone}
                  onChange={(e) => handleInputChange(e, setPhone)}
                  type="number"
                  className="form-control"
                  placeholder="Phone"
                  name="Phone"
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>
                  Address <span className="text-danger">*</span>
                </label>
                {/* <input
                  value={''}
                  onChange={() => {}}
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  name="Address"
                  required=""
                /> */}
                <PlacesAutocomplete
                  value={places}
                  onChange={handleChange}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,

                    loading,
                  }) => (
                    <Select
                      styles={{ ...customStyles }}
                      // isMulti
                      // className="form-control"
                      isLoading={loading}
                      options={suggestions.map((n) => ({
                        label: n.description,
                        value: n.description,
                      }))}
                      {...getInputProps({
                        placeholder: 'Search Address ...',
                      })}
                      onChange={(tags) => {
                        setMultiLocations(tags);
                      }}
                      value={multiLocations}
                      onInputChange={(text) =>
                        getInputProps().onChange({ target: { value: text } })
                      }
                    />
                  )}
                </PlacesAutocomplete>
              </div>
              <div className="form-group position-relative">
                <label>
                  Country of Citizenship <span className="text-danger">*</span>
                </label>
                <input
                  value={countryOfCitizenship}
                  onChange={(e) =>
                    handleInputChange(e, setCountryOfCitizenship)
                  }
                  type="text"
                  className="form-control"
                  placeholder="Country of Citizenship"
                  name="Country of Citizenship"
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>
                  Veteran’s Preferences <span className="text-danger">*</span>
                </label>
                <input
                  value={veteransPreferences}
                  onChange={(e) => handleInputChange(e, setVeteransPreferences)}
                  type="text"
                  className="form-control"
                  placeholder="Veteran’s Preferences"
                  name="Veteran’s Preferences"
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>
                  Other Preferences <span className="text-danger">*</span>
                </label>
                <input
                  value={otherPreferences}
                  onChange={(e) => handleInputChange(e, setOtherPreferences)}
                  type="text"
                  className="form-control"
                  placeholder="Other Preferences"
                  name="Other Preferences"
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>
                  Federal Civilian Status <span className="text-danger">*</span>
                </label>
                <input
                  value={federalCivilianStatus}
                  onChange={(e) =>
                    handleInputChange(e, setFederalCivilianStatus)
                  }
                  type="text"
                  className="form-control"
                  placeholder="Federal Civilian Status"
                  name="Federal Civilian Status"
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>
                  Security Clearance <span className="text-danger">*</span>
                </label>
                <input
                  value={securityClearance}
                  onChange={(e) => handleInputChange(e, setSecurityClearance)}
                  type="text"
                  className="form-control"
                  placeholder="Security Clearance"
                  name="Security Clearance"
                  required=""
                />
              </div>
              <div className="form-group position-relative">
                <label>
                  Typical Hours per week worked
                  <span className="text-danger">*</span>
                </label>
                <input
                  value={hoursPerWeek}
                  onChange={(e) => handleInputChange(e, setHoursPerWeek)}
                  type="number"
                  className="form-control"
                  placeholder="Typical Hours per week worked"
                  name="Typical Hours per week worked"
                  required=""
                />
              </div>
              {/* <div className="form-group position-relative">
                <label>
                  Select File Type<span className="text-danger">*</span>
                </label>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                  }}
                >
                  <div className="radioLabel">
                    <input
                      type="radio"
                      value="pdf"
                      name="fileType"
                      id="pdf"
                      className="radioInput"
                      onChange={() => setFileType('pdf')}
                    />
                    <label
                      htmlFor="pdf"
                      style={{
                        fontSize: 14,
                        marginLeft: 10,
                        fontWeight: 'medium',
                      }}
                    >
                      PDF
                    </label>
                  </div>
                  <div className="radioLabel">
                    <input
                      type="radio"
                      value="doc"
                      name="fileType"
                      id="doc"
                      className="radioInput"
                      onChange={() => setFileType('doc')}
                    />
                    <label
                      htmlFor="doc"
                      style={{
                        fontSize: 14,
                        marginLeft: 10,
                        fontWeight: 'medium',
                      }}
                    >
                      DOC
                    </label>
                  </div>
                  <div className="radioLabel">
                    <input
                      type="radio"
                      value="txt"
                      name="fileType"
                      id="txt"
                      className="radioInput"
                      onChange={() => setFileType('txt')}
                    />
                    <label
                      htmlFor="txt"
                      style={{
                        fontSize: 14,
                        marginLeft: 10,
                        fontWeight: 'medium',
                      }}
                    >
                      TXT
                    </label>
                  </div>
                </div>
              </div> */}
            </Col>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                marginRight: 16,
              }}
            >
              <Mbutton
                onClick={convertResume}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: colors.blue,
                  color: colors.white,
                  height: '40px',
                }}
              >
                {/* {printLoader ? (
              <CircularProgress size={20} color="#fff" />
            ) : ( */}
                Convert
                {/* )} */}
              </Mbutton>
            </div>
          </Row>
        </ModalBody>
      </Modal>

      {/* model for jobDescription */}
      <Modal isOpen={modalLarge2} size="lg" toggle={toggleLarge2}>
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            {props.scanResult &&
              props.scanResult?.ats?.ats_data?.job_title_matched?.requires}
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={toggleLarge2}
          />
        </div>
        <ModalBody>
          <Row style={styles.row(width)}>
            <Colxx
              lg={{
                size: 12,
              }}
              md={{
                size: 12,
              }}
              sm="12"
              xs="12"
            >
              <div>
                <h3 style={styles.saveApply_h3}>
                  JOB DESCRIPTION{' '}
                  <CopyToClipboard
                    text={stripHtml(
                      (props.scanResult && props.scanResult.jobDescription) ||
                        '',
                    )}
                    onCopy={() => toastr.success('job description copied!')}
                  >
                    <FileCopyIcon
                      className="hovdel"
                      style={styles.actionIcon}
                    />
                  </CopyToClipboard>
                </h3>
                <textarea
                  className="scrollBar"
                  placeholder="Paste the entire job description text - Exclude the 'About company' section, but include the job title."
                  style={styles.textArea}
                  name="jobdescription"
                  value={props.scanResult && props.scanResult.jobDescription}
                />
              </div>
            </Colxx>
          </Row>
        </ModalBody>
      </Modal>

      <Dialog
        fullWidth
        open={viewEmail}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setViewEmail(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 23,
              fontFamily: 'Nunito',
            }}
          >
            Email after apply - Preview
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => {
              setViewEmail(false);
              setPreviewContentHandler();
              setPreviewSubjectHandler();
            }}
          />
        </div>
        <DialogContent>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '1rem',
              }}
            >
              <h4
                style={{ color: 'black', fontWeight: 'bold', fontSize: '19px' }}
              >
                From:{' '}
              </h4>
              <h4 style={{ color: colors.fontGray, fontSize: '19px' }}>
                {user?.email}
              </h4>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: '10px',
              }}
            >
              <h4
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: '19px',
                }}
              >
                Subject:
                <CopyToClipboard
                  text={previewSubject}
                  onCopy={() => toastr.success('Subject copied!')}
                >
                  <BiCopy style={{ marginLeft: 6 }} cursor={'pointer'} />
                </CopyToClipboard>
              </h4>
              <input
                type="text"
                value={previewSubject}
                onChange={(e) => setPreviewSubject(e.target.value)}
                style={{
                  color: colors.fontGray,
                  fontSize: '19px',
                  outline: 'none',
                  border: 'none',
                  width: '100%',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: '10px',
              }}
            >
              <h4
                style={{ color: 'black', fontWeight: 'bold', fontSize: '19px' }}
              >
                Content:
                <CopyToClipboard
                  text={previewContent}
                  onCopy={() => toastr.success('Content copied!')}
                >
                  <BiCopy style={{ marginLeft: 6 }} cursor={'pointer'} />
                </CopyToClipboard>
              </h4>
              <textarea
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #ccc',
                  outline: 'none',
                }}
                placeholder="Cover Letter"
                rows={height < 600 ? 8 : 13}
                value={previewContent}
                onChange={(e) => setPreviewContent(e.target.value)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <CopyToClipboard
            text={`From: ${user?.email}\nSubject: ${previewSubject}\nContent: ${previewContent}`}
            onCopy={() => toastr.success('Email copied!')}
          >
            <MuiButton
              color="primary"
              variant="contained"
              style={{
                backgroundColor: colors.blue,
                height: '30px',
              }}
            >
              Copy
            </MuiButton>
          </CopyToClipboard>
          {/* <MuiButton
            color="primary"
            variant="contained"
            style={{
              backgroundColor: colors.blue,
            }}
            onClick={() =>
              sendAndUpdateCoverLetter(
                user?.firstName +
                  ' ' +
                  user?.lastName +
                  "'s Application for " +
                  props.scanResult?.ats?.ats_data?.job_title_matched?.requires +
                  ' Role',
              )
            }
          >
            Send
          </MuiButton> */}
          <MuiButton
            onClick={updateFollowupEmail}
            disabled={followUpLoading}
            color="primary"
            variant="contained"
            style={{
              height: '30px',
              backgroundColor: colors.blue,
            }}
          >
            {followUpLoading ? (
              <CircularProgress size={20} color="gray" />
            ) : (
              'Save'
            )}
          </MuiButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen2(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 23,
              fontFamily: 'Nunito',
            }}
          >
            Add Email
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => setOpen2(false)}
          />
        </div>
        <>
          <DialogContent>
            <Input.Group>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <Button
                style={{
                  background: email?.length ? '#4B6FB6' : 'grey',
                  color: 'white',
                  padding: '5px 20px',
                  float: 'right',
                  marginTop: '10px',
                }}
                onClick={emailAdd}
              >
                {newEmailLoader ? '...Adding' : 'Add'}
              </Button>
            </Input.Group>
          </DialogContent>
        </>
      </Dialog>
      <VideoModel
        open={modelOpen}
        setOpen={setModelOpen}
        video={VideoLinks?.SaveAndApply}
      />
      <VideoModel
        open={isFollowUpVideo}
        setOpen={setIsFollowUpVideo}
        video={VideoLinks?.FollowUpEmail}
      />
      <VideoModel
        open={govtJobModalOpen}
        setOpen={setGovtJobModal}
        video={{
          videoID: '933405093?h=28d3d8ca5c',
        }}
      />
      <Card className="saveContainer">
        <CardBody
          style={{
            width: width < 500 ? '100%' : '95%',
            margin: 'auto',
            ...(width < 500 && { padding: '10px 6px' }),
          }}
        >
          <div
            style={{
              ...styles.flex,
              ...styles.justifyContentCenter,
              ...styles.mb_10,
              margin: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: width < 500 ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <InfoIcon
                onClick={() => setModelOpen(true)}
                text={'How To'}
                icon={'Play'}
                height={18}
                width="70px"
                marginTop={-1}
                marginRight={5}
              />
              <span
                style={{
                  ...styles.h1(width),
                  textAlign: 'center',
                }}
              >
                Save & Apply
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontSize: width < 500 ? '17px' : '20px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Congratulations
              <Lottie
                style={{ width: width < 500 ? 50 : 70, marginTop: -10 }}
                animationData={PopAnimation}
                loop={true}
              />
            </div>
            <div style={{ fontSize: 15, padding: 10 }}>
              <div>
                You have successfully optimized your resume! Download your
                resume down below.
              </div>
              <div
                style={{
                  marginBottom: '10px',
                }}
              >
                You can download a word copy of your created resume and edit and
                make changes as needed.
              </div>
            </div>
          </div>
          {/* {props.scanResult && props.scanResult.jobApplied === "" && ( */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              // gap: width < 650 ? "10%" : "20%",
              alignItems: 'center',
              flexWrap: width < 930 ? 'wrap' : 'nowrap',
              ...(width < 500 && { padding: 10 }),
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'baseline',
                marginTop: '20px',
                gap: 10,
                width: width < 875 ? '100%' : '70%',
                marginBottom: 10,
                flexWrap: width < 615 ? 'wrap' : 'nowrap',
              }}
            >
              <div>
                <div
                  style={{
                    ...styles.flex,
                  }}
                >
                  <span
                    style={{
                      ...styles.itemDesc,
                      paddingBottom: '15px',
                      paddingRight: 5,
                    }}
                  >
                    Job Title:
                  </span>
                  {location?.state?.resume?.MainJobTitle ? (
                    <span style={styles.itemText}>
                      {location?.state?.resume?.MainJobTitle}
                    </span>
                  ) : (
                    <span style={styles.itemText}>
                      {scanResult?.ats?.ats_data?.job_title_matched?.requires
                        ? ' ' +
                          scanResult?.ats?.ats_data?.job_title_matched?.requires
                        : 'Job title not found'}
                    </span>
                  )}
                </div>
                <ViewButton
                  handleInfoModal={handleInfoModal}
                  title="Job View"
                  jobKey="job"
                />
              </div>
              <div
                style={{
                  marginLeft:
                    width < 1005 ? '30px' : width < 958 ? '10px' : '60px',
                  ...(width < 450 && {
                    // flexWrap: 'wrap',
                    marginTop: 10,
                    marginLeft: 0,
                  }),
                }}
              >
                <div
                  style={{
                    ...styles.flex,
                  }}
                >
                  <span
                    style={{
                      ...styles.itemDesc,
                      paddingBottom: '15px',
                      paddingRight: 5,
                    }}
                  >
                    Employer:
                  </span>
                  {location.state ? (
                    <span style={styles.itemText}>
                      {location?.state?.data?.company}
                    </span>
                  ) : (
                    <span style={styles.itemText}>
                      {scanResult?.ats?.ats_data?.job_level_match?.EmployerNames
                        ? scanResult?.ats?.ats_data?.job_level_match
                            ?.EmployerNames
                        : ''}
                    </span>
                  )}
                </div>
                <ViewButton
                  handleInfoModal={handleInfoModal}
                  title="Company View"
                  jobKey="company"
                />
              </div>
              <div
                style={{
                  ...styles.flex,
                  ...styles.flexColumn,
                  ...styles.alignItemsCenter,
                  gap: 10,
                  paddingLeft: width < 960 ? '10px' : '30px',
                  ...(width < 615 && { marginTop: 10 }),
                }}
              >
                <span style={{ ...styles.itemDesc }}>Job Description</span>
                <ViewIcon
                  onClick={() => setJobOpen(true)}
                  cursor={'pointer'}
                  color={colors.blue}
                />
                {/* <VisibilityIcon
                  onClick={() => setJobOpen(true)}
                  style={{
                    cursor: 'pointer',
                    color: colors.blue,
                    fontSize: 30,
                  }}
                /> */}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                marginTop: -15,
              }}
            >
              <Model
                type="resume"
                oid={props.resume?.oid}
                note={props.resume?.oid}
                clip="clip"
                size={{ height: 40, width: 40 }}
              />
            </div>
            <div
              style={{
                float: 'right',
                marginBottom: '20px',
                display: 'flex',
                width: width < 764 ? '100%' : '30%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                flexDirection: 'column',
              }}
            >
              {props.scanResult?.jobApplied == 'no' ||
              props.scanResult?.jobApplied == '' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    ...(width < 500 && { marginTop: 10 }),
                  }}
                >
                  <img
                    src={tickIcon}
                    alt="check"
                    style={{
                      width: '25px',
                      height: '25px',
                      objectPosition: 'center',
                    }}
                  />
                  <span style={styles.saveApply_title}>Job Applied ?</span>
                  <IconButton
                    size="small"
                    onClick={() => questionsFunction('yes', 'jobApplied')}
                    disabled={props.scanResult?.jobApplied === 'yes'}
                  >
                    <span
                      style={
                        props.scanResult?.jobApplied === 'yes'
                          ? styles.saveApply_no
                          : styles.saveApply_yes
                      }
                    >
                      Yes
                    </span>
                  </IconButton>
                  <span
                    style={{
                      color: '#F0F0F0',
                    }}
                    // eslint-disable-next-line react/no-children-prop
                    children="|"
                  />
                  <IconButton
                    size="small"
                    onClick={() => questionsFunction('no', 'jobApplied')}
                    disabled={props.scanResult?.jobApplied === 'no'}
                  >
                    <span
                      style={
                        props.scanResult?.jobApplied === 'no'
                          ? styles.saveApply_no
                          : styles.saveApply_yes
                      }
                    >
                      No
                    </span>
                  </IconButton>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <img
                    src={tickIcon}
                    alt="check"
                    style={{
                      width: '25px',
                      height: '25px',
                      objectPosition: 'center',
                    }}
                  />
                  <span style={styles.saveApply_title}>Interview?</span>
                  <IconButton
                    size="small"
                    onClick={() => questionsFunction('yes', 'interview')}
                    disabled={props.scanResult?.isInterview === 'yes'}
                  >
                    <span
                      style={
                        props.scanResult?.isInterview === 'yes'
                          ? styles.saveApply_no
                          : styles.saveApply_yes
                      }
                    >
                      Yes
                    </span>
                  </IconButton>
                  <span
                    style={{
                      color: '#F0F0F0',
                    }}
                    // eslint-disable-next-line react/no-children-prop
                    children="|"
                  />
                  <IconButton
                    size="small"
                    onClick={() => questionsFunction('no', 'interview')}
                    disabled={props.scanResult?.isInterview === 'no'}
                  >
                    <span
                      style={
                        props.scanResult?.isInterview === 'no'
                          ? styles.saveApply_no
                          : styles.saveApply_yes
                      }
                    >
                      No
                    </span>
                  </IconButton>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InfoIcon
                  onClick={() => this.setState({ modelOpen: true })}
                  text={'PRO TIP'}
                  icon={'Bulb'}
                  height={20}
                  width="80px"
                  marginTop={-4}
                />

                <span
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                    fontSize: '10px',
                    padding: '2px 5px',
                    borderRadius: '5px',
                    border: '1px solid gray',
                    backgroundColor: '#ffffff',
                    fontWeight: '900',
                    fontFamily: 'Roboto',
                  }}
                >
                  Update status for accurate tracking
                </span>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: width < 1150 ? 'column' : 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              style={{
                width: width < 1150 ? '100%' : '60%',
                display: 'flex',
                flexDirection: 'column',
                gap: 50,
                // justifyContent: 'space-between',
              }}
            >
              {/* <div
                style={{
                  border: '1px solid #F0F0F0',
                }}
              >
                <div style={styles.tableTitle(width)}>JOB REQUIREMENTS</div>
                <div
                  style={{
                    ...styles.flex,
                    ...styles.flexRow,
                    ...styles.spaceBetween,
                    width: width < 1310 ? '100%' : '90%',
                    padding: '30px 25px',
                  }}
                >
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexColumn,
                      paddingRight: width < 650 ? '30px' : '',
                    }}
                  >
                    <span style={{ ...styles.itemDesc, paddingBottom: '15px' }}>
                      Job Title:
                    </span>
                    <span style={styles.itemText}>
                      {scanResult?.ats?.ats_data?.job_title_matched?.requires
                        ? scanResult?.ats?.ats_data?.job_title_matched?.requires
                        : 'Job title not found'}
                    </span>
                  </div>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexColumn,
                      borderLeft: '1px solid #F0F0F0',
                      paddingLeft: width < 650 ? '30px' : '60px',
                      width: '250px',
                    }}
                  >
                    <span style={{ ...styles.itemDesc, paddingBottom: '15px' }}>
                      Employer:
                    </span>
                    <span style={styles.itemText}>
                      {scanResult?.ats?.ats_data?.job_level_match?.EmployerNames
                        ? scanResult?.ats?.ats_data?.job_level_match
                          ?.EmployerNames
                        : ''}
                    </span>
                  </div>
                </div>
              </div> */}
              <div
                style={{
                  marginTop: width < 1150 ? '30px' : '0',
                  border: '1px solid #F0F0F0',
                }}
              >
                <div
                  style={{
                    ...styles.flex,
                    ...styles.resumeTitleBar,
                    ...styles.alignItemsCenter,
                    justifyContent: 'space-between',
                  }}
                >
                  <span
                    style={{
                      ...styles.tableTitle(width),
                      whiteSpace: 'nowrap',
                    }}
                  >
                    COVER LETTER
                  </span>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.alignItemsCenter,
                      // width: '70%',
                      paddingRight: '10px',
                    }}
                  >
                    {width < 470 ? (
                      <Tooltip
                        classes={tooltipClasses}
                        title="Send a cover letter ONLY if the job application specifically asks for it"
                      >
                        <InfoIcon
                          onClick={() => this.setState({ modelOpen: true })}
                          text={'PRO TIP'}
                          icon={'Bulb'}
                          height={20}
                          width="80px"
                          marginTop={0}
                        />
                      </Tooltip>
                    ) : (
                      <>
                        <InfoIcon
                          onClick={() => this.setState({ modelOpen: true })}
                          text={'PRO TIP'}
                          icon={'Bulb'}
                          height={20}
                          width="80px"
                          marginTop={-4}
                        />

                        <span
                          style={{
                            // width: '100%',
                            marginLeft: '10px',
                            marginRight: '10px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            padding: '2px 5px',
                            borderRadius: '5px',
                            border: '1px solid gray',
                            backgroundColor: '#ffffff',
                          }}
                        >
                          Send a cover letter ONLY if the job application
                          specifically asks for it
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    ...styles.flex,
                    ...styles.spaceBetween,
                    flexDirection: width < 650 ? 'column' : 'row',
                    width: width < 1310 ? '100%' : '90%',
                    padding: width < 500 ? 20 : '30px 25px',
                  }}
                >
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexColumn,
                      width: '100%',
                    }}
                  >
                    <span style={{ ...styles.itemDesc, paddingBottom: '15px' }}>
                      <div
                        style={{ ...styles.itemDesc, paddingBottom: '15px' }}
                      >
                        Cover Letter:
                      </div>
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          border: '1px solid #F0F0F0',
                          height: 32,
                          paddingLeft: 10,
                          paddingRight: 10,
                          // paddingTop: 3,
                          width: 230,
                          overflow: 'hidden',
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: props.resume?.coverLetter ? 'black' : 'grey',
                        }}
                      >
                        {props.resume?.coverLetter
                          ? props.resume?.coverLetter?.title || jobTitle
                          : 'Cover Letter'}
                      </div>
                      <Button
                        disabled={props.resume?.coverLetter}
                        style={{
                          background: props.resume?.coverLetter
                            ? 'grey'
                            : '#4B6FB6',
                          color: 'white',
                          padding: '0 10px',
                          width: 130,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={generateCover}
                      >
                        {coverLetterLoader ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          'Generate'
                        )}
                      </Button>
                    </div>
                    {/* </Input.Group> */}
                  </div>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexColumn,
                      borderLeft: width < 650 ? 'none' : '1px solid #F0F0F0',
                      paddingTop: width < 650 ? '30px' : '0',
                      paddingLeft: width < 650 ? '0' : '60px',
                      marginLeft: width < 650 ? '0' : '20px',
                      width: width < 500 ? '100%' : '200px',
                      justifyContent: 'flex-end',
                      // alignItems:"center"
                    }}
                  >
                    <span style={{ ...styles.itemDesc, paddingBottom: '15px' }}>
                      {/* Copy Cover Letter: */}
                    </span>
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                        justifyContent: props.resume?.coverLetter
                          ? 'flex-end'
                          : 'space-around',
                        gap: 15,
                        width: '100%',
                      }}
                    >
                      {props.resume?.coverLetter ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '4%',
                            }}
                          >
                            <span
                              style={{ marginLeft: -13, ...styles.itemDesc }}
                            >
                              View
                            </span>
                            <ShowCover
                              user={user}
                              toggleModal={toggleSubscription}
                              resume={props.resume}
                              jobTitle={jobTitle}
                              checkIsPremium={checkIsPremium}
                              // selected={coverLetter && coverLetter}
                              UpdateCoverLetter={props.UpdateCoverLetter}
                              // getCoverLetters={props?.getCoverLetters}
                            />
                          </div>
                          <CopyToClipboard
                            text={
                              !checkIsPremium()
                                ? ''
                                : stripHtml(
                                    (props.resume?.coverLetter?.title ||
                                      title) +
                                      ' ' +
                                      ' ' +
                                      ' ' +
                                      ' ' +
                                      ' ' +
                                      (props.resume?.coverLetter?.description ||
                                        ''),
                                  )
                            }
                            onCopy={() => {
                              if (!checkIsPremium()) {
                                toggleSubscription();
                              } else {
                                toastr.success('Cover Letter copied!');
                              }
                            }}
                          >
                            {/* <span
                              style={{
                                ...styles.itemText,
                                borderLeft: '1px solid #F0F0F0',
                                paddingLeft: '20px',
                                paddingRight: "20px",
                                cursor: 'pointer',
                              }}
                            >
                              <CopyIcon />
                            </span> */}
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                gap: '5%',
                              }}
                            >
                              <span style={{ ...styles.itemDesc, gap: '1%' }}>
                                Copy
                              </span>
                              <span>
                                <CopyIcon />
                              </span>
                            </div>
                          </CopyToClipboard>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              gap: '4%',
                            }}
                            onClick={() => {
                              if (!checkIsPremium()) toggleSubscription();
                              else !sendMeCoverLoader && sendMeCoverEmail();
                            }}
                          >
                            <span
                              style={{
                                ...styles.itemDesc,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Send me
                            </span>
                            <span>
                              {sendMeCoverLoader ? (
                                <Spin style={{ color: colors.grey }} />
                              ) : (
                                <AiOutlineMail color="black" fontSize={22} />
                              )}
                            </span>
                          </div>
                          {/* <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              gap: '4%',
                            }}
                            onClick={downloadCoverLetter}
                          >
                            <span style={styles.itemDesc}>Download</span>
                            <span>
                              <DownloadIcon />
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              gap: '4%',
                            }}
                            onClick={printCoverLetter}
                          >
                            <span style={styles.itemDesc}>Print</span>
                            <span>
                              <PrintIcon />
                            </span>
                          </div> */}
                        </>
                      ) : (
                        <span
                          style={{
                            display: 'flex',
                            alignitems: 'center',
                            justifyContent: 'center',
                            ...styles.itemText,
                          }}
                        >
                          You do not have cover letter!
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: '1px solid #F0F0F0',
                }}
              >
                <div
                  style={{
                    ...styles.flex,
                    ...styles.resumeTitleBar,
                    ...styles.alignItemsCenter,
                    flexDirection: width < 500 ? 'column' : 'row',
                  }}
                >
                  <div
                    style={{
                      ...styles.flex,
                      alignItems: 'center',
                      ...(width < 650 && {
                        flexDirection: 'column',
                        width: '100%',
                        paddingTop: 10,
                        alignItems: 'flex-start',
                      }),
                    }}
                  >
                    <div
                      style={{
                        ...(width < 650 && { alignSelf: 'center' }),
                      }}
                    >
                      {width < 470 ? (
                        <Tooltip
                          classes={tooltipClasses}
                          title="Send a cover letter ONLY if the job application specifically asks for it"
                        >
                          <InfoIcon
                            onClick={() => setIsFollowUpVideo(true)}
                            text={'PRO TIP'}
                            icon={'Play'}
                            height={20}
                            width="80px"
                            marginTop={0}
                          />
                        </Tooltip>
                      ) : (
                        <>
                          <InfoIcon
                            onClick={() => setIsFollowUpVideo(true)}
                            text={'PRO TIP'}
                            icon={'Play'}
                            height={20}
                            width="80px"
                            marginTop={-4}
                          />
                        </>
                      )}
                    </div>
                    <span
                      style={{
                        ...styles.tableTitle(width),
                      }}
                    >
                      FOLLOW UP EMAIL AFTER YOU APPLY
                    </span>
                  </div>
                  <div
                    style={{
                      float: 'right',
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-end',
                      alignItems: 'baseline',
                      marginRight: width < 650 ? 0 : 10,
                      gap: 15,
                      width: '100%',
                      ...(width < 650 && { padding: '0px 14px 10px 0px' }),
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 14,
                        }}
                      >
                        View
                      </span>

                      <span
                        style={{
                          color: 'gray',
                        }}
                        disabled={followUpLoader}
                        onClick={async () => {
                          if (!checkIsPremium()) toggleSubscription();
                          else {
                            if (previewContent.length > 0) {
                              setViewEmail(true);
                              return;
                            }
                            const emailRes = await getFollowUpEmail();
                            setPreviewContent(
                              emailRes ? stripHtml(emailRes) : '',
                            );
                            setViewEmail(true);
                          }
                        }}
                      >
                        {followUpLoader ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <ViewIcon
                            style={{
                              fontSize: 20,
                            }}
                          />
                        )}
                      </span>
                    </div>
                    <div
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 14,
                        }}
                      >
                        Add
                      </span>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (!checkIsPremium()) toggleSubscription();
                          else setOpen2(true);
                        }}
                      >
                        <NewMessageIcon fontSize={20} />
                      </span>
                    </div>
                    {/* <Button
                      style={{
                        background: colors.blue,
                        color: 'white',
                      }}
                      onClick={() => emailSendAll()}
                    >
                      Send All
                    </Button> */}
                    <div
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span
                        style={{
                          fontSize: 14,
                        }}
                      >
                        Send me
                      </span>

                      <span
                        style={{
                          color: 'gray',
                        }}
                        disabled={sendMeFollowUpLoader}
                        onClick={() => {
                          if (!checkIsPremium()) toggleSubscription();
                          else sendMeFollowUpEmail(user?.email);
                        }}
                      >
                        {sendMeFollowUpLoader ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <AiOutlineMail color="black" fontSize={22} />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    ...styles.flex,
                    ...styles.spaceBetween,
                    flexDirection: width < 650 ? 'column' : 'row',
                    width: width < 1310 ? '100%' : '90%',
                    padding: width < 500 ? 20 : '30px 25px',
                  }}
                >
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexColumn,
                      width: '100%',
                    }}
                  >
                    <span style={{ ...styles.itemDesc, paddingBottom: '15px' }}>
                      Email:
                    </span>
                    <div>
                      {suggestionEmails?.length ? (
                        suggestionEmails?.map((item, i) => {
                          return (
                            <EmailComponent
                              item={item}
                              key={i}
                              onDeleteClick={onDeleteClick}
                              updateEmailHandler={updateEmailHandler}
                              emailSend={emailSend}
                              sendingEmail={mailLoader === item?.senderMail}
                            />
                          );
                        })
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                              color: 'gray',
                              textAlign: 'center',
                            }}
                          >
                            No email found
                          </span>
                          <span
                            style={{
                              fontSize: 15,
                              color: 'gray',
                              textAlign: 'center',
                            }}
                          >
                            Please add email
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: width < 1150 ? '30px' : '0',
                  border: '1px solid #F0F0F0',
                }}
              >
                <div
                  style={{
                    ...styles.flex,
                    ...styles.spaceBetween,
                    ...styles.resumeTitleBar,
                    ...styles.alignItemsCenter,
                  }}
                >
                  <span style={styles.tableTitle(width)}>RESUME INFO</span>

                  <div
                    style={{
                      // padding: "0px 30px",
                      paddingRight: '5px',
                      ...styles.flex,
                      ...styles.alignItemsCenter,
                    }}
                  >
                    <span style={{ ...styles.itemDesc, marginRight: '20px' }}>
                      Make it Default
                    </span>
                    <Switch
                      style={{
                        transform: width < 650 ? 'scale(0.7)' : 'scale(1)',
                      }}
                      checked={
                        props.resumaId === props.resume?.oid ||
                        props.resumaId[props.resumaId.length - 1] ===
                          props.resume?.oid
                      }
                      onColor="#4267b2"
                      onChange={() => onChangeR(props.resume?.oid)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    ...styles.flex,
                    flexDirection: width < 650 ? 'column' : 'row',
                    ...styles.spaceBetween,
                    width: width < 1310 ? '100%' : '95.5%',
                    padding: width < 500 ? 20 : '30px 25px',
                  }}
                >
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexColumn,
                      ...styles.spaceBetween,
                    }}
                  >
                    <div style={{ ...styles.flex, ...styles.flexColumn }}>
                      <span
                        style={{ ...styles.itemDesc, paddingBottom: '15px' }}
                      >
                        Saved Name
                      </span>
                      <Input.Group compact>
                        <Input
                          style={{
                            width: width < 650 ? '100%' : 'calc(308px - 50px)',
                          }}
                          value={savedName}
                          onChange={(e) => {
                            if (e.target.value.length < 50) {
                              setSavedName(e.target.value);
                            }
                          }}
                        />
                        <Button
                          onClick={resumeNameHandler}
                          style={{
                            background: '#4B6FB6',
                            color: 'white',
                            padding: width < 650 ? '2px 10px' : '0 10px',
                            width: width < 650 ? '100%' : 'calc(200px - 50px)',
                          }}
                        >
                          Rename
                        </Button>
                      </Input.Group>
                    </div>
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexColumn,
                        paddingTop: width < 650 ? '30px' : '0',
                      }}
                    >
                      <span
                        style={{ ...styles.itemDesc, paddingBottom: '10px' }}
                      >
                        Copy URL
                      </span>
                      <div
                        style={{
                          ...styles.flex,
                          ...styles.flexRow,
                        }}
                      >
                        {loader ? (
                          <Loader
                            type="ThreeDots"
                            color={colors.blue}
                            height={20}
                            width={20}
                          />
                        ) : (
                          <Input.Group
                            compact
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Input
                              readOnly
                              style={{
                                width:
                                  width < 650
                                    ? 'calc(200px - 50px)'
                                    : 'calc(308px - 50px)',
                                color: colors.blue,
                              }}
                              value={downloadLink}
                            />
                            <CopyToClipboard
                              text={downloadLink}
                              onCopy={() =>
                                toastr.success('Resume URL copied!')
                              }
                            >
                              <span
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '20px',
                                }}
                              >
                                <CopyIcon />
                              </span>
                            </CopyToClipboard>
                          </Input.Group>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexColumn,
                      borderLeft: width < 650 ? 'none' : '1px solid #F0F0F0',
                      paddingLeft: width < 650 ? '0' : '60px',
                      paddingTop: width < 650 ? '30px' : '0',
                      width: '200px',
                    }}
                  >
                    <div
                      style={{
                        marginBottom: '20px',
                        ...styles.flex,
                        ...styles.flexColumn,
                      }}
                    >
                      <span
                        style={{ ...styles.itemDesc, paddingBottom: '10px' }}
                      >
                        Last reviewed
                      </span>
                      <span style={styles.itemText}>
                        {scanResult.updatedAt !== ''
                          ? moment(scanResult.updatedAt)
                              .startOf('hour')
                              .fromNow()
                          : 'Not updated yet!'}
                      </span>
                    </div>
                    <div style={{ ...styles.flex, ...styles.flexColumn }}>
                      <span
                        style={{ ...styles.itemDesc, paddingBottom: '10px' }}
                      >
                        Match Score
                      </span>
                      <p
                        style={{
                          ...styles.itemText,
                          textAlign: 'center',
                          color: 'white',
                          backgroundColor: getScoreColor(
                            location?.state?.data?.total_score ||
                              scanResult?.total_score
                              ? location?.state?.data?.total_score ||
                                  scanResult?.total_score
                              : 'N/A',
                          ),
                          padding: 2,
                          width: 50,
                          borderRadius: 50,
                        }}
                      >
                        {location?.state?.data?.total_score ||
                        scanResult?.total_score
                          ? location?.state?.data?.total_score ||
                            scanResult?.total_score
                          : 'N/A'}
                      </p>
                    </div>
                    <div>
                      <span style={{ ...styles.itemDesc }}>
                        Email Link | Text Link
                      </span>
                      <div
                        style={{
                          ...styles.flex,
                          ...styles.flexRow,
                          marginTop: '5px',
                        }}
                      >
                        {emailLoader ? (
                          <Loader
                            type="TailSpin"
                            color={colors.blue}
                            height={20}
                            width={20}
                            style={{ paddingRight: '20px' }}
                          />
                        ) : (
                          <span
                            onClick={() => sendTextTo('email')}
                            style={{
                              paddingRight: '20px',
                              marginLeft: '20px',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={MsgIcon}
                              alt="message button"
                              style={{
                                width: '30px',
                                height: '30px',
                                objectFit: 'center',
                              }}
                            />
                          </span>
                        )}
                        {mobileLoader ? (
                          <Loader
                            type="TailSpin"
                            color={colors.blue}
                            height={20}
                            width={20}
                            style={{
                              borderLeft: '1px solid #F0F0F0',
                              paddingLeft: '20px',
                            }}
                          />
                        ) : (
                          <span
                            onClick={() => sendTextTo('mobile')}
                            style={{
                              borderLeft: '1px solid #F0F0F0',
                              paddingLeft: '20px',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={MobileIcon}
                              alt="Phone button"
                              style={{
                                width: '30px',
                                height: '30px',
                                objectFit: 'center',
                              }}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: width < 1150 ? '30px' : '0',
                width: width < 1150 ? '100%' : '35%',
                border: '1px solid #F0F0F0',
              }}
            >
              <div style={styles.tableTitle(width)}>OPTIMIZED RESUME</div>
              <div
                style={{
                  ...styles.flex,
                  ...styles.flexColumn,
                  padding: width < 650 ? '16px 0px' : '16px 0px',
                }}
              >
                <div
                  style={{
                    borderBottom: '1px solid #F0F0F0',
                    marginTop: '16px',
                    padding: width < 650 ? '0px 10px' : '0px 40px',
                  }}
                >
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      ...styles.alignItemsCenter,
                    }}
                  >
                    <span style={{ marginRight: '20px' }}>
                      <DownloadIcon />
                    </span>
                    <span style={styles.itemDesc}>Download Resume</span>
                  </div>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      padding: '18px 0',
                      ...styles.alignItemsCenter,
                    }}
                  >
                    {loader ? (
                      <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={35}
                        width={35}
                        style={{ margin: '16px 28px' }}
                      />
                    ) : (
                      <>
                        <span
                          style={{
                            paddingRight: width > 650 ? '28px' : '0px',
                          }}
                          onClick={downloadResume}
                          style={{
                            // padding: "16px 28px",
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            paddingLeft: width < 650 ? '5px' : '28px',
                            paddingRight: '28px',
                            cursor: 'pointer',
                            ...(width < 500 && { padding: '10px' }),
                          }}
                        >
                          <TxtIcon
                            height={width < 500 ? 40 : null}
                            width={width < 500 ? 40 : null}
                          />
                        </span>
                        <span
                          onClick={() => {
                            openUrl('doc');
                          }}
                          style={{
                            padding: width < 500 ? 10 : '16px 28px',
                            borderLeft: '1px solid #F0F0F0',
                            cursor: 'pointer',
                          }}
                        >
                          <WordIcon
                            height={width < 500 ? 40 : null}
                            width={width < 500 ? 40 : null}
                          />
                        </span>
                        <span
                          onClick={() => {
                            openUrl('pdf');
                          }}
                          style={{
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            paddingLeft: width < 650 ? '20px' : '28px',
                            borderLeft: '1px solid #F0F0F0',
                            cursor: 'pointer',
                            ...(width < 500 && { padding: '10px' }),
                          }}
                        >
                          <img
                            src={PdfIcon}
                            alt="Pdf File"
                            height={width < 500 ? 45 : 60}
                            width={width < 500 ? 45 : 60}
                          />
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: '1px solid #F0F0F0',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '16px',
                    gap: width < 1350 ? 0 : 40,
                    padding: width < 650 ? '0px 10px' : '0px 40px',
                  }}
                >
                  <div>
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                        ...styles.alignItemsCenter,
                      }}
                    >
                      <span style={{ marginRight: '20px' }}>
                        <CopyIcon />
                      </span>
                      <span style={styles.itemDesc}>Copy Resume</span>
                    </div>
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                        padding: '18px 0',
                      }}
                    >
                      <CopyToClipboard
                        text={stripHtml(
                          (props.scanResult &&
                            props.scanResult.CompletePlainText) ||
                            '',
                          true,
                        )}
                        onCopy={() => {
                          toastr.success('Resume copied for text file!');
                        }}
                      >
                        <span
                          style={{
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            paddingRight: ' 28px',
                            paddingLeft: width < 650 ? '5px' : '28px',
                            cursor: 'pointer',
                          }}
                        >
                          <TxtIcon
                            height={width < 500 ? 40 : null}
                            width={width < 500 ? 40 : null}
                          />
                        </span>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 7.5,
                    }}
                  >
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                        ...styles.alignItemsCenter,
                      }}
                    >
                      <span style={{ marginRight: '20px' }}>
                        <PrintIcon />
                      </span>
                      <span style={styles.itemDesc}>Print Resume</span>
                    </div>
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                        padding: '18px 0',
                      }}
                    >
                      {props.fedexStatus ? (
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={35}
                          width={35}
                          style={{ margin: '16px 28px' }}
                        />
                      ) : (
                        <>
                          <span
                            onClick={() => {
                              setPrintModal(true);
                              // props.EmailParserAction(data, handleClickOpen);
                            }}
                            style={{
                              paddingTop: '16px',
                              paddingBottom: '16px',
                              paddingRight: '28px',
                              paddingLeft: width < 650 ? '5px' : '28px',
                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={PdfIcon}
                              alt="Pdf File"
                              height={width < 500 ? 45 : 60}
                              width={width < 500 ? 45 : 60}
                            />
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                        ...styles.alignItemsCenter,
                      }}
                    >
                      <span style={{ marginRight: '20px' }}>
                        <ScanIcon />
                      </span>
                      <span style={styles.itemDesc}>Scan QR</span>
                    </div>
                    <div style={{ margin: '30px 0' }}>
                      {downloadLink !== '' || downloadLink === undefined ? (
                        <center className="HpQrcode">
                          <QRCode value={downloadLink} size={60} />
                        </center>
                      ) : (
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={35}
                          width={35}
                          style={{ margin: '16px 28px' }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* <div
                  style={{
                    borderBottom: '1px solid #F0F0F0',
                    marginTop: '16px',
                  }}
                >
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      ...styles.alignItemsCenter,
                    }}
                  >
                    <span style={{ marginRight: '20px' }}>
                      <PrintIcon />
                    </span>
                    <span style={styles.itemDesc}>Print Resume</span>
                  </div>
                  <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      padding: '18px 0',
                    }}
                  >
                    {props.fedexStatus ? (
                      <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={35}
                        width={35}
                        style={{ margin: '16px 28px' }}
                      />
                    ) : (
                      <>
                        <span
                          onClick={() => {
                            setPrintModal(true);
                            // props.EmailParserAction(data, handleClickOpen);
                          }}
                          style={{
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            paddingRight: '28px',
                            paddingLeft: width < 650 ? '5px' : '28px',
                            cursor: 'pointer',
                          }}
                        >
                          <img
                            src={PdfIcon}
                            alt="Pdf File"
                            height={width < 500 ? 45 : 60}
                            width={width < 500 ? 45 : 60}
                          />
                        </span>
                      </>
                    )}
                  </div>
                </div> */}
                <div
                  style={{
                    borderBottom: '1px solid #F0F0F0',
                    backgroundColor: '#EDF0F7',
                    padding: width < 650 ? '16px 10px' : '16px 40px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 20,
                    }}
                  >
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                        ...styles.alignItemsCenter,
                      }}
                    >
                      <span style={{ marginRight: '20px' }}>
                        <img src={USAFlag} width={40} height={25} />
                      </span>
                      <span style={styles.itemDesc}>
                        Export Resume for Government Job
                      </span>
                    </div>
                    <div>
                      <img
                        src={Play}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setGovtJobModal(true)}
                      />
                    </div>
                  </div>
                  <div style={{ margin: '30px 0' }}>
                    <span
                      style={{
                        display: 'flex',
                        alignitems: 'center',
                        justifyContent: 'center',
                        ...styles.itemText,
                      }}
                    >
                      Government Jobs (USA State, Local and Federal*) require
                      additional details compared to traditional resume, and
                      this step will convert the resume so you can apply to
                      government jobs.
                    </span>
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                        ...styles.alignItemsCenter,
                        marginTop: 20,
                      }}
                    >
                      <span style={styles.itemDesc}>
                        Convert to Government Format
                      </span>
                      <div
                        style={{
                          backgroundColor: '#24B80B',
                          borderRadius: '30px',
                          marginLeft: 10,
                          height: 25,
                          width: 80,
                          textAlign: 'center',
                          cursor: 'pointer',
                          paddingTop: 3,
                        }}
                        onClick={() => setGovtJobFormModal(true)}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            color: '#fff',
                            fontWeight: '700',
                          }}
                        >
                          START
                        </span>
                      </div>
                    </div>
                    {/* <span style={styles.itemDesc}>
                     
                    </span> */}
                    {/* <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 20,
                        maxWidth: 300,
                        marginTop: 20,
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignitems: 'center',
                          justifyContent: 'center',
                          ...styles.itemText,
                        }}
                      >
                        Overview of Resume format for Government Jobs
                      </span>

                      <div>
                        <img
                          src={Play}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setGovtJobModal(true)}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                {fileLinks?.doc && (
                  <div
                    style={{
                      marginTop: '16px',
                      borderBottom: '1px solid #F0F0F0',
                      padding: width < 650 ? '0px 10px' : '0px 40px',
                    }}
                  >
                    {/* <div
                    style={{
                      ...styles.flex,
                      ...styles.flexRow,
                      ...styles.alignItemsCenter,
                    }}
                  >
                    <span style={styles.itemDesc}>Start Convert Resume</span>
                    <div
                      style={{
                        backgroundColor: '#24B80B',
                        borderRadius: '30px',
                        marginLeft: 10,
                        height: 25,
                        width: 80,
                        textAlign: 'center',
                        cursor: 'pointer',
                        paddingTop: 3,
                      }}
                      onClick={() => setGovtJobFormModal(true)}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          color: '#fff',
                          fontWeight: '700',
                        }}
                      >
                        START
                      </span>
                    </div>
                  </div> */}

                    <div style={{ margin: '30px 0' }}>
                      <div
                        style={{
                          ...styles.flex,
                          ...styles.flexColumn,
                          paddingTop: width < 650 ? '30px' : '0',
                        }}
                      >
                        <span
                          style={{ ...styles.itemDesc, paddingBottom: '10px' }}
                        >
                          Converted Resume URL
                        </span>
                        <div
                          style={{
                            ...styles.flex,
                            ...styles.flexRow,
                          }}
                        >
                          {loader ? (
                            <Loader
                              type="ThreeDots"
                              color={colors.blue}
                              height={20}
                              width={20}
                            />
                          ) : (
                            <Input.Group
                              compact
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Input
                                readOnly
                                style={{
                                  width:
                                    width < 650
                                      ? 'calc(200px - 50px)'
                                      : 'calc(308px - 50px)',
                                  color: colors.blue,
                                }}
                                value={`${window.location.host}/${
                                  fileLinks?.download ?? ''
                                }&isResumeConverted=true`}
                              />
                              <CopyToClipboard
                                text={`${window.location.host}/${
                                  fileLinks?.download ?? ''
                                }&isResumeConverted=true`}
                                onCopy={() =>
                                  toastr.success('Resume URL copied!')
                                }
                              >
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '20px',
                                  }}
                                >
                                  <CopyIcon />
                                </span>
                              </CopyToClipboard>
                            </Input.Group>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: 40,
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            ...styles.itemDesc,
                            paddingBottom: '10px',
                            marginTop: 15,
                          }}
                        >
                          Converted Resume Links
                        </div>
                        <div
                          style={{
                            marginTop: 12,
                          }}
                        >
                          <span style={{ ...styles.itemDesc }}>
                            Email Link | Text Link
                          </span>
                          <div
                            style={{
                              ...styles.flex,
                              ...styles.flexRow,
                              marginTop: '5px',
                            }}
                          >
                            {emailLoader ? (
                              <Loader
                                type="TailSpin"
                                color={colors.blue}
                                height={20}
                                width={20}
                                style={{ paddingRight: '20px' }}
                              />
                            ) : (
                              <span
                                onClick={() => sendGovtGeneratedTextTo('email')}
                                style={{
                                  paddingRight: '20px',
                                  marginLeft: '20px',
                                  cursor: 'pointer',
                                }}
                              >
                                <img
                                  src={MsgIcon}
                                  alt="message button"
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                    objectFit: 'center',
                                  }}
                                />
                              </span>
                            )}
                            {mobileLoader ? (
                              <Loader
                                type="TailSpin"
                                color={colors.blue}
                                height={20}
                                width={20}
                                style={{
                                  borderLeft: '1px solid #F0F0F0',
                                  paddingLeft: '20px',
                                }}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  sendGovtGeneratedTextTo('mobile')
                                }
                                style={{
                                  borderLeft: '1px solid #F0F0F0',
                                  paddingLeft: '20px',
                                  cursor: 'pointer',
                                }}
                              >
                                <img
                                  src={MobileIcon}
                                  alt="Phone button"
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                    objectFit: 'center',
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <div
                          style={{
                            ...styles.flex,
                            ...styles.flexRow,
                            ...styles.alignItemsCenter,
                          }}
                        >
                          <span style={{ marginRight: '20px' }}>
                            <DownloadIcon />
                          </span>
                          <span style={styles.itemDesc}>
                            Download Converted Resume
                          </span>
                        </div>
                        <div
                          style={{
                            ...styles.flex,
                            ...styles.flexRow,
                            padding: '18px 0',
                            ...styles.alignItemsCenter,
                          }}
                        >
                          {loader ? (
                            <Loader
                              type="TailSpin"
                              color="#00BFFF"
                              height={35}
                              width={35}
                              style={{ margin: '16px 28px' }}
                            />
                          ) : (
                            <>
                              <span
                                style={{
                                  paddingRight: width > 650 ? '28px' : '0px',
                                }}
                                onClick={() => downloadGeneratedResume('txt')}
                                style={{
                                  // padding: "16px 28px",
                                  paddingTop: '16px',
                                  paddingBottom: '16px',
                                  paddingLeft: width < 650 ? '5px' : '28px',
                                  paddingRight: '28px',
                                  cursor: 'pointer',
                                  ...(width < 500 && { padding: '10px' }),
                                }}
                              >
                                <TxtIcon
                                  height={width < 500 ? 40 : null}
                                  width={width < 500 ? 40 : null}
                                />
                              </span>
                              <span
                                onClick={() => downloadGeneratedResume('doc')}
                                style={{
                                  padding: width < 500 ? 10 : '16px 28px',
                                  borderLeft: '1px solid #F0F0F0',
                                  cursor: 'pointer',
                                }}
                              >
                                <WordIcon
                                  height={width < 500 ? 40 : null}
                                  width={width < 500 ? 40 : null}
                                />
                              </span>
                              <span
                                onClick={() => downloadGeneratedResume('pdf')}
                                style={{
                                  paddingTop: '16px',
                                  paddingBottom: '16px',
                                  paddingLeft: width < 650 ? '20px' : '28px',
                                  borderLeft: '1px solid #F0F0F0',
                                  cursor: 'pointer',
                                  ...(width < 500 && { padding: '10px' }),
                                }}
                              >
                                <img
                                  src={PdfIcon}
                                  alt="Pdf File"
                                  height={width < 500 ? 45 : 60}
                                  width={width < 500 ? 45 : 60}
                                />
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = ({ fileScanReducer, templateReducer, authUser }) => {
  return {
    scanResult: fileScanReducer.scanResult,
    resume: fileScanReducer.resume,
    resumaId: fileScanReducer.resumaId,
    coverLetters: templateReducer.coverLetters,
    pdf: templateReducer.pdfLink,
    getPdfStatus: templateReducer.getPdfStatus,
    fedexStatus: fileScanReducer.fedexStatus,
    sendTextToStatus: fileScanReducer.sendTextToStatus,
    user_template: authUser.selectedTemplate,
    user: authUser.user,
  };
};
export default connect(mapStateToProps, {
  scanResultsReload,
  SaveActiveIndex,
  EmailParserAction,
  getCoverLetters,
  saveToken,
  updateMasterResuma,
  UpdateCoverLetter,
  getFollowUpEmail,
  getPdf,
  setDefault,
  updateQuestionsA,
  sendTextToEmailOrMobile,
  updateFileName,
  backupYouResumeAction,
  addNotesAction,
  downloadResumePdf,
})(withRouter(SaveAndApply));
