import React, { useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { useWindowWidth } from '@react-hook/window-size';
import { styles } from './styles';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SolrAutoSuggestion from '../../../../commonComponents/jobSuggestion';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function SelectionCard({ suggestions }) {
  const { suggestionLoader, builderSelectedTitle } = useSelector(
    (store) => store.fileScanReducer,
  );

  const [builderJobTitle, setBuilderJobTitle] = React.useState(
    builderSelectedTitle || '',
  );
  const suggestionData = suggestions?.store || [];
  const suggestionArr =
    (suggestionData &&
      suggestionData?.filter((item) => item !== '' && item !== ' '));

  React.useEffect(() => {
    if (builderSelectedTitle) {
      setBuilderJobTitle(builderSelectedTitle);
    }
  }, [builderSelectedTitle]);
  // const suggestionArr2 = suggestions && suggestions?.store1?.split(/\d+\. /);
  // const suggestionArr3 =
  //   suggestionArr2?.filter((value, index) => {
  //     if (value !== '') {
  //       return value;
  //     }
  //   }) ||
  //   suggestionArr?.filter((value, index) => {
  //     if (value !== '') {
  //       return value;
  //     }
  //   });

  return (
    <div>
      <div style={{ padding: 15, marginBottom: 7 }}>
        <Card>
          <CardBody
            style={{
              background: '#EDF0F7',
              border: '1px solid lightgray',
              overflowX: 'auto',
              padding: 0,
            }}
          >
            <CardTitle
              style={{
                maxWidth: '400px',
                fontWeight: 700,
                fontSize: '20px',
                padding: '25px 15px 0px 15px',
                marginBottom: '7px',
              }}
            >
              {suggestions?.title}
            </CardTitle>
            <div style={{ margin: '0px 10px' }}>
              <SolrAutoSuggestion
                onChangeTitle={(title) => setBuilderJobTitle(title)}
                value={builderJobTitle}
                titleType="builder"
              />
              <div style={{ marginTop: 10 }}>
                {builderSelectedTitle ? (
                  <p>
                    Showing {suggestionArr?.length} results for{' '}
                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                      {builderSelectedTitle}
                    </span>
                  </p>
                ) : (
                  <p>Ready-to-use suggestions</p>
                )}
              </div>
            </div>
            <div
              style={{
                height: 345,
                minWidth: 'fit-content',
                overflowY: 'auto',
                marginTop: 10,
                padding: 10,
              }}
            >
              {suggestionLoader ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <BeatLoader
                    css={override}
                    sizeUnit={'px'}
                    size={15}
                    color={'orange'}
                    loading={true}
                  />
                </div>
              ) : (
                suggestionArr?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div
                        onClick={() =>
                          suggestions?.onClick &&
                          suggestions?.onClick(item, index, suggestions.title)
                        }
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: 10,
                          borderBottom: '1px solid white',
                          borderRadius: 10,
                          backgroundColor: 'transparent',
                          cursor: 'pointer',
                          marginBottom: 10,

                          ...(suggestions?.data?.includes(item) ||
                            suggestions?.data[suggestions?.expanded]
                              ?.toLowerCase()
                              ?.includes(item?.toLowerCase())
                            ? { opacity: 0.5 }
                            : { opacity: 1 }),
                        }}
                      >
                        <div
                          style={{
                            width: '28px',
                            height: '28px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#4267b2',
                          }}
                        >
                          {suggestions?.data?.includes(item) ||
                            suggestions?.data[suggestions?.expanded]
                              ?.toLowerCase()
                              ?.includes(item?.toLowerCase()) ? (
                            <CheckIcon style={{ color: '#FFFFFF' }} />
                          ) : (
                            <AddIcon style={{ color: '#FFFFFF' }} />
                          )}
                        </div>
                        <div style={{ flex: 1, marginLeft: 20 }}>{item}</div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
