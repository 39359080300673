
import { PdfDocument, PdfBitmap, PdfPageSettings, SizeF, PdfPageOrientation } from '@syncfusion/ej2-pdf-export';

export const pdfFromEditor = (docEditor, fileName) => {

    var pdfdocument = new PdfDocument();
    var count = docEditor.documentEditor.pageCount;
    docEditor.documentEditor.documentEditorSettings.printDevicePixelRatio = 2;
    var loadedPage = 0;
    for (let i = 1; i <= count; i++) {
        setTimeout(() => {
            var image = docEditor.documentEditor.exportAsImage(i, 'image/jpeg');
            image.onload = function () {
                var imageHeight = parseInt(
                    image.style.height.toString().replace('px', '')
                );
                var imageWidth = parseInt(
                    image.style.width.toString().replace('px', '')
                );
                var section = pdfdocument.sections.add();
                var settings = new PdfPageSettings(0);
                if (imageWidth > imageHeight) {
                    settings.orientation = PdfPageOrientation.Landscape;
                }
                settings.size = new SizeF(imageWidth, imageHeight);
                section.setPageSettings(settings);
                var page = section.pages.add();
                var graphics = page.graphics;
                var imageStr = image.src.replace('data:image/jpeg;base64,', '');
                var pdfImage = new PdfBitmap(imageStr);
                graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
                loadedPage++;
                if (loadedPage === count) {
                    pdfdocument.save(fileName + '.pdf'
                    );
                }
            };
        }, 500);
    }
};