import React from 'react'

export default function PrintIcon() {
    return (
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 1H7V4H17V1Z" fill="white" />
            <path d="M22 4H18V1C18 0.5 17.5 0 17 0H7C6.5 0 6 0.5 6 1V4H2C0.9 4 0 4.9 0 6V13C0 14.1 0.9 15 2 15H6V19C6 19.5 6.5 20 7 20H17C17.5 20 18 19.5 18 19V15H22C23.1 15 24 14.1 24 13V6C24 4.9 23.1 4 22 4ZM7 1H17V4H7V1ZM17 19H7V12H17V19ZM17 9H14C13.5 9 13 8.5 13 8C13 7.5 13.5 7 14 7H17C17.5 7 18 7.5 18 8C18 8.5 17.5 9 17 9Z" fill="#4A4A4A" />
            <path d="M18 8C18 8.5 17.5 9 17 9H14C13.5 9 13 8.5 13 8C13 7.5 13.5 7 14 7H17C17.5 7 18 7.4 18 8Z" fill="#313E4D" />
            <path d="M7 12V19H17V12H7ZM13 17.5H9C8.7 17.5 8.5 17.3 8.5 17C8.5 16.7 8.7 16.5 9 16.5H13C13.3 16.5 13.5 16.7 13.5 17C13.5 17.3 13.3 17.5 13 17.5ZM15 15H9C8.7 15 8.5 14.8 8.5 14.5C8.5 14.2 8.7 14 9 14H15C15.3 14 15.5 14.2 15.5 14.5C15.5 14.8 15.3 15 15 15Z" fill="#313E4D" />
            <path d="M7 12V19H17V12H7ZM13 17.5H9C8.7 17.5 8.5 17.3 8.5 17C8.5 16.7 8.7 16.5 9 16.5H13C13.3 16.5 13.5 16.7 13.5 17C13.5 17.3 13.3 17.5 13 17.5ZM15 15H9C8.7 15 8.5 14.8 8.5 14.5C8.5 14.2 8.7 14 9 14H15C15.3 14 15.5 14.2 15.5 14.5C15.5 14.8 15.3 15 15 15Z" fill="white" />
            <path d="M18 8C18 8.5 17.5 9 17 9H14C13.5 9 13 8.5 13 8C13 7.5 13.5 7 14 7H17C17.5 7 18 7.4 18 8Z" fill="white" />
            <path d="M15.5 14.5C15.5 14.8 15.3 15 15 15H9C8.7 15 8.5 14.8 8.5 14.5C8.5 14.2 8.7 14 9 14H15C15.3 14 15.5 14.2 15.5 14.5Z" fill="#4A4A4A" />
            <path d="M13.5 17C13.5 17.3 13.3 17.5 13 17.5H9C8.7 17.5 8.5 17.3 8.5 17C8.5 16.7 8.7 16.5 9 16.5H13C13.3 16.5 13.5 16.7 13.5 17Z" fill="#4A4A4A" />
        </svg>
    )
}