import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ChangePassword from './changePass';
import AccountDetail from './Account-detail';
import Notifications from '../notifications/';
import DeleteAccount from '../profile/deleteAccount';
import { styles } from './style';
import { useSelector, useDispatch } from 'react-redux';
import Basic from '../profile/basic';
import { useWindowWidth } from '@react-hook/window-size';
import { GetUserDetailAction } from '../profile/redux/action';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Account() {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const user = useSelector((state) => state.authUser.user);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabValue = localStorage.getItem('tab') || 0;

  React.useEffect(() => {
    if (window.location.href.includes('?subscription')) {
      setValue(1);
    } else if (window.location.href.includes('?')) {
      setValue(3);
    } else {
      setValue(0);
    }

    dispatch(GetUserDetailAction(setLoading));
  }, []);
  return (
    <div className="account-container" style={styles.mainContainer(width)}>
      <div>
        <Tabs
          TabIndicatorProps={{ style: { background: '#4267B2' } }}
          orientation={width < 700 ? 'horizontal' : 'vertical'}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          style={styles.mainTab(width)}
        >
          <Tab
            label="Basics"
            onClick={() => localStorage.setItem('tab', 0)}
            {...a11yProps(0)}
            className="change_font_style"
            style={styles.fontWeight_600}
          />
          <Tab
            onClick={() => localStorage.setItem('tab', 1)}
            label="Subscription"
            {...a11yProps(1)}
            className="change_font_style"
            style={styles.fontWeight_600}
          />
          <Tab
            onClick={() => localStorage.setItem('tab', 2)}
            label="Change Password"
            {...a11yProps(2)}
            className="change_font_style"
            style={styles.fontWeight_600}
          />

          <Tab
            onClick={() => localStorage.setItem('tab', 3)}
            label="Notifications"
            {...a11yProps(3)}
            className="change_font_style"
            style={styles.fontWeight_600}
          />
          {/* <Tab
               onClick={()=>localStorage.setItem('tab', 4)}
            {...a11yProps(4)}
            label={
              width < 700 ? (
                'Downgrade to Free Account'
              ) : (
                <p>
                  Downgrade to <br /> Free Account
                </p>
              )
            }
            className="change_font_style"
            style={{
              ...styles.fontWeight_600,
            }}
          /> */}
        </Tabs>
      </div>
      <div>
        <TabPanel value={value} index={0} style={styles.width_100_percent}>
          <Basic loading={loading} />
        </TabPanel>
        <TabPanel value={value} index={1} style={styles.width_100_percent}>
          <AccountDetail handleChange={handleChange} loading={loading} />
        </TabPanel>
        <TabPanel value={value} index={2} style={styles.width_100_percent}>
          <ChangePassword user={user} loading={loading} />
        </TabPanel>
        {/* <TabPanel value={value} index={3} style={styles.width_100_percent}>
          <Setting user={user} loading={loading} />
        </TabPanel> */}
        <TabPanel value={value} index={3} style={styles.width_100_percent}>
          <Notifications loading={loading} />
        </TabPanel>
        <TabPanel value={value} index={4} style={styles.width_100_percent}>
          <DeleteAccount
            handleChange={handleChange}
            user={user}
            loading={loading}
          />
        </TabPanel>
      </div>
    </div>
  );
}
