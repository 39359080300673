import { colors } from '../../../../commonComponents/colors';

export const styles = {
  background: {
    backgroundColor: 'white',
  },
  img: {
    width: 350,
    height: 500,
  },
  pb_30: {
    paddingBottom: 30,
  },
  pricing: {
    marginTop: 82,
    marginBottom: 82,
  },
  discount: {
    marginTop: 100,
    marginBottom: 100,
  },
  minWidth: {
    minWidth: '190px',
  },
  closeBtn: {
    position: 'absolute',
    right: 2,
    top: 2,
    zIndex: 99,
    cursor: 'pointer',
    color: colors.red,
    fontSize: 24,
  },
  successBtn: {
    cursor: 'pointer',
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    color: 'green',
  },
  listContainer: (width) => {
    return {
      display: 'flex',
      flexDirection: width < 1250 ? 'column' : 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    };
  },
  inputFields: (width) => {
    return {
      width: width < 1250 ? '100%' : '40%',
      margin: '5px',
    };
  },
  addButton: {
    background: '#4267B2',
    color: 'white',
    margin: '5px',
  },
  emailList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '10px',
  },
  listMain: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    borderBottom: '1px solid #4267B2',
    boxShadow: '0px 0px 3px gray',
    padding: '10px',
  },
  listNumber: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  emailDiv: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    textAlign: 'left',
  },
  email: {
    fontSize: '14px',
    color: '#grey',
  },
  date: {
    fontSize: '12px',
    color: '#grey',
  },
  fontWeight_bold: {
    fontWeight: 'bold',
  },
  fontSize_85: {
    fontSize: '85%',
  },
  bb_ligthGrey: {
    border: '0.5px dashed lightgray',
  },
  mt_5: {
    marginTop: 5,
  },
  mt_30: { marginTop: 30 },
  mt_20: {
    marginTop: 20,
  },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_90: {
    marginBottom: 90,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  currentSubscription: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 30,
  },
  changePass_Col: {
    display: 'flex',
    justifyContent: 'center',
  },
  changePass_Col1: {
    marginBottom: 10,
    width: '100%',
  },
  changePassBtn: {
    borderRadius: 0,
    backgroundColor: colors.blue,
    color: 'white',
    marginTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  activeIcon: {
    // position: "absolute",
    // right: 10,
    // top: 10,
    backgroundColor: '#2ecc71',
    color: 'white',
    marginTop: 2,
    marginLeft: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 5,
    paddingBottom: 3,
    fontSize: 12,
    height: 30,
  },
  expiryStyle: {
    color: 'rgba(0,0,0,0.5)',
  },
};
