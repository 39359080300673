import React from 'react';
import { Text, Dnd } from '../../component';
import { Grid } from '@material-ui/core';
//eslint-disable-next-line
import { useStyles } from '../style';
import { useDispatch } from 'react-redux';
import { updateOrder, addNewObj, deleteObjInArray } from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import InputText from '../../component/inputText';
import { colors } from '../../../../commonComponents/colors';

export default function Military(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'military.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');

    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;
  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Military
          </label>

        </div>
      </Grid>
      <Dnd
        data={data}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <div>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                <div>
                  <label className={classes.label}>Branch</label>
                  <Text
                    value={item['branch']}
                    placeholder="US Army"
                    customclass={classes.input}
                    path={`${path}.${index}.branch`}
                  />
                </div>
                <div>
                  <label className={classes.label}>Start Date</label>
                  <InputText
                    type="month"
                    value={item?.DatesOfService?.StartDate?.YearMonth ?? ''}
                    placeholder="MM / YYYY"
                    customclass={classes.input}
                    path={`${path}.${index}.DatesOfService.StartDate.YearMonth`}
                  />
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                <div>
                  <label className={classes.label}>Current/End Rank</label>
                  <Text
                    value={item?.RankAchieved?.CurrentOrEndRank}
                    placeholder="FIRST LIEUTENANT"
                    customclass={classes.input}
                    path={`${path}.${index}.RankAchieved.CurrentOrEndRank`}
                  />
                </div>
                <div>
                  <label className={classes.label}>End Date</label>
                  <InputText
                    type="month"
                    value={item?.DatesOfService?.EndDate?.YearMonth ?? ''}
                    placeholder="MM / YYYY"
                    customclass={classes.input}
                    path={`${path}.${index}.DatesOfService.EndDate.YearMonth`}

                  />
                </div>
              </Grid>
            </Grid>
            {data.length > 1 && (
              <hr style={{ borderTop: 'dashed 1px #9E9E9E', marginTop: 20 }} />
            )}
          </div>
        )}
      />
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more military
      </div>
    </>
  );
}
