import React from 'react';
import { Text, Dnd } from '../../component';
import { Grid } from '@material-ui/core';
//eslint-disable-next-line
import { useStyles } from '../style';
import { useDispatch } from 'react-redux';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import RichEditor from '../helper/RichEditor';
import Accordian from '../helper/Accordian';
import InputText from '../../component/inputText';
import { colors } from '../../../../commonComponents/colors';

export default function Projects(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'projects.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Projects
          </label>
        </div>
      </Grid>
      <Dnd
        data={data}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <Accordian
            defaultExpanded={false}
            summary={
              <>
                {' '}
                <label
                  className={
                    item.Title || item?.institution
                      ? classes.summaryLabel
                      : classes.summaryLabelGrey
                  }
                >
                  {item.Title || item?.institution
                    ? item.Title
                    : 'Add Project Details'}{' '}
                  {'\n '}
                </label>
                <br />
                <label className={classes.summarySubLabel}>
                  {item.Duration}
                  {item.TechnologiesUsed ? ' - ' + item.TechnologiesUsed : ''}
                </label>
              </>
            }
          >
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                <div>
                  <label className={classes.label}>Title</label>
                  <Text
                    value={item.Title}
                    placeholder="Project Title"
                    customclass={classes.input}
                    path={`${path}.${index}.Title`}
                  />
                </div>
                <div>
                  <label className={classes.label}>Start Date</label>
                  <InputText
                    type="month"
                    value={item.Duration ?? ''}
                    placeholder="MM / YYYY"
                    customclass={classes.input}
                    path={`${path}.${index}.Duration`}
                  />
                </div>
              </Grid>
              <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                <div>
                  <label className={classes.label}>Company</label>
                  <Text
                    value={item.Company}
                    placeholder="Company"
                    customclass={classes.input}
                    path={`${path}.${index}.Company`}
                  />
                </div>
                <div>
                  <label className={classes.label}>End Date</label>
                  <InputText
                    type="month"
                    value={item.TechnologiesUsed ?? ''}
                    placeholder="MM / YYYY"
                    customclass={classes.input}
                    path={`${path}.${index}.TechnologiesUsed`}
                  />
                </div>

                {/* <div>
                <label className={classes.label}>Technologies Used</label>
                <Text
                value={item.TechnologiesUsed}
                placeholder="Ex: React, Node js, Django, Angular...."
                customclass={classes.input}
                  path={`${path}.${index}.TechnologiesUsed`}
                />
              </div> */}
              </Grid>
            </Grid>
            <div>
              <label className={classes.label} style={{ marginTop: 14 }}>
                Summary
              </label>
              {/* <Text
              value={item.Summary}
              placeholder="Summary"
              customclass={`${classes.input} ${classes.summaryInput}`}
              path={`${path}.${index}.Summary`}
            /> */}

              <RichEditor
                value={item.Summary}
                placeHolder="Description"
                customclass={`${classes.input} ${classes.summaryInput}`}
                path={`${path}.${index}.Summary`}
                onBlurField={onBlurField}
              />
            </div>

            {data.length > 1 && (
              <hr style={{ borderTop: 'dashed 1px #9E9E9E', marginTop: 20 }} />
            )}
          </Accordian>
        )}
      />
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more project
      </div>
    </>
  );
}
