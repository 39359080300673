/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const CURRENT_USER = 'CURRENT_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const ERROR_OCCURED = 'ERROR_OCCURED';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';
export const SAVE_TOKEN = 'SAVE_TOKEN';

export const CURRENT_USER_LOADING = 'CURRENT_USER_LOADING';
export const CHANGE_SYNC = 'CHANGE_SYNC';
export const NOT_VERIFIED = 'NOT_VERIFIED';

export const REQUEST_FOR_VERIFY = 'REQUEST_FOR_VERIFY';
export const VERIFIED_SUCCESSFULLY = 'VERIFIED_SUCCESSFULLY';
export const VERFIED_FAILED = 'VERFIED_FAILED';

export const UPGRADE_PLAN_MODAl = 'UPGRADE_PLAN_MODAl';
export const UPDATE_HOWTOSTART = 'UPDATE_HOWTOSTART';
export const CONTACT_MODAL = 'CONTACT_MODAL';
