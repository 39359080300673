import React, { useEffect, useSearchParams, useState } from 'react';
import axios from 'axios';
import { useStyles } from './DownloadUserDocStyle';
import { Grid } from '@material-ui/core';
import H from '../../assets/H.webp';
import GaugeChart from '../../commonComponents/react-advanced-gauge-chart/dist/GaugeChart';
import { colors } from '../../commonComponents/colors';
import HelpIcon from '@material-ui/icons/Help';
function DownloadUserDoc(props) {
  const styles = useStyles();
  const [userName, setUserName] = useState('');
  const [resumeName, setResumeName] = useState('');
  const [resumeScore, setResumeScore] = useState('');
  const isResumeConverted =
    new URLSearchParams(props.location.search).get('isResumeConverted') || '';
  const userOid = new URLSearchParams(props.location.search).get('oid') || '';
  useEffect(async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/get-resume-details`,
      { oid: userOid },
    );
    setUserName(
      response?.data?.data?.userName ? response?.data?.data?.userName : 'N/A',
    );
    setResumeName(
      response?.data?.data?.resumeName?.length
        ? response?.data?.data?.resumeName
        : 'N/A',
    );
    setResumeScore(response?.data?.data?.resumeScore);
  }, []);

  let downloadDoc = (type) => {
    if (type && userOid) {
      let url;
      if (isResumeConverted) {
        url = `${process.env.REACT_APP_API_URL}/download-government-resume?oid=${userOid}&type=${type}`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/download-user-doc?oid=${userOid}&type=${type}`;
      }
      window.open(url);
    }
  };

  return (
    <div className={styles.mainColor}>
      <div className={styles.body}>
        <div>
          <div className={styles.logo}>
            <img src={H} height={25} width={100} />
          </div>
          <div className={styles.downloadContainer}>
            <center className={styles.imageLogo}>
              <div className={styles.matchScore}>
                MatchScore<sup style={{ marginLeft: 3 }}>TM</sup>
                <HelpIcon className={styles.iconQuestion} />
              </div>
              <GaugeChart
                id="gauge-chart3"
                nrOfLevels={3}
                textColor={'#4c4c4c'}
                colors={[colors.red, colors.lightBlue, colors.green]}
                arcsLength={[50, 30, 20]}
                percent={resumeScore / 100}
              />
              <br />
            </center>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <div>
                  <p className={styles.user}>User Name</p>
                  <p className={styles.user}>Job Title</p>
                </div>
                <div>
                  <p className={styles.details}>{userName}</p>
                  <p className={styles.details}>{resumeName}</p>
                </div>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <button
                      className={styles.buttonpdf}
                      onClick={() => {
                        downloadDoc('pdf');
                      }}
                    >
                      Download PDF
                    </button>
                  </Grid>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <button
                      className={styles.buttonDocx}
                      onClick={() => {
                        downloadDoc('docx');
                      }}
                    >
                      Download DOC
                    </button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadUserDoc;
