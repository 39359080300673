import { colors } from '../colors';

const styles = {
  container: {
    padding: 25,
  },
  infoItem: {
    display: 'flex',
    alignItems: 'baseline',
  },
  light: {
    fontWeight: 'normal',
    color: colors.fontGray,
    fontSize: 14,
  },
  para: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: 14,
  },
};

export default styles;
