import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';

import { useWindowSize } from '@react-hook/window-size';
import { updateLinkedinData } from '../redux/actions';

export default function SecurityCreds(props) {
  const [width] = useWindowSize();
  const [name, setName] = useState('');
  const [mode, setMode] = useState('');
  const [index, setIndex] = useState();

  const [copied, setCopied] = useState([]);

  const dispatch = useDispatch();
  const linkedinData = props.linkedinData;
  const SecurityCredsModal = useSelector(
    (state) => state.linkedinReducer.securityCredsModal,
  );
  const securityCredsModalMode = useSelector(
    (state) => state.linkedinReducer.securityCredsModalMode,
  );
  const cb = (v) => {
    dispatch({
      type: 'SECURITY_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      toastr.success('Security Clearance');
    } else {
      toastr.error('Error Updating Security Clearance');
    }
  };
  useEffect(() => {
    if (securityCredsModalMode === 'new') {
      setMode('new');
      setName('');
    } else {
      setMode('edit');
    }
  }, [securityCredsModalMode]);

  const handleUpdate = () => {
    if (securityCredsModalMode === 'new') {
      if (Array.isArray(linkedinData.securityClearence)) {
      linkedinData.securityClearence.push({
        Name: name,
      });
    } else {
      linkedinData.securityClearence = [
        {
          Name: name,
        },
      ];
    }
    } else {
      linkedinData.securityClearence[index].Name = name;
    }

    dispatch(updateLinkedinData(linkedinData, cb));

    dispatch({
      type: 'SECURITY_MODAL',
      payload: {
        open: false,
        mode: mode,
        index: index,
      },
    });
  };

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };
  return (
    <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
      {Object?.keys(linkedinData?.securityClearence || {})?.length
        ? linkedinData?.securityClearence?.map((v, i) => {
            return (
              <Row key={i} className="mt-2">
                <Col
                  xs={10}
                  lg={11}
                  xl={11}
                  md={11}
                  className="border-bottom p-5"
                >
                  {v?.Name}
                </Col>
                <Col
                  xs={2}
                  lg={1}
                  xl={1}
                  md={1}
                  className="border-bottom p-2 d-flex align-items-center justify-content-center"
                >
                  <span
                    style={styles.copyIcon}
                    onClick={() => {
                      setName(v?.Name);
                      setIndex(i);
                      dispatch({
                        type: 'SECURITY_MODAL',
                        payload: {
                          open: true,
                          mode: mode,
                        },
                      });
                    }}
                  >
                    {props.stepIndex === 1 ? (
                      <CreateIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    ) : (
                      <FileCopyIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    )}
                  </span>
                </Col>
              </Row>
            );
          })
        : 'NO DATA'}

      <Popup
        open={SecurityCredsModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'SECURITY_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
     
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'SECURITY_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>

        <h5>Security Clearance</h5>

        <Row>
          <Col>
            <Label for="exampleEmail">Title</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />{' '}
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(name) ? (
                    <CopyToClipboard
                      text={name}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(name);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={false}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  Save
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    // position: "absolute",
    // right: "10px",
    // top: "10px",
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};
