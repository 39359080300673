import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { jobInfo } from './redux/action';
import { currentUser } from '../auth/redux/actions';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { styles } from './style';
import Select2 from 'react-select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CreatableSelect from 'react-select/creatable';

import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class JobInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownBasicOpen: false,
      dropdownBasicOpenJobSeeker: false,
      dropdownBasicOpenIndustry: false,
      dropdownBasicOpenEmployment: false,
      dropdownBasicOpenRecruiter: false,
      dropdownBasicOpenOther: false,
      dropDownValue: 'Please specify your Profession',
      dropDownValueJobSeeker: 'Please specify',
      dropDownValueIndustry: 'Please specify your Industry',
      dropDownValueEmployment: 'Please specify',
      dropDownValueRecruiter: 'Please specify',
      dropDownValueOther: 'Please specify',

      startDateLabelTop: null,
      student: false,
      entrylevel: false,
      midlevel: false,
      seniorlevel: false,
      exective: false,
      veteran: false,
      newGrad: false,
      postGrade: false,
      loader: false,
      currentJob: '',
      pastJob: '',
      tags: ['nodejs', 'reactjs', 'php'],
      show_skills: false,
      job_location: [],
      job_type: '',
      skills: '',
      job_titles: [],
      places: '',
      multiLocations: [],
      selectedJobTypes: [],
      value2: '',
      jobValue: '',
    };
  }

  UNSAFE_componentWillMount() {
    let { user } = this.props;

    if (user) {
      if (user.type) {
        let isStudent = false;
        let entryLevel = false;
        let midLevel = false;
        let seniorLevel = false;
        let executive = false;
        let veteran = false;
        let newGrad = false;
        let postGrad = false;
        let jobType = [];

        let skills = [];
        let job_location = [];
        let job_titles = [];

        let profession = 'Please specify your Profession';
        let jobseeker = 'Please specify';
        let recruiter = 'Please specify';
        let employmentspecilist = 'Please specify';
        let industry = 'Please specify your Industry';
        let other = 'Please specify';
        let currentJob = '';
        let pastJob = '';

        if (user.type.student) {
          switch (user.type.student) {
            case 'true':
              isStudent = true;
              break;
            default:
              break;
          }
        }

        if (user.type.entrylevel) {
          switch (user.type.entrylevel) {
            case 'true':
              entryLevel = true;
              break;
            default:
              break;
          }
        }

        if (user.type.midlevel) {
          switch (user.type.midlevel) {
            case 'true':
              midLevel = true;
              break;
            default:
              break;
          }
        }

        if (user.type.seniorlevel) {
          switch (user.type.seniorlevel) {
            case 'true':
              seniorLevel = true;
              break;
            default:
              break;
          }
        }

        if (user.type.exective) {
          switch (user.type.exective) {
            case 'true':
              executive = true;
              break;
            default:
              break;
          }
        }

        if (user.type.veteran) {
          switch (user.type.veteran) {
            case 'true':
              veteran = true;
              break;
            default:
              break;
          }
        }

        if (user.type.newGrad) {
          switch (user.type.newGrad) {
            case 'true':
              newGrad = true;
              break;
            default:
              break;
          }
        }

        if (user.type.postGrad) {
          switch (user.type.postGrad) {
            case 'true':
              postGrad = true;
              break;
            default:
              break;
          }
        }

        if (user.type.role) {
          profession = user.type.role;
        }
        if (user.type.job_type) {
          jobType = user.type.job_type;

          this.setState({ selectedJobTypes: jobType });
        }
        if (user.type.job_titles) {
          job_titles = user.type.job_titles?.map((title) => {
            return {
              value: title,
              label: title,
            };
          });
        }
        if (user.type.skills) {
          skills = user.type.skills?.map((skill) => {
            return {
              value: skill,
              label: skill,
            };
          });
        }
        if (user.type.job_location) {
          let arr = [];
          arr = user.type.job_location.map((n) => ({
            value: n,
            label: n,
          }));
          this.setState({
            multiLocations: arr.length ? arr : [],
          });
        }

        if (user.type.jobseeker) {
          jobseeker = user.type.jobseeker;
        }

        if (user.type.Recruiter) {
          recruiter = user.type.Recruiter;
        }

        if (user.type.Specilization) {
          employmentspecilist = user.type.Specilization;
        }

        if (user.type.Industry) {
          industry = user.type.Industry;
        }

        if (user.type.currentJob) {
          currentJob = user.type.currentJob;
        }
        if (user.type.pastJob) {
          pastJob = user.type.pastJob;
        }

        if (user.type.Other) {
          other = user.type.Other;
        }

        this.setState({
          student: isStudent,
          entrylevel: entryLevel,
          midlevel: midLevel,
          seniorlevel: seniorLevel,
          exective: executive,
          veteran: veteran,
          newGrad: newGrad,
          postGrade: postGrad,
          dropDownValue: profession,
          dropDownValueJobSeeker: jobseeker,
          dropDownValueRecruiter: recruiter,
          dropDownValueEmployment: employmentspecilist,
          dropDownValueIndustry: industry,
          currentJob: currentJob,
          pastJob: pastJob,
          job_type: jobType,
          // selectedJobTypes: jobType,
          job_location: job_location,
          skills: skills,
          job_titles: job_titles,

          dropDownValueOther: other,
        });
      }
    }
  }

  componentDidMount() {
    this.props.currentUser();
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.profileStatus === 'done') {
      this.props.currentUser();
      this.setState({ loader: false });
    }
    if (nextProps.profileStatus === 'not done') {
      this.setState({ loader: false });
    }
  };
  changeJobType = (e) => {
    this.setState({ job_type: e });
  };
  saveInfo = () => {
    let skillsArr = this.state.skills?.map((n) => n.value);
    let jobTitlesArr = this.state.job_titles?.map((n) => n.value);
    let arr = [];
    if (Array.isArray(this.state.multiLocations)) {
      arr.push(this.state.multiLocations.map((n) => n.value));
    }

    this.setState({ loader: true });
    this.props.jobInfo({
      type: {
        job_titles: jobTitlesArr ? jobTitlesArr : [],
        job_type: this.state.selectedJobTypes,
        skills: skillsArr ? skillsArr : [],
        job_location: arr.length ? arr[0] : [],
      },
    });
  };

  // Google Places Functions
  handleChange = (address) => {
    this.setState({
      places: address,
    });
  };
  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          multiLocations: [
            ...this.state.multiLocations,
            results[0].address_components[0].short_name,
          ],
        });
      })

      .catch((error) => console.error('Error', error));
  };
  customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      minHeight: 45,
    }),
  };

  handleJobType = (e) => {
    let selectedJobTypes = this.state.selectedJobTypes;
    if (selectedJobTypes?.length == 0) {
      let d = [];
      d.push(e.target.name);

      this.setState({
        selectedJobTypes: d,
      });
    } else {
      let checkObj = selectedJobTypes.find((item) => item === e.target.name);
      if (checkObj) {
        let d = [];
        d = selectedJobTypes.filter((item) => item !== e.target.name);
        this.setState({
          selectedJobTypes: d,
        });
      } else {
        let selected = selectedJobTypes;
        selected.push(e.target.name);
        this.setState({
          selectedJobTypes: selected,
        });
      }
    }
  };
  components = {
    DropdownIndicator: null,
  };
  createOption = (label) => ({
    label,
    value: label,
  });
  handleEmailChange = (value) => {
    console.group('Value Changed');

    console.groupEnd();
    this.setState({
      skills: value === null ? [] : value,
    });
  };
  handleInputChange = (value) => {
    this.setState({
      value2: value,
    });
  };

  handleKeyDown = (event) => {
    if (!this.state.value2) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        console.group('Value Added');

        console.groupEnd();
        this.setState({
          value2: '',
        });
        this.setState({
          skills: [...this.state.skills, this.createOption(this.state.value2)],
        });
        break;
      default:
        break;
    }
  };
  handleEmailChangeJob = (value) => {
    console.group('Value Changed');

    console.groupEnd();
    this.setState({
      job_titles: value === null ? [] : value,
    });
    // setValue2(value === null ? [] : value);
  };
  handleInputChangeJob = (value) => {
    this.setState({
      jobValue: value,
    });
    // setInputValue(value);
    // this.setState({
    //   emails: value,
    // });
  };

  handleKeyDownJob = (event) => {
    if (!this.state.jobValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        // console.group("Value Added");
        //
        // console.groupEnd();
        this.setState({
          jobValue: '',
        });
        this.setState({
          job_titles: [
            ...this.state.job_titles,
            this.createOption(this.state.jobValue),
          ],
        });
    }
  };

  render() {
    return (
      <div className="change_font_style">
        <div style={styles.fontWeight_bold}>JOB INFORMATION</div>
        <div style={styles.bb_ligthGrey}></div>
        <Row style={(styles.mt_30, { marginLeft: 5, marginRight: 5 })}>
          <Col lg="12" sm="12" md="12" xs="12" style={styles.mb_10}>
            <h5 style={{ ...styles.h5, paddingTop: 6, paddingBottom: 6 }}>
              Job Type
            </h5>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.selectedJobTypes.some(
                      (v) => v === 'Full Time',
                    )}
                    onChange={this.handleJobType}
                    name="Full Time"
                    color="primary"
                  />
                }
                label="Full Time"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.selectedJobTypes.some(
                      (v) => v === 'Remote',
                    )}
                    onChange={this.handleJobType}
                    name="Remote"
                    color="primary"
                  />
                }
                label="Remote"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.selectedJobTypes.some(
                      (v) => v === 'Part Time',
                    )}
                    onChange={this.handleJobType}
                    name="Part Time"
                    color="primary"
                  />
                }
                label="Part Time"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.selectedJobTypes.some(
                      (v) => v === 'Onsite',
                    )}
                    onChange={this.handleJobType}
                    name="Onsite"
                    color="primary"
                  />
                }
                label="Onsite"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.selectedJobTypes.some(
                      (v) => v === 'Contract',
                    )}
                    onChange={this.handleJobType}
                    name="Contract"
                    color="primary"
                  />
                }
                label="Contract"
              />
            </FormGroup>
            {/* <Select2
              isMulti
              options={jobType}
              defaultValue={this.state.selectedJobTypes}
              onChange={this.handleJobType}
            /> */}
          </Col>
          <Col lg="12" sm="12" md="12" xs="12" style={styles.mb_10}>
            <h5 style={{ ...styles.h5, paddingTop: 6, paddingBottom: 6 }}>
              Job Titles:
            </h5>
            <CreatableSelect
              styles={{ ...this.customStyles }}
              components={this.components}
              inputValue={this.state.jobValue}
              isClearable={false}
              isMulti
              menuIsOpen={false}
              onBlur={() =>
                this.handleKeyDownJob({
                  key: 'Tab',
                })
              }
              onChange={this.handleEmailChangeJob}
              onInputChange={this.handleInputChangeJob}
              onKeyDown={this.handleKeyDownJob}
              placeholder="Add job Titles"
              value={this.state.job_titles}
            />
            {/* <InputGroup style={{ ...styles.inputGroup, marginTop: 0 }}>
              <InputGroupAddon addonType="prepend">
                <span className="input-group-text">Job Title(s)</span>
              </InputGroupAddon> */}

            {/* <TagsInput
              tagProps={{
                className: "react-tagsinput-tag",
              }}
              value={this.state.job_titles}
              onChange={(tags) => {
                if (this.state.show_skills) {
                  this.setState({
                    show_skills: false,
                  });
                }
                this.setState({
                  job_titles: tags,
                });
              }}
              addOnBlur
              inputProps={{
                className: "react-tagsinput-input",
                placeholder: "",

                onChange: (e) => {
                  if (!this.state.show_skills) {
                    this.setState({
                      show_skills: true,
                    });
                  }
                  if (!e.target.value.replace(/\s/g, "").length) {
                    this.setState({
                      show_skills: false,
                    });
                  }
                },
              }}
            /> */}
            {/* </InputGroup> */}
          </Col>

          <Col lg="6" sm="12" md="12" xs="12" style={styles.mb_10}>
            <h5 style={{ ...styles.h5, paddingTop: 6, paddingBottom: 6 }}>
              Skills:
            </h5>
            {/* <InputGroup style={{ ...styles.inputGroup, marginTop: 0 }}>
              <InputGroupAddon addonType="prepend">
                <span className="input-group-text">Skills</span>
              </InputGroupAddon> */}
            {/* <TagsInput
              style={styles.tagInput}
              value={this.state.skills}
              onChange={(tags) => {
                if (this.state.show_skills) {
                  this.setState({
                    show_skills: false,
                  });
                }
                this.setState({
                  skills: tags,
                });
              }}
              addOnBlur
              inputProps={{
                placeholder: "",
                onChange: (e) => {
                  if (!this.state.show_skills) {
                    this.setState({
                      show_skills: true,
                    });
                  }
                  if (!e.target.value.replace(/\s/g, "").length) {
                    this.setState({
                      show_skills: false,
                    });
                  }
                },
              }}
            /> */}
            <CreatableSelect
              styles={{ ...this.customStyles }}
              components={this.components}
              inputValue={this.state.value2}
              isClearable={false}
              isMulti
              menuIsOpen={false}
              onBlur={() =>
                this.handleKeyDown({
                  key: 'Tab',
                })
              }
              onChange={this.handleEmailChange}
              onInputChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              placeholder="Add Skills"
              value={this.state.skills}
            />
            {/* </InputGroup> */}
          </Col>
          <Col lg="6" sm="12" md="12" xs="12" style={styles.mb_10}>
            <h5 style={{ ...styles.h5, paddingTop: 6, paddingBottom: 6 }}>
              Locations:
            </h5>
            {/* <InputGroup
              style={{ ...styles.inputGroup, marginTop: 0, width: "100%" }}
            >
              <InputGroupAddon addonType="prepend">
                <span className="input-group-text">Locations</span>
              </InputGroupAddon> */}

            <PlacesAutocomplete
              value={this.state.places}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,

                loading,
              }) => (
                <Select2
                  styles={{ ...this.customStyles }}
                  isMulti
                  isLoading={loading}
                  options={suggestions.map((n) => ({
                    label: n.description,
                    value: n.description,
                  }))}
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'location-search-input',
                  })}
                  onChange={(tags) => {
                    this.setState({
                      multiLocations: tags,
                    });
                  }}
                  value={this.state.multiLocations}
                  onInputChange={(text) =>
                    getInputProps().onChange({ target: { value: text } })
                  }
                />
              )}
            </PlacesAutocomplete>
            {/* </InputGroup> */}
          </Col>

          <Col lg="12" style={styles.col}>
            <Button
              onClick={this.saveInfo}
              className="save_education_butn"
              style={styles.saveEduBtn}
            >
              {this.state.loader ? (
                <BeatLoader
                  css={override}
                  sizeUnit={'px'}
                  size={10}
                  color={'white'}
                  loading={true}
                />
              ) : (
                'Save'
              )}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, profileReducer }) => {
  return {
    profileStatus: profileReducer.profileStatus,
    updator: profileReducer.updator,
    user: authUser.user,
  };
};

export default connect(mapStateToProps, { jobInfo, currentUser })(
  JobInformation,
);
