import React, { useState, memo } from 'react';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { green } from '@material-ui/core/colors';
import { Progress } from 'react-sweet-progress';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOnboardingToggle,
  setTourActivity,
  showTourSection,
} from '../../commonRedux/scan/action';
import { useHistory } from 'react-router';
import 'reactjs-popup/dist/index.css';
import VideoModel from '../videoModel';
import { Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
const COLOR = 'white';
const trailColor = 'rgba(255,255,255,.1)';
function mapStateToProps({ fileScanReducer }) {
  return {
    tourActivity: fileScanReducer.tourActivity,
    toggle: fileScanReducer.showOnboardingToggle,
    VideoLinks: fileScanReducer.videoLinks,
  };
}
export const OnboardingTaskCard = memo(function OnboardingTaskCard() {
  const { tourActivity, toggle, VideoLinks } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const STEPS = [
    {
      name: 'COMPLETE PLATFORM TOUR',
      isActive: tourActivity?.productTour,
    },
    {
      name: 'LINKEDIN PROFILE UPDATE',
      isActive: tourActivity?.linkedinTour,
    },
    // {
    //   name: 'COMPLETE COVER LETTER',
    //   isActive: tourActivity?.editTour,
    // },
    {
      name: 'JOB SEARCH SETUP',
      isActive: tourActivity?.jobSearchTour,
    },
  ];
  const existLength =
    parseInt(tourActivity?.productTour) +
    // parseInt(tourActivity?.videoTour) +
    parseInt(tourActivity?.editTour) +
    // parseInt(tourActivity?.profileTour) +
    parseInt(tourActivity?.jobSearchTour) +
    parseInt(tourActivity?.linkedinTour);
  const totalPercent = (existLength / STEPS.length) * 100;
  const totalLeft = STEPS.length - existLength;
  const TITLE =
    existLength >= STEPS.length ? 'Completed' : `${totalLeft} Task(s) Remain`;

  const themeStyle = {
    symbol: TITLE,
    color: COLOR,
    trailColor,
  };

  function onClick() {
    dispatch(setOnboardingToggle(!toggle));
  }
  window.onclick = function (event) {
    if (event.target.id == 'modal') {
      dispatch(setOnboardingToggle(true));
    } else {
      dispatch(setOnboardingToggle(false));
    }
  };
  function onCloseVideo() {
    setOpen(false);

    dispatch(
      setTourActivity({
        videoTour: 1,
      }),
    );
  }

  function onClickItem(key) {
    onClick();
    if (key === 0) {
      setOpen(true);

      dispatch(
        setTourActivity({
          productTour: 1,
        }),
      );
      return;
    }
    if (key === 1) {
      history.push('/linkedin');
      dispatch(
        setTourActivity({
          linkedinTour: 1,
        }),
      );
      return;
    } else if (key === 2) {
      history.push('/jobmatcher');
      dispatch(
        setTourActivity({
          jobSearchTour: 1,
        }),
      );
      return;
    }
    // else if (key === 3) {
    //   history.push('/jobmatcher');
    //   dispatch(
    //     setTourActivity({
    //       jobSearchTour: 1,
    //     }),
    //   );
    //   return;
    // }
    else {
      dispatch(showTourSection(key));
      return;
    }
  }
  return (
    <>
      <div
        className="onboarding-main"
        id="modal"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={
            window.location.pathname === '/builder' ? 'alignCard1' : 'alignCard'
          }
        >
          <div
            className={`onboarding-card ${
              toggle ? 'onboarding-card-show' : 'onboarding-card-hide'
            }`}
          >
            {/* Card Header */}
            <div className="onboarding-card-header">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span className="onboarding-header-text">
                  Resumás Onboarding
                </span>
                <IconButton
                  style={{
                    padding: 0,
                  }}
                  onClick={onClick}
                >
                  <Close
                    style={{
                      color: 'white',
                      fontSize: '20px',
                    }}
                  />
                </IconButton>
              </div>
              <div className="onboarding-header-progress">
                <Progress
                  theme={{
                    success: themeStyle,
                    active: themeStyle,
                    default: themeStyle,
                  }}
                  symbolClassName="onboarding-header-progress-text"
                  percent={totalPercent}
                  status="success"
                />
              </div>
            </div>

            {/* Card Body */}
            {STEPS?.map((n, key) => {
              return (
                <div
                  onClick={() => onClickItem(key)}
                  key={key}
                  className="onboarding-card-item"
                >
                  <h5 className="onboarding-card-item-text">{n.name}</h5>

                  {n.isActive ? (
                    <RadioButtonChecked style={{ color: green[500] }} />
                  ) : (
                    <RadioButtonUncheckedIcon
                      style={{ color: 'rgba(0,0,0,.1)' }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <VideoModel
        closeAble
        open={isOpen}
        setOpen={onCloseVideo}
        video={VideoLinks?.Onboarding}
      />
    </>
  );
});
