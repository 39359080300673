import React, { memo } from "react";
import { Row, Col } from "reactstrap";
import Image2 from "../../assets/landingPage/image-2.webp";

export default memo(() => {
  return (
    <React.Fragment>
      <section className="section bg-light">
        <div className="container">
          <Row className="justify-content-center">
            <Col className="text-center">
              <div className="section-title mb-60">
                <h4
                  style={{ fontFamily: "Montserrat" }}
                  className="main-title mb-4 "
                >
                  App Features
                </h4>
                <p
                  style={{ fontFamily: "Nunito" }}
                  className="text-muted para-desc mb-0 mx-auto"
                >
                  Your chances of getting an interview depends on your resume making it
                  through Applicant Tracking Systems (ATS). Our platform makes
                  it easier to break through.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center">
            <Col lg={8} md={12}>
              <Row>
                <Col md={6} className="col-12">
                  <div className="features p-1 pt-4 pb-4">
                    <i className="mdi mdi-monitor-cellphone-star icon text-center rounded-pill text-primary mr-3 mt-2 float-left"></i>

                    <div className="content d-block overflow-hidden">
                      <h4 className="title">Optimized Resume</h4>
                      <p className="text-muted para mb-0">
                        Companies use Applicant Tracking Systems (ATS) to select
                        qualified applicants based on keyword matching. Use our
                        platform to review and optimize your resume.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="col-12">
                  <div className="features p-1 pt-4 pb-4">
                    <i className="mdi mdi-cube-outline icon text-center rounded-pill text-primary mr-3 mt-2 float-left"></i>

                    <div className="content d-block overflow-hidden">
                      <h4 className="title">Optimized LinkedIn Profile</h4>
                      <p className="text-muted para mb-0">
                        Let recruiters on LinkedIn easily find you! We provide
                        optimized LinkedIn profile content based on your resume
                        and job descriptions. Edit as needed and update your
                        LinkedIn profile.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="col-12">
                  <div className="features p-1 pt-4 pb-4">
                    <i className="mdi mdi-cryengine icon text-center rounded-pill text-primary mr-3 mt-2 float-left"></i>

                    <div className="content d-block overflow-hidden">
                      <h4 className="title">Resume Builder</h4>
                      <p className="text-muted para mb-0">
                        Don't have a resume? Use our easy-to-use resume builder
                        to create an Applicant Tracking System (ATS)
                        compliant resume.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="col-12">
                  <div className="features p-1 pt-4 pb-4">
                    <i className="mdi mdi-check-decagram icon text-center rounded-pill text-primary mr-3 mt-2 float-left"></i>

                    <div className="content d-block overflow-hidden">
                      <h4 className="title">Cover Letter</h4>
                      <p className="text-muted para mb-0">
                        Use our professional cover letter template to create a
                        message that impresses employers. Create letters in
                        minutes.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="col-12">
                  <div className="features p-1 pt-4 pb-4">
                    <i className="mdi mdi-responsive icon text-center rounded-pill text-primary mr-3 mt-2 float-left"></i>

                    <div className="content d-block overflow-hidden">
                      <h4 className="title">Job Finder</h4>
                      <p className="text-muted para mb-0">
                        Search local jobs based on your criteria,
                        your qualifications, and your profile. Integrated with
                        Zip Recruiter &#174;.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="col-12">
                  <div className="features p-1 pt-4 pb-4">
                    <i className="mdi mdi-cards-heart icon text-center rounded-pill text-primary mr-3 mt-2 float-left"></i>
                    <div className="content d-block overflow-hidden">
                      <h4 className="title">Mobile App - Android & IOS </h4>
                      <p className="text-muted para mb-0">
                        Full-featured mobile app where essential 
                        feautres can be done via mobile app. 
                        Access the mobile app from anywhere with cell service.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={12} className="col-12 pt-2 pt-sm-0">
              <img src={Image2} className="img-fluid mx-auto d-block" alt="" />
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
});
