import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';

export default function Accordian(props) {
  return (
    <Accordion
      expanded={props?.expanded} onChange={props?.onChange && props?.onChange()}
      // defaultExpanded={props.defaultExpanded}
      style={{ marginTop: 20, padding: 15 }}
    >
      <AccordionSummary
        style={{ padding: '10px 0px 0px 0px' }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{props.summary}</Typography>
      </AccordionSummary>
      {props.children}
    </Accordion>
  );
}
