export const sampleData = {
  achievements: {
    heading: 'Achievements',
    index: 8,
    items: [
      {
        Description: '',
      },
    ],
    visible: false,
  },
  associations: {
    heading: 'Associations',
    index: 7,
    items: [
      {
        Name: '',
      },
    ],
    visible: false,
  },
  additional_skills: {
    heading: 'Additional Skills',
    index: 17,
    items: [
      {
        Name: '',
      },
    ],
    visible: false,
  },
  certifications: {
    visible: false,
    heading: 'Certifications',
    index: 5,
    items: [
      {
        date: '',
        summary: '',
        title: '',
      },
    ],
  },
  courses: {
    heading: 'Courses',
    index: 6,
    items: [
      {
        course: '',
        endDate: '',
        institution: '',
        startDate: '',
      },
    ],
    visible: false,
  },
  education: {
    enable: true,
    heading: 'Education',
    index: 2,
    items: [
      {
        degree: '',
        enabled:'',
        institution: '',
        location:'',
        major: '',
        minor:'',
        month: '',
        summary:'',
      },
    ],
    visible: true,
  },
  military: {
    heading: 'Military',
    index: 14,
    items: [
      {
        RankAchieved: {
          CurrentOrEndRank: '',
        },
        DatesOfService: {
          StartDate: {
            YearMonth: '',
          },
          EndDate: {
            YearMonth: '',
          },
        },
        branch: '',
      },
    ],
    visible: false,
  },
  objective: {
    body: '',
    enable: true,
    heading: 'Summary',
    index: 4,
    visible: true,
  },
  custom: {
    body: '',
    enable: false,
    heading: 'Custom',
    title: '',
    index: 15,
    visible: false,
  },
  patents: {
    heading: 'Patents',
    index: 10,
    items: [
      {
        Description: '',
        Inventors: {
          InventorName: '',
        },
        PatentTitle: '',
      },
    ],
    visible: false,
  },
  profile: {
    address: {
      city: '',
      state: '',
      zipcode: '',
    },
    email: '',
    firstName: '',
    lastName: '',
    heading: 'Profile',
    jobTitle:"",
    phone: '',
    linkedinURL: '',
    photo:'',
    website:'',
    visible: true,
    index: 0,
  },
  projects: {
    heading: 'Projects',
    index: 9,
    items: [
      {
        Title: '',
        Company: '',
        Duration: '',
        TechnologiesUsed: '',
        Summary: '',
      },
    ],
    visible: false,
  },
  publications: {
    heading: 'Publications',
    index: 11,
    items: [
      {
        Title: '',
        JournalOrSerialName: '',
        Issue: '',
      },
    ],
    visible: false,
  },
  security_creds: {
    heading: 'Security Credentials',
    index: 13,
    items: [
      {
        EffectiveDate: {
          FirstIssuedDate: {
            Year: '',
          },
        },
        Name: '',
      },
    ],
    visible: false,
  },
  skills: {
    enable: true,
    heading: 'Skills',
    index: 3,
    items: [],
    visible: true,
  },
  additional_skills: {
    heading: "Additional Skills",
    index: 16,
    items: [{Name:''}],
    visible: false
  },
  speaking_engagements: {
    heading: 'Speaking Engagements',
    index: 12,
    items: [
      {
        Description: '',
        EventName: '',
        EventType: '',
      },
    ],
    visible: false,
  },
  training: {
    heading: 'Trainings',
    index: 7,
    items: [
      {
        Description: '',
        EndDate: {
          YearMonth: '',
        },
        StartDate: {
          YearMonth: '',
        },
        TrainingName: '',
      },
    ],
    visible: false,
  },
  work: {
    enable: true,
    heading: 'Work Experience',
    index: 1,
    items: [
      {
        company: '',
        enabled: true,
        endDate: '',
        location: '',
        position: '',
        startDate: '',
        summary: '',
      },
    ],
    visible: true,
  },
};
