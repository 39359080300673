export const styles = {
  background: {
    backgroundColor: "white",
  },
  img: {
    // width: 400,
    // height: 200,
  },
  pb_30: {
    paddingBottom: 30,
  },
  pricing: {
    // marginTop: 82,
    // marginBottom: 82,
  },
  discount: {
    marginTop: 100,
    marginBottom: 100,
  },
  mt_30: {
    marginTop: "32px",
  },
  minWidth: {
    minWidth: "190px",
  },
  marginTopAuto: {
    marginTop: "auto",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    margin: "120px 0px -35px 0px",
  },
  priceContainer: {
    maxWidth: '1400px',
    margin: 'auto',
    width: '95%',
  },
  priceCardContainer: {
    textAlign: 'center',
    background: '#f8f9fa',
    padding: "2rem 1.5rem 1.5rem",
    display: "flex",
    flexDirection: "column",
    height: '720px',
    maxWidth: '280px',
    minWidth: '270px',
    borderRadius: '3rem',
    borderBottom: '3px solid #adb5bd',
    position: 'relative',
  },
  iconSpan: (width) => {
    return { width: width < 1200 && width > 990 ? "60px" : '80px' }
  },
  proCard: (width) => {
    return {
      zIndex: "2",
      overflow: "hidden",
      background: 'white',
      height: width < 857 ? '720px' : '750px',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'
    }
  },
  titleFont: {
    fontSize: "45px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textAlign: "center",
  },
  detailsMainDiv: (width) => {
    return {
      display: "flex",
      flexDirection: width < 912 ? "column" : "row",
      justifyContent: 'center',
      alignItems: "center",
      margin: '40px auto 0',
      minHeight: '350px',
      width: "100%",
      padding: width < 912 ? '0' : width < 1200 ? '0 50px' : '0 100px',
    }
  },
  detailsDiv: (width) => {
    return {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      width: width < 912 ? '90%' : "50%",
    }
  },
  frameMain: (width) => {
    return {
      marginTop: width < 912 ? "50px" : "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: width < 912 ? '90%' : "50%",
    }
  },
  subHeading: {
    marginBottom: "8px",
    lineHeight: 1,
    fontWeight: "bold",
    fontSize: "22px",
    fontFamily: "Montserrat",
  },
};
