import { useState } from "react";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { sendSupportMessage } from "../../profile/redux/action";
import { validateEmail } from "../../../utils/emailValidation"

export function useFooter() {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const cb = (v) => {
        if (v === "OK") {
            setMessage("");
            setName("");
            setEmail("");
        }
        setLoading(false);
    };
    const sendMessage = () => {
        try {
            setLoading(true);
            var files = new FormData();
            const body = `<b>Message:<b/> ${message}`;
            files.append(
                'data',
                JSON.stringify({
                  name: name + " ",
                  subject: '',
                  email: email,
                  message: body,
                  from: 'Web',
                }),
              );
            if(validateEmail(email)){
                dispatch(sendSupportMessage(cb, files));
            }
            else{
                toastr.error("Please enter a valid email");
                setLoading(false);
            }
        } catch (error) {
            toastr(error.response);
        }
    }

    return {
        name,
        setName,
        email,
        setEmail,
        message,
        setMessage,
        sendMessage,
        loading,
    };
}