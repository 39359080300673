import { colors } from "../../commonComponents/colors";

export const styles = {
  flex: {
    display: 'flex',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  inline: {
    display: 'inline',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  relative: {
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
  },
  left50: { left: '50%' },
  width_100_percent: {
    width: '100%',
  },
  width_80_percent: {
    width: '80%',
  },
  fontWeight_400: {
    fontWeight: 400,
  },
  fontWeight_500: {
    fontWeight: 500,
  },
  fontWeight_bold: {
    fontWeight: 'bold',
  },
  fontSize_75_rem: {
    fontSize: '.75rem',
  },
  mt_5: {
    marginTop: 5,
  },
  mt_10: {
    marginTop: 10,
  },
  mt_20: {
    marginTop: 20,
  },
  mt_30: { marginTop: 30 },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_40: {
    marginBottom: 40,
  },
  mb_90: {
    marginBottom: 90,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  row: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  h3: {
    color: 'black',
    fontSize: '19px',
  },
  textArea: {
    width: '100%',
    height: '460px',
    padding: '10px',
    border: '1px solid #A9A9A9',
    fontSize: 13,
    color: 'black',
  },
  mdDelete: {
    position: 'absolute',
    right: '30px',
    top: '-36px',
  },
  loader: {
    fontSize: '1.3em',
    color: '#00BFFF',
    position: 'absolute',
    right: '57px',
    top: '-39px',
  },
  actionIcon: {
    fontSize: '1.3em',
    color: 'grey',
    position: 'absolute',
    right: '52px',
    top: '-36px',
  },
  col: {
    minWidth: '250px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  colSpan: {
    fontWeight: '600',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  btn: {
    color: 'white',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#4267B2',
    borderRadius: '0',
  },
  warningColor: {
    backgroundColor: '#FFCC00',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 40,
    color: 'white',
  },
  dangerColor: {
    backgroundColor: '#F45B40',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 40,
    color: 'white',
  },
  matchRate_h1: {
    fontSize: '1.6rem',
    fontWeight: 400,
  },
  matchRate_p: {
    width: '350px',
    fontSize: '.7rem',
  },
  matchRate_progress: {
    height: '5px',
  },
  colorGrey: {
    color: 'grey',
  },
  colorBlack: {
    color: 'black',
  },
  tableSpan: {
    display: 'flex',
    flexDirection: 'row',

    alignItems: 'center',
    justifyContent: 'center',
  },
  actionIcon_Edit: {
    cursor: 'pointer',
    fontSize: '1.1rem',
  },
  scrollBar_textArea: {
    width: '100%',
    height: '460px',
    padding: '10px',
    resize: 'none',
    overflow: 'auto',
    border: '1px solid #A9A9A9',
    fontSize: 13,
    color: 'black',
  },
  tableLoader: {
    marginRight: 3,
    marginLeft: 3,
  },
};

export const style = () => ({
  textFont: {
    fontSize: 14,
  },
  mostRecentDate: {
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 550,
  },
  questions: {
    fontWeight: 550,
    fontSize: 14,
  },
  links: {
    color: '#303030',
    fontSize: '0.74rem',
    fontWeight: 550,
  },
  modal: {
    position: 'absolute',
    alignSelf: 'center',
    width: 600,
    // backgroundColor: "white",
    borderRadius: 10,
    top: '20%',
    left: '27%',
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  closeBtn: {
    position: 'absolute',
    right: 2,
    top: 2,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    color: colors.red,
  },
});
