import React, { memo, useState, useEffect } from 'react';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx } from '../../commonComponents/customBootstrap';
import ModalRescan from '../save&rescan-modal';
import AutoCompleteField from './autoCompleteField';
import Megaphone from '../../assets/megaphone.webp';
import Arrow from '../../assets/arrow.webp';
import { styles } from './style';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSelector, useDispatch } from 'react-redux';
import { GetStepImage } from '../../utils/GetStepImage';
import { Check, Close } from '@material-ui/icons';
import { updateJobTitle } from '../../commonRedux/scan/action';
import { useWindowWidth } from '@react-hook/window-size';
import { colors } from '../../commonComponents/colors';
import CancelIcon from '@material-ui/icons/Cancel';

const ATSfindings = memo((props) => {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const [defaultJobTitle, setDefaultJobTitle] = useState('N/A');
  const [jobTitleDialgue, setJobTitleDialgue] = useState(false);
  const { jobTitles } = useSelector((state) => ({
    jobTitles: state.fileScanReducer?.similar_job_title?.titles,
    jobTitleMatch: state.fileScanReducer,
  }));
  const jobTitlePrompt = useSelector(
    (state) => state.fileScanReducer.jobTitlePrompt,
  );

  let headingStyle = {};

  let CardStyles = {};
  if (props.showOnlyMissing) {
    headingStyle = { fontSize: 16 };
    CardStyles = {
      paddingTop: 15,
      paddingBottom: 15,
    };
  }

  useEffect(() => {
    if (props.fromState === 'yes') {
      if (props.ats?.ats_data?.job_title_matched?.requires) {
        setDefaultJobTitle(props.ats?.ats_data?.job_title_matched?.requires);
      } else if (jobTitlePrompt) {
        setJobTitleDialgue(true);
      }
    }
  }, []);

  return (
    <>
      {props.comingFrom !== 'review' ? (
        <Row>
          <Colxx xxs="12" sm="12" lg="12">
            <Card className="mb-2">
              <CardBody
                style={{
                  ...styles.atsCardBody,
                  background: '#EDF0F7',
                  border: '1px solid lightgray',
                  ...CardStyles,
                  ...(width < 500 && { paddingLeft: 5 }),
                }}
              >
                <GetStepImage step={6} />
                <CardTitle
                  style={{
                    maxWidth: width < 500 ? '220px' : '400px',
                    ...styles.fontWeight_heading,
                    ...headingStyle,
                    marginBottom: 7,
                  }}
                >
                  JOB TITLE MATCH RESULTS
                  <div style={{ position: 'absolute', right: 15, top: 20 }}>
                    {props.fromState === 'yes' ? null : (
                      <ModalRescan
                        goBuilder={props.goBuilder}
                        percent={props.totalScore}
                        skills={props.skills}
                        description={{
                          resume: props.resume,
                          job: props.job,
                          is_parsed: props.is_parsed,
                        }}
                        oid={props.oid}
                        resumeId={props.resumeId}
                        company={props.company}
                        jobTitle={props.jobTitle}
                      />
                    )}
                  </div>
                </CardTitle>
                <div>
                  <p
                    style={{
                      ...styles.colorGrey,
                      fontWeight: 'bold',
                    }}
                  >
                    <div>
                      Applying for (Job Title):{' '}
                      <p
                        style={{
                          display: 'inline',
                          textDecoration: 'underline',
                          textDecorationColor: colors.red,
                        }}
                      >
                        {defaultJobTitle}
                      </p>
                    </div>

                    {props.ats?.ats_data?.job_title_matched?.isMatched ? (
                      <p
                        style={{
                          ...styles.colorGrey,
                          fontWeight: 'bold',
                          display: 'flex',
                          // alignItems: 'center',
                          justifyContent: 'flex-end',
                          paddingRight: '10px',
                          marginTop: 5,
                        }}
                      >
                        <Check
                          style={{
                            fontSize: '18px',
                            color: colors.green,
                            // marginLeft: '-18px',
                            // alignSelf: 'flex-end',
                          }}
                        />
                        This job title is already in your resume.
                      </p>
                    ) : (
                      <p
                        style={{
                          ...styles.colorGrey,
                          fontWeight: 'bold',
                          display: 'flex',
                          // alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginTop: 5,
                          paddingRight: '10px',
                        }}
                      >
                        <Close
                          style={{
                            fontSize: '18px',
                            color: colors.red,
                            // marginLeft: '-18px',
                            // alignSelf: 'flex-end',
                          }}
                        />
                        This job title is missing in your resume.
                      </p>
                    )}
                  </p>
                </div>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  {props.comingFrom !== 'review' && (
                    <Card
                      style={{
                        width: '100%',
                        marginBottom: 20,
                        marginTop: 20,
                        borderRadius: 10,
                        backgroundColor: 'white',
                        borderWidth: 0,
                      }}
                    >
                      <CardBody style={{ padding: 8 }}>
                        <div
                          style={{
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div>
                            <img
                              alt=""
                              src={Megaphone}
                              style={{
                                width: 40,
                                height: 40,
                                marginRight: 20,
                                color: '#576baa',
                                backgroundColor: '"#576baa"',
                              }}
                            />
                          </div>
                          <div>
                            <p
                              style={{
                                marginTop: 8,
                                color: '#576baa',
                                fontSize: 12,
                                fontWeight: 'bold',
                              }}
                            >
                              If you performed similar duties under different
                              job title, please select that Job Title from list
                              below, to append.
                            </p>
                          </div>
                        </div>
                        <img
                          alt=""
                          src={Arrow}
                          style={{
                            position: 'absolute',
                            bottom: -30,
                            left: 80,
                            width: 40,
                            height: 50,
                            zIndex: 99,
                            marginRight: 20,
                            color: '#576baa',
                            backgroundColor: '"#576baa"',
                          }}
                        />
                      </CardBody>
                    </Card>
                  )}
                  {props.comingFrom !== 'review' && (
                    <AutoCompleteField
                      jobTitles={jobTitles}
                      oid={props.oid}
                      defaultJobTitle={defaultJobTitle}
                      isMatched={
                        props.ats?.ats_data?.job_title_matched?.isMatched
                      }
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      ) : (
        ''
      )}
      <Dialog
        open={jobTitleDialgue}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setJobTitleDialgue(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            Job Title
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => {
              setDefaultJobTitle(defaultJobTitle);
              dispatch({
                type: 'USER_CANCEL_JOB_TITLE_MATCH',
                // payload: defaultJobTitle,
              });
              dispatch({
                type: 'SET_JOB_REPLACE_FALSE',
                // payload: defaultJobTitle,
              });
              setJobTitleDialgue(false);
            }}
          />
        </div>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DescriptionIcon style={{ color: '#4267B2' }} />
              </InputAdornment>
            ),
          }}
          style={{ marginTop: 10, width: 500, padding: 20 }}
          id="filled-helperText"
          // label="Resume Name"
          placeholder={'Enter Job Title'}
          onChange={(e) => setDefaultJobTitle(e.target.value)}
          //variant="outlined"
        />
        <DialogActions>
          <Button
            onClick={() => {
              setDefaultJobTitle(defaultJobTitle);
              dispatch(
                updateJobTitle({
                  jobTitle: defaultJobTitle,
                  oid: props.oid,
                }),
              );
              setJobTitleDialgue(false);
            }}
            color="#4267B2"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default ATSfindings;
