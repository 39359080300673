import axios from 'axios';
import toastr from 'toastr';
import url from '../../config/endpoint';
// import cheerio from "cheerio";
import { sampleData } from '../../constants/sampleData';
import { skillMatcher } from '../../utils/skillsMatcher';
import { scoreCalculator, matchJobTitle } from '../../utils/scoreCalculator';
import { addAdditionalSkills } from '../../utils';
import { UPGRADE_PLAN_MODAl } from '../../modules/auth/redux/constants';

export const ZIPRECRUITER_REQUEST = 'ZIPRECRUITER_REQUEST';
export const ZIPRECRUITER_REQUEST_FAILED = 'ZIPRECRUITER_REQUEST_FAILED';
export const FETCHED_ZIPRECRUITER = 'FETCHED_ZIPRECRUITER';

export const zipRecruiterAction = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: ZIPRECRUITER_REQUEST,
    // });
    // axios.get(`${url}`).then((response) => {
    //   if (response.status === 200) {
    //     const html = response.data;
    //     // const $ = cheerio.load(html);
    //     let description = [];
    //     $(".pc_chat_window").each(function (i, elem) {
    //       description[i] = {
    //         jobdescription: $(this)
    //           .find(".jobDescriptionSection")
    //           .text()
    //           .trim(),
    //       };
    //     });
    //     dispatch({
    //       type: FETCHED_ZIPRECRUITER,
    //       payload: description,
    //     });
    //   } else {
    //     dispatch({
    //       type: ZIPRECRUITER_REQUEST_FAILED,
    //     });
    //   }
    // });
  } catch (err) {
    dispatch({
      type: ZIPRECRUITER_REQUEST_FAILED,
    });
  }
};

export const zipRecruiterActionFromJobs = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: ZIPRECRUITER_REQUEST,
    // });
    // axios.get(`${url}`).then((response) => {
    //   if (response.status === 200) {
    //     const html = response.data;
    //     // const $ = cheerio.load(html);
    //     let description = [];
    //     $(".wrapper").each(function (i, elem) {
    //       description[i] = {
    //         jobdescription: $(this).find("#job_desc").text().trim(),
    //       };
    //     });
    //     dispatch({
    //       type: FETCHED_ZIPRECRUITER,
    //       payload: description,
    //     });
    //   } else {
    //     dispatch({
    //       type: ZIPRECRUITER_REQUEST_FAILED,
    //     });
    //   }
    // });
  } catch (err) {
    dispatch({
      type: ZIPRECRUITER_REQUEST_FAILED,
    });
  }
};

export const saveScanResult = (data) => async (dispatch) => {
  try {
    dispatch({
      type: 'SAVED_RESUME',
      payload: data.resume,
    });
    dispatch({
      type: 'SCAN_RESULT',
      payload: data.data,
    });
  } catch (error) {
    // dispatch({ type: 'SCAN_RESULT_STATUS' });
    toastr.error(`error`);
  }
};

export const getDefaultFiles = () => async (dispatch) => {
  try {
    let res = await axios.get(`${url}/admin/get-resume-doc`);

    if (res.status === 200) {
      dispatch({
        type: 'GET_DEFAULT_FILES',
        payload: res.data.data,
      });
    }
  } catch (error) {
    toastr.error(`error`);
  }
};

export const getNotification = (id) => async (dispatch) => {
  try {
    const data = {
      oid: id,
    };
    let res = await axios.post(`${url}/getUserNotification`, data);
    // let res1 = await axios.get(`${url}/getNotification`);

    if (res.status === 200) {
      dispatch({
        type: 'NOTIFICATION',
        payload: res?.data?.data,
      });
    }
  } catch (e) {
    toastr.error(`error`);
  }
};
export const updateUserNotification = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/updateUserNotification`, data);
    if (res.status === 200) {
      dispatch({
        type: 'UPDATE_NOTIFICATION',
        payload: res.data,
      });
    }
  } catch (e) {
    toastr.error(`error`);
  }
};
export const UpdateResuma =
  (data, successFun, ErrorFun) => async (dispatch, getState) => {
    try {
      let newData = {
        ...data,
        userId: getState().authUser.user._id,
      };
      const res = await axios.post(`${url}/update-resuma`, newData);

      if (res.data.status === 200) {
        // toastr.success(`Successfully Updated!`);
        successFun(res.data);
      } else {
        toastr.error(
          `${
            res.data.message ||
            'An error has been occured: Error in updating resume'
          }`,
        );

        ErrorFun(res.data);
      }
    } catch (error) {
      ErrorFun(error);

      toastr.error(`${error}`);
    }
  };

export const AddScanResuma =
  (data, successFun, ErrorFun) => async (dispatch, getState) => {
    try {
      let newData = {
        ...data,
        userId: getState().authUser.user._id,
      };
      const res = await axios.post(`${url}/add-resuma`, newData);

      if (res.data.status === 200) {
        toastr.success(`Successfully Saved!`);

        dispatch({
          type: 'RESUME_EXIST',
          payload: true,
        });
        successFun(res.data);
      } else {
        toastr.error(`An error has been occured: Error in adding resume`);

        ErrorFun(res.data);
      }
    } catch (error) {
      ErrorFun(error);
      toastr.error(`${error}`);
    }
  };

export const UpdateScanResuma = async (data, successFun, ErrorFun) => {
  try {
    const res = await axios.post(`${url}/update-scan-resuma`, data);
    if (res.data.status === 200) {
      toastr.success(`Successfully Saved!`);

      successFun(res.data);
    } else {
      toastr.error(
        `${
          res.data.message ||
          'An error has been occured: Error in updating resume'
        }`,
      );
      ErrorFun(res.data);
    }
  } catch (error) {
    ErrorFun(error);

    toastr.error(`${error}`);
  }
};

export const updateMasterResuma = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/set-user-resuma-id`, data);

    if (res.data.status !== 200) {
      toastr.error(`Something wrong`);
    } else {
      if (!data.type) {
        dispatch({
          type: 'SAVED_USER_RESUMA_ID',
          payload: data.oid,
        });
      } else if (data?.type === 'cover') {
        dispatch({
          type: 'SAVED_USER_COVER_ID',
          payload: data.oid,
        });
      }

      toastr.success(`Successfully default select!`);
    }
  } catch (error) {
    // dispatch({ type: 'SCAN_RESULT_STATUS' })
    toastr.error(`${error}`);
  }
};

export const getResumaId = () => async (dispatch) => {
  try {
    let getToken = localStorage.getItem('clientAccessToken');

    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }
    const res = await axios.get(`${url}/get-resuma-id`);

    if (res.data.status !== 200) {
    } else {
      // localStorage.setItem('masterTemplate', res.data.resume_id);
      dispatch({
        type: 'SAVED_USER_RESUMA_ID',
        payload: res.data.resume_id,
      });
      dispatch({
        type: 'SAVED_USER_COVER_ID',
        payload: res.data.cover_id,
      });
    }
  } catch (error) {}
};

export const getResumaResults =
  (page, source) => async (dispatch, getState) => {
    const perPage = source === 'scan' ? 100 : 5;
    try {
      dispatch({ type: 'GET_RESUMES_STARTED' });
      let getToken = localStorage.getItem('clientAccessToken');
      if (getToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
      }
      const res = await axios.get(
        `${url}/get-user-resuma?filters=oid,resume.oid,data.profile.jobTitle,jobTitle,note,company,createdAt,jobDescription,reviewScore,builderResume,resumeName,resumeText&page=${
          page || 1
        }&per_page=${perPage}&userId=${getState().authUser.user._id}`,
      );
      if (res.data.status === 200) {
        const data = res.data.data.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        if (data.length === 0) {
          dispatch({
            type: 'SHOW_LINKEDIN_DIALOG',
          });
        }
        let obj = {
          data: data,
          page: res.data.page,
          page_total: res.data.page_total,
        };
        dispatch({
          type: 'SAVED_USER_RESUME',
          payload: obj,
        });
      } else {
        dispatch({ type: 'GET_RESUMES_FAILED' });
      }
    } catch (error) {
      dispatch({ type: 'GET_RESUMES_FAILED' });
    }
  };

const parseTextWithFileReader = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result || '');
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsText(file);
  });
};

export const parseTextFile =
  ({ file, fileType = 'job', callback }) =>
  async (dispatch) => {
    try {
      const text = await parseTextWithFileReader(file);

      if (fileType === 'resume') {
        dispatch({
          type: 'PARSE_FILE',
          payload: text,
        });
      }

      callback && callback(text);
    } catch (error) {
      toastr.error('An error occur during parsing Try again');
      dispatch({
        type: 'FILE_PARSE_ERROR',
      });
    }
  };

export const fileParser =
  (file, saveJobDescription = null, firstName = '', userId = '') =>
  async (dispatch) => {
    const files = new FormData();
    files.append('file', file);
    files.append('data', JSON.stringify({ firstName, userId }));

    try {
      const res = await axios.post(`${url}/upload-resume`, files);

      if (!res.data.response) {
        toastr.error('An error occur during parsing Try again');
        dispatch({
          type: 'FILE_PARSE_ERROR',
        });
      }

      if (res.data.message === 'File not sent!') {
        toastr.error('An error occur during parsing Try again');
        dispatch({
          type: 'FILE_PARSE_ERROR',
        });
      } else if (res.data.message === 'No file selected!') {
        toastr.error('An error occur during parsing Try again');
        dispatch({
          type: 'FILE_PARSE_ERROR',
        });
      } else if (res.data.message === 'File must be in pdf or docx format!') {
        toastr.error('An error occur during parsing Try again');
        dispatch({
          type: 'FILE_PARSE_ERROR',
        });
      } else if (res.data.message === 'err') {
        toastr.error('An error occur during parsing Try again');
        dispatch({
          type: 'FILE_PARSE_ERROR',
        });
      } else {
        if (saveJobDescription) {
          saveJobDescription(res.data.response);
        } else {
          dispatch({
            type: 'PARSE_FILE',
            payload: res.data.response,
          });
        }
      }
    } catch (error) {
      toastr.error(`error`);
      dispatch({
        type: 'FILE_PARSE_ERROR',
      });
    }
  };
export const getSimilarJobTitle = (data, oid) => async (dispatch) => {
  let newData = {
    ...data,

    oid: oid,
  };

  try {
    let res = await axios.post(`${url}/get-similar-scan`, newData);
    dispatch({
      type: 'SIMILAR_JOB_TITLE',
      payload: { titles: Object.keys(res.data.data) },
    });
  } catch (error) {}
};
export const updateScanScore = async (data) => {
  try {
    const res = await axios.post(`${url}/update-scan-score`, data);
    if (res) {
    }
  } catch (error) {
    // updateScanScore(data);
  }
};
export const updateSkills = async (data) => {
  try {
    const res = await axios.post(`${url}/update-skills`, data);
    if (res.data.response === 'success') {
      // return;
    } else if (res.data.message === 'err') {
      // return;
    }
  } catch (error) {}
};

export const scanResults = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'SCAN_RESULT_START',
    });

    dispatch({
      type: 'CONTENT_LOADERS',
      payload: {
        readabilty: true,
        score: true,
        skills: true,
      },
    });

    const res = await axios.post(`${url}/scan-cv-description`, data);

    if (!res.data.response) {
      dispatch({ type: 'SCAN_RESULT_STATUS' });
      toastr.error(res['data']['message']);
    } else if (res.data.message === 'err') {
      dispatch({ type: 'SCAN_RESULT_STATUS' });
      toastr.error(res.data.message);
    } else {
      const skills = skillMatcher(
        res.data.resume.data.skills.items,
        res.data.job_parsed.skills,
        res.data.soft_skills.skills,
        res.data.response.skillsData,
        res.data.resume.data?.additional_skills?.items || [],
        res.data.job_parsed.softSkills,
      );
      localStorage.setItem('masterTemplate', res.data?.resume?.oid);
      var newResponse = res.data.response;
      newResponse['skillsData'] = skills;
      const userDefinedJobTitle =
        getState().fileScanReducer.userDefinedJobTitle;

      const { total_value, score_skills, ats_data } = scoreCalculator(
        { ...res.data, userDefinedJobTitle },
        res.data.counts_managements,
      );
      if (!ats_data?.job_title_matched?.isMatched) {
        dispatch(
          getSimilarJobTitle(
            { title: res.data.job_parsed['MainJobTitle'] },
            res?.data?.response?.oid,
          ),
        );
      }
      newResponse['ats']['ats_data'] = ats_data;
      newResponse['total_score'] = total_value;
      newResponse['scores']['skills'] = score_skills;
      updateScanScore({
        total_score: newResponse['total_score'],
        oid: res.data.response.oid,
        initialScore: true,
      });
      // updateSkills({
      //   skills: skills,
      //   oid: res.data.response.oid,
      //   skillsItem: res.data.resume.data.skills.items,
      // });
      let templates = [];
      const resume = res?.data?.resume;
      const editorKeys = Object.keys(resume || {}).filter((key) =>
        key.endsWith('editorJson'),
      );
      if (editorKeys?.length > 1) {
        templates = editorKeys?.map((key, index) => ({
          id: index + 1,
          template: resume[key],
        }));
      }
      dispatch({
        type: 'SAVE_COUNT_MANAGEMENTS',
        payload: res.data.counts_managements,
      });
      dispatch({
        type: 'SAVED_RESUME',
        payload: res.data.resume,
      });
      dispatch({
        type: 'SAVED_TEMPLATES',
        payload: templates,
      });
      dispatch({
        type: 'CLOUD_IMAGE',
        payload: res.data.styleCloud,
      });
      dispatch({
        type: 'SAVE_JOB_PARSED',
        payload: res.data.job_parsed,
      });

      dispatch({
        type: 'SCAN_RESULT',
        payload: newResponse,
      });
      dispatch({
        type: 'SAVE_SOFT_SKILLS',
        payload: res.data.soft_skills.skills,
      });

      dispatch({
        type: 'SAVE_SOVREN',
        payload: res.data.soft_skills?.sovren_counts,
      });

      dispatch({
        type: 'CONTENT_LOADERS',
        payload: {
          readabilty: false,
          score: false,
          skills: false,
        },
      });
    }
  } catch (error) {
    toastr.error(error.message);

    dispatch({ type: 'SCAN_RESULT_STATUS' });
  }
};

export const openUpgradeModel = () => async (dispatch) => {
  dispatch({
    type: UPGRADE_PLAN_MODAl,
    payload: true,
  });
};
export const mobileEditorJsonUpdate =
  (sfdt, oid, token) => async (dispatch) => {
    try {
      dispatch({
        type: 'SFDT_LOADING',
        payload: true,
      });
      await axios.post(
        `${url}/update-sfdt`,
        {
          sfdt,
          oid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
    } finally {
      dispatch({
        type: 'SFDT_LOADING',
        payload: false,
      });
    }
  };
export const editorJsonUpdate = (sfdt, oid) => async (dispatch) => {
  try {
    dispatch({
      type: 'SFDT_LOADING',
      payload: true,
    });

    const response = await axios.post(`${url}/update-sfdt`, {
      sfdt,
      oid,
    });
    dispatch({
      type: 'SAVE_EDITOR_JSON',
      payload: { editorJson: response?.data?.editorJson },
    });
  } catch (error) {
  } finally {
    dispatch({
      type: 'SFDT_LOADING',
      payload: false,
    });
  }
};
export const updateEditorJson = (data) => (dispatch) => {
  dispatch({
    type: 'UPDATE_EDITOR',
    payload: data,
  });
};

export const updateEditor = (data) => async (dispatch, getState) => {
  try {
    let font = getState().fileScanReducer.resume.fontFamily || [];

    let newData = {
      ...data,
      font: font.length ? font[font?.length - 1] : '',
    };
    const res = await axios.post(`${url}/update-skills`, newData);

    if (res.data.response === 'success') {
      dispatch({
        type: 'UPDATE_EDITOR',
        payload: {
          editorJson: res.data.editorJson,
        },
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({
      type: 'EDITOR_STATUS',
      payload: false,
    });
  }
};
export const updateResumeTextAction = (data, cb) => async (dispatch) => {
  try {
    if (!cb) {
      dispatch({
        type: 'UPDATE_RESUME_TEXT_START',
      });
    }
    const res = await axios.post(`${url}/update-resume-text`, data);
    if (res.data.status === 200) {
      if (cb) {
        cb(true);
      } else {
        dispatch({
          type: 'UPDATE_RESUME_TEXT_SUCCESS',
        });
        if (data.resumeText) {
          dispatch({
            type: 'SAVE_RESUME_TEXT',
            payload: data.resumeText,
          });
        }
        toastr.success(res.data.message);
      }
    } else {
      if (cb) {
        cb();
      } else {
        toastr.error(res.data.message);
        dispatch({
          type: 'UPDATE_RESUME_TEXT_FAILED',
        });
      }
    }
  } catch (error) {
    if (cb) {
      cb();
    } else {
      dispatch({
        type: 'UPDATE_RESUME_TEXT_FAILED',
      });
    }
  }
};
export const addSkillsAction =
  (data, key, content, multiSkills = false, callback) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: 'EDITOR_STATUS',
        payload: true,
      });
      callback && callback(true);
      var resume = getState().fileScanReducer.resume;
      var soft_skills = getState().fileScanReducer.softSkills;
      var newResponse = getState().fileScanReducer.scanResult;
      var job_parsed = getState().fileScanReducer.job_parsed;
      const counts_managements = getState().fileScanReducer.count_managements;

      if (!content) {
        dispatch({
          type: 'ADD_SKILLS_ADDITIONAL',
          payload: key,
        });
      }

      if (!content) {
        const newResumeText = addAdditionalSkills(
          newResponse?.resumeText,
          key,
          resume?.other_info?.heading_font_family || newResponse?.fontFamily,
        );
        if (newResumeText) {
          newResponse['resumeText'] = newResumeText;
          dispatch(
            updateResumeTextAction(
              {
                oid: newResponse?.oid,
                resumeText: newResumeText,
              },
              () => null,
            ),
          );
        }
      } else {
        const newResumeText = content;
        if (newResumeText) {
          newResponse['resumeText'] = newResumeText;
          dispatch(
            updateResumeTextAction(
              {
                oid: newResponse?.oid,
                resumeText: newResumeText,
              },
              () => null,
            ),
          );
        }
        resume['data']['additional_skills']['items'].push(...key);
      }

      const { total_value, score_skills, ats_data } = scoreCalculator(
        {
          resume,
          job_parsed: job_parsed,
          soft_skills,
        },
        counts_managements,
      );
      newResponse['ats']['ats_data'] = ats_data;

      newResponse['total_score'] = total_value;
      newResponse['scores']['skills'] = score_skills;

      updateScanScore({
        total_score: newResponse['total_score'],
        oid: data.oid,
      });

      dispatch({
        type: 'SCAN_RESULT',
        payload: newResponse,
      });

      dispatch(
        updateEditor({
          ...data,
          additional_skills: resume?.data?.additional_skills?.items || [],
        }),
      );
      if (!data?.hideToaster) {
        // eslint-disable-next-line
        multiSkills
          ? toastr.success('Skills have been Added')
          : toastr.success('Skill has been Added');
      }
    } catch (error) {
      toastr.error(`error`, error);
      dispatch({
        type: 'EDITOR_STATUS',
        payload: false,
      });
    } finally {
      callback && callback(false);
    }
  };

export const removeSkillsAction =
  (data, key, multiSkills = false, callback) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: 'EDITOR_STATUS',
        payload: true,
      });
      callback && callback(true);
      var resume = getState().fileScanReducer.resume;
      var soft_skills = getState().fileScanReducer.softSkills;
      var newResponse = getState().fileScanReducer.scanResult;
      var job_parsed = getState().fileScanReducer.job_parsed;
      const counts_managements = getState().fileScanReducer.count_managements;
      const newResumeText = addAdditionalSkills(
        newResponse?.resumeText,
        key,
        resume?.other_info?.heading_font_family || newResponse?.fontFamily,
      );
      if (newResumeText) {
        newResponse['resumeText'] = newResumeText;
        dispatch(
          updateResumeTextAction(
            {
              oid: newResponse?.oid,
              resumeText: newResumeText,
            },
            () => null,
          ),
        );
      }
      if (typeof key === 'string') {
        var skills = resume['data']['additional_skills']['items'].filter(
          (n) => n.name.toLowerCase() !== key.toLowerCase(),
        );
        resume['data']['additional_skills']['items'] = skills;
      }
      let additional_skills = null;
      if (typeof key === 'object') {
        additional_skills = [];
        resume['data']['additional_skills']['items'] = additional_skills;
      }
      dispatch({
        type: 'SAVED_RESUME',
        payload: resume,
      });
      const userDefinedJobTitle =
        getState().fileScanReducer.userDefinedJobTitle;
      const { total_value, score_skills, ats_data } = scoreCalculator(
        {
          resume,
          job_parsed: job_parsed,
          soft_skills,
          userDefinedJobTitle,
        },
        counts_managements,
      );
      newResponse['ats']['ats_data'] = ats_data;

      newResponse['total_score'] = total_value;
      newResponse['scores']['skills'] = score_skills;

      dispatch({
        type: 'SCAN_RESULT',
        payload: newResponse,
      });
      // eslint-disable-next-line
      multiSkills
        ? toastr.success('Skills have been removed')
        : toastr.success('Skill has been removed');
      updateScanScore({
        total_score: newResponse['total_score'],
        oid: data.oid,
      });

      dispatch(
        updateEditor({
          ...data,
          skillsItem: skills,
          additional_skills: additional_skills,
        }),
      );
    } catch (error) {
      toastr.error(`error`, error);
      dispatch({
        type: 'EDITOR_STATUS',
        payload: false,
      });
    } finally {
      callback && callback(false);
    }
  };

export const scanHistory = () => async (dispatch) => {
  try {
    dispatch({
      type: 'SCAN_STARTING',
    });
    let getToken = localStorage.getItem('clientAccessToken');
    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }
    const filters =
      'resume.oid,data.jobApplied,jobDescription,data.jobDescription,note,resume.MainJobTitle,data.scores,data.isInterview,data.skillsData,data.ats.resumeSkillsMissing,data.jobApplied,data.note,data.company,data.total_score,data.initialScore,updatedAt,resumeText';
    const res = await axios.get(`${url}/scan-history?filters=${filters}`);
    let dateWise = res.data.scans.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    let skillsData = [];
    skillsData.push(Object.keys(dateWise[0].data.skillsData));

    if (!res.data.scans) {
      dispatch({
        type: 'SCAN_FAILED',
      });
      dispatch({ type: 'HISTORY_STATUS' });
    }

    if (res.data.message === 'err') {
      dispatch({
        type: 'SCAN_FAILED',
      });
      dispatch({ type: 'HISTORY_STATUS' });
    } else {
      dispatch({
        type: 'HISTORY_DATA',
        payload: dateWise,
      });

      dispatch({
        type: 'SKILLS_FROM_LATEST_SCAN',
        payload: Object.keys(dateWise[0].data.skillsData),
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(scanHistory());
    } else {
      dispatch({
        type: 'SCAN_FAILED',
      });
      dispatch({ type: 'HISTORY_STATUS' });
    }
  }
};

export const manageScanHistory = () => async (dispatch) => {
  try {
    dispatch({
      type: 'SCAN_STARTING',
    });
    let getToken = localStorage.getItem('clientAccessToken');
    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }
    const filters =
      'resume.oid,data.jobApplied,jobDescription,data.jobTitle,data.scores,data.isInterview,data.skillsData,data.ats.resumeSkillsMissing,data.jobApplied,data.note,data.company,data.total_score,data.initialScore,updatedAt,resumeText';
    const res = await axios.get(
      `${url}/scan-history?filters=${filters}&isParsed=1`,
    );
    let dateWise = res.data.scans.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    let skillsData = [];
    skillsData.push(Object.keys(dateWise[0].data.skillsData));

    if (!res.data.scans) {
      dispatch({
        type: 'SCAN_FAILED',
      });
      dispatch({ type: 'HISTORY_STATUS' });
    }

    if (res.data.message === 'err') {
      dispatch({
        type: 'SCAN_FAILED',
      });
      dispatch({ type: 'HISTORY_STATUS' });
    } else {
      dispatch({
        type: 'MANAGE_HISTORY_DATA',
        payload: dateWise,
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(scanHistory());
    } else {
      dispatch({
        type: 'SCAN_FAILED',
      });
      dispatch({ type: 'HISTORY_STATUS' });
    }
  }
};

export const deleteHistoryItem = (id) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/delete-scan`, {
      oid: id,
    });

    if (res.data.message === 'err') {
      toastr.error('An error has been occured: Error in deleting history item');
      dispatch({ type: 'HISTORY_DELETE_STATUS' });
    } else if (res.data.response === 'success')
      toastr.success('History item deleted');
    dispatch({
      type: 'DELETE_HISTORY',
      payload: id,
    });
  } catch (error) {
    dispatch({ type: 'HISTORY_DELETE_STATUS' });
    toastr.error(`error`);
  }
};

export const setTourActivity = (data) => async (dispatch, getState) => {
  dispatch({ type: 'SET_TOUR_ACTIVITY', payload: data });

  const tourActivity = {
    ...getState().fileScanReducer.tourActivity,
    ...data,
  };
  try {
    await axios.post(`${url}/update-tour-activity`, { tourActivity });
  } catch (error) {}
};

export const sendMail = () => async (dispatch) => {
  dispatch({
    type: 'MAIL_SENT',
  });
};

export const setOnboardingToggle =
  (data, autoMatic) => async (dispatch, getState) => {
    if (autoMatic) {
      dispatch({
        type: 'SET_ONBOARDING_TOGGLE',
        payload: !getState().fileScanReducer.showOnboardingToggle,
      });
    } else {
      dispatch({ type: 'SET_ONBOARDING_TOGGLE', payload: data });
    }
  };

export const showTourSection = (data) => async (dispatch) => {
  dispatch({ type: 'SHOW_TOUR_SECTION', payload: data });
};

export const scanResultsReload = (data, oid) => async (dispatch, getState) => {
  try {
    if (data === 'not done') {
      dispatch({
        type: 'SCAN_RELOAD_START',
      });

      const res = await axios.get(
        `${url}/get_updated_scan_results?data_id=${oid}`,
      );

      if (res.data.status === 500) {
        dispatch({ type: 'SCAN_RESULT_STATUS' });
        // toastr.error('An error has been occured');
      } else {
        const skills = skillMatcher(
          res.data.resume.data.skills.items,
          res.data.job_parsed.skills,
          res.data.soft_skills.skills,
          res.data.response.skillsData,
          res.data.resume.data?.additional_skills?.items || [],
          res.data.job_parsed.softSkills,
        );
        // alert('oko')

        var newResponse = res.data.response;
        newResponse['skillsData'] = skills;
        const userDefinedJobTitle =
          getState().fileScanReducer.userDefinedJobTitle;
        const { total_value, score_skills, ats_data } = scoreCalculator(
          { ...res.data, userDefinedJobTitle },
          res.data.counts_managements,
        );
        dispatch({
          type: 'SAVE_COUNT_MANAGEMENTS',
          payload: res.data.counts_managements,
        });
        newResponse['ats']['ats_data'] = ats_data;

        newResponse['total_score'] = total_value;
        newResponse['scores']['skills'] = score_skills;

        updateScanScore({
          total_score: newResponse['total_score'],
          oid: res.data.response.oid,
        });
        // updateSkills({
        //   skills: skills,
        //   oid: res.data.response.oid,
        //   skillsItem: res.data.resume.data.skills.items,
        // });
        dispatch({
          type: 'SAVED_RESUME',
          payload: res.data.resume,
        });
        dispatch({
          type: 'SAVE_JOB_PARSED',
          payload: res.data.job_parsed,
        });

        dispatch({
          type: 'SCAN_RESULT',
          payload: newResponse,
        });
        dispatch({
          type: 'SAVE_SOFT_SKILLS',
          payload: res.data.soft_skills.skills,
        });

        dispatch({
          type: 'SAVE_SOVREN',
          payload: res.data.soft_skills?.sovren_counts,
        });
      }
    }
  } catch (error) {
    dispatch({ type: 'SCAN_RESULT_STATUS' });
  }
};

export const clearScanResult = () => {
  return {
    type: 'CLEAR_SCAN_RESULT',
  };
};

export const updateQuestionsAction =
  (data, handleClose) => async (dispatch) => {
    try {
      const res = await axios.post(`${url}/update-scanned`, data);
      if (res.data.status === 200) {
        const res = await axios.get(`${url}/scan-history`);
        if (res.data?.scans?.length) {
          let dateWise = res.data.scans?.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          dispatch({
            type: 'HISTORY_DATA',
            payload: dateWise,
          });
        }
      }
      if (handleClose) {
        handleClose(res.data);
      }
    } catch (err) {
      toastr.error(err);
      if (handleClose) {
        handleClose(err);
      }
    }
  };

export const updateJobTitle = (data) => async (dispatch, getState) => {
  try {
    const newResponse = getState().fileScanReducer.scanResult;
    const resume = getState().fileScanReducer.resume;
    const soft_skills = getState().fileScanReducer.softSkills;
    const job_parsed = getState().fileScanReducer.job_parsed;
    const counts_managements = getState().fileScanReducer.count_managements;
    const newData = {
      ...data,
      resumeId: resume?.oid,
    };
    const res = await axios.post(`${url}/update-job-title-data`, newData);
    if (res.data.status === 200) {
      if (data.jobTitle) {
        let matchResult = matchJobTitle(
          getState().fileScanReducer.resume.data.work.items,
          data.jobTitle,
        );
        job_parsed['MainJobTitle'] = data.jobTitle;
        const { total_value, score_skills, ats_data } = scoreCalculator(
          {
            resume,
            job_parsed: job_parsed,
            soft_skills,
          },
          counts_managements,
        );
        newResponse['ats']['ats_data'] = ats_data;
        newResponse['total_score'] = total_value;
        newResponse['scores']['skills'] = score_skills;
        newResponse['ats']['ats_data']['job_title_matched']['isMatched'] =
          matchResult;
        newResponse['ats']['ats_data']['job_title_matched']['requires'] =
          data.jobTitle;
      }

      if (data.employer) {
        newResponse['ats']['ats_data']['job_level_match']['EmployerNames'] =
          data.employer;
      }

      updateScanScore({
        total_score: newResponse['total_score'],
        oid: data.oid,
      });
      dispatch({
        type: 'SCAN_RESULT',
        payload: newResponse,
      });
      dispatch({
        type: 'SELECTED_TITLE',
        payload: data.jobTitle,
      });
    }
    dispatch({
      type: 'UPDATE_SCAN_JOB_TITLE',
      payload: data.jobTitle,
    });
  } catch (err) {
    toastr.error(err);
  }
};

export const EmailParserAction =
  (data, handleClickOpen) => async (dispatch) => {
    try {
      dispatch({
        type: 'FEDEX_START',
      });
      const res = await axios.post(`${url}/email-parser`, data);
      if (res.data.status === 200) {
        if (handleClickOpen) {
          handleClickOpen(true, res.data.message);
        } else {
          toastr.success(res.data.message);
        }
        dispatch({
          type: 'FEDEX_SUCCESS',
        });
      } else {
        toastr.error(res.data.message);
        dispatch({
          type: 'FEDEX_FAILED',
        });
      }
    } catch (err) {
      toastr.error(err);
      dispatch({
        type: 'FEDEX_FAILED',
      });
    }
  };
export const EmailParserForScanTables =
  (data, handleClickOpen) => async (dispatch) => {
    try {
      dispatch({
        type: 'FEDEX_START_FOR_TABLE',
      });
      const res = await axios.post(`${url}/email-parser`, data);
      if (res.data.status === 200) {
        handleClickOpen(true, res.data.message);
        dispatch({
          type: 'FEDEX_SUCCESS_FOR_TABLE',
        });
      } else {
        toastr.error(res.data.message);
        dispatch({
          type: 'FEDEX_FAILED_FOR_TABLE',
        });
      }
    } catch (err) {
      toastr.error(err);
      dispatch({
        type: 'FEDEX_FAILED_FOR_TABLE',
      });
    }
  };

export const SaveActiveIndex = (data) => async (dispatch) => {
  try {
    localStorage.setItem('activeStep', data);
    dispatch({
      type: 'SAVE_STEP_ACTIVE_INDEX',
      payload: data,
    });
  } catch (err) {}
};
export const deleteActiveIndex = (data) => async (dispatch) => {
  try {
    localStorage.setItem('activeStep', data);
    dispatch({
      type: 'REMOVE_STEP_ACTIVE_INDEX',
      payload: data,
    });
  } catch (err) {}
};
export const deleteResume = (data, cb) => async (dispatch, getState) => {
  try {
    var userResumas = getState().fileScanReducer.userResumas;
    const res = await axios.post(`${url}/delete-resuma`, { oid: data });
    if (res.data.status === 200) {
      if (cb) {
        cb();
      }
      userResumas = userResumas.filter((n) => data !== n.oid);
      dispatch(getResumaId());
      dispatch(getResumaResults());

      toastr.success(res.data.message);
      dispatch({
        type: 'SAVED_USER_RESUME_DATA',
        payload: userResumas,
      });
      // }
    }
  } catch (error) {
    toastr.error(error);
  }
};

export const selectedTemplate = (data) => async (dispatch) => {
  try {
    localStorage.setItem('defaultTemp', data);
    dispatch({
      type: 'SELECTED_TEMPLATE',
      payload: data,
    });
  } catch (error) {}
};

export const addNotesAction = (data, cb) => async () => {
  try {
    const res = await axios.post(`${url}/update-note`, data);

    if (res.data.response === 'success') {
      if (cb) {
        cb();
      }
      toastr.success('Successfully Updated');
    } else {
      toastr.error(res.data.response);
    }
  } catch (error) {
    toastr.error(error);
  }
};

export const updateQuestionsA = (data) => async (dispatch) => {
  try {
    await axios.post(`${url}/update-scanned`, data);
    dispatch(scanResultsReload('not done', data.oid));
  } catch (err) {
    toastr.error(err);
  }
};

export const sendTextToEmailOrMobile = (data, cb) => async (dispatch) => {
  dispatch({
    type: 'SEND_TEXT_TO_START',
  });

  try {
    const res = await axios.post(`${url}/send-sms`, data);

    if (res.data.status === 200) {
      if (cb) {
        cb('OK');
      }

      toastr.success(res.data.message);
      dispatch({
        type: 'SEND_TEXT_TO_SUCCESS',
      });
    } else {
      toastr.error(res.data.message);
      dispatch({
        type: 'SEND_TEXT_TO_FAILED',
      });
    }
  } catch (error) {
    toastr.error(error);
    dispatch({
      type: 'SEND_TEXT_TO_FAILED',
    });
  }
};

export const AnalyzParsedResume = (data, cb) => async (dispatch, getState) => {
  try {
    dispatch({
      type: 'PARSED_ANALYZ_START',
    });
    let res = await axios.post(
      `${url}/get_updated_scan_results_with_new_resume`,
      data,
    );
    if (res.data) {
      const skills = skillMatcher(
        res.data.resume.data.skills.items,
        res.data.job_parsed.skills,
        res.data.soft_skills.skills,
        res.data.response.skillsData,
        res.data.resume.data?.additional_skills?.items || [],
        res.data.job_parsed.softSkills,
      );

      var newResponse = res.data.response;
      newResponse['skillsData'] = skills;
      const userDefinedJobTitle =
        getState().fileScanReducer.userDefinedJobTitle;
      const { total_value, score_skills, ats_data } = scoreCalculator(
        { ...res.data, userDefinedJobTitle },
        res.data.counts_managements,
      );
      if (!ats_data?.job_title_matched?.isMatched)
        dispatch(
          getSimilarJobTitle(
            { title: res.data.job_parsed['MainJobTitle'] },
            res.data.resume.oid,
          ),
        );
      newResponse['ats']['ats_data'] = ats_data;
      newResponse['total_score'] = total_value;
      newResponse['scores']['skills'] = score_skills;
      updateScanScore({
        total_score: newResponse['total_score'],
        oid: res.data.response.oid,
      });
      // updateSkills({
      //   skills: skills,
      //   oid: res.data.response.oid,
      //   skillsItem: res.data.resume.data.skills.items,
      // });
      dispatch({
        type: 'SAVE_COUNT_MANAGEMENTS',
        payload: res.data.counts_managements,
      });
      dispatch({
        type: 'SAVED_RESUME',
        payload: res.data.resume,
      });
      dispatch({
        type: 'SAVE_JOB_PARSED',
        payload: res.data.job_parsed,
      });

      dispatch({
        type: 'SCAN_RESULT',
        payload: newResponse,
      });
      dispatch({
        type: 'SAVE_SOFT_SKILLS',
        payload: res.data.soft_skills.skills,
      });

      dispatch({
        type: 'SAVE_SOVREN',
        payload: res.data.soft_skills?.sovren_counts,
      });

      dispatch({
        type: 'PARSED_ANALYZ_SUCCESS',
      });
      cb();
    } else {
      dispatch({
        type: 'PARSED_ANALYZ_FAILED',
      });
    }
  } catch (error) {
    dispatch({
      type: 'PARSED_ANALYZ_FAILED',
    });
  }
};

export const getAvoidWords = () => async (dispatch) => {
  try {
    let res = await axios.get(`${url}/admin/get-avoid-words`);
    if (res.data.status === 200) {
      dispatch({
        type: 'SAVE_AVOID_WORDS',
        payload: res.data.data,
      });
    } else {
      toastr.error('error');
    }
  } catch (error) {}
};

export const setChooseTemplate = (id) => async (dispatch) => {
  dispatch({
    type: 'DEFAULT_TEMPLATE',
    payload: id,
  });
};

export const getSkillsAction = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/admin/get-soft-skills`);
    if (res.data.status === 200) {
      dispatch({
        type: 'SAVE_SOFT_SKILLS',
        payload: res.data.data,
      });

      dispatch({
        type: 'SAVE_SOVREN',
        payload: res.data?.sovren_counts,
      });
    }
  } catch (error) {}
};

export const downloadResumePdf = (data, cb) => async () => {
  try {
    const res = await axios.post(`${url}/generate-resume-pdf`, data);

    if (cb) {
      cb(res.data);
    }
  } catch (error) {
    if (cb) {
      cb();
    }
  }
};

export const updateResumeFromModel = (data) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_START',
  });
  try {
    const res = await axios.post(`${url}/update-resume-text`, data);
    if (res.data.status === 200) {
      toastr.success(res.data.message);
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
    } else {
      toastr.error(res.data.message);
      dispatch({
        type: 'UPDATE_FAILED',
      });
    }
  } catch (error) {
    dispatch({
      type: 'UPDATE_FAILED',
    });
  }
};

export const resumeStats = (file) => async () => {
  const resumeFile = new FormData();
  resumeFile.append('resumeUpload', file);

  try {
    await axios.post(`${url}/score-resume`, resumeFile);
  } catch (error) {}
};

export const updateFileName = (name, oid) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_EDITOR',
    payload: {
      resumeName: name,
    },
  });
  await axios.post(`${url}/update-note`, {
    oid,
    resumeName: name,
    type: '',
    note: '',
  });
};

export const addSimilarJobTitle =
  (job_title, oid, defaultJobTitle) => async (dispatch, getState) => {
    try {
      dispatch({
        type: 'EDITOR_STATUS',
        payload: true,
      });
      const resume = getState().fileScanReducer.resume;
      const scanResult = getState().fileScanReducer.scanResult;
      const job_parsed = getState().fileScanReducer.job_parsed;
      const softSkills = getState().fileScanReducer.softSkills;
      const count_managements = getState().fileScanReducer.count_managements;

      let responce = await axios.post(`${url}/update-job-title`, {
        oid: oid,
        jobTitle: job_title,
        defaultJobTitle: defaultJobTitle,
      });
      var newResponse = scanResult;

      const work_item = resume.data.work.items.length
        ? resume.data.work.items[0]
        : sampleData.resume.data.work.items[0];
      work_item['position'] = job_title;
      dispatch({
        type: 'SET_USER_DEFINED_JOB_TITLE',
      });
      const userDefinedJobTitle =
        getState().fileScanReducer.userDefinedJobTitle;
      resume.data.work.items.push(work_item);
      const { total_value, ats_data } = scoreCalculator(
        {
          resume,
          job_parsed,
          soft_skills: { skills: softSkills },
          userDefinedJobTitle,
        },
        count_managements,
      );
      newResponse['ats']['ats_data'] = ats_data;
      newResponse['ats']['ats_data']['job_title_matched']['isMatched'] = true;
      newResponse['total_score'] = total_value;

      if (responce.data.status === 200) {
        updateScanScore({
          total_score: newResponse['total_score'],
          oid: newResponse['oid'],
        });

        UpdateScanResuma(
          {
            oid: newResponse['oid'],
            type: 'scan',
            data: { data: resume },
          },
          () => null,
          () => null,
        );

        let obj = {
          ...resume,
          editorJson: responce.data.data,
        };
        dispatch({
          type: 'SAVED_RESUME',
          payload: obj,
        });

        dispatch({
          type: 'SCAN_RESULT',
          payload: newResponse,
        });
      } else {
        toastr.error(`Failed to updated Job title!`);
      }

      dispatch({
        type: 'EDITOR_STATUS',
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: 'UPDATE_FAILED',
      });
    }
  };

export const getLatestScanResult =
  (data, oid) => async (dispatch, getState) => {
    const step = localStorage.getItem('activeStep');
    try {
      if (data === 'not done') {
        dispatch({
          type: 'STEPPER_LOADER',
          payload: true,
        });
        const res = await axios.get(
          `${url}/get_updated_scan_results?data_id=${oid}`,
        );

        if (res.data.status === 500) {
          //
        } else {
          const skills = skillMatcher(
            res.data.resume.data.skills.items,
            res.data.job_parsed.skills,
            res.data.soft_skills.skills,
            res.data.response.skillsData,
            res.data.resume.data?.additional_skills?.items || [],
            res.data.job_parsed.softSkills,
          );

          var newResponse = res.data.response;
          newResponse['skillsData'] = skills;
          const userDefinedJobTitle =
            getState().fileScanReducer.userDefinedJobTitle;
          const { total_value, score_skills, ats_data } = scoreCalculator(
            { ...res.data, userDefinedJobTitle },
            res.data.counts_managements,
          );
          if (!ats_data?.job_title_matched?.isMatched)
            dispatch(
              getSimilarJobTitle(
                { title: res.data.job_parsed['MainJobTitle'] },
                oid,
              ),
            );
          newResponse['ats']['ats_data'] = ats_data;

          newResponse['total_score'] = total_value;
          newResponse['scores']['skills'] = score_skills;

          updateScanScore({
            total_score: newResponse['total_score'],
            oid: res.data.response.oid,
          });
          // updateSkills({
          //   skills: skills,
          //   oid: res.data.response.oid,
          //   skillsItem: res.data.resume.data.skills.items,
          // });
          let templates = [];
          const resume = res?.data?.resume;
          const editorKeys = Object.keys(resume || {}).filter((key) =>
            key.endsWith('editorJson'),
          );
          if (editorKeys?.length > 1) {
            templates = editorKeys?.map((key, index) => ({
              id: index + 1,
              template: resume[key],
            }));
          }
          dispatch({
            type: 'SAVE_JOB_PARSED',
            payload: res.data.job_parsed,
          });

          dispatch({
            type: 'SAVE_SOFT_SKILLS',
            payload: res.data.soft_skills.skills,
          });

          dispatch({
            type: 'SAVE_COUNT_MANAGEMENTS',
            payload: res.data.counts_managements,
          });

          dispatch({
            type: 'SAVE_SOVREN',
            payload: res.data.soft_skills?.sovren_counts,
          });
          dispatch({
            type: 'SAVE_STEP_ACTIVE_INDEX',
            payload: parseInt(step) ? parseInt(step) : 1,
          });
          dispatch({
            type: 'CLOUD_IMAGE',
            payload: res.data.styleCloud,
          });
          dispatch({
            type: 'SAVED_RESUME',
            payload: res.data.resume,
          });
          dispatch({
            type: 'SAVED_TEMPLATES',
            payload: templates,
          });
          dispatch({
            type: 'SAVE_LATEST_SCAN',
            payload: newResponse,
          });
          if (res?.data?.response?.suggestionData) {
            dispatch({
              type: 'SAVE_SUGGESTIONS',
              payload: res?.data?.response?.suggestionData,
            });
          }
          dispatch({
            type: 'SELECTED_TITLE',
            payload: res?.data?.job_parsed?.MainJobTitle,
          });
        }
      }
      dispatch({
        type: 'STEPPER_LOADER',
        payload: false,
      });
    } catch (error) {
      localStorage.removeItem('myScanId');
      dispatch({
        type: 'STEPPER_LOADER',
        payload: false,
      });
      dispatch({ type: 'SCAN_RESULT_STATUS' });
    }
  };

export const clearLatestScanResult = () => async (dispatch) => {
  dispatch({
    type: 'CLEAR_LATEST_SCAN',
  });
  dispatch({
    type: 'CLEAR_SAVED_RESUME',
  });
};

export const getVideoLinks = () => async (dispatch) => {
  try {
    dispatch({
      type: 'VIDEO_LINKS_STATUS',
    });
    let res = await axios.get(`${url}/get-video`);
    dispatch({
      type: 'VIDEO_LINKS_STATUS',
    });
    if (res.data.status === 200) {
      dispatch({
        type: 'VIDEO_LINKS_STATE',
        payload: res.data.data,
      });
    }
  } catch (error) {}
};

export const getSuggestions =
  (jobTitle, monthsOfExperience) => async (dispatch) => {
    try {
      dispatch({
        type: 'SUGGESTION_LOADER',
        payload: true,
      });
      const requestData = {
        jobTitle,
      };

      if (monthsOfExperience) {
        requestData['experience'] = monthsOfExperience;
      }

      let res = await axios.post(`${url}/search-json-suggestions`, requestData);
      if (res.data.status === 200) {
        dispatch({
          type: 'GET_SUGGESTIONS',
          payload: res.data.data,
        });
        dispatch({
          type: 'SUGGESTION_LOADER',
          payload: false,
        });
        // dispatch({
        //   type: 'SAVE_SUMMARY',
        //   payload: res.data.data.Fresh_Summary,
        // });
      }
    } catch (error) {
      console.log('errro', error);
      dispatch({
        type: 'SUGGESTION_LOADER',
        payload: false,
      });
    }
  };

export const AddSuggestions = (data, callback) => async (dispatch) => {
  try {
    dispatch({
      type: 'EDITOR_STATUS',
      payload: true,
    });
    callback && callback(true);
    let res = await axios.post(`${url}/get-update-suggestion`, data);
    if (res.data.response === 'success') {
      const data = {
        editorJson: res?.data?.editorJson,
        addedSuggestions: res?.data?.data,
      };

      dispatch({
        type: 'UPDATE_SUGGESTIONS_EDITOR',
        payload: data,
      });
    }
  } catch (error) {
    console.log('errro', error);
  } finally {
    callback && callback(false);
    dispatch({
      type: 'EDITOR_STATUS',
      payload: false,
    });
  }
};

export const templateSelectorAction = (temp, tempId) => async (dispatch) => {
  try {
    const data = { temp, tempId };
    dispatch({
      type: 'TEMPLATE_SELECTION',
      payload: data,
    });
  } catch (error) {
    console.log('errro', error);
  }
};

export const generateCoverLetter = (data, setLoader) => async (dispatch) => {
  try {
    let res = await axios.post(`${url}/cover-letter-ai`, data);
    if (res?.data?.status === 200) {
      dispatch({
        type: 'SAVE_COVER_LETTER',
        payload: {
          description: res?.data?.coverLetter,
          oid: res?.data?.coverLetterId,
        },
      });
      setLoader(false);
    } else if (res?.data?.status === 500) {
      setLoader(false);
      toastr.error(res?.data?.message);
    }
  } catch (error) {
    console.log('error', error);
    setLoader(false);
  }
};
export const getSingleCoverLetter = (id) => async (dispatch) => {
  try {
    const data = {
      oid: id,
    };
    let res = await axios.post(`${url}/get-single-cover-letter`, data);
    if (res.data.status === 200) {
      dispatch({
        type: 'GET_COVER_LETTER',
        payload: {
          coverLetter: res?.data?.coverLetter,
          data: res.data,
        },
      });
      // setLoader(false);
    }
  } catch (error) {
    console.log('errro', error);
  }
};
export const sendMeEmail = async (textData, subject) => {
  try {
    let res = await axios.post(`${url}/send-email-to-me`, {
      textData,
      subject,
    });
    if (res.data.status === 200) {
      toastr.success('Email Sent Successfully');
    }
  } catch (error) {
    console.log('errro', error);
  }
};
export const sendEmailCoverLetter = (data, setLoader) => async (dispatch) => {
  try {
    let res = await axios.post(`${url}/send-email-to-user`, data);
    if (res.data.status === 200) {
      toastr.success('Email Sent Successfully');
    }
  } catch (error) {
    console.log('errro', error);
  } finally {
    setLoader && setLoader('');
  }
};

export const sendAllEmailCoverLetter = (data) => async (dispatch) => {
  try {
    let res = await axios.post(`${url}/send-email-to-all-users`, data);
    if (res.data.status === 200) {
      toastr.success('Email Sent Successfully');
    }
  } catch (error) {
    console.log('errro', error);
  }
};

export const addEmailCoverLetter = (data, setLoader) => async (dispatch) => {
  try {
    let res = await axios.post(`${url}/add-email-to-user`, data);
    if (res.data.status === 200) {
      dispatch({
        type: 'ADD_SUGGESTION_EMAIL',
        payload: { ...data, mailId: res.data.mailId },
      });
      setLoader(false);
    }
  } catch (error) {
    console.log('errro', error);
    setLoader(false);
  }
};

export const getFollowUpEmail = (resumeId, role) => async (dispatch) => {
  try {
    let res = await axios.post(`${url}/follow_up_email`, {
      resumeId,
      userRole: role,
    });
    if (res.data.status === 200) {
      dispatch({
        type: 'FOLLOWUP_EMAIL',
        payload: res.data?.followUpEmail,
      });
    }

    return res.data?.followUpEmail || '';
  } catch (error) {
    console.log('errro', error);
    return '';
  }
};

export const getSuggestionEmails = (data) => async (dispatch) => {
  try {
    let res = await axios.post(`${url}/get-suggestion-emails`, data);
    if (res.data.status === 200) {
      dispatch({
        type: 'GET_SUGGESTION_EMAILS',
        payload: res.data.suggestionEmails,
      });
    }
  } catch (error) {
    console.log('error', error);
  }
};
export const deleteSuggestionEmail = (mailId) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/del-suggestion-emails`, {
      mailId: mailId,
    });
    if (res.data.status === 200) {
      dispatch({
        type: 'DEL_SUGGESTION_EMAILS',
        payload: mailId,
      });
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const updateSuggestionEmail = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-suggestion-emails`, data);
    if (res.data.status === 200) {
      dispatch({
        type: 'PUT_SUGGESTION_EMAILS',
        payload: data,
      });
    }
  } catch (error) {
    console.log('error', error);
  }
};
export const addExperienceSummary = (jobTitle, exp) => async (dispatch) => {
  try {
    let res = await axios.post(`${url}/search-json-suggestions`, {
      jobTitle,
      experience: exp,
    });
    if (res.data.status === 200) {
      dispatch({
        type: 'SAVE_SUMMARY',
        payload: res.data.data.summary,
      });
    }
  } catch (error) {
    console.log('errro', error);
  }
};

export const getCompanyInfo = (company) => (dispatch) => {
  var myHeaders = new Headers();
  myHeaders.append('X-API-KEY', '3338537f643cb5d93c14a62ab23c108058b87fd2');
  myHeaders.append('Content-Type', 'application/json');

  var raw = JSON.stringify({
    q: company,
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  dispatch({
    type: 'INFO_LOADER',
    payload: true,
  });
  fetch('https://google.serper.dev/search', requestOptions)
    .then((response) => response.json())
    .then((result) => {
      dispatch({
        type: 'COMPANY_INFO',
        payload: result?.knowledgeGraph ?? {},
      });
      dispatch({
        type: 'INFO_LOADER',
        payload: false,
      });
    })
    .catch((error) => console.log('error', error));
};

export const setSelectedTitle = (jobTitle) => (dispatch) => {
  dispatch({
    type: 'SELECTED_TITLE',
    payload: jobTitle,
  });
};

export const getSuggestionAgainstSelectedTitle =
  (jobTitle, titleType) => async (dispatch) => {
    try {
      if (titleType === 'builder') {
        dispatch({
          type: 'SELECTED_BUILDER_TITLE',
          payload: jobTitle,
        });
      } else {
        dispatch({
          type: 'SELECTED_TITLE',
          payload: jobTitle,
        });
      }
      dispatch({
        type: 'SUGGESTION_LOADER',
        payload: true,
      });
      let res = await axios.post(`${url}/search-json-suggestions`, {
        jobTitle,
      });

      if (res?.data?.status === 200) {
        dispatch({
          type: 'SUGGESTION_LOADER',
          payload: false,
        });
        dispatch({
          type: 'GET_SUGGESTIONS',
          payload: res?.data?.data,
        });
        dispatch({
          type: 'SAVE_SUMMARY',
          payload: res?.data?.data?.summary,
        });
      }
    } catch (error) {}
  };

export const getOnetData = (jobTitle) => async (dispatch) => {
  try {
    dispatch({
      type: 'INFO_LOADER',
      payload: true,
    });
    let res = await axios.post(`${url}/search-json-suggestions`, {
      jobTitle,
    });
    if (res?.data?.status === 200) {
      dispatch({
        type: 'INFO_LOADER',
        payload: false,
      });
      dispatch({
        type: 'ONET_DATA',
        payload: {
          jobTitle: res.data.data.JobTitle,
          onsc: res.data.data.SOC,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: 'INFO_LOADER',
      payload: false,
    });
  }
};
