import { colors } from '../../commonComponents/colors';

export const styles = {
  flex: {
    display: 'flex',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  inline: {
    display: 'inline',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  relative: {
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
  },
  left50: { left: '50%' },
  width_100_percent: {
    width: '100%',
  },
  width_80_percent: {
    width: '80%',
  },
  fontWeight_300: {
    fontWeight: 300,
  },
  fontWeight_400: {
    fontWeight: 400,
    minWidth: '160px',
  },
  fontWeight_500: {
    fontWeight: 500,
  },
  fontWeight_600: {
    fontWeight: '600',
  },
  fontWeight_heading: {
    fontWeight: '700',
    fontSize: 20,
  },
  fontWeight_bold: {
    fontWeight: 'bold',
  },
  fontSize_75_rem: {
    fontSize: '.75rem',
  },
  mt_5: {
    marginTop: 5,
  },
  mt_10: {
    marginTop: 10,
  },
  mt_20: {
    marginTop: 20,
  },
  mt_30: { marginTop: 30 },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_40: {
    marginBottom: 40,
  },
  mb_90: {
    marginBottom: 90,
  },
  ml_3: {
    marginLeft: 3,
  },
  mr_3: {
    marginRight: 3,
  },
  p_none: {
    padding: 'none',
  },
  p_10: { padding: 10 },
  textAlignCenter: {
    textAlign: 'center',
    marginLeft: '30px',
  },
  atsResult: {
    position: 'absolute',
    right: 25,
    top: 20,
  },
  colorGrey: {
    color: 'grey',
  },
  row: { paddingLeft: '20px', paddingRight: '20px' },
  h3: { color: '#145388', fontSize: '0.7rem' },
  textArea: {
    width: '100%',
    height: '460px',
    padding: '10px',
    border: '1px solid rgb(169, 169, 169)',
    fontSize: 13,
    color: 'black',
  },
  scrollBar: {
    width: '100%',
    height: '460px',
    padding: '10px',
    border: '1px solid rgb(169, 169, 169)',
    fontSize: '13px',
    color: ' black',
  },
  td: {
    color: '#145388',
    position: 'relative',
  },
  actionIcon: {
    position: 'absolute',
    left: '50%',
    display: 'inline',
    cursor: 'pointer',
  },
  cardAction: {
    // position: "absolute",
    // right: 25,
    // top: 29,
    marginTop: 20,
    justifyContent: 'space-between',
    display: 'flex',
  },
  cardBtn: {
    backgroundColor: colors.blue,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    padding: '0px 10px',
  },
  reviewScore: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  styleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progess: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '10px',
    marginTop: '20px',
  },
  info_box: {
    width: 50,
    height: 15,
    borderRadius: 3,
    marginRight: 5,
    marginLeft: 5,
  },
  resumeResult: {
    position: 'absolute',
    right: 25,
    top: 20,
  },
  topStatCOntainer: { textAlign: 'center', paddingTop: 15 },
  h2: { fontWeight: '600', fontSize: 25 },
  chartContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chartImage: {
    width: '100%',
    marginTop: '20px',
    maxWidth: '30rem',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  chartCanvas: { display: 'block', width: 770, height: 380, margin: '0 auto' },
  table: {
    display: 'flex',
    border: '1px solid rgb(238, 238, 238)',
    borderRadius: 5,
    boxShadow: '1px 1px 1px 1px rgb(238,238,238)',
  },
  col: {
    float: 'left',
    width: '50%',
    paddingTop: 15,
    paddingLeft: 15,
  },
  borderLeft: {
    borderLeft: '1px solid rgb(238, 238, 238)',
  },
  atsCardBody: {
    fontSize: '.85rem',
  },
  hardSkill_table: {
    fontSize: '.75rem',
  },
  hardSkill_table2: {
    fontSize: '.85rem',
  },
  jobMatchIcon: {
    fontSize: '0.9rem',
  },
  cardDiv: {
    position: 'absolute',
    right: 25,
    top: 29,
    display: 'flex',
  },
  jobMatch_cardTitle: {
    fontWeight: 500,
    // marginTop: 50,
  },
  cursorPointer: {
    cursor: 'pointer',
    marginLeft: 3,
    marginTop: -3,
  },
  jobMatch_progess: {
    width: '12%',
  },
  resumeResult_cardBody: {
    fontSize: '.85rem',
  },
  tdSpan: { color: 'black', fontWeight: 'bold' },
  scanResultCard: { textAlign: 'center', padding: 60 },
  recruiter_cardBody: { fontSize: '.85rem', overflow: 'auto' },
  colorGreen: { color: 'green' },
  reviewTitle: {
    fontWeight: 800,
    fontSize: '22px',
    lineHeight: '34.13px',
    fontFamily: 'Montserrat',
    color: '#4A4A4A',
    justifyContent: 'center',
    display: 'flex',
  },
  colorBlack: { color: 'black' },
};
