import React, { Component } from 'react';
import GradientCard from '../../../commonComponents/gradientCard';
import moment from 'moment';
import { styles } from './style';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  render() {
    return (
      <div
        className="my_footer_container"
        style={{
          width: '100%',
        }}
      >
        <GradientCard>
          <div style={styles.gradientCard}>
            <div>
              <span style={styles.gradientSpan(this.state.windowWidth)}>
                &copy; {moment(new Date()).format('YYYY')} Resumas.com - All
                Rights Reserved
              </span>
            </div>
          </div>
        </GradientCard>
      </div>
    );
  }
}
