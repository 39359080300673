export const styles = {
  flex: {
    display: "flex",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  justifyContentEnd: {
    justifyContent: "flex-end",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  width_100_percent: {
    width: "100%",
  },
  width_80_percent: {
    width: "80%",
  },
  fontWeight_400: {
    fontWeight: 400,
  },
  fontWeight_bold: {
    fontWeight: "bold",
  },
  fontSize_1_rem: {
    fontSize: "1rem",
  },
  mt_5: {
    marginTop: 5,
  },
  mt_30: { marginTop: 30 },
  mt_20: {
    marginTop: 20,
  },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_40: {
    marginBottom: 40,
  },
  mb_90: {
    marginBottom: 90,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  bb_ligthGrey: {
    border: "0.5px dashed lightgray",
    marginTop: 5,
    marginBottom: 5,
  },
  h5: {
    marginLeft: 10,
    marginRight: 20,
    marginTop: 7,
    fontSize: "1rem",
    textAlign: "left",
    width: "80%",
  },
  listItemText: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
  },
  span: {
    paddingLeft: 19,
    paddingRight: 19,
  },
  flexRow: {
    flexDirection: "row",
  },
};
