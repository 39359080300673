function cleanText(text) {
    text = text.replace(/\|\s*\|/g, '|');
    // Remove extra symbols
    text = text.replace(/['"\\]+/g, '');

    // Replace multiple pipe symbols with a single pipe symbol
    text = text.replace(/\|+/g, '|');

    // Remove extra spaces or newlines around |
    text = text.replace(/\s*\|\s*/g, ' | ');

    // Remove extra spaces at the beginning and end of each line
    // text = text.replace(/^\s+|\s+$/gm, '');
    text = text.replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, '\n');

    // Remove empty pipe symbols at the beginning of the first line
    text = text.replace(/^\|+/, '');

    // Trim the text
    text = text.trim();

    return text;

}

export default cleanText;