import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from '../style';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import SelectTag from '../../component/SelectTag';
import { useDispatch } from 'react-redux';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';
import { colors } from '../../../../commonComponents/colors';
import InputText from '../../component/inputText';
import Accordian from '../helper/Accordian';
import { lastIndexOf } from 'lodash';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import moment from 'moment';
import Select from 'react-select';
import RichEditor from '../helper/RichEditor';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
export default function Education(props) {
  const [checked, setChecked] = React.useState(false);
  const [degree, setDegree] = React.useState('');
  const [places, setPlaces] = React.useState('');
  const [endDatePresent, setEndDatePresent] = React.useState(false);
  const [multiLocations, setMultiLocations] = React.useState([]);
  const classes = useStyles();

  const dispatch = useDispatch();
  const path = 'education.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');

    dispatch(updateOrder(storeReorder, path));
  };
  //eslint-disable-next-line
  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };
  //eslint-disable-next-line
  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };

  let { data } = props;
  const lastIndex = lastIndexOf(data);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: 13,
      minHeight: '30px',
      // height: '30px',
      border: state.isFocused ? '0.5px solid #4267b3' : '0.5px solid #ccc',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '0.5px solid #ccc',
        boxShadow: 'none',
      },
      '&:onFocus': {
        border: '0.5px solid #4267b3',
        boxShadow: 'none',
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '43px',
      padding: '0 6px',
    }),
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled ? 'red' : blue,
        color: '#000',
        fontSize: 15,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
  };
  const handleChange = (address) => {
    setPlaces(address);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        if (multiLocations.length) {
          setMultiLocations(() => [...multiLocations]);
        }
        setMultiLocations([results[0].address_components[0].short_name]);
      })

      .catch((error) => console.error('Error', error));
  };

  const handledData = (() => {
    if (!data?.length) {
      return [
        {
          location: '',
          institution: '',
          degree: '',
          month: '',
        },
      ];
    }

    // Check if data is Array of Strings
    if (typeof data[0] === 'string') {
      return data?.map((item) => {
        return {
          location: '',
          institution: '',
          degree: item,
          month: '',
        };
      });
    }

    return data;
  })();

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Education
          </label>
          <br />
          <p>
            A varied education on your resume sums up the value that your
            learning and background will bring to job.
          </p>
        </div>
      </Grid>
      <Dnd
        data={handledData}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => {
          const location = {
            label: item.location,
            value: item.location,
          };
          const institution = {
            label: item.institution,
            value: item.institution,
          };
          return (
            <Accordian
              defaultExpanded={false}
              summary={
                <>
                  <label
                    className={
                      item?.degree || item?.institution
                        ? classes.summaryLabel
                        : classes.summaryLabelGrey
                    }
                  >
                    {item.degree || item?.institution
                      ? item?.degree || '' + ' at ' + item?.institution || ''
                      : 'Add Education Details'}
                    {'\n '}
                  </label>
                  <br />
                  <label className={classes.summarySubLabel}>
                    {item.month && item.month}
                  </label>
                </>
              }
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div>
                    <label className={classes.label}>School Name</label>
                    {/* <Text
                      value={item.institution}
                      placeholder="School Name"
                      customclass={classes.input}
                      path={`${path}.${index}.institution`}
                    /> */}
                    <PlacesAutocomplete
                      searchOptions={{
                        types: [
                          'university',
                          'primary_school',
                          'school',
                          'secondary_school',
                        ],
                        // types: ['(cities)'],
                        // componentRestrictions: { country: 'us' },
                      }}
                      value={places}
                      onChange={handleChange}
                      onSelect={handleSelect}
                    >
                      {({ getInputProps, suggestions, loading }) => (
                        <div style={{ paddingBottom: 16 }}>
                          <Select
                            // isMulti

                            isLoading={loading}
                            options={suggestions?.map((n) => {
                              let cityStateCountry =
                                n?.formattedSuggestion?.secondaryText.split(
                                  ',',
                                );
                              cityStateCountry.shift();
                              const joined = cityStateCountry.join();

                              return {
                                label:
                                  n?.formattedSuggestion?.mainText +
                                  ', ' +
                                  joined,
                                value:
                                  n?.formattedSuggestion?.mainText +
                                  ', ' +
                                  joined,
                              };
                            })}
                            {...getInputProps({
                              placeholder: 'Select City ',
                              // className: 'location-search-input',
                            })}
                            styles={customStyles}
                            onChange={(tags) => {
                              var arr = tags.value;
                              var removedElement = arr.split(',');
                              var firstElm = removedElement.shift();
                              const joined = removedElement.join();
                              dispatch(
                                onBlurField(
                                  firstElm,
                                  `${path}.${index}.institution`,
                                ),
                              );
                              dispatch(
                                onBlurField(
                                  joined,
                                  `${path}.${index}.location`,
                                ),
                              );
                              setMultiLocations({
                                label: joined,
                                value: joined,
                              });
                            }}
                            value={institution}
                            onInputChange={(text) =>
                              getInputProps().onChange({
                                target: { value: text },
                              })
                            }
                          />
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div>
                    <label className={classes.label}>Degree</label>
                    <SelectTag
                      value={item.degree}
                      placeHolder="Select Degree"
                      customclass={classes.input}
                      isCreatable={false}
                      path={`${path}.${index}.degree`}
                      options={[
                        'High School Diploma',
                        'GED',
                        'Associate of Arts',
                        'Associate of Science',
                        'Associate of Applied Science',
                        'Bachelor of Arts',
                        'Bachelor of Science',
                        'BBA',
                        'Master of  Arts',
                        'Master of Science',
                        'MBA',
                        'J.D.',
                        'M.D',
                        'Ph.D.',
                        'No Degree',
                      ]}
                    />
                  </div>
                  {/* <div style={{ marginTop: 12 }}>
                    <label className={classes.label}>Field Of Study</label>
                    <Text
                      value={item.field}
                      placeholder="Major or Minor (If any)"
                      customclass={classes.input}
                      path={`${path}.${index}.field`}
                    />
                  </div> */}

                  {/* <div>
                    <label className={classes.label}>Courseworks</label>
                    <Text
                      value={item.courseworks}
                      placeholder="Ex: Economics, Data Management, Marketing, Finance"
                      customclass={classes.input}
                      path={`${path}.${index}.courseworks`}
                    />
                  </div> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div>
                    <label className={classes.label}>School Location</label>
                    {/* <Text
                      value={item.location}
                      placeholder="City, State"
                      customclass={classes.input}
                      path={`${path}.${index}.location`}
                    /> */}
                    <PlacesAutocomplete
                      searchOptions={{
                        types: ['(cities)'],
                        // componentRestrictions: { country: 'us' },
                      }}
                      value={places}
                      onChange={handleChange}
                      onSelect={handleSelect}
                    >
                      {({ getInputProps, suggestions, loading }) => (
                        <div style={{ paddingBottom: 16 }}>
                          <Select
                            // isMulti
                            isLoading={loading}
                            options={suggestions?.map((n) => ({
                              label:
                                n?.formattedSuggestion?.mainText +
                                ', ' +
                                n?.formattedSuggestion?.secondaryText,
                              value:
                                n?.formattedSuggestion?.mainText +
                                ', ' +
                                n?.formattedSuggestion?.secondaryText,
                            }))}
                            {...getInputProps({
                              placeholder: 'Select City ',
                              // className: 'location-search-input',
                            })}
                            styles={customStyles}
                            onChange={(tags) => {
                              dispatch(
                                onBlurField(
                                  tags.label,
                                  `${path}.${index}.location`,
                                ),
                              );
                              setMultiLocations(tags);
                            }}
                            value={location}
                            onInputChange={(text) =>
                              getInputProps().onChange({
                                target: { value: text },
                              })
                            }
                          />
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  {/* <Grid container spacing={2}> */}
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div>
                      <label className={classes.label}>Graduation Date</label>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {item.month === 'Present' ? (
                          <Text
                            type="text"
                            placeholder="Present"
                            value="Present"
                            disabled={true}
                            editable={false}
                            className={classes.input}
                            style={{
                              border: '1px solid',
                              borderColor: colors.grey,
                              borderRadius: 4,
                              marginBottom: 0,
                              width: '100%',
                            }}
                          />
                        ) : (
                          <InputText
                            type={'month'}
                            placeholder={'MMMM YYYY'}
                            value={item.month ?? ''}
                            path={`${path}.${index}.month`}
                            customclass={classes.input}
                          />
                        )}
                        <label
                          className={classes.label}
                          htmlFor="current"
                          style={{
                            marginRight: 5,
                          }}
                        >
                          Current
                        </label>
                        {item.month !== 'Present' ? (
                          <ImCheckboxUnchecked
                            style={{
                              cursor: 'pointer',
                              // marginLeft: -70,
                            }}
                            size="1.2em"
                            color="#4267B2"
                            onClick={() =>
                              dispatch(
                                onBlurField(
                                  'Present',
                                  `${path}.${index}.month`,
                                ),
                              )
                            }
                          />
                        ) : (
                          <ImCheckboxChecked
                            style={{
                              cursor: 'pointer',
                              // marginLeft: -70,
                            }}
                            size="1.2em"
                            color="#4267B2"
                            onClick={() =>
                              dispatch(
                                onBlurField(
                                  moment().format('MMMM YYYY'),
                                  `${path}.${index}.month`,
                                ),
                              )
                            }
                          />
                        )}
                      </div>
                      <div></div>
                    </div>
                  </Grid>

                  {/* </Grid> */}

                  {/* <div>
                    <label className={classes.label}>Awards</label>
                    <Text
                      value={item.awards}
                      placeholder="Ex: Ivy League Debate Championship, Programming Competition"
                      customclass={classes.input}
                      path={`${path}.${index}.awards`}
                    />
                  </div> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div>
                    <label className={classes.label}>Major</label>
                    <Text
                      value={item.major}
                      placeholder="Major"
                      customclass={classes.input}
                      path={`${path}.${index}.major`}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div>
                    <label className={classes.label}>Minor</label>
                    <Text
                      value={item.minor}
                      placeholder="Minor"
                      customclass={classes.input}
                      path={`${path}.${index}.minor`}
                    />
                  </div>
                </Grid>
              </Grid>
              {/* <div>
                <label className={classes.label}>Extracurricular</label>
                <Text
                  value={item.extracurricular}
                  placeholder="Ex: Hockey Team Captain"
                  customclass={classes.input}
                  path={`${path}.${index}.extracurricular`}
                />
              </div> */}
              <label className={classes.label} style={{ marginTop: 14 }}>
                Summary
              </label>
              <RichEditor
                value={item.summary}
                placeHolder="Description (Include GPA, awards & Extracurricular activities)"
                customclass={`${classes.input} ${classes.summaryInput}`}
                path={`${path}.${index}.summary`}
                onBlurField={onBlurField}
              />
            </Accordian>
          );
        }}
      />
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more education
      </div>
    </>
  );
}
