import React from 'react'

export default function CopyIcon() {
    return (
        <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.76557 3.99355L7.27576 0.638547C7.36879 0.549624 7.49254 0.5 7.62123 0.5H14.9604C15.2366 0.5 15.4604 0.723857 15.4604 1V17.8869C15.4604 18.1631 15.2366 18.3869 14.9604 18.3869H0.999999C0.723857 18.3869 0.5 18.1631 0.5 17.8869V7.3285C0.5 7.19198 0.555829 7.06139 0.654527 6.96705L3.76557 3.99355Z" fill="white" stroke="#4A4A4A" />
            <mask id="path-2-inside-1_1_441" fill="white">
                <path d="M8.36044 1.52588e-05H7.52441V0.726435H8.36044V1.52588e-05Z" />
            </mask>
            <path d="M8.36044 1.52588e-05H7.52441V0.726435H8.36044V1.52588e-05Z" fill="#4A4A4A" />
            <path d="M7.52441 1.52588e-05V-0.999985H6.52441V1.52588e-05H7.52441ZM8.36044 1.52588e-05H9.36044V-0.999985H8.36044V1.52588e-05ZM8.36044 0.726435V1.72644H9.36044V0.726435H8.36044ZM7.52441 0.726435H6.52441V1.72644H7.52441V0.726435ZM7.52441 1.00002H8.36044V-0.999985H7.52441V1.00002ZM7.36044 1.52588e-05V0.726435H9.36044V1.52588e-05H7.36044ZM8.36044 -0.273565H7.52441V1.72644H8.36044V-0.273565ZM8.52441 0.726435V1.52588e-05H6.52441V0.726435H8.52441Z" fill="#4A4A4A" mask="url(#path-2-inside-1_1_441)" />
            <path d="M0.501982 6.76421L7.08992 1.03996L7.10872 1.33394L7.44246 1.05921V6.53779V6.76421H0.501982Z" fill="#4A4A4A" stroke="#4A4A4A" />
            <path d="M9.14785 9.95662L11.9481 7.28018C12.0411 7.19125 12.1649 7.14163 12.2936 7.14163H17.9999C18.276 7.14163 18.4999 7.36549 18.4999 7.64163V21C18.4999 21.2762 18.276 21.5 17.9999 21.5H7.02124C6.7451 21.5 6.52124 21.2762 6.52124 21V12.6809C6.52124 12.5443 6.57707 12.4137 6.67577 12.3194L9.14785 9.95662Z" fill="white" stroke="#4A4A4A" />
            <mask id="path-6-inside-2_1_441" fill="white">
                <path d="M12.8195 6.64166H12.1396V7.23237H12.8195V6.64166Z" />
            </mask>
            <path d="M12.8195 6.64166H12.1396V7.23237H12.8195V6.64166Z" fill="black" />
            <path d="M12.1396 6.64166V5.64166H11.1396V6.64166H12.1396ZM12.8195 6.64166H13.8195V5.64166H12.8195V6.64166ZM12.8195 7.23237V8.23237H13.8195V7.23237H12.8195ZM12.1396 7.23237H11.1396V8.23237H12.1396V7.23237ZM12.1396 7.64166H12.8195V5.64166H12.1396V7.64166ZM11.8195 6.64166V7.23237H13.8195V6.64166H11.8195ZM12.8195 6.23237H12.1396V8.23237H12.8195V6.23237ZM13.1396 7.23237V6.64166H11.1396V7.23237H13.1396Z" fill="#4A4A4A" mask="url(#path-6-inside-2_1_441)" />
            <path d="M6.67929 12.0487L11.7053 7.68159L11.7202 7.9145L11.9798 7.70084V11.958V12.0487H6.67929Z" fill="#4A4A4A" stroke="#4A4A4A" />
        </svg>
    )
}
