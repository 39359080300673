export const actionTypes = {
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  UPDATE_THEME: 'UPDATE_THEME',
  ADD_NEW_WORK_EXPERIENCE: 'ADD_NEW_WORK_EXPERIENCE',
  UPDATE_WORK_EXPERIENCE: 'UPDATE_WORK_EXPERIENCE',
  UPDATE_WORK_EXPERIENCE_DATA: 'UPDATE_WORK_EXPERIENCE_DATA',
  DELETE_WORK_EXPERIENCE_DATA: 'DELETE_WORK_EXPERIENCE_DATA',
  ADD_DELETED_WORK_EXPERIENCE_ITEM: 'ADD_DELETED_WORK_EXPERIENCE_ITEM',
  ADD_NEW_EDUCATION: 'ADD_NEW_EDUCATION',
  UPDATE_EDUCATION: 'UPDATE_EDUCATION',
  UPDATE_EDUCATION_DATA: 'UPDATE_EDUCATION_DATA',
  DELETE_EDUCATION_DATA: 'DELETE_EDUCATION_DATA',
  ADD_DELETED_WORK_EDUCATION_ITEM: 'ADD_DELETED_WORK_EDUCATION_ITEM',
  ADD_NEW_SKILL: 'ADD_NEW_SKILL',
  UPDATE_SKILL: 'UPDATE_SKILL',
  UPDATE_SKILL_DATA: 'UPDATE_SKILL_DATA',
  DELETE_SKILL_DATA: 'DELETE_SKILL_DATA',
  ADD_DELETED_WORK_SKILL_ITEM: 'ADD_DELETED_WORK_SKILL_ITEM',
  UPDATE_ITEM_STATUS: 'UPDATE_ITEM_STATUS',
  RESET_BUILD_RESUME: 'RESET_BUILD_RESUME',
  NEW_BUILD_RESUME: 'NEW_BUILD_RESUME',
  RESUME_EXIST: 'RESUME_EXIST',
  SET_ALL_BUILD_RESUMES: 'SET_ALL_BUILD_RESUMES',
  // customConstants
  ON_BLUR_FILED: 'ON_BLUR_FILED',
  ON_UPDATE_ORDER: 'ON_UPDATE_ORDER',
  ON_ADD_OBJECT: 'ON_ADD_OBJECT',
  DELETE_OBJECT: 'DELETE_OBJECT',
  CLEAR_STYLES: 'CLEAR_STYLES',
  CHANGE_THEME: 'CHANGE_THEME',
  GET_RESUME_SCAN: 'GET_RESUME_SCAN',
  GET_RESUME_SCAN_STATUS: 'GET_RESUME_SCAN_STATUS',
  GET_RESUME_BUILDER_CHOICE: 'GET_RESUME_BUILDER_CHOICE',
  GET_RESUME_NAME: 'GET_RESUME_NAME',
  GET_BUILDER_RESUME_DATA: 'GET_BUILDER_RESUME_DATA',
  ADD_SUMMARY: 'ADD_SUMMARY',
  UPDATE_SUMMARY: 'UPDATE_SUMMARY',
};
