import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { BeatLoader } from 'react-spinners';
import { loginUser } from '../../redux/actions';
import { getSkills } from '../../../../commonRedux/jobs/action';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import images
import loginImage from '../../../../assets/signup.webp';
import { css } from '@emotion/core';
import { validateEmail } from '../../../../utils/emailValidation';
import { styles } from '../../style';
import useDimenstion from '../../useDimension';
import Footer from '../../../layout/authLayout/Footer';
import TopBar from '../../../layout/authLayout/Topbar';

const RightImage = ({ signupImage }) => {
  const size = useDimenstion();
  if (size.width < 991 && size.width > 700) {
    return null;
  }
  return (
    <Col
      lg={6}
      className="offset-lg-6 padding-less img order-1"
      style={{
        backgroundImage: `url(${signupImage})`,
        backgroundSize: 'contain',
      }}
    ></Col>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class PageCoverLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      width: 0,
      height: 0,
      loading: false,
      // email: "rana.navee.sarwar@gmail.com",
      // password: "12345678",
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (this.props.user) {
      this.props.history.push('/dashboards');
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.props.history.push('/dashboards');
    }
  }

  getValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loginCallBack = () => {
    this.setState({ loading: false });
  };

  onUserLogin = () => {
    if (this.state.email === '') {
      toastr.error('Email can not be empty');
    } else if (!validateEmail(this.state.email.trim())) {
      toastr.error('Email is not valid');
    } else if (this.state.password === '') {
      toastr.error('Password can not be empty');
    } else {
      this.setState({ loading: true });
      let data = {
        email: this.state.email,
        password: this.state.password,
      };
      this.props.loginUser(data, this.loginCallBack);
      this.props.getSkills();
    }
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: 'white',
          height: '100%',
          width: '100%',
        }}
      >
        <TopBar />
        <React.Fragment>
          <div className="back-to-home rounded d-none d-sm-block"></div>

          <section
            className="cover-user bg-white d-flex "
            style={{
              paddingTop:
                this.state.width > 1400
                  ? 200
                  : this.state.width > 1200
                    ? 100
                    : 0,
            }}
          >
            <div className="container-fluid">
              <Row className="position-relative">
                <Col lg={6} className="cover-my-30 order-2 md_LoginForm">
                  <div className="cover-user-img d-flex align-items-center">
                    <div className="login-page ">
                      <div className="text-center">
                        <h4 className="mb-4" style={{
                          color: '#000000',
                          fontWeight: 800,
                          fontSize: 25,
                          fontFamily: 'Montserrat'
                        }}>
                          Employer Login
                        </h4>
                      </div>
                      <form className="login-form">
                        <Row>
                          <Col lg={12}>
                            <div className="form-group position-relative">
                              <label>
                                Your Email{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <i className="mdi mdi-account ml-3 icons"></i>
                              <input
                                value={this.state.email}
                                onChange={this.getValue}
                                type="email"
                                className="form-control pl-5"
                                placeholder="Email"
                                name="email"
                                required=""
                              />
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="form-group position-relative">
                              <label>
                                Password <span className="text-danger">*</span>
                              </label>
                              <i className="mdi mdi-key ml-3 icons"></i>
                              {
                        this.state.passwordShowToggle ? <VisibilityOffIcon style={{
                          position: "absolute",
                          top: 42,
                          marginRight: 10,
                          right: 0,
                          lineHeight: "100px",
                          fontSize: "18px",
                          color: "black",
                        }}
                          onClick={() => this.setState({ passwordShowToggle: !this.state.passwordShowToggle })}
                        />
                          : <VisibilityIcon style={{
                            position: "absolute",
                            top: 42,
                            marginRight: 10,
                            right: 0,
                            lineHeight: "100px",
                            fontSize: "18px",
                            color: "black",
                          }}
                            onClick={() => this.setState({ passwordShowToggle: !this.state.passwordShowToggle })}

                          />
                      }
                              <input
                                value={this.state.password}
                                onChange={this.getValue}
                                type={this.state.passwordShowToggle ? "text" : "password"}
                                name="password"
                                className="form-control pl-5"
                                placeholder="Password"
                                required=""
                              />
                            </div>
                          </Col>

                          <Col lg={12}>
                            <p
                              className="float-right forgot-pass"
                              style={styles.mt_3}
                            >
                              <Link
                                className="text-dark font-weight-bold"
                                to="/forget-password"
                              >
                                {/* // <a
                                //   href="page-cover-re-password"
                                //   className="text-dark font-weight-bold"
                                // > */}
                                Forgot password ?{/* </a> */}
                              </Link>
                            </p>
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                {/* <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck1"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck1"
                                  >
                                    Remember me
                                  </label> */}
                              </div>
                            </div>
                          </Col>

                          <Col lg={12} className="mb-0">
                            <button
                              onClick={this.onUserLogin}
                              type="button"
                              className="btn btn-primary w-100"
                            >
                              {this.state.loading ? (
                                <BeatLoader
                                  css={override}
                                  sizeUnit={'px'}
                                  size={10}
                                  color={'white'}
                                  loading={true}
                                />
                              ) : (
                                'Sign in'
                              )}
                            </button>
                          </Col>

                          <Col className="text-center">
                            <p className="mb-0 mt-3">
                              <small className="text-dark mr-2">
                                Don't have an account ?
                              </small>{' '}
                              <Link
                                to="/signup"
                                className="text-dark font-weight-bold"
                              >
                                Sign Up
                              </Link>
                            </p>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </div>
                </Col>

                <RightImage signupImage={loginImage} />
                {/* <Col
                lg={8}
                className="offset-lg-4 padding-less img order-1"
                style={{ backgroundImage: `url(${loginImage})` }}
              ></Col> */}
              </Row>
            </div>
          </section>
        </React.Fragment>
        {this.state.width < 500 ? (
          <Footer removeContent />
        ) : (
          <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
            <Footer removeContent />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(mapStateToProps, {
  loginUser,
  getSkills,
})(PageCoverLogin);
