// React Basic and Bootstrap
import React, { Component, memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import {
  registerUser,
  sendOtpEmail,
  sendVCode,
  verifyEmailOtp,
  verifyUserCode,
} from '../../redux/actions';
// import { getSkills } from "../../../commonRedux/jobs/action";
import toastr from 'toastr';
import url from '../../../../config/endpoint';
import { CircularProgress } from '@material-ui/core';
import signupImage from '../../../../assets/signup.webp';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import 'react-phone-number-input/style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import { validateEmail } from '../../../../utils/emailValidation';
import { styles } from '../../style';
import useDimenstion from '../../useDimension';
import Countdown, { zeroPad } from 'react-countdown';
import Footer from '../../../layout/authLayout/Footer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TopBar from '../../../layout/authLayout/Topbar';
import { Button } from '@material-ui/core';
import OTPInput from 'react-otp-input';
import { colors } from '../../../../commonComponents/colors';
import ReactPlayer from 'react-player';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import { sendSupportMessage } from '../../../profile/redux/action';
import Loader from '../../../account/loader';
const renderer = ({ minutes, seconds }) => (
  <span style={{ width: 70 }}>
    {zeroPad(minutes)}:{zeroPad(seconds)}
  </span>
);
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const CodeVerifyCompoenents = memo(
  ({
    type,
    handleVCode,
    handleConfirm,
    onClose,
    handleEmailBlur,
    email,
    phone,
  }) => {
    const [vCode, setVCode] = useState('');
    const [notMatch, setNotMacth] = useState(false);
    const [showTimer, setTimer] = useState(false);

    const onChange = (e) => {
      setVCode(e.target.value);
      handleVCode(e);
    };

    const setTimerHandler = () => {
      setTimer(!showTimer);
    };

    return (
      <div>
        <label>
          We sent a 4-digit verification code to{' '}
          {type === 'email' ? email : phone} <br />
          This code is valid for 30 minutes.
        </label>
        <div
          className="d-flex"
          style={{
            border: '1px solid #e6e6e6',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 10,
            paddingTop: 1,
            paddingBottom: 1,
            marginBottom: 5,
          }}
        >
          <i
            style={{ fontSize: 18 }}
            className="mdi mdi-account ml-3 icons"
          ></i>
          <input
            style={{
              fontSize: 13,
            }}
            type="number"
            className="form-control border-0 w-100"
            placeholder="Verification Code"
            onChange={onChange}
            value={vCode}
          />
          <button
            onClick={() => handleConfirm(type, onClose, setNotMacth)}
            type="button"
            style={{
              backgroundColor: '#4267B2',
              color: 'white',
              border: 'none',
              fontSize: 16,
              width: '40%',
              height: '40px',
              borderRadius: 5,
            }}
          >
            Verify
          </button>
        </div>
        {notMatch ? (
          <div style={styles.textAlignCenter}>Incorrect verification code.</div>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {showTimer ? (
            <Countdown
              onComplete={setTimerHandler}
              date={Date.now() + 60000}
              renderer={renderer}
            />
          ) : (
            <a
              onClick={() => {
                handleEmailBlur();
                setTimerHandler();
              }}
              className="ml-1"
            >
              Resend
            </a>
          )}
        </div>
      </div>
    );
  },
);

const RightSection = ({ isOtp, signupImage, videoLinks }) => {
  const width = useWindowWidth();
  const isMobile = width < 540;
  const isMedium = width <= 991;
  const [formName, setFormName] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [videoID, setVideoID] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const { videoLinksStatus } = useSelector((state) => state.fileScanReducer);
  useEffect(() => {
    setVideoID(videoLinks?.OutplacementServices?.videoID);
  }, [videoLinks]);
  const handleSubmitForm = async () => {
    if (formName === '') {
      toastr.error('Please enter name');
      return;
    }
    if (formEmail === '') {
      toastr.error('Please enter email');
      return;
    } else if (!validateEmail(formEmail)) {
      toastr.error('Please enter valid email');
      return;
    }

    if (formMessage === '') {
      toastr.error('Please enter message');
      return;
    }
    if (formEmail.length && validateEmail(formEmail)) {
      const files = new FormData();
      files.append(
        'data',
        JSON.stringify({
          name: formName,
          message: formMessage,
          email: formEmail,
          from: 'Web - Employer Dashboard',
        }),
      );
      try {
        setFormLoading(true);
        const res = await axios.post(`${url}/customer-support`, files);

        if (res.data.status === 200) {
          toastr.success(
            'Thanks for contacting us. We will get back to you as soon as possible',
          );
        }
      } catch (error) {
        toastr.error(error);
      } finally {
        setFormName('');
        setFormMessage('');
        setFormEmail('');
        setFormLoading(false);
      }
    }
  };
  if (isOtp && width < 1000 && width > 700) {
    return null;
  }

  return isOtp ? (
    <Col
      lg={6}
      className="offset-lg-6 padding-less img order-1"
      style={{
        backgroundImage: `url(${signupImage})`,
        backgroundSize: 'contain',
      }}
    ></Col>
  ) : (
    <Col
      lg={6}
      md={12}
      // style={{ backgroundColor: 'red' }}
    >
      <div
        style={{
          ...(!isMedium && { marginRight: 70 }),
          ...(!isMobile && isMedium && { margin: '-50px 25px 0px 25px' }),
        }}
      >
        <div className="text-center" style={styles.mt_15}></div>
        <div>
          <div
            style={{
              position: 'relative',
              ...(isMedium && { paddingTop: '56.25%' }),
              ...(!isMedium && { height: 385 }),
            }}
          >
            {videoLinksStatus ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Loader size={40} />
              </div>
            ) : (
              videoID && (
                <ReactPlayer
                  url={`https://player.vimeo.com/video/${videoID}`}
                  width={'100%'}
                  height={'100%'}
                  style={{
                    ...(isMedium && {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }),
                    width: '100%',
                    height: '100%',
                  }}
                  config={{
                    playerOptions: {
                      autoplay: videoLinks?.OutplacementServices?.autoPlay,
                      loop: videoLinks?.OutplacementServices?.loop,
                      autopause: false,
                      muted: videoLinks?.OutplacementServices?.muted,
                      controls: videoLinks?.OutplacementServices?.showControls,
                    },
                    vimeo: {
                      title: 'Resumás',
                    },
                  }}
                  onEnded={() => {
                    // setOpen(false);
                    // onEnded();
                  }}
                />
              )
            )}
          </div>

          <div
            style={{ backgroundColor: '#E9E9E9', marginTop: 15, padding: 10 }}
          >
            <Row>
              <Col lg={6} md={12}>
                <p style={{ margin: 0, fontWeight: 'bold', color: '#000000' }}>
                  Name
                </p>
                <input
                  placeholder="Name"
                  style={{ width: '100%', padding: '4px 10px' }}
                  onChange={(e) => setFormName(e.target.value)}
                  value={formName}
                />
              </Col>
              <Col lg={6} md={12}>
                <p style={{ margin: 0, fontWeight: 'bold', color: '#000000' }}>
                  Email Address
                </p>
                <input
                  placeholder="Email Address"
                  style={{ width: '100%', padding: '4px 10px' }}
                  onChange={(e) => setFormEmail(e.target.value)}
                  value={formEmail}
                />
              </Col>
            </Row>
            <div
              style={{
                marginTop: 10,
              }}
            >
              <p style={{ margin: 0, fontWeight: 'bold', color: '#000000' }}>
                Message
              </p>
              <textarea
                placeholder="Message"
                style={{
                  width: '100%',
                  border: 'none',
                  paddingLeft: 10,
                }}
                value={formMessage}
                onChange={(e) => setFormMessage(e.target.value)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 10,
              }}
            >
              <button
                disabled={formLoading}
                style={{
                  backgroundColor: colors.blue,
                  width: 80,
                  height: 35,
                  color: colors.white,
                  borderRadius: 5,
                }}
                onClick={handleSubmitForm}
              >
                {formLoading ? (
                  <CircularProgress
                    size={24}
                    style={{
                      color: '#FFFFFF',
                    }}
                  />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

class PageCoverSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phone: '',
      password: '',
      repassword: '',
      confirmPasswordShowToggle: false,
      passwordShow: false,
      showPMessage: false,
      showEMessage: false,
      isEmailVerified: false,
      isPhoneVerified: false,
      vCode: '',
      notMatch: false,
      isTerms: false,
      planChoosed: 0,
      loading: false,
      ipData: '',
      otpState: false,
      otp: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleVCode = (e) => {
    this.setState({
      vCode: e.target.value,
    });
  };

  handleChange(value) {
    this.setState({ selectedValue: value.target.id });
  }

  handleChangeTerms = () => {
    this.setState({ isTerms: !this.state.isTerms });
  };

  resendMail = () => {
    if (validateEmail(this.state.email)) {
      this.props.sendVCode('email', this.state.email);
      this.setState({
        showEMessage: true,
        canSendEmail: false,
      });
    }
  };

  async componentDidMount() {
    const plan =
      new URLSearchParams(this.props.location.search).get('plan') || 0;
    if (parseInt(plan) === 1) {
      this.setState({ planChoosed: 1 });
    }
    try {
      const userIp = await axios.get(
        'https://www.cloudflare.com/cdn-cgi/trace',
      );

      this.setState({ ipData: userIp?.data });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate() {
    if ((Object.keys(this.props.user ?? {}) || [])?.length) {
      this.props.history.push('/app');
    }
  }

  handlePhone = (phone) => {
    this.setState({
      phone,
      isPhoneVerified: false,
    });
  };

  handleEmail = (e) => {
    this.setState({
      email: e.target.value,
      isEmailVerified: false,
    });
  };

  // submit = (type) => {
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <CodeVerifyCompoenents
  //           handleVCode={this.handleVCode}
  //           type={type}
  //           handleEmailBlur={this.handleEmailBlur}
  //           handleConfirm={this.handleConfirm}
  //           onClose={onClose}
  //         />
  //       );
  //     },
  //   });
  // };

  handleConfirm = () => {
    let vCode = this.state.vCode;
    vCode = parseInt(vCode);
    this.setState({
      verificationLoading: true,
    });
    this.props.verifyUserCode(this.state.email, vCode, () => {
      this.setState({
        verificationLoading: false,
      });
    });
  };

  handleOtpSend = async () => {
    try {
      if (validateEmail(this.state.email)) {
        return await sendOtpEmail(this.state.email);
      } else {
        toastr.error('Please enter valid email');
      }
    } catch (error) {
      return error;
    }
  };

  handlePhoneBlur = () => {
    if (this.state.phone && this.state.phone.length) {
      this.props.sendVCode('mobile', this.state.phone);
      this.setState({
        showPMessage: true,
      });
    }
  };

  getValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  signUpCallBack = (success) => {
    this.setState({ loading: false });
    if (success) {
      this.props.history.push('/employerLogin');
    }
  };

  handleGotoOtp = async () => {
    if (this.state.firstName === '' || this.state.lastName === '') {
      toastr.error('firstname and lastname can not be empty');
    } else if (!validateEmail(this.state.email)) {
      toastr.error('Email is not valid');
    } else if (this.state.companyName === '') {
      toastr.error('Company name can not be empty');
    } else if (this.state.password === '') {
      toastr.success('Password can not be empty');
    } else if (this.state.password.length < 8) {
      toastr.error('Password must be greater than 8 characters');
    } else if (this.state.repassword === '') {
      toastr.error('RE-Password can not be empty');
    } else if (this.state.password !== this.state.repassword) {
      toastr.error('Passwords must be matched');
    } else if (!this.state.isTerms) {
      toastr.error('Please read and agree terms and conditions');
    } else {
      const otpRes = await this.handleOtpSend();
      if (otpRes?.status === 200) {
        toastr.success('Verification code sent successfully');
        this.setState({
          otpState: true,
        });
      } else {
        toastr.error(otpRes.message);
      }
    }
  };

  onUserRegister = async () => {
    try {
      this.setState({
        loading: true,
      });

      const otpRes = await verifyEmailOtp(this.state.email, this.state.otp);

      if (otpRes?.status === 200) {
        this.props.registerUser(
          {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            isStudent: '',
            mobile: '',
            coupon: '',
            companyName: this.state.companyName,
            userType: 'employer',
            userIp: this.state.ipData,
          },
          this.signUpCallBack,
        );
      } else {
        toastr.error(otpRes.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  onPlanChoosed = (planChoosed) => {
    if (planChoosed !== this.state.planChoosed) this.setState({ planChoosed });
  };

  render() {
    return (
      <>
        <TopBar />
        <React.Fragment>
          <div
            className="back-to-home rounded d-none d-sm-block"
            style={{ paddingTop: 70 }}
          ></div>
          <section className="cover-user bg-white">
            <div className="container-fluid">
              {!this.state.otpState ? (
                <h4
                  style={{
                    padding: '25px 0px 10px 0px',
                    fontWeight: 'bold',
                    fontSize: 22,
                    fontFamily: 'Montserrat',
                    marginBottom: 15,
                    textAlign: 'center',
                  }}
                >
                  Resumas Outplacement Services
                </h4>
              ) : (
                ''
              )}
              <Row className="position-relative">
                <Col
                  lg={6}
                  md={12}
                  style={{ paddingBottom: 100 }}
                  className="md-order-2"
                >
                  {this.state.otpState ? (
                    <div
                      style={{
                        // minHeight: '100vh',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          flexDirection: 'column',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          maxWidth: 500,
                          padding: 20,
                        }}
                      >
                        <div>
                          <h1
                            style={{
                              fontSize: 30,
                              fontWeight: 'bold',
                              color: 'black',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Verify Email
                          </h1>
                        </div>
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              color: colors.fontGray,
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Almost there! An email containing verification code
                            was sent to {this.state.email}.
                          </p>
                        </div>
                        <div>
                          {/* OTP input */}

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              marginTop: 40,
                            }}
                          >
                            <OTPInput
                              value={this.state.otp}
                              onChange={(otp) => this.setState({ otp })}
                              numInputs={4}
                              renderSeparator={
                                <span
                                  style={{
                                    width: 20,
                                  }}
                                >
                                  &nbsp;
                                </span>
                              }
                              renderInput={(props) => <input {...props} />}
                              inputStyle={{
                                width: 50,
                                height: 50,
                                borderRadius: 5,
                                border: 'none',
                                fontSize: 20,
                                fontWeight: 'bold',
                                color: '#000000',
                                backgroundColor: '#F5F5F5',
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                              }}
                              inputType="number"
                            />
                          </div>
                        </div>
                        <div>
                          <p
                            style={{
                              fontSize: 15,
                              color: colors.fontGray,
                              fontFamily: 'Montserrat',
                              marginTop: 70,
                            }}
                          >
                            Didn't receive code? check spam folder or {' '}
                            <span
                              style={{
                                color: colors.blue,
                                cursor: 'pointer',
                              }}
                              onClick={() => this.resendMail()}
                            >
                              Request again
                            </span>
                          </p>
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: 15,
                              color: colors.blue,
                              cursor: 'pointer',
                              fontFamily: 'Montserrat',
                            }}
                            onClick={() => this.setState({ otpState: false })}
                          >
                            Change email here
                          </span>
                        </div>
                        <div>
                          <button
                            onClick={() => this.onUserRegister()}
                            disabled={this.state.loading}
                            type="button"
                            className="btn btn-primary w-fit"
                            style={{
                              marginTop: 40,
                            }}
                          >
                            {this.state.loading ? (
                              <BeatLoader
                                css={override}
                                sizeUnit={'px'}
                                size={10}
                                color={'white'}
                                loading={true}
                              />
                            ) : (
                              'Verify and Create Account'
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="cover-user-img d-flex align-items-center flex-column">
                        <div className="align-self-start" style={styles.mt_15}>
                          <h4
                            style={{
                              paddingBottom: 1,
                              fontWeight: 'bold',
                              fontSize: 22,
                              fontFamily: 'Montserrat',
                            }}
                          >
                            Free SignUp
                          </h4>
                        </div>
                        <Row>
                          <Col style={styles.mb_15}>
                            <div className="login_page">
                              <form className="login-form">
                                <Row>
                                  <Col md={6}>
                                    <div className="form-group position-relative">
                                      <label>
                                        First name{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-account ml-3 icons"></i>
                                      <input
                                        type="text"
                                        className="form-control pl-5"
                                        placeholder="First Name"
                                        onChange={this.getValue}
                                        value={this.state.firstName}
                                        name="firstName"
                                        required=""
                                      />
                                    </div>
                                  </Col>

                                  <Col md={6}>
                                    <div className="form-group position-relative">
                                      <label>
                                        Last name{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-account ml-3 icons"></i>
                                      <input
                                        type="text"
                                        className="form-control pl-5"
                                        placeholder="Last Name"
                                        onChange={this.getValue}
                                        value={this.state.lastName}
                                        name="lastName"
                                        required=""
                                      />
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    <div className="form-group position-relative">
                                      <label>
                                        Company Name{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-office-building ml-3 icons"></i>
                                      <input
                                        type="text"
                                        className="form-control pl-5"
                                        placeholder="Company Name"
                                        name="companyName"
                                        value={this.state.companyName}
                                        onChange={this.getValue}
                                        required=""
                                      />
                                    </div>
                                  </Col>

                                  <Col md={12}>
                                    <div className="form-group position-relative">
                                      <label>
                                        Your Email{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-account ml-3 icons"></i>
                                      <input
                                        type="email"
                                        className="form-control pl-5"
                                        placeholder="Email"
                                        // onBlur={this.handleEmailBlur}
                                        onChange={this.handleEmail}
                                        value={this.state.email}
                                        name="email"
                                        required=""
                                      />
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    <div className="form-group position-relative">
                                      <label>
                                        Password{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-key ml-3 icons"></i>
                                      {this.state.passwordShow ? (
                                        <VisibilityOffIcon
                                          style={{
                                            position: 'absolute',
                                            top: 42,
                                            marginRight: 10,
                                            right: 0,
                                            lineHeight: '100px',
                                            fontSize: '18px',
                                            color: 'black',
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              passwordShow:
                                                !this.state.passwordShow,
                                            })
                                          }
                                        />
                                      ) : (
                                        <VisibilityIcon
                                          style={{
                                            position: 'absolute',
                                            top: 42,
                                            marginRight: 10,
                                            right: 0,
                                            lineHeight: '100px',
                                            fontSize: '18px',
                                            color: 'black',
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              passwordShow:
                                                !this.state.passwordShow,
                                            })
                                          }
                                        />
                                      )}
                                      <input
                                        type={
                                          this.state.passwordShow
                                            ? 'text'
                                            : 'password'
                                        }
                                        className="form-control pl-5"
                                        placeholder="Password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.getValue}
                                        required=""
                                      />
                                    </div>
                                  </Col>

                                  <Col md={12}>
                                    <div className="form-group position-relative">
                                      <label>
                                        Confirm Password{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <i className="mdi mdi-key ml-3 icons"></i>
                                      {this.state.confirmPasswordShowToggle ? (
                                        <VisibilityOffIcon
                                          style={{
                                            position: 'absolute',
                                            top: 42,
                                            marginRight: 10,
                                            right: 0,
                                            lineHeight: '100px',
                                            fontSize: '18px',
                                            color: 'black',
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              confirmPasswordShowToggle:
                                                !this.state
                                                  .confirmPasswordShowToggle,
                                            })
                                          }
                                        />
                                      ) : (
                                        <VisibilityIcon
                                          style={{
                                            position: 'absolute',
                                            top: 42,
                                            marginRight: 10,
                                            right: 0,
                                            lineHeight: '100px',
                                            fontSize: '18px',
                                            color: 'black',
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              confirmPasswordShowToggle:
                                                !this.state
                                                  .confirmPasswordShowToggle,
                                            })
                                          }
                                        />
                                      )}
                                      <input
                                        type={
                                          this.state.confirmPasswordShowToggle
                                            ? 'text'
                                            : 'password'
                                        }
                                        className="form-control pl-5"
                                        placeholder="Confirm Password"
                                        name="repassword"
                                        onChange={this.getValue}
                                        value={this.state.repassword}
                                        required=""
                                      />
                                    </div>
                                  </Col>

                                  <Col md={12} style={{ marginTop: 10 }}>
                                    <div className="form-group">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          checked={this.state.isTerms}
                                          className="custom-control-input"
                                          id="customCheck1"
                                          onChange={this.handleChangeTerms}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="customCheck1"
                                          style={styles.pt_3}
                                        >
                                          I agree to Resumas{' '}
                                          <Link
                                            to="/terms-of-services"
                                            className="text-primary"
                                          >
                                            Terms of services{' '}
                                          </Link>
                                          and{' '}
                                          <Link
                                            to="/page-privacy"
                                            className="text-primary"
                                          >
                                            Privacy Policy
                                          </Link>
                                        </label>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col
                                    md={12}
                                    style={{ margin: '0px 0px 15px 0px' }}
                                  >
                                    <button
                                      onClick={() => this.handleGotoOtp()}
                                      type="button"
                                      className="btn btn-primary w-100"
                                    >
                                      Register
                                    </button>
                                  </Col>

                                  <div className="mx-auto">
                                    <p className="mb-0">
                                      <small className="text-dark mr-2">
                                        Already have an account ?
                                      </small>
                                      <Link
                                        to="/employerLogin"
                                        className="text-dark font-weight-bold"
                                      >
                                        Sign in
                                      </Link>
                                    </p>
                                  </div>
                                </Row>
                              </form>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </Col>
                {/* <Col
                  lg={6}
                  md={12}
                  // style={{ backgroundColor: 'red' }}
                > */}
                <RightSection
                  signupImage={signupImage}
                  isOtp={this.state.otpState}
                  videoLinks={this.props.videoLinks}
                />
                {/* </Col> */}
              </Row>
            </div>
          </section>
          <Footer removeContent />
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = ({ authUser, fileScanReducer }) => {
  const { user, loading, pVCode, eVCode } = authUser;
  const { videoLinks } = fileScanReducer;
  return { user, loading, pVCode, eVCode, videoLinks };
};

export default connect(mapStateToProps, {
  registerUser,
  // getSkills,
  verifyUserCode,
  sendVCode,
})(PageCoverSignup);
