import React, { useState, useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
//eslint-disable-next-line
import { useStyles } from './style';
import { useSelector, useDispatch } from 'react-redux';
import StepButton from '@material-ui/core/StepButton';
import { GetStepContent } from './helper/stepper';
import { formStepsIndexes, formStepsKey } from './constants/steps';
import Chip from '@material-ui/core/Chip';
import { Tooltip } from 'react-tippy';
import { onBlurField } from '../redux/actions';
import { useWindowSize } from '@react-hook/window-size';
import { GetStepImage } from '../../../utils/GetStepImage.js';
import { ColorlibConnector, ColorlibStepIcon } from './stepperConfig';
import { colors } from '../../../commonComponents/colors';

export default function FormBuilder({ handleSave }) {
  const dispatch = useDispatch();
  let { resumeData, updater } = useSelector((state) => state.editorReducer);
  const [width] = useWindowSize();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeForm, setActiveForm] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [steps, setSteps] = React.useState([]);

  function getList() {
    let list = [];
    formStepsKey.forEach((item) => {
      if (resumeData[item]) {
        const currentResumeData = resumeData[item];
        let i = {
          label: resumeData[item]?.heading,
          index: formStepsIndexes[item],
          visible: resumeData[item].visible,
        };
        if (i.label === 'Professional Objective') {
          i = {
            ...i,
            label: 'Summary',
          };
        }
        list.push(i);
      }
    });
    setSteps([...list]);
  }

  React.useEffect(() => {
    getList();
  }, [resumeData]);

  const visibleSteps = steps.filter((step) => step.visible);
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  useEffect(() => {
    const savedIndex = localStorage.getItem('builderIndex');
    const savedFormIndex = localStorage.getItem('formIndex');
    if (savedIndex) {
      setActiveStep(Number(savedIndex));
      setActiveForm(Number(savedFormIndex));
    }
  }, []);

  const handleNext = () => {
    handleSave(true);
    if (activeStep + 1 === visibleSteps?.length) {
      // handleSave(true);
      localStorage.setItem('builderPage', 'editor');
      dispatch({
        type: 'GET_RESUME_BUILDER_CHOICE',
        payload: 'editor',
      });
    } else {
      // handleSave(false);
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep(activeStep + 1);
      setActiveForm(visibleSteps[activeStep + 1].index);
      localStorage.setItem('builderIndex', activeStep + 1);
      localStorage.setItem('formIndex', activeForm + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // setActiveForm((prevActiveForm) => prevActiveForm - 1);
    setActiveStep(activeStep - 1);
    setActiveForm(visibleSteps[activeStep - 1].index);
    localStorage.setItem('builderIndex', activeStep - 1);
    localStorage.setItem('formIndex', activeForm - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (data) => {
    const objIndex = steps.findIndex((obj) => obj.label === data);
    steps[objIndex].visible = !steps[objIndex].visible;
    dispatch(
      onBlurField(steps[objIndex].visible, `${formStepsKey[objIndex]}.visible`),
    );
    setSteps([...steps]);
  };
  return (
    <div
      className={classes.root}
      style={{
        marginTop: width > 1000 ? '5%' : '10%',
      }}
    >
      {/* <style jsx="true">
        {`
          .MuiChip-colorPrimary {
            background-color: ${colors.blue};
          }
          // .MuiStepLabel-completed .MuiStepLabel-label {
          //   color: ${colors.green} !important;
          // }
        `}
      </style> */}

      <div
        style={{ marginTop: width < 600 ? 100 : 30 }}
        className={`${classes.stepper} ${classes.choiceChipList} ${classes.stepperScroll}`}
      >
        <GetStepImage
          step={1}
          style={{
            marginLeft: -20,
            marginTop: -28,
            zIndex: 99,
            position: 'absolute',
          }}
        />
        {steps?.map((item, index) => {
          return (
            <Tooltip
              key={index}
              title={
                item.visible
                  ? 'That blue section already in resume'
                  : 'Option section can be edit( click on it to add )'
              }
              arrow
              distance={50}
            >
              <Chip
                label={item.label}
                clickable
                color={item.visible ? 'primary' : 'default'}
                className={classes.choiceChip}
                onClick={() => {
                  if (item.label === 'Profile') {
                    return;
                  } else {
                    handleChange(item.label);
                  }
                }}
              />
            </Tooltip>
          );
        })}
      </div>
      <Stepper
        activeStep={activeStep}
        className={`${classes.stepper} ${classes.stepperScroll}`}
        connector={<ColorlibConnector />}
      >
        {visibleSteps?.map((item, index) => {
          return (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <StepButton
                  onClick={() => {
                    setActiveStep(index);
                    setActiveForm(item.index);
                    localStorage.setItem('builderIndex', index);
                    localStorage.setItem('formIndex', item.index);
                  }}
                  style={{
                    overflow: 'hidden',
                    width: '100%',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Tooltip
                    arrow
                    distance={30}
                    title={
                      // eslint-disable-next-line
                      index === activeStep
                        ? 'Currently working here'
                        : index < activeStep
                        ? 'Section already in the resume'
                        : 'Section can be edit( click on it to add )'
                    }
                  >
                    <b>{item.label}</b>
                  </Tooltip>
                </StepButton>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div className={classes.container}>
        {activeStep === steps.length ? (
          <div>
            <div className={classes.instructions}>
              All steps completed - you&apos;re finished
            </div>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <div
              style={{
                marginTop: -45,
                marginLeft: -47,
              }}
            >
              <GetStepImage step={2} />
            </div>
            <div className={classes.instructions}>
              {GetStepContent(
                activeForm,
                classes,
                resumeData,
                updater,
                dispatch,
                steps,
              )}
            </div>
          </div>
        )}

        <div className={classes.buttonContainer}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            className={`${classes.button} ${classes.btnRight}`}
          >
            {activeStep + 1 === visibleSteps?.length ? 'Finish' : 'Next '}
          </Button>
        </div>
      </div>
    </div>
  );
}
