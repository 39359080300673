import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import { Button } from 'antd';
import { updateLinkedinData } from '../redux/actions';
import CloseIcon from '@material-ui/icons/Close';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Loader from 'react-loader-spinner';
import Popup from 'reactjs-popup';
import TagsInput from 'react-tagsinput';
import { useWindowSize } from '@react-hook/window-size';
import { useDispatch, useSelector } from 'react-redux';

export default function Skills(props) {
  const [width] = useWindowSize();
  const [copied, setCopied] = useState([]);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const linkedinData = useSelector(
    (state) => state.linkedinReducer.linkedinData,
  );
  const skillsModal = useSelector((state) => state.linkedinReducer.skillsModal);

  const [showMore, setShowMore] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState('');
  const dispatch = useDispatch();

  const skillsData =
    showMore === false && props.skillsData?.length > 5
      ? props.skillsData.slice(0, 5)
      : props.skillsData || [];

  useEffect(() => {
    setSkills(props.skillsData?.length > 0 ? props.skillsData : []);
  }, [props.skillsData]);

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const cb = (v) => {
    dispatch({ type: 'SKILLS_MODAL', payload: false });
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Skills Updated');
    } else {
      toastr.error('Error Updating Experience');
    }
  };
  const handleUpdate = () => {
    setLoading(true);
    let data = {
      ...linkedinData,
      skills: skills,
    };
    dispatch(updateLinkedinData(data, cb));
  };
  const clearCopied = () => {
    setCopied([]);
  };
  const deleteSkill = (item) => {
    setLoading(true);
    let newSkills = skills.filter((skill) => skill !== item);
    let data = {
      ...linkedinData,
      skills: newSkills,
    };
    dispatch(updateLinkedinData(data, cb));
  };
  return (
    <Card body className="text-left mb-4" style={styles.border_radius_5}>
      {skillsData?.length > 0
        ? skillsData?.map((item, index) => {
            return (
              <Row key={index} className="mt-2">
                <Col
                  xs={10}
                  lg={11}
                  xl={11}
                  md={11}
                  className="p-2 border-bottom d-flex align-items-center  "
                >
                  <p style={styles.title}>{item}</p>
                </Col>
                <Col
                  xs={2}
                  lg={1}
                  xl={1}
                  md={1}
                  className="p-2 border-bottom d-flex align-items-center justify-content-center "
                >
                  {!copied.includes(item) ? (
                    props.stepIndex === 2 && (
                      <CopyToClipboard
                        text={item}
                        onCopy={() => {
                          toastr.success('Skill Copied!');

                          handleCopied(item);
                        }}
                      >
                        <FileCopyIcon
                          style={{ fontSize: 16 }}
                          className="hovdel"
                        />
                      </CopyToClipboard>
                    )
                  ) : (
                    <CheckIcon style={{ color: 'green', fontSize: 16 }} />
                  )}
                  {props.stepIndex === 1 && (
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedSkill(item);
                        deleteSkill(item);
                      }}
                    >
                      {loading && selectedSkill === item ? (
                        <Loader
                          type="TailSpin"
                          color="black"
                          height={15}
                          width={15}
                        />
                      ) : (
                        <RemoveCircleOutlineIcon
                          style={{ fontSize: 20, marginLeft: 10 }}
                        />
                      )}
                    </span>
                  )}
                </Col>
              </Row>
            );
          })
        : 'No Skills Available'}
      <span
        onClick={() => setShowMore(!showMore)}
        style={{
          width: '100%',

          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '10px',
          cursor: 'pointer',
          fontWeight: 'bold',
          marginTop: '10px',
        }}
      >
        {skills?.length > 5 ? (!showMore ? 'Show More' : 'Show Less') : ''}
        {/* {!showMore ? "Show More" : "Show Less"} */}
      </span>
      <Popup
        open={skillsModal}
        onClose={() => {
          clearCopied();
          dispatch({ type: 'SKILLS_MODAL', payload: false });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
        }}
        modal
        position="center"
        closeOnDocumentClick={false}
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({ type: 'SKILLS_MODAL', payload: false });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5 style={{ fontSize: 23 }}>Skills</h5>

        <Row>
          <Col>
            <TagsInput
              style={styles.tagInput}
              value={skills}
              onChange={(tags) => {
                setSkills(tags);
              }}
              addOnBlur
              inputProps={{
                id: 'title',
                placeholder: 'Add Skills',
                className: 'react-tagsinput-input',
              }}
            />
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={handleUpdate}
                className="mt-4"
                loading={loading}
                style={{ backgroundColor: '#4267b2', color: 'white' }}
                shape="round"
                size={'large'}
              >
                {loading ? 'Saving' : 'Save'}
              </Button>
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 16,
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
  },
};
