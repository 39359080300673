import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import { styles } from "./style";

export default function TopStat({ scores }) {
  return (
    <div>
      <div style={styles.p_10}>
        <div style={styles.topStatCOntainer}>

          <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', alignItems: 'left' }}>
            <div>
              Readability Score = {scores ? scores.flesch_reading_ease : 0}
              <Tooltip
                title="The Flesch Reading Ease score gives easy readability measure between 1 and 100.
              How to Improve Score?
              Reduce Sentence Length and average # of words in a sentence. Also Reduce # of syllables in a word."
                arrow
              >
                <HelpIcon style={styles.cursorPointer} />
              </Tooltip>
            </div>
            <div>
              Reading Time = {scores?.counts.reading_time}
              <Tooltip
                title="The time it would take for the average person to read this resume at a rate of 225 words per minute"
                arrow
              >
                <HelpIcon style={styles.cursorPointer} />
              </Tooltip>
            </div>
          </div>
          <div>
            Number of pages in resume ={" "}
            {scores?.counts?.total_page_count
              ? scores?.counts?.total_page_count
              : (parseInt(scores?.counts.word_count || 0) / 350).toFixed(0)}
          </div>

        </div>
      </div>
      <div style={styles.table}>
        <div style={styles.col}>
          <p>Tone = {scores?.counts.tone}</p>
          <p>Character Count = {scores?.counts.letter_count}</p>
          <p>Word Count = {scores?.counts.word_count}</p>
        </div>
        <div style={{ ...styles.col, ...styles.borderLeft }}>
          <p>Sentiment = {scores?.sentiment}</p>
          <p>Sentence Count = {scores?.counts.sentence_count}</p>
          <p>Paragraph Count = {scores?.counts.paragraph_count}</p>
        </div>
      </div>
    </div>
  );
}
