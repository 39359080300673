import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import uuid from 'react-uuid';
import CreateIcon from '@material-ui/icons/Create';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateLinkedinData } from '../redux/actions';

import { useWindowSize } from '@react-hook/window-size';
import moment from 'moment';

export default function Education(props) {
  const [width] = useWindowSize();
  const [copied, setCopied] = useState([]);
  const [institution, setInstitution] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [degree, setDegree] = useState('');
  const [field, setField] = useState('');
  const [grade, setGrade] = useState('');
  const [summary, setSummary] = useState('');
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const linkedinData = useSelector(
    (state) => state.linkedinReducer.linkedinData,
  );
  const educationModal = useSelector(
    (state) => state.linkedinReducer.educationModal,
  );
  const educationModalMode = useSelector(
    (state) => state.linkedinReducer.educationModalMode,
  );
  useEffect(() => {
    if (educationModalMode === 'new') {
      setId(uuid());

      setInstitution('');
      setStartDate('');
      setEndDate('');
      setDegree('');
      setField('');
      setGrade('');
      setSummary('');
    }
  }, [educationModalMode]);

  const cb = (v) => {
    dispatch({
      type: 'EDUCATION_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Education Updated');
    } else {
      toastr.error('Error Updating Education');
    }
  };
  const handleUpdate = () => {
    setLoading(true);

    let educationHistory = linkedinData?.educationHistory?.filter(
      (item) => item.id !== id,
    );

    const newEduHist = {
      institution,
      startDate,
      endDate,
      degree,
      field,
      description: summary,
      grade,
      summary,
    };
    educationHistory.push(newEduHist);

    let data = { ...linkedinData, educationHistory };

    dispatch(updateLinkedinData(data, cb));
  };

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };

  return (
    <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
      {props.educatioData?.length
        ? props.educatioData.map((item, index) => {
            return (
              <Row key={index} className="mt-2">
                <Col
                  xs={10}
                  lg={11}
                  xl={11}
                  md={11}
                  className="border-bottom p-5"
                >
                  <p style={styles.title}>{item?.institution}</p>
                  <p style={styles.sub}>{item?.degree}</p>
                  <p style={styles.years}>Start Date : {item?.startDate && moment(item?.startDate).format("MM/DD/YY")}</p>
                  <p style={styles.years}>End Date : {item?.endDate && moment(item?.endDate).format("MM/DD/YY")}</p>
                </Col>
                <Col
                  xs={2}
                  lg={1}
                  xl={1}
                  md={1}
                  className="border-bottom p-2 d-flex align-items-center justify-content-center"
                >
                  <span
                    style={styles.copyIcon}
                    onClick={() => {
                      setInstitution(item.institution);
                      setId(item.id);
                      setStartDate(item.startDate);
                      setEndDate(item.endDate);
                      setDegree(item.degree);
                      setField(item.field);
                      setGrade(item.grade ? item.grade : '');
                      setSummary(item.summary ? item.summary : '');
                      dispatch({
                        type: 'EDUCATION_MODAL',
                        payload: {
                          open: true,
                          mode: 'edit',
                        },
                      });
                    }}
                  >
                    {props.stepIndex === 1 ? (
                      <CreateIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    ) : (
                      <FileCopyIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    )}
                  </span>
                </Col>
              </Row>
            );
          })
        : 'NO DATA AVAILABLE'}

      <Popup
        open={educationModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'EDUCATION_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'EDUCATION_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5>Education</h5>

        <Row>
          <Col>
            <Label for="exampleEmail">School</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={institution}
                onChange={(e) => setInstitution(e.target.value)}
              />{' '}
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(institution) ? (
                    <CopyToClipboard
                      text={institution}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(institution);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Degree
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(degree) ? (
                    <CopyToClipboard
                      text={degree}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(degree);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Field of study
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={field}
                onChange={(e) => setField(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(field) ? (
                    <CopyToClipboard
                      text={field}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(field);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Row>
              <Col xs={6}>
                <Label style={{ marginTop: 15 }} for="exampleEmail">
                  Start Date
                </Label>
                <InputGroup>
                  <Input
                    type="date"
                    style={{ height: '50px' }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  {props.stepIndex === 2 && (
                    <InputGroupText>
                      {!copied.includes(startDate) ? (
                        <CopyToClipboard
                          text={startDate}
                          onCopy={() => {
                            toastr.success('Text Copied!');
                            handleCopied(startDate);
                          }}
                        >
                          <FileCopyIcon className="hovdel" />
                        </CopyToClipboard>
                      ) : (
                        <CheckIcon style={{ color: 'green' }} />
                      )}
                    </InputGroupText>
                  )}
                </InputGroup>
              </Col>
              <Col xs={6}>
                <Label style={{ marginTop: 15 }} for="exampleEmail">
                  End Date
                </Label>
                <InputGroup>
                  <Input
                    type="date"
                    style={{ height: '50px' }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  {props.stepIndex === 2 && (
                    <InputGroupText>
                      {!copied.includes(endDate) ? (
                        <CopyToClipboard
                          text={endDate}
                          onCopy={() => {
                            toastr.success('Text Copied!');
                            handleCopied(endDate);
                          }}
                        >
                          <FileCopyIcon className="hovdel" />
                        </CopyToClipboard>
                      ) : (
                        <CheckIcon style={{ color: 'green' }} />
                      )}
                    </InputGroupText>
                  )}
                </InputGroup>
              </Col>
            </Row>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Grade
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(grade) ? (
                    <CopyToClipboard
                      text={grade}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(grade);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Description
            </Label>
            <InputGroup>
              <Input
                style={{ height: '150px' }}
                type="textarea"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(summary) ? (
                    <CopyToClipboard
                      text={summary}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(summary);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={loading}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  {loading ? 'Saving' : 'Save'}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};
