import React, { useEffect } from 'react';
import THEME from './config/theme';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Navigation from './navigation';
import axios from 'axios';
import { configureStore } from './commonRedux/store';
import 'antd/dist/antd.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-sweet-progress/lib/style.css';

import 'toastr/build/toastr.min.css';
import 'toastr/build/toastr.min.js';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-tagsinput/react-tagsinput.css';
import 'react-image-lightbox/style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tippy/dist/tippy.css';
import { createBrowserHistory } from 'history';
import sentryInit from './config/sentry';
function App() {
  /* eslint-disable */
  useEffect(() => {
    let getToken = localStorage.getItem('clientAccessToken');

    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }
  }, []);
  //sentry initialization
  const history = createBrowserHistory();
  sentryInit(history);

  return (
    <MuiThemeProvider theme={THEME}>
      <Provider store={configureStore()}>
        <Navigation />
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
