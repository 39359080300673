import 'date-fns';
import React from 'react';
import { Grid } from '@material-ui/core';
//eslint-disable-next-line
import Select from 'react-select';
import { useStyles } from '../style';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import { useDispatch, useSelector } from 'react-redux';
import Accordian from '../helper/Accordian';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
  handleAccordionAction,
} from '../../redux/actions';
import InputText from '../../component/inputText';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { colors } from '../../../../commonComponents/colors';
import RichEditor from '../helper/RichEditor';
import moment from 'moment';
import SolrAutoSuggestion from '../../../../commonComponents/jobSuggestion';
import AutoComplete from '../../../../commonComponents/autoCompleteCompany/AutoCompleteCompany';

const EmployerField = ({ value, onBlur }) => {
  const classes = useStyles();
  const [employer, setEmployer] = React.useState(value);
  return (
    <AutoComplete
      builder={true}
      value={employer}
      placeHolder="Employer"
      customClass={classes.input}
      handleChange={(company) => {
        setEmployer(company);
      }}
      handleSelect={(fullAddress) => {
        const company = fullAddress?.split(',')?.[0] || '';
        setEmployer(company);
      }}
      onBlur={() => {
        onBlur && onBlur(employer);
      }}
    />
  );
};

export default function WorkHistory(props) {
  const classes = useStyles();
  const [places, setPlaces] = React.useState('');
  const [multiLocations, setMultiLocations] = React.useState([]);
  const expanded = useSelector((state) => state.editorReducer.accordion);

  const dispatch = useDispatch();
  const path = 'work.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');

    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  const handleChange = (address) => {
    setPlaces(address);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        if (multiLocations.length) {
          setMultiLocations(() => [...multiLocations]);
        }
        setMultiLocations([results[0].address_components[0].short_name]);
      })

      .catch((error) => console.error('Error', error));
  };
  let { data } = props;
  const handleAccordion = (panel) => (event, isExpanded) => {
    dispatch(handleAccordionAction(panel, isExpanded));
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: 13,
      minHeight: '30px',
      // height: '30px',
      border: state.isFocused ? '0.5px solid #4267b3' : '0.5px solid #ccc',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '0.5px solid #ccc',
        boxShadow: 'none',
      },
      '&:onFocus': {
        border: '0.5px solid #4267b3',
        boxShadow: 'none',
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '43px',
      padding: '0 6px',
    }),
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    manuList: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled ? 'red' : blue,
        color: '#000',
        fontSize: 15,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
  };

  const handledData = (() => {
    if (!data?.length) {
      return [
        {
          position: '',
          company: '',
          startDate: '',
          endDate: '',
          location: '',
        },
      ];
    }

    // Check if data is Array of Strings
    if (typeof data[0] === 'string') {
      return [
        {
          position: '',
          company: '',
          startDate: '',
          endDate: '',
          location: '',
        },
      ];
    }

    return data;
  })();

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Work Experience
          </label>
        </div>
      </Grid>
      <Dnd
        data={handledData}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => {
          const location = {
            label: item.location,
            value: item.location,
          };
          return (
            <Accordian
              // defaultExpanded={false}
              expanded={expanded === index}
              onChange={() => handleAccordion(index)}
              summary={
                <>
                  <label
                    className={
                      item.position || item?.company
                        ? classes.summaryLabel
                        : classes.summaryLabelGrey
                    }
                  >
                    {item.position || item?.company
                      ? item.position + ' at ' + item?.company
                      : 'Add Experience Details'}{' '}
                    {'\n '}
                  </label>
                  <br />
                  <label className={classes.summarySubLabel}>
                    {item?.startDate &&
                      moment(item?.startDate, 'MMMM YYYY').format('MMMM YYYY') +
                      ' - '}{' '}
                  </label>
                  <label className={classes.summarySubLabel}>
                    {item?.endDate
                      ? item.endDate === 'Present'
                        ? 'Present'
                        : moment(item?.endDate, 'MMMM YYYY').format('MMMM YYYY')
                      : ''}{' '}
                  </label>
                </>
              }
            >
              <Grid container spacing={3}>
                <Grid item md={6} lg={6} xl={6} style={{ width: '100%' }}>
                  {/* <div>
                    <label className={classes.label}>
                      Job Title (Seeking or current)
                    </label>
                    <Text
                      value={item.position}
                      placeholder="Job Title"
                      customclass={classes.input}
                      path={`${path}.${index}.position`}
                    />
                  </div> */}
                  <div
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <label className={classes.label}>
                      Job Title (Seeking or current)
                    </label>
                    <SolrAutoSuggestion
                      onChangeTitle={(title) =>
                        dispatch(
                          onBlurField(title, `${path}.${index}.position`),
                        )
                      }
                      value={item.position}
                      titleType="builder"
                    />
                  </div>
                  <div>
                    <label className={classes.label}>Location</label>
                    <PlacesAutocomplete
                      searchOptions={{
                        types: ['(cities)'],
                        // componentRestrictions: { country: 'us' },
                      }}
                      value={places}
                      onChange={handleChange}
                      onSelect={handleSelect}
                    >
                      {({ getInputProps, suggestions, loading }) => (
                        <div style={{ paddingBottom: 16 }}>
                          <Select
                            // isMulti

                            isLoading={loading}
                            options={suggestions?.map((n) => ({
                              label:
                                n?.formattedSuggestion?.mainText +
                                ', ' +
                                n?.formattedSuggestion?.secondaryText,
                              value:
                                n?.formattedSuggestion?.mainText +
                                ', ' +
                                n?.formattedSuggestion?.secondaryText,
                              id: n?.placeId,
                            }))}
                            {...getInputProps({
                              placeholder: 'Select City ',
                              // className: 'location-search-input',
                            })}
                            styles={customStyles}
                            onChange={(tags) => {
                              dispatch(
                                onBlurField(
                                  tags.label,
                                  `${path}.${index}.location`,
                                ),
                              );
                              setMultiLocations(tags);
                            }}
                            value={location}
                            onInputChange={(text) =>
                              getInputProps().onChange({
                                target: { value: text },
                              })
                            }
                          />
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </Grid>
                <Grid item md={6} lg={6} xl={6} style={{ width: '100%' }}>
                  <div
                    style={{
                      marginBottom: 16,
                    }}
                  >
                    <label className={classes.label}>Employer</label>
                    <EmployerField
                      value={item?.company}
                      onBlur={(employer) => {
                        dispatch(
                          onBlurField(employer, `${path}.${index}.company`),
                        );
                      }}
                    />
                    {/* <Text
                      value={item.company}
                      placeholder="Employer"
                      customclass={classes.input}
                      path={`${path}.${index}.company`}
                    /> */}
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div>
                        <label className={classes.label}>Start Date</label>
                        <InputText
                          type="month"
                          placeholder="MM / YYYY"
                          value={item.startDate ?? ''}
                          path={`${path}.${index}.startDate`}
                          customclass={classes.input}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div>
                        <label className={classes.label}>End Date</label>
                        {item.endDate === 'Present' ? (
                          <Text
                            type="text"
                            placeholder="Present"
                            value="Present"
                            disabled={true}
                            editable={false}
                            className={classes.input}
                            style={{
                              border: '1px solid',
                              borderColor: colors.grey,
                              borderRadius: 4,
                              marginBottom: 0,
                            }}
                          />
                        ) : (
                          <InputText
                            type={'month'}
                            placeholder={'MM / YYYY'}
                            value={item.endDate ?? ''}
                            path={`${path}.${index}.endDate`}
                            customclass={classes.input}
                          />
                        )}
                        <div>
                          <label
                            className={classes.label}
                            htmlFor="Current"
                            style={{
                              marginRight: 5,
                            }}
                          >
                            Current
                          </label>
                          {item.endDate !== 'Present' ? (
                            <ImCheckboxUnchecked
                              style={{
                                cursor: 'pointer',
                                // marginLeft: -70,
                              }}
                              size="1.2em"
                              color="#4267B2"
                              onClick={() =>
                                dispatch(
                                  onBlurField(
                                    'Present',
                                    `${path}.${index}.endDate`,
                                  ),
                                )
                              }
                            />
                          ) : (
                            <ImCheckboxChecked
                              style={{
                                cursor: 'pointer',
                                // marginLeft: -70,
                              }}
                              size="1.2em"
                              color="#4267B2"
                              onClick={() =>
                                dispatch(
                                  onBlurField(
                                    moment().format('MMMM YYYY'),
                                    `${path}.${index}.endDate`,
                                  ),
                                )
                              }
                            />
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {/* <Grid item md={6} lg={6} xl={6}>
                  <div>
                    <label className={classes.label}>My Experience Level</label>
                    <SelectTag
                      value={item.expLevel}
                      placeHolder="Select Experience Level"
                      customclass={classes.input}
                      path={`${path}.${index}.expLevel`}
                      options={[
                        'Graduate',
                        'Entry-Level',
                        'Mid-Level',
                        'Senior-Level',
                        'C-Suite',
                      ]}
                    />
                  </div>
                </Grid> */}
                {/* <Grid item md={6} lg={6} xl={6}>
                  <div>
                    <label className={classes.label}>Years of Experience</label>
                    <SelectTag
                      value={item.expYears}
                      placeHolder="Select Years of Experience"
                      customclass={classes.input}
                      path={`${path}.${index}.expYears`}
                      options={['0-9', '10+', '15+', '20+', '25+', '30+']}
                    />
                  </div>
                </Grid> */}
              </Grid>
              <br />
              <label className={classes.label}>Summary</label>
              <RichEditor
                value={item.summary}
                placeHolder="Please write summary of your experience, achievement and skills."
                customclass={`${classes.input} ${classes.summaryInput}`}
                path={`${path}.${index}.summary`}
                onBlurField={onBlurField}
              />
            </Accordian>
          );
        }}
      />
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add Job role & description
      </div>
    </>
  );
}
