import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  DialogTitle,
  Button as ButtonR,
  Select,
  MenuItem,
} from '@material-ui/core';
import toastr from 'toastr';
import CancelIcon from '@material-ui/icons/Cancel';
import { Delete, Edit, Search } from '@material-ui/icons';
import { Input } from 'antd';
import { Card, CardTitle, Table } from 'reactstrap';
import { MdCheck, MdClear } from 'react-icons/md';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { colors } from '../../commonComponents/colors';
import { GetStepImage } from '../../utils/GetStepImage';

import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '20px',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  textStyle: {
    fontSize: '16px',
    fontWeight: 'bolder',
    color: 'black',
  },
  textStyle1: {
    fontSize: '16px',
    fontWeight: 'bolder',
    color: 'black',
    marginTop: 4,
  },
  labelStyle: {
    fontSize: '14px',
    fontWeight: 'bolder',
    color: 'black',
    marginTop: '20px',
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Searches = memo((props) => {
  const {
    saveState,
    setSaveState,
    handleSearchSave,
    savedSearches,
    setAllState,
    searchJobs,
    deleteHandler,
    updateHandler,
    editState,
    setEditState,
    searchName,
    setSearchName,
  } = props;

  const classes = useStyles();
  const open = true;
  const [emailDaily, setEmailDaily] = React.useState(true);
  const [editId, setEditId] = React.useState('');
  const [open1, setOpen1] = React.useState(false);

  const shrinkText = (text) => {
    if (text?.length > 20) {
      return text.slice(0, 20) + '...';
    } else {
      return text;
    }
  };
  const saveSearchHandle = () => {
    handleSearchSave(searchName, emailDaily);
  };
  let [num, setNum] = React.useState('');
  const SearchList = memo(({ list }) => {
    const handleOpen = (id) => {
      return list?.map((item) => {
        if (item.id === id) {
          setOpen1(true);
        }
      });
    };
    const handleClose = () => {
      setOpen1(false);
    };
    const handleSubmit = (id) => {
      deleteHandler(id);
      setOpen1(false);
    };

    const goToTop = () => window.scrollTo(0, 0);
    return (
      <Table
        hover
        responsive
        style={{
          fontSize: '13px',
          margin: 'auto',
          width: '95%',
          fontWeight: 500,
        }}
      >
        <thead>
          <th>
            <p style={{ fontWeight: 700 }}>Search Name</p>
          </th>
          <th>
            <p style={{ fontWeight: 700 }}>Job Title</p>
          </th>
          <th>
            <p style={{ fontWeight: 700 }}>Location</p>
          </th>
          <th>
            <p style={{ fontWeight: 700 }}>Email Daily</p>
          </th>
          <th>
            <p style={{ fontWeight: 700 }}>Actions</p>
          </th>
        </thead>
        <tbody>
          {list?.slice(0, 5).map((item, index) => (
            <tr key={index}>
              <td>{shrinkText(item?.searchName)}</td>
              <td>{item?.jobTitles[0]}</td>
              <td>{shrinkText(item?.location[0]?.label)}</td>
              <td>
                {item?.emailDaily ? (
                  <MdCheck size="1.7em" color={colors.green} />
                ) : (
                  <MdClear size="1.7em" color={colors.red} />
                )}
              </td>
              <td>
                <Tooltip title="Search">
                  <IconButton
                    style={{ color: '#4368b2', margin: '-10px 0 0 -15px' }}
                    onClick={() => {
                      searchJobs(item);
                    }}
                  >
                    {}
                    <Search />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    style={{ color: '#4368b2', margin: '-10px 0 0 -15px' }}
                    onClick={() => {
                      setAllState(item);
                      setEditState(true);
                      setEditId(item.id);
                      setSearchName(item.searchName);
                      setEmailDaily(item.emailDaily);
                      goToTop();
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    style={{ color: colors.red, margin: '-10px 0 0 -15px' }}
                    onClick={() => {
                      setNum(item.id);
                      handleOpen(item.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
        <Dialog
          fullWidth
          maxWidth={'xs'}
          open={open1}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '10px',
              paddingTop: '10px',
            }}
          >
            <CancelIcon
              style={{
                color: colors.red,
                cursor: 'pointer',
                fontSize: 24,
              }}
              onClick={handleClose}
            />
          </div>
          <DialogTitle style={{ padding: '0px 10px 0px 15px' }}>
            Are you sure, you want to delete?
          </DialogTitle>
          <DialogActions>
            <ButtonR
              onClick={() => handleSubmit(num)}
              style={{
                backgroundColor: colors.blue,
                fontSize: '14px',
                color: colors.white,
                borderRadius: '3px',
                margin: '0px 3px 6px 0px',
              }}
            >
              Delete
            </ButtonR>
          </DialogActions>
        </Dialog>
      </Table>
    );
  });

  return (
    <>
      {saveState || editState ? (
        <div style={{ marginTop: '40px' }}>
          <p style={{ margin: 0 }} className={classes.textStyle1}>
            Save this search?
          </p>
          <div
            className="d-flex"
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 10,
              maxWidth: '70rem',
            }}
          >
            <div className="d-flex" style={{ marginBottom: '5px' }}>
              <label
                For="name"
                className={classes.textStyle}
                style={{ marginRight: '10px' }}
              >
                Name
              </label>
              <Input
                style={{ marginTop: -2 }}
                type="text"
                id="name"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>
            <FormControlLabel
              style={{ margin: 0 }}
              labelPlacement="end"
              label={
                <p style={{ margin: 0 }} className={classes.labelStyle}>
                  Email Daily
                </p>
              }
              control={
                <Checkbox
                  checked={emailDaily}
                  onChange={() => setEmailDaily(!emailDaily)}
                  color="primary"
                />
              }
            />
            {editState ? (
              <Button
                onClick={() => {
                  updateHandler(searchName, emailDaily, editId);
                }}
                style={{
                  borderRadius: 5,
                  color: 'white',
                  backgroundColor: colors.blue,
                  border: 0,
                  width: '150px',
                  height: 38,
                  marginTop: -13,
                }}
              >
                Update
              </Button>
            ) : savedSearches?.length < 5 ? (
              <div
                style={{
                  position: 'relative',
                  marginTop: -40,
                }}
              >
                <div>
                  <GetStepImage step={2} />
                </div>
                <Button
                  onClick={saveSearchHandle}
                  style={{
                    borderRadius: 5,
                    color: 'white',
                    backgroundColor: colors.blue,
                    border: 0,
                    width: '150px',
                    height: 38,
                  }}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div
                style={{
                  position: 'relative',
                  marginTop: -40,
                }}
              >
                <div>
                  <GetStepImage step={2} />
                </div>
                <Button
                  onClick={() =>
                    toastr.error('Saved Searches cannot be greater than 5!')
                  }
                  style={{
                    borderRadius: 5,
                    color: 'white',
                    backgroundColor: colors.blue,
                    border: 0,
                    width: '150px',
                    height: 38,
                  }}
                >
                  <LockIcon
                    style={{
                      position: 'absolute',
                      right: 5,
                      top: 5,
                      fontSize: 12,
                    }}
                  />
                  Save
                </Button>
              </div>
            )}

            <div
              style={{
                width: '150px',
                marginBottom: 20,
                float: 'right',
              }}
            >
              <label
                style={{
                  fontWeight: 'bold',
                  fontSize: 14,
                  float: 'left',
                }}
              >
                Filter Posted Time:{' '}
              </label>
              <div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.filterDate}
                  placeholder=""
                  fullWidth
                  label="Select Date"
                  onChange={(e) => props.setFilterDate(e.target.value)}
                >
                  <MenuItem value={'All'}>Anytime</MenuItem>
                  <MenuItem value={'yesterday'}>24 hours</MenuItem>
                  <MenuItem value={'7 days ago'}>7 days</MenuItem>
                  <MenuItem value={'14 days ago'}>14 days</MenuItem>
                  <MenuItem value={'1 month ago'}>30 days</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {savedSearches?.length ? (
        <Card className={classes.root}>
          <div>
            <CardTitle
              style={{
                margin: '10px',
                fontWeight: '700',
                fontSize: 16,
                cursor: 'pointer',
              }}
              // onClick={handleClick}
            >
              <IconButton>
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                &nbsp;
              </IconButton>
              Saved Searches
            </CardTitle>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <SearchList list={savedSearches} />
            </Collapse>
          </div>
        </Card>
      ) : (
        ''
      )}
    </>
  );
});

export default Searches;
