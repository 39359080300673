import * as Sentry from '@sentry/react';
import { Route } from 'react-router-dom';
const sentryInit = (history) => {
  const sentryInitConfig = {
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'https://app.resumas.com',
          'https://dev.resumas.com',
        ],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    // Performance Monitoring
    tracesSampleRate:
      process.env.REACT_APP_SENTRY_ENV === 'development' ? 0.5 : 1.0,
    environment: process.env.REACT_APP_SENTRY_ENV,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  };

  Sentry.init(sentryInitConfig);
  Sentry.captureMessage('Sentry is working!');
};

export default sentryInit;

// route for sentry error tracking
export const SentryRoute = Sentry.withSentryRouting(Route);
