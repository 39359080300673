export const styles = {
  flex: {
    display: 'flex',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  width_100_percent: {
    width: '100%',
  },
  width_80_percent: {
    width: '80%',
  },
  fontWeight_400: {
    fontWeight: 400,
  },
  fontWeight_600: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  fontWeight_bold: {
    fontWeight: 'bold',
  },
  fontSize_1_rem: {
    fontSize: '1rem',
  },
  mt_5: {
    marginTop: 5,
  },
  mt_30: { marginTop: 30 },
  mt_20: {
    marginTop: 20,
  },
  mt_40: {
    marginTop: '40px',
  },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_40: {
    marginBottom: 40,
  },
  mb_90: {
    marginBottom: 90,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  bb_ligthGrey: {
    border: '0.5px dashed lightgray',
    marginTop: 5,
    width: '100%',
    marginBottom: 20,
  },
  saveEduBtn: {
    paddingLeft: 40,
    paddingRight: 40,
    marginTop: 20,
    fontSize: 14,
    fontWeight: 700,
    borderRadius: 5,
  },
  colxx_div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2%',
  },
  colxx_div_span: {
    color: 'grey',
    marginTop: '1.5%',
    fontSize: '.75rem',
  },
  colxx_div_span2: {
    color: 'grey',
    fontSize: '.75rem',
    marginBottom: '2%',
  },
  inputGroup: {
    marginTop: '40px',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  inputGroup2: {
    marginTop: '40px',
    width: '265px',
  },
  dropdown: {
    overflow: 'auto',
    height: '300px',
    top: '180px',
  },
  dropdownToggle: {
    borderRadius: 0,
    width: '190px',
  },
  glyphIcon: {
    fontSize: '20px',
    marginLeft: '2%',
    color: 'green',
  },
  eduSpan: { fontSize: '85%' },
  col: {
    marginBottom: 10,
    textAlign: 'center',
  },
  textMuted: {
    fontSize: '.7rem',
  },
  borderRadius_0: {
    borderRadius: 0,
  },
  h3: { marginLeft: '22%', marginTop: '7%' },
  inputGroup1: {
    marginTop: '40px',
    flexWrap: 'nowrap',
  },
  linkdinCol: {
    size: 10,
    offset: 1,
  },
  linkdinSpan: {
    color: '#0077B5',
  },
  linkdinDiv: {
    display: 'flex',
    marginLeft: '33%',
  },
  specificCardTitle: {
    fontSize: '0.9rem',
  },
  specificDropdown: {
    borderRadius: 0,
    width: '135px',
  },
  specificInputGroup: {
    marginTop: '40px',
    width: '200px',
  },
  h5: {
    margin: 0,
    padding: 0,
    fontSize: '1rem',
  },
};
