import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { templateSelectorAction } from '../../commonRedux/scan/action';

export default function TemplateRadio() {
  const dispatch = useDispatch();
  const { templateId, templates } = useSelector(
    (store) => store.fileScanReducer,
  );
  const handleOptionChange = (temp, tempId) => {
    dispatch(templateSelectorAction(temp, tempId));
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: '-30px',
        zIndex: 999,
        width: 20,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <form>
        {templates?.map((item, index) => {
          return (
            <input
              type="radio"
              id={index}
              name={item.id}
              value={item.id}
              checked={item.id == templateId}
              onClick={() => handleOptionChange(item?.template, item?.id)}
              key={index}
              className="radioInput"
            />
          );
        })}
      </form>
    </div>
  );
}
