import React, { useEffect } from 'react';
import debounce from '../../utils/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { docEditorInit } from './redux/action';
import {
  editorJsonUpdate,
  mobileEditorJsonUpdate,
} from '../../commonRedux/scan/action';
import { pdfFromEditor } from '../../utils/pdfFromEditor';

export default function useWordEditor(props) {
  const [docEditor, setDocEditor] = React.useState(null);

  const [breakMenu, setBreakMenu] = React.useState(null);
  const [alignMenu, setAlignMenu] = React.useState(null);
  const [currentFontSize, setCurrentFontSize] = React.useState(12);
  const [currentFontFamily, setCurrentFontFamily] = React.useState('Arial');
  const { fileName } = useSelector((state) => state.docEditorReducer);
  const { sfdtLoading } = useSelector((state) => state.fileScanReducer);

  const dispatch = useDispatch();
  const propertiesPane = false;
  const HandleTitleBar = (id, value) => {
    switch (id) {
      case 'pdfExport':
        pdfFromEditor(
          docEditor,
          props.resumeName ? props.resumeName : fileName,
        );
        break;
      case 'wordExport':
        docEditor.documentEditor.save(
          props.resumeName ? props.resumeName : fileName,
          'Docx',
        );
        break;
      case 'txtExport':
        docEditor.documentEditor.save(
          props.resumeName ? props.resumeName : fileName,
          'Txt',
        );
        break;
      case 'print':
        docEditor.documentEditor.print();
        break;
      case 'undo':
        docEditor.documentEditor.editor.editorHistory.undo();
        break;
      case 'redo':
        docEditor.documentEditor.editor.editorHistory.redo();
        break;
      case 'find':
        docEditor.documentEditor.showOptionsPane();
        break;
      case 'hyperLink':
        docEditor.documentEditor.showDialog('Hyperlink');
        break;
      case 'pageSetup':
        docEditor.documentEditor.showDialog('PageSetup');
        break;
      case 'pageNumber':
        docEditor.documentEditor.editor.insertPageNumber();
        break;
      case 'pageBreak':
        setBreakMenu(null);
        docEditor.documentEditor.editor.insertPageBreak();
        break;
      case 'sectionBreak':
        setBreakMenu(null);
        docEditor.documentEditor.editor.insertSectionBreak();
        break;
      case 'bold':
        docEditor.documentEditor.editor.toggleBold();
        break;
      case 'italic':
        docEditor.documentEditor.editor.toggleItalic();
        break;
      case 'underline':
        docEditor.documentEditor.editor.toggleUnderline('Underline');
        break;
      case 'textAlignLeft':
        docEditor.documentEditor.editor.toggleTextAlignment('Left');
        break;
      case 'textAlignCenter':
        docEditor.documentEditor.editor.toggleTextAlignment('Center');
        break;
      case 'textAlignRight':
        docEditor.documentEditor.editor.toggleTextAlignment('Right');
        break;
      case 'textAlignJustify':
        docEditor.documentEditor.editor.toggleTextAlignment('Justify');
        break;
      case 'bullets':
        docEditor.documentEditor.editor.applyBullet('\uf0b7', 'Symbol');
        break;
      case 'fontFamily':
        docEditor.documentEditor.selection.characterFormat.fontFamily = value;
        break;
      case 'fontSize':
        docEditor.documentEditor.selection.characterFormat.fontSize = value;
        break;
      case 'styleList':
        docEditor.documentEditor.editor.applyStyle(value, true);
        break;
      default:
        break;
    }
  };

  const onContentChange = debounce(() => {
    let sfdt = docEditor?.documentEditor?.serialize();
    if (sfdt && props.isMobile) {
      dispatch(mobileEditorJsonUpdate(sfdt, props.oid, props.mobileToken));
    }
    if (sfdt && !props.isMobile) {
      dispatch(editorJsonUpdate(sfdt, props.oid));
    }
  }, 2000);

  useEffect(() => {
    let sfdt = docEditor?.documentEditor?.serialize();
    if (sfdt && props.isMobile) {
      props.onLoadEnd();
    }
  }, [docEditor]);

  useEffect(() => {
    let sfdt = docEditor?.documentEditor?.serialize();
    if (sfdt) {
      dispatch(editorJsonUpdate(sfdt, props.oid));
    }
  }, []);

  const onSelectionChange = () => {
    setCurrentFontSize(
      docEditor.documentEditor.selection.characterFormat.fontSize,
    );
    setCurrentFontFamily(
      docEditor.documentEditor.selection.characterFormat.fontFamily,
    );
  };

  const initDocEditor = (editor) => {
    if (editor && !docEditor) {
      setDocEditor(editor);
      dispatch(docEditorInit(editor));
    }
  };

  return {
    docEditor,
    propertiesPane,
    HandleTitleBar,
    initDocEditor,
    breakMenu,
    setBreakMenu,
    onContentChange,
    alignMenu,
    setAlignMenu,
    onSelectionChange,
    currentFontSize,
    currentFontFamily,
    sfdtLoading,
  };
}
