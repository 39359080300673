import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { colors } from '../../../../commonComponents/colors';
import { onBlurField } from '../../redux/actions';
import styles from '../Text/text.module.scss';
import moment from 'moment';
import './style.css';
import DatePicker from 'react-datepicker';

export default function InputText({
  path,
  value,
  type,
  customclass,
  placeholder,
  isSecurity = false,
  customFunction = (val) => {
    return val;
  },
  maxLength,
  ...props
}) {
  const dispatch = useDispatch();
  const [addBorder, setAddBorder] = useState(false);
  const [myValue, setMyValue] = useState(isSecurity ? '' : value);
  const [startDate, setStartDate] = useState(new Date());
  const changeInput = (data) => {
    dispatch(onBlurField(data, path));
  };

  let dateToMonth = (date) => {
    if (date) {
      let finalDate = moment(date, 'YYYY-MM').format('MMMM YYYY');
      return finalDate;
    }
  };

  let monthToDate = (date) => {
    if (date) {
      let finalDate = moment(date, 'MMMM YYYY').format('YYYY-MM');
      return finalDate;
    }
  };

  useEffect(() => {
    if (isSecurity) setMyValue(value);
  }, [value]);
  return (
    <>
      {type !== 'month' ? (
        <input
          type={type}
          maxLength={maxLength}
          placeholder={placeholder}
          style={{
            width: '100%',
            fontSize: '14px',
            // margin: '0 0 16px',
            border: '1px solid',
            borderColor: addBorder ? colors.blue : colors.grey,
          }}
          className={styles.contentEditableContainer + ' ' + customclass}
          value={myValue}
          onBlur={(e) => {
            setAddBorder(false);
            changeInput(e.target.value);
          }}
          onChange={(e) => {
            setMyValue(customFunction(e.target.value));
          }}
          {...props}
          onClick={() => {
            setAddBorder(true);
          }}
        />
      ) : (
        <DatePicker
          maxLength={maxLength}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          placeholderText={placeholder}
          style={{
            width: '100%',
            fontSize: '14px',
            margin: '0 0 16px',
            border: '1px solid',
            borderColor: addBorder ? colors.blue : colors.grey,
          }}
          className={styles.contentEditableContainer + ' ' + customclass}
          value={myValue}
          onBlur={(date) => {
            setAddBorder(false);
          }}
          selected={startDate}
          onChange={(date) => {
            // type === 'month'
            //   ? setMyValue(customFunction(dateToMonth(e.target.value)))
            // setMyValue(customFunction(date));
            setMyValue(customFunction(dateToMonth(date)));
            setStartDate(date);
            changeInput(dateToMonth(date));
          }}
          {...props}
          onClick={() => {
            setAddBorder(true);
          }}
        />
      )}
    </>
  );
}
