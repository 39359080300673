import React from 'react';
import MilitaryStepper from './Stepper';
import useMilitaryCrosswalk from './useMilitaryCrosswalk';
import { styles } from './MilitaryXWalkStyle';
import StartMillWalk from './steps/StartMilitaryWalk';
import ReviewMilitary from './steps/ReviewMilitary';
import EndMilitary from './steps/EndMilitary';
import BottomButtons from './bottomButton/BottomButtons';
import SelectOccupations from './steps/SelectOccupations';
import { useDispatch } from 'react-redux';
import { updateMilitaryInfo } from './redux/actions';
import { CircularProgress } from '@material-ui/core';
import InfoIcon from '../../commonComponents/InfoIcon';
import VideoModel from '../../commonComponents/videoModel';

const MilitaryCrosswalk = () => {
  const {
    militarySteps,
    milXWalkActiveStep,
    currentTitle,
    suggestedInformation,
    militaryData,
    user,
    handleNext,
    handleBack,
    handleReset,
    handleStepTwo,
    handleStepOne,
    onGetSuggestionInformation,
    isDisabledBack,
    isDisabledNext,
    isStepDisabled,
    milXWalkLoading,
    subtitles,
    selectedJobOccupations,
    selectedSubtitles,
    branch,
    code,
    jobOccupation,
    suggestionDataExist,
    resetLoading,
    tabValue,
    milXwalkDetail,
    milXwalkDetailExist,
    handleVideoModal,
    milXwalkVideoModal,
    video,
    width,
  } = useMilitaryCrosswalk();

  const dispatch = useDispatch();

  React.useEffect(() => {
    const data = {
      userId: user?._id ? user?._id : localStorage.getItem('user_id'),
      branch,
      code,
      subtitles,
      selectedJobOccupations,
      selectedSubtitles,
      jobOccupation,
      suggestedInformation,
      suggestionDataExist,
      milXWalkActiveStep,
      tabValue,
      milXwalkDetail,
      milXwalkDetailExist,
    };
    dispatch(updateMilitaryInfo(data));
  }, [
    branch,
    code,
    subtitles,
    selectedJobOccupations,
    selectedSubtitles,
    jobOccupation,
    suggestedInformation,
    suggestionDataExist,
    milXWalkActiveStep,
    tabValue,
    milXwalkDetail,
    milXwalkDetailExist,
  ]);

  if (milXWalkLoading) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <CircularProgress size={25} color="primary" />
        </div>
      </>
    );
  }

  return (
    <>
      <MilitaryStepper
        width={width}
        militarySteps={militarySteps}
        milXWalkActiveStep={milXWalkActiveStep}
        handleNext={handleNext}
        handleBack={handleBack}
        handleReset={handleReset}
        loading={resetLoading}
        isStepDisabled={isStepDisabled}
        handleStepTwo={handleStepTwo}
        handleStepOne={handleStepOne}
        onGetSuggestionInformation={onGetSuggestionInformation}
        isDisabledBack={isDisabledBack}
        isDisabledNext={isDisabledNext}
      />
      <center>
        {milXWalkActiveStep === 3 ? (
          ''
        ) : (
          <p
            style={{
              ...styles.heading(width),
              marginBottom: milXWalkActiveStep === 0 ? 5 : 10,
            }}
          >
            <InfoIcon
              onClick={() => handleVideoModal(true)}
              text={'How To'}
              icon={'Play'}
              height={18}
              width="70px"
              marginRight={5}
            />
            {currentTitle}
          </p>
        )}
        {milXWalkActiveStep === 0 && <p>Please follow steps below</p>}
      </center>
      <div
        style={{
          ...styles.container(width),
          marginTop: milXWalkActiveStep === 3 ? 12 : 0,
        }}
      >
        {milXWalkActiveStep === 0 && <StartMillWalk />}
        {milXWalkActiveStep === 1 && <SelectOccupations />}
        {milXWalkActiveStep === 2 && <ReviewMilitary />}
        {milXWalkActiveStep === 3 && (
          <EndMilitary
            suggestedInformation={suggestedInformation}
            militaryData={militaryData}
            user={user}
            milXWalkLoading={milXWalkLoading}
            handleVideoModal={handleVideoModal}
          />
        )}
      </div>
      <BottomButtons
        activeStep={milXWalkActiveStep}
        isDisabledBack={isDisabledBack}
        isDisabledNext={isDisabledNext}
        handleNext={handleNext}
        handleBack={handleBack}
      />
      <VideoModel
        open={milXwalkVideoModal}
        setOpen={(open) => handleVideoModal(open)}
        video={video}
        onEnded={() => handleVideoModal(false)}
      />
    </>
  );
};

export default MilitaryCrosswalk;
