import React, { memo, useEffect } from 'react';
import { useCountUp } from 'react-countup';
import './style.css';
import { useWindowWidth } from '@react-hook/window-size';

export default memo(
  ({
    title = '',
    isLinkedIn = false,
    isMilXwalk = false,
    isJobTitle = false,
  }) => {
    const { countUp, update } = useCountUp({
      start: 0,
      end: 100,
      duration: isLinkedIn ? 80 : 60,
    });

    let loading_states = '';
    if (isLinkedIn) {
      if (countUp < 28) {
        loading_states = 'Started building content for LinkedIn!';
      } else if (countUp < 49) {
        loading_states = 'Gathering data from your resume!';
      } else if (countUp < 65) {
        loading_states = 'Pulling all LinkedIn sections!';
      } else if (countUp <= 100) {
        loading_states = 'Completed!';
      }
    } else if (isJobTitle) {
      if (countUp <= 50) {
        loading_states = 'Getting Relevant Military Job Occupations!';
      } else if (countUp <= 100) {
        loading_states = 'Completed!';
      }
    } else if (isMilXwalk) {
      if (countUp < 20) {
        loading_states = 'Start Building content for Report!';
      } else if (countUp < 40) {
        loading_states = 'Discovering Relevant Skills!';
      } else if (countUp < 60) {
        loading_states = 'Tailoring Career Recommendations!';
      } else if (countUp < 80) {
        loading_states = 'Connecting You with Opportunities!';
      } else if (countUp <= 100) {
        loading_states = 'Completed!';
      }
    } else {
      if (countUp < 20) {
        loading_states = 'Review started!';
      } else if (countUp < 40) {
        loading_states = 'Gathering data from resume!';
      } else if (countUp < 60) {
        loading_states = 'Pulling sections together!';
      } else if (countUp < 80) {
        loading_states = 'Analyzing resume now!';
      } else if (countUp <= 100) {
        loading_states = 'Completed!';
      }
    }

    useEffect(() => {
      return () => {
        update(countUp);
      };
    }, []);
    const width = useWindowWidth();
    return (
      <div
        style={{
          height: '60vh',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Loader Start */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column-reverse',
          }}
        >
          <div className="scan-loader-shadow"></div>
          <div className="scan-loader-box"></div>
        </div>
        {/* Loader End */}

        {title ? (
          <span
            style={{ ...(width <= 600 && { fontSize: 15 }) }}
            className="loader-text"
          >
            {title}
          </span>
        ) : (
          <span
            style={{ ...(width <= 600 && { fontSize: 15 }) }}
            className="loader-text"
          >
            {loading_states}
          </span>
        )}
      </div>
    );
  },
);
