import React, { memo, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Link as Lk } from 'react-scroll';

import { useWindowSize } from '@react-hook/window-size';

const TopBar = memo((props) => {
  const [width] = useWindowSize();
  const [isOpen, setOpen] = useState(false);

  const toggleLine = () => {
    setOpen(!isOpen);
  };
  const checkingforLink = (routeName, page) => {
    return (
      <a onClick={() => window.location.replace(`https://resumas.com/${page}`)}>
        {routeName}
      </a>
    );
  };

  useEffect(() => {
    let matchingMenuItem = null;
    let ul = document.getElementById('top-menu');
    let items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, []);

  const activateParentDropdown = (item) => {
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add('active'); // li
      const parent1 = parent.parentElement;
      parent1.classList.add('active'); // li
      if (parent1) {
        const parent2 = parent1.parentElement;
        parent2.classList.add('active'); // li
        if (parent2) {
          const parent3 = parent2.parentElement;
          parent3.classList.add('active'); // li
          if (parent3) {
            const parent4 = parent3.parentElement;
            parent4.classList.add('active'); // li
          }
        }
      }
    }
  };

  return (
    <header id="topnav" className="defaultscroll sticky">
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          paddingLeft: 10,
          paddingRight: 50,
        }}
      >
        <div style={{ marginLeft: 25 }}>
          <Link className="logo" to="/">
            <img
              src={require('../../../assets/optirblue.webp')}
              alt="logo"
              style={{
                width: 150,
                height: 50,
                objectFit: 'contain',
              }}
            />
          </Link>
        </div>
        {width > 768 && (
          <>
            <div className="buy-button">
              <Link to="/signup" className="btn btn-primary">
                Sign Up
              </Link>
            </div>
            <div className="buy-button">
              <Link to="/login" className="btn loginBtn">
                Login
              </Link>
            </div>
          </>
        )}

        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              onClick={toggleLine}
              className={isOpen ? 'navbar-toggle open' : 'navbar-toggle'}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <div id="navigation" style={{ display: isOpen ? 'block' : 'none' }}>
          <ul
            className="navigation-menu"
            id="top-menu"
            style={{ paddingTop: 5 }}
          >
            {width <= 768 && (
              <>
                <li>
                  <Link
                    to={{
                      pathname: '/login',
                    }}
                  >
                    {'Login'}
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: '/signup',
                    }}
                  >
                    {'Signup'}
                  </Link>
                </li>
              </>
            )}

            <li>{checkingforLink('home', '')}</li>

            <li>{checkingforLink('features', '#features')}</li>
            <li>{checkingforLink('pricing', '#pricing')}</li>
            <li>{checkingforLink('partners', '#partners')}</li>
            <li>{checkingforLink('blog', 'blog')}</li>
            <li>{checkingforLink('contact', '#contact')}</li>
          </ul>
        </div>
      </div>
    </header>
  );
});

export default withRouter(TopBar);
