import { colors } from '../../commonComponents/colors';

export const styles = {
  flex: {
    display: 'flex',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  width_100_percent: {
    width: '100%',
  },
  width_80_percent: {
    width: '80%',
  },
  fontWeight_400: {
    fontWeight: 400,
  },
  fontWeight_bold: {
    fontWeight: 'bold',
  },
  fontSize_1_rem: {
    fontSize: '1rem',
  },
  mt_5: {
    marginTop: 5,
  },
  mt_10: {
    marginTop: 10,
  },
  mt_30: { marginTop: 30 },
  mt_20: {
    marginTop: 20,
  },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_90: {
    marginBottom: 90,
  },
  pl_10: {
    paddingLeft: 10,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  cardBody: {
    fontSize: '.7rem',
    fontWeight: 400,
  },
  cardTitleFont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  cardTitle: {
    fontWeight: '800',
    fontSize: '22px',
    lineHeight: '34.13px',
    fontFamily: 'Montserrat',
    color: 'rgb(74, 74, 74)',
    marginRight: '5px',
  },
  textMuted: {
    fontSize: '.75rem',
    textAlign: 'center',
  },
  dataList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  h3: {
    color: '#303030',
    marginLeft: 15,
    fontSize: 14,
    lineHeight: 1.6,
    fontWeight: 300,
  },
  h5: {
    margin: 0,
    padding: 0,
    fontSize: '1rem',
    fontWeight: 700,
  },
  inputGroup: {
    paddingTop: 10,
    marginTop: '20px',
    flexWrap: 'nowrap',
  },
  tagInput: { border: 'none', width: '100%' },
  btn: {
    borderRadius: 0,
    color: 'white',
    backgroundColor: colors.blue,
    // paddingLeft: 34,
    border: 0,
    // paddingRight: 34,
    width: '150px',
  },
  inputIcon: {
    backgroundColor: '#145388',
    color: 'white',
    padding: 10,
    alignItems: 'center',
  },
  content: {
    height: 'auto',
    width: '100%',
    marginBottom: '40px',
    marginLeft: 0,
    marginwidth: 0,
    marginTop: 0,
    offset: 0,
  },
  table: {
    borderColor: '#ffffff',
    // border: 0,
    height: 'auto',
    width: '100%',
    backgroundColor: '#ffffff',
    border: '1px solid #e4e2e2',
    borderCollapse: 'separate',
    borderRadius: 4,
    borderSpacing: 0,
    color: '#242128',
    margin: 0,
    padding: 30,
  },
  td: {
    borderTop: 0,
    height: '100%',
    display: 'flex',
    width: '100%',
  },
  td2: {
    paddingTop: 10,
    borderTop: '1px solid #e4e2e2',
    colSpan: 2,
  },
  span: {
    color: 'black',
    marginLeft: 10,
    fontWeight: 'normal',
    lineHeight: 2,
    fontSize: 14,
  },
  p: {
    // color: "#8f8f8f",
    margin: 0,
    fontSize: 16,
    lineHeight: 1.4,
  },
  span2: {
    color: '#303030',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.6,
  },
  span3: {
    color: 'grey',
    borderRadius: 50,
    display: 'inline-block',
    marginBottom: 20,
    marginLeft: 10,
    fontSize: 14,
    lineHeight: 1.4,
    marginTop: 0,
  },
  table2: {
    minWidth: '100%',
    borderCollapse: 'collapse',
    width: '100%',
    border: 0,
  },
  table3: {
    border: 0,
    borderCollapse: 'separate',
    width: '100%',
  },
  btnJob: {
    fontSize: 14,
    lineHeight: 1.5,
    letterSpacing: 1,
    border: 'none',
    padding: '9px 10px',
    textAlign: 'center',
    textDecoration: 'none',
    color: 'white',
    borderRadius: 5,
    fontWeight: 'bold',
    backgroundColor: colors.blue,
  },
  skill: {
    background: 'white',
    paddingTop: '10px',
    paddingRight: '16px',
    borderRadius: '10px',
    marginBottom: '10px',
    border: '1px solid #e4e2e2',
    display: 'flex',
  },
  btnSkill: {
    padding: 10,
    backgroundColor: '#145388',
    color: 'white',
    marginLeft: '25%',
    borderRadius: 5,
    marginTop: 10,

    border: '1px solid #145388',
  },
  btnSpan: {
    fontSize: '0.8rem',
    textAlign: 'center',
    alignSelf: 'center',
  },
  skillsLabel: {
    fontSize: '12px !important',
  },
};
