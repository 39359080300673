import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { Button } from 'antd';

import CloseIcon from '@material-ui/icons/Close';
import toastr from 'toastr';
import { updateLinkedinData } from '../redux/actions';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CreateIcon from '@material-ui/icons/Create';

import { Popup } from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useWindowSize } from '@react-hook/window-size';

const causesData = [
  'Animal Welfare',
  'Arts and Culture',
  'Children',
  'Civil Rights and Social Action',
  'Disaster and Humanitarian Relief',
  'Economic Empowerment',
  'Education',
  'Environment',
  'Health',
  'Human Rights',
  'Politics',
  'Poverty Alleviation',
  'Science and Technology',
  'Social Services',
  'Veteran Support',
];
const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};

export default function Causes(props) {
  const [width] = useWindowSize();
  const [checkList, setCheckList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const causesModal = useSelector((state) => state.linkedinReducer.causesModal);
  const causesModalMode = useSelector(
    (state) => state.linkedinReducer.causesModalMode,
  );
  useEffect(() => {}, [causesModalMode]);
  const linkedinData = props.linkedinData;
  useEffect(() => {
    if (causesModalMode === 'edit') {
      setCheckList(linkedinData?.causes);
    }
  }, [causesModalMode]);
  const handleCheckBoxes = (e) => {
    let selectedJobTypes = checkList;
    if (Array.isArray(selectedJobTypes) && selectedJobTypes?.length === 0) {
      setCheckList([e.target.name]);
    } else {
      let checkObj = selectedJobTypes.find((item) => item === e.target.name);
      if (checkObj) {
        let d = [];
        d = selectedJobTypes.filter((item) => item !== e.target.name);
        setCheckList(d);
      } else {
        setCheckList([...selectedJobTypes, e.target.name]);
      }
    }

  };

  const cb = (v) => {
    dispatch({
      type: 'CAUSES_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Cause Updated');
    } else {
      toastr.error('Error Updating Cause');
    }
  };

  const handleUpdate = () => {
    if (Array.isArray(checkList) && checkList?.length === 0) {
      toastr.error('Please select at least one cause');
      return;
    }
    setLoading(true);
     dispatch(updateLinkedinData({...linkedinData, causes: checkList}, cb));
     

  };

  const clearCopied = () => {};

  return (
    <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
      {
        linkedinData?.causes ?
        <ul>

        {linkedinData?.causes.map((cause, index)=>{
          return(
            <li>{cause}</li>
            )
          })}
        
          </ul>
        :
        "NO DATA AVAILABLE"
      }
      <Popup
        open={causesModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'CAUSES_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'CAUSES_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5>Causes you care about</h5>
        <h6>Choose the causes from the list below</h6>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {causesData.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={Array.isArray(checkList) ? checkList.some((v) => v === item) : false}
                    name={item}
                    color="primary"
                    onChange={handleCheckBoxes}
                  />
                }
                label={item}
              />
            );
          })}
        </div>

        <Row>
          <Col>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={false}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  Save
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}
