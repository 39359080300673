import React, { useEffect } from 'react';
import TopBar from '../../layout/authLayout/Topbar';
import TopNav from '../../layout/appLayout/topnav';
import { Row, Col, Card } from 'reactstrap';
import Footer from '../../layout/appLayout/footer';
import './style.css';
function Terms(props) {
  const comingFrom = props.history?.location?.state?.comingFrom || '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <Card
          className="privacy-page-main"
          style={{ height: 'auto', minHeight: '100vh' }}
        >
          {comingFrom === 'TopNav' ? <TopNav /> : <TopBar />}
          <Row>
            <Col
              style={{
                backgroundColor: '#f7f9fe',
                display: 'flex',
                //    alignItems: "center",
                justifyContent: 'center',
              }}
              sm="12"
              md="4"
              xs="12"
              lg="4"
            >
              <img
                src={require('../../../assets/optirblue.webp')}
                alt=""
                style={{
                  width: 200,
                  height: 110,
                  objectFit: 'contain',
                  padding: 5,
                  position: 'relative',
                  marginTop: 100,
                }}
              />{' '}
            </Col>
            <Col sm="12" md="8" xs="12" lg="8">
              <h1 style={{ marginTop: 20 }}>
                <strong>TERMS OF USE </strong>
              </h1>
              &nbsp;&nbsp;
              <h1>
                <strong> AGREEMENT TO TERMS</strong>
              </h1>
              <p
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'Nunito',
                  fontSize: 15,
                }}
              >
                <p>
                  &nbsp;&nbsp;&nbsp;These &quot;Terms of Use&quot; set forth the
                  terms and conditions that apply to your use of
                  www.workscaler.com&nbsp;(the &quot;Web Site&quot;). By using
                  the Web Site (other than to read this page for the first
                  time), you agree to comply with all of the Terms of Use set
                  forth herein. The right to use the Web Site is personal to you
                  and is not transferable to any other person or entity.
                  Copyrights and Trademarks
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>

                <p>&nbsp;</p>

                <ul>
                  <li>
                    A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All materials contained on
                    the Web Site are Copyright November 20, 2020, WorkScaler
                    Inc. All rights reserved.
                  </li>
                  <li>
                    B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No person is authorized to
                    use, copy or distribute any portion the Web Site including
                    related graphics.
                  </li>
                  <li>
                    C.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WorkScaler Inc. and other
                    trademarks and/or service marks (including logos and
                    designs) found on the Web Site are trademarks/service marks
                    that identify WorkScaler Inc&nbsp;and the goods and/or
                    services provided by WorkScaler Inc. Such marks may not be
                    used under any circumstances without the prior written
                    authorization of WorkScaler Inc. Links to Third-Party Web
                    Site
                  </li>
                </ul>

                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WorkScaler
                  Inc may provide hyperlinks to third-party web sites as a
                  convenience to users of the Web Site. WorkScaler Inc&nbsp;does
                  not control third-party web sites and is not responsible for
                  the contents of any linked-to, third-party web sites or any
                  hyperlink in a linked-to web site. WorkScaler Inc&nbsp;does
                  not endorse, recommend or approve any third-party web site
                  hyperlinked from the Web Site. WorkScaler Inc&nbsp;will have
                  no liability to any entity for the content or use of the
                  content available through such hyperlink. No Representations
                  or Warranties; Limitations on Liability
                </p>

                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The
                  information and materials on the Web Site could include
                  technical inaccuracies or typographical errors. Changes are
                  periodically made to the information contained herein.
                  WorkScaler Inc&nbsp;MAKES NO REPRESENTATIONS OR WARRANTIES
                  WITH RESPECT TO ANY INFORMATION, MATERIALS OR GRAPHICS ON THE
                  WEB SITE, ALL OF WHICH IS PROVIDED ON A STRICTLY &quot;AS
                  IS&quot; BASIS, WITHOUT WARRANTY OF ANY KIND AND HEREBY
                  EXPRESSLY DISCLAIMS ALL WARRANTIES WITH REGARD TO ANY
                  INFORMATION, MATERIALS OR GRAPHICS ON THE WEB SITE, INCLUDING
                  ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE AND NON-INFRINGEMENT. UNDER NO
                  CIRCUMSTANCES SHALL THE SITE OWNER OR PUBLISHER BE LIABLE
                  UNDER ANY THEORY OF RECOVERY, AT LAW OR IN EQUITY, FOR ANY
                  DAMAGES, INCLUDING WITHOUT LIMITATION, SPECIAL, DIRECT,
                  INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT
                  NOT LIMITED TO LOSS OF USE OR LOST PROFITS), ARISING OUT OF OR
                  IN ANY MANNER CONNECTED WITH THE USE OF INFORMATION OR
                  SERVICES, OR THE FAILURE TO PROVIDE INFORMATION OR SERVICES,
                  FROM THE WEB SITE. Changes to These Terms of Use
                </p>

                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WorkScaler
                  Inc reserves the right to change these Terms of Use at any
                  time by posting new Terms of Use at this location. You can
                  send e-mail to WorkScaler Inc&nbsp;with any questions relating
                  to these Terms of Use at support@resumas.com.
                </p>
              </p>
            </Col>
          </Row>
        </Card>
      </div>
      {comingFrom === 'TopNav' && <Footer />}
    </>
  );
}

export default Terms;
