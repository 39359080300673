import { useState, useEffect } from 'react';
import toastr from 'toastr';
import { useSelector, useDispatch } from 'react-redux';
import { updateLinkedinData } from '../redux/actions';

export function useAbout(props) {
  const dispatch = useDispatch();
  const linkedinData = useSelector(
    (state) => state.linkedinReducer.linkedinData,
  );
  const aboutModal = useSelector((state) => state.linkedinReducer.aboutModal);

  const t1 = props.linkedinData?.employmentHistory?.length
    ? props.linkedinData?.employmentHistory[0]?.position
    : '';
  const t2 = props.linkedinData?.educationHistory?.length
    ? props.linkedinData?.educationHistory[0]?.degree +
      ' in ' +
      props.linkedinData?.educationHistory[0]?.major
    : '';
  const t3 = props.linkedinData?.certificationAndLicense?.length
    ? props.linkedinData?.certificationAndLicense[0]?.title
    : '';
  const userheadline = `${t1 || ''} | ${t2 || ''} | ${t3} ` || '';

  const [showModal, setShowModal] = useState(false);
  const [valid, setValid] = useState(false);
  const [item, setItem] = useState({});
  const [copied, setCopied] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [headline, setHeadline] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [loading, setLoading] = useState(false);
  const [additionalName, setAdditionalName] = useState('');
  const [industry, setIndustry] = useState('');

  const cb = (v) => {
    dispatch({
      type: 'ABOUT_MODAL',
      payload: false,
    });
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Profile Updated');
    } else {
      toastr.error('Error Updating Profile');
    }
  };
  const handleUpdate = () => {
    setLoading(true);
    let ContactInfo = {
      firstName,
      lastName,
      email,
      phone,
      headline,
      country,
      city,
      additionalName,
      industry,
    };
    let data = { ...linkedinData, ContactInfo };
    dispatch(updateLinkedinData(data, cb));
  };

  useEffect(() => {
    const { user } = props;

    try {
      setIndustry(
        linkedinData.ContactInfo[0].industry
          ? linkedinData.ContactInfo[0].industry
          : '',
      );
      setFirstName(
        linkedinData.ContactInfo[0].firstName
          ? linkedinData.ContactInfo[0].firstName
          : user?.firstName,
      );
      setLastName(
        linkedinData.ContactInfo[0].lastName
          ? linkedinData.ContactInfo[0].lastName
          : user?.lastName,
      );
      setAdditionalName(
        linkedinData.ContactInfo[0].additionalName
          ? linkedinData.ContactInfo[0].additionalName
          : '',
      );
      setEmail(
        linkedinData.ContactInfo[0].email
          ? linkedinData.ContactInfo[0].email
          : user?.email,
      );
      setPhone(
        linkedinData.ContactInfo[0].phone
          ? linkedinData.ContactInfo[0].phone
          : user?.phone,
      );
      setHeadline(userheadline);
      setCountry(
        linkedinData.ContactInfo[0].country
          ? linkedinData.ContactInfo[0].country
          : user?.country,
      );
      setCity(
        linkedinData.ContactInfo[0].city
          ? linkedinData.ContactInfo[0].city
          : user?.city,
      );
    } catch (err) {
    }
  }, [linkedinData.ContactInfo, props, userheadline]);

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };
  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    headline,
    setHeadline,
    country,
    setCountry,
    city,
    setCity,
    copied,
    loading,
    phone,
    setPhone,
    handleCopied,
    handleUpdate,
    showModal,
    setShowModal,
    clearCopied,
    valid,
    setValid,
    item,
    setItem,
    additionalName,
    setAdditionalName,
    industry,
    setIndustry,
    dispatch,
    aboutModal,
  };
}
