import React from 'react';
import styles from './infoModalStyle';
import { colors } from '../colors';
import { useWindowWidth } from '@react-hook/window-size';

export default function CompanyView(props) {
  const { companyInfo } = props;
  const companyData = [
    { heading: 'Company', value: companyInfo?.['title'] },
    { heading: 'Industry', value: companyInfo?.['type'] },
    { heading: 'Website', value: companyInfo?.['website'] },
    {
      heading: 'Description',
      value: companyInfo?.['Description'] ?? companyInfo?.['description'],
    },
    {
      heading: 'Headquarter',
      value:
        companyInfo?.['attributes']?.Address ??
        companyInfo?.['attributes']?.Headquarters,
    },
    { heading: 'Revenue', value: companyInfo?.['attributes']?.Revenue },
  ];
  const width = useWindowWidth();
  return (
    <div style={{ padding: width < 500 ? '10px 2px' : 20, minWidth: 450 }}>
      {companyData?.map((data, index) => (
        <div style={styles.infoItem} key={index}>
          <h6
            style={{
              fontWeight: 'bold',
              fontSize: width < 500 ? 13 : 19,
              color: 'black',
              marginTop: -4,
            }}
          >
            {data?.heading}:&nbsp;
          </h6>
          <p
            style={{
              fontSize: width < 500 ? 13 : 19,
              color: colors.fontGray,
            }}
          >
            {data?.value ?? 'NA'}
          </p>
        </div>
      ))}
    </div>
  );
}
