import React from 'react';
import { Progress } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';
import { colors } from '../../../../commonComponents/colors';

export default ({ isModal }) => {
  const { scanResult } = useSelector((state) => state.fileScanReducer);

  const total_score = scanResult?.total_score || 0;
  const width = useWindowWidth();

  if (isModal && scanResult) {
    return (
      <center>
        <style jsx="true">{`
          .progress-bar {
            background-color: ${colors.green};
            overflow: visible;
            border-radius: 50px;
          }
        `}</style>
        <div style={styles.row}>
          <Progress value={total_score} style={styles.progress}>
            <span
              style={{
                ...styles.progressText,
                fontSize: '1rem',
                paddingLeft: '10px',
              }}
            >
              {width < 500
                ? total_score
                : total_score > 15
                ? 'MatchScore = ' + total_score
                : total_score}
            </span>
          </Progress>
        </div>
      </center>
    );
  }
  return null;
};

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    width: '100%',
    height: '23px',
    // backgroundColor: hexToRgbA(colors.red, 0.2),
    backgroundColor: colors.blue,
    // backgroundColor: "#EDF0F7",
    borderRadius: '50px',
    border: '1px solid lightgray',
  },
  progressText: {
    marginRight: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: colors.white,
  },
};
