import React from 'react';
import Button from '@material-ui/core/Button';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';
import { addSimilarJobTitle } from '../../commonRedux/scan/action';
import { colors } from '../../commonComponents/colors';
const { Option } = Select;

export default function FreeSoloCreateOptionDialog(props) {
  const [value, setValue] = React.useState(null);
  const dispatch = useDispatch();

  const onClick = () => {
    if (value) {
      dispatch(addSimilarJobTitle(value, props.oid, props.defaultJobTitle));
    }
  };

  const handleChange = (v) => {
    setValue(v);
  };

  return (
    <div
      className="autocomplete-mainContainer"
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 5,
      }}
    >
      <Select
        className="change_select_width"
        value={value}
        onChange={handleChange}
      >
        <Option value={null}>
          {props.isMatched ? 'No action is required' : 'Action is required'}
        </Option>
        {props.jobTitles?.map((n, i) => (
          <Option key={i} value={n}>
            {n}
          </Option>
        ))}
      </Select>
      <Button
        size="small"
        style={{
          borderRadius: 0,
          backgroundColor: props.isMatched ? colors.blue : colors.yellow,
          color: props.isMatched ? 'white' : 'black',
          paddingHorizontal: 5,
          marginRight: 5,
          textTransform: 'capitalize',
          height: 30,
        }}
        onClick={onClick}
      >
        Append
      </Button>
    </div>
  );
}
