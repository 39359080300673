import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { Badge } from 'reactstrap';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Dialouge from './add-letter-model';
import { colors } from '../../commonComponents/colors';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  addCoverLetter,
  getCoverLetters,
  DeleteCoverLatter,
  GetCoverAdminLetters,
  UpdateCoverLetter,
} from '../../commonRedux/template/action';
import Button from '@material-ui/core/Button';
import { fileParser, updateMasterResuma } from '../../commonRedux/scan/action';
import Assignment from '@material-ui/icons/Assignment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toastr from 'toastr';
import ShowCoverLetter from './show-cover-letter';
import ReactHtmlParser from 'react-html-parser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { styles } from './style';
import TableLoader from '../../commonComponents/tableLoader';
import moment from 'moment';
import Switch from 'react-ios-switch';
import RightIconButton from '../../commonComponents/RightIconButton';
import BulbImage from '../../assets/bulb.webp';
import InfoIcon from '../../commonComponents/InfoIcon';
import {
  DialogTitle,
  IconButton,
  Button as ButtonR,
  Tooltip,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
var n = 0;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 540,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

function CoverLetterTable(props) {
  const classes = useStyles();
  const [pageSelected, setPageSelected] = React.useState(0);
  const [coverId, setCoverId] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [oid, setOid] = React.useState('');

  const handleClickOpen = (id) => {
    setOpen(true);
    setOid(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _getCoverData = (page) => {
    props.getCoverLetters(page);
  };
  const stripHtml = (html) => {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  React.useEffect(() => {
    n += 1;
    if (n === 1) {
      _getCoverData(1);
    }
    props.GetCoverAdminLetters();
  }, []);
  const handlePageClick = (data) => {
    let num = data.selected + 1;
    _getCoverData(num);
    setPageSelected(data.selected);
  };

  React.useEffect(() => {
    if (!props.cover_id) {
      setCoverId(props.coverLetters[0]?.oid);
    } else {
      let coverIdFilter = props.coverLetters?.filter((item) => {
        if (item?.oid === props.cover_id) {
          return true;
        }
      });
      if (!coverIdFilter.length) {
        setCoverId(props.coverLetters[0]?.oid);
      } else {
        setCoverId(props.cover_id);
      }
    }
  }, [props.cover_id, props.coverLetters]);

  const columns = [
    { id: 'title', label: 'Title' },
    { id: 'tags', label: 'Tags' },
    { id: 'default_id', label: 'Default' },
    { id: 'createdAt', label: 'Created At', minWidth: '105px' },
    { id: 'actions', label: 'Actions' },
  ];

  function createData(title, decription, tags, default_id, createdAt, actions) {
    return {
      title,
      decription,
      createdAt,
      actions,
      tags,
      default_id,
    };
  }

  const onChangeR = (i) => {
    if (props.cover_id !== i) {
      props.updateMasterResuma({ oid: i, type: 'cover' });
      setCoverId(i);
    }
  };

  const rows = props.coverLetters.map((item) =>
    createData(
      item?.isDefault ? (
        <>
          <p>{item.title}</p>
        </>
      ) : (
        item.title
      ),
      item?.isDefault ? (
        <>
          <p>
            {ReactHtmlParser(item.description.toString().slice(0, 100) + '...')}
          </p>
        </>
      ) : (
        ReactHtmlParser(item.description.toString().slice(0, 100) + '...')
      ),
      item?.isDefault ? (
        <>
          <p>{item.tags} </p>
        </>
      ) : item.tags ? (
        item.tags + " "
      ) : (
        'None'
      ),
      item?.isDefault ? (
        <>
          <Badge color="danger">Template#1</Badge>
        </>
      ) : (
        <Switch
          checked={coverId === item['oid']}
          className="switch"
          disabled={item?.isDefault || false}
          onColor="rgb(43,0125,227)"
          onChange={() => onChangeR(item['oid'])}
        />
      ),
      moment(item.createdAt).format('MM DD, YYYY'),
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <ShowCoverLetter
          detail={item}
          addCoverLetter={props.addCoverLetter}
          getCoverData={props.getCoverLetters}
          UpdateCoverLetter={props.UpdateCoverLetter}
        />

        <Tooltip title="Copy">
          <CopyToClipboard
            text={
              item.title +
              ' ' +
              ' ' +
              ' ' +
              ' ' +
              ' ' +
              stripHtml(item.description)
            }
            onCopy={() => toastr.success('Cover letter copied!')}
          >
            <FileCopyIcon style={styles.fileCopyIcon} className="hovdel" />
          </CopyToClipboard>
        </Tooltip>
        {!item?.isDefault ? (
          <>
            <Tooltip title="Delete">
              <DeleteOutlineIcon
                onClick={() => handleClickOpen(item.oid)}
                className="hovdel"
                style={styles.delIcon}
              />
            </Tooltip>
          </>
        ) : null}
      </span>,
    ),
  );

  return (
    <div style={{ marginBottom: -20 }}>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
            paddingTop: '10px',
          }}
        >
          <CancelIcon
            onClick={handleClose}
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
          />
        </div>
        <DialogTitle style={{ ...styles.h3, padding: '0px 5px 0px 10px' }}>
          Are you sure, you want to delete?
        </DialogTitle>
        <DialogActions>
          {/* <button
            onClick={handleClose}
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              borderRadius: '3px',
              fontSize: '14px',
            }}
          >
            Cancel
          </button> */}
          <ButtonR
            onClick={() => {
              props.DeleteCoverLatter(oid);
              setCoverId(props.coverLetters[1]?.oid);
              setOpen(false);
            }}
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              borderRadius: '3px',
              fontSize: '14px',
              margin: '0px 3px 6px 0px',
            }}
          >
            Delete
          </ButtonR>
        </DialogActions>
      </Dialog>
      <div>
        <div style={{ ...styles.flex, ...styles.spaceBetween }}>
          <div></div>
          <Dialouge
            addCoverLetter={props.addCoverLetter}
            fileParser={props.fileParser}
            getCoverData={props.getCoverLetters}
            resumeDescription={props.resumeDescription}
          />
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {props.coverLetters.length && rows.length ? (
                <TableBody>
                  {rows
                    .slice(pageSelected * 5, pageSelected * 5 + 5)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align="center"
                                style={{
                                  borderBottom: 'none',
                                }}
                              >
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <div
                      className="resume_main_container"
                      style={styles.tableCell}
                    >
                      <InfoIcon
                        onClick={() => this.setState({ modelOpen: true })}
                        text={'PRO TIP'}
                        icon={'Bulb'}
                        height={20}
                        width="80px"
                        marginTop={-4}
                      />
                      <span
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px',
                          fontSize: '10px',
                          padding: '2px 5px',
                          borderRadius: '5px',
                          border: '1px solid gray',
                          backgroundColor: '#ffffff',
                          fontWeight: '900',
                          fontFamily: 'Roboto',
                        }}
                      >
                        Customize and keep Cover Letter ready, build from our
                        template or import your own
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>

          {rows.length > 5 ? (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(props.total_page / 5)}
              // initialPage={page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
              forcePage={pageSelected}
            />
          ) : null}
          <div style={{ height: 2 }}></div>
        </Paper>
      </div>
    </div>
  );
}
const mapStateToProps = ({ templateReducer, fileScanReducer, authUser }) => {
  const coverLetters = [
    ...templateReducer.coverAdminLetters,
    ...(templateReducer?.coverLetters || []),
  ];
  return {
    coverLetters,
    total_page: templateReducer.total_page,
    page: templateReducer.current_page,
    coverLetterStatus: templateReducer.coverLetterStatus,
    resumeDescription: fileScanReducer.parsefile,
    cover_id: authUser?.user?.cover_id,
  };
};
export default connect(mapStateToProps, {
  addCoverLetter,
  getCoverLetters,
  GetCoverAdminLetters,
  DeleteCoverLatter,
  UpdateCoverLetter,
  fileParser,
  updateMasterResuma,
})(CoverLetterTable);
