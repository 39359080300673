import React from 'react'

export default function ViewIcon({ cursor, style, onClick}) {
    return (
        <svg onClick={onClick} cursor={cursor} style={style} width="20" height="14" viewBox="0 0 20 14" fill='none'  xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="14" fill="#E5E5E5" />
            <path d="M93 -205H-249V69H93V-205Z" fill="white" />
            <path d="M0 7C0 7 3.6 0 10 0C16.4 0 20 7 20 7C20 7 16.4 14 10 14C3.6 14 0 7 0 7Z" fill="#4A4A4A" />
            <path d="M11.7 7C11.7 7.9 10.9 8.60001 10 8.60001C9.1 8.60001 8.3 7.9 8.3 7C8.3 6.1 9.1 5.39999 10 5.39999C10.9 5.39999 11.7 6.1 11.7 7Z" fill="#4A4A4A" />
            <path d="M10 3.39999C8 3.39999 6.3 5 6.3 7C6.3 9 8 10.6 10 10.6C12 10.6 13.7 9 13.7 7C13.7 5 12 3.39999 10 3.39999ZM10 8.60001C9 8.60001 8.3 7.9 8.3 7C8.3 6.1 9.1 5.39999 10 5.39999C10.9 5.39999 11.7 6.1 11.7 7C11.7 7.9 11 8.60001 10 8.60001Z" fill="white" />
        </svg>
    )
}