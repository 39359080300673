import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { colors } from '../../commonComponents/colors';
import MostRecentReviews from './mostRecentReviews';
import ResumeHistory from './resume_history';
import ScanHistory from './scan_history';
import { useEffect } from 'react';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
const clickTabStyle = {
  // border: `1.5px solid ${colors.blue}`,
  background: colors.blue,
  color: colors.white,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '&$selected': {
      color: '#ffffff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#ffffff',
    },
  },
  titleDiv: {
    paddingTop: '20px',
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 800,
    fontSize: '22px',
    lineHeight: '34.13px',
    fontFamily: 'Montserrat',
    color: '#4A4A4A',
  },
  manageTabs: {
    // fontSize: ".75rem",
    // fontWeight: 600,
  },
}));

export default function TabBar(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [toggle, setToggle] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!props.data?.length) {
      setValue(1);
      setToggle(1);
    }
  }, [props.data]);

  return (
    <div>
      <AppBar
        position="static"
        color="default"
        style={{ zIndex: 1, marginBottom: 10 }}
      >
        <Tabs
          className="manage_tab_bar_cont"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          TabIndicatorProps={{
            style: {
              background: colors.blue,
              display: 'none',
            },
          }}
        >
          <Tab
            className={`resume_main_container ${classes.manageTabs}`}
            label="Most Recent Reviews"
            style={toggle === 0 ? clickTabStyle : {}}
            {...a11yProps(2)}
            onClick={() => {
              setToggle(0);
            }}
          />

          <Tab
            className={`resume_main_container ${classes.manageTabs}`}
            label="Resume History"
            {...a11yProps(0)}
            style={toggle === 1 ? clickTabStyle : {}}
            onClick={() => {
              setToggle(1);
            }}
          />

          <Tab
            className={`resume_main_container ${classes.manageTabs}`}
            label="Review History"
            {...a11yProps(1)}
            style={toggle === 2 ? clickTabStyle : {}}
            onClick={() => {
              setToggle(2);
            }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MostRecentReviews {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ResumeHistory />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ScanHistory />
      </TabPanel>
    </div>
  );
}
