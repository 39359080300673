import { makeStyles } from '@material-ui/styles/';
import { colors } from '../../../commonComponents/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100vw',
    width: '88vw',
    margin: '0 auto',
  },
  stepper: {
    // '-webkit-box-shadow': '0 0 10px #eee',
    boxShadow: '0 0 10px #eee',
  },
  stepperScroll: {
    overflowX: 'auto',
    '&::-webkit-scrollbar ': {
      height: '5px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#4267B2',
      borderRadius: 10,
      // outline: "1px solid slategrey",
    },
  },
  container: {
    maxWidth: '100vw',
    width: '88vw',
    background: '#FFFFFF',
    margin: '20px auto',
    padding: 50,
    borderRadius: 5,
  },
  resumePreview: {
    maxWidth: '300px',
    cursor: 'pointer',
    background: '#eee',
    margin: 'auto',
  },
  resumeThumbnail: {
    width: '100%',
    border: '1px solid #eee',
    transition: '0.8s',
    '&:hover': { border: '1px solid #30409F' },
    imageRendering: '--webkit-optimize-contrast',
  },
  buttonContainer: {
    marginTop: 50,
  },
  choiceChipList: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    background: 'white',
    padding: '20px 20px 10px',
  },
  choiceChip: {
    marginRight: 10,
    marginBottom: 10,
  },
  btnRight: {
    float: 'right',
  },
  button: {
    color: 'white',
    backgroundColor: colors.blue,
    // marginRight: theme.spacing(1),
    width: '80px',
    height: '40px',
    '&:hover': {
      backgroundColor: colors.blue,
    },
  },
  instructions: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  summaryLabel: {
    color: colors.blue,
    fontSize: 16,
    fontWeight: '700',
    paddingTop: 8,
  },
  summaryLabelGrey: {
    color: 'grey',
    fontSize: 16,
    fontWeight: '700',
    paddingTop: 8,
  },
  summarySubLabel: {
    marginTop: -4,
    color: 'grey',
    fontSize: 16,
  },
  label: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 0,
    color: '#545454',
    lineHeight: '24px',
    marginLeft: 2,
  },
  input: {
    color: 'black',
    '&::placeholder': {
      color: 'black',
    },
    padding: 10,
    '&:focus': {
      border: '1px solid red',
    },
  },
  summaryInput: {
    minHeight: 250,
    minWidth: 250
  },
  skillList: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 320,
  },
  skillItem: {
    alignItems: 'stretch',
    minHeight: 60,
    border: '1px solid #e8ecf0',
    background: '#fff',
    boxShadow: '0 0 10px rgba(88,88,95,0.15)',
    position: 'relative',
    marginBottom: 10,
  },
  skillEnd: {
    marginTop: 50,
  },
  headTextLabel: {
    padding: '12px 10px',
    fontSize: 14,
  },
  actionContainer: {
    position: 'absolute',
    // background: "#449F76",
    right: 0,
    height: 58,
    width: 38,
  },
  actionLabel: {
    padding: '16px 5px',
    fontSize: 10,
    cursor: 'pointer',
    fontWeight: 700,
    marginBottom: 0,
    color: colors.red,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  labelTranform: {
    // transform: "rotate(-90deg)",
    height: '100%',
    right: 0,
  },
  verticalCenter: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)',
  },
  summaryAction: {
    fontSize: 14,
    cursor: 'pointer',
    color: '#55acee',
    marginBottom: 10,
  },
  overlay: {
    position: 'fixed',
    display: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 1030,
    cursor: 'pointer',
    overflowX: 'auto',
  },
  overflowStyle: {
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  overlayContent: {
    // position: "relative",
    // top: "50%",
    // left: "50%",
    // fontSize: 50,
    // color: "white",
    // transform: "translate(-50%,-50%)",
    // "-ms-transform": "translate(-50%,-50%)",
    cursor: 'auto',
    maxWidth: 1200,
    margin: '0 auto',
    display: 'block',
    // pointerEvents: "none",
  },
  overlayClose: {
    position: 'fixed',
    top: 0,
    right: 0,
    marginRight: 10,
    marginTop: 10,
    color: '#FFF',
  },
}));
