// React Basic and Bootstrap
import React, { memo } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import android from "../../assets/landingPage/android.webp";
import ios from "../../assets/landingPage/ios.webp";
import QRCode from "qrcode.react";

export default memo(() => {
  return (
    <>
      <section className="section pt-0 " style={{ backgroundColor: 'white' }}>
        <div className="container">
          <Row className="  mt-sm-0 pt-sm-0 justify-content-center">
            <Col className="text-center">
              <div className="section-title">
                <h4
                  style={{ fontFamily: 'Montserrat', marginTop: 10 }}
                  className="main-title mb-4"
                >
                  Get the App now !
                </h4>
                <p
                  style={{ fontFamily: 'Nunito' }}
                  className="text-muted para-desc mx-auto"
                >
                  Start working with Resumás - your missing link to interviews.
                  Kickstart your interview process.
                </p>
                <div className="mt-2">
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      window.open(
                        'https://apps.apple.com/us/app/resumas/id1640272499',
                      )
                    }
                    className=""
                  >
                    <img src={ios} style={{ width: 130, height: 50 }} />
                  </span>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.resumas',
                      )
                    }
                    className=""
                  >
                    <img
                      src={android}
                      style={{ width: 130, height: 50, marginLeft: 20 }}
                    />
                  </span>
                </div>
                <div className="mt-2">
                  <span>
                    {/* iOS QR */}
                    <QRCode
                      style={{ width: 130, height: 130 }}
                      value="https://apps.apple.com/us/app/resumas/id1640272499"
                    />
                  </span>
                  <span>
                    {/* Android QR */}
                    <QRCode
                      style={{ width: 130, height: 130, marginLeft: 20 }}
                      value="https://play.google.com/store/apps/details?id=com.resumas"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
});
