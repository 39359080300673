import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import toastr from 'toastr';
import { BeatLoader } from 'react-spinners';
import { basicInfo } from './redux/action';
import { currentUser } from '../auth/redux/actions';
import { sendVCode, updatePhoneNo } from '../auth/redux/actions';
import { Input } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import { validateEmail } from '../../utils/emailValidation';
import { styles } from './style';
import { css } from '@emotion/core';
import Loader from '../account/loader';

const CodeVerifyCompoenents = React.memo(
  ({ type, handleVCode, handleConfirm, onClose, submitCode }) => {
    const [vCode, setVCode] = React.useState('');
    const [notMatch, setNotMacth] = React.useState(false);

    const onChange = (e) => {
      setVCode(e.target.value);
      handleVCode(e);
    };

    return (
      <div className="custom-ui">
        <h3>Enter {type} verification code?</h3>
        <Col md={12}>
          <div className="form-group position-relative">
            <label>
              Your Code <span className="text-danger">*</span>
            </label>
            <i className="mdi mdi-account ml-3 icons"></i>
            <input
              type="number"
              className="form-control pl-5"
              placeholder="Verification Code"
              onChange={onChange}
              value={vCode}
            />
          </div>
        </Col>
        <Col md={12}>
          {notMatch ? (
            <div style={styles.textAlignCenter}>
              Incorrect verification code.
            </div>
          ) : null}
        </Col>
        <div className="buttons">
          <a className="button1"></a>
          <a onClick={submitCode} className="button1" style={{ color: 'blue' }}>
            Resend
          </a>
        </div>
        <div className="buttons">
          <a onClick={onClose} className="button1">
            Cancel
          </a>
          <a
            onClick={() => handleConfirm(type, onClose, setNotMacth)}
            className="button1"
          >
            Confirm
          </a>
        </div>
      </div>
    );
  },
);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Basic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      email: '',
      city: '',
      country: '',
      gender: '',
      phone: '',
      education: '',
      emailNotifications: true,
      birthDate: '',
      loader: false,
      isPhoneVerified: false,
      vCode: '',
      userLoading: false,
    };
  }

  componentDidMount() {
    let { user } = this.props;

    if (user) {
      this.setState({
        ...user,
        fname: user.firstName,
        lname: user.lastName,
        emailNotifications: user.preferences?.emailNotifications,
      });
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.user) {
      this.setState({
        ...nextProps?.user,
        fname: nextProps?.user?.firstName,
        lname: nextProps?.user?.lastName,
        emailNotifications: nextProps?.user?.preferences?.emailNotifications,
      });
    }
  };

  handleVCode = (e) => {
    this.setState({
      vCode: e.target.value,
    });
  };

  handleChange = (e) => {
    this.setState({ emailNotifications: e.target.checked });
  };
  inputControl = (e) => {
    const { name } = e.target;
    this.setState({
      [name]: e.target.value,
    });
  };
  callBack = () => {
    this.setState({ userLoading: false });
  };
  cb = () => {
    this.setState({ loader: false });
    this.props.currentUser(this.callBack);
  };

  saveInfo = () => {
    if (
      this.state.fname === '' ||
      this.state.lname === ''
      // this.state.email === "" ||
      // this.state.phone === ""
    ) {
      toastr.error('fields can not be empty!');
    } else if (this.state.email === '') {
      toastr.error('Email can not be empty');
    } else if (!validateEmail(this.state.email)) {
      toastr.error('Email is not valid');
    } else {
      this.setState({
        loader: true,
      });
      this.props.basicInfo(
        {
          firstName: this.state.fname,
          lastName: this.state.lname,
          email: this.state.email,
          phone: this.state.phone,
          emailNotifications: this.state.emailNotifications.toString(),
        },
        this.cb,
      );
    }
  };

  handlePhone = (e) => {
    this.setState({
      phone: e.target.value,
      isPhoneVerified: false,
    });
  };

  submit = (type = 'phone') => {
    if (this.state.phone && this.state.phone.length) {
      this.props.sendVCode('mobile', this.state.phone);
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CodeVerifyCompoenents
            handleVCode={this.handleVCode}
            type={type}
            handleConfirm={this.handleConfirm}
            submitCode={() => {
              if (this.state.phone && this.state.phone.length) {
                this.props.sendVCode('mobile', this.state.phone);
              }
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  handleConfirm = (type, cb, setNotMacth) => {
    let vCode = this.state.vCode;
    vCode = parseInt(vCode);
    if (type === 'email') {
      if (vCode === this.props.eVCode) {
        setNotMacth(false);
        this.setState({
          isEmailVerified: true,
          notMatch: false,
        });
        cb();
      } else {
        setNotMacth(true);
        this.setState({
          notMatch: true,
        });
      }
    } else {
      if (vCode === this.props.pVCode) {
        setNotMacth(false);
        this.props.updatePhoneNo({
          type: 'verified',
          mobile: this.state.phone,
        });
        this.setState({
          isPhoneVerified: true,
          notMatch: false,
        });
        cb();
      } else {
        setNotMacth(true);

        this.setState({
          notMatch: true,
        });
      }
    }
  };

  render() {
    const isVerified = this.props.user?.vType === 'verified';
    if (this.props.loading || this.state.userLoading) {
      return <Loader />;
    }
    return (
      <div className="change_font_style">
        <div
          style={{ ...styles.fontWeight_bold, marginLeft: 5, paddingLeft: 15 }}
        >
          BASICS
        </div>
        <div style={styles.bb_ligthGrey}></div>
        <Row style={(styles.mt_30, { marginLeft: 5, marginRight: 5 })}>
          <Col lg="6" sm="12" md="6" xs="12" style={styles.mb_10}>
            <span>First Name:</span>
            <Input
              style={{ paddingTop: 10, paddingBottom: 10 }}
              placeholder="First Name"
              onChange={this.inputControl}
              name="fname"
              value={this.state.fname}
            />
          </Col>
          <Col lg="6" sm="12" md="6" xs="12" style={styles.mb_10}>
            <span>Last Name:</span>
            <Input
              style={{ paddingTop: 10, paddingBottom: 10 }}
              placeholder="Last Name"
              onChange={this.inputControl}
              name="lname"
              value={this.state.lname}
            />
          </Col>

          <Col lg="6" sm="12" xs="12" md="12" style={styles.mb_10}>
            <span>Email:</span>
            {/* <Search
              className="change_button_color"
              readOnly
              placeholder="example@gmail.com"
              onChange={this.inputControl}
              name="email"
              value={this.state.email}
              enterButton="VERIFY EMAIL"
            /> */}
            <Input
              style={{ paddingTop: 10, paddingBottom: 10 }}
              className="change_button_color"
              readOnly
              placeholder="example@gmail.com"
              onChange={this.inputControl}
              name="email"
              value={this.state.email}
            />
          </Col>
          <Col lg="6" sm="12" xs="12" md="12" style={styles.mb_10}>
            <span>Phone:</span>
            <Input
              style={{
                minWidth: 100,
                paddingTop: 10,
                paddingBottom: 10,
              }}
              // readOnly={isVerified}
              className="change_button_color"
              type="number"
              value={this.state.phone}
              placeholder="+16505130550"
              onSearch={() => {
                if (isVerified) {
                  return;
                }
                this.submit('phone');
              }}
              onChange={this.handlePhone}
              enterButton={isVerified ? 'Verified' : 'VERIFY'}
            />
          </Col>
          <Col lg="12" style={{ ...styles.mb_10, ...styles.textAlignCenter }}>
            <Button
              className="save_education_butn"
              onClick={this.saveInfo}
              style={styles.saveEduBtn}
            >
              {this.state.loader ? (
                <BeatLoader
                  css={override}
                  sizeUnit={'px'}
                  size={10}
                  color={'white'}
                  loading={true}
                />
              ) : (
                'Save'
              )}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser, profileReducer }) => {
  return {
    profileStatus: profileReducer.profileStatus,
    updator: profileReducer.updator,
    user: authUser.user,
    pVCode: authUser.pVCode,
    eVCode: authUser.eVCode,
  };
};

export default connect(mapStateToProps, {
  basicInfo,
  sendVCode,
  updatePhoneNo,
  currentUser,
})(Basic);
