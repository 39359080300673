import React, { useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import { Tooltip } from 'react-tippy';
import { onBlurField } from '../../redux/actions';
import { formStepsIndexes, formStepsKey } from '../constants/steps';
//eslint-disable-next-line
import { useStyles } from '../style';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';

export default function ToolTip() {
  const width = useWindowWidth();
  const classes = useStyles();
  const [steps, setSteps] = React.useState([]);
  let dispatch = useDispatch();
  let { resumeData } = useSelector((state) => state.editorReducer);

  function getList() {
    let list = [];
    formStepsKey.forEach((item) => {
      if (resumeData[item]) {
        let i = {
          label: resumeData[item]?.heading,
          index: formStepsIndexes[item],
          visible: resumeData[item]?.visible,
        };
        list.push(i);
      }
    });
    setSteps([...list]);
  }
  useEffect(() => {
    getList();
  }, [resumeData]);

  const handleChange = (data) => {
    const objIndex = steps.findIndex((obj) => obj.label === data);
    steps[objIndex].visible = !steps[objIndex].visible;
    dispatch(
      onBlurField(steps[objIndex].visible, `${formStepsKey[objIndex]}.visible`),
    );
    setSteps([...steps]);
  };
  return (
    <div
      style={{ marginBottom: -50, marginTop: width < 815 ? 150 : 50 }}
      className={` ${classes.choiceChipList} ${classes.stepperScroll} ${classes.root}  `}
    >
      {steps?.map((item, index) => {
        return (
          <Tooltip
            key={index}
            title={
              item.visible
                ? 'That blue section already in resume'
                : 'Option section can be edit( click on it to add )'
            }
            arrow
            distance={50}
          >
            <Chip
              label={item.label}
              clickable
              color={item.visible ? 'primary' : 'default'}
              className={classes.choiceChip}
              onClick={() => handleChange(item.label)}
            />
          </Tooltip>
        );
      })}
    </div>
  );
}
