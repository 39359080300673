import { colors } from '../../commonComponents/colors';

export const styles = {
  flex: {
    display: 'flex',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  width_100_percent: {
    width: '100%',
  },
  width_80_percent: {
    width: '80%',
  },
  fontWeight_400: {
    fontWeight: 400,
  },
  fontWeight_bold: {
    fontWeight: 'bold',
  },
  fontSize_1_rem: {
    fontSize: '1rem',
  },
  p_0: { padding: 0 },
  ml_5_px: { marginLeft: '5px' },
  mt_5: {
    marginTop: 5,
  },
  mt_30: { marginTop: 30 },
  mt_20: {
    marginTop: 20,
  },
  mt_10: {
    marginTop: 10,
  },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_90: {
    marginBottom: 90,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  btnModel: {
    height: '82%',
    color: 'white',
    backgroundColor: '#4267B2',
    borderRadius: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  imgUploadModel: {
    fontWeight: '600',
    color: 'white',
    cursor: 'pointer',
    fontSize: '20px',
    margin: 0,
    paddingLeft: 8,
    paddingRight: 8,
  },
  inputModel: {
    // border: "1px solid #F1F1F1",
    // height: "300px",
    // width: "90vw",
    // marginTop: 15,
    marginTop: 15,
    marginBottom: 15,
    width: '100%',
    height: '90vh',
    paddingRight: 30,
    paddingLeft: 5,
  },
  fileCopyIcon: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    marginLeft: 6,
    marginRight: 6,
    marginTop: 3,
  },
  delIcon: {
    cursor: 'pointer',
    fontSize: '1.1rem',
    color: colors.red,
    marginTop: 3,
  },
  h3: {
    fontSize: '1.3rem',
    display: 'inline-block',
    fontWeight: 'bold',
    fontFamily: 'inherit',
    // letterSpacing: "3px",
    marginBottom: 10,
    color: 'black',
  },
  tableCell: {
    paddingTop: 40,
    paddingBottom: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningColor: {
    backgroundColor: '#FFCC00',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 40,
    color: 'white',
  },
  dangerColor: {
    backgroundColor: '#F45B40',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 40,
    color: 'white',
  },
  rowSpan: {
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-around',
  },
  positinRelative: {
    position: 'relative',
  },
  polar: {
    width: '100%',
    height: '400px',
  },
  summaryTypography: {
    fontWeight: 400,
    textAlign: 'center',
    marginTop: 20,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  zIndex: {
    zIndex: 1,
    marginBottom: 10,
  },
  notesModel: {
    position: 'relative',
    flex: 1,
    // bottom: 5,
  },
  notesModelIcon: {
    cursor: 'pointer',
    fontSize: '1.1rem',
  },

  closeBtn: {
    position: 'absolute',
    right: 2,
    top: 2,
    zIndex: 99,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    color: colors.red,
    fontSize: 24,
  },
  switchButtons: {
    backgroundColor: colors.red,
    width: '100%',
    padding: 20,
    color: 'white',
    cursor: 'pointer',
  },
  builderContainer: (width) => {
    return {
      width: '100%',
      display: 'flex',
      paddingTop:
        width && width > 500
          ? width < 600
            ? 100
            : width < 768
            ? 170
            : width < 885
            ? 75
            : width < 1080
            ? 60
            : 40
          : 100,
      flexDirection: 'column',
      justifyContent: 'center',
    };
  },
  builderTopBarMain: (width) => {
    return {
      position: 'fixed',
      top: width < 750 ? '3.6rem' : width < 1250 ? '5rem' : '5rem',
      height: width < 750 ? '250px' : width < 1000 ? '200px' : '150px',
      background: 'rgb(247, 247, 247)',
      flex: 1,
      left: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 99,
    };
  },
  builderTopBar: (width) => {
    return {
      maxWidth: 'fit-content',
      width: '88vw',
      margin: width < 500 ? '0px 0px 0px 10px' : '0 auto',
      // gap: '5px',
      display: 'flex',
      height: '100%',
      flexDirection: width < 1000 ? 'column' : 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    };
  },
  width_300_px: { width: '300px' },
  width_630_px: { width: '630px' },
  builderTitleMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  builderTitle: {
    fontWeight: 800,
    fontSize: '22px',
    lineHeight: '34.13px',
    fontFamily: 'Montserrat',
    color: '#4A4A4A',
    marginRight: '5px',
  },
  tooltipCard: {
    minWidth: '30rem',
    borderRadius: 10,
    backgroundColor: '#edf0f7',
    borderWidth: 0,
    padding: 0,
  },
  alignTooltip: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipIcon: {
    width: 40,
    height: 40,
    marginRight: 20,
    color: '#576baa',
    backgroundColor: '"#576baa"',
  },
  tooltipText: {
    fontSize: 12,
    color: '#576baa',
    marginTop: 20,
    fontWeight: 'bold',
  },
  buttonMain: (width) => {
    return {
      flexDirection: 'row',
      display: 'flex',
      // width: '640px',
      marginBottom: width < 1000 ? '20px' : '0px',
      // justifyContent: 'flex-end',
    };
  },
};
