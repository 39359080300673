import React, { useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import {
  CLEAR_MILITARY_DATA,
  CLEAR_SELECTED_SUBTITLE,
  INTRO_MILXWALK_VIDEO_MODAL,
  MILITARY_DATA_API_STATUS,
  ON_ADD_SELECTED_JOB_OCCUPATION,
  ON_ADD_SELECTED_SUBTITLE,
  ON_REMOVE_SELECTED_JOB_OCCUPATION,
  ON_REMOVE_SELECTED_SUBTITLE,
  UPDATE_MILITARY_BRANCH,
  UPDATE_MILITARY_CODE,
  UPDATE_MILXWALK_ACTIVE_STEP,
} from './redux/actions.constants';
import {
  getJobOccupation,
  getMilitaryInfo,
  getMilxWalkDetails,
  getSubtitles,
  getSuggestedInformation,
  resetMilitaryInfo,
} from './redux/actions';
import url from '../../config/endpoint';
import Axios from 'axios';

export default function useMilitaryCrosswalk() {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const isSmall = width < 700;

  const [militarySteps] = React.useState([
    {
      label: 'Start',
      content: 'Military-to-Civilian Crosswalk Explorer',
    },
    {
      label: 'Select Civilian Job Titles',
      content: 'Select Civilian Job Titles',
    },
    {
      label: 'Review',
      content: 'Review Your Results',
    },
    {
      label: 'Report',
      content: '',
    },
  ]);

  const {
    jobOccupationLoader,
    jobOccupation,
    selectedJobOccupations,
    suggestedInformation,
    suggestionDataExist,
    branch,
    code,
    subtitles,
    milXWalkLoading,
    selectedSubtitles,
    milXWalkActiveStep,
    resetLoading,
    isGetApi,
    tabValue,
    milXwalkDetail,
    milXwalkDetailExist,
    jobSubtitleLoader,
    suggestedInformationLoader,
    isEmptyOccupations,
  } = useSelector((state) => state.militaryReducer);

  const { user, video } = useSelector((state) => {
    return {
      user: state.authUser?.user,
      video: state?.fileScanReducer?.videoLinks?.milXwalkIntro,
    };
  });
  const { milXwalkVideoModal } = user;
  const [currentTitle, setCurrentTitle] = React.useState(
    militarySteps[milXWalkActiveStep]?.label,
  );
  const handleVideoModal = async (payload) => {
    try {
      dispatch({ type: INTRO_MILXWALK_VIDEO_MODAL, payload });
      await Axios.post(`${url}/update-milXwalk-modal`, {
        userId: user?._id,
      });
    } catch (error) {
      console.log('error updating video modal:', error);
    }
  };
  const onGetSuggestionInformation = (occupations, militaryData) => {
    const userId = localStorage.getItem('user_id');
    let data = {
      jobOccupations:
        selectedJobOccupations?.length > 0
          ? selectedJobOccupations
          : occupations,
      userId,
      branch: branch?.value ? branch?.value : militaryData?.branch?.value,
      code: code?.code ? code?.code : militaryData?.code?.code,
    };

    if (!suggestionDataExist) dispatch(getSuggestedInformation(data));
  };

  const onAddJobOccupation = (item) => {
    if (selectedJobOccupations?.length === 3) {
      toastr.error('You can select only three job titles');
    } else {
      dispatch({
        type: ON_ADD_SELECTED_JOB_OCCUPATION,
        payload: item,
      });
    }
  };

  const onRemoveJobOccupation = (itemToRemove) => {
    dispatch({
      type: ON_REMOVE_SELECTED_JOB_OCCUPATION,
      payload: itemToRemove,
    });
  };
  const onAddSubtitle = (itemToAdd) => {
    if (selectedSubtitles?.length === 1) {
      toastr.error('You can select only 1 subtitle');
    } else {
      dispatch({
        type: ON_ADD_SELECTED_SUBTITLE,
        payload: itemToAdd,
      });
    }
  };

  const onRemoveSubtitle = (itemToRemove) => {
    dispatch({
      type: ON_REMOVE_SELECTED_SUBTITLE,
      payload: itemToRemove,
    });
  };

  let options = [
    { value: 'Air Force (AFSC)', label: 'Air Force (AFSC)' },
    { value: 'Army (MOS)', label: 'Army (MOS)' },
    {
      value: 'Coast Guard (Ratings)',
      label: 'Coast Guard (Ratings)',
    },
    { value: 'Marine Corps (MOS)', label: 'Marine Corps (MOS)' },
    { value: 'Navy (Ratings)', label: 'Navy (Ratings)' },
    {
      value: 'Space Force (SFSC)',
      label: 'Space Force (SFSC)',
    },
  ];
  const onGetMilxWalkDetail = () => {
    if (!milXwalkDetailExist) {
      const subtitle =
        JSON.parse(localStorage.getItem('selectedSubtitle')) || [];
      let data = {
        SOC: subtitle[0]?.SOC || selectedSubtitles[0]?.SOC,
      };
      dispatch(getMilxWalkDetails(data));
    }
  };
  const handleStepTwo = () => {
    onGetSuggestionInformation();
    onGetMilxWalkDetail();
    localStorage.setItem(
      'selectedJobOccupations',
      JSON.stringify(selectedJobOccupations),
    );
  };
  const handleStepOne = () => {
    if (jobOccupation?.length === 0) {
      const subtitle =
        JSON.parse(localStorage.getItem('selectedSubtitle')) || [];
      const data = {
        branch: branch?.value,
        code: code?.code,
        title: code?.title,
        SOC: selectedSubtitles[0]?.SOC || subtitle[0]?.SOC,
        userId: user?._id,
      };

      dispatch(getJobOccupation(data));
    }
  };
  const handleNext = () => {
    if (milXWalkActiveStep === 0) {
      dispatch({
        type: MILITARY_DATA_API_STATUS,
        payload: true,
      });
      localStorage.setItem(
        'selectedSubtitle',
        JSON.stringify(selectedSubtitles),
      );
      handleStepOne();
      localStorage.setItem('milXWalkData', JSON.stringify({ branch, code }));
    }
    if (milXWalkActiveStep === 1 && selectedJobOccupations?.length > 0) {
      handleStepTwo();
    }
    if (milXWalkActiveStep === 3) {
      return;
    }
    dispatch({
      type: UPDATE_MILXWALK_ACTIVE_STEP,
      payload: milXWalkActiveStep + 1,
    });
    localStorage.setItem('milXWalkActiveStep', milXWalkActiveStep + 1);
  };

  const handleBack = () => {
    if (milXWalkActiveStep === 0) {
      return;
    }
    dispatch({
      type: UPDATE_MILXWALK_ACTIVE_STEP,
      payload: milXWalkActiveStep - 1,
    });
    localStorage.setItem('milXWalkActiveStep', milXWalkActiveStep - 1);
  };

  const handleReset = () => {
    if (resetLoading) return;
    dispatch(
      resetMilitaryInfo(
        {
          userId: user?._id ? user?._id : localStorage.getItem('user_id'),
        },
        () => {
          dispatch({
            type: CLEAR_MILITARY_DATA,
          });
          localStorage.removeItem('milXWalkData');
          localStorage.setItem('milXWalkActiveStep', '0');
          localStorage.removeItem('selectedJobOccupations');
          localStorage.removeItem('selectedSubtitle');
          dispatch({
            type: UPDATE_MILXWALK_ACTIVE_STEP,
            payload: 0,
          });
        },
      ),
    );
  };
  const getOptions = async (input) => {
    try {
      const response = await Axios.post(
        `${url}/code-options?branch=${branch.value}&search=${input}`,
      );
      if (response?.data?.length > 0) {
        const options = response?.data?.map((item) => ({
          label: item?.matched,
          value: item?.matched,
          code: item?.code,
          title: item?.title,
        }));
        return options;
      } else return [];
    } catch (error) {
      toastr.error('Something went Wrong');
      console.log('error:', error);
      return [];
    }
  };
  const loadOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const options = await getOptions(inputValue);
        resolve(options);
      }, 300);
    });
  };
  const subtitleCb = () => {
    localStorage.setItem('milXWalkActiveStep', '1');
    dispatch({
      type: MILITARY_DATA_API_STATUS,
      payload: true,
    });
    handleStepOne();
    dispatch({
      type: UPDATE_MILXWALK_ACTIVE_STEP,
      payload: 1,
    });
  };
  const onSearchHandler = () => {
    dispatch({
      type: CLEAR_SELECTED_SUBTITLE,
    });
    if (branch?.value) {
      if (code?.value) {
        let data = {
          branch: branch?.value,
          code: code?.code,
          title: code?.title,
        };
        dispatch(getSubtitles(data, subtitleCb));
      } else {
        toastr.error('Please add correct MOC code or title');
      }
    } else {
      toastr.error('Please select military branch and military code');
    }
  };

  const onSelectMilitaryBranch = (payload) => {
    dispatch({
      type: UPDATE_MILITARY_BRANCH,
      payload,
    });
    dispatch({
      type: UPDATE_MILITARY_CODE,
      payload: '',
    });
  };

  const onChangeMilitaryCode = (payload) => {
    dispatch({
      type: UPDATE_MILITARY_CODE,
      payload,
    });
  };
  const isDisabledNext = () => {
    if (milXWalkActiveStep === 0 && selectedSubtitles?.length === 0)
      return true;
    if (milXWalkActiveStep === 1 && selectedJobOccupations?.length < 3)
      return true;
    if (milXWalkActiveStep === 2 && suggestedInformationLoader) return true;
    if (milXWalkActiveStep === 2 && !suggestionDataExist) return true;
    return false;
  };
  const isDisabledBack = () => {
    if (milXWalkActiveStep === 0 || milXWalkActiveStep === 1) return true;
    return false;
  };
  const isStepDisabled = (index) => {
    if (index === 0) return true;
    if (milXWalkActiveStep === index) return true;
    if (milXWalkActiveStep === 0 && selectedSubtitles?.length === 0)
      return true;
    if (
      milXWalkActiveStep === 2 &&
      suggestedInformationLoader &&
      (index === 3 || index === 2)
    )
      return true;

    if (
      (milXWalkActiveStep === 1 || milXWalkActiveStep === 0) &&
      selectedJobOccupations?.length < 3 &&
      (index === 2 || index === 3)
    )
      return true;

    if (!suggestionDataExist && index === 3) return true;
    return false;
  };

  React.useEffect(() => {
    setCurrentTitle(militarySteps[milXWalkActiveStep]?.content);
  }, [milXWalkActiveStep]);

  React.useEffect(() => {
    dispatch({
      type: UPDATE_MILXWALK_ACTIVE_STEP,
      payload: parseInt(localStorage.getItem('milXWalkActiveStep') || '0'),
    });
    if (!isGetApi) {
      dispatch(
        getMilitaryInfo({
          userId: user?._id ? user?._id : localStorage.getItem('user_id'),
        }),
      );
    }
  }, []);

  return {
    militarySteps,
    milXWalkActiveStep,
    currentTitle,
    isSmall,
    options,
    jobOccupationLoader,
    jobOccupation,
    selectedJobOccupations,
    suggestedInformation,
    militaryData: {
      code,
      branch,
    },
    user,
    subtitles,
    selectedSubtitles,
    branch,
    code,
    suggestionDataExist,
    loadOptions,
    handleNext,
    handleBack,
    handleReset,
    setCurrentTitle,
    onSelectMilitaryBranch,
    onChangeMilitaryCode,
    onSearchHandler,
    onAddJobOccupation,
    onRemoveJobOccupation,
    onGetSuggestionInformation,
    milXWalkLoading,
    onAddSubtitle,
    onRemoveSubtitle,
    isDisabledNext: isDisabledNext(),
    isDisabledBack: isDisabledBack(),
    handleStepTwo,
    isStepDisabled,
    tabValue,
    milXwalkDetail,
    milXwalkDetailExist,
    jobSubtitleLoader,
    resetLoading,
    handleStepOne,
    handleVideoModal,
    milXwalkVideoModal,
    video,
    handleStepOne,
    isEmptyOccupations,
    width,
  };
}
