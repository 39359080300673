import React, { Component } from 'react';
import { Row, Card, CardBody, Table, CardTitle } from 'reactstrap';
import { Colxx } from '../../commonComponents/customBootstrap';
import { MdCheck, MdClear } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import ModalRescan from '../save&rescan-modal';
import { connect } from 'react-redux';
import { getAvoidWords } from '../../commonRedux/scan/action';
import { jobTitles } from '../scan/jobTitle';
import { styles } from '../save&rescan-modal/style';
import { colors } from '../../commonComponents/colors';

class RECRUITERFINDINGS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveavoidWords: [],
      isJobFound: false,
      width: 0,
      height: 0,
    };
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    this.props.getAvoidWords();
    let newData = jobTitles.filter((i) => {
      return i === this.props.title;
    });
    if (newData.length) {
      this.setState({ isJobFound: true });
    } else {
      this.setState({ isJobFound: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  static getDerivedStateFromProps(nextProps) {
    if (nextProps.avoidWords) {
      return {
        saveavoidWords: nextProps.avoidWords,
      };
    }
  }
  render() {
    let headingStyle = {};
    let subHeadingStyle = { verticalAlign: 'center' };
    if (this.props.showOnlyMissing) {
      headingStyle = { fontSize: 16 };
      subHeadingStyle = { fontSize: 12 };
    }
    return (
      <Row>
        <Colxx xxs="12" sm="12" lg="12">
          <Card className="mb-2">
            <CardBody style={styles.recruiter_cardBody}>
              <CardTitle
                style={{
                  maxWidth: this.state.width < 500 ? '220px' : '400px',
                  ...styles.fontWeight_heading,
                  ...headingStyle,
                  marginBottom: 7,
                }}
              >
                JOB REQUIREMENTS MATCH
                <div style={{ position: 'absolute', right: 15, top: 20 }}>
                  {this.props.fromState === 'yes' ? null : (
                    <ModalRescan
                      goBuilder={this.props.goBuilder}
                      percent={this.props.totalScore}
                      skills={this.props.skills}
                      description={{
                        resume: this.props.resume,
                        job: this.props.job,
                        is_parsed: this.props.is_parsed,
                      }}
                      oid={this.props.oid}
                      resumeId={this.props.resumeId}
                      company={this.props.company}
                      jobTitle={this.props.jobTitle}
                    />
                  )}
                </div>
              </CardTitle>
              <Table hover style={{ tableLayout: 'fixed', overflow: 'auto',}}>
                <tbody style={{ display: 'block' }}>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        ...styles.fontWeight_400,
                        ...subHeadingStyle,
                      }}
                    >
                      JOB TITLE MATCH
                      <Tooltip
                        title="That will show you the percentage of Job title match"
                        arrow
                      >
                        <HelpIcon style={styles.cursorPointer} />
                      </Tooltip>
                    </th>
                    <td style={{ width: window.innerWidth < 500 ? '15%' : '' }}>
                      {!this.props.ats?.ats_data?.job_title_matched
                        ?.isMatched ? (
                        <MdClear size="1.7em" color={colors.red} />
                      ) : (
                        <MdCheck size="1.7em" color={colors.green} />
                      )}
                    </td>
                    <td
                      style={{
                        width: window.innerWidth < 500 ? '' : '75%',
                        ...styles.colorBlack,
                      }}
                    >
                      {this.props.ats?.ats_data?.job_title_matched?.isMatched
                        ? 'Excellent!'
                        : this.props.ats?.ats_data?.job_title_matched?.shows ===
                            'unknown' &&
                          this.props.ats?.ats_data?.job_title_matched
                            ?.requires === 'unknown'
                        ? 'Unknown!'
                        : 'Missing!'}{' '}
                      The job requires{' '}
                      <span style={{ color: '#145388' }}>
                        {' '}
                        {this.props.ats?.ats_data?.job_title_matched?.requires}
                      </span>{' '}
                      title
                      {this.props.ats?.ats_data?.job_title_matched?.isMatched
                        ? ' and your resume  does show the same title.'
                        : this.props.ats?.ats_data?.job_title_matched?.shows ===
                            'unknown' &&
                          this.props.ats?.ats_data?.job_title_matched
                            ?.requires === 'unknown'
                        ? ' and your resume shows unknown degree.'
                        : ' and your resume does NOT show the same title.'}
                    </td>
                  </tr>
                  {this.props.showOnlyMissing &&
                  this.props.ats?.ats_data?.manage_level?.isMatched ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{
                          ...styles.fontWeight_400,
                          ...subHeadingStyle,
                        }}
                      >
                        MANAGEMENT LEVEL
                        <Tooltip
                          title="That will show your management level"
                          arrow
                        >
                          <HelpIcon style={styles.cursorPointer} />
                        </Tooltip>
                      </th>
                      <td>
                        {!this.props.ats?.ats_data?.manage_level?.isMatched ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                      <td style={{ width: '1300px', ...styles.colorBlack }}>
                        {this.props.ats?.ats_data?.manage_level?.message}
                      </td>
                    </tr>
                  )}
                  {this.props.showOnlyMissing &&
                  this.props.ats?.ats_data?.education_match
                    ?.isMatched ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{
                          ...styles.fontWeight_400,
                          ...subHeadingStyle,
                        }}
                      >
                        EDUCATION MATCH
                        <Tooltip
                          title="That will show you the percentage of Education match"
                          arrow
                        >
                          <HelpIcon style={styles.cursorPointer} />
                        </Tooltip>
                      </th>
                      <td>
                        {!this.props.ats?.ats_data?.education_match
                          ?.isMatched ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>

                      <td style={{ width: '45%', ...styles.colorBlack }}>
                        {this.props.ats?.ats_data?.education_match?.shows ===
                          'unknown' &&
                        this.props.ats?.ats_data?.education_match?.requires ===
                          'unknown' ? (
                          'Missing! We could not find education/degree in either in the Job Description and/or Resume.'
                        ) : (
                          <>
                            {this.props.ats?.ats_data?.education_match
                              ?.isMatched
                              ? 'Excellent!'
                              : 'Warning!'}{' '}
                            The job requires{' '}
                            <span style={{ color: '#145388' }}>
                              {' '}
                              {
                                this.props.ats?.ats_data?.education_match
                                  ?.requires
                              }
                            </span>{' '}
                            degree and your resume shows{' '}
                            <span style={{ color: '#145388' }}>
                              {' '}
                              {this.props.ats?.ats_data?.education_match?.shows}
                            </span>{' '}
                            degree(s).
                          </>
                        )}
                      </td>
                    </tr>
                  )}
                  {this.props.showOnlyMissing &&
                  this.props.ats?.ats_data?.job_level_match
                    ?.isMatched ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{
                          ...styles.fontWeight_400,
                          ...subHeadingStyle,
                        }}
                      >
                        EXPERIENCE LEVEL
                        <Tooltip
                          title="That will show your experience level"
                          arrow
                        >
                          <HelpIcon style={styles.cursorPointer} />
                        </Tooltip>
                      </th>
                      <td>
                        {!this.props.ats?.ats_data?.job_level_match
                          ?.isMatched ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                      <td style={{ width: '45%', ...styles.colorBlack }}>
                        {this.props.ats?.ats_data?.job_level_match?.message}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ fileScanReducer }) => {
  return {
    avoidWords: fileScanReducer.avoidWords,
  };
};

export default connect(mapStateToProps, { getAvoidWords })(
  withRouter(RECRUITERFINDINGS),
);
