import React, { memo } from 'react';
import { Row, Card, CardBody, Table, CardTitle } from 'reactstrap';
import { Colxx } from '../../commonComponents/customBootstrap';
import { MdCheck, MdClear, MdInfo } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import ModalRescan from '../save&rescan-modal';
import { styles } from './style';
import { GoIssueOpened } from 'react-icons/go';
import { AiOutlineCloseCircle } from 'react-icons/ai';
// import { jobTitles } from '../scan/jobTitle';
// import { getAvoidWords } from '../../commonRedux/scan/action';
import { useWindowSize } from '@react-hook/window-size';
import { colors } from '../../commonComponents/colors';

const getMeasureableResult = (percent) => {
  if (percent) {
    return `Excellent! We found ${percent} instances of measurable metrics. `;
  } else {
    return 'Missing! We found 0 instances of measurable metrics. ';
  }
};

const getParseCount = (word_count) => {
  var data_string = 'N';
  if (word_count > 450 && word_count < 700) {
    data_string = (
      <>
        Excellent! Your resume has{' '}
        <span
          style={{
            ...styles.fontWeight_bold,
            color: colors.green,
          }}
        >
          {' ' + word_count + ' '}
        </span>{' '}
        words within the optimal range of 450~700
      </>
    );
  } else if (word_count > 300 && word_count < 1000) {
    data_string = (
      <>
        Good! Your resume has{' '}
        <span
          style={{
            ...styles.fontWeight_bold,
            color: colors.green,
          }}
        >
          {' ' + word_count + ' '}
        </span>{' '}
        words in the average range of 300~1000 words
      </>
    );
  } else {
    data_string = (
      <>
        Needs Improvement! Your resume has{' '}
        <span
          style={{
            ...styles.fontWeight_bold,
            color: colors.red,
          }}
        >
          {' ' + word_count + ' '}
        </span>{' '}
        words and and is outside average range of 300~1000. Acceptable
        exceptions: Entry-Level resume or 3+ pages of resume with 10+ years of
        experience.
      </>
    );
  }

  return data_string;
};

const ResumeResult = memo((props) => {
  const word_count = props.scores?.counts.word_count;

  // const [state, setState] = React.useState({
  //   saveavoidWords: [],
  //   isJobFound: false,
  // });
  const [width] = useWindowSize();

  // useEffect(() => {
  //   getAvoidWords();
  //   let newData = jobTitles.filter((i) => {
  //     return i === props.title;
  //   });
  //   if (newData.length) {
  //     setState({ isJobFound: true });
  //   } else {
  //     setState({ isJobFound: false });
  //   }
  // }, []);

  const isLinkedIn = props.data?.profile?.linkedinURL;
  let headingStyle = {};
  let subHeadingStyle = { verticalAlign: 'center', color: 'black' };
  let CardStyles = {};
  if (props.showOnlyMissing) {
    headingStyle = { fontSize: 16 };
    subHeadingStyle = { fontSize: 12, paddingLeft: 0 };
    CardStyles = {
      paddingTop: 15,
    };
  }
  return (
    <div>
      <Row>
        <Colxx xxs="12" sm="12" lg="12">
          <Card className="mb-2">
            <CardBody
              style={{
                ...styles.recruiter_cardBody,
                background: props.showOnlyMissing ? '#EDF0F7' : 'white',
                border: props.showOnlyMissing ? '1px solid lightgray' : 'none',
                ...CardStyles,
                paddingBottom: 15,
              }}
            >
              <CardTitle
                style={{
                  maxWidth: width < 500 ? '220px' : '400px',
                  ...styles.fontWeight_heading,
                  ...headingStyle,
                  marginBottom: 7,
                }}
              >
                RESUME FOR ATS-RESULTS
                <div style={{ position: 'absolute', right: 15, top: 20 }}>
                  {props.fromState === 'yes' ? null : (
                    <ModalRescan
                      goBuilder={props.goBuilder}
                      percent={props.totalScore}
                      skills={props.skills}
                      description={{
                        resume: props.resume,
                        job: props.job,
                        is_parsed: props.is_parsed,
                      }}
                      oid={props.oid}
                      resumeId={props.resumeId}
                      company={props.company}
                      jobTitle={props.jobTitle}
                    />
                  )}
                </div>
              </CardTitle>
              <Table style={{ tableLayout: 'fixed', overflow: 'auto' }}>
                <tbody style={{ display: 'block' }}>
                  {props.showOnlyMissing &&
                  props.ats?.ats_data?.sections?.length ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{ ...styles.fontWeight_400, ...subHeadingStyle }}
                      >
                        SECTION HEADINGS
                      </th>
                      <td style={{ width: width < 500 ? '15%' : '' }}>
                        {!props.ats?.ats_data?.sections?.length ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                      {props.ats?.ats_data?.sections?.length ? (
                        <td
                          style={{
                            width: width < 500 ? '' : '75%',
                            ...styles.colorBlack,
                          }}
                        >
                          Found following sections in your resume:{' '}
                          <span style={{ textTransform: 'lowercase' }}>
                            {props.ats?.ats_data?.sections.join(', ')}
                          </span>{' '}
                          section in your resume.
                        </td>
                      ) : (
                        <td
                          style={{
                            width: width < 500 ? '' : '75%',
                            ...styles.colorBlack,
                          }}
                        >
                          We doses not find the Work experience and Education
                          section in your resume
                        </td>
                      )}
                    </tr>
                  )}
                  {props.showOnlyMissing && props.dateError ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{ ...styles.fontWeight_400, ...subHeadingStyle }}
                      >
                        DATE ERRORS
                      </th>
                      <td>
                        {!props.dateError ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                      {!props.dateError ? (
                        <td style={{ width: '50%', ...styles.colorBlack }}>
                          Missing: Missing dates or date formatting errors.
                        </td>
                      ) : (
                        <td style={{ width: '50%', ...styles.colorBlack }}>
                          Excellent! Found no missing date or date formatting
                          errors
                        </td>
                      )}
                    </tr>
                  )}
                  {props.showOnlyMissing &&
                  props.ats?.ats_data?.creative_bullets ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{
                          ...styles.fontWeight_400,
                          ...subHeadingStyle,
                        }}
                      >
                        ACCOMPLISHMENTS
                      </th>
                      <td>
                        {!props.ats?.ats_data?.creative_bullets.length ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                      {!props.ats?.ats_data?.creative_bullets.length ? (
                        <td style={{ width: '1300px', ...styles.colorBlack }}>
                          Found no measurable results in you resume. Recommend
                          adding few!
                        </td>
                      ) : (
                        <td style={{ width: '45%', ...styles.colorBlack }}>
                          <ul>
                            {props.ats?.ats_data?.creative_bullets
                              ?.slice(0, 5)
                              .map((n) => (
                                <li
                                  key={n}
                                  style={{
                                    marginLeft: -24,
                                  }}
                                >
                                  {n['Text']}
                                </li>
                              ))}
                          </ul>
                        </td>
                      )}
                    </tr>
                  )}

                  {props.showOnlyMissing && props.isMeaurable ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{
                          ...styles.fontWeight_400,
                          ...subHeadingStyle,
                        }}
                      >
                        MEASURABLE RESULTS
                      </th>
                      <td>
                        {!props.isMeaurable ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>

                      <td style={{ width: '45%', ...styles.colorBlack }}>
                        {getMeasureableResult(props.isMeaurable)}
                      </td>
                    </tr>
                  )}
                  {props.showOnlyMissing && props.email ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{ ...styles.fontWeight_400, ...subHeadingStyle }}
                      >
                        EMAIL ADDRESS
                      </th>
                      <td
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {props.email ? (
                          <MdCheck size="1.7em" color={colors.green} />
                        ) : (
                          <MdClear size="1.7em" color={colors.red} />
                        )}
                      </td>
                      {props.email ? (
                        <td style={{ width: '50%', ...styles.colorBlack }}>
                          Your resume shows you have{' '}
                          <span style={styles.tdSpan}>{props.email}</span> as
                          your email contact.
                        </td>
                      ) : (
                        <td style={styles.colorBlack}>
                          Your resume does not show your email!
                        </td>
                      )}
                    </tr>
                  )}
                  {props.showOnlyMissing && props.phone ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{ ...styles.fontWeight_400, ...subHeadingStyle }}
                      >
                        PHONE NUMBER
                      </th>
                      <td>
                        {props.phone ? (
                          <MdCheck size="1.7em" color={colors.green} />
                        ) : (
                          <MdClear size="1.7em" color={colors.darkYellow} />
                        )}
                      </td>
                      {props.phone ? (
                        <td style={styles.colorBlack}>
                          Your resume shows you have{' '}
                          <span
                            style={{
                              ...styles.colorBlack,
                              ...styles.fontWeight_bold,
                            }}
                          >
                            {props.phone}
                          </span>{' '}
                          as your contact phone number.
                        </td>
                      ) : (
                        <td style={styles.colorBlack}>
                          Your resume doesn not show your phone number!
                        </td>
                      )}
                    </tr>
                  )}

                  {props.showOnlyMissing && isLinkedIn ? null : (
                    <tr>
                      <th
                        scope="row"
                        style={{ ...styles.fontWeight_400, ...subHeadingStyle }}
                      >
                        LINKEDIN PROFILE
                      </th>
                      <td>
                        {isLinkedIn ? (
                          <MdCheck size="1.7em" color={colors.green} />
                        ) : (
                          <MdClear size="1.7em" color={colors.darkYellow} />
                        )}
                      </td>
                      {isLinkedIn ? (
                        <td style={styles.colorBlack}>
                          Excellent! Found link to your LinkedIn profile.
                        </td>
                      ) : (
                        <td style={styles.colorBlack}>
                          Missing! Do not have a link to your LinkedIn profile
                          in your Resume
                        </td>
                      )}
                    </tr>
                  )}
                  {props.showOnlyMissing &&
                  !(word_count > 1200 || word_count < 800) ? null : (
                    <tr>
                      <th
                        id="impr"
                        scope="row"
                        style={{ ...styles.fontWeight_400, ...subHeadingStyle }}
                      >
                        WORD COUNT
                      </th>
                      <td>
                        {word_count > 1200 || word_count < 800 ? (
                          <MdInfo size="1.7em" color={colors.darkYellow} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                      <td style={styles.colorBlack}>
                        {getParseCount(word_count)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Colxx>
      </Row>
      {Array.isArray(props.other_info?.resume_quality) &&
      props.other_info?.resume_quality[0].Level === 'No Issues Found' ? null : (
        <Row>
          <Colxx xxs="12" sm="12" lg="12">
            <div style={{ position: 'absolute', display: 'none' }}>
              This is a hidden div
            </div>
            <Card className="mb-2">
              <CardBody
                style={{
                  ...styles.recruiter_cardBody,
                  background: props.showOnlyMissing ? '#EDF0F7' : 'white',
                  border: props.showOnlyMissing
                    ? '1px solid lightgray'
                    : 'none',
                  ...CardStyles,
                }}
              >
                <CardTitle
                  style={{
                    maxWidth: width < 500 ? '220px' : '400px',
                    ...styles.fontWeight_heading,
                    ...headingStyle,
                    marginBottom: 7,
                  }}
                >
                  <p

                  // id="impr"
                  >
                    RESUME IMPROVEMENTS{'  '}
                  </p>
                  <div style={{ position: 'absolute', right: 15, top: 20 }}>
                    {props.fromState === 'yes' ? null : (
                      <ModalRescan
                        goBuilder={props.goBuilder}
                        percent={props.totalScore}
                        skills={props.skills}
                        description={{
                          resume: props.resume,
                          job: props.job,
                          is_parsed: props.is_parsed,
                        }}
                        oid={props.oid}
                        resumeId={props.resumeId}
                        company={props.company}
                        jobTitle={props.jobTitle}
                      />
                    )}
                  </div>
                </CardTitle>
                <Table hover>
                  {(props.other_info?.resume_quality ?? []).map((n) => {
                    return n?.Findings?.length
                      ? n?.Findings?.map((d) => {
                          if (d)
                            return (
                              <tr>
                                {d?.QualityCode !== '151' && (
                                  <th
                                    scope="row"
                                    style={{
                                      ...styles.fontWeight_400,
                                      ...subHeadingStyle,
                                      ...styles.colorBlack,
                                    }}
                                  >
                                    {d?.Message}
                                    {d?.SectionIdentifiers?.length ? (
                                      <p>
                                        <strong>Sections:</strong>
                                        <ul>
                                          {d?.SectionIdentifiers?.map(
                                            (item, index) => (
                                              <li key={index}>
                                                {item?.SectionType}
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      </p>
                                    ) : null}
                                  </th>
                                )}
                                <td>
                                  <>
                                    {d?.QualityCode !== '151' && (
                                      <>
                                        {n?.Level ===
                                        'Suggested Improvements' ? (
                                          <MdInfo
                                            size="22px"
                                            color={colors.darkYellow}
                                          />
                                        ) : (
                                          <>
                                            {n?.Level === 'Data Missing' ? (
                                              <AiOutlineCloseCircle
                                                size="22px"
                                                color={colors.red}
                                              />
                                            ) : (
                                              <GoIssueOpened
                                                size="22px"
                                                color={colors.darkYellow}
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                </td>
                                <td style={styles.colorBlack}></td>
                              </tr>
                            );
                          return null;
                        })
                      : null;
                  })}
                </Table>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
});

export default withRouter(ResumeResult);
