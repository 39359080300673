import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { WordEditor } from '../../commonComponents/syncFusion';
import useEditor from './useEditor';

function EditorWebView(props) {
  const oid = new URLSearchParams(props.location.search).get('oid') || '';
  const token = new URLSearchParams(props.location.search).get('token') || '';
  const { editorJson, fontFamily } = useEditor({ oid, token });
  const [loader, setLoader] = React.useState(true);

  return (
    <div>
      {loader && (
        <div
          style={{
            zIndex: '9999',
            background: 'white',
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}

      <WordEditor
        data={editorJson}
        oid={oid}
        isMobile={true}
        fontFamilies={fontFamily}
        mobileToken={token}
        onLoadEnd={() => {
          // alert("onLoadEnd");
          setLoader(false);
        }}
      />
    </div>
  );
}

export default EditorWebView;
