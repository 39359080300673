import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import RichEditor from '../helper/RichEditor';
import { onAddSummaryHandler, onBlurField } from '../../redux/actions';
import { addExperienceSummary } from '../../../../commonRedux/scan/action';

export default function Profile() {
  const classes = useStyles();
  let { resumeData } = useSelector((state) => state.editorReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (resumeData?.ProfessionalSummary) {
      dispatch(onAddSummaryHandler(resumeData?.ProfessionalSummary?.body));
    }
  }, []);

  return (
    <div>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Summary
          </label>
        </div>
      </Grid>
      <RichEditor
        value={resumeData?.objective?.body}
        placeHolder="Please write summary of your experience, achievement and skills."
        customclass={`${classes.input} ${classes.summaryInput}`}
        path={'objective.body'}
        onBlurField={onBlurField}
      />
    </div>
  );
}
