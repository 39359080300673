import React from 'react';
import Button from '@material-ui/core/Button';
import { connect, useDispatch } from 'react-redux';
import { changePasswordAction } from '../profile/redux/action';
import toastr from 'toastr';
import { Row, Col } from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Input } from 'antd';
import { styles } from './style';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { forgetPassword } from '../auth/redux/actions';
import { validateEmail } from '../../utils/emailValidation';
import { colors } from '../../commonComponents/colors';

const ChangePassword = (props) => {
  const [currentPass, setcurrentPass] = React.useState('');
  const [newPass, setnewPassass] = React.useState('');
  const [confirmNewPass, setconfirmNewPass] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [modelState, setModelState] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [currentPassToggle, setcurrentPassToggle] = React.useState(false);
  const [newPassToggle, setnewPassToggle] = React.useState(false);
  const [confirmNewPassToggle, setconfirmNewPassToggle] = React.useState(false);
  const dispatch = useDispatch();
  const resetHandler = () => {
    setcurrentPass('');
    setconfirmNewPass('');
    setnewPassass('');
  };
  const submitForm = () => {
    if (currentPass === '') {
      toastr.error(`Please enter your current password!`);
    } else if (newPass === '') {
      toastr.error(`Please enter new password!`);
      // } else if (confirmPass === "") {
      //   toastr.error(`please enter confirm password!`);
      // } else if (newPass != confirmPass) {
      //   toastr.error(`confirm password doesn't match!`);
    } else if (newPass.length < 8) {
      toastr.error(`Password must be equal to or greater than 8 characters!`);
      return;
    } else if (newPass !== confirmNewPass) {
      toastr.error(`confirm password doesn't match!`);
    } else if (newPass === currentPass) {
      toastr.error(`New password can't be same as old password!`);
    } else {
      let data = {
        password: currentPass,
        newpassword: newPass,
        confirmpassword: newPass,
        // confirmpassword: confirmPass,
      };
      props.changePasswordAction(data, resetHandler);
      props.setShowChangePass(false);
      // setcurrentPass("");
      // setnewPassass("");
      // setconfirmPass("");
    }
  };

  const callBack = (res) => {
    if (res) {
      setLoader(true);
    }
    setLoader(false);
  };

  const handleOpenModel = () => {
    // setEmail(props.user.email);
    setModelState(true);
  };
  const handleCloseModel = () => {
    setModelState(false);
  };
  const recoverHandler = () => {
    if (!email) {
      toastr.error('Email can not be empty');
    } else if (!validateEmail(email)) {
      toastr.error('Email is not valid');
    } else {
      setLoader(true);
      dispatch(
        forgetPassword(
          {
            email: email,
          },
          callBack,
        ),
      );
      handleCloseModel();
      setEmail('');
    }
  };

  return (
    <div className="change_font_style">
      <div style={styles.fontWeight_bold}>CHANGE PASSWORD</div>
      <span style={styles.fontSize_85}>
        Use this form to reset your account password.
      </span>
      <div style={styles.bb_ligthGrey}></div>
      <Row style={styles.mt_30}>
        <Col sm="12" lg="4" xs="12" md="4" style={styles.changePass_Col}>
          Old Password:
        </Col>
        <Col sm="12" lg="8" xs="12" md="8" style={styles.changePass_Col1}>
          <Input
            value={currentPass}
            onChange={(e) => setcurrentPass(e.target.value)}
            type={currentPassToggle ? 'text' : 'password'}
            placeholder="Old Password"
            className="pass_change_input"
          />
          {currentPassToggle ? (
            <VisibilityOffIcon
              style={{
                position: 'absolute',
                marginLeft: '-30px',
                fontSize: '18px',
                marginTop: '5px',
                color: colors.grey,
              }}
              onClick={() => setcurrentPassToggle(!currentPassToggle)}
            />
          ) : (
            <VisibilityIcon
              style={{
                position: 'absolute',
                marginLeft: '-30px',
                fontSize: '18px',
                marginTop: '5px',
                color: colors.grey,
              }}
              onClick={() => setcurrentPassToggle(!currentPassToggle)}
            />
          )}
        </Col>
        <Col sm="12" lg="4" xs="12" md="4" style={styles.changePass_Col}>
          New Password:
        </Col>
        <Col sm="12" lg="8" xs="12" md="8" style={styles.mb_10}>
          <Input
            value={newPass}
            onChange={(e) => setnewPassass(e.target.value)}
            type={newPassToggle ? 'text' : 'password'}
            placeholder="New Password"
            className="pass_change_input"
          />
          {newPassToggle ? (
            <VisibilityOffIcon
              style={{
                position: 'absolute',
                marginLeft: '-30px',
                fontSize: '18px',
                marginTop: '5px',
                color: colors.grey,
              }}
              onClick={() => setnewPassToggle(!newPassToggle)}
            />
          ) : (
            <VisibilityIcon
              style={{
                position: 'absolute',
                marginLeft: '-30px',
                fontSize: '18px',
                marginTop: '5px',
                color: colors.grey,
              }}
              onClick={() => setnewPassToggle(!newPassToggle)}
            />
          )}
          <br />
          {newPass.length > 1 && newPass.length < 8 ? (
            <span style={{ color: 'red', fontSize: '12px' }}>
              Password must be 8 digits
            </span>
          ) : newPass.length === 8 ? (
            ''
          ) : (
            ''
          )}
        </Col>
        <Col sm="12" lg="4" xs="12" md="4" style={styles.changePass_Col}>
          Confirm New Password:
        </Col>
        <Col sm="12" lg="8" xs="12" md="8" style={styles.mb_10}>
          <Input
            value={confirmNewPass}
            onChange={(e) => setconfirmNewPass(e.target.value)}
            type={confirmNewPassToggle ? 'text' : 'password'}
            placeholder="Confirm New Password"
            className="pass_change_input"
          />
          {confirmNewPassToggle ? (
            <VisibilityOffIcon
              style={{
                position: 'absolute',
                marginLeft: '-30px',
                fontSize: '18px',
                marginTop: '5px',
                color: colors.grey,
              }}
              onClick={() => setconfirmNewPassToggle(!confirmNewPassToggle)}
            />
          ) : (
            <VisibilityIcon
              style={{
                position: 'absolute',
                marginLeft: '-30px',
                fontSize: '18px',
                marginTop: '5px',
                color: colors.grey,
              }}
              onClick={() => setconfirmNewPassToggle(!confirmNewPassToggle)}
            />
          )}
          <br />
          {(confirmNewPass.length > 1 && confirmNewPass.length < 8) ||
          (confirmNewPass.length === 8 && confirmNewPass.length > 8) ? (
            <span style={{ color: 'red', fontSize: '12px' }}>
              Password must be 8 digits
            </span>
          ) : confirmNewPass.length === 8 ? (
            ''
          ) : (
            ''
          )}
        </Col>
        {/* <Col sm="12" lg="4" xs="12" md="4" style={styles.changePass_Col}>
          Password confirmation:
        </Col>
        <Col sm="12" lg="8" xs="12" md="8" style={styles.mb_10}>
          <Input
            value={confirmPass}
            onChange={(e) => setconfirmPass(e.target.value)}
            type="password"
            placeholder="confirm password"
            className="pass_change_input"
          />
        </Col> */}
        <Col sm="12" md="12" xs="12" lg="12" style={{ textAlign: 'center' }}>
          <Button
            onClick={submitForm}
            size="large"
            style={styles.changePassBtn}
          >
            {props.loaderStatus ? 'Loading...' : 'CHANGE PASSWORD'}
          </Button>
        </Col>
      </Row>
      {loader ? (
        <CircularProgress />
      ) : (
        <div
          style={{
            cursor: 'pointer',
            display: 'inline',
            color: colors.blue,
            marginLeft: '10px',
          }}
          onClick={
            props.comingFrom === 'employer'
              ? () => props.swithToForgot(true)
              : handleOpenModel
          }
        >
          Forgot Password ?
        </div>
      )}
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={modelState}
        keepMounted
        onClose={handleCloseModel}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            Forgot Password
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={handleCloseModel}
          />
        </div>
        <DialogContent>
          <DialogContentText>
            {/* To forgot your password enter you email */}
            Please enter your email to recover your password
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleCloseModel} color="primary" variant="contained">
            Cancel
          </Button> */}
          <Button
            onClick={recoverHandler}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              height: '40px',
            }}
          >
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ profileReducer }) => {
  return {
    loaderStatus: profileReducer.loaderStatus,
  };
};
export default connect(mapStateToProps, {
  changePasswordAction,
})(ChangePassword);
