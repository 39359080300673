import { Grid } from '@material-ui/core';
import React from 'react';
import styles from './Questions.module.scss';
import img from '../../../../assets/questionPage.svg';
import { Link } from 'react-router-dom';
import useWorkExperiences from './useWorkExperiences';

export default function Questions() {
  const { data, setsummaryHandler } = useWorkExperiences();
  return (
    <div className={styles.main1_container}>
      <div className={styles.main_container}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={styles.text_container}>
              <div className={styles.experience_text}>
                Experience in the job you plan to apply?
              </div>
              <div className={styles.volunteer_text}>
                Only include years of experience that is pertinent to the job
                you are seeking.
              </div>
              <div className={styles.div_container}>
                {data.map((item, index) => {
                  return (
                    <Link key={index} to={item.link}>
                      <>
                        <div
                          style={{ borderColor: localStorage.getItem('experienceCardIndex') === index.toString() ? '#2f7264' : '#E0E0E0' }}
                          className={styles.div}
                          onClick={() => setsummaryHandler(item.experience, index)}
                        >
                          <div>{item.title}</div>
                          <div>{item.experience + ' ' + 'yr(s)'}</div>
                        </div>
                      </>
                    </Link>
                  );
                })}
              </div>
            </div>
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={5}
            style={{
              display: 'flex',
              width: 'fir-content',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div className={styles.img_div}>
              <div className={styles.img_container}> */}
          {/* <img
                  src={img}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    marginTop: 20,
                  }}
                /> */}
          {/* </div>
            </div>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
}
