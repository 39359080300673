import React from 'react';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { useDispatch } from 'react-redux';

export default function RichEditor(props) {
  const editorRef = React.useRef();
  React.useEffect(() => {
    //  editorRef.current.editor.fontSize.apply(14));
  }, []);
  const configFroalaEditor = {
    heightMax: props.heightMax ? '' : 300,
    toolbarSticky: true,
    toolbarInline: props.enableInline || false,
    quickInsertTags: [],
    placeholderText: props.placeHolder,
    charCounterCount: false,
    fontSizeSelection: true,
    fontSizeDefaultSelection: '14',
    fontFamilyDefaultSelection: 'Arial',
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          // 'insertLink',
          'fontFamily',
          'formatUL',
          // 'align',
          // 'outdent',
          // 'indent',
          'fontSize',
          'lineHeight',
        ],
        buttonsVisible: 10,
      },
      moreMisc: {
        //   buttons: ['undo', 'redo', 'Update & Rescan', 'Save'],
        align: 'right',
        buttonsVisible: 6,
      },
    },
    lineHeights: {
      Default: '0.5',
      Half: '0.5',
      Single: '1',
      1.15: '1.15',
      1.5: '1.5',
      Double: '2',
    },
    attribution: false,
    htmlUntouched: true,
    pastePlain: true,
    pasteDeniedTags: ['style', 'script', 'link', 'img'],
    pasteDeniedAttrs: ['class', 'id', 'style'],
    key: 'CTD5xB1C2B2C1A7B5wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4A3C6E2F2F4G1C1==',
  };
  const dispatch = useDispatch();
  const onChange = (newContent) => {
    dispatch(props.onBlurField(newContent, props.path));
  };
  return (
    <FroalaEditorComponent
      ref={editorRef}
      tag="textarea"
      config={configFroalaEditor}
      model={props.value}
      onModelChange={onChange}
    />
  );
}
