import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { currentLink } from "../../commonRedux/currentNav/action";
import { withRouter } from "react-router-dom";
import Basic from "./basic";
import Education from "./education";
import JobInformation from "./jobinformation";
import { styles } from "./style";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    width: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function Profile(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProgress = () => {
    var count = 0;
    const data = props.user || {};

    Object.keys(data).map((milestone) => {
      if (
        (milestone === "firstName" && data[milestone] !== "") ||
        (milestone === "lastName" && data[milestone] !== "") ||
        (milestone === "phone" && data[milestone] !== "") ||
        (milestone === "email" && data[milestone] !== "") ||
        (milestone === "education" &&
          data[milestone] !== "" &&
          data[milestone] !== "Select highest level of education") ||
        (milestone === "firstMajor" && data[milestone] !== "") ||
        (milestone === "secondMajor" && data[milestone] !== "")
      ) {
        count = count + 1;
      }
      if (
        milestone === "type" &&
        data[milestone]["role"] === "Employment Specilist"
      ) {
        Object.keys(data[milestone]).map((pdata) => {
          if (
            data[milestone][pdata] !== "" &&
            data[milestone][pdata] !== "Please specify" &&
            data[milestone][pdata] !== "false"
          ) {
            count = count + 4;
          }
        });
        count = count + 1;
      } else if (
        milestone === "type" &&
        data[milestone]["role"] === "Recruiter"
      ) {
        Object.keys(data[milestone]).map((pdata) => {
          if (
            data[milestone][pdata] !== "" &&
            data[milestone][pdata] !== "Please specify" &&
            data[milestone][pdata] !== "false"
          ) {
            count = count + 4;
          }
        });
        count = count + 1;
      } else if (milestone === "type" && data[milestone]["role"] === "other") {
        Object.keys(data[milestone]).map((pdata) => {
          if (
            data[milestone][pdata] !== "" &&
            data[milestone][pdata] !== "Please specify" &&
            data[milestone][pdata] !== "false"
          ) {
            count = count + 4;
          }
        });
        count = count + 1;
      } else if (
        milestone === "type" &&
        data[milestone]["role"] === "Job seeker"
      ) {
        Object.keys(data[milestone]).map((pdata) => {
          if (
            data[milestone][pdata] !== "" &&
            data[milestone][pdata] !== "Please specify" &&
            data[milestone][pdata] !== "false"
          ) {
            count = count + 1;
          }
        });
      }
    });
  };

  React.useEffect(() => {
    props.currentLink(props.location.pathname);
    getProgress();
  }, [props]);

  return (
    <>
      <div className="tabs-container">
        <div style={{ width: "100%" }} className={classes.root}>
          <div style={{ width: "20%", minWidth: 100 }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab
                label="BASICS"
                {...a11yProps(0)}
                className="change_font_style"
                style={styles.fontWeight_600}
              />

              <Tab
                label="EDUCATION"
                {...a11yProps(2)}
                className="change_font_style"
                style={styles.fontWeight_600}
              />
              <Tab
                label="JOB INFO"
                {...a11yProps(3)}
                className="change_font_style"
                style={styles.fontWeight_600}
              />
            </Tabs>
          </div>
          <div style={{ width: "80%" }}>
            <TabPanel value={value} index={0} style={styles.width_100_percent}>
              <Basic user={props.user} />
            </TabPanel>
            <TabPanel value={value} index={1} style={styles.width_100_percent}>
              <Education user={props.user} />
            </TabPanel>
            <TabPanel value={value} index={2} style={styles.width_100_percent}>
              <JobInformation user={props.user} />
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ authUser }) => {
  return {
    user: authUser.user,
  };
};

export default connect(mapStateToProps, { currentLink })(withRouter(Profile));
