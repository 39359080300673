import { colors } from "../commonComponents/colors";

export const getScoreColor = (score = '') => {
  if (score === "N/A") {
    return colors.red;
  } else if (Number(score) <= 50) {
    return colors.darkYellow;
  } else if (Number(score) >= 51 && Number(score) <= 79) {
    return colors.lightBlue;
  } else if (Number(score) >= 80) {
    return colors.green;
  } else {
    return colors.red;
  }
};