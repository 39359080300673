import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  StepButton,
  CircularProgress,
} from '@material-ui/core';
import { ColorlibConnector } from '../scan/stepperConfig';
import { styles, useStyles } from './MilitaryXWalkStyle';
import { colors } from '../../commonComponents/colors';
import LoopIcon from '@material-ui/icons/Loop';
import { UPDATE_MILXWALK_ACTIVE_STEP } from './redux/actions.constants';
import { useDispatch } from 'react-redux';
const MilitaryStepper = ({
  militarySteps,
  handleBack,
  milXWalkActiveStep,
  handleNext,
  handleReset,
  isDisabledNext,
  isDisabledBack,
  isStepDisabled,
  handleStepTwo,
  handleStepOne,
  loading,
  width,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <>
      <center>
        <div style={styles.stepperContainer(width)}>
          {width > 600 && (
            <Button
              onClick={handleBack}
              disabled={isDisabledBack}
              style={{
                ...styles.stepBtn,
                backgroundColor: isDisabledBack ? colors.grey : colors.blue,
              }}
            >
              Back
            </Button>
          )}
          <div
            style={{
              width: '100%',
              overflow: 'auto',
            }}
          >
            <Stepper
              activeStep={milXWalkActiveStep}
              connector={<ColorlibConnector />}
              style={{
                paddingLeft: width <= 600 ? 0 : 10,
                paddingRight: width <= 600 ? 0 : 10,
              }}
            >
              {militarySteps?.map((step, index) => (
                <Step key={step?.label}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.stepIcon,
                        active: classes.active,
                        completed: classes.completed,
                      },
                    }}
                  >
                    <StepButton
                      onClick={() => {
                        if (index === 2) {
                          handleStepTwo();
                        }
                        if (milXWalkActiveStep === 0) {
                          handleStepOne();
                        }
                        dispatch({
                          type: UPDATE_MILXWALK_ACTIVE_STEP,
                          payload: index,
                        });
                        localStorage.setItem('milXWalkActiveStep', index);
                      }}
                      disabled={isStepDisabled(index)}
                    >
                      <b
                        style={{
                          color: colors.fontGray,
                        }}
                      >
                        {width > 800 ? step?.label : step?.label?.split(' ')[0]}
                      </b>
                    </StepButton>
                  </StepLabel>
                </Step>
              ))}
              {milXWalkActiveStep !== 0 && width > 600 && (
                <StepLabel
                  onClick={handleReset}
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  <div
                    onClick={handleReset}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      paddingRight: 10,
                      gap: 5,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: loading ? colors.white : colors.green,
                        padding: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={handleReset}
                    >
                      {loading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : (
                        <LoopIcon style={{ fontSize: 20, color: 'white' }} />
                      )}
                    </div>

                    <b
                      style={{
                        color: colors.fontGray,
                        fontSize: 14,
                      }}
                    >
                      Start New
                    </b>
                  </div>
                </StepLabel>
              )}
            </Stepper>
          </div>
          {width <= 600 && (
            <div
              onClick={handleReset}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                gap: 1,
                padding: '0px 10px',
              }}
            >
              <div
                style={{
                  backgroundColor: loading ? colors.white : colors.green,
                  padding: 2,
                  display: 'flex',
                  borderRadius: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}
                onClick={handleReset}
              >
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : (
                  <LoopIcon style={{ fontSize: 20, color: 'white' }} />
                )}
              </div>

              <b
                style={{
                  color: colors.fontGray,
                  fontSize: 13,
                }}
              >
                Start New
              </b>
            </div>
          )}
          {width > 600 && (
            <Button
              onClick={() => {
                if (milXWalkActiveStep === 3) {
                  handleReset();
                } else {
                  handleNext();
                }
              }}
              style={{
                ...styles.stepBtn,
                background:
                  loading || isDisabledNext ? colors.grey : colors.blue,
              }}
              disabled={isDisabledNext || loading}
            >
              {milXWalkActiveStep === 3 ? 'Finish' : 'Next'}
            </Button>
          )}
        </div>
        {width <= 600 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <Button
              size={'small'}
              onClick={handleBack}
              disabled={isDisabledBack}
              style={{
                ...styles.stepBtn,
                backgroundColor: isDisabledBack ? colors.grey : colors.blue,
              }}
            >
              Back
            </Button>
            <Button
              size={'small'}
              onClick={() => {
                if (milXWalkActiveStep === 3) {
                  handleReset();
                } else {
                  handleNext();
                }
              }}
              style={{
                ...styles.stepBtn,
                background:
                  loading || isDisabledNext ? colors.grey : colors.blue,
              }}
              disabled={isDisabledNext || loading}
            >
              {milXWalkActiveStep === 3 ? 'Finish' : 'Next'}
            </Button>
          </div>
        )}
      </center>
    </>
  );
};

export default MilitaryStepper;
