import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import DescriptionIcon from '@material-ui/icons/Description';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import { styles } from '../../manage/style';
import Stripe from '../component/ManipulationStripe';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getFormatedTextFromBuilder } from '../../../utils/getFormatedTextFromBuilder';
import Manager from './manager';
import {
  getResumeScan,
  changeTheme,
  changeNameHandler,
  resetBuilderResume,
  setDefaultResume,
} from '../redux/actions';
import Button from '@material-ui/core/Button';
import FormBuilder from '../formBuilder';
import Loader from 'react-loader-spinner';
import FormStepsLoader from '../../../commonComponents/formStepsLoader';
import FormDataLoader from '../../../commonComponents/formDataLoader';
import { useWindowSize } from '@react-hook/window-size';
import {
  AddScanResuma,
  UpdateResuma,
  getResumaId,
  addNotesAction,
} from '../../../commonRedux/scan/action';
import HelperToolTip from '../formBuilder/helper/ToolTip';
import SavePage from '../formBuilder/SavePage';
import { colors } from '../../../commonComponents/colors';
import VideoModel from '../../../commonComponents/videoModel';
import InfoIcon from '../../../commonComponents/InfoIcon';
import SelectTemplate from '../component/ManipulationStripe/selectTemplate';
import { Replay } from '@material-ui/icons';
import {
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import toastr from 'toastr';
import { makeStyles } from '@material-ui/styles';
import { updateHowToStart } from '../../auth/redux/actions';
import './style.css';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 200,
  },
}));

export default function Index(props) {
  const classes = useStyles();
  const [showToolTip, setShowToolTip] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [createdOid, setCreatedOid] = React.useState(
    localStorage.getItem('builderResumeOid'),
  );
  const {
    resumeData,
    resumeExist,
    allBuildResumes,
    resumeName,
    serverUpdater,
    getScanResultStatus,
    getResumeBuilderChoice,
    templateIndex,
    builderResumeData,
  } = useSelector((state) => state.editorReducer);
  const VideoLinks = useSelector((state) => state.fileScanReducer.videoLinks);
  const { _id: userId, ...user } = useSelector(
    (state) => state.authUser.user || {},
  );
  const [showResumeNameModal, setShowResumeNameModal] = React.useState(false);
  const [width] = useWindowSize();
  const [btn, setBtn] = React.useState('form');
  const [newResumeName, setNewResumeName] = React.useState('');
  const [nextClick, setNextClick] = React.useState(false);
  const [modelOpen, setModelOpen] = React.useState(false);
  const [templateSelect, setTemplateSelect] = React.useState(0);
  const [startNewConfirm, setStartNewConfirm] = React.useState(false);
  const [resetLoader, setResetLoader] = React.useState(false);
  const [missingInfoModel, setMissingInfoModel] = React.useState(false);
  const timestamp = new Date().getTime();
  const first6Digits = Math.floor(timestamp / 1000)
    .toString()
    .slice(0, 6);

  let dispatch = useDispatch();
  const history = useHistory();

  const builderPage = localStorage.getItem('builderPage');
  useEffect(() => {
    if (builderPage) {
      setBtn(builderPage);
      dispatch({
        type: 'GET_RESUME_BUILDER_CHOICE',
        payload: builderPage,
      });
    } else if (getResumeBuilderChoice === 'editor') {
      setBtn('editor');
    }
  }, [getResumeBuilderChoice, builderPage]);

  useEffect(() => {
    setNewResumeName(resumeName);
  }, [resumeName]);

  useEffect(() => {
    if (builderResumeData?.oid) {
      setCreatedOid(builderResumeData?.oid);
      localStorage.setItem('builderResumeOid', builderResumeData?.oid);
    }
  }, [builderResumeData?.oid]);

  useEffect(() => {
    if (templateIndex) {
      setTemplateSelect(templateIndex);
    }
  }, [templateIndex]);

  const switchToForm = () => {
    if (getResumeBuilderChoice !== 'form')
      dispatch({
        type: 'GET_RESUME_BUILDER_CHOICE',
        payload: 'form',
      });
    setBtn('form');
    localStorage.setItem('builderPage', 'form');
  };
  const switchToSave = () => {
    if (getResumeBuilderChoice !== 'save') {
      dispatch({
        type: 'GET_RESUME_BUILDER_CHOICE',
        payload: 'save',
      });
      setBtn('save');
      localStorage.setItem('builderPage', 'save');
    }
  };

  const switchToPersonalize = () => {
    if (getResumeBuilderChoice !== 'personalize') {
      dispatch({
        type: 'GET_RESUME_BUILDER_CHOICE',
        payload: 'personalize',
      });
      setBtn('personalize');
      localStorage.setItem('builderPage', 'personalize');
    }
  };

  const switchToEditor = () => {
    if (getResumeBuilderChoice !== 'editor')
      dispatch({
        type: 'GET_RESUME_BUILDER_CHOICE',
        payload: 'editor',
      });
    setBtn('editor');
    localStorage.setItem('builderPage', 'editor');
  };

  const handleShowToolTip = () => {
    setShowToolTip(!showToolTip);
  };

  const clearResumeOrNoCurrentCallback = () => {
    history.push('/questionnaire/work_experience');
    dispatch(updateHowToStart('Optimize'));
    localStorage.removeItem('howToStartCardIndex');
    localStorage.removeItem('experienceCardIndex');
    localStorage.removeItem('educationCardIndex');
    localStorage.removeItem('builderResumeOid');
    setCreatedOid('');
    switchToForm();
    dispatch({ type: 'CLEAR_BUILDER_TITLE' });
  };

  const getResume = () => {
    let data = {
      builderResume: true,
      is_resume: true,
      filters: '',
    };

    dispatch(getResumeScan(data, clearResumeOrNoCurrentCallback));
  };
  useEffect(() => {
    dispatch({
      type: 'GET_RESUME_SCAN_STATUS',
      payload: 'loading',
    });
    localStorage.setItem('tempTemplate', localStorage.getItem('defaultTemp'));
    dispatch(changeTheme(localStorage.getItem('defaultTemp')));
    dispatch(getResumaId());
    getResume();
    setNewResumeName(resumeName);
  }, []);

  useEffect(() => {
    if (
      resumeExist &&
      builderResumeData?.parsedByFile === true &&
      localStorage.getItem('parse-info-model') === 'true'
    ) {
      setTimeout(() => {
        // toastr.warning('We noticed that some information is missing. Please fill missing information manually.',
        //   "Missing Information",
        //   {
        //     hideDuration: 5000,
        //     closeOnHover: false,
        //     tapToDismiss: false,
        //   });
        setMissingInfoModel(true);
      }, 1500);
      localStorage.setItem('parse-info-model', false);
    }
  }, [builderResumeData?.parsedByFile, resumeExist]);
  const resumeNameHandler = () => {
    setShowResumeNameModal(false);
    const isNamePresent = allBuildResumes?.some(
      (obj) => obj?.resumeName === newResumeName,
    );
    if (isNamePresent) {
      toastr.error('Name Already Exists!');
      return;
    }
    dispatch(changeNameHandler(newResumeName));
    dispatch(
      addNotesAction({
        type: '',
        oid: createdOid,
        resumeName: newResumeName,
      }),
    );
  };
  const errorFunction = () => {
    setLoader(false);
  };
  const saveToServer = (isFinish) => {
    // if (!createdOid && !showResumeNameModal) {
    //   console.log('onFinishNotWorking', isFinish);
    //   // setNextClick(!isFinish);
    //   return;
    // }
    if (!resumeName && showResumeNameModal) {
      alert('Resume name is required!');
      setShowResumeNameModal(true);
      return;
    }

    var filter_data = resumeData;

    // setLoader(true);
    // setBtn("save");

    if (!resumeExist) {
      dispatch(
        AddScanResuma(
          {
            data: { data: filter_data },
            resumeName: newResumeName,
            builderResume: true,
            templateIndex: templateSelect,
            resumeText: getFormatedTextFromBuilder(filter_data),
            // data: { data, theme },
          },
          () => {},
          errorFunction,
        ),
      );
      dispatch(changeNameHandler(newResumeName));
    } else {
      dispatch(
        UpdateResuma(
          {
            oid: createdOid,
            data: { data: filter_data },
            templateIndex: templateSelect,
            resumeText: getFormatedTextFromBuilder(filter_data),
            resumeName: `${resumeName}_${first6Digits}`,
          },
          () => {},
          errorFunction,
        ),
      );
    }
    // }
  };

  const successFunction = (data) => {
    setLoader(false);
    if (data.oid) {
      setShowResumeNameModal(false);
      setCreatedOid(data.oid);
    }
    if (data.status === 200 && !nextClick) {
      switchToSave();
    } else {
      setNextClick(false);
    }
  };

  useEffect(() => {
    if (serverUpdater && createdOid) {
      saveToServer();
    }
  }, [serverUpdater]);

  const handleChangeResume = async (resumeOid) => {
    dispatch(
      setDefaultResume(
        {
          oid: resumeOid,
          userId: userId,
        },
        (newOid) => {
          if (newOid) {
            setCreatedOid(newOid);
          }
        },
      ),
    );
  };

  const handleSave = () => {
    if (!createdOid && !showResumeNameModal) {
      setShowResumeNameModal(true);
      return;
    }
    if (!resumeName && showResumeNameModal) {
      alert('Resume name is required!');
      return;
    }

    setLoader(true);

    var filter_data = resumeData;

    if (!resumeExist) {
      dispatch(
        AddScanResuma(
          {
            data: { data: filter_data },
            resumeName: newResumeName,
            builderResume: true,
            templateIndex: templateSelect,
            resumeText: getFormatedTextFromBuilder(filter_data),
            // data: { data, theme },
          },
          successFunction,
          errorFunction,
        ),
      );
      dispatch(changeNameHandler(newResumeName));
    } else {
      dispatch(
        UpdateResuma(
          {
            oid: createdOid,
            data: { data: filter_data },
            resumeText: getFormatedTextFromBuilder(filter_data),
            templateIndex: templateSelect,
            resumeName: `${resumeName}_${first6Digits}`,
          },
          successFunction,
          errorFunction,
        ),
      );
    }
    // }
  };

  const handleStartNew = () => {
    setStartNewConfirm(false);
    setResetLoader(true);

    dispatch(
      resetBuilderResume(
        userId,
        setResetLoader,
        clearResumeOrNoCurrentCallback,
      ),
    );
  };

  const handleNextClick = () => {
    if (btn === 'form') {
      switchToEditor();
    } else if (btn === 'editor') {
      switchToPersonalize();
    } else if (btn === 'personalize') {
      setNextClick(false);
      handleSave();
      if (!newResumeName) {
        setShowResumeNameModal(true);
      }
    } else if (btn === 'save') {
      setStartNewConfirm(true);
    } else {
      return;
    }
  };

  const handleBackClick = () => {
    if (btn === 'save') {
      switchToPersonalize();
    } else if (btn === 'personalize') {
      switchToEditor();
    } else if (btn === 'editor') {
      switchToForm();
    } else {
      return;
    }
  };

  const renderTemplate = (props) => {
    return <Manager width={width} {...props} templateSelect={0} />;
  };

  if (getScanResultStatus === 'loading' || resetLoader === true) {
    return (
      <div style={{ maxWidth: '100vw', width: '90vw' }}>
        <div style={{ background: '#fff', height: 85, padding: 16 }}>
          <FormStepsLoader />
        </div>
        <div
          style={{
            background: '#fff',
            height: '100%',
            padding: 16,
            marginTop: 30,
            width: '100%',
          }}
        >
          <FormDataLoader />
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.builderContainer(width)}>
        <VideoModel
          open={modelOpen}
          setOpen={setModelOpen}
          video={VideoLinks?.ResumeBuilder}
        />
        {getResumeBuilderChoice === 'form' ? (
          <FormBuilder handleSave={saveToServer} {...props} />
        ) : getResumeBuilderChoice === 'personalize' ? (
          <div style={{ width: '100%', marginTop: width < 768 ? 220 : 150 }}>
            <SelectTemplate
              changeTemp={(tempIndex) => {
                setTemplateSelect(tempIndex);
              }}
              selectedTemp={templateSelect}
            />
          </div>
        ) : getResumeBuilderChoice === 'save' ? (
          <div style={{ width: '100%', marginTop: width < 768 ? 220 : 150 }}>
            <SavePage
              oid={createdOid}
              resumeName={resumeName}
              resumeData={resumeData}
              allBuildResumes={allBuildResumes}
            />
          </div>
        ) : (
          <div>
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: '20%',
              }}
            ></div>
            <div style={{ width: '100%', marginTop: width < 768 ? 220 : 106 }}>
              <Stripe
                showToolTip={showToolTip}
                handleShowToolTip={handleShowToolTip}
                createdOid={createdOid}
                handleSave={handleSave}
                resumeName={resumeName}
                setShowResumeNameModal={setShowResumeNameModal}
                setTemplateSelect={setTemplateSelect}
                {...props}
              />
            </div>
            {showToolTip ? <HelperToolTip /> : null}
            {renderTemplate(props)}
          </div>
        )}
        <div style={styles.builderTopBarMain(width)}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
              width:
                width > 2700
                  ? '96%'
                  : width > 2600
                  ? '95.5%'
                  : width > 2020
                  ? '95%'
                  : width > 1900
                  ? '94%'
                  : width > 1700
                  ? '93.5%'
                  : width > 1620
                  ? '93%'
                  : '92.5%',
              margin: '5px auto',
              gap: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: 1,
                height: '100%',
              }}
            >
              <label
                htmlFor="saved-resume"
                style={{
                  fontSize: 13,
                  color: '#4a4a4a',
                  margin: 0,
                  fontWeight: 600,
                }}
              >
                Saved Resume
              </label>

              <select
                id="saved-resume"
                htmlFor="saved-resume"
                defaultValue={builderResumeData?.oid}
                value={builderResumeData?.oid}
                onChange={(e) => {
                  handleChangeResume(e.target.value);
                }}
              >
                {allBuildResumes?.map((resumeItem, index) => {
                  return (
                    <option
                      className="saved-resume-option"
                      key={resumeItem?.oid + index}
                      value={resumeItem?.oid}
                    >
                      {resumeItem?.resumeName &&
                      resumeItem?.resumeName.length > 15
                        ? resumeItem?.resumeName.slice(0, 15) + '...'
                        : resumeItem?.resumeName
                        ? resumeItem?.resumeName
                        : 'Resumás'}
                    </option>
                  );
                })}
                <option value={builderResumeData?.oid} selected>
                  {resumeName && resumeName.length > 15
                    ? resumeName.slice(0, 15) + '...'
                    : resumeName
                    ? resumeName
                    : 'Resumás'}
                </option>
              </select>
            </div>
            <div style={styles.builderTopBar(width)}>
              <div style={styles.builderTitleMain}>
                <InfoIcon
                  onClick={() => setModelOpen(true)}
                  text={'How To'}
                  icon={'Play'}
                  height={18}
                  width="70px"
                  marginTop={-1}
                  marginRight={5}
                />
                <span style={styles.builderTitle}>Resume Builder</span>
              </div>
              <div className="option-bar" style={styles.buttonMain(width)}>
                <button
                  className={`template-builder ${
                    btn === 'form'
                      ? 'choice__color'
                      : btn === 'editor' ||
                        btn === 'save' ||
                        btn === 'personalize'
                      ? 'complete__choice'
                      : 'active__choice'
                  }`}
                  onClick={switchToForm}
                >
                  <span
                    style={{
                      position: 'absolute',
                      left: 8,
                      top: 8,
                      fontSize: 12,
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: 'white',
                      color: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    1
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        width: 10,
                        height: 15,
                      }}
                    >
                      &nbsp;
                    </span>
                    <p style={{ marginTop: 18 }}>Build</p>
                    <ChevronRightIcon />
                  </div>
                </button>

                <button
                  className={`template-builder ${
                    btn === 'editor'
                      ? 'choice__color'
                      : btn === 'personalize' || btn === 'save'
                      ? 'complete__choice'
                      : 'active__choice'
                  }`}
                  onClick={switchToEditor}
                >
                  <span
                    style={{
                      position: 'absolute',
                      left: 8,
                      top: 8,
                      fontSize: 12,
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: btn === 'form' ? colors.blue : 'white',
                      color: btn === 'form' ? 'white' : 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    2
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        width: 10,
                        height: 15,
                      }}
                    >
                      &nbsp;
                    </span>
                    <p style={{ marginTop: 18 }}>Review</p>
                    <ChevronRightIcon />
                  </div>
                </button>

                <button
                  className={`template-builder ${
                    btn === 'personalize'
                      ? 'choice__color'
                      : btn === 'save'
                      ? 'complete__choice'
                      : 'active__choice'
                  }`}
                  onClick={switchToPersonalize}
                >
                  <span
                    style={{
                      position: 'absolute',
                      left: 8,
                      top: 8,
                      fontSize: 12,
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor:
                        btn === 'form'
                          ? colors.blue
                          : btn === 'editor'
                          ? colors.blue
                          : 'white',
                      color:
                        btn === 'form'
                          ? 'white'
                          : btn === 'editor'
                          ? 'white'
                          : 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    3
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        width: 10,
                        height: 15,
                      }}
                    >
                      &nbsp;
                    </span>
                    <p style={{ marginTop: 18 }}>Personalize</p>
                    <ChevronRightIcon />
                  </div>
                </button>

                <button
                  className={`template-builder ${
                    btn === 'editor'
                      ? 'active__choice'
                      : btn === 'save'
                      ? 'complete__choice'
                      : 'active__choice'
                  }`}
                  onClick={() => {
                    setNextClick(false);
                    handleSave();
                    if (!newResumeName) {
                      setShowResumeNameModal(true);
                    }
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        left: 8,
                        top: 8,
                        fontSize: 12,
                        width: 20,
                        height: 20,
                        borderRadius: 50,
                        backgroundColor: btn === 'save' ? 'white' : colors.blue,
                        color: btn === 'save' ? 'black' : 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      4
                    </span>
                    <span
                      style={{
                        width: 10,
                        height: 15,
                      }}
                    >
                      &nbsp;
                    </span>
                    {loader ? (
                      <Loader
                        style={{
                          marginLeft: 7,
                          height: 47,
                          marginTop: 5,
                          paddingRight: 15,
                        }}
                        type="TailSpin"
                        color={colors.blue}
                        height={24}
                        width={24}
                      />
                    ) : (
                      <>
                        <p style={{ marginTop: 18 }}>Save</p>
                        <ChevronRightIcon />
                      </>
                    )}
                  </div>
                </button>
                <button
                  className={`template-builder complete__choice`}
                  style={{
                    paddingInline: 6,
                  }}
                  onClick={() => setStartNewConfirm(true)}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ marginTop: 18, whiteSpace: 'nowrap' }}>
                      Start New
                    </p>
                    <Replay />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={missingInfoModel}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setMissingInfoModel(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div
            id="alert-dialog-slide-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '10px 20px',
            }}
          >
            <h5
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: 20,
                fontFamily: 'Nunito',
              }}
            >
              Missing Information
            </h5>
            <CancelIcon
              style={{
                color: colors.red,
                cursor: 'pointer',
                fontSize: 24,
              }}
              onClick={() => setMissingInfoModel(false)}
            />
          </div>
          <DialogContent>
            <p style={{ color: 'black', fontSize: 16, fontWeight: 'bold' }}>
              We noticed that some information is missing. Please fill missing
              information manually.
            </p>
          </DialogContent>
        </Dialog>
        <Dialog
          open={startNewConfirm}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setStartNewConfirm(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div
            id="alert-dialog-slide-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '10px 20px',
            }}
          >
            <h5
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: 20,
                fontFamily: 'Nunito',
              }}
            >
              Confirm Start New
            </h5>
            <CancelIcon
              style={{
                color: colors.red,
                cursor: 'pointer',
                fontSize: 24,
              }}
              onClick={() => setStartNewConfirm(false)}
            />
          </div>
          <DialogContent>
            <p>Are you sure you want to start a new resume?</p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleStartNew();
              }}
              color="primary"
              variant="contained"
              style={{
                backgroundColor: colors.blue,
                color: colors.white,
                height: '40px',
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showResumeNameModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowResumeNameModal(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <span onClick={() => setShowResumeNameModal(false)}>
            <CancelIcon style={styles.closeBtn} />
          </span>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DescriptionIcon style={{ color: colors.blue }} />
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 20,
            }}
            style={{ marginTop: 10, width: 500, padding: 20 }}
            id="filled-helperText"
            placeholder={'Enter Resume Name'}
            value={newResumeName}
            onChange={(e) => setNewResumeName(e.target.value)}
          />
          <DialogActions>
            {createdOid ? (
              <Button
                onClick={resumeNameHandler}
                style={{
                  backgroundColor: colors.blue,
                  color: 'white',
                }}
              >
                {!loader ? 'Update' : 'Please wait...'}
              </Button>
            ) : (
              <Button
                onClick={handleSave}
                style={{
                  backgroundColor: colors.blue,
                  color: 'white',
                }}
              >
                {!loader ? 'Save' : 'Please wait...'}
              </Button>
            )}
          </DialogActions>
        </Dialog>
        {btn !== 'form' && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Button
              style={{
                minWidth: '80px',
                height: '40px',
                backgroundColor: colors.blue,
                color: 'white',
              }}
              disabled={btn === 'form'}
              onClick={handleBackClick}
            >
              Back
            </Button>
            <Button
              variant="contained"
              style={{
                minWidth: '80px',
                height: '40px',
                backgroundColor: colors.blue,
                color: 'white',
              }}
              onClick={handleNextClick}
            >
              {btn === 'save' ? 'Start New' : 'Next'}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
