import { actionTypes } from './constants';
import { sampleData } from './sample';

const initialState = {
  resumeData: JSON.parse(JSON.stringify({ ...sampleData })),
  theme: {
    color: '#03A9F4',
    fontFamily: 'Source Sans Pro',
  },
  updater: new Date(),
  resumeExist: false,
  // serverUpdater: new Date(),
  resumeName: 'Resumás',
  currentTemplate: 'chonological',
  getScanResultStatus: 'loading',
  getResumeBuilderChoice: 'form',
  builderResumeData: {},
  allBuildResumes: [],
  pdfData: {
    pdfData: {},
    pdfDataUploaded: false,
  },
  accordion: 0,
  templateIndex: 0,
};

export default function resumeEditor(state = initialState, action) {
  switch (action.type) {
    case 'PDF_DATA':
      // eslint-disable-next-line
      const pdf = action.payload;
      return {
        ...state,
        resumeData: action.payload.pdfData,
        pdfData: {
          pdfData: pdf.pdfData,
          pdfDataUploaded: pdf.pdfDataUploaded,
        },
        resumeExist: true,
      };
    case 'ACCORDION_CHANGE':
      return {
        ...state,
        accordion: action.payload,
      };
    case actionTypes.RESUME_EXIST:
      return {
        ...state,
        resumeExist: action.payload,
      };
    ///////// custom Cases ///////////////////////
    case actionTypes.ON_BLUR_FILED:
    case actionTypes.ON_UPDATE_ORDER:
    case actionTypes.ON_ADD_OBJECT:
    case actionTypes.DELETE_OBJECT:
      return {
        ...state,
        resumeData: action.payload,
        builderResumeData: {
          ...state.builderResumeData,
          data: action.payload,
        },
        updater: new Date(),
        serverUpdater: new Date(),
      };

    case actionTypes.SET_ALL_BUILD_RESUMES:
      return {
        ...state,
        allBuildResumes: action.payload,
      };

    case actionTypes.NEW_BUILD_RESUME:
    case actionTypes.RESET_BUILD_RESUME:
      return {
        ...state,
        resumeData: JSON.parse(JSON.stringify({ ...sampleData })),
        updater: new Date(),
        resumeExist: false,
        resumeName: `Resumas`,
        builderResumeData: {},
      };

    ///////////// end Custom Cases /////////////////////////////////////

    case actionTypes.UPDATE_USER_DATA:
      if (!action.payload) return state;

      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
        },
      };
    case actionTypes.CLEAR_STYLES:
      let newData = JSON.parse(JSON.stringify({ ...sampleData }));
      return {
        ...state,
        resumeData: newData,
        updater: new Date(),
      };

    case actionTypes.UPDATE_THEME:
      if (!action.payload) return state;

      return {
        ...state,
        theme: {
          ...state.theme,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_ITEM_STATUS:
      if (!action.payload) return state;

      return {
        ...state,
        itemStatus: {
          ...state.itemStatus,
          ...action.payload,
        },
      };

    case actionTypes.ADD_NEW_WORK_EXPERIENCE:
      if (!action.payload) return state;

      return {
        ...state,
        workExperience: [
          ...state.workExperience,
          {
            ...action.payload,
          },
        ],
      };

    case actionTypes.UPDATE_WORK_EXPERIENCE:
      if (!action.payload) return state;

      return Object.assign({}, state, {
        workExperience: action.payload,
      });

    case actionTypes.UPDATE_WORK_EXPERIENCE_DATA:
      if (!action.payload || !action.payloadId) return state;

      const newWorkExperience = JSON.parse(
        JSON.stringify(state.workExperience),
      );
      const index = state.workExperience
        ?.map((itm) => {
          return itm.id;
        })
        .indexOf(action.payloadId);
      if (index > -1) {
        Object.keys(action.payload).forEach(function (key) {
          newWorkExperience[index][key] = action.payload[key];
        });
      }
      return {
        ...state,
        workExperience: [...newWorkExperience],
      };

    case actionTypes.DELETE_WORK_EXPERIENCE_DATA:
      if (!action.payload) return state;

      let newWkE = JSON.parse(JSON.stringify(state.workExperience));
      newWkE = state.workExperience.filter(({ id }) => id !== action.payload);
      return {
        ...state,
        workExperience: [...newWkE],
      };

    case actionTypes.ADD_DELETED_WORK_EXPERIENCE_ITEM:
      if (!action.payload) return state;

      return {
        ...state,
        workExperience: [...state.workExperience, ...action.payload],
      };

    case actionTypes.ADD_NEW_EDUCATION:
      if (!action.payload) return state;

      return {
        ...state,
        education: [
          ...state.education,
          {
            ...action.payload,
          },
        ],
      };

    case actionTypes.UPDATE_EDUCATION:
      if (!action.payload) return state;

      return Object.assign({}, state, {
        education: action.payload,
      });

    case actionTypes.UPDATE_EDUCATION_DATA:
      if (!action.payload || !action.payloadId) return state;

      const neweducation = JSON.parse(JSON.stringify(state.education));
      const ejuIndex = state.education
        ?.map((itm) => {
          return itm.id;
        })
        .indexOf(action.payloadId);
      if (ejuIndex > -1) {
        Object.keys(action.payload).forEach(function (key) {
          neweducation[ejuIndex][key] = action.payload[key];
        });
      }
      return {
        ...state,
        education: [...neweducation],
      };

    case actionTypes.DELETE_EDUCATION_DATA:
      if (!action.payload) return state;

      let newE = JSON.parse(JSON.stringify(state.education));
      newE = state.education.filter(({ id }) => id !== action.payload);
      return {
        ...state,
        education: [...newE],
      };

    case actionTypes.ADD_DELETED_WORK_EDUCATION_ITEM:
      if (!action.payload) return state;

      return {
        ...state,
        education: [...state.education, ...action.payload],
      };

    case actionTypes.ADD_NEW_SKILL:
      if (!action.payload) return state;

      return {
        ...state,
        skills: [
          ...state.skills,
          {
            ...action.payload,
          },
        ],
      };

    case actionTypes.UPDATE_SKILL:
      if (!action.payload) return state;

      return Object.assign({}, state, {
        skills: action.payload,
      });

    case actionTypes.UPDATE_SKILL_DATA:
      if (!action.payload || !action.payloadId) return state;

      const newSkills = JSON.parse(JSON.stringify(state.skills));
      const skillsIndex = state.skills
        ?.map((itm) => {
          return itm.id;
        })
        .indexOf(action.payloadId);
      if (skillsIndex > -1) {
        Object.keys(action.payload).forEach(function (key) {
          newSkills[skillsIndex][key] = action.payload[key];
        });
      }
      return {
        ...state,
        skills: [...newSkills],
      };

    case actionTypes.DELETE_SKILL_DATA:
      if (!action.payload) return state;

      let newS = JSON.parse(JSON.stringify(state.skills));
      newS = state.skills.filter(({ id }) => id !== action.payload);
      return {
        ...state,
        skills: [...newS],
      };

    case actionTypes.ADD_DELETED_WORK_SKILL_ITEM:
      if (!action.payload) return state;

      return {
        ...state,
        skills: [...state.skills, ...action.payload],
      };

    case actionTypes.CHANGE_THEME:
      return {
        ...state,
        currentTemplate: action.payload,
      };

    case actionTypes.GET_RESUME_SCAN:
      return {
        ...state,
        resumeData: action.payload,
        updater: new Date(),
        resumeExist: true,
      };

    case 'SET_TEMPLATE_INDEX':
      return {
        ...state,
        templateIndex: action.payload,
      };
    case actionTypes.GET_BUILDER_RESUME_DATA:
      return {
        ...state,
        builderResumeData: action.payload,
        updater: new Date(),
      };
    case actionTypes.GET_RESUME_SCAN_STATUS:
      return {
        ...state,
        getScanResultStatus: action.payload,
        updater: new Date(),
      };

    case actionTypes.GET_RESUME_BUILDER_CHOICE:
      return {
        ...state,
        getResumeBuilderChoice: action.payload,
        updater: new Date(),
      };
    case actionTypes.GET_RESUME_NAME:
      return {
        ...state,
        resumeName: action.payload,
      };
    case actionTypes.ADD_SUMMARY:
      return {
        ...state,
        resumeData: {
          ...state.resumeData,
          objective: {
            ...state.resumeData.objective,
            body: action.payload,
          },
        },
      };
    default:
      return { ...state };
  }
}
