import axios from 'axios';
import url from '../../config/endpoint';
import toastr from 'toastr';

export const getPdf = (obj, onClick) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/apply-cv-template`, obj);
    if (!res.data.pdf) {
      if (onClick) {
        onClick(false);
      }
      //toastr.error('An error has been occured: Error in applying pdf template');
      dispatch({ type: 'TEMPLATE_STATUS' });
    }
    if (res.data.message === 'err') {
      if (onClick) {
        onClick(false);
      }
      toastr.error('An error has been occured: Error in applying template');
      dispatch({ type: 'TEMPLATE_STATUS' });
    } else if (res.data.pdf) {
      if (onClick) {
        onClick(false);
      }
      dispatch({
        type: 'PDF_LINK',
        payload: res.data,
      });
      if (onClick) {
        onClick(res.data);
      }
    }
  } catch (error) {
    if (error) {
      if (onClick) {
        onClick(false);
      }
      dispatch({ type: 'TEMPLATE_STATUS' });
      toastr.error(`${error}`);
    }
  }
};

export const getDoc = (obj, onClick) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/apply-cv-template`, obj);
    if (!res.data.doc) {
      if (onClick) {
        onClick(false);
      }
      toastr.error('An error has been occured: Error in applying doc template');
      dispatch({ type: 'TEMPLATE_STATUS' });
    }
    if (res.data.message === 'err') {
      if (onClick) {
        onClick(false);
      }
      toastr.error('An error has been occured: Error in applying template');
      dispatch({ type: 'TEMPLATE_STATUS' });
    } else if (res.data.doc) {
      if (onClick) {
        onClick(false);
      }
      dispatch({
        type: 'DOC_LINK',
        payload: res.data,
      });
      if (onClick) {
        onClick(res.data);
      }
    }
  } catch (error) {
    if (error) {
      if (onClick) {
        onClick(false);
      }
      dispatch({ type: 'TEMPLATE_STATUS' });
      toastr.error(`${error}`);
    }
  }
};

export const setPdfStatusFalse = () => async (dispatch) => {
  dispatch({
    type: 'PDF_STATUS_FALSE',
  });
};

//action for backup
// export const backupYouResumeAction = (obj) => async (dispatch) => {
//   try {
//     const res = await axios.post(`${url}/apply-cv-template`, obj);
//   } catch (error) {
//     if (error) {
//       toastr.error(`${error}`);
//     }
//   }
// };

export const getBuilderPdf = (obj) => async (dispatch) => {
  dispatch({
    type: 'PDF_START',
  });
  try {
    const res = await axios.post(`${url}/apply-cv-template`, obj);
    if (res.data.pdf) {
      dispatch({
        type: 'BUILDER_PDF_LINK',
        payload: res.data.cloundinary,
      });
    } else {
      dispatch({
        type: 'PDF_FAILED',
      });
    }
  } catch (error) {
    toastr.error(`${error}`);
    dispatch({
      type: 'PDF_FAILED',
    });
  }
};

export const getBuilderDocs = (obj) => async (dispatch) => {
  dispatch({
    type: 'DOCS_START',
  });
  try {
    const res = await axios.post(`${url}/apply-cv-template`, obj);
    if (res.data.pdf) {
      dispatch({
        type: 'BUILDER_DOCX_LINK',
        payload: res.data.doc,
      });
    } else {
      dispatch({
        type: 'DOCS_FAILED',
      });
    }
  } catch (error) {
    toastr.error(`${error}`);
    dispatch({
      type: 'DOCS_FAILED',
    });
  }
};

export const setDefault = () => {
  return {
    type: 'TEMPLATE_STATUS',
  };
};
export const getCoverLetters = (page) => async (dispatch) => {
  let token = localStorage.getItem('clientAccessToken');
  try {
    // dispatch({ type: 'GET_LETTERS_STARTED' });
    const res = await axios.get(
      `${url}/get-cover-letter?filters=title,description,tags,default_id,createdAt,actions&page=${page || 1
      }&per_page=5`,
      { headers: { Authorization: `Bearer ${token}` } },
    );

    if (res.data.data) {
      const data = res.data.data.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      let obj = {
        data: data,
        page: res.data.page,
        page_total: res.data.page_total,
      };
      dispatch({
        type: 'COVER_LETTERS',
        payload: obj,
      });
      // dispatch({ type: 'GET_LETTERS_SUCCESS' });
    }
  } catch (error) {
    // toastr.error(`${error}`);
    dispatch({ type: 'GET_LETTERS_FAILED' });
  }
};

export const addCoverLetter = (data) => async (dispatch, getState) => {
  try {
    let obj = {
      ...data,
      oid: getState().authUser.user._id,
    };
    const res = await axios.post(`${url}/add-cover-letter`, obj);
    if (res.data.status === 200) {
      toastr.success(res.data.message);
      dispatch(getCoverLetters(1));
    } else {
      toastr.error('having some issue');
    }
  } catch (error) {
    toastr.error(`${error}`);
  }
};

export const GetCoverAdminLetters = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/admin/get-all-covers`);

    if (res.data.covers) {
      dispatch({
        type: 'COVER_ADMIN_LETTERS',
        payload: res.data.covers,
      });
    }
  } catch (error) { }
};

export const DeleteCoverLatter = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/delete-cover-letter`, { oid: data });
    if (res.data.status === 200) {
      toastr.success(res.data.message);
      dispatch(getCoverLetters(1));
      // const resp = await axios.get(`${url}/get-cover-letter`);
      // if (resp.data.data) {
      //   dispatch({
      //     type: "COVER_LETTERS",
      //     payload: resp.data.data,
      //   });
      // } else {
      //   toastr.error("having some issue in getting letters");
      // }
    } else {
      toastr.success(res.data.message);
    }
  } catch (error) {
    toastr.error(`${error}`);
  }
};

export const UpdateCoverLetter = (data, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-cover-letter`, data);
    if (res.data.status === 200) {
      if (cb) {
        cb(true);
      }
      toastr.success(res.data.message);
      // const resp = await axios.get(`${url}/get-cover-letter`);
      // if (resp.data.data) {
      //   dispatch({
      //     type: "COVER_LETTERS",
      //     payload: resp.data.data,
      //   });
      dispatch({ type: 'GET_LETTERS_SUCCESS' });
      dispatch({ type: 'UPDATE_COVER_LETTER', payload: {
        title: data?.title,
        description: data?.description,
      } });
    } else {
      if (cb) {
        cb();
      }
      toastr.error('having some issue');
      dispatch({ type: 'GET_LETTERS_FAILED' });
    }
    // } else {
    //   toastr.error(res.data.message);
    // }
  } catch (error) {
    if (cb) {
      cb();
    }
    toastr.error(`${error}`);
  }
};
