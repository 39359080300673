import React from 'react';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import { useDispatch } from 'react-redux';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';
import { styles } from './style';
import RichEditor from '../../formBuilder/helper/RichEditor';

function WorkExperience(props) {
  const dispatch = useDispatch();
  const path = 'work.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  const { data } = props;

  const handledData = (() => {
    if (!data?.length) {
      return [
        {
          position: '',
          company: '',
          startDate: '',
          endDate: '',
          location: '',
        },
      ];
    }

    // Check if data is Array of Strings
    if (typeof data[0] === 'string') {
      return [
        {
          position: '',
          company: '',
          startDate: '',
          endDate: '',
          location: '',
        },
      ];
    }

    return data;
  })();

  return (
    <div style={styles.mt_10}>
      <Dnd
        data={handledData}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <div>
            <div className="manager-template-row" style={styles.workItem}>
              <Text
                value={item.company}
                placeholder="Company"
                customclass={'manager-fName manager-template-text-bold'}
                path={`${path}.${index}.company`}
                style={styles.mb_10}
              />
              <p style={styles.paraStyles(false)}>,</p>
              <Text
                value={item.location}
                placeholder="Location"
                customclass={'manager-fName'}
                path={`${path}.${index}.location`}
                style={styles.mb_10}
              />
            </div>
            <div className="manager-template-row" style={styles.workItem}>
              <p style={styles.paraStyles(true)}>Position: </p>
              <Text
                value={item.position}
                placeholder="Position"
                customclass={'manager-fName'}
                path={`${path}.${index}.position`}
              />
            </div>
            <div className="manager-template-row" style={styles.workItem}>
              <p style={styles.paraStyles(true)}>From</p>
              <Text
                value={item.startDate}
                placeholder="Start Date"
                customclass={'manager-fName'}
                path={`${path}.${index}.startDate`}
              />
              <p style={styles.paraStyles(true)}>to</p>
              <Text
                value={item.endDate}
                placeholder="End Date"
                customclass={'manager-fName'}
                path={`${path}.${index}.endDate`}
                max="9999-12-31"
              />
            </div>
            <div>
              <strong>Summary</strong>
              <RichEditor
                value={item.summary}
                enableInline
                placeHolder="Please write summary of your experience, achievement and skills."
                path={`${path}.${index}.summary`}
                onBlurField={onBlurField}
                heightMax={true}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
}
export default WorkExperience;
