import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ContactSupport from '../../profile/help';
import InviteFriends from '../../profile/InviteFriends';
import CancelIcon from '@material-ui/icons/Cancel';
import NotificationIcon from '../../../assets/Notification.webp';
import MenuIcon from '@material-ui/icons/Menu';
import './style.css';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';

import { connect } from 'react-redux';

import { logoutUser } from '../../auth/redux/actions';

import {
  setContainerClassnames,
  clickOnMobileMenu,
  handleInviteFriendModal,
  handleHelpModal,
} from '../redux/actions';

import HelpIcon from '../../../assets/help.webp';

import FullScreenIcon from '../../../assets/fullscreen.webp';
import FullScreenExitIcon from '../../../assets/smallscreen.webp';
import { styles, useStyles } from './style';
import {
  setOnboardingToggle,
  getNotification,
  updateUserNotification,
} from '../../../commonRedux/scan/action';
import { colors } from '../../../commonComponents/colors';
import NotificationManu from '../../../commonComponents/NotificationManu';
import NotificationDialog from '../../../commonComponents/NotificationDialog';
import Help from '../../profile/help';

class TopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInFullScreen: false,
      searchKeyword: '',
      pathname: this.props.location.pathname,
      showHelpModal: false,
      width: 0,

      selectedNotification: null,
      showNotification: false,
    };
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  active = (str) => {
    this.setState({ pathname: str });
  };

  showNotification = (notification) => {
    this.setState({
      showNotification: true,
      selectedNotification: notification,
    });
  };

  closeNotification = () => {
    this.setState({ showNotification: false, selectedNotification: null });
  };

  isInFullScreen = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ pathname: nextProps.link });
  }
  closeModal = () =>
    this.setState({
      showHelpModal: false,
    });
  toggleFullScreen = () => {
    const isInFullScreen = this.isInFullScreen();

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    this.setState({
      isInFullScreen: !isInFullScreen,
    });
  };

  handleLogout = (currentPage) => {
    this.props.logoutUser(this.props.history, currentPage);
  };

  menuButtonClick = (e, menuClickCount, containerClassnames) => {
    e.preventDefault();

    setTimeout(() => {
      var event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);
    this.props.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.props.selectedMenuHasSubItems,
    );
  };
  mobileMenuButtonClick = (e, containerClassnames) => {
    e.preventDefault();
    this.props.clickOnMobileMenu(containerClassnames);
  };

  onboardingPress = (e) => {
    e.stopPropagation();
    this.props.setOnboardingToggle(true, true);
  };
  shrinkText = (firstName, lastName) => {
    let text = firstName + ' ' + lastName;
    if (text?.length > 20) {
      return text.slice(0, 20) + '...';
    } else {
      return text;
    }
  };

  getCurrentPageName() {
    const path = window.location.pathname;
    const pageName = path.substring(path.lastIndexOf('/') + 1);
    return pageName;
  }

  render() {
    const { containerClassnames } = this.props;
    const { classes, tourActivity } = this.props;
    const currentPageName = this.getCurrentPageName();
    const existLength =
      parseInt(tourActivity?.productTour) +
      // parseInt(tourActivity?.videoTour) +
      parseInt(tourActivity?.editTour) +
      // parseInt(tourActivity?.profileTour) +
      parseInt(tourActivity?.jobSearchTour) +
      parseInt(tourActivity?.linkedinTour);

    const totalLeft = 3 - existLength;

    return (
      <>
        <nav
          className="navbar fixed-top top-nav-container change_font_style"
          style={{
            ...(!this.props.resume?.oid &&
              currentPageName === 'optimize' &&
              !this.props.showUpgradeModal && { zIndex: 10000 }),
          }}
        >
          <div
            className=" d-flex align-items-center justify-content-between"
            style={{
              flexBasis: 'auto',
              width: '100%',
            }}
          >
            <Popup
              open={this.props.inviteFriendModal}
              onClose={this.closeModal}
              contentStyle={{
                marginTop: 120,
                borderRadius: 10,
                padding: 10,
                width: this.state.width < 768 ? '90%' : '50%',
              }}
              modal
              position="center"
              closeOnDocumentClick={false}
            >
              <>
                <span onClick={() => this.props.handleInviteFriendModal(false)}>
                  <CancelIcon style={styles.closeBtn} />
                </span>

                <InviteFriends
                  closeModal={this.props.handleInviteFriendModal}
                />
              </>
            </Popup>

            <div className="d-flex align-items-center">
              <NavLink
                to="#"
                style={{
                  display: this.state.width > 1050 ? 'none' : 'block',
                }}
                onClick={(e) =>
                  this.mobileMenuButtonClick(e, containerClassnames)
                }
              >
                <MenuIcon style={{ marginTop: 10, width: 30, height: 20 }} />
              </NavLink>

              <div className="search" data-search-path="/app/pages/search">
                <Link className="navbar-logo" to="/optimize">
                  <img
                    src={require('../../../assets/optirblue.webp')}
                    alt="logo"
                    style={{
                      width: this.state.width <= 375 ? 95 : 150,
                      height: 50,
                      objectFit: 'contain',
                      padding: 5,
                      position: 'relative',
                      top: '-34%',
                      ...(this.state.width <= 375 ? { left: -23 } : {}),
                    }}
                  />
                </Link>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <Row
                style={{
                  display: this.state.width < 1050 ? 'none' : 'block',
                }}
              >
                <Nav style={styles.flex}>
                  {/* Optimize */}
                  <NavItem
                    style={{
                      borderRadius: 0,
                      borderBottom:
                        this.props.location.pathname === '/optimize'
                          ? '2px solid'
                          : '0px',
                      color:
                        this.props.location.pathname === '/optimize'
                          ? '#4267B2'
                          : 'black',
                    }}
                  >
                    <NavLink
                      className={`${classes.topNavLinks} ${
                        this.props.location.pathname === '/optimize'
                          ? classes.active
                          : ''
                      } `}
                      href="#"
                      onClick={() => {
                        this.active('/optimize');
                        this.props.history.push('/optimize');
                      }}
                      style={{
                        fontWeight: 550,
                      }}
                    >
                      Optimize
                    </NavLink>
                  </NavItem>

                  {/* LinkedIn */}
                  <NavItem
                    style={{
                      borderRadius: 0,
                      borderBottom:
                        this.props.location.pathname === '/linkedin'
                          ? '2px solid'
                          : '0px',
                      color:
                        this.props.location.pathname === '/linkedin'
                          ? '#4267B2'
                          : 'black',
                    }}
                  >
                    <NavLink
                      className={`${classes.topNavLinks} ${
                        this.props.location.pathname === '/linkedin'
                          ? classes.active
                          : ''
                      } `}
                      href="#"
                      onClick={() => {
                        this.active('/linkedin');
                        this.props.history.push('/linkedin');
                      }}
                      style={{
                        fontWeight: 550,
                      }}
                    >
                      LinkedIn
                    </NavLink>
                  </NavItem>

                  {/* Job Finder */}
                  <NavItem
                    style={{
                      borderRadius: 0,
                      // paddingBottom: 30,
                      borderBottom:
                        this.props.location.pathname === '/jobmatcher'
                          ? '2px solid'
                          : '0px',
                      color:
                        this.props.location.pathname === '/jobmatcher'
                          ? '#4267B2'
                          : 'black',
                    }}
                  >
                    <NavLink
                      href="#"
                      className={`${classes.topNavLinks} ${
                        this.props.location.pathname === '/jobmatcher'
                          ? classes.active
                          : ''
                      }`}
                      onClick={() => {
                        this.active('/jobmatcher');
                        this.props.history.push('/jobmatcher');
                      }}
                      style={styles.fontWeight}
                    >
                      Job Finder
                    </NavLink>
                  </NavItem>

                  {/* Resume Builder */}
                  <NavItem
                    style={{
                      borderRadius: 0,
                      // paddingBottom: 30,
                      borderBottom:
                        this.props.location.pathname === '/builder'
                          ? '2px solid'
                          : '0px',
                      color:
                        this.props.location.pathname === '/builder'
                          ? '#4267B2'
                          : 'black',
                    }}
                  >
                    <NavLink
                      href="#"
                      className={`${classes.topNavLinks} ${
                        this.props.location.pathname === '/builder'
                          ? classes.active
                          : ''
                      }`}
                      onClick={() => {
                        this.active('/builder');

                        if (
                          !this.props.user?.how_to_start ||
                          this.props.user?.how_to_start === 'Optimize'
                        ) {
                          this.props.history.push(
                            '/questionnaire/work_experience',
                          );
                        } else {
                          this.props.history.push('/builder');
                        }
                      }}
                      style={styles.fontWeight}
                    >
                      Resume Builder
                    </NavLink>
                  </NavItem>

                  {/* milXwalk */}
                  <NavItem
                    style={{
                      borderRadius: 0,
                      borderBottom:
                        this.props.location.pathname === '/milxwalk'
                          ? '2px solid'
                          : '0px',
                      color:
                        this.props.location.pathname === '/milxwalk'
                          ? '#4267B2'
                          : 'black',
                    }}
                  >
                    <NavLink
                      className={`${classes.topNavLinks} ${
                        this.props.location.pathname === '/milxwalk'
                          ? classes.active
                          : ''
                      } `}
                      href="#"
                      onClick={() => {
                        this.active('/milxwalk');
                        this.props.history.push('/milxwalk');
                      }}
                      style={{
                        fontWeight: 550,
                      }}
                    >
                      milXwalk
                    </NavLink>
                  </NavItem>

                  {/* <NavItem
                  style={{
                    borderRadius: 0,
                    // paddingBottom: 30,
                    borderBottom:
                      this.props.location.pathname === '/templates'
                        ? '2px solid'
                        : '0px',
                    color:
                      this.props.location.pathname === '/templates'
                        ? '#4267B2'
                        : 'black',
                  }}
                >
                  <NavLink
                    href="#"
                    className={`${classes.topNavLinks} ${
                      this.props.location.pathname === '/templates'
                        ? classes.active
                        : ''
                    }`}
                    onClick={() => {
                      this.active('/templates');
                      this.props.history.push('/templates');
                    }}
                    style={styles.fontWeight}
                  >
                    Manage
                  </NavLink>
                </NavItem> */}

                  {/* Dashboard */}
                  <NavItem
                    style={{
                      borderRadius: 0,
                      // paddingBottom: 30,
                      borderBottom:
                        this.props.location.pathname === '/dashboards'
                          ? '2px solid'
                          : '0px',
                      color:
                        this.props.location.pathname === '/dashboards'
                          ? '#4267B2'
                          : 'black',
                    }}
                  >
                    <NavLink
                      href="#"
                      className={`${classes.topNavLinks} ${
                        this.props.location.pathname === '/dashboards'
                          ? classes.active
                          : ''
                      }`}
                      onClick={() => {
                        this.active('/dashboards');
                        this.props.history.push('/dashboards');
                      }}
                      style={styles.fontWeight}
                    >
                      Dashboard
                    </NavLink>
                  </NavItem>
                  <NavItem
                    title={'Help'}
                    style={{
                      borderRadius: 0,
                      // paddingBottom: 30,
                      borderBottom:
                        this.props.location.pathname === '/help'
                          ? '2px solid'
                          : '0px',
                      color:
                        this.props.location.pathname === '/help'
                          ? '#4267B2'
                          : 'black',
                    }}
                  >
                    <NavLink
                      href="#"
                      title={'Help'}
                      className={`${classes.topNavLinks} ${
                        this.props.location.pathname === '/help'
                          ? classes.active
                          : ''
                      }`}
                      style={styles.fontWeight}
                    >
                      <span title={'Help'}></span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Row>
              <img
                onClick={() => this.props.handleHelpModal(true)}
                title={'Help'}
                src={HelpIcon || require('../../../assets/help.webp')}
                style={{
                  width: 35,
                  height: 20,
                  cursor: 'pointer',
                  objectFit: 'contain',
                }}
                alt="help"
              />

              <Help />
              <div
                style={{ marginRight: 0 }}
                className="header-icons d-inline-block align-middle ml-3"
              >
                <button
                  className="header-icon btn btn-empty d-none d-sm-inline-block"
                  type="button"
                  id="fullScreenButton"
                  onClick={this.toggleFullScreen}
                >
                  {this.state.isInFullScreen ? (
                    <img
                      title={'Exit Full Screen'}
                      src={
                        FullScreenExitIcon ||
                        require('../../../assets/smallscreen.webp')
                      }
                      style={{ width: 25, height: 25, objectFit: 'center' }}
                      alt="Exit Full Screen"
                    />
                  ) : (
                    <img
                      src={
                        FullScreenIcon ||
                        require('../../../assets/fullscreen.webp')
                      }
                      title={'Full Screen'}
                      style={{ width: 25, height: 25, objectFit: 'center' }}
                      alt="Full Screen"
                    />
                  )}
                </button>
              </div>

              <div
                style={{ marginRight: '.5rem' }}
                className="header-icons d-inline-block align-middle"
              >
                {this.props.user?._id ? (
                  <NotificationManu
                    NotificationIcon={NotificationIcon}
                    email={this.props.user}
                    getNotification={this.props.getNotification}
                    selectNotification={this.showNotification}
                    notification={this.props.notification}
                    updateUserNotification={this.props.updateUserNotification}
                  />
                ) : null}
              </div>
              <div
                style={{ marginRight: '.5rem' }}
                className="header-icons d-inline-block align-middle"
                id="modal"
              >
                <button
                  className="header-icon btn btn-empty d-none d-sm-inline-block"
                  type="button"
                  id="modal"
                  onClick={this.onboardingPress}
                >
                  {/* {totalLeft ? ( */}
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    {/* <img
                    src={NotificationIcon}
                    title={'Notifications'}
                    id="modal"
                    style={{
                      width: 20,
                      height: 25,
                      objectFit: 'center',
                    }}
                  /> */}
                    <div id="modal">
                      <AssignmentIcon
                        id="modal"
                        style={{
                          fontSize: '27px',
                          color: colors.blue,
                          transform: 'rotate(180deg)',
                        }}
                      />
                    </div>
                    <span
                      id="modal"
                      style={{
                        position: 'absolute',
                        top: 2,
                        right: -3,
                        width: 13,
                        height: 13,
                        color: colors.blue,
                        fontSize: '10px',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        border: '1px solid white',
                        backgroundColor: colors.yellow,
                      }}
                    >
                      {totalLeft}
                    </span>
                  </div>
                  {/* ) : (
                  <img
                    title={'Notifications'}
                    src={NotificationIcon}
                    style={{
                      width: 20,
                      height: 25,
                      objectFit: 'center',
                    }}
                  />
                )} */}
                </button>
              </div>
              <div
                style={{ marginRight: '.5rem' }}
                className="header-icons d-inline-block align-middle"
              >
                <UncontrolledDropdown className="dropdown-menu-right mr-3">
                  <DropdownToggle className="p-0" color="empty">
                    <span
                      style={{
                        backgroundColor: '#4267B2',
                        display: 'flex',
                        color: 'white',
                        width: 15,
                        height: 15,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 50,
                        padding: 13,
                      }}
                    >
                      <p style={{ fontSize: 16, marginTop: 18 }}>
                        {this.props.user?.firstName?.charAt(0)}
                      </p>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      width: 200,

                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: 13,
                    }}
                    className="mt-1 change_font_style dropdown-menu-top-nav"
                    right
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        overflow: 'hidden',
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: '#4267B2',
                          display: 'flex',
                          color: 'white',
                          width: 50,
                          height: 50,
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 50,
                          padding: 18,
                          marginBottom: 5,
                        }}
                      >
                        <p style={{ fontSize: 35, marginTop: 20 }}>
                          {' '}
                          {this.props.user?.firstName?.charAt(0)}
                        </p>
                      </span>
                      <h6 style={{ textIndent: 10 }}>
                        {this.shrinkText(
                          this.props.user ? this.props.user?.firstName : '',
                          this.props.user ? this.props.user?.lastName : '',
                        )}
                      </h6>
                      <p
                        style={{
                          color: 'rgba(0,0,0,0.5)',
                          marginTop: -6,
                          lineBreak: 'anywhere',
                          padding: '5px 10px',
                        }}
                      >
                        {this.props.user?.email}
                      </p>
                    </div>
                    <DropdownItem divider className="pt-0 pb-0" />

                    <DropdownItem
                      onClick={() => this.props.history.push('/account')}
                      style={styles.fontWeight}
                    >
                      Account
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.history.push('/DownloadApp')}
                      style={styles.fontWeight}
                    >
                      Download App
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.props.handleInviteFriendModal(true)}
                      style={styles.fontWeight}
                    >
                      Invite Friends
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.history.push('/faq', {
                          comingFrom: 'TopNav',
                        })
                      }
                      style={styles.fontWeight}
                    >
                      FAQ
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.history.push('/page-terms', {
                          comingFrom: 'TopNav',
                        })
                      }
                      style={styles.fontWeight}
                    >
                      Terms of Service
                    </DropdownItem>

                    <DropdownItem
                      onClick={() =>
                        this.props.history.push('/page-privacy', {
                          comingFrom: 'TopNav',
                        })
                      }
                      style={styles.fontWeight}
                    >
                      Privacy Policy
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleLogout(currentPageName)}
                      style={styles.fontWeight}
                      // className="border-bottom"
                    >
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        Sign out
                        <ExitToAppRoundedIcon />
                      </span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </nav>
        <NotificationDialog
          isOpen={this.state.showNotification}
          notification={this.state.selectedNotification}
          onClose={this.closeNotification}
        />
      </>
    );
  }
}

const mapStateToProps = ({ menu, linkReducer, fileScanReducer, authUser }) => {
  const {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
    inviteFriendModal,
  } = menu;
  const { user, showUpgradeModal } = authUser;

  return {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
    link: linkReducer,
    tourActivity: fileScanReducer.tourActivity,
    user,
    notification: fileScanReducer.notification,
    resume: fileScanReducer.resume,
    showUpgradeModal,
    inviteFriendModal,
  };
};

TopNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  setContainerClassnames,
  clickOnMobileMenu,
  logoutUser,
  setOnboardingToggle,
  getNotification,
  updateUserNotification,
  handleInviteFriendModal,
  handleHelpModal,
})(withRouter(withStyles(useStyles)(TopNav)));
