import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Colxx } from '../../commonComponents/customBootstrap';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import { currentLink } from '../../commonRedux/currentNav/action';
import { colors } from '../../commonComponents/colors';
import {
  getJobs,
  SaveJobMatches,
  GetJobMatches,
} from '../../commonRedux/jobs/action';
import { currentUser } from '../../modules/auth/redux/actions';
import JobFinderLoader from '../../commonComponents/jobfinder';
import Jobs from './jobs';
import { scanHistory } from '../../commonRedux/scan/action';
import JobLeftView from './jobMatcher';
import { styles } from './style';
import VideoModel from '../../commonComponents/videoModel';
import howToIcon from '../../assets/How-to.svg';
import InfoIcon from '../../commonComponents/InfoIcon';
import './jobMatcher.css';
class JobMatcher extends Component {
  state = {
    loader: false,
    data: [],
    selectedApi: '',
    modelOpen: false,
    searchesOpen: false,
    filterDate: 'All',
  };
  UNSAFE_componentWillMount = () => {
    this.props.currentLink(this.props.location.pathname);
  };
  componentDidMount = () => {
    // this.props.scanHistory();
    this.setState({
      searchesOpen: false,
    });
    this.props.GetJobMatches();
    // this.props.currentUser();
  };
  UNSAFE_componentWillReceiveProps = (nextPorps) => {
    if (nextPorps.status === 'done') {
      this.setState({ loader: false, data: nextPorps.jobs });
    }
    if (nextPorps.status === 'not done') {
      this.setState({ loader: false });
    }
  };

  getskills = (loader) => {
    this.setState({
      loader: loader,
    });
  };

  toggleSearches = () => {
    this.setState({
      searchesOpen: true,
    });
  };
  dateFilter = (data) => {
    this.setState({
      filterDate: data,
    });
  };
  render() {
    return (
      <div className="job_matcher_main_container">
        <Row style={styles.justifyContentCenter}>
          <Colxx lg="12">
            <Card className="mb-1">
              <CardBody style={styles.cardBody}>
                <div>
                  <CardTitle style={styles.cardTitleFont}>
                    {/* <RightIconButton style={{
                      marginLeft: "10px",
                    }}
                    onClick={() => this.setState({ modelOpen: true })}
                  /> */}
                    <InfoIcon
                      onClick={() => this.setState({ modelOpen: true })}
                      text={'How To'}
                      icon={'Play'}
                      height={18}
                      width="68px"
                      marginTop={-3}
                      marginRight={5}
                    />
                    <span style={styles.cardTitle}>Job Finder</span>
                  </CardTitle>
                  <p className="text-muted" style={styles.textMuted}></p>
                  <div className="job-matcher-proTip">
                    <JobLeftView
                      SaveJobMatches={this.props.SaveJobMatches}
                      jobmatches={this.props.jobmatches}
                      checkStatus={this.props.checkStatus}
                      getJobs={this.props.getJobs}
                      loader={this.state.loader}
                      getskills={this.getskills}
                      toggleSearches={this.toggleSearches}
                      userLatestScan={this.props.userLatestScan}
                      filterDate={this.state.filterDate}
                      setFilterDate={this.dateFilter}
                    />
                  </div>
                </div>
                {this.state.searchesOpen && (
                  <>
                    {' '}
                    {this.state.loader ? (
                      <>
                        <JobFinderLoader />
                        <JobFinderLoader />
                        <JobFinderLoader />
                      </>
                    ) : (
                      <div
                        style={{
                          marginTop: 50,
                        }}
                      >
                        {/* <CardTitle>
                      <span style={styles.cardTitle}>Search Results</span>
                    </CardTitle> */}
                        {this.state.data.length < 1 ? (
                          <div style={styles.dataList}>
                            <span
                              style={{ fontWeight: 'bold', fontSize: '16px' }}
                            >
                              No Data Found
                            </span>
                          </div>
                        ) : (
                          this.state.data
                            ?.filter((item) => {
                              if (this.state.filterDate === 'All') {
                                return item;
                              } else if (
                                this.state.filterDate === 'yesterday'
                              ) {
                                return (
                                  item?.posted_time_friendly === 'yesterday'
                                );
                              } else if (
                                this.state.filterDate === '7 days ago'
                              ) {
                                return (
                                  item?.posted_time_friendly.split(' ')[0] <
                                    7 &&
                                  item?.posted_time_friendly.split(' ')[1] ===
                                    'days'
                                );
                              } else if (
                                this.state.filterDate === '14 days ago'
                              ) {
                                return (
                                  item?.posted_time_friendly.split(' ')[0] <
                                    14 &&
                                  item?.posted_time_friendly.split(' ')[1] ===
                                    'days'
                                );
                              } else if (
                                this.state.filterDate === '1 month ago'
                              ) {
                                return (
                                  (item?.posted_time_friendly.split(' ')[0] ==
                                    1 &&
                                    item?.posted_time_friendly.split(' ')[1] ===
                                      'month') ||
                                  (item?.posted_time_friendly.split(' ')[0] <
                                    30 &&
                                    item?.posted_time_friendly.split(' ')[1] ===
                                      'days')
                                );
                              } else {
                                return item;
                              }
                            })
                            ?.map((item, index) => {
                              return (
                                <>
                                  {item?.length < 1 ? (
                                    <div style={styles.dataList}>
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: '16px',
                                        }}
                                      >
                                        No Data Found
                                      </span>
                                    </div>
                                  ) : (
                                    <Jobs
                                      key={index}
                                      job={item}
                                      skills={this.props.skills}
                                      skillsFromLatestScan={
                                        this.props.skillsFromLatestScan
                                      }
                                      loginUser={this.props.loginUser}
                                    />
                                  )}
                                </>
                              );
                            })
                        )}
                      </div>
                    )}{' '}
                  </>
                )}
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <VideoModel
          open={this.state.modelOpen}
          setOpen={(open) => this.setState({ modelOpen: open })}
          video={this.props.VideoLinks?.JobFinder}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ jobRducer, fileScanReducer, authUser }) => {
  const { user: loginUser } = authUser;

  return {
    jobs: jobRducer.jobs,
    status: jobRducer.jobsStatus,
    updator: jobRducer.jobUpdator,
    skills: jobRducer.skills,
    jobmatches: jobRducer.jobmatches,
    userLatestScan: jobRducer.userLatestScan,
    checkStatus: jobRducer.status,
    skillsFromLatestScan: fileScanReducer.skillsFromLatest,
    VideoLinks: fileScanReducer.videoLinks,
    loginUser,
  };
};

export default connect(mapStateToProps, {
  currentLink,
  getJobs,
  scanHistory,
  GetJobMatches,
  SaveJobMatches,
  currentUser,
})(JobMatcher);
