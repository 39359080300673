import React, { useState, useEffect, Fragment } from 'react';
import ConnectLinkedIn from '../../assets/linkedin.webp';
import BulbImage from '../../assets/bulb.webp';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import UpgradeModal from '../../commonComponents/UpgradeModal';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../commonComponents/colors';
import { Button } from 'antd';
import CancelIcon from '@material-ui/icons/Cancel';
import toastr from 'toastr';
import RightIconButton from '../../commonComponents/RightIconButton';
import InfoIcon from '../../commonComponents/InfoIcon';
import { useHistory } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import LinkedInAnimation from '../../commonComponents/scanLoader';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Connect(props) {
  const user = useSelector((state) => state.authUser.user);
  const [loading, setLoading] = useState(false);
  const showLinkedInDialog = useSelector(
    (state) => state.fileScanReducer.showLinkedInDialog,
  );
  const dispatch = useDispatch();
  const userResumas =
    useSelector((state) => state.fileScanReducer?.userResumas) || [];
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedinAuth`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      if (code) {
        localStorage.setItem('LinkedIn_Token', code);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          props.handleNext();
        }, 8000);
      }
    },
    onError: () => {
      setErrorMessage(
        'Go to linkedin.com, reset password and make sure you can login. If you have account, please signup for new account.',
      );
    },
  });
  useEffect(() => {
    if (user && user.isPremium) {
      const token = localStorage.getItem('LinkedIn_Token');
      if (token) {
        props.handleNext();
      }
    }

    // }
  }, [user]);
  return loading ? (
    <LinkedInAnimation isLinkedIn={true} />
  ) : (
    <div
      style={{
        marginTop: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={userResumas.length > 0 ? null : showLinkedInDialog}
        // onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {props.dialogLoading ? (
          <div
            style={{
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BeatLoader
              className="loader"
              css={override}
              sizeUnit={'px'}
              size={20}
              color={'#D5641C'}
              loading={true}
            />
          </div>
        ) : (
          <Fragment>
            <div
              id="alert-dialog-slide-title"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '10px 20px',
              }}
            >
              <h5
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: 20,
                  fontFamily: 'Nunito',
                }}
              >
                LinkedIn Profile
              </h5>
            </div>
            <DialogContent>
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <h5>
                  Please “optimize” your own resume at least once before
                  connecting your LinkedIn account
                </h5>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    width: '100%',
                  }}
                >
                  <Button
                    className="mt-3"
                    variant="contained"
                    onClick={() => history.push('/optimize')}
                    style={{
                      backgroundColor: colors.blue,
                      color: colors.white,
                    }}
                  >
                    Optimize First
                  </Button>
                  <Button
                    className="mt-3"
                    variant="contained"
                    disabled={true}
                    onClick={() => props.setShowDialog(false)}
                    style={{
                      backgroundColor: colors.grey,
                      color: colors.white,
                    }}
                  >
                    Proceed
                  </Button>
                </div>
              </span>
            </DialogContent>
          </Fragment>
        )}
      </Dialog>
      {user?.subscription === false && <UpgradeModal />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <InfoIcon
          // onClick={() => this.setState({ modelOpen: true })}
          text={'PRO TIP'}
          icon={'Bulb'}
          height={20}
          width="80px"
          marginTop={-5}
        />

        <span
          style={{
            marginLeft: '5px',
            marginRight: '10px',
            fontSize: '10px',
            padding: '2px 5px',
            borderRadius: '5px',
            border: '1px solid gray',
            backgroundColor: '#ffffff',
            fontWeight: '900',
            fontFamily: 'Roboto',
          }}
        >
          Run Optimize {'>'} Review at least once before you run this
        </span>
      </div>
      {user?.subscription === false && (
        <LockIcon
          style={{
            position: 'absolute ',
            right: 5,
            top: 5,
            fontSize: 12,
          }}
        />
      )}
      <img
        onClick={() => {
          if (user?.subscription === false) {
            toastr.error('Please upgrade to premium to use this feature');
            return;
          } else {
            linkedInLogin();
          }
        }}
        src={ConnectLinkedIn}
        alt="Sign in with Linked In"
        style={{
          maxWidth: '180px',
          cursor: 'pointer',
          marginBottom: 20,
        }}
      />
      {errorMessage && <h5>{errorMessage}</h5>}
    </div>
  );
}
