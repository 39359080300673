import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardBody, CardTitle, Col } from 'reactstrap';
import _ from 'lodash';
import LockIcon from '@material-ui/icons/Lock';
import { Colxx } from '../../commonComponents/customBootstrap';
import LoopIcon from '@material-ui/icons/Loop';
import toastr from 'toastr';
import howToIcon from '../../assets/How-to.svg';
import { useWindowSize } from '@react-hook/window-size';
import { ColorlibConnector, ColorlibStepIcon } from './stepperConfig';
import {
  fileParser,
  scanResults,
  clearScanResult,
  getResumaResults,
  getResumaId,
  zipRecruiterAction,
  SaveActiveIndex,
  zipRecruiterActionFromJobs,
  scanHistory,
  resumeStats,
  getLatestScanResult,
  clearLatestScanResult,
  setTourActivity,
  getDefaultFiles,
  showTourSection,
  openUpgradeModel,
  getSuggestions,
  setSelectedTitle,
  parseTextFile,
  updateEditorJson,
} from '../../commonRedux/scan/action';
import { Link } from 'react-router-dom';
import { getFormatedTextFromBuilder } from '../../utils/getFormatedTextFromBuilder';
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';
import { currentLink } from '../../commonRedux/currentNav/action';
import { currentUser } from '../auth/redux/actions';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './style.css';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ScanResult from '../scanresult/scanresult';
import UpdateEditEditor from './updateEditEditor';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import SaveAndApply from './SaveAndApply';
import FineTuning from './FineTuning';
import { Select, Input } from 'antd';
import { FolderOpenFilled } from '@ant-design/icons';
import { styles, useStyles } from './style';
import RecentScanCard from '../../commonComponents/scan/firstSection';
import ScanAnimation from '../../commonComponents/scanLoader';
import RecentScanCard2 from '../../commonComponents/scan/thirdSection';
import RecentScanCard3 from '../../commonComponents/scan/fourthSection';
import GraphqLoader from '../../commonComponents/scan/graphLoader';
import { GetStepImage } from '../../utils/GetStepImage';
import { Check } from '@material-ui/icons';
import { colors } from '../../commonComponents/colors';
import VideoModel from '../../commonComponents/videoModel';
import InfoIcon from '../../commonComponents/InfoIcon';
import Arrow from '../../assets/arrowold.webp';
import axios from 'axios';
import SolrAutoSuggestion from '../../commonComponents/jobSuggestion';
import AutoCompleteCompany from '../../commonComponents/autoCompleteCompany/AutoCompleteCompany';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const { Option } = Select;
class Scan extends Component {
  state = {
    file: '',
    job_file: '',
    type: 'text',
    resume: '',
    jobdescription: '',
    loader: false,
    selcted_oid: 'none',
    zipRecruiterUrl: '',
    jobTitle: '',
    company: '',
    tempJobTitle: '',
    tempCompany: '',
    isTourOpen: false,
    isChangedResume: false,
    isChangedJob: false,
    modelOpen: false,
    listState: 'Saved Resumes',
    introVideoModel: false,
  };

  componentDidMount() {
    this.props.getResumaId();
    // this.props.getSuggestions(this?.props?.selectedTitle);

    let getId = localStorage.getItem('myScanId');

    if (getId) {
      this.props.getLatestScanResult('not done', getId);
    }
  }

  UNSAFE_componentWillMount = () => {
    this.setState({ resume: '', jobDesc: '' });
    this.props.clearScanResult();
    this.props.currentLink(this.props.clocation);
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.scanResult) {
      this.setState({
        resume: nextProps.scanResult?.resumeText,
        jobdescription: nextProps.scanResult.jobDescription,
        jobTitle: nextProps.scanResult.jobTitle,
        company: nextProps.scanResult.company,
      });
    }

    // if (nextProps.showTourSection === 0 && !this.state.isTourOpen) {
    if (
      nextProps.showTourSection === 0 &&
      !this.state.isTourOpen &&
      (Object.keys(nextProps.VideoLinks) ?? [])?.length
    ) {
      // this.openTour();
      this.setState({
        introVideoModel: true,
      });
      setTimeout(() => {
        this.props.setShowTourSection(-1);
      }, 500);
    }

    if (nextProps.resumeDescription) {
      this.setState({
        resume: nextProps.resumeDescription,
      });
    }
    if (nextProps.scanResultStatus === 'not-Done') {
      this.setState({ loader: false });
    }

    if (nextProps.scanResultStatus === 'done') {
      this.setState({ loader: false });
      this.props.activeStep(1);
    }

    if (nextProps.recruiterData) {
      this.setState({
        jobdescription:
          nextProps.recruiterData !== '' &&
          nextProps.recruiterData[0].jobdescription,
      });
    }
  };

  handleInput = (e) => {
    const { name } = e.target;

    if (name === 'jobdescription') {
      this.setState({
        isChangedJob: true,
      });
    } else {
      this.setState({
        isChangedResume: true,
      });
    }
    this.setState({
      [name]: e.target.value,
    });
  };

  ScanningHandler = async () => {
    if (!this.props.user?._id) {
      await this.props.currentUser?.();
      this.ScanningHandler();
    } else {
      this.scanning();
    }
  };

  scanning = () => {
    if (this.props.user?.credit < 1) {
      toastr.error('Please purchase a subscription to scan!');
      return;
    }

    if (this.state.resume === '') {
      toastr.error('Resume can not be empty!');
    } else if (this.state.jobdescription === '') {
      toastr.error('Job description can not be empty!');
    } else if (!this.state.company && !this.state.jobTitle) {
      toastr.error('Company Name & Job Title Required!');
    } else if (!this.state.company) {
      toastr.error('Company Name Required!');
    } else if (!this.state.jobTitle) {
      toastr.error('Job Title Required!');
    } else if (this.state.jobdescription.length < 100) {
      toastr.error('Job description must be greater than 100!');
    } else {
      this.setState({ loader: true });

      // return null
      if (this.state.selcted_oid === 'none') {
        const form_data = new FormData();
        if (this.state.job_file) {
          form_data.append('job_file', this.state.job_file);
        }
        form_data.append('file', this.state.file);
        form_data.append(
          'data',
          JSON.stringify({
            resumeText: this.state?.resume,
            jobDescription: this.state.jobdescription,
            fileType: this.state.type,
            is_parsed: false,
            jobTitle: this.state.jobTitle,
            company: this.state.company,
            isChangedResume: this.state.isChangedResume,
            isChangedJob: this.state.isChangedJob,
            userId: this.props.user?._id,
          }),
        );
        this.props.scanResults(form_data);
      } else {
        const form_data = new FormData();
        if (this.state.job_file) {
          form_data.append('job_file', this.state.job_file);
        }

        form_data.append('file', this.state.file);
        form_data.append(
          'data',
          JSON.stringify({
            resumeText: this.state?.resume,
            jobDescription: this.state.jobdescription,
            fileType: this.state.type,
            is_parsed: true,
            oid: this.state.selcted_oid,
            jobTitle: this.state.jobTitle,
            company: this.state.company,
            isChangedResume: this.state.isChangedResume,
            isChangedJob: this.state.isChangedJob,
            userId: this.props.user?._id,
          }),
        );
        this.props.scanResults(form_data);
      }
    }
  };

  sendZipRecruiterUrl = () => {
    if (this.state.zipRecruiterUrl === '') {
      toastr.error("field can't be empty!");
    } else {
      this.props.zipRecruiterAction(this.state.zipRecruiterUrl);
    }
  };

  saveJobDescription = (description) => {
    this.setState({
      jobdescription: description,
    });
  };

  handleFile = (e) => {
    e.persist();
    let userFirstName = this.props.user.firstName;
    let userId = this.props.user._id;

    this.setState(
      {
        file: e.target.files[0],
        isChangedResume: true,
        listState: 'Uploaded original resume',
      },
      () => {
        try {
          if (e.target.files[0]) {
            if ('txt' === e.target.files[0].name.split('.').pop()) {
              this.setState({ type: this.state.file.type.split('/')[1] });
              this.props.textFileParser({
                file: e.target.files[0],
                fileType: 'resume',
              });
            } else if (
              ['pdf', 'docx', 'doc'].includes(
                e.target.files[0].name.split('.').pop(),
              )
            ) {
              this.setState({ type: this.state.file.type.split('/')[1] });
              this.props.fileParser(
                this.state.file,
                null,
                userFirstName,
                userId,
              );
            } else {
              toastr.error('please upload pdf, doc, docx and txt files only');
            }
          }
        } catch (error) {
          alert(error);
        }
      },
    );
  };

  handleJobFile = (e) => {
    let userFirstName = this.props.user.firstName;
    let userId = this.props.user._id;
    try {
      if (e.target.files[0]) {
        if ('txt' === e.target.files[0].name.split('.').pop()) {
          this.setState({
            job_file: e.target.files[0],
            isChangedJob: true,
          });
          this.props.textFileParser({
            file: e.target.files[0],
            fileType: 'job',
            callback: this.saveJobDescription,
          });
        } else if (
          ['pdf', 'docx', 'doc'].includes(
            e.target.files[0].name.split('.').pop(),
          )
        ) {
          this.setState(
            {
              job_file: e.target.files[0],
              isChangedJob: true,
            },
            () => {
              this.props.fileParser(
                this.state.job_file,
                this.saveJobDescription,
                userFirstName,
                userId,
              );
            },
          );
        } else {
          toastr.error('please upload pdf, doc, docx and txt files only');
        }
      }
    } catch (error) {}
  };

  loadSample = () => {
    this.setState({
      resume: this.props.defaultFiles?.resume,
      jobdescription: this.props.defaultFiles?.jobDescription,
      company: this.props.defaultFiles?.company,
      jobTitle: this.props.defaultFiles?.jobTitle,
      tempCompany: this.props.defaultFiles?.company,
      tempJobTitle: this.props.defaultFiles?.jobTitle,
      isChangedJob: false,
      isChangedResume: false,
    });
    this.props.setSelectedTitle(this.props.defaultFiles?.jobTitle);
  };

  viewResuma = () => {
    if (this.state.selcted_oid !== 'none') {
      const { userResumas } = this.props;
      const data = _.find(userResumas, ['oid', this.state.selcted_oid]);
      this.props.pushprops.push('/builder', {
        isResume: true,
        data: data.data,
        oid: this.state.selcted_oid,
      });
    } else {
      toastr.error('please select existing resume!');
    }
  };

  onResumaSelected = (e) => {
    this.setState({ selcted_oid: e.target.value });
  };

  handleChange = (value) => {
    this.setState({
      selcted_oid: value,
      listState: 'Saved Resumes',
    });
    if (value !== 'none') {
      let setResumeTextFromExistingResume = this.props.userResumas.filter(
        (item) => item.oid === value,
      );
      if (
        setResumeTextFromExistingResume[0].resumeText ||
        setResumeTextFromExistingResume[0].CompletePlainText
      ) {
        this.setState({
          resume:
            setResumeTextFromExistingResume[0]?.CompletePlainText ||
            setResumeTextFromExistingResume[0]?.resumeText,
        });
      } else {
        this.setState({
          resume: getFormatedTextFromBuilder(
            setResumeTextFromExistingResume[0]?.data,
          ),
        });
      }
    }
  };

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  closeTour = () => {
    // setIsTourOpen(false);
    this.setState({
      isTourOpen: false,
    });
  };

  openTour = () => {
    this.setState({
      isTourOpen: true,
    });
    // setIsTourOpen(true);
  };

  finishTour = () => {
    this.props.setTourActivity({
      productTour: 1,
    });
  };

  shrinkName = (name) => {
    if (name?.length > 20) {
      return name.slice(0, 20) + '...';
    } else {
      return name;
    }
  };

  handleChangeCompany = (address) => {
    this.setState({ tempCompany: address });
  };
  handleSelectCompnay = (address) => {
    const mainCompanyText = address?.split(',')?.[0];
    this.setState({ company: mainCompanyText });
    this.setState({ tempCompany: mainCompanyText });
  };
  render() {
    // if (true) {
    if (this.props.resumeAnalyzStatus) {
      return <ScanAnimation />;
    }
    return (
      <>
        <VideoModel
          open={this.state.modelOpen}
          setOpen={(open) => this.setState({ modelOpen: open })}
          video={this.props.VideoLinks?.ScanPage}
        />

        {/* <VideoModel
          open={this.state.introVideoModel}
          setOpen={() => {
            this.setState({ introVideoModel: false });
            this.openTour();
          }}
          onEnded={() => {
            this.setState({ introVideoModel: false });
            this.finishTour();
          }}
          video={this.props.VideoLinks?.IntroVideo}
          closeAble={false}
        /> */}

        <div className="scan_main_container">
          <div style={styles.scanMainContainer} data-tut="reactour__2">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                ...(this.props.width < 500 && {
                  flexDirection: 'column',
                  alignItems: 'center',
                }),
              }}
            >
              <InfoIcon
                onClick={() => this.setState({ modelOpen: true })}
                text={'How To'}
                icon={'Play'}
                height={18}
                width="70px"
                marginTop={8}
                marginRight={5}
              />
              <h4 style={{ ...styles.h1(this.props.width) }}>
                START RESUME OPTIMIZATION
              </h4>
              {/* <div>
                <img
                  src={howToIcon}
                  style={{
                    width: '75px',
                    height: '16px',
                    marginTop: '9px',
                    marginLeft: '3px',
                  }}
                  onClick={() => this.setState({ modelOpen: true })}
                />
              </div> */}
            </div>
            <span style={styles.spanSample}>
              To begin optimizing your resume for the job identified, please
              follow STEP 1, STEP 2 and STEP 3 below.
            </span>
            <Row
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                marginBottom: 20,
                minWidth: 250,
                ...(this.props.width > 500 && { width: '99.5%' }),
              }}
            >
              <Col
                style={{
                  ...(this.props.width < 500 && {
                    paddingLeft: 0,
                    paddingRight: 0,
                  }),
                }}
                sm={12}
                xs={12}
                md={6}
                lg={6}
                xl={6}
              >
                <Card style={{ padding: 10 }}>
                  <p style={{ fontSize: 14 }}>
                    Don&lsquo;t have a job description & resume?
                  </p>

                  <Button
                    // className="try_sample"
                    style={{
                      backgroundColor: 'rgb(66, 103, 178)',
                      color: 'white',
                      fonSize: 14,
                      fontStyle: 'normal',
                      minHeight: 40,
                      alignSelf: 'center',
                      width: this.props.width < 500 ? '100%' : '300px',
                    }}
                    // size="small"
                    onClick={this.loadSample}
                  >
                    TRY SAMPLE RESUME & JOB
                  </Button>
                </Card>
              </Col>
              <Col
                style={{
                  ...(this.props.width < 500 && {
                    paddingLeft: 0,
                    paddingRight: 0,
                  }),
                }}
                sm={12}
                xs={12}
                md={6}
                lg={6}
                xl={6}
              >
                <Card style={{ padding: 10 }}>
                  <p style={{ fontSize: 14 }}>
                    Don’t like your resume? Build a new one now!
                  </p>

                  <Link to="/builder">
                    <Button
                      style={{
                        backgroundColor: 'rgb(66, 103, 178)',
                        color: 'white',
                        fonSize: 14,
                        fontStyle: 'normal',
                        minHeight: 40,
                        alignSelf: 'center',
                        width: this.props.width < 500 ? '100%' : '300px',
                        marginLeft: '-8px',
                      }}
                    >
                      Build Your Resume
                    </Button>
                  </Link>
                </Card>
              </Col>
            </Row>
          </div>
          <Row style={styles.row(this.props.width)}>
            <Colxx
              style={{
                ...(this.props.width < 500 && {
                  paddingLeft: 0,
                  paddingRight: 0,
                }),
              }}
              lg={{
                size: 6,
              }}
              md={{
                size: 6,
              }}
              sm="12"
              xs="12"
            >
              <Card style={styles.card} className="cardOptimizer">
                <CardBody>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexDirection: this.props.width < 990 ? 'column' : 'row',
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        ...styles.col,
                        ...styles.flex,
                        justifyContent: 'flex-start',
                        width: this.props.width < 990 ? '100%' : 'auto',
                        flexDirection:
                          this.props.width < 500 ? 'column' : 'row',
                      }}
                    >
                      <GetStepImage step={1} />
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1rem',
                          marginLeft: '10px',
                        }}
                      >
                        Job Description: Paste or Upload
                      </span>
                    </div>
                    <div
                      style={{
                        ...styles.col,
                        ...styles.flex,
                        ...styles.alignItemsCenter,
                        justifyContent: 'flex-end',
                        gap: '10px',
                        width: this.props.width < 990 ? '100%' : 'auto',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'end',
                          justifyContent: 'center',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '14px',
                          }}
                        >
                          {this.state.job_file
                            ? this.shrinkName(this.state.job_file.name)
                            : 'Upload Job'}
                        </span>
                        <span
                          style={{
                            fontSize: '12px',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {'(pdf, doc, docx, txt files)'}
                        </span>
                      </div>
                      <span
                        style={{
                          backgroundColor: 'rgb(66, 103, 178)',
                          textAlign: 'center',
                          padding: '0 17px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          document.getElementById('file-input-job').click();
                        }}
                      >
                        <label className="pt-1">
                          <FolderOpenFilled style={styles.folderOpen} />
                        </label>
                        <input
                          id="file-input-job"
                          style={{
                            display: 'none',
                            width: '100%',
                            height: '100%',
                          }}
                          type="file"
                          onChange={this.handleJobFile}
                        />
                      </span>
                    </div>
                  </div>
                  <Row>
                    <Col
                      lg="6"
                      style={{
                        ...styles.flex,
                        ...styles.flexColumn,
                      }}
                    >
                      <p
                        style={{
                          margin: '5px 0px 0px 0px',
                          color: '#000000',
                          textAlign: 'right',
                          fontSize: 12,
                        }}
                      >
                        Type exact name or pick from list if available.
                      </p>
                      <div style={{ ...styles.flex }}>
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <AutoCompleteCompany
                            value={this.state.tempCompany}
                            handleChange={this.handleChangeCompany}
                            handleSelect={this.handleSelectCompnay}
                          />
                        </div>

                        <span
                          style={{
                            ...styles.span4,
                            width: '70px',
                            height: '33px',
                          }}
                          onClick={() => {
                            this.setState({ company: this.state.tempCompany });
                          }}
                        >
                          {this.state.tempCompany === this.state.company &&
                          this.state.company ? (
                            <Check />
                          ) : (
                            'ADD'
                          )}
                        </span>
                      </div>
                    </Col>
                    <Col
                      lg="6"
                      style={{
                        ...styles.flex,
                        ...styles.flexColumn,
                      }}
                    >
                      <p
                        style={{
                          margin: '5px 0px 0px 0px',
                          color: '#000000',
                          textAlign: 'right',
                          fontSize: 12,
                        }}
                      >
                        Select exact job title from the list or enter & add.
                      </p>
                      <div
                        style={{ ...styles.alignItemsCenter, ...styles.flex }}
                      >
                        {/* <Input
                          className="input-placeholder-style"
                          value={this.state.tempJobTitle}
                          maxLength={20}
                          placeholder="Job Title"
                          style={{
                            ...styles.input,
                            width: '100%',
                          }}
                          onChange={(e) =>
                            this.setState({ tempJobTitle: e.target.value })
                          }
                          onBlur={() =>
                            this.setState({ jobTitle: this.state.tempJobTitle })
                          }
                        /> */}

                        <SolrAutoSuggestion
                          height="33px"
                          borderRadius={'1px'}
                          onChangeTitle={(title) => {
                            this.setState({ tempJobTitle: title });
                            this.props.setSelectedTitle(title);
                          }}
                          value={this.state.tempJobTitle}
                          onBlur={(title) => {
                            this.props.setSelectedTitle(title);
                            this.setState({
                              jobTitle: title ? title : this.state.tempJobTitle,
                            });
                          }}
                          place={'optimize'}
                        />
                        <span
                          style={{
                            ...styles.span4,
                            width: '70px',
                          }}
                          onClick={() => {
                            this.setState({
                              jobTitle: this.state.tempJobTitle,
                            });
                            this.props.setSelectedTitle(
                              this.state.tempJobTitle,
                            );
                          }}
                        >
                          {this.state.tempJobTitle === this.state.jobTitle &&
                          this.state.jobTitle ? (
                            <Check />
                          ) : (
                            'ADD'
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {this.state.jobdescription ? (
                <textarea
                  autoFocus={true}
                  style={styles.textArea}
                  placeholder="&#61604; PASTE JOB DESCRIPTION HERE"
                  value={this.state.jobdescription}
                  name="jobdescription"
                  onChange={this.handleInput}
                />
              ) : (
                <div
                  onClick={() => this.setState({ jobdescription: ' ' })}
                  style={{
                    ...styles.textArea,
                    backgroundColor: 'white',
                    cursor: 'text',
                  }}
                >
                  <span
                    style={{
                      padding: 5,
                      borderRadius: 5,
                      marginBottom: 5,
                    }}
                  >
                    <i className="fas fa-hand-point-right" />
                    <span
                      style={{ fontSize: 12, fontWeight: 'bold' }}
                      className="ml-1 font-smaller"
                    >
                      PASTE JOB DESCRIPTION HERE
                    </span>
                  </span>
                  <br />
                  <br />

                  <p
                    style={{
                      fontSize: 12,
                      fontWeight: '600',
                      color: 'rgba(0,0,0,0.5)',
                      marginTop: -15,
                      marginLeft: 20,
                    }}
                  >
                    (Include job title, responsibilities and qualifications.
                    Exclude company and benefits related info)
                  </p>
                </div>
              )}

              <div className="text-center" style={styles.pb_15}>
                <span
                  className="hov change_font"
                  style={{
                    ...styles.span5,
                    fontSize: 16,
                    border: '1px solid #A9A9A9',
                    borderRadius: 50,
                    padding: 5,
                  }}
                  onClick={() =>
                    this.setState({
                      jobdescription: '',
                      isChangedJob: false,
                    })
                  }
                >
                  Clear job description
                </span>
              </div>
            </Colxx>
            <Colxx
              style={{
                ...(this.props.width < 500 && {
                  paddingLeft: 0,
                  paddingRight: 0,
                }),
              }}
              lg={{
                size: 6,
              }}
              md={{
                size: 6,
              }}
              sm="12"
              xs="12"
            >
              <div>
                <Card style={styles.card} className="cardOptimizer">
                  <CardBody>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection:
                          this.props.width < 990 ? 'column' : 'row',
                        marginBottom: 20,
                      }}
                    >
                      <div
                        style={{
                          ...styles.col,
                          ...styles.flex,
                          justifyContent: 'flex-start',
                          width: this.props.width < 990 ? '100%' : 'auto',
                          flexDirection:
                            this.props.width < 500 ? 'column' : 'row',
                        }}
                      >
                        <GetStepImage step={2} />
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize:
                              this.props.width < 500 ? '0.7rem' : '1rem',
                            marginLeft: '10px',
                          }}
                        >
                          Resume: Upload, Select or Paste
                        </span>
                      </div>
                      <div
                        style={{
                          ...styles.col,
                          ...styles.flex,
                          ...styles.alignItemsCenter,
                          justifyContent: 'flex-end',
                          gap: '10px',
                          width: this.props.width < 990 ? '100%' : 'auto',
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'end',
                            justifyContent: 'center',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                            }}
                          >
                            {this.state.file
                              ? this.shrinkName(this.state.file.name)
                              : 'Upload Resume'}
                          </span>
                          <span
                            style={{
                              fontSize: '12px',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {'(pdf, doc, docx, txt files)'}
                          </span>
                        </div>
                        <span
                          style={{
                            backgroundColor: 'rgb(66, 103, 178)',
                            textAlign: 'center',
                            padding: '0 17px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            document.getElementById('file-input').click();
                          }}
                        >
                          <label className="pt-1">
                            <FolderOpenFilled style={styles.folderOpen} />
                          </label>
                          <input
                            id="file-input"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={this.handleFile}
                          />
                        </span>
                      </div>
                    </div>
                    <Row>
                      <Col
                        style={{
                          ...styles.col,
                          ...styles.flex,
                          alignItems:
                            this.props.width < 500 ? 'flex-end' : 'center',
                          width: this.props.width < 990 ? '100%' : 'auto',
                          marginBottom: 19,
                          flexDirection:
                            this.props.width < 500 ? 'column' : 'row',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            padding:
                              this.props.width < 500
                                ? '4.5px 0px'
                                : '4.5px 20px',
                            // border: '1px solid #d9d9d9',
                            // borderRight: 'none',
                          }}
                        >
                          Select from list
                        </span>
                        <Select
                          style={{
                            width: '100%',
                          }}
                          value={this.state.selcted_oid}
                          onChange={this.handleChange}
                        >
                          <Option value="none">{this.state.listState}</Option>
                          {this.props.userResumas?.map((item, i) => (
                            <Option key={i} value={item.oid}>
                              {item?.builderResume
                                ? `Builder_${item.resumeName}_${moment(
                                    item.createdAt,
                                  ).format('MMDDYY')}`?.replaceAll(' ', '')
                                : item.jobTitle
                                ? `${item.jobTitle}_${item.company}_${moment(
                                    item.createdAt,
                                  ).format('MMDDYY')}`?.replaceAll(' ', '')
                                : item.resumeName
                                ? `${item.resumeName}_${item.company}_${moment(
                                    item.createdAt,
                                  ).format('MMDDYY')}`?.replaceAll(' ', '')
                                : `no_job_title_${item.company}_${moment(
                                    item.createdAt,
                                  ).format('MMDDYY')}`?.replaceAll(' ', '')}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {this.state?.resume ? (
                  <textarea
                    autoFocus={true}
                    style={styles.textArea}
                    placeholder="&#61604; PASTE YOUR RESUME HERE OR UPLOAD FROM FILE (remove period)."
                    value={this.state?.resume}
                    name="resume"
                    onChange={this.handleInput}
                  />
                ) : (
                  <div
                    onClick={() => this.setState({ resume: ' ' })}
                    style={{
                      ...styles.textArea,
                      backgroundColor: 'white',
                      cursor: 'text',
                    }}
                  >
                    <span
                      style={{
                        // backgroundColor: "#ffe44d",
                        padding: 5,
                        borderRadius: 5,
                        marginBottom: 5,
                      }}
                    >
                      <i className="fas fa-hand-point-right" />
                      <span
                        style={{ fontSize: 12, fontWeight: 'bold' }}
                        className="ml-1 font-smaller"
                      >
                        PASTE YOUR RESUME HERE OR UPLOAD FROM FILE
                      </span>
                    </span>
                    <br />
                    <br />
                  </div>
                )}

                <div className="text-center" style={styles.pb_15}>
                  {' '}
                  <span
                    className="hov change_font"
                    style={{
                      ...styles.span5,
                      fontSize: 16,
                      border: '1px solid #A9A9A9',
                      borderRadius: 50,
                      padding: 5,
                    }}
                    onClick={() =>
                      this.setState({
                        resume: '',
                        isChangedResume: false,
                      })
                    }
                  >
                    Clear resume
                  </span>
                </div>
              </div>
            </Colxx>
          </Row>

          <div className="text-center">
            <h3 style={styles.pb_15}></h3>
            <Button
              variant="outlined"
              size="small"
              className="default mb-2 review-butn analyz-button"
              onClick={() => {
                if (this.props.user?.credit < 1) {
                  this.props.openUpgradeModel();
                } else {
                  this.ScanningHandler();
                }
              }}
              disabled={this.props.resumeAnalyzStatus ? true : false}
              style={styles.btnStart}
              data-tut="reactour__5"
            >
              {this.props.user?.credit < 1 && (
                <LockIcon
                  style={{
                    position: 'absolute ',
                    right: 5,
                    top: 5,
                    fontSize: 12,
                  }}
                />
              )}
              <GetStepImage
                step={3}
                style={{ marginTop: -30, marginLeft: -40 }}
              />
              {this.props.resumeAnalyzStatus ? (
                <BeatLoader
                  className="loader"
                  css={override}
                  sizeUnit={'px'}
                  size={10}
                  color={'#FFFFFF'}
                  loading={true}
                />
              ) : (
                'START REVIEW'
              )}
            </Button>
          </div>
        </div>
        <style jsx="true">{`
          .YNDSS,
          .frSZiY {
            display: none;
          }
        `}</style>
        {/* <Tour
          closeWithMask={false}
          onRequestClose={this.closeTour}
          steps={scanTourConfig}
          isOpen={this.state.isTourOpen}
          rounded={5}
          accentColor={"#5cb7b7"}
          // onAfterOpen={this.disableBody}
          // onBeforeClose={this.enableBody}
          lastStepNextButton={
            <Button onClick={this.finishTour}>Let's Start</Button>
          }
        /> */}
      </>
    );
  }
}

function getSteps() {
  return [
    { name: 'Start', id: 0 },
    { name: `Review ${'\n'} Results`, id: 1 },
    { name: `Fine-Tune`, id: 2 },
    { name: 'Customize', id: 3 },
    { name: `Save & Apply`, id: 4 },
  ];
}

function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isAllStepsActive, setAllStepsActive] = React.useState(false);
  const [zipUrl, setZipUrl] = React.useState('');
  const [showHelpDialog, setShowHelpDialog] = React.useState(false);
  const [showHelpVideoModal, setShowHelpVideoModal] = React.useState(false);
  const steps = getSteps();
  const [width] = useWindowSize();
  const [load, setLoad] = React.useState(true);

  const handleMoveToCustomize = async () => {
    let newActive = 3;
    setActiveStep(newActive);
    props.SaveActiveIndex(newActive);
    if (localStorage.getItem('helpPopupVideo') !== 'true') {
      setShowHelpVideoModal(true);
      localStorage.setItem('helpPopupVideo', 'true');
      await axios.get(`${process.env.REACT_APP_API_URL}/help-popup-hide`);
    }
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Scan
            getSuggestions={props.getSuggestions}
            selectedTitle={props.selectedTitle}
            textFileParser={props.textFileParser}
            setSelectedTitle={props.setSelectedTitle}
            pushprops={props.history}
            fileParser={props.fileParser}
            clearScanResult={props.clearScanResult}
            getResumaId={props.getResumaId}
            scanResults={props.scanResults}
            currentLink={props.currentLink}
            zipRecruiterAction={props.zipRecruiterAction}
            resumeDescription={props.resumeDescription}
            scanResultStatus={props.scanResultStatus}
            scanResult={props.scanResult}
            parsingError={props.parsingError}
            userResumas={props.userResumas}
            updator={props.updator}
            isStatusTrue={props.isStatusTrue}
            recruiterData={props.recruiterData}
            clocation={props.location.pathname}
            activeStep={setActiveStep}
            setZipUrl={setZipUrl}
            resumeAnalyzStatus={props.resumeAnalyzStatus}
            resumeStats={props.resumeStats}
            setTourActivity={props.setTourActivity}
            showTourSection={props.showTourSection}
            getLatestScanResult={props.getLatestScanResult}
            tourActivity={props.tourActivity}
            setShowTourSection={props.setShowTourSection}
            user={props.user}
            defaultFiles={props.defaultFiles}
            width={width}
            VideoLinks={props.VideoLinks}
            openUpgradeModel={props.openUpgradeModel}
            currentUser={props.currentUser}
          />
        );
      case 1:
        return (
          <ScanResult
            width={width}
            history={props.history}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            getSuggestions={props.getSuggestions}
            suggestions={props.suggestions}
            handleMoveToCustomize={handleMoveToCustomize}
            SaveActiveIndex={props.SaveActiveIndex}
          />
        );
      case 3:
        return (
          <UpdateEditEditor
            history={props.history}
            getSuggestions={props.getSuggestions}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            SaveActiveIndex={props.SaveActiveIndex}
            fineTuneSkills={props.suggestions?.['generalSkills'] || []}
          />
        );
      case 2:
        return <FineTuning suggestions={props.suggestions} step={stepIndex} />;
      case 4:
        return (
          <SaveAndApply
            activeStep={activeStep}
            zipUrl={zipUrl}
            setActiveStep={setActiveStep}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  }
  const handleNext = async () => {
    if (localStorage.getItem('helpPopupVideo') !== 'true' && activeStep === 3) {
      setShowHelpVideoModal(true);
      localStorage.setItem('helpPopupVideo', 'true');
      await axios.get(`${process.env.REACT_APP_API_URL}/help-popup-hide`);
    } else {
      let newActive = activeStep + 1;
      setActiveStep((prevActiveStep) => {
        return prevActiveStep++;
      });
      props.SaveActiveIndex(newActive);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    props.SaveActiveIndex(activeStep - 1);
  };

  const handleReset = () => {
    if (props.scanReloadStatus) {
      return;
    }
    localStorage.removeItem('myScanId');
    localStorage.removeItem('selectedTemp');
    props.SaveActiveIndex(0);
    props.clearLatestScanResult();
    setActiveStep(0);
  };
  React.useEffect(() => {
    if (activeStep === 4) {
      setTimeout(() => {
        setLoad(false);
      }, 2000);
      setLoad(true);
    }
    if (props?.savedEditorJson) {
      props.updateEditorJson(props?.savedEditorJson);
    }
  }, [activeStep]);
  React.useEffect(() => {
    if (props.activeIndexFromReducer) {
      setActiveStep(props.activeIndexFromReducer);
    }
  }, [props.activeIndexFromReducer]);

  React.useEffect(() => {
    if (localStorage.getItem('myScanId')) {
      setAllStepsActive(true);
    }
  }, [localStorage.getItem('myScanId')]);

  React.useEffect(() => {
    if (Object.keys(props.user || {})?.length) {
      props.getResumaResults('', null, 'scan');
    }
  }, [props.user]);

  React.useEffect(() => {
    props.getDefaultFiles();
    // don't remove comma (,)
    if (props.location.state && props.location.state.joburl) {
      props.zipRecruiterActionFromJobs(props.location.state.joburl);
    }
    if (
      props.location.state &&
      props.location.state.cameFromJobMatcher === 'yes'
    ) {
      localStorage.removeItem('myScanId');
      setAllStepsActive(false);
      setActiveStep(0);
    }
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      setShowHelpDialog(false);
    }, 10000);
  }, [showHelpDialog]);

  //
  React.useEffect(() => {
    if (props.scanResult === undefined) {
      localStorage.removeItem('myScanId');
      setAllStepsActive(false);
      setActiveStep(0);
    } else if (props.scanResult && localStorage.getItem('myScanId')) {
      props.currentUser();
      setAllStepsActive(true);
    }
  }, [props.scanResult]);

  if (props.stepperLoader) {
    return (
      <>
        <RecentScanCard />
        <GraphqLoader />
        <RecentScanCard2 />
        <RecentScanCard3 />
      </>
    );
  }

  return (
    <>
      <VideoModel
        open={showHelpVideoModal}
        setOpen={(open) => setShowHelpVideoModal(open)}
        video={props.VideoLinks?.HelpPopup}
        closeAble={false}
      />
      <div className={classes.root}>
        <center>
          <div style={styles.stepperContainer}>
            {activeStep === 0 || activeStep === 1
              ? ''
              : width > 768 && (
                  <Button
                    style={{
                      backgroundColor: colors.blue,
                      color: 'white',
                      width: '80px',
                      height: '40px',
                      marginLeft: '10px',
                      display: 'flex',
                      alignSelf: 'center',
                    }}
                    onClick={handleBack}
                  >
                    Previous
                  </Button>
                )}

            <div
              style={{
                width: width > 768 ? '80%' : '100%',
                margin: 'auto',
                overflow: 'auto',
              }}
            >
              <Stepper
                connector={<ColorlibConnector />}
                activeStep={activeStep}
              >
                {steps?.map((label) =>
                  isAllStepsActive ? (
                    <Step key={label.name}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        <StepButton
                          style={{
                            overflow: 'hidden',
                            width: '100%',
                            whiteSpace: 'nowrap',
                          }}
                          onClick={() => {
                            if (
                              !localStorage.getItem('myScanId') ||
                              label.id === 0
                            ) {
                              return;
                            } else {
                              setActiveStep(label.id);
                              props.SaveActiveIndex(label.id);
                            }
                          }}
                        >
                          <b
                            style={{
                              color: colors.fontGray,
                            }}
                          >
                            {label.name}
                          </b>
                        </StepButton>
                      </StepLabel>
                    </Step>
                  ) : (
                    <Step key={label.name}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        <StepButton>
                          <b
                            style={{
                              color: colors.fontGray,
                            }}
                          >
                            {label.name}
                          </b>
                        </StepButton>
                      </StepLabel>
                    </Step>
                  ),
                )}
                {activeStep === 0 ? null : (
                  <StepLabel
                    style={{
                      marginLeft: '2rem',
                    }}
                    StepIconComponent={() => (
                      <div
                        style={{
                          backgroundColor: colors.green,
                          width: 30,
                          height: 30,
                          display: 'flex',
                          borderRadius: '50%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={handleReset}
                      >
                        <LoopIcon style={{ fontSize: 20, color: 'white' }} />
                      </div>
                    )}
                  >
                    <StepButton
                      onClick={handleReset}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <b
                        style={{
                          color: colors.fontGray,
                        }}
                      >
                        Start New
                      </b>
                    </StepButton>
                  </StepLabel>
                )}
              </Stepper>
            </div>

            {activeStep === 0 ? null : width > 768 &&
              activeStep === steps.length - 1 ? (
              load ? (
                <Button
                  style={{
                    backgroundColor: colors.blue,
                    color: 'white',
                    width: '80px',
                    height: '40px',
                    marginRight: '10px',
                    display: 'flex',
                    alignSelf: 'center',
                  }}
                  // onClick={handleReset}
                >
                  <Loader
                    type="TailSpin"
                    color={colors.white}
                    height={20}
                    width={20}
                  />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: colors.blue,
                    color: 'white',
                    width: '80px',
                    height: '40px',
                    marginRight: '10px',
                    display: 'flex',
                    alignSelf: 'center',
                  }}
                  onClick={handleReset}
                >
                  Finish
                </Button>
              )
            ) : (
              width > 768 && (
                <Button
                  style={{
                    backgroundColor: colors.blue,
                    color: 'white',
                    width: '80px',
                    height: '40px',
                    marginRight: '10px',
                    display: 'flex',
                    alignSelf: 'center',
                  }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              )
            )}
          </div>

          <div
            style={{
              width: width < 500 ? '100%' : '85%',
              display: 'flex',
              justifyContent: activeStep === 1 ? 'flex-end' : 'space-between',
              padding: '10px 0px',
            }}
          >
            {/* Mobile Previous Button */}
            {activeStep === 0 || activeStep === 1
              ? ''
              : width < 768 && (
                  <Button
                    style={{
                      backgroundColor: colors.blue,
                      color: 'white',
                    }}
                    size="small"
                    onClick={handleBack}
                  >
                    Previous
                  </Button>
                )}
            {/* Mobile Next / Finish Buttons */}
            {activeStep === 0 ? null : width < 768 &&
              activeStep === steps.length - 1 ? (
              load ? (
                <Button
                  size="small"
                  style={{
                    backgroundColor: colors.blue,
                    color: 'white',
                    fonSize: 13,
                    width: '80px',
                    height: '40px',
                  }}
                  // onClick={handleReset}
                >
                  <Loader
                    type="TailSpin"
                    color={colors.white}
                    height={20}
                    width={20}
                  />
                </Button>
              ) : (
                <Button
                  size="small"
                  style={{
                    backgroundColor: colors.blue,
                    color: 'white',
                    fonSize: 13,
                    width: '80px',
                    height: '40px',
                  }}
                  onClick={handleReset}
                >
                  Finish
                </Button>
              )
            ) : (
              width < 768 && (
                <Button
                  size="small"
                  style={{
                    backgroundColor: colors.blue,
                    color: 'white',
                    fonSize: 13,
                    width: '80px',
                    height: '40px',
                  }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              )
            )}
          </div>
        </center>

        <div style={{ width: '100%' }}>
          <div
            style={{
              paddingBottom: 15,
              margin: activeStep === 4 && width < 500 ? 0 : 10,
            }}
          >
            {activeStep === steps.length + 1 ? (
              <div style={styles.mb_120}>
                <div className={classes.instructions}>
                  <Card>
                    <CardBody style={styles.activeStep_cardBody}>
                      <CardTitle>You Are Done With This!</CardTitle>
                      <Button
                        size="small"
                        style={styles.activeStep_btn}
                        onClick={handleReset}
                      >
                        APPLY FOR ANOTHER JOB
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              </div>
            ) : (
              <div>
                <div className={classes.instructions}>
                  {getStepContent(activeStep)}
                </div>
                <div style={styles.activeStep_div(activeStep)}>
                  {activeStep === 0 ? null : (
                    <>
                      {activeStep === 1 ? null : (
                        <Button
                          style={{
                            backgroundColor: colors.blue,
                            color: 'white',
                            width: '80px',
                            height: '40px',
                            marginLeft: '10px',
                            display: 'flex',
                            alignSelf: 'center',
                          }}
                          onClick={handleBack}
                        >
                          Previous
                        </Button>
                      )}
                      {activeStep === steps.length - 1 ? (
                        load ? (
                          <Button
                            style={{
                              backgroundColor: colors.blue,
                              color: 'white',
                              width: '80px',
                              height: '40px',
                              marginRight: '10px',
                              display: 'flex',
                              alignSelf: 'center',
                            }}
                            //  onClick={handleReset}
                          >
                            <Loader
                              type="TailSpin"
                              color={colors.white}
                              height={20}
                              width={20}
                            />
                          </Button>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: colors.blue,
                              color: 'white',
                              width: '80px',
                              height: '40px',
                              marginRight: '10px',
                              display: 'flex',
                              alignSelf: 'center',
                            }}
                            onClick={handleReset}
                          >
                            Finish
                          </Button>
                        )
                      ) : activeStep === 2 ||
                        activeStep === 3 ||
                        activeStep === 4 ? (
                        <Button
                          size="small"
                          style={styles.activeStep_btn1}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            size="small"
                            style={styles.activeStep_btn1}
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={showHelpDialog}
        onClose={() => setShowHelpDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent
          style={{
            width: 420,
          }}
        >
          <p
            style={{
              color: colors.black,
            }}
          >
            Before You go to next step, add missing keywords in the appropriate
            context to the "Experience" section(s) using editor below.
          </p>
        </DialogContent>
        <div
          style={{
            display: 'flex',

            justifyContent: 'space-between',
          }}
        >
          <img
            src={Arrow}
            style={{
              width: 50,
              marginLeft: 10,
            }}
          />
          <Button onClick={() => setShowHelpDialog(false)}>Ok</Button>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = ({ fileScanReducer, authUser }) => {
  return {
    user: authUser.user,
    showTourSection: fileScanReducer.showTourSection,
    tourActivity: fileScanReducer.tourActivity,
    resumeDescription: fileScanReducer.parsefile,
    scanResultStatus: fileScanReducer.scanResultStatus,
    scanResult: fileScanReducer.scanResult,
    parsingError: fileScanReducer.parsingError,
    userResumas: fileScanReducer.userResumas,
    updator: fileScanReducer.updator,
    isStatusTrue: fileScanReducer.zipRecruiterRequest,
    recruiterData: fileScanReducer.zipRecruiterDescription,
    activeIndexFromReducer: fileScanReducer.activeIndex,
    data: fileScanReducer.historyData,
    resumeAnalyzStatus: fileScanReducer.analyzStatus,
    stepperLoader: fileScanReducer.stepperLoader,
    defaultFiles: fileScanReducer.defaultFiles,
    VideoLinks: fileScanReducer.videoLinks,
    suggestions: fileScanReducer.suggestions,
    selectedTitle: fileScanReducer.selectedTitle,
    scanReloadStatus: fileScanReducer.scanReloadStatus,
    savedEditorJson: fileScanReducer?.resume?.savedEditorJson,
  };
};
// export default
export default connect(mapStateToProps, {
  fileParser,
  textFileParser: parseTextFile,
  scanResults,
  clearScanResult,
  currentLink,
  getResumaResults,
  getResumaId,
  zipRecruiterAction,
  SaveActiveIndex,
  scanHistory,
  zipRecruiterActionFromJobs,
  resumeStats,
  getLatestScanResult,
  clearLatestScanResult,
  currentUser,
  setTourActivity,
  setShowTourSection: showTourSection,
  getDefaultFiles,
  openUpgradeModel,
  getSuggestions,
  setSelectedTitle,
  updateEditorJson,
})(HorizontalLabelPositionBelowStepper);
