import React from 'react';
import StepsContainer from '../container';
import {
  Profile,
  WorkHistory,
  Education,
  Skills,
  Summary,
  Certifications,
  Courses,
  Publications,
  Speakings,
  Patents,
  Military,
  SecurityCredentials,
  Trainings,
  Achievements,
  Associations,
  Projects,
  Custom,
} from '../views';
import { sampleData } from '../../redux/sample';
import NewAdditionalSkills from '../views/newAdditionalSkills';

export function GetStepContent(step, classes, resumeData, updater) {
  switch (step) {
    case 0:
      return (
        <StepsContainer classes={classes} step={step}>
          <Profile />
        </StepsContainer>
      );
    case 1:
      return (
        <StepsContainer
          classes={classes}
          step={step}
          data={
            resumeData?.work?.items.length
              ? [...resumeData?.work?.items]
              : [...sampleData?.work?.items]
          }
        >
          <WorkHistory
            data={
              resumeData?.work?.items.length
                ? [...resumeData?.work?.items]
                : [...sampleData?.work?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 2:
      return (
        <StepsContainer classes={classes} step={step}>
          <Education
            data={
              resumeData?.education?.items.length
                ? [...resumeData?.education?.items]
                : [...sampleData?.education?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 5:
      return (
        <StepsContainer
          classes={classes}
          step={step}
          data={
            resumeData?.certifications?.items.length
              ? [...resumeData?.certifications?.items]
              : [...sampleData?.certifications?.items]
          }
        >
          <Certifications
            data={
              resumeData?.certifications?.items.length
                ? [...resumeData?.certifications?.items]
                : [...sampleData?.certifications?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 3:
      return (
        <StepsContainer
          classes={classes}
          step={step}
          data={
            resumeData?.skills?.items.length
              ? [...resumeData?.skills?.items]
              : [...sampleData?.skills?.items]
          }
        >
          <Skills
            data={
              resumeData?.skills?.items.length
                ? [...resumeData?.skills?.items]
                : [...sampleData?.skills?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 4:
      return (
        <StepsContainer
          classes={classes}
          step={step}
          data={
            resumeData.objective.body
              ? [...resumeData?.objective?.body]
              : [...sampleData?.objective?.body]
          }
        >
          <Summary
            data={
              resumeData.objective.body
                ? [...resumeData?.objective?.body]
                : [...sampleData?.objective?.body]
            }
          />
        </StepsContainer>
      );
    case 6:
      return (
        <StepsContainer classes={classes} step={step}>
          <Courses
            data={
              resumeData?.courses?.items.length
                ? [...resumeData?.courses?.items]
                : [...sampleData?.courses?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 7:
      return (
        <StepsContainer
          classes={classes}
          step={step}
          data={
            resumeData?.associations?.items.length
              ? [...resumeData?.associations?.items]
              : [...sampleData?.associations?.items]
          }
        >
          <Associations
            data={
              resumeData?.associations?.items.length
                ? [...resumeData?.associations?.items]
                : [...sampleData?.associations?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 8:
      return (
        <StepsContainer classes={classes} step={step}>
          <Trainings
            data={
              resumeData?.training?.items.length
                ? [...resumeData?.training?.items]
                : [...sampleData?.training?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 9:
      return (
        <StepsContainer classes={classes} step={step}>
          <Achievements
            data={
              resumeData?.achievements?.items.length
                ? [...resumeData?.achievements?.items]
                : [...sampleData?.achievements?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 10:
      return (
        <StepsContainer classes={classes} step={step}>
          <Projects
            data={
              resumeData?.projects?.items.length
                ? [...resumeData?.projects?.items]
                : [...sampleData?.projects?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 11:
      return (
        <StepsContainer classes={classes} step={step}>
          <Patents
            data={
              resumeData?.patents?.items?.length
                ? resumeData?.patents
                : [...sampleData?.patents?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 12:
      return (
        <StepsContainer classes={classes} step={step}>
          <Publications
            data={
              resumeData?.publications?.items.length
                ? [...resumeData?.publications?.items]
                : [...sampleData?.publications?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 13:
      return (
        <StepsContainer classes={classes} step={step}>
          <Speakings
            data={
              resumeData?.speaking_engagements?.items.length
                ? [...resumeData?.speaking_engagements?.items]
                : [...sampleData?.speaking_engagements?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 14:
      return (
        <StepsContainer classes={classes} step={step}>
          <SecurityCredentials
            data={
              resumeData?.security_creds?.items.length
                ? [...resumeData?.security_creds?.items]
                : [...sampleData?.security_creds?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 15:
      return (
        <StepsContainer classes={classes} step={step}>
          <Military
            data={
              resumeData?.military?.items.length
                ? [...resumeData?.military?.items]
                : [...sampleData?.military?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );
    case 16:
      return (
        <StepsContainer classes={classes} step={step}>
          <Custom />
        </StepsContainer>
      );
    case 17:
      return (
        <StepsContainer
          classes={classes}
          step={step}
          data={
            resumeData?.additional_skills?.items.length
              ? [...resumeData?.additional_skills?.items]
              : [...sampleData?.additional_skills?.items]
          }
        >
          <NewAdditionalSkills
            data={
              resumeData?.additional_skills?.items.length
                ? [...resumeData?.additional_skills?.items]
                : [...sampleData?.additional_skills?.items]
            }
            updater={updater}
          />
        </StepsContainer>
      );

    default:
      return 'Unknown step';
  }
}
