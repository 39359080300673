import React, { useEffect } from 'react';
import TopBar from '../layout/authLayout/Topbar';
import TopNav from '../layout/appLayout/topnav';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PublicFooter from '../layout/authLayout/Footer';
import PrivateFooter from '../layout/appLayout/footer';

function Faq(props) {
  const comingFrom = props.history?.location?.state?.comingFrom || '';
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {comingFrom === 'TopNav' ? <TopNav /> : <TopBar />}
      <Card className="privacy-page-main mt-4" style={{ height: 'auto' }}>
        <center>
          <div className="section-title  mt-4">
            <h4
              style={{ fontFamily: 'Montserrat', marginTop: 20 }}
              className="main-title mb-4 mt-4 "
            >
              Frequently Asked Questions
            </h4>
          </div>
        </center>
        <CardBody>
          <Row style={{ padding: 20 }}>
            <Col lg={6} md={12} sm={12} xs={12}>
              <div
                className="features p-1 pt-4 pb-4 "
                style={{ minHeight: 200 }}
              >
                <i className="mdi mdi-help icon text-center rounded-pill text-primary mr-3 float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">
                    How do you differentiate from others?
                  </h4>
                  <p className="faq-para para mb-0">
                    We built this platform for Job Seeker's with a sole purpose
                    of landing job interviews and job offers. Our platform
                    provides all necessary tools to achieve your goal, including
                    resume optimization, Linkedin profile update and targeted
                    job listing for you. We also provide easy to use mobile apps
                    for Apple IOS and Android. Our platform is simple to use and
                    comes with a number of useful tools!
                  </p>
                </div>
              </div>
              <div
                className="features p-1 pt-4 pb-4"
                style={{ minHeight: 180 }}
              >
                <i className="mdi mdi-help  icon text-center rounded-pill text-primary mr-3  float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">
                    Is there a free trial of resumas.com?
                  </h4>
                  <p className="faq-para para mb-0">
                    Yes! Simply sign up with your email address, no credit card
                    required. You'll receive a reminder email a few days before
                    your 14-day trial ends. If you wish to continue using
                    Resumás after your trial, enter your credit card info to
                    UPGRADE to the full PRO version to access all of our unique
                    features or continue with the free version.
                  </p>
                </div>
              </div>
              <div
                className="features p-1 pt-4 pb-4"
                style={{ minHeight: 180 }}
              >
                <i className="mdi mdi-help  icon text-center rounded-pill text-primary mr-3  float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">What is resume optimization?</h4>
                  <p className="faq-para para mb-0">
                    Resume optimization is the process of reviewing your skills
                    against the job description and highlighting the required
                    skills in your customized resume. Your chances of an
                    interview depend on your resume making it through Applicant
                    Tracking Systems (ATS). Our platform helps you through the
                    process.
                  </p>
                </div>
              </div>
              <div
                className="features p-1 pt-4 pb-4"
                style={{ minHeight: 180 }}
              >
                <i className="mdi mdi-help  icon text-center rounded-pill text-primary mr-3  float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">What is a Review credit?</h4>
                  <p className="faq-para para mb-0">
                    A Review credit allows you to review your resume against a
                    job description and highlight all the necessary keywords
                    missing from your resume. The 14-day trial account comes
                    with five (5) review credits. A subscription to one of our
                    PRO plans will allow you unlimited review credits.
                  </p>
                </div>
              </div>
              <div
                className="features p-1 pt-4 pb-4"
                style={{ minHeight: 180 }}
              >
                <i className="mdi mdi-help  icon text-center rounded-pill text-primary mr-3  float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">Is resumas.com secure?</h4>
                  <p className="faq-para para mb-0">
                    The security of our customers’ data is our top priority. We
                    do not share your information or resume with anyone. We do
                    not store your credit card information. We use industry
                    standard data encryption to protect information.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} xs={12}>
              <div
                className="features p-1 pt-4 pb-4"
                style={{ minHeight: 180 }}
              >
                <i className="mdi mdi-help  icon text-center rounded-pill text-primary mr-3  float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">Can I cancel any time?</h4>
                  <p className="faq-para para mb-0">
                    Yes! You can cancel at any time. When you cancel, you will
                    still have access to paid features until the end of your
                    subscription period as according to your plan, either the
                    PRO-1 monthly plan or PRO-3 three month plan. When your
                    subscription expires, you will lose access to paid features
                    and will be downgraded to the free version. You can cancel
                    your subscription at any time directly from your account by
                    selecting Account {'>'} downgrade.
                  </p>
                </div>
              </div>
              <div
                className="features p-1 pt-4 pb-4"
                style={{ minHeight: 180 }}
              >
                <i className="mdi mdi-help  icon text-center rounded-pill text-primary mr-3  float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">
                    Can I use Resumás on mobile devices?
                  </h4>
                  <p className="faq-para para mb-0">
                    Yes, Resumás is available natively on both IOS and Android
                    devices. You can download the app on the Apple or Google
                    Play Stores. You can use our application on a desktop or
                    laptop browser, Apple iPad, Android Tablet and any IOS or
                    Android phone on the go. You can also switch between desktop
                    and mobile for your personal use.
                  </p>
                </div>
              </div>
              <div
                className="features p-1 pt-4 pb-4"
                style={{ minHeight: 180 }}
              >
                <i className="mdi mdi-help  icon text-center rounded-pill text-primary mr-3  float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">Are there any hidden fees?</h4>
                  <p className="faq-para para mb-0">
                    No, there are no hidden fees: no set-up fees, no
                    cancellation fees, no support fees - nada.
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="features p-1 pt-4 pb-4"
                style={{ minHeight: 180 }}
              >
                <i className="mdi mdi-help  icon text-center rounded-pill text-primary mr-3  float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">Can I create a new resume?</h4>
                  <p className="faq-para para mb-0">
                    Yes! You can build your resume from scratch if you do not
                    already have one on file. Our resume builder will help you
                    to create the ideal resume for Applicant Tracking Systems
                    (ATS). Build the perfect resume in minutes, then view, save,
                    and export your completed document.
                  </p>
                </div>
              </div>
              <div
                className="features p-1 pt-4 pb-4"
                style={{ minHeight: 180 }}
              >
                <i className="mdi mdi-help  icon text-center rounded-pill text-primary mr-3  float-left"></i>

                <div className="content d-block overflow-hidden">
                  <h4 className="title">
                    What is optimization of a Linkedin profile?
                  </h4>
                  <p className="faq-para para mb-0">
                    Both the Pro-1 and Pro-3 Resumas plans provide access to our
                    LinkedIn profile optimization feature. Recruiters can find
                    you on linkedIn<sup style={{ fontSize: 12 }}>® </sup>with
                    ease thanks to your customized profile! We optimized
                    information for your LinkedIn
                    <sup style={{ fontSize: 12 }}>® </sup>profile based on the
                    job description and resume you submitted; this will help you
                    appear in recruiters' searches on LinkedIn.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        {comingFrom === 'TopNav' ? null : <PublicFooter />}
      </Card>
      {comingFrom === 'TopNav' ? <PrivateFooter /> : null}
    </>
  );
}

export default Faq;
