import React from 'react';
import {
  Card,
  CardBody,
  Row,
  ModalHeader,
  Modal,
  ModalBody,
  Button,
} from 'reactstrap';
import { Colxx } from '../../commonComponents/customBootstrap';
import {
  scanResultsReload,
  EmailParserAction,
  updateQuestionsA,
  sendTextToEmailOrMobile,
} from '../../commonRedux/scan/action';
import { connect } from 'react-redux';
import moment from 'moment';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Select, Table, Descriptions } from 'antd';
import {
  getCoverLetters,
  getPdf,
  setDefault,
} from '../../commonRedux/template/action';
import { saveToken, backupYouResumeAction } from '../auth/redux/actions';
import ShowCover from '../scan/show-cover-letter';
import toastr from 'toastr';
import Loader from 'react-loader-spinner';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EmailIcon from '@material-ui/icons/Email';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { styles } from './style';
import { colors } from '../../commonComponents/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import { DialogContent } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const { Option } = Select;

const FinalResult = (props) => {
  const { state } = props.history.location;
  const [scanResult, setScanResult] = React.useState({});
  const [selectedTemplate, setselectedTemplate] = React.useState('');
  const [resumename, setResumeName] = React.useState('');
  const [coverLetter, setCoverLetter] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [oidd, setOidd] = React.useState('');
  const [modalLarge, setmodalLarge] = React.useState(false);
  const [modalLarge2, setmodalLarge2] = React.useState(false);
  const [mobileLoader, setMobileLoader] = React.useState(false);
  const [emailLoader, setEmailLoader] = React.useState(false);
  const [docs, setDocs] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [checkForApply, setCheckForApply] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (open, message) => {
    setMessage(message);
    setOpen(open);
  };

  //table data
  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index === 4) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  //table columns
  const resumeColumns = [
    {
      title: 'Resume Name',
      dataIndex: 'name',
      render: (text, row, index) => {
        if (index < 4) {
          return <a>{text}</a>;
        }
        return {
          children: <a>{text}</a>,
          props: {
            colSpan: 5,
          },
        };
      },
    },
    {
      title: 'Review Score',
      dataIndex: 'score',
      render: renderContent,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: renderContent,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: renderContent,
    },
    {
      title: 'Template you choosed',
      dataIndex: 'template',
    },
    {
      title: 'View Description',
      dataIndex: 'descView',
    },
  ];

  const jobDetailColumns = [
    {
      title: 'View Description',
      dataIndex: 'jobDescView',
    },
    {
      title: 'Actions',
      dataIndex: 'jobActions',
    },
  ];

  const coverTableColumns = [
    {
      title: 'Cover Letters',
      dataIndex: 'coverLetter',
    },
    {
      title: 'Actions',
      dataIndex: 'coverLetterActions',
    },
  ];

  const zipUrlColumn = [
    {
      title: 'ZipRecruiter Url',
      dataIndex: 'zipfUrl',
    },
  ];

  const actionColumns = [
    {
      title: 'Copy Resume',
      dataIndex: 'copyResume',
      render: renderContent,
    },
    {
      title: 'Print through Fedex',
      dataIndex: 'fedex',
      render: renderContent,
    },
    {
      title: 'Download',
      dataIndex: 'download',
      render: renderContent,
    },
    {
      title: 'Text Details to Mobile',
      dataIndex: 'toMobile',
    },
    {
      title: 'Text Details to Email',
      dataIndex: 'toEmail',
    },
    {
      title: 'pdf URL',
      dataIndex: 'pdfurl',
    },
    {
      title: 'docs URL',
      dataIndex: 'docsurl',
    },
  ];

  //table data
  const jobDetailData = [
    {
      jobDescView: (
        <span onClick={() => toggleLarge2()} style={styles.jobDescView}>
          Click to view
        </span>
      ),
      jobActions: (
        <CopyToClipboard
          text={scanResult.jobDescription}
          onCopy={() => toastr.success('job description copied!')}
        >
          <FileCopyIcon className="hovdel" style={styles.jobActions} />
        </CopyToClipboard>
      ),
    },
  ];

  const coverLetterData = [
    {
      key: '1',
      coverLetter: (
        <Select
          value={coverLetter}
          onChange={(val) => handleSelect(val)}
          style={styles.width_100_percent}
        >
          {props.coverLetters.length ? (
            props.coverLetters.map((i, index) => {
              return (
                <Option key={index} value={i._id}>
                  {i.title}
                </Option>
              );
            })
          ) : (
            <Option value="">none</Option>
          )}
        </Select>
      ),
      coverLetterActions: props.coverLetters.length ? (
        <Descriptions.Item label="Cover Letters Actions">
          <span style={styles.flex}>
            <ShowCover
              detail={props.coverLetters}
              selected={coverLetter && coverLetter}
            />
            <CopyToClipboard
              text={title + ' ' + ' ' + ' ' + ' ' + ' ' + description}
              onCopy={() => toastr.success('Cover Letter copied!')}
            >
              <FileCopyIcon className="hovdel" style={styles.fileCopyIcon} />
            </CopyToClipboard>
          </span>
        </Descriptions.Item>
      ) : (
        'you do not have cover letter!'
      ),
    },
  ];

  const zipUrlData = [
    {
      key: '1',
      zipfUrl: (
        <>
          <span onClick={() => goToZip()} style={styles.url}>
            {props.zipUrl && props.zipUrl.slice(55)}
            {pdfUrl.slice(69)}
          </span>
          <CopyToClipboard
            text={props.zipUrl && props.zipUrl}
            onCopy={() => toastr.success('Link Copied')}
          >
            <FileCopyIcon className="hovdel" style={styles.fileCopyIcon} />
          </CopyToClipboard>
        </>
      ),
    },
  ];

  const actionData = [
    {
      key: '1',
      copyResume: (
        <CopyToClipboard
          text={scanResult.resumeText}
          onCopy={() => toastr.success('resume copied!')}
        >
          <FileCopyIcon className="hovdel" style={styles.cursorPointer} />
        </CopyToClipboard>
      ),
      fedex: props.fedexStatus ? (
        <Loader type="TailSpin" color="#00BFFF" height={15} width={15} />
      ) : (
        <PrintIcon
          onClick={() => {
            let data = {
              is_scan: 1,
              oid: scanResult.oid,
              temp_id: selectedTemplate,
            };
            props.EmailParserAction(data, handleClickOpen);
          }}
          className="icon2"
          style={styles.icon}
        />
      ),
      download: loader ? (
        <Loader type="TailSpin" color="#00BFFF" height={15} width={15} />
      ) : (
        <GetAppIcon
          style={styles.getAppIcon}
          className="icon3"
          onClick={() => generatePdf(scanResult.oid)}
        />
      ),
      toMobile: mobileLoader ? (
        <Loader type="TailSpin" color="#00BFFF" height={15} width={15} />
      ) : (
        <PhoneAndroidIcon
          onClick={() => sendTextTo('mobile')}
          className="hovdel"
          style={{ ...styles.cursorPointer, ...styles.fontSize1_1_rem }}
        />
      ),
      toEmail: emailLoader ? (
        <Loader
          style={styles.mt_5}
          type="TailSpin"
          color="#00BFFF"
          height={15}
          width={15}
        />
      ) : (
        <EmailIcon
          onClick={() => sendTextTo('email')}
          className="hovdel"
          style={styles.icon}
        />
      ),
      pdfurl: (
        <>
          {pdfUrl === '' ? null : (
            <CopyToClipboard
              text={pdfUrl}
              onCopy={() => toastr.success('Link Copied')}
            >
              <FileCopyIcon className="hovdel" style={styles.fileCopyIcon} />
            </CopyToClipboard>
          )}
        </>
      ),
      docsurl: (
        <>
          {docs === '' ? null : (
            <CopyToClipboard
              text={docs}
              onCopy={() => toastr.success('Link Copied')}
            >
              <FileCopyIcon className="hovdel" style={styles.fileCopyIcon} />
            </CopyToClipboard>
          )}
        </>
      ),
    },
  ];

  const resumeData = [
    {
      key: scanResult.oid,
      name: resumename,
      score: scanResult.data && scanResult.data.total_score,
      createdAt: moment(scanResult.createdAt).startOf('day').fromNow(),
      updatedAt:
        scanResult.updatedAt !== ''
          ? moment(scanResult.updatedAt).startOf('day').fromNow()
          : 'Not updated yet!',
      template: selectedTemplate,
      descView: (
        <span onClick={() => toggleLarge()} style={styles.jobDescView}>
          Click to view
        </span>
      ),
    },
  ];

  React.useEffect(() => {
    if (localStorage.getItem('myScanId')) {
      let get = localStorage.getItem('myScanId');
      props.scanResultsReload('not done', get);
    }
  }, [localStorage.getItem('myScanId')]);

  React.useEffect(() => {
    if (props.user_template) {
      setselectedTemplate(props.user_template);
    }
  }, [props.user_template]);

  React.useEffect(() => {
    props.getCoverLetters();
  }, []);

  React.useEffect(() => {
    if (state) {
      setScanResult(state);
      setOidd(state.oid);
      setResumeName(
        state.data.jobTitle +
        '_' +
        state.data.company +
        '_' +
        moment(state.createdAt).format('MM-DD-YYYY_h:mm:ss a'),
      );
    }
  }, [state]);

  React.useEffect(() => {
    setCoverLetter(props.coverLetters.length ? props.coverLetters[0]._id : '');
    setTitle(props.coverLetters.length ? props.coverLetters[0].title : '');
    setDescription(
      props.coverLetters.length ? props.coverLetters[0].description : '',
    );
  }, [props.coverLetters]);

  const handleSelect = (val) => {
    // setCopies(false);
    setCoverLetter(val);
    let data =
      props.coverLetters.length &&
      props.coverLetters.filter((i) => {
        return i._id === val;
      });
    setTitle(data.length && data[0].title);
    setDescription(data.length && data[0].description);
  };

  const generatePdf = async (id) => {
    if (!!id) {
      if (selectedTemplate === '') {
        toastr.error('please select a template!');
      } else {
        setLoader(true);
        props.getPdf({
          oid: id,
          is_scan: 1,
          temp_id: selectedTemplate,
          token: '',
        });
      }
    }
  };

  //generate pdf on next
  React.useEffect(() => {
    if (oidd !== '') {
      generatePdf(oidd);
    }
  }, [oidd]);

  React.useEffect(() => {
    if (props.getPdfStatus === 'done') {
      setLoader(false);
      setPdfUrl(props.pdf.cloundinary);
      setDocs(props.pdf.doc);
      window.open(`${props.pdf.cloundinary}`, '_blank');
      props.setDefault();
    } else {
      setLoader(false);
    }
  }, [props.getPdfStatus, props.pdf]);

  const goToZip = () => {
    window.open(`${props.zipUrl && props.zipUrl}`, '_blank');
  };

  const toggleLarge = () => {
    setmodalLarge(!modalLarge);
  };

  const toggleLarge2 = () => {
    setmodalLarge2(!modalLarge2);
  };

  const questionsFunction = (condition, type) => {
    setCheckForApply(true);
    let data = {
      type: type,
      oid: oidd,
      isTrue: condition,
    };
    props.updateQuestionsA(data);
  };

  //send text to email or mobile

  const sendTextTo = (type) => {
    if (type === 'mobile') {
      setMobileLoader(true);
      let data = {
        type: type,
        body: `Resume Name: ${resumename} Review Score:${scanResult.data.total_score}
               created At: ${scanResult.createdAt} updated At: ${scanResult.updatedAt}
               Template you choosed: ${selectedTemplate} Pdf URL: ${pdfUrl}
        `,
      };
      props.sendTextToEmailOrMobile(data);
    } else if (type === 'email') {
      setEmailLoader(true);
      let data = {
        type: type,
        body: [
          pdfUrl,
          resumename,
          scanResult.data.total_score,
          scanResult.createdAt,
          scanResult.updatedAt,
          selectedTemplate,
        ],
      };
      props.sendTextToEmailOrMobile(data);
    }
  };

  React.useEffect(() => {
    if (
      props.sendTextToStatus === 'done' ||
      props.sendTextToStatus === 'failed'
    ) {
      if (mobileLoader) {
        setMobileLoader(false);
      } else if (emailLoader) {
        setEmailLoader(false);
      }
    }
  }, [props.sendTextToStatus]);

  return (
    <>
      {/* email parser success model */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito'
            }}
          >
            Message
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent>
          {message}
        </DialogContent>
      </Dialog>
      <Modal isOpen={modalLarge} size="lg" toggle={toggleLarge}>
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '20px 20px 0px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito'
            }}
          >
            {scanResult.data && scanResult.data.jobTitle}
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={toggleLarge}
          />
        </div>
        <ModalBody>
          <Row style={styles.row}>
            <Colxx
              lg={{
                size: 12,
              }}
              md={{
                size: 12,
              }}
              sm="12"
              xs="12"
            >
              <div>
                <h3 style={styles.h3}>
                  RESUME{' '}
                  <CopyToClipboard
                    text={scanResult.resumeText}
                    onCopy={() => toastr.success('resume copied!')}
                  >
                    <FileCopyIcon
                      className="hovdel"
                      style={styles.fileCopyIcon}
                    />
                  </CopyToClipboard>
                </h3>
                <textarea
                  className="scrollBar"
                  placeholder="Paste your resume"
                  style={styles.textArea}
                  name="resume"
                  value={scanResult.resumeText}
                />
              </div>
            </Colxx>
          </Row>
        </ModalBody>
      </Modal>

      {/* model for jobDescription */}
      <Modal isOpen={modalLarge2} size="lg" toggle={toggleLarge2}>
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito'
            }}
          >
            {scanResult.data && scanResult.data.jobTitle}
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={toggleLarge2}
          />
        </div>
        <ModalBody>
          <Row style={styles.row}>
            <Colxx
              lg={{
                size: 12,
              }}
              md={{
                size: 12,
              }}
              sm="12"
              xs="12"
            >
              <div>
                <h3 style={styles.h3}>
                  JOB DESCRIPTION{' '}
                  <CopyToClipboard
                    text={scanResult.jobDescription}
                    onCopy={() => toastr.success('job description copied!')}
                  >
                    <FileCopyIcon
                      className="hovdel"
                      style={styles.fileCopyIcon}
                    />
                  </CopyToClipboard>
                </h3>
                <textarea
                  className="scrollBar"
                  placeholder="Paste the entire job description text - Exclude the 'About company' section, but include the job title."
                  style={styles.scrollBar}
                  name="jobdescription"
                  value={scanResult.jobDescription}
                />
              </div>
            </Colxx>
          </Row>
        </ModalBody>
      </Modal>

      <Card className="saveContainer">
        <CardBody>
          <Descriptions title="Save And Apply" size={'default'}>
            {scanResult?.data &&
              scanResult?.data?.jobApplied === '' &&
              (checkForApply ? null : (
                <Descriptions.Item
                  label="Job Applied?"
                  style={styles.textAlignCenter}
                >
                  <span
                    onClick={() => questionsFunction('yes', 'jobApplied')}
                    style={styles.jobDescView}
                  >
                    yes
                  </span>{' '}
                  /{' '}
                  <span
                    onClick={() => questionsFunction('no', 'jobApplied')}
                    style={styles.jobDescView}
                  >
                    No
                  </span>
                </Descriptions.Item>
              ))}
          </Descriptions>
          <Table
            title={() => (
              <span style={{ fontWeight: 'bold' }}>Resume Detail</span>
            )}
            pagination={false}
            columns={resumeColumns}
            dataSource={resumeData}
            bordered
          />
          <Table
            pagination={false}
            title={() => (
              <span style={{ fontWeight: 'bold' }}>Resume Actions</span>
            )}
            columns={actionColumns}
            dataSource={actionData}
            bordered
          />
          <Table
            title={() => <span style={{ fontWeight: 'bold' }}>Job Detail</span>}
            pagination={false}
            columns={jobDetailColumns}
            dataSource={jobDetailData}
            bordered
          />
          <Table
            pagination={false}
            title={() => (
              <span style={{ fontWeight: 'bold' }}>Cover Letters</span>
            )}
            columns={coverTableColumns}
            dataSource={coverLetterData}
            bordered
          />

          {props.zipUrl && (
            <Table
              pagination={false}
              columns={zipUrlColumn}
              dataSource={zipUrlData}
              bordered
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = ({ fileScanReducer, templateReducer, authUser }) => {
  return {
    scanResult: fileScanReducer.scanResult,
    resume: fileScanReducer.resume,
    coverLetters: templateReducer.coverLetters,
    pdf: templateReducer.pdfLink,
    getPdfStatus: templateReducer.getPdfStatus,
    fedexStatus: fileScanReducer.fedexStatus,
    sendTextToStatus: fileScanReducer.sendTextToStatus,
    user_template: authUser.selectedTemplate,
    user: authUser.user,
  };
};
export default connect(mapStateToProps, {
  scanResultsReload,
  EmailParserAction,
  getCoverLetters,
  saveToken,
  getPdf,
  setDefault,
  updateQuestionsA,
  sendTextToEmailOrMobile,
  backupYouResumeAction,
})(withRouter(FinalResult));
