import { Button } from '@material-ui/core';
import React from 'react';
import { colors } from '../../../commonComponents/colors';
import { styles } from '../MilitaryXWalkStyle';

const BottomButtons = ({
  activeStep,
  handleBack,
  isDisabledNext,
  isDisabledBack,
  handleNext,
}) => {
  if (activeStep === 3) return null;
  return (
    <div>
      <div style={styles.bottomButtonContainer}>
        <Button
          onClick={handleBack}
          disabled={isDisabledBack}
          style={{
            ...styles.stepBtn,
            backgroundColor: isDisabledBack ? colors.grey : colors.blue,
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            handleNext();
          }}
          style={{
            ...styles.stepBtn,
            background: isDisabledNext ? colors.grey : colors.blue,
          }}
          disabled={isDisabledNext}
        >
          {activeStep === 3 ? 'Finish' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

export default BottomButtons;
