import React, { useState, memo, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from 'antd';
import { styles } from './style';
import { Col } from 'reactstrap';
import { BeatLoader } from 'react-spinners';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import { css } from '@emotion/core';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import CancelIcon from '@material-ui/icons/Cancel';
import ViewIcon from '../../assets/svg/ViewIcon';
import { Tooltip } from '@material-ui/core';
import { colors } from '../../commonComponents/colors';
let convertedNotification = {};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const CustomEditor = memo(({ defaultText = '', onChange }) => {
  const [content, setContent] = useState(true);
  // const [coverLetter, setCoverLetter] = useState(defaultText);
  useEffect(() => {
    setTimeout(() => {
      setContent(false);
    }, 1000);
  }, []);

  // const onChange = (newContent) => {
  //   if (Object.keys(convertedNotification).length === 0) {
  //     convertedNotification[Object.keys(convertedNotification)[0]] = newContent;
  //   }
  // };
  const configFroalaEditor = {
    toolbarSticky: false,
    quickInsertTags: false,
    height: 180,
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline', 'fontFamily'],
      },
      moreParagraph: {
        buttons: ['formatUL'],
      },
      moreRich: {
        buttons: ['insertLink'],
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'print'],
        align: 'right',
        buttonsVisible: 3,
      },
    },
    charCounterCount: false,
    attribution: false,
    key: 'CTD5xB1C2B2C1A7B5wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4A3C6E2F2F4G1C1==',
  };
  if (content) {
    return (
      <div style={{ marginTop: 100 }}>
        <center>
          <BeatLoader
            className="loader"
            css={override}
            sizeUnit={'px'}
            size={10}
            color={'#D5641C'}
            loading={true}
          />
        </center>
      </div>
    );
  }

  return (
    <center>
      <div>
        {/* <FroalaEditorComponent
          config={configFroalaEditor}
          model={defaultText}
          onModelChange={onChange}
        /> */}
        <textarea
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            outline: 'none',
          }}
          value={defaultText}
          rows={13}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </center>
  );
});

function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(
    props.resume?.coverLetter?.title || props.jobTitle,
  );
  const [coverLetter, setCoverLetter] = useState(
    props.resume?.coverLetter?.description || '',
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateResume = () => {
    let data = {
      oid: props.resume?.coverLetter?.oid,
      title: title,
      description: coverLetter,
    };
    props.UpdateCoverLetter(data, () => {
      handleClose();
    });
  };
  return (
    <div style={styles.notesModel}>
      <Tooltip title="View">
        <span
          onClick={() => {
            if (!props.checkIsPremium()) props.toggleModal();
            else handleClickOpen();
          }}
          style={{ paddingRight: '20px', cursor: 'pointer' }}
        >
          <ViewIcon />
        </span>
      </Tooltip>
      <Dialog
        open={open}
        // fullScreen
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            Your Cover Letter
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Add Title"
          />
          <div
            style={{
              border: '1px solid #F1F1F1',
              height: '100%',
              overflow: 'auto',
              marginTop: 15,
              marginBottom: 15,
              width: '90vw',
            }}
          >
            <Col lg="12" style={{ padding: 0 }}>
              <CustomEditor
                oid={props.resume?.oid}
                defaultText={coverLetter}
                onChange={setCoverLetter}
              />
            </Col>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={updateResume}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              height: 40,
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;
