import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from '../style';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import uniqid from 'uniqid';
import styles from '../../component/Text/text.module.scss';
import { colors } from '../../../../commonComponents/colors';
import { useDispatch } from 'react-redux';
import { addNewSkillObj, deleteObjInArray } from '../../redux/actions';
const suggestedSkills = [
  {
    id: 'Communication Skills',
    name: 'Communication Skills',
  },
  {
    id: 'MicroSoft Office',
    name: 'MicroSoft Office',
  },
  {
    id: 'MicroSoft Excel',
    name: 'MicroSoft Excel',
  },
  {
    id: 'MicroSoft PowerPoint',
    name: 'MicroSoft PowerPoint',
  },
  {
    id: 'Ability To Work In A Team',
    name: 'Ability To Work In A Team',
  },
  {
    id: 'Ability To Work Under Pressure',
    name: 'Ability To Work Under Pressure',
  },
  {
    id: 'LeaderShip',
    name: 'LeaderShip',
  },
  {
    id: 'Customer Service',
    name: 'Customer Service',
  },
  {
    id: 'Adaptibility',
    name: 'Adaptibility',
  },
  {
    id: 'Time Management',
    name: 'Time Management',
  },
];
export default function Skills(props) {
  const classes = useStyles();
  const [skill, setSkill] = useState('');
  const [addBorder, setAddBorder] = useState(false);
  let { data } = props;
  let dataWithKey = [];
  const convertedData = data?.map((item) => {
    if (item.name) dataWithKey.push(item);
    else {
      dataWithKey.push({
        name: item,
        id: uniqid(),
      });
    }
  });
  const dataKeys = dataWithKey?.map((item) => item.name);
  const availableSuggestedSkills =
    dataKeys.length > 0
      ? suggestedSkills.filter((item) => !dataKeys.includes(item.name))
      : suggestedSkills;

  const dispatch = useDispatch();
  const path = 'skills.items';
  let skillEndElement;
  //eslint-disable-next-line
  const _addNewItem = () => {
    if (skill.length) {
      let newSkill = {
        id: uniqid(),
        name: skill,
      };
      dispatch(addNewSkillObj(newSkill, path));
      //eslint-disable-next-line
      skillEndElement.scrollIntoView &&
        skillEndElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setSkill('');
    }
  };
  const _addSuggestedItem = (id, name) => {
    let newSkill = {
      id: id,
      name: name,
    };
    dispatch(addNewSkillObj(newSkill, path));
  };
  //eslint-disable-next-line
  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };

  return (
    <div>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.skillList}
      >
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Add Skill{' '}
          </label>
          <br />
          <p>
            Please select all skills from list below. Add skills relevant to
            your experience.
          </p>
        </div>
        {availableSuggestedSkills?.map((item, index) => (
          <Chip
            label={item.name}
            onClick={() => _addSuggestedItem(item.id, item.name)}
            key={index}
            style={{ margin: 3, borderRadius: 5, fontSize: 14 }}
            deleteIcon={<AddIcon />}
          />
        ))}
        {dataWithKey?.map((item, index) => {
          if (!item?.name) return null;
          return (
            <Chip
              label={item.name}
              key={index}
              onClick={() => _removeItem(index)}
              onDelete={() => _removeItem(index)}
              style={{ margin: 3, borderRadius: 5, fontSize: 14 }}
              color="primary"
            />
          );
        })}
        <div
          className={classes.skillEnd}
          ref={(el) => {
            skillEndElement = el;
          }}
        />
      </Grid>
      <div>
        <input
          value={skill}
          type="text"
          onChange={(e) => setSkill(e.target.value)}
          onClick={() => {
            setAddBorder(true);
          }}
          onBlur={() => {
            setAddBorder(false);
          }}
          placeholder="Ex: Cashier"
          className={styles.contentEditableContainer + ' ' + classes.input}
          style={{
            width: '100%',
            border: '1px solid',
            borderColor: addBorder ? colors.blue : colors.grey,
          }}
          onKeyPress={(e) => {
            if (e.charCode === 13) {
              _addNewItem();
            }
          }}
        />
      </div>
      <div style={{ textAlign: 'end', marginTop: 20 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => _addNewItem()}
          className={classes.button}
          style={{ marginRight: 0 }}
        >
          Add
        </Button>
      </div>
    </div>
  );
}
