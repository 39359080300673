import React, { useState, useEffect } from 'react';
import PopAnimation from '../../assets/animations/popAnimation.json';
import { Card, Col } from 'reactstrap';
import InfoCard from './Modals/InfoCard';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StepByStep from '../../assets/stepbystep.webp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { InfoHelper, getLinkedInImage } from './FinalOptionsHelper';
import InfoIcon from '../../assets/infoIcon.webp';
import Tooltip from '@material-ui/core/Tooltip';
import Lottie from 'lottie-react';
// import Confetti from 'react-confetti';
import Congrats from '../../assets/congrats.webp';
import MarkCheckBox from '../../assets/markCheckBox.webp';
import { useWindowSize } from '@react-hook/window-size';
import TextIcon from '../../assets/text.webp';
import RightIconButton from '../../commonComponents/RightIconButton';
import { colors } from '../../commonComponents/colors';
import CancelIcon from '@material-ui/icons/Cancel';

export default function FinalOptions() {
  const [open, setOpen] = useState(false);
  const [imageDialogue, setImageDialogue] = useState(false);
  const [info, setInfo] = useState({});
  const [image, setImage] = useState('');
  const [width, height] = useWindowSize();
  const [completed, setCompleted] = useState(false);

  const [checkList, setCheckList] = useState([]);

  const flex_Row = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  useEffect(() => {
    if (checkList.length === 9) {
      setCompleted(true);
    }
  }, [checkList]);

  const handleCheckBoxes = (e) => {
    let selectedJobTypes = checkList;
    if (selectedJobTypes?.length == 0) {
      setCheckList([e.target.name]);
    } else {
      let checkObj = selectedJobTypes.find((item) => item === e.target.name);
      if (checkObj) {
        let d = [];
        d = selectedJobTypes.filter((item) => item !== e.target.name);
        setCheckList(d);
      } else {
        setCheckList([...selectedJobTypes, e.target.name]);
      }
    }
  };

  const handleImageDialog = () => {
    setImageDialogue(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleImageDialogueClose = () => {
    setImageDialogue(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const RecruiterLabel = () => {
    return (
      <RightIconButton
        text="RECRUITER FOCUS"
        color={colors.darkYellow}
        imgsrc={TextIcon}
        style={{
          marginLeft: width < 500 ? 0 : 40,
          alignSelf: width < 500 ? 'flex-start' : 'center',
          cursor: 'default',
        }}
      />
    );
  };
  const RenderTitle = ({ val }) => {
    return <p className="m-0 font-weight-bold text-left">{val}</p>;
  };
  const InfoIconUI = ({ text }) => {
    return (
      <Tooltip
        style={{ marginRight: '10px', marginTop: '-6px' }}
        title={text}
        arrow
      >
        <img src={InfoIcon} width={20} height={20} />
      </Tooltip>
    );
  };
  return (
    <>
      <h4 className="font-weight-bold">Final Options</h4>
      <p>Below is the content to update your LinkedIn profile</p>

      <div
        className="mt-4"
        style={{
          display: 'flex',
          flexDirection: width < 991 ? 'column-reverse' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <Col
          style={{
            ...(width < 500 && { paddingLeft: 0, paddingRight: 0 }),
          }}
          lg={8}
        >
          <Card
            style={{
              ...(width < 500 && { paddingLeft: 2, paddingRight: 0 }),
            }}
            body
          >
            <span
              className="border-bottom p-1"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                ...(width < 500 && {
                  alignSelf: 'flex-start',
                  flexDirection: 'column',
                }),
              }}
            >
              <span
                className="d-flex flex-row align-items-center"
                style={{
                  width: width < 500 ? '100%' : '70%',
                  gap: 10,
                  marginBottom: width < 500 ? 10 : 0,
                }}
              >
                <span className="ml-2">
                  <img
                    src={StepByStep}
                    width={width < 500 ? '20px' : '30px'}
                    onClick={() => {
                      setInfo(InfoHelper.step1);
                      handleClickOpen();
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </span>
                <RenderTitle val={'Copied Content to LinkedIn Profile?'} />
              </span>
              <span
                style={{
                  ...(width < 500 && { alignSelf: 'flex-end' }),
                }}
              >
                <img
                  src={MarkCheckBox}
                  style={{
                    position: 'absolute',
                    right: width < 500 ? '-8px' : 10,
                    top: width < 500 ? 44 : width < 304 ? 61 : 5,
                    width: 100,
                    objectFit: 'center',
                  }}
                />
                <span style={flex_Row}>
                  <InfoIconUI
                    text={
                      'If you have not copied to your LinkedIn profile, click "previous" button to go back and complete.'
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkList.some((v) => v === 'CopyContent')}
                        name="CopyContent"
                        color="primary"
                        onChange={handleCheckBoxes}
                      />
                    }
                  />
                </span>
              </span>
            </span>

            <span
              className="border-bottom p-1"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: width < 500 ? 'column' : 'row',
              }}
            >
              <span
                className="d-flex"
                style={{
                  width: width < 500 ? '100%' : '70%',
                  flexDirection: width < 500 ? 'column-reverse' : 'row',
                  gap: 10,
                  marginBottom: width < 500 ? 10 : 0,
                }}
              >
                <span
                  className="d-flex"
                  style={{
                    gap: 10,
                  }}
                >
                  <span className="ml-2">
                    <img
                      src={StepByStep}
                      width={width < 500 ? '20px' : '30px'}
                      onClick={() => {
                        setInfo(InfoHelper.allStar);
                        handleClickOpen();
                      }}
                      style={{ cursor: 'pointer', alignSelf: 'flex-end' }}
                    />
                  </span>
                  <RenderTitle
                    val={
                      " Verify LinkedIn profile status is 'All Star' on 'Your Dashboard'"
                    }
                  />
                </span>
                <RecruiterLabel />
              </span>
              <span
                style={{
                  ...flex_Row,
                  ...(width < 500 && { alignSelf: 'flex-end' }),
                }}
              >
                <InfoIconUI
                  text={
                    'Recruiter’s searches require profiles with “All Star” status to show up. “All Star” status indicates that you completed all sections of your LinkedIn profile. '
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkList.some((v) => v === 'AllStar')}
                      name="AllStar"
                      color="primary"
                      onChange={handleCheckBoxes}
                      style={{
                        ...(width < 500 && { marginRight: 0 }),
                      }}
                    />
                  }
                  label=""
                />
              </span>
            </span>
            <span
              className="border-bottom p-1"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: width < 500 ? 'column' : 'row',
              }}
            >
              <span
                className="d-flex flex-row"
                style={{ width: width < 500 ? '100%' : '70%', gap: 10 }}
              >
                <span className="ml-2">
                  <img
                    src={StepByStep}
                    width={width < 500 ? '20px' : '30px'}
                    onClick={() => {
                      setInfo(InfoHelper.profilePhoto);
                      handleClickOpen();
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </span>{' '}
                <RenderTitle
                  val={' Update profile photo with a professional picture?'}
                />
              </span>
              <span
                style={{
                  ...flex_Row,
                  ...(width < 500 && { alignSelf: 'flex-end' }),
                }}
              >
                <InfoIconUI
                  text={
                    'Update your “Profile photo” to a professional looking one for your job searches. Click on “Step-by-Step” icon for help and steps. '
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkList.some((v) => v === 'ProfilePhoto')}
                      name="ProfilePhoto"
                      color="primary"
                      onChange={handleCheckBoxes}
                    />
                  }
                  label=""
                />
              </span>
            </span>
            <span
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: width < 500 ? 'column' : 'row',
              }}
              className="border-bottom p-1"
            >
              <div
                style={{
                  display: 'flex',
                  width: width < 500 ? '100%' : '70%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: 2,
                  flexDirection: width < 500 ? 'column' : 'row-reverse',
                  gap: width < 500 ? 10 : 0,
                }}
              >
                <RecruiterLabel />
                <span className="d-flex flex-row" style={{ gap: 10 }}>
                  <span className="ml-2 ">
                    <img
                      src={StepByStep}
                      width={width < 500 ? '20px' : '30px'}
                      onClick={() => {
                        setInfo(InfoHelper.profilePage);
                        handleClickOpen();
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </span>
                  <RenderTitle
                    val={' Update setting: “Open to” & “Finding a new job”'}
                  />
                </span>
              </div>

              <span
                style={{
                  ...flex_Row,
                  ...(width < 500 && { alignSelf: 'flex-end' }),
                }}
              >
                <InfoIconUI
                  text={
                    'Recruiter’s searches require profiles setting to “Open to & Finding a new job” before your profile is included in search results. Also update your Job preferences. '
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkList.some((v) => v === 'UpdateSettings')}
                      name="UpdateSettings"
                      color="primary"
                      onChange={handleCheckBoxes}
                    />
                  }
                  label=""
                />
              </span>
            </span>
            <div style={{ paddingLeft: width < 500 ? 0 : 30 }}>
              <span
                className="border-bottom p-1"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: width < 500 ? 'column' : 'row',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: width < 500 ? '100%' : '70%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: width < 500 ? 'column' : 'row-reverse',
                    paddingTop: 2,
                    gap: width < 500 ? 10 : 0,
                  }}
                >
                  <RecruiterLabel />
                  <span
                    className="d-flex flex-row"
                    style={{ width: width < 500 ? '100%' : '70%', gap: 10 }}
                  >
                    <span className="ml-2">
                      <img
                        src={StepByStep}
                        width={width < 500 ? '20px' : '30px'}
                        onClick={() => {
                          setImage('JobTitles');
                          handleImageDialog();
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </span>{' '}
                    <p className="m-0">Job Titles</p>
                  </span>
                </div>
                <span
                  style={{
                    ...flex_Row,
                    ...(width < 500 && { alignSelf: 'flex-end' }),
                  }}
                >
                  <InfoIconUI
                    text={
                      'Add job titles you’re looking for. Type titles in the grey bubble called Add title +'
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkList.some((v) => v === 'JobTitles')}
                        name="JobTitles"
                        color="primary"
                        onChange={handleCheckBoxes}
                      />
                    }
                    label=""
                  />
                </span>
              </span>

              <span
                className="border-bottom p-1"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: width < 500 ? 'column' : 'row',
                }}
              >
                <span
                  className="d-flex flex-row align-items-center "
                  style={{
                    width: width < 500 ? '100%' : '70%',
                    gap: 10,
                  }}
                >
                  <span className="ml-2">
                    <img
                      src={StepByStep}
                      width={width < 500 ? '20px' : '30px'}
                      onClick={() => {
                        setImage('WorkPlaces');
                        handleImageDialog();
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </span>{' '}
                  <p className="m-0">Work Places</p>
                </span>
                <span
                  style={{
                    ...flex_Row,
                    ...(width < 500 && { alignSelf: 'flex-end' }),
                  }}
                >
                  <InfoIconUI
                    text={
                      'Select workplaces you are looking for. If you are looking for “Remote” only work, please select Remove and remove On-site and Hybrid. '
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkList.some((v) => v === 'WorkPlaces')}
                        name="WorkPlaces"
                        color="primary"
                        onChange={handleCheckBoxes}
                      />
                    }
                    label=""
                  />
                </span>
              </span>

              <span
                className="border-bottom p-1"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: width < 500 ? 'column' : 'row',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: width < 500 ? '100%' : '70%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: width < 500 ? 'column' : 'row-reverse',
                    gap: width < 500 ? 10 : 0,
                  }}
                >
                  <RecruiterLabel />
                  <span
                    className="d-flex flex-row align-items-center "
                    style={{
                      width: width < 500 ? '100%' : '70%',
                      gap: 10,
                    }}
                  >
                    <span className="ml-2">
                      <img
                        src={StepByStep}
                        width={width < 500 ? '20px' : '30px'}
                        onClick={() => {
                          setImage('JobLocations');
                          handleImageDialog();
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </span>
                    <p className="m-0">Job Locations</p>
                  </span>
                </div>
                <span
                  style={{
                    ...flex_Row,
                    ...(width < 500 && { alignSelf: 'flex-end' }),
                  }}
                >
                  <InfoIconUI
                    text="Please add locations you are interested in for the new job. Select “greater” or “metro area” of the
region to get more exposure. "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkList.some((v) => v === 'JobLocations')}
                        name="JobLocations"
                        color="primary"
                        onChange={handleCheckBoxes}
                      />
                    }
                    label=""
                  />
                </span>
              </span>

              <span
                className="border-bottom p-1"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  className="d-flex flex-row align-items-center "
                  style={{
                    width: width < 500 ? '100%' : '70%',
                    gap: 10,
                  }}
                >
                  <span className="ml-2">
                    <img
                      src={StepByStep}
                      width={width < 500 ? '20px' : '30px'}
                      onClick={() => {
                        setImage('StartDate');
                        handleImageDialog();
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </span>{' '}
                  <p className="m-0">Start Date</p>
                </span>
                <span style={flex_Row}>
                  <InfoIconUI text={'Add your start date preference.'} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkList.some((v) => v === 'StartDate')}
                        name="StartDate"
                        color="primary"
                        onChange={handleCheckBoxes}
                      />
                    }
                    label=""
                  />
                </span>
              </span>

              <span
                className="border-bottom p-1"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  flexDirection: width < 500 ? 'column' : 'row',
                }}
              >
                <span
                  className="d-flex flex-row align-items-center "
                  style={{
                    width: width < 500 ? '100%' : '70%',
                    gap: 10,
                  }}
                >
                  <span className="ml-2">
                    <img
                      src={StepByStep}
                      width={width < 500 ? '20px' : '30px'}
                      onClick={() => {
                        setImage('WhoSees');
                        handleImageDialog();
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </span>{' '}
                  <p className="m-0">Choose who sees you're open</p>
                </span>
                <span
                  style={{
                    flex_Row,
                    ...(width < 500 && { alignSelf: 'flex-end' }),
                  }}
                >
                  <InfoIconUI
                    text={
                      'Finally, you will have the option to Choose who sees you’re open. Choose from All LinkedIn members or just Recruiters. '
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkList.some((v) => v === 'ChooseWhoSeeYou')}
                        name="ChooseWhoSeeYou"
                        color="primary"
                        onChange={handleCheckBoxes}
                      />
                    }
                    label=""
                  />
                </span>
              </span>
            </div>
          </Card>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div
              id="alert-dialog-slide-title"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '10px 20px',
              }}
            >
              <h5
                style={{
                  fontFamily: 'Nunito',
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: 23,
                }}
              >
                {info?.title}
              </h5>
              <CancelIcon
                style={{
                  color: colors.red,
                  cursor: 'pointer',
                  fontSize: 24,
                }}
                onClick={handleClose}
              />
            </div>
            {/* <DialogTitle id="alert-dialog-title">{info?.title}</DialogTitle> */}
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className="f-s-19"
              >
                {info?.p1}
                <br />
                {info?.p2}
                <br />
                {info?.p3}
                <br />
                {info?.p4}
                <br />
                {info?.p5}
              </DialogContentText>
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions> */}
          </Dialog>
          <Dialog
            open={imageDialogue}
            onClose={handleImageDialogueClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div
              id="alert-dialog-slide-title"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '10px 20px',
              }}
            >
              <h5
                style={{
                  fontFamily: 'Nunito',
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: 23,
                }}
              >
                {getLinkedInImage(image).title}
              </h5>
              <CancelIcon
                style={{
                  color: colors.red,
                  cursor: 'pointer',
                  fontSize: 24,
                }}
                onClick={handleImageDialogueClose}
              />
            </div>
            <DialogContent>
              <img src={getLinkedInImage(image).image} width={400} />
              <p className="mt-2  f-s-19"> {getLinkedInImage(image).text}</p>
            </DialogContent>
            {/* <DialogActions>
              <Button
                onClick={handleImageDialogueClose}
                color="primary"
                autoFocus
              >
                OK
              </Button>
            </DialogActions> */}
          </Dialog>
        </Col>
        <Col
          style={{
            ...(width < 500 && { paddingLeft: 0, paddingRight: 0 }),
          }}
          lg={4}
        >
          <InfoCard
            heading={'Almost there! Final options to update LinkedIn'}
            message={
              "This is your final checklist to complete your LinkedIn profile. Make sure you compete all items as recommended on your LinkedIn profile as well as your LinkedIn account 'Job Preferences' options so that recruiter can find you easily."
            }
          />
        </Col>
      </div>
      <Dialog
        open={completed}
        onClose={() => setCompleted(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            {info?.title}
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => setCompleted(false)}
          />
        </div>
        {/* <DialogTitle id="alert-dialog-title">{info?.title}</DialogTitle> */}
        <DialogContent>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {/* <img src={Congrats} width={100} /> */}
            <Lottie
              style={{ width: 70, marginTop: -10 }}
              animationData={PopAnimation}
              loop={true}
            />
            <h4>Congratulations!</h4>
            <h5>You completed your LinkedIn profile update</h5>
            {/* <Button
              className="mt-3"
              variant="contained"
              onClick={() => setCompleted(false)}
              color="primary"
            >
              OK
            </Button> */}
          </span>
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </Dialog>
      {/* {completed && <Confetti width={width} height={height} />} */}
    </>
  );
}
