import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';

import { useWindowSize } from '@react-hook/window-size';
import { updateLinkedinData } from '../redux/actions';
import moment from 'moment';

export default function Awards(props) {
  const [width] = useWindowSize();
  const [issuer, setIssuer] = useState('');
  const [associatedWith, setAssociatedWith] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [year, setYear] = useState('');
  const [description, setDescription] = useState('');
  const [copied, setCopied] = useState([]);
  const [title, setTitle] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [fieldIndex, setIndex] = useState(0);

  const dispatch = useDispatch();
  const linkedinData = props.linkedinData;
  const awardsModal = useSelector((state) => state.linkedinReducer.awardsModal);
  const awardsModalMode = useSelector(
    (state) => state.linkedinReducer.awardsModalMode,
  );

  const cb = (v) => {
    dispatch({
      type: 'PROJECT_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      toastr.success('Awards Updated');
    } else {
      toastr.error('Error Updating Awards');
    }
  };
  useEffect(() => {
    if (awardsModalMode === 'new') {
      setTitle('');
      setYear('');
      setDescription('');
      setIssuer('');
      setAssociatedWith('');
      setIssueDate('');
    }
  }, [awardsModalMode]);

  const handleUpdate = () => {
    const data = {
      title: title,
      year: year,
      description: description,
      issuer: issuer,
      associatedWith: associatedWith,
      issueDate: issueDate,
    };
    if (isEdit) {
      linkedinData.awards.splice(fieldIndex, 1, data);
    } else {
      linkedinData.awards.push(data);
    }

    dispatch(updateLinkedinData(linkedinData, cb));
    dispatch({
      type: 'AWARDS_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
  };

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };

  return (
    <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
      {linkedinData?.awards?.length
        ? linkedinData.awards.map((item, index) => {
            return (
              <Row key={index} className="mt-2">
                <Col
                  xs={10}
                  lg={11}
                  xl={11}
                  md={11}
                  className="border-bottom p-5"
                >

                  <div style={styles.title}>{item.title}</div>
                  <div style={styles.sub}>
                    {item?.['issueDate'] && moment(item?.['issueDate']).format("MM/DD/YY")} -
                    {item?.['year'] || ''}
                  </div>
                  <p style={styles.years}>{item?.['issuer']}</p>
                  <p style={styles.years}>{item?.['description']}</p>
                </Col>
                  
                <Col
                  xs={2}
                  lg={1}
                  xl={1}
                  md={1}
                  className="border-bottom p-2 d-flex align-items-center justify-content-center"
                >
                  <span
                    style={styles.copyIcon}
                    onClick={() => {
                      setTitle(item.title);
                      setYear(item.year);
                      setDescription(item.description);
                      setIssuer(item.issuer);
                      setAssociatedWith(item.associatedWith);
                      setIssueDate(item.issueDate);
                      setIsEdit(true);
                      setIndex(index);
                      dispatch({
                        type: 'AWARDS_MODAL',
                        payload: {
                          open: true,
                          mode: 'edit',
                        },
                      });
                    }}
                  >
                    {props.stepIndex === 1 ? (
                      <CreateIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    ) : (
                      <FileCopyIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    )}
                  </span>
                </Col>
              </Row>
            );
          })
        : 'NO DATA AVAILABLE'}

      <Popup
        open={awardsModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'AWARDS_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'AWARDS_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5>Honors & Awards</h5>

        <Row>
          <Col>
            <Label for="exampleEmail">Title</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />{' '}
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(title) ? (
                    <CopyToClipboard
                      text={title}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(title);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Associated With
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={associatedWith}
                onChange={(e) => setAssociatedWith(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(associatedWith) ? (
                    <CopyToClipboard
                      text={associatedWith}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(associatedWith);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Issuer
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={issuer}
                onChange={(e) => setIssuer(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(issuer) ? (
                    <CopyToClipboard
                      text={issuer}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(issuer);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Row>
              <Col xs={6}>
                <Label style={{ marginTop: 15 }} for="exampleEmail">
                  Issue Date
                </Label>
                <InputGroup>
                  <Input
                    type="date"
                    style={{ height: '50px' }}
                    value={issueDate}
                    onChange={(e) => setIssueDate(e.target.value)}
                  />
                  {props.stepIndex === 2 && (
                    <InputGroupText>
                      {!copied.includes(issueDate) ? (
                        <CopyToClipboard
                          text={issueDate}
                          onCopy={() => {
                            toastr.success('Text Copied!');
                            handleCopied(issueDate);
                          }}
                        >
                          <FileCopyIcon className="hovdel" />
                        </CopyToClipboard>
                      ) : (
                        <CheckIcon style={{ color: 'green' }} />
                      )}
                    </InputGroupText>
                  )}
                </InputGroup>
              </Col>
              <Col xs={6}>
                <Label style={{ marginTop: 15 }} for="exampleEmail">
                  Year
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={new Date().getFullYear() - 100}
                    max={new Date().getFullYear() + 100}
                    style={{ height: '50px' }}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  />
                  {props.stepIndex === 2 && (
                    <InputGroupText>
                      {!copied.includes(year) ? (
                        <CopyToClipboard
                          text={year}
                          onCopy={() => {
                            toastr.success('Text Copied!');
                            handleCopied(year);
                          }}
                        >
                          <FileCopyIcon className="hovdel" />
                        </CopyToClipboard>
                      ) : (
                        <CheckIcon style={{ color: 'green' }} />
                      )}
                    </InputGroupText>
                  )}
                </InputGroup>
              </Col>
            </Row>

            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Description
            </Label>
            <InputGroup>
              <Input
                style={{ height: '150px' }}
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(description) ? (
                    <CopyToClipboard
                      text={description}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(description);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={false}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  Save
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};
