import React from 'react';

const TxtIcon = ({ height, width }) => {
  return (
    <svg
      width={width ? width : '42'}
      height={height ? height : '51'}
      viewBox="0 0 458 559"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 59C36 34.1472 56.1472 14 81 14H325.5L331 40.5L338.5 86.5H359.5L367.47 96.7112C372.715 103.432 379.762 108.524 387.789 111.395L444 131.5V514C444 538.853 423.853 559 399 559H81C56.1472 559 36 538.853 36 514V59Z"
        fill="url(#paint0_linear_113_582)"
      />
      <rect
        y="209"
        width="290"
        height="155"
        rx="26"
        fill="url(#paint1_linear_113_582)"
      />

      <path
        d="M316 24.1421C316 15.2331 326.771 10.7714 333.071 17.0711L387 71L440.929 124.929C447.229 131.229 442.767 142 433.858 142H358C334.804 142 316 123.196 316 100V24.1421Z"
        fill="url(#paint2_linear_113_582)"
      />
      <path
        d="M76 251L76 322"
        stroke="#C8E2F7"
        stroke-width="17"
        stroke-linecap="round"
      />
      <path
        d="M94 251H57"
        stroke="#C8E2F7"
        stroke-width="17"
        stroke-linecap="round"
      />
      <path
        d="M215 251L215 322"
        stroke="#C8E2F7"
        stroke-width="17"
        stroke-linecap="round"
      />
      <path
        d="M233 251H196"
        stroke="#C8E2F7"
        stroke-width="17"
        stroke-linecap="round"
      />
      <path
        d="M125 251L165.992 322"
        stroke="#C8E2F7"
        stroke-width="17"
        stroke-linecap="round"
      />
      <path
        d="M165.992 251L125 322"
        stroke="#C8E2F7"
        stroke-width="17"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_113_582"
          x1="365.5"
          y1="76"
          x2="68"
          y2="534.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.333123" stop-color="#CFE6F8" />
          <stop offset="1" stop-color="#B5D9F4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_113_582"
          x1="-44.5"
          y1="309.5"
          x2="290"
          y2="311.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0260D0" />
          <stop offset="1" stop-color="#2386FD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_113_582"
          x1="405.5"
          y1="48"
          x2="308.5"
          y2="153"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.295608" stop-color="#00CFD7" />
          <stop offset="0.884199" stop-color="#009DA2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TxtIcon;
