import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import styles from './DropZone.module.scss';
import docSelect from '../../../../assets/doc-select.png';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pdfUploadData } from '../../../resumeBuilder/redux/actions';
import ScanLoader from '../../../../commonComponents/scanLoader';
import { colors } from '../../../../commonComponents/colors';
import { Check, CreateOutlined } from '@material-ui/icons';
import toastr from 'toastr';

const guideList = [
  'Your uploaded resume info has been transferred.',
  'Next step is to go to each section to complete new resume.',
];

function PDFUploader() {
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [startState, setStartState] = useState(false);
  const router = useHistory();
  const dispatch = useDispatch();

  const handleFileDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const extension = file.name.split('.').pop();
    if (
      extension === 'pdf' ||
      extension === 'doc' ||
      extension === 'docx'
      // || extension === 'txt'
    ) {
      return setFile(acceptedFiles[0]);
    }

    return toastr.error('Please upload a valid file');
  };
  const callBack = () => {
    localStorage.setItem('parse-info-model', true);
    setLoader(false);
    setStartState(true);
  };
  const buttonState = () => {
    setLoader(true);
    dispatch(pdfUploadData(file, callBack, (error) => {
      setLoader(false);
      toastr.error(error);
    }));
  };

  return (
    <div
      style={{
        paddingTop: 70,
        paddingBottom: 70,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#f9fbfa',
        zIndex: 1000,
      }}
    >
      {startState ? (
        loader ? (
          <ScanLoader title="Upload started" />
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              paddingInline: '30px',
            }}
          >
            <div className={styles.complete_text}>Upload Completed!</div>
            {guideList?.length
              ? guideList?.map((guideText, index) => (
                <div key={index}>
                  <p
                    style={{
                      marginBottom: '20px',
                      fontSize: '1.2rem',
                    }}
                  >
                    <span
                      style={{
                        color: colors.blue,
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                      }}
                    >
                      ⦁{' '}
                    </span>
                    {guideText}
                  </p>
                </div>
              ))
              : ''}
            <button
              type="button"
              className={styles.button_browse}
              style={{
                backgroundColor: '#4267b2',
                color: 'white',
                cursor: 'pointer',
                marginTop: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 10,
              }}
              onClick={() => {
                router.push('/questionnaire/work_experience');
              }}
            >
              <CreateOutlined />
              Edit Resume
            </button>
          </div>
        )
      ) : (
        <>
          <div className={styles.upload_text}>
            {file ? 'Ready to upload!' : 'Upload your existing resume'}
          </div>
          <Dropzone
            onDrop={handleFileDrop}
            // accept=".pdf,.doc,.docx,.html,.rtf,.txt"
            accept=".pdf,.doc,.docx"
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                className={styles.drop_zone}
                style={{
                  border: `2px ${isDragActive ? 'dashed' : 'solid'
                    } rgb(66, 103, 178)`,
                }}
              >
                <input {...getInputProps()} />
                {file ? (
                  <div className={styles.dropZone_Div2}>
                    <div
                      className={styles.drop_zone_img_div}
                      style={{
                        position: 'relative',
                      }}
                    >
                      <img src={docSelect} className={styles.drop_zone_img} />
                      <div
                        style={{
                          position: 'absolute',
                          bottom: -10,
                          right: -10,
                          backgroundColor: colors.blue,
                          borderRadius: '50%',
                          width: 30,
                          height: 30,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Check style={{ color: 'white' }} />
                      </div>
                    </div>
                    <p className={styles.drop_zone_text}>
                      File Selected: “{file.name}”
                    </p>
                    <div className={styles.drop_zone_text2}>
                      We are all set! Let's polish your resume!
                    </div>
                    <button type="button" className={`${styles.button_select}`}>
                      Select New File
                    </button>
                  </div>
                ) : (
                  <div className={styles.dropZone_Div2}>
                    <div className={styles.drop_zone_img_div}>
                      <img src={docSelect} className={styles.drop_zone_img} />
                    </div>
                    <p className={styles.drop_zone_text}>
                      Drag and drop a files here
                    </p>
                    <button
                      type="button"
                      style={{
                        marginTop: '10px',
                        marginBottom: '15px',
                      }}
                      className={`${styles.button_browse}`}
                    >
                      Browse
                    </button>
                    <div className={styles.drop_zone_text2}>
                      Files we can read: DOC, DOCX, PDF
                      {/* Files we can read: DOC, DOCX, PDF, TXT */}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          {file ? (
            <div
              style={{
                paddingTop: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px 20px',
                flexWrap: 'wrap',
              }}
            >
              <button
                type="button"
                onClick={() => {
                  setFile(null);
                }}
                className={styles.button_select}
              >
                Back
              </button>
              <button
                type="button"
                className={styles.button_browse}
                style={{
                  backgroundColor: loader ? '#e0e0e0' : '#4267b2',
                  color: loader ? 'black' : 'white',
                  cursor: loader ? 'not-allowed' : 'pointer',
                  marginTop: 20,
                }}
                disabled={loader}
                onClick={buttonState}
              >
                Continue
              </button>
            </div>
          ) : (
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <button
                type="button"
                className={`${styles.button_select}`}
                onClick={() => {
                  router.push('/how-to-start');
                }}
              >
                Back
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default PDFUploader;
