import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import { Popup } from 'reactjs-popup';
import CancelIcon from '@material-ui/icons/Cancel';
import ContactSupport from '../../../profile/help';
import ChangePassword from '../../../account/changePass';
import { useHistory } from 'react-router-dom';
import { Input } from 'antd';
import toastr from 'toastr';
import { validateEmail } from '../../../../utils/emailValidation';
import { CircularProgress } from '@material-ui/core';
import { forgetPassword, logoutUser } from '../../../auth/redux/actions';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
} from 'reactstrap';
import ResumaLogo from '../../../../assets/optirblue.webp';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from './style';
import { CONTACT_MODAL } from '../../redux/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar(props) {
  const [showChangePass, setShowChangePass] = useState(false);
  const dispatch = useDispatch();
  const [switchForgotUI, setSwithForgotUI] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const { contactSupportModal } = useSelector((state) => state?.authUser);
  const onClose = () => {
    dispatch({
      type: CONTACT_MODAL,
      payload: false,
    });
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" color={'#4267B2'}>
        <Toolbar>
          <div className={classes.title}>
            <span
              onClick={() => history.push('/employerDashboard')}
              style={{ color: 'black', cursor: 'pointer' }}
            >
              <img style={{ cursor: 'pointer' }} src={ResumaLogo} width={120} />
            </span>
          </div>
          <Button
            onClick={() =>
              dispatch({
                type: CONTACT_MODAL,
                payload: true,
              })
            }
            color="inherit"
          >
            Support{' '}
          </Button>
          {/* <Popup
            open={contactSupportModal}
            onClose={() =>
              dispatch({
                type: CONTACT_MODAL,
                payload: false,
              })
            }
            contentStyle={{ borderRadius: 10, width: '50%', padding: 10 }}
            modal
            position="center"
            closeOnDocumentClick
          > */}
            <div style={{ alignSelf: 'center' }}>
              <>
                {/* <span
                  onClick={() =>
                    dispatch({
                      type: CONTACT_MODAL,
                      payload: false,
                    })
                  }
                >
                  <CancelIcon style={styles.closeBtn} />
                </span> */}
                <ContactSupport  onClose={onClose}  open={contactSupportModal}/>
              </>
            </div>
          {/* </Popup> */}
          <Popup
            open={showChangePass}
            onClose={() => setShowChangePass(false)}
            contentStyle={{ marginTop: 120, borderRadius: 10, padding: 10 }}
            modal
            position="center"
            closeOnDocumentClick
          >
            <div style={{ alignSelf: 'center' }}>
              <>
                <span onClick={() => setShowChangePass(false)}>
                  <CancelIcon style={styles.closeBtn} />
                </span>
                {!switchForgotUI ? (
                  <ChangePassword
                    swithToForgot={setSwithForgotUI}
                    setShowChangePass={setShowChangePass}
                    comingFrom="employer"
                  />
                ) : (
                  <ForgotModal
                    user={props.user}
                    swithToForgot={setSwithForgotUI}
                    setShowChangePassFalse={() => setShowChangePass(false)}
                  />
                )}
              </>
            </div>
          </Popup>

          <UncontrolledDropdown
            // isOpen={this.state.dropdownOpen}
            // toggle={this.toggle}
            className="dropdown-menu-right mr-3"
          >
            <DropdownToggle className="p-0" color="empty">
              <Button color="inherit">Account</Button>
            </DropdownToggle>
            <DropdownMenu
              style={{
                width: 300,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className="mt-3 change_font_style"
              right
            >
              <DropdownItem>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <PersonIcon
                    style={{
                      backgroundColor: '#4267B2',
                      color: 'white',
                      fontSize: 70,
                      borderRadius: 50,
                      padding: 20,
                      marginBottom: 5,
                    }}
                  />
                  <h6>
                    {props.user?.firstName
                      ? props.user?.firstName.length > 10
                        ? props.user?.firstName.slice(0, 10) + '...'
                        : props.user?.firstName
                      : ''}
                  </h6>
                  <h6>
                    {props.user?.firstName
                      ? props.user?.firstName.length > 10
                        ? props.user?.firstName.slice(0, 10) + '...'
                        : props.user?.firstName
                      : ''}
                  </h6>
                  <p style={{ color: 'rgba(0,0,0,0.5)', marginTop: -6 }}>
                    {props.user?.email}
                  </p>
                </div>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
              // onClick={props.toggleInvoiceModal}
              // style={styles.fontWeight}
              >
                <span
                  onClick={() => history.push('/pastInvoices')}
                  style={{ color: 'black' }}
                >
                  <center> Past Invoices</center>
                </span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => setShowChangePass(true)}
                // style={styles.fontWeight}
              >
                <center> Change Password</center>
              </DropdownItem>

              <DropdownItem divider />
              <DropdownItem
                onClick={() => dispatch(logoutUser(props.history))}
                // style={styles.fontWeight}
              >
                <center> Sign out</center>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export const ForgotModal = (props) => {
  const [email, setEmail] = useState(props.user?.email);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const callBack = (res) => {
    if (res) {
      setLoader(true);
      props.setShowChangePassFalse();
    }
    setLoader(false);
  };
  const recoverHandler = () => {
    if (!email) {
      toastr.error('Email can not be empty');
    } else if (!validateEmail(email)) {
      toastr.error('Email is not valid');
    } else {
      setLoader(true);
      dispatch(
        forgetPassword(
          {
            email: email,
          },
          callBack,
        ),
      );
    }
  };
  return (
    <div className="change_font_style">
      <div style={styles.fontWeight_bold}>FORGOT PASSWORD</div>
      <span style={styles.fontSize_85}>
        Use this form to recover your account password
      </span>
      <div style={styles.bb_ligthGrey}></div>
      <Row style={styles.mt_30}>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          EMAIL:
          <Input
            style={{ marginLeft: 20 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email"
            className="pass_change_input"
          />
        </Col>

        <Col sm="12" md="12" xs="12" lg="12" style={{ textAlign: 'center' }}>
          <Button
            onClick={recoverHandler}
            size="small"
            style={styles.changePassBtn}
          >
            {loader ? (
              <CircularProgress
                style={{ color: 'white', width: 20, height: 20 }}
              />
            ) : (
              'RECOVER'
            )}
          </Button>

          <span
            style={{
              display: 'flex',
              alignSelf: 'flex-start',
              cursor: 'pointer',
            }}
            onClick={() => props.swithToForgot(false)}
          >
            Reset Password?
          </span>
        </Col>
      </Row>
    </div>
  );
};
