import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Bulb from '../../../assets/bulb.webp';
import RightIconButton from '../../../commonComponents/RightIconButton';
import InfoIcon from '../../../commonComponents/InfoIcon';

export default function InfoCard(props) {
  return (
    <Card
      style={{
        marginBottom: 20,
        borderRadius: 5,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 8,
          left: '43%',
        }}
      >
        <InfoIcon
          onClick={() => this.setState({ modelOpen: true })}
          text={'PRO TIP'}
          icon={'Bulb'}
          height={19}
          width="80px"
          marginTop={0}
        />
      </div>

      <CardBody style={{ padding: '30px 20px 20px 20px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h6
              style={{
                color: '#576baa',
                paddingTop: 15,
              }}
            >
              {props.heading || ''}
            </h6>
            <p style={{ padding: 5, textAlign: 'left' }}>{props.message}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
