import React, { memo, useEffect, useState } from 'react';
import { Colxx } from '../../commonComponents/customBootstrap';
import { Row, InputGroup, InputGroupAddon } from 'reactstrap';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';

import LockIcon from '@material-ui/icons/Lock';
import { Button } from 'antd';
import toastr from 'toastr';
import { styles } from './style';
import UpgradeModal from '../../commonComponents/UpgradeModal';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Oval from 'react-loader-spinner';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import Searches from './searches';
import uuid from 'react-uuid';
import {
  addSearchHistory,
  deleteSearchHistory,
  getSavedSearchs,
  updateSearchHistory,
} from '../../commonRedux/jobs/action';
import { GetStepImage } from '../../utils/GetStepImage';
import moment from 'moment';
import { colors } from '../../commonComponents/colors';
import getJobTitleSuggestions from '../../utils/getJobTitleSuggestions';
import { useWindowWidth } from '@react-hook/window-size';
import { tipsList } from '../resumeBuilder/formBuilder/constants/protips';
import InfoIcon from '../../commonComponents/InfoIcon';

const searchRadius = [
  {
    value: 5,
    label: '5 miles',
  },
  {
    value: 10,
    label: '10 miles',
  },
  {
    value: 25,
    label: '25 miles',
  },
  {
    value: 50,
    label: '50 miles',
  },
  {
    value: 100,
    label: '100 miles',
  },
];

const JobLeftView = memo((props) => {
  const [country, setCountry] = useState([]);
  const [radius, setRadius] = useState([searchRadius[2]]);
  const [jobTitle, setJobTitle] = useState('');
  const dispatch = useDispatch();
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [show_skills, setShowSkills] = useState(false);
  const [skillsValue, setSkillsValue] = useState('');
  const [tags, setTags] = useState([]);
  const [saveState, setSaveState] = useState(false);
  const [multiLocations, setMultiLocations] = useState([]);
  const [editState, setEditState] = React.useState(false);
  const [searchName, setSearchName] = React.useState('');
  const [places, setPlaces] = useState('');
  const [al, setAl] = useState('');
  const user = useSelector((state) => state.authUser.user);
  const searchList = useSelector((state) => state.jobRducer.searchList);
  const handleChange = (address) => {
    setPlaces(address);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: 13,
      border: state.isFocused ? '0.5px solid #4267b3' : '0.5px solid #ccc',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '0.5px solid #ccc',
        boxShadow: 'none',
      },
      '&:onFocus': {
        border: '0.5px solid #4267b3',
        boxShadow: 'none',
      },
    }),
    menuList: (provider) => ({
      ...provider,
      height: al.length > 0 ? '120px' : '70px',
      overflowY: 'auto',
    }),
    noOptionsMessage: (provider) => ({
      ...provider,
      marginTop: '11px',
    }),
    loadingMessage: (provider) => ({
      ...provider,
      marginTop: '40px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 13,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#4267b3' : 'white',
      '&:hover': {
        backgroundColor: '#4267b3',
        color: 'white',
      },
    }),

    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: 13,
      backgroundColor: colors.blue,
      color: colors.white,
      borderRadius: '5px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: 13,
      color: colors.white,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled ? 'red' : blue,
        color: '#000',
        fontSize: 13,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
  };
  const components = {
    DropdownIndicator: null,
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        if (multiLocations.length) {
          setMultiLocations(() => [...multiLocations]);
        }
        setMultiLocations([results[0].address_components[0].short_name]);
      })

      .catch((error) => console.error('Error', error));
  };

  const saveSearchCB = () => {
    setSaveState(false);
    setSearchName('');
  };

  const handleSearchSave = (searchName, emailDaily) => {
    const isNamePresent = searchList?.some(
      (item) =>
        item?.searchName?.toLowerCase()?.trim() ===
        searchName?.toLowerCase()?.trim(),
    );
    if (isNamePresent) {
      toastr.error('Search name already exists');
      return;
    }
    if (!searchName) {
      toastr.error('Please enter search name');
      return;
    } else {
      let searchObj = {
        searchName,
        emailDaily,
        jobTitles: country,
        radius,
        jobTypes: selectedJobTypes,
        skills: tags,
        location: multiLocations,
        userId: user._id,
        id: uuid(),
      };
      dispatch(addSearchHistory(searchObj, saveSearchCB));
    }
  };

  const deleteHandler = (id) => {
    dispatch(deleteSearchHistory(id));
  };

  const updateHandler = (searchName, emailDaily, id) => {
    const isNamePresent = searchList?.some(
      (item) =>
        item?.searchName?.toLowerCase()?.trim() ===
          searchName?.toLowerCase()?.trim() && item?.id !== id,
    );
    if (isNamePresent) {
      toastr.error('Search name already exists');
      return;
    }
    if (!searchName) {
      toastr.error('Please enter search name');
      return;
    }
    let data = {
      searchName,
      emailDaily,
      jobTitles: country,
      radius,
      jobTypes: selectedJobTypes,
      skills: tags,
      location: multiLocations,
      userId: user._id,
      id: id,
    };
    dispatch(updateSearchHistory(data, setEditState));
  };

  React.useEffect(() => {
    dispatch(getSavedSearchs());
  }, []);

  React.useEffect(() => {
    if (user?.type) {
      if (user?.type?.job_location?.length) {
        setMultiLocations(
          user?.type?.job_location?.map((n) => ({ value: n, label: n })),
        );
      }
      if (user?.type?.skills?.length) {
        setTags(user?.type?.skills);
      }
      if (user?.type?.job_titles?.length) {
        setCountry(user?.type?.job_titles);
      }
      if (user?.type?.job_type) {
        setSelectedJobTypes(user?.type?.job_type);
      }
    } else if (props.userLatestScan) {
      const resume_info = props.userLatestScan?.resume;
      // if (resume_info?.other_info?.job_titles) {
      //   setCountry([...new Set(resume_info?.other_info?.job_titles)]);ْْْ
      // }
      if (
        resume_info?.other_info?.other_contact_methods?.PostalAddress
          ?.Municipality
      ) {
        // setMultiLocations([
        //   resume_info?.other_info?.other_contact_methods?.PostalAddress
        //     ?.Municipality,
        // ]);
      } else if (
        (resume_info?.other_info?.other_contact_methods?.employ || []).length
      ) {
        const filters = resume_info?.other_info?.other_contact_methods?.employ
          ?.map((n) => n['Municipality'])
          ?.map((n) => ({ value: n, label: n }));
        setMultiLocations(filters);
      }
    }
  }, [props.userLatestScan, user]);
  const handleJobType = (e) => {
    if (selectedJobTypes?.length == 0) {
      let d = [];
      d.push(e.target.name);

      setSelectedJobTypes(d);
    } else {
      let checkObj = selectedJobTypes.find((item) => item === e.target.name);
      if (checkObj) {
        let d = [];
        d = selectedJobTypes.filter((item) => item !== e.target.name);
        setSelectedJobTypes(d);
      } else {
        setSelectedJobTypes([...selectedJobTypes, e.target.name]);
      }
    }
  };

  const setAllState = (data) => {
    setCountry(data?.jobTitles);
    setSelectedJobTypes(data?.selectedJobTypes);
    setTags(data?.skills);
    setMultiLocations(data?.location);
    setRadius(data?.radius);
  };

  const hasValidJobType = (jobType) => {
    const validJobTypes = ['Full-Time', 'Contract', 'Part-Time'];
    return validJobTypes.includes(jobType);
  };

  const hasValidLocationType = (jobType) => {
    const validLocationTypes = ['Onsite', 'Remote', 'Hybrid'];
    return validLocationTypes.includes(jobType);
  };

  const handleSearch = () => {
    if (!country?.length) {
      toastr.error('Please enter atleast one job title!');
      return;
    }
    if (!selectedJobTypes?.some(hasValidJobType)) {
      toastr.error('Please select atleast one job type!');
      return;
    }
    if (!selectedJobTypes?.some(hasValidLocationType)) {
      toastr.error('Please select at least one location');
      return;
    }
    if (!tags?.length) {
      toastr.error('Please enter atleast one Skill!');
      return;
    }
    if (!multiLocations?.length) {
      toastr.error('Please enter atleast one city!');
      return;
    }
    if (!radius?.value) {
      toastr.error('Please select atleast one search radius!');
      return;
    }

    props.toggleSearches();
    props.getskills(true);
    setSaveState(true);
    setSearchName(
      `${
        country.length
          ? `${country[0]}_${multiLocations[0]?.value}_${moment(
              new Date(),
            ).format('MMDDYY')}`.replaceAll(' ', '')
          : `${multiLocations[0]?.value}_${moment(new Date()).format(
              'MMDDYY',
            )}`.replaceAll(' ', '')
      }`,
    );

    let data = {
      country: country || [],
      city: multiLocations?.map((n) => n.value) || [],
      jobType: tags || [],
      miles: radius || [],
    };

    props.getJobs(data, selectedJobTypes);
  };

  const searchJobs = (searchData) => {
    props.toggleSearches();
    props.getskills(true);
    let data = {
      country: searchData?.jobTitles || [],
      city: searchData.location.map((n) => n.value) || [],
      jobType: '',
      miles: searchData?.radius || [],
    };
    props.getJobs(data, searchData?.jobTypes);
  };

  const tabTitleStyle = {
    fontSize: '1rem',
    fontWeight: '700',
    whiteSpace: 'nowrap',
    border: '1px solid #e6e6e6',
    padding: '0 9px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
  };
  const width = useWindowWidth();
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '1400px',
        // margin: '0 auto',
      }}
    >
      <style jsx>
        {`
          .react-tagsinput {
            height: 40px;
          }
        `}
      </style>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 20,
          ...(width < 950 && {
            flexDirection: 'column-reverse',
          }),
        }}
      >
        <div
          style={{
            width: '75%',
            ...(width < 950 && {
              width: '100%',
            }),
          }}
        >
          {' '}
          <Colxx lg="12">
            <div>
              <div>
                <h5 style={{ ...styles.h5, paddingTop: 8, paddingBottom: 6 }}>
                  Job Title
                </h5>
                <AsyncSelect
                  styles={customStyles}
                  isClearable
                  // isMulti
                  loadOptions={(inputVal) =>
                    new Promise(async (resolve) => {
                      const titleRes = await getJobTitleSuggestions(inputVal);
                      resolve(titleRes?.length ? titleRes : []);
                    })
                  }
                  // menuIsOpen={false}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setCountry([selectedOption.value]);
                    } else {
                      setCountry([]);
                    }
                  }}
                  onInputChange={(inputValue) => {
                    setJobTitle(inputValue);
                    setAl(inputValue);
                  }}
                  isCreatable={false}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && jobTitle.length > 0) {
                      setCountry([jobTitle]);
                      setJobTitle('');
                    }
                  }}
                  inputValue={jobTitle}
                  placeholder="Enter a Job Title or a Keyword"
                  value={
                    country?.length
                      ? [{ value: country[0], label: country[0] }]
                      : null
                  }
                />
              </div>
            </div>
          </Colxx>
          <Colxx lg="12" xs="12" sm="12" md="12">
            <div>
              <h5 style={{ ...styles.h5, paddingTop: 20, paddingBottom: 6 }}>
                Job Type
              </h5>
              <FormGroup
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  maxWidth: '400px',
                  gap: '20px',
                }}
              >
                <FormControlLabel
                  style={{
                    margin: 0,
                    width: 100,
                  }}
                  control={
                    <Checkbox
                      checked={selectedJobTypes?.includes('Full-Time')}
                      onChange={handleJobType}
                      name="Full-Time"
                      color="primary"
                      style={{
                        padding: 0,
                      }}
                    />
                  }
                  label="Full-Time"
                />
                <FormControlLabel
                  style={{
                    margin: 0,
                    width: 100,
                  }}
                  control={
                    <Checkbox
                      checked={selectedJobTypes?.some((v) => v === 'Contract')}
                      onChange={handleJobType}
                      name="Contract"
                      color="primary"
                      style={{
                        padding: 0,
                      }}
                    />
                  }
                  label="Contract"
                />
                <FormControlLabel
                  style={{
                    margin: 0,
                    width: 100,
                  }}
                  control={
                    <Checkbox
                      checked={selectedJobTypes?.some((v) => v === 'Part-Time')}
                      onChange={handleJobType}
                      name="Part-Time"
                      color="primary"
                      style={{
                        padding: 0,
                      }}
                    />
                  }
                  label="Part-Time"
                />
              </FormGroup>
            </div>
          </Colxx>
          <Colxx lg="12" xs="12" sm="12" md="12">
            <div>
              <h5 style={{ ...styles.h5, paddingTop: 20, paddingBottom: 6 }}>
                Location
              </h5>
              <FormGroup
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  maxWidth: '400px',
                  gap: '20px',
                }}
              >
                <FormControlLabel
                  style={{
                    margin: 0,
                    width: 100,
                  }}
                  control={
                    <Checkbox
                      checked={selectedJobTypes?.some((v) => v === 'Onsite')}
                      onChange={handleJobType}
                      name="Onsite"
                      color="primary"
                      style={{
                        padding: 0,
                      }}
                    />
                  }
                  label="Onsite"
                />
                <FormControlLabel
                  style={{
                    margin: 0,
                    width: 100,
                  }}
                  control={
                    <Checkbox
                      checked={selectedJobTypes?.some((v) => v === 'Remote')}
                      onChange={handleJobType}
                      name="Remote"
                      color="primary"
                      style={{
                        padding: 0,
                      }}
                    />
                  }
                  label="Remote"
                />
                <FormControlLabel
                  style={{
                    margin: 0,
                    width: 100,
                  }}
                  control={
                    <Checkbox
                      checked={selectedJobTypes?.some((v) => v === 'Hybrid')}
                      onChange={handleJobType}
                      name="Hybrid"
                      color="primary"
                      style={{
                        padding: 0,
                      }}
                    />
                  }
                  label="Hybrid"
                />
              </FormGroup>
            </div>
          </Colxx>
          <Colxx lg="12" xs="12" sm="12" md="12">
            <div>
              <h5 style={{ ...styles.h5, paddingTop: 20, paddingBottom: 6 }}>
                Skills
              </h5>
              <CreatableSelect
                components={components}
                styles={customStyles}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(tags) => {
                  if (show_skills) {
                    setShowSkills(false);
                  }
                  setTags(tags ? [...tags.map((n) => n.value)] : []);
                }}
                onInputChange={(e) => {
                  if (!show_skills) {
                    setShowSkills(true);
                  }
                  setSkillsValue(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && skillsValue.length > 0) {
                    setTags([...tags, skillsValue]);
                    setSkillsValue('');
                  }
                }}
                inputValue={skillsValue}
                placeholder="Add Skills and press ENTER"
                value={
                  tags?.length
                    ? [
                        ...tags.map((n) => {
                          return { value: n, label: n };
                        }),
                      ]
                    : []
                }
              />
            </div>
          </Colxx>
          <Colxx lg="12" xs="12" sm="12" md="12">
            <PlacesAutocomplete
              value={places}
              onChange={handleChange}
              onSelect={handleSelect}
              searchOptions={{
                types: ['(cities)'],
                componentRestrictions: { country: 'us' },
              }}
            >
              {({ getInputProps, suggestions, loading }) => (
                <div style={{ paddingBottom: 16 }}>
                  <h5
                    style={{ ...styles.h5, paddingTop: 20, paddingBottom: 8 }}
                  >
                    City
                  </h5>
                  <Select
                    // isMulti
                    isLoading={loading}
                    options={suggestions.map((n) => {
                      return {
                        label: n.description,
                        value: n.description,
                      };
                    })}
                    styles={customStyles}
                    {...getInputProps({
                      placeholder: 'Enter city name and select from list',
                      // className: 'location-search-input',
                    })}
                    value={multiLocations[0]}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setMultiLocations([selectedOption]);
                      } else {
                        setMultiLocations([]);
                      }
                    }}
                    onInputChange={(text) => {
                      getInputProps().onChange({ target: { value: text } });
                      setAl(text);
                    }}
                  />
                </div>
              )}
            </PlacesAutocomplete>
          </Colxx>
          {/* <Colxx lg="6" xs="6" sm="6" md="6">
        
      </Colxx> */}
          {/* <Colxx lg="12" xs="12" sm="12" md="12">
        <div>
          <h5 style={{ ...styles.h5, paddingTop: 8, paddingBottom: 6 }}>
            Search Radius
          </h5>
          <Select
            placeholder={'Select radius from list'}
            isMulti
            styles={customStyles}
            options={searchRadius}
            value={radius}
            onChange={(rad) => {
              setRadius(rad);
            }}
          />
        </div>
      </Colxx> */}
          <Colxx lg="12" xs="12" sm="12" md="12">
            <div>
              <h5 style={{ ...styles.h5, paddingTop: 8, paddingBottom: 6 }}>
                Search Radius
              </h5>
              <FormGroup
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                {searchRadius?.map((radiusData, index) => {
                  return (
                    <FormControlLabel
                      key={index + radiusData?.value}
                      style={{
                        margin: 0,
                        width: 100,
                      }}
                      control={
                        <Checkbox
                          checked={radius.value === radiusData?.value}
                          onChange={(tempEvent) => {
                            console.log('tempEvent', tempEvent);
                            setRadius(radiusData);
                          }}
                          name="Full-Time"
                          color="primary"
                          style={{
                            padding: 0,
                          }}
                        />
                      }
                      label={radiusData?.label}
                    />
                  );
                })}
              </FormGroup>
            </div>
          </Colxx>
          <Colxx
            style={{
              display: 'flex',
              alignSelf: 'flex-end',
            }}
            lg="3"
            sm="12"
            xs="12"
            md="12"
          >
            {editState ? (
              ''
            ) : (
              <div
                style={{
                  position: 'relative',
                  marginTop: 20,
                  paddingTop: 20,
                  paddingBottom: 6,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 3,
                    zIndex: 1,
                  }}
                >
                  <GetStepImage step={1} />
                </div>
                <Button
                  disabled={props.loader ? true : false}
                  style={{ ...styles.btn, height: 38, borderRadius: 5 }}
                  onClick={handleSearch}
                >
                  {props.loader ? (
                    <Oval
                      type="Oval"
                      color="white"
                      height={20}
                      width={20}
                      style={{ paddingLeft: 25, paddingRight: 25 }}
                    />
                  ) : (
                    'Search Now'
                  )}
                </Button>
              </div>
            )}
          </Colxx>
        </div>
        <div
          style={{
            maxWidth: 350,
            marginRight: 10,
            ...(width < 950 && {
              maxWidth: '100%',
              marginRight: 0,
            }),
          }}
        >
          <div className="proTip-container">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '-10px',
              }}
            >
              <InfoIcon width={80} />
            </div>
            <div
              style={{
                padding: '20px 20px 5px',
                boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.2)',
                minHeight: 'calc(250px - 15px)',
                borderRadius: '8px',
              }}
            >
              {tipsList[16]?.length
                ? tipsList[16]?.map((tip, index) => (
                    <div key={index}>
                      <p
                        style={{
                          marginBottom: '10px',
                          lineHeight: '1rem',
                          fontSize: '14px',
                        }}
                      >
                        <span
                          style={{
                            color: colors.blue,
                            fontWeight: 'bold',
                            fontSize: '13px',
                          }}
                        >
                          ⦁{' '}
                        </span>
                        {tip}
                      </p>
                    </div>
                  ))
                : ''}
            </div>
          </div>
        </div>
      </div>
      {
        <Colxx lg="12">
          <Searches
            setSaveState={setSaveState}
            saveState={saveState}
            handleSearchSave={handleSearchSave}
            savedSearches={searchList}
            setAllState={setAllState}
            searchJobs={searchJobs}
            deleteHandler={deleteHandler}
            updateHandler={updateHandler}
            editState={editState}
            setEditState={setEditState}
            searchName={searchName}
            setSearchName={setSearchName}
            filterDate={props.filterDate}
            setFilterDate={props.setFilterDate}
          />
        </Colxx>
      }
    </div>
  );
});

export default JobLeftView;
