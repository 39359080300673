import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Text } from '../../component';
import './style.scss';
import { onBlurField } from '../../redux/actions';
import Skills from './skills';
import AdditionalSkills from './additional_skills';
import WorkExperience from './workExperience';
import EducationHistory from './educationHistory';
import { sampleData } from '../../redux/sample';
import { styles } from './style';
import Profile from './profile';
import Certifications from './certifications';
import Associations from './associations';
import Trainings from './trainings';
import Achievements from './achievements';
import Projects from './projects';
import Patents from './patents';
import Publications from './publications';
import Speakings from './speakings';
import Security from './security';
import Military from './military';
import Courses from './courses';
import RichEditor from '../../formBuilder/helper/RichEditor';
import { Row } from 'antd';

class Manager extends Component {
  render() {
    const { resumeData, updater, width } = this.props;

    return (
      <>
        {this.props.templateSelect === 0 ? (
          <>
            <div className={'manager-template-wrapper '}>
              <div className={width < 500 ? '' : 'a4Paper'}>
                <div>
                  <Profile templateSelect={this.props.templateSelect} />
                  {resumeData?.objective?.visible && (
                    <div className="manager-template-col manager-heading">
                      <Text
                        value={resumeData?.objective?.heading}
                        placeholder="SUMMARY"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'objective.heading'}
                        editable={false}
                      />

                      <RichEditor
                        value={resumeData?.objective?.body}
                        enableInline
                        placeHolder="Please write summary of your experience, achievement and skills."
                        path={'objective.body'}
                        onBlurField={onBlurField}
                        heightMax={true}
                      />
                    </div>
                  )}
                  {resumeData?.work?.visible && (
                    <>
                      <div className="manager-template-col manager-fHeading">
                        <Text
                          value={resumeData?.work?.heading}
                          placeholder="Experience"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'experience.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <WorkExperience
                          data={
                            resumeData?.work?.items.length
                              ? [...resumeData?.work?.items]
                              : [...sampleData?.work?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </div>
                    </>
                  )}

                  {resumeData?.education?.visible && (
                    <>
                      <Text
                        value={resumeData?.education?.heading}
                        placeholder="EDUCATION"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'education.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <EducationHistory
                        data={
                          resumeData?.education?.items.length
                            ? [...resumeData?.education?.items]
                            : [...sampleData?.education?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.courses?.visible && (
                    <>
                      <Text
                        value={resumeData?.courses?.heading}
                        placeholder="Courses"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'courses.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Courses
                        data={
                          resumeData?.courses?.items.length
                            ? [...resumeData?.courses?.items]
                            : [...sampleData?.courses?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}
                  {resumeData?.certifications?.visible && (
                    <>
                      <Text
                        value={resumeData?.certifications?.heading}
                        placeholder="Certifications"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'certifications.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Certifications
                        data={
                          resumeData?.certifications?.items.length
                            ? [...resumeData?.certifications?.items]
                            : [...sampleData?.certifications?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}
                  {resumeData?.skills?.visible && (
                    <>
                      <Text
                        value={resumeData?.skills?.heading || 'Skills'}
                        placeholder="Skills"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'skills.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Skills
                        data={
                          resumeData?.skills?.items?.length
                            ? [...resumeData?.skills?.items]
                            : [...sampleData?.skills?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.associations?.visible && (
                    <>
                      <Text
                        value={resumeData?.associations?.heading}
                        placeholder="Associations"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'associations.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Associations
                        data={
                          resumeData?.associations?.items.length
                            ? [...resumeData?.associations?.items]
                            : [...sampleData?.associations?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.training?.visible && (
                    <>
                      <Text
                        value={resumeData?.training?.heading}
                        placeholder="Trainings"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'training.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Trainings
                        data={
                          resumeData?.training?.items.length
                            ? [...resumeData?.training?.items]
                            : [...sampleData?.training?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.achievements?.visible && (
                    <>
                      <Text
                        value={resumeData?.achievements?.heading}
                        placeholder="Achievements"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'achievements.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Achievements
                        data={
                          resumeData?.achievements?.items.length
                            ? [...resumeData?.achievements?.items]
                            : [...sampleData?.achievements?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.projects?.visible && (
                    <>
                      <Text
                        value={resumeData?.projects?.heading}
                        placeholder="Projects"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'projects.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Projects
                        data={
                          resumeData?.projects?.items.length
                            ? [...resumeData?.projects?.items]
                            : [...sampleData?.projects?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.patents?.visible && (
                    <>
                      <Text
                        value={resumeData?.patents?.heading}
                        placeholder="Patents"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'patents.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      {/* TODO: Fix this */}
                      <Patents
                        data={
                          resumeData?.patents?.items?.length
                            ? resumeData?.patents
                            : [...sampleData?.patents?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.publications?.visible && (
                    <>
                      <Text
                        value={resumeData?.publications?.heading}
                        placeholder="Publications"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'publications.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Publications
                        data={
                          !resumeData?.publications?.items.length
                            ? [...sampleData?.publications?.items]
                            : Array.isArray(resumeData?.publications?.items)
                            ? [...resumeData?.publications?.items]
                            : [resumeData?.publications?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.speaking_engagements?.visible && (
                    <>
                      <Text
                        value={resumeData?.speaking_engagements?.heading}
                        placeholder="Speaking Engagements"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'speaking_engagements.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Speakings
                        data={
                          resumeData?.speaking_engagements?.items.length
                            ? [...resumeData?.speaking_engagements?.items]
                            : [...sampleData?.speaking_engagements?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.security_creds?.visible && (
                    <>
                      <Text
                        value={resumeData?.security_creds?.heading}
                        placeholder="Security Credentials"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'security_creds.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Security
                        data={
                          resumeData?.security_creds?.items.length
                            ? [...resumeData?.security_creds?.items]
                            : [...sampleData?.security_creds?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}

                  {resumeData?.military?.visible && (
                    <>
                      <Text
                        value={resumeData?.military?.heading}
                        placeholder="Military"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'military.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <Military
                        data={
                          resumeData?.military?.items.length
                            ? [...resumeData?.military?.items]
                            : [...sampleData?.military?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </>
                  )}
                </div>

                {resumeData?.custom?.visible && (
                  <>
                    <div className="manager-template-col manager-heading">
                      <Text
                        value={resumeData?.custom?.title}
                        placeholder="Heading"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'custom.heading'}
                        editable={true}
                      />
                      <Text
                        value={resumeData?.custom?.body}
                        placeholder="Custom Detail"
                        customclass={'manager-fName manager-template-text-bold'}
                        path={'custom.body'}
                      />
                    </div>
                  </>
                )}

                {resumeData?.additional_skills?.visible && (
                  <>
                    <div className="manager-template-col manager-fHeading">
                      <Text
                        value={resumeData?.additional_skills?.heading}
                        placeholder="Experience"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'additional_skills.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <AdditionalSkills
                        data={
                          resumeData?.additional_skills?.items.length
                            ? [...resumeData?.additional_skills?.items]
                            : [...sampleData?.additional_skills?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : this.props.templateSelect === 1 ? (
          <>
            <div className={'manager-template-wrapper '}>
              <div className={width < 500 ? '' : 'a4Paper'}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      width: 300,
                    }}
                  >
                    <Profile templateSelect={this.props.templateSelect} />
                  </div>
                  <div
                    style={{
                      width: 700,
                    }}
                  >
                    {resumeData?.objective?.visible && (
                      <div className="manager-template-col manager-heading">
                        <Text
                          value={resumeData?.objective?.heading}
                          placeholder="SUMMARY"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'objective.heading'}
                          editable={false}
                        />

                        <RichEditor
                          value={resumeData?.objective?.body}
                          enableInline
                          placeHolder="Please write summary of your experience, achievement and skills."
                          path={'objective.body'}
                          onBlurField={onBlurField}
                          heightMax={true}
                        />
                      </div>
                    )}
                    {resumeData?.work?.visible && (
                      <>
                        <div className="manager-template-col manager-fHeading">
                          <Text
                            value={resumeData?.work?.heading}
                            placeholder="Experience"
                            customclass={
                              this.props.templateSelect === 1
                                ? 'manager-fHeading-title1'
                                : 'manager-fHeading-title'
                            }
                            path={'experience.heading'}
                            editable={false}
                            style={styles.workHeading}
                          />
                          <WorkExperience
                            data={
                              resumeData?.work?.items.length
                                ? [...resumeData?.work?.items]
                                : [...sampleData?.work?.items]
                            }
                            color={this.props.theme.color}
                            updater={updater}
                          />
                        </div>
                      </>
                    )}

                    {resumeData?.education?.visible && (
                      <>
                        <Text
                          value={resumeData?.education?.heading}
                          placeholder="EDUCATION"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'education.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <EducationHistory
                          data={
                            resumeData?.education?.items.length
                              ? [...resumeData?.education?.items]
                              : [...sampleData?.education?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.courses?.visible && (
                      <>
                        <Text
                          value={resumeData?.courses?.heading}
                          placeholder="Courses"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'courses.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Courses
                          data={
                            resumeData?.courses?.items.length
                              ? [...resumeData?.courses?.items]
                              : [...sampleData?.courses?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}
                    {resumeData?.certifications?.visible && (
                      <>
                        <Text
                          value={resumeData?.certifications?.heading}
                          placeholder="Certifications"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'certifications.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Certifications
                          data={
                            resumeData?.certifications?.items.length
                              ? [...resumeData?.certifications?.items]
                              : [...sampleData?.certifications?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}
                    {resumeData?.skills?.visible && (
                      <>
                        <Text
                          value={resumeData?.skills?.heading || 'Skills'}
                          placeholder="Skills"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'skills.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Skills
                          data={
                            resumeData?.skills?.items?.length
                              ? [...resumeData?.skills?.items]
                              : [...sampleData?.skills?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.associations?.visible && (
                      <>
                        <Text
                          value={resumeData?.associations?.heading}
                          placeholder="Associations"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'associations.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Associations
                          data={
                            resumeData?.associations?.items.length
                              ? [...resumeData?.associations?.items]
                              : [...sampleData?.associations?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.training?.visible && (
                      <>
                        <Text
                          value={resumeData?.training?.heading}
                          placeholder="Trainings"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'training.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Trainings
                          data={
                            resumeData?.training?.items.length
                              ? [...resumeData?.training?.items]
                              : [...sampleData?.training?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.achievements?.visible && (
                      <>
                        <Text
                          value={resumeData?.achievements?.heading}
                          placeholder="Achievements"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'achievements.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Achievements
                          data={
                            resumeData?.achievements?.items.length
                              ? [...resumeData?.achievements?.items]
                              : [...sampleData?.achievements?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.projects?.visible && (
                      <>
                        <Text
                          value={resumeData?.projects?.heading}
                          placeholder="Projects"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'projects.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Projects
                          data={
                            resumeData?.projects?.items.length
                              ? [...resumeData?.projects?.items]
                              : [...sampleData?.projects?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.patents?.visible && (
                      <>
                        <Text
                          value={resumeData?.patents?.heading}
                          placeholder="Patents"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'patents.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        {/* TODO: Fix this */}
                        <Patents
                          data={
                            resumeData?.patents?.items?.length
                              ? resumeData?.patents
                              : [...sampleData?.patents?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.publications?.visible && (
                      <>
                        <Text
                          value={resumeData?.publications?.heading}
                          placeholder="Publications"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'publications.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Publications
                          data={
                            !resumeData?.publications?.items.length
                              ? [...sampleData?.publications?.items]
                              : Array.isArray(resumeData?.publications?.items)
                              ? [...resumeData?.publications?.items]
                              : [resumeData?.publications?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.speaking_engagements?.visible && (
                      <>
                        <Text
                          value={resumeData?.speaking_engagements?.heading}
                          placeholder="Speaking Engagements"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'speaking_engagements.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Speakings
                          data={
                            resumeData?.speaking_engagements?.items.length
                              ? [...resumeData?.speaking_engagements?.items]
                              : [...sampleData?.speaking_engagements?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.security_creds?.visible && (
                      <>
                        <Text
                          value={resumeData?.security_creds?.heading}
                          placeholder="Security Credentials"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'security_creds.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Security
                          data={
                            resumeData?.security_creds?.items.length
                              ? [...resumeData?.security_creds?.items]
                              : [...sampleData?.security_creds?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.military?.visible && (
                      <>
                        <Text
                          value={resumeData?.military?.heading}
                          placeholder="Military"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'military.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Military
                          data={
                            resumeData?.military?.items.length
                              ? [...resumeData?.military?.items]
                              : [...sampleData?.military?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}
                  </div>
                </div>

                {resumeData?.custom?.visible && (
                  <>
                    <div className="manager-template-col manager-heading">
                      <Text
                        value={resumeData?.custom?.title}
                        placeholder="Heading"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'custom.heading'}
                        editable={true}
                      />
                      <Text
                        value={resumeData?.custom?.body}
                        placeholder="Custom Detail"
                        customclass={'manager-fName manager-template-text-bold'}
                        path={'custom.body'}
                      />
                    </div>
                  </>
                )}

                {resumeData?.additional_skills?.visible && (
                  <>
                    <div className="manager-template-col manager-fHeading">
                      <Text
                        value={resumeData?.additional_skills?.heading}
                        placeholder="Experience"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'additional_skills.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <AdditionalSkills
                        data={
                          resumeData?.additional_skills?.items.length
                            ? [...resumeData?.additional_skills?.items]
                            : [...sampleData?.additional_skills?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : this.props.templateSelect === 2 ? (
          <>
            <div className={'manager-template-wrapper '}>
              <div className={width < 500 ? '' : 'a4Paper'}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    paddingTop: 20,
                    height: 100,
                    backgroundColor: '#454545',
                  }}
                >
                  <Row>
                    <Text
                      value={resumeData?.profile?.firstName}
                      placeholder="First Name"
                      customclass={'fullNameNew'}
                      path={'profile.firstName'}
                    />
                    &nbsp;
                    <Text
                      value={resumeData?.profile?.lastName}
                      placeholder="Last Name"
                      customclass={'fullNameNew'}
                      path={'profile.lastName'}
                    />
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      width: 700,
                    }}
                  >
                    {resumeData?.objective?.visible && (
                      <div className="manager-template-col manager-heading">
                        <Text
                          value={resumeData?.objective?.heading}
                          placeholder="SUMMARY"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'objective.heading'}
                          editable={false}
                        />

                        <RichEditor
                          value={resumeData?.objective?.body}
                          enableInline
                          placeHolder="Please write summary of your experience, achievement and skills."
                          path={'objective.body'}
                          onBlurField={onBlurField}
                          heightMax={true}
                        />
                      </div>
                    )}
                    {resumeData?.work?.visible && (
                      <>
                        <div className="manager-template-col manager-fHeading">
                          <Text
                            value={resumeData?.work?.heading}
                            placeholder="Experience"
                            customclass={
                              this.props.templateSelect === 1
                                ? 'manager-fHeading-title1'
                                : 'manager-fHeading-title'
                            }
                            path={'experience.heading'}
                            editable={false}
                            style={styles.workHeading}
                          />
                          <WorkExperience
                            data={
                              resumeData?.work?.items.length
                                ? [...resumeData?.work?.items]
                                : [...sampleData?.work?.items]
                            }
                            color={this.props.theme.color}
                            updater={updater}
                          />
                        </div>
                      </>
                    )}

                    {resumeData?.education?.visible && (
                      <>
                        <Text
                          value={resumeData?.education?.heading}
                          placeholder="EDUCATION"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'education.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <EducationHistory
                          data={
                            resumeData?.education?.items.length
                              ? [...resumeData?.education?.items]
                              : [...sampleData?.education?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.courses?.visible && (
                      <>
                        <Text
                          value={resumeData?.courses?.heading}
                          placeholder="Courses"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'courses.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Courses
                          data={
                            resumeData?.courses?.items.length
                              ? [...resumeData?.courses?.items]
                              : [...sampleData?.courses?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}
                    {resumeData?.certifications?.visible && (
                      <>
                        <Text
                          value={resumeData?.certifications?.heading}
                          placeholder="Certifications"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'certifications.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Certifications
                          data={
                            resumeData?.certifications?.items.length
                              ? [...resumeData?.certifications?.items]
                              : [...sampleData?.certifications?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}
                    {resumeData?.skills?.visible && (
                      <>
                        <Text
                          value={resumeData?.skills?.heading || 'Skills'}
                          placeholder="Skills"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'skills.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Skills
                          data={
                            resumeData?.skills?.items?.length
                              ? [...resumeData?.skills?.items]
                              : [...sampleData?.skills?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.associations?.visible && (
                      <>
                        <Text
                          value={resumeData?.associations?.heading}
                          placeholder="Associations"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'associations.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Associations
                          data={
                            resumeData?.associations?.items.length
                              ? [...resumeData?.associations?.items]
                              : [...sampleData?.associations?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.training?.visible && (
                      <>
                        <Text
                          value={resumeData?.training?.heading}
                          placeholder="Trainings"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'training.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Trainings
                          data={
                            resumeData?.training?.items.length
                              ? [...resumeData?.training?.items]
                              : [...sampleData?.training?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.achievements?.visible && (
                      <>
                        <Text
                          value={resumeData?.achievements?.heading}
                          placeholder="Achievements"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'achievements.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Achievements
                          data={
                            resumeData?.achievements?.items.length
                              ? [...resumeData?.achievements?.items]
                              : [...sampleData?.achievements?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.projects?.visible && (
                      <>
                        <Text
                          value={resumeData?.projects?.heading}
                          placeholder="Projects"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'projects.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Projects
                          data={
                            resumeData?.projects?.items.length
                              ? [...resumeData?.projects?.items]
                              : [...sampleData?.projects?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.patents?.visible && (
                      <>
                        <Text
                          value={resumeData?.patents?.heading}
                          placeholder="Patents"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'patents.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        {/* TODO: Fix this */}
                        <Patents
                          data={
                            resumeData?.patents?.items?.length
                              ? resumeData?.patents
                              : [...sampleData?.patents?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.publications?.visible && (
                      <>
                        <Text
                          value={resumeData?.publications?.heading}
                          placeholder="Publications"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'publications.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Publications
                          data={
                            !resumeData?.publications?.items.length
                              ? [...sampleData?.publications?.items]
                              : Array.isArray(resumeData?.publications?.items)
                              ? [...resumeData?.publications?.items]
                              : [resumeData?.publications?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.speaking_engagements?.visible && (
                      <>
                        <Text
                          value={resumeData?.speaking_engagements?.heading}
                          placeholder="Speaking Engagements"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'speaking_engagements.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Speakings
                          data={
                            resumeData?.speaking_engagements?.items.length
                              ? [...resumeData?.speaking_engagements?.items]
                              : [...sampleData?.speaking_engagements?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.security_creds?.visible && (
                      <>
                        <Text
                          value={resumeData?.security_creds?.heading}
                          placeholder="Security Credentials"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'security_creds.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Security
                          data={
                            resumeData?.security_creds?.items.length
                              ? [...resumeData?.security_creds?.items]
                              : [...sampleData?.security_creds?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}

                    {resumeData?.military?.visible && (
                      <>
                        <Text
                          value={resumeData?.military?.heading}
                          placeholder="Military"
                          customclass={
                            this.props.templateSelect === 1
                              ? 'manager-fHeading-title1'
                              : 'manager-fHeading-title'
                          }
                          path={'military.heading'}
                          editable={false}
                          style={styles.workHeading}
                        />
                        <Military
                          data={
                            resumeData?.military?.items.length
                              ? [...resumeData?.military?.items]
                              : [...sampleData?.military?.items]
                          }
                          color={this.props.theme.color}
                          updater={updater}
                        />
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      width: '300px',
                    }}
                  >
                    <Profile templateSelect={this.props.templateSelect} />
                  </div>
                </div>

                {resumeData?.custom?.visible && (
                  <>
                    <div className="manager-template-col manager-heading">
                      <Text
                        value={resumeData?.custom?.title}
                        placeholder="Heading"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'custom.heading'}
                        editable={true}
                      />
                      <Text
                        value={resumeData?.custom?.body}
                        placeholder="Custom Detail"
                        customclass={'manager-fName manager-template-text-bold'}
                        path={'custom.body'}
                      />
                    </div>
                  </>
                )}

                {resumeData?.additional_skills?.visible && (
                  <>
                    <div className="manager-template-col manager-fHeading">
                      <Text
                        value={resumeData?.additional_skills?.heading}
                        placeholder="Experience"
                        customclass={
                          this.props.templateSelect === 1
                            ? 'manager-fHeading-title1'
                            : 'manager-fHeading-title'
                        }
                        path={'additional_skills.heading'}
                        editable={false}
                        style={styles.workHeading}
                      />
                      <AdditionalSkills
                        data={
                          resumeData?.additional_skills?.items.length
                            ? [...resumeData?.additional_skills?.items]
                            : [...sampleData?.additional_skills?.items]
                        }
                        color={this.props.theme.color}
                        updater={updater}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  theme: store.editorReducer.theme,
  resumeData: store.editorReducer.resumeData,
  updater: store.editorReducer.updater,
});

export default connect(mapStateToProps, { onBlurField })(Manager);
