import React from 'react';
import { Text, Dnd } from '../../component';
import { Grid } from '@material-ui/core';
import { useStyles } from '../style';
import { useDispatch } from 'react-redux';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import RichEditor from '../helper/RichEditor';
import { colors } from '../../../../commonComponents/colors';

export default function Speakings(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'speaking_engagements.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;
  return (
    <>
    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
      <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Speaking Engagements
          </label>
         
        </div>
      </Grid>
    <Dnd
      data={data}
      reorder={(e) => onOrderUpdate(e)}
      additem={_addNewItem}
      removeitem={(index) => _removeItem(index)}
      renderItem={(item, index) => (
        <div>
          <div>
            <label className={classes.label}>Name</label>
            <Text
              value={item.EventName}
              placeholder="Event Name"
              customclass={classes.input}
              path={`${path}.${index}.EventName`}
            />
          </div>
          <div>
            <label className={classes.label}>Event Type</label>
            <Text
              value={item.EventType}
              placeholder="Ex: conference"
              customclass={classes.input}
              path={`${path}.${index}.EventType`}
            />
          </div>
          <div>
            <label className={classes.label}>Description</label>
            {/* <Text
              value={item.Description}
              placeholder="Main Speaker, AYA Forum, 2006"
              path={`${path}.${index}.Description`}
              customclass={classes.input}
            /> */}
            <RichEditor
              value={item.Description}
              placeHolder="Description"
              customclass={`${classes.input} ${classes.summaryInput}`}
              path={`${path}.${index}.Description`}
              onBlurField={onBlurField}
            />
          </div>
          {data.length > 1 && (
            <hr style={{ borderTop: 'dashed 1px #9E9E9E', marginTop: 20 }} />
          )}
        </div>
      )}
    />
        <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more speaking Engagement
      </div> 
    </>
  );
}
