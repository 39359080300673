import React, { memo } from 'react';
import SelectTemplate from './selectTemplate';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useDispatch } from 'react-redux';
import { clearStyles, changeTheme } from '../../redux/actions';
import { useWindowSize } from '@react-hook/window-size';
import { jsStyles } from './style';
import { confirmAlert } from 'react-confirm-alert';
import './style.css';
import { Edit } from '@material-ui/icons';
import { Button, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default memo((props) => {
  const dispatch = useDispatch();

  const [width] = useWindowSize();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const changeTemp = (name) => {
    dispatch(changeTheme(name));
  };

  const changeText = (type, param = null) => {
    document.execCommand(type, false, param);
  };

  const addLink = () => {
    var sLnk = prompt('Write the URL here', 'http://');
    if (sLnk && sLnk != '' && sLnk != 'http://') {
      changeText('createlink', sLnk);
    }
  };

  const clearTemplate = () => {
    dispatch(clearStyles());
  };

  return (
    <div id="manipulationStripe" style={jsStyles.stripeDiv(width)}>
      <div style={jsStyles.mainDiv}>
        <div style={jsStyles.nameDiv}>
          <div style={jsStyles.name}>{props.resumeName}</div>
          <Edit
            style={{
              fontSize: '16px',
              marginLeft: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              props.setShowResumeNameModal(true);
            }}
          />
        </div>
        <div
          className="px-5 py-1 d-flex flex-row justify-content-around"
          style={jsStyles.toolsMain}
        >
          <button
            title="bold"
            className="e-icons boldIcon mr-3 bgTransparent"
            onClick={() => changeText('bold')}
          ></button>
          <button
            title="italic"
            className="e-icons italicIcon mr-3 bgTransparent"
            onClick={() => changeText('italic')}
          ></button>
          <button
            title="underline"
            className="e-icons underlineIcon mr-3 bgTransparent"
            onClick={() => changeText('underline')}
          ></button>
          <button
            title="justifyl left"
            className="e-icons textAlignLeft mr-3 bgTransparent"
            onClick={() => changeText('justifyleft')}
          ></button>
          <button
            title="justify center"
            className="e-icons textAlignCenter mr-3 bgTransparent"
            onClick={() => changeText('justifycenter')}
          ></button>
          <button
            title="justify right"
            className="e-icons textAlignRight mr-3 bgTransparent"
            onClick={() => changeText('justifyright')}
          ></button>
          <button
            title="insert un-ordered list"
            className="fas fa-list-ul mr-3 bgTransparent"
            onClick={() => changeText('insertunorderedlist')}
          ></button>

          <button
            title="insert ordered list"
            className="fas fa-list-ol mr-3 bgTransparent"
            onClick={() => changeText('insertorderedlist')}
          ></button>

          <button
            title="add link"
            className="e-icons hyperLink mr-3 bgTransparent"
            onClick={addLink}
          ></button>
          <button
            title="add indentation"
            className="fas fa-indent mr-3 bgTransparent"
            onClick={() => changeText('indent')}
          ></button>
          <button
            title="delete indentation"
            className="fas fa-indent mr-3 bgTransparent"
            onClick={() => changeText('outdent')}
          ></button>
          <button
            title="clear"
            className="fas fa-broom mr-3 bgTransparent"
            onClick={() => {
              confirmAlert({
                title: 'Clear Data',
                message: 'Are you sure to delete the data?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                      clearTemplate();
                    },
                  },
                  {
                    label: 'No',
                  },
                ],
              });
            }}
          ></button>
          <span
            title={props.showToolTip ? 'Hide Tooltip' : 'Show Tooltip'}
            style={jsStyles.flexCenter}
            onClick={() => {
              props.handleShowToolTip();
            }}
          >
            {props.showToolTip ? (
              <span className="fas fa-eye-slash mr-3"></span>
            ) : (
              <span className="fas fa-eye mr-3"></span>
            )}
          </span>
        </div>
        <div style={jsStyles.width_100px}>&nbsp;</div>
      </div>
    </div>
  );
});
