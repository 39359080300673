import React, { Component } from 'react';
import { Row, Card, CardBody, Table, CardTitle } from 'reactstrap';
import { Colxx } from '../../commonComponents/customBootstrap';
import { MdCheck, MdClear } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import ModalRescan from '../save&rescan-modal';
import { connect } from 'react-redux';
import { getAvoidWords } from '../../commonRedux/scan/action';
import { jobTitles } from '../scan/jobTitle';
import { styles } from '../save&rescan-modal/style';
import { colors } from '../../commonComponents/colors';

class JobRequirementsMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveavoidWords: [],
      isJobFound: false,
      width: window.innerWidth ?? 0,
      height: window.innerHeight ?? 0,
    };
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    this.props.getAvoidWords();
    let newData = jobTitles.filter((i) => {
      return i === this.props.title;
    });
    if (newData.length) {
      this.setState({ isJobFound: true });
    } else {
      this.setState({ isJobFound: false });
    }
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.avoidWords) {
      return {
        saveavoidWords: nextProps.avoidWords,
      };
    }
  }
  render() {
    let headingStyle = {};
    // let subHeadingStyle = { verticalAlign: 'center' };
    let CardStyles = {};
    if (this.props.showOnlyMissing) {
      headingStyle = { fontSize: 16 };
      // subHeadingStyle = { fontSize: 12 };
      CardStyles = {
        paddingTop: 15,
        paddingBottom: 15,
      };
    }
    return (
      <div>
        <Row>
          <Colxx xxs="12" sm="12" lg="12">
            <Card className="mb-2">
              <CardBody
                style={{
                  ...styles.recruiter_cardBody,
                  background: '#EDF0F7',
                  border: '1px solid lightgray',
                  ...CardStyles,
                }}
              >
                <CardTitle
                  style={{
                    maxWidth: this.state.width < 500 ? '220px' : '400px',
                    ...styles.fontWeight_heading,
                    ...headingStyle,
                    marginBottom: 7,
                  }}
                >
                  JOB REQUIREMENTS MATCH
                  <div style={{ position: 'absolute', right: 15, top: 20 }}>
                    {this.props.fromState === 'yes' ? null : (
                      <ModalRescan
                        goBuilder={this.props.goBuilder}
                        percent={this.props.totalScore}
                        skills={this.props.skills}
                        description={{
                          resume: this.props.resume,
                          job: this.props.job,
                          is_parsed: this.props.is_parsed,
                        }}
                        oid={this.props.oid}
                        resumeId={this.props.resumeId}
                        company={this.props.company}
                        jobTitle={this.props.jobTitle}
                      />
                    )}
                  </div>
                </CardTitle>
                <Table style={{ tableLayout: 'fixed', overflow: 'auto' }}>
                  <tbody style={{ display: 'block' }}>
                    <tr>
                      <td style={{ paddingLeft: 0 }}>
                        <b>Needed</b>
                      </td>
                      <td>
                        <b>Job</b>
                      </td>
                      <td>
                        <b>Resume</b>
                      </td>
                      <td>
                        <b>Status</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: 0 }}>
                        Minimum Experience (Years)
                      </td>
                      <td>
                        {isNaN(
                          this.props.ats?.ats_data?.job_level_match
                            .years_required,
                        )
                          ? 'N/A'
                          : this.props.ats?.ats_data?.job_level_match
                              .years_required}
                      </td>
                      <td>
                        {isNaN(
                          this.props.ats?.ats_data?.job_level_match
                            .years_showed,
                        )
                          ? 'N/A'
                          : this.props.ats?.ats_data?.job_level_match
                              .years_showed}
                      </td>
                      <td>
                        {!this.props.ats?.ats_data?.job_level_match
                          ?.isMatched ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: 0 }}>Mgmt Level</td>
                      <td>
                        {this.props.ats?.ats_data?.manage_level?.jobLevel !==
                        'None'
                          ? this.props.ats?.ats_data?.manage_level?.jobLevel.toUpperCase()
                          : 'N/A'}
                      </td>
                      <td>
                        {this.props.ats?.ats_data?.manage_level?.resumeLevel
                          ? this.props.ats?.ats_data?.manage_level?.resumeLevel.toUpperCase()
                          : 'N/A'}
                      </td>
                      <td>
                        {!this.props.ats?.ats_data?.manage_level?.isMatched ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: 0 }}>Education</td>
                      <td>
                        {this.props.ats?.ats_data?.education_match?.requires
                          ? this.props.ats?.ats_data?.education_match?.requires.toUpperCase()
                          : 'N/A'}
                      </td>
                      <td>
                        {this.props.ats?.ats_data?.education_match?.shows
                          ? this.props.ats?.ats_data?.education_match?.shows
                              .split(',')[0]
                              .toUpperCase()
                          : 'N/A'}
                      </td>
                      <td>
                        {!this.props.ats?.ats_data?.education_match
                          ?.isMatched ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ fileScanReducer }) => {
  return {
    avoidWords: fileScanReducer.avoidWords,
  };
};

export default connect(mapStateToProps, { getAvoidWords })(
  withRouter(JobRequirementsMatch),
);
