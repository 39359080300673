import React, { Component } from "react";
import { Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { Colxx } from "../../../commonComponents/customBootstrap";
import { MdCheck, MdClear } from "react-icons/md";
import { styles } from "../style";
import { colors } from "../../../commonComponents/colors";

export default class ATSfindings extends Component {
  render() {
    return (
      <div>
        <Row>
          <Colxx xxs="12" sm="12" lg="12">
            <Card className="mb-4">
              <CardBody
                style={{ ...styles.fontSize_75_rem, ...styles.fontWeight_400 }}
              >
                <CardTitle>ATS FINDINGS</CardTitle>
                <Table hover>
                  <tr>
                    <th scope="row">SKILLS AND KEYWORDS</th>
                    <td>
                      {this.props.ats.resumeSkillsMissing > 4 ? 
                        <MdClear size="1.7em" color={colors.red} />
                       : 
                        <MdCheck size="1.7em" color={colors.green} />
                      }
                    </td>
                    {!this.props.ats.resumeSkillsMissing ? 
                      <td style={styles.colorBlack}>
                        No,skills are missing from your resume.
                      </td>
                     : 
                      <td style={styles.colorBlack}>
                        You are missing {this.props.ats.resumeSkillsMissing}{" "}
                        important high-value skills on your resume. For example,
                        customer experience appears on the job description 3
                        times and it is not on your resume.
                      </td>
                    }
                  </tr>

                  <tbody>
                    <tr>
                      <th scope="row">JOB TITLE MATCH </th>
                      <td>
                        {this.props.ats.jobTitleMatch === "notMatch" ? 
                          <MdClear size="1.7em" color={colors.red} />
                         : 
                          <MdCheck size="1.7em" color={colors.green} />
                        }
                      </td>
                      {this.props.ats.jobTitleMatch === "notMatch" ? 
                        <td style={styles.colorBlack}>
                          The job title
                          <span style={{ color: "#145388" }}>
                            {" "}
                            {this.props.ats.jobTitle}
                          </span>{" "}
                          is not found from your resume It is really important
                          for job because the recruiters search the candidate by
                          the job title
                        </td>
                       : 
                        <td style={styles.colorBlack}>
                          The job title
                          <span style={{ color: "#145388" }}>
                            {" "}
                            {this.props.ats.jobTitle}
                          </span>
                          , from the job description is also found in your
                          resume. We recommend this to be the case to ensure
                          you're found when recruiters search by job title.
                          Incorrect job title in the job description?
                        </td>
                      }
                    </tr>
                    <tr>
                      <th scope="row">EDUCATION MATCH</th>
                      <td>
                        {!this.props.ats.educationMatch.match &&
                        this.props.ats.educationMatch.required ? 
                          <MdClear size="1.7em" color={colors.red} />
                         : 
                          <MdCheck size="1.7em" color={colors.green} />
                        }
                      </td>
                      {!this.props.ats.educationMatch.match &&
                      this.props.ats.educationMatch.required ? 
                        <td style={styles.colorBlack}>
                          This job doesn't specify a preferred degree.
                        </td>
                       : 
                        <td style={styles.colorBlack}>
                          {" "}
                          The job specify a preferred degree for the required
                          Job
                        </td>
                      }
                    </tr>
                    <tr>
                      <th scope="row">ATS TIP</th>
                      <td>
                        {" "}
                        <MdCheck size="1.7em" color={colors.green} />
                      </td>
                      <td style={styles.colorBlack}>
                        Adding this job's company name and web address can help
                        us provide you ATS-specific tips. Company: Amazon (Edit)
                        | URL: www.amazon.com (Edit) ATS Matched: iCIMS - Amazon
                        uses iCIMS
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">SECTION HEADINGS</th>
                      <td>
                        {!this.props.ats.headings.educationHeading ||
                        !this.props.ats.headings.workExperienceHeading ? 
                          <MdClear size="1.7em" color={colors.red} />
                         : 
                          <MdCheck size="1.7em" color={colors.green} />
                        }
                      </td>
                      {this.props.ats.headings.educationHeading &&
                      this.props.ats.headings.workExperienceHeading ? 
                        <td style={styles.colorBlack}>
                          We found the work experience section in your resume.
                          We found the education section in your resume.
                        </td>
                       : !this.props.ats.headings.educationHeading &&
                        this.props.ats.headings.workExperienceHeading ? 
                        <td style={styles.colorBlack}>
                          We dosen't find the education section section in your
                          resume
                        </td>
                       : !this.props.ats.headings.workExperienceHeading &&
                        this.props.ats.headings.educationHeading ? 
                        <td style={styles.colorBlack}>
                          We dosen't find the Work experience section in your
                          resume
                        </td>
                       : 
                        <td style={styles.colorBlack}>
                          We dosen't find the Work experience and Education
                          section in your resume
                        </td>
                      }
                    </tr>
                    <tr>
                      <th scope="row">DATE FORMATTING</th>
                      <td>
                        {!this.props.ats.dateFormatting ? 
                          <MdClear size="1.7em" color={colors.red} />
                         : 
                          <MdCheck size="1.7em" color={colors.green} />
                        }
                      </td>
                      {!this.props.ats.dateFormatting ? 
                        <td style={styles.colorBlack}>
                          The dates in your work experience section are not
                          formatted properly.
                        </td>
                       : 
                        <td style={styles.colorBlack}>
                          The dates in your work experience section are properly
                          formatted.
                        </td>
                      }
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </div>
    );
  }
}
