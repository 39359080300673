import React from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CheckIcon from "@material-ui/icons/Check";
import { useWindowSize } from "@react-hook/window-size";
import { colors } from "../../../commonComponents/colors";

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        `linear-gradient( 95deg,${colors.green} 0%,${colors.green} 50%,${colors.green} 100%)`,
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        `linear-gradient( 95deg,${colors.green} 0%,${colors.green} 50%,${colors.green} 100%)`,
    },
  },
  line: {
    height: 10,
    border: 0,
    borderRadius: 50,
    backgroundColor: "#eaeaf0",
  },
})(StepConnector);

export const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: colors.white,
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${colors.blue}`,
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, #4267B2 0%, #4267B2 50%,#4267B2 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      `linear-gradient( 136deg, ${colors.green} 0%, ${colors.green} 50%,${colors.green} 100%)`,
    border: `0px solid`,
  },
});

export function ColorlibStepIcon(props) {
  const [width] = useWindowSize();

  const classes = useColorlibStepIconStyles(width);
  const { active, completed } = props;

  const icons = {
    1: <FiberManualRecordIcon />,
    2: <CheckIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? icons[2] : icons[1]}
    </div>
  );
}