import React, { memo } from "react";
// Layout Components
import Topbar from "./Topbar";
import Footer from "./Footer";
import FooterWithoutMenuLightSocialOnly from "./FooterWithoutMenuLightSocialOnly";
import { withRouter } from "react-router-dom";
// Scroll up button
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

const Layout = memo((props) => {
  return (
    <>
      <Topbar />
      {props.children}
      {(() => {
        if (props.location.pathname === "/page-contact-two") {
          return <FooterWithoutMenuLightSocialOnly />;
        } else {
          return <Footer />;
        }
      })()}
      <div id="bottomIcon">
        <ScrollUpButton ContainerClassName="back-to-top rounded text-center" />
      </div>
    </>
  );
});

export default withRouter(Layout);
