import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Description, Edit } from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { colors } from '../../commonComponents/colors';
import {
  getCompanyInfo,
  getOnetData,
  updateJobTitle,
} from '../../commonRedux/scan/action';
import InfoModal from '../../commonComponents/infoModal/InfoModal';
import { useSelector } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
});

function createData(name, current, current2, average) {
  return { name, current, current2, average };
}

export default function SimpleTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { companyInfo, infoLoader, onetData } = useSelector(
    (store) => store?.fileScanReducer,
  );
  const [dialogField, setDialogField] = React.useState(
    props.data?.ats?.ats_data?.job_level_match?.EmployerNames,
  );
  const [dialogField1, setDialogField1] = React.useState(
    props.data?.ats?.ats_data?.job_title_matched?.requires,
  );
  const [jobTitleEdit, setJobTitleEdit] = React.useState('');
  const [resumeNameEdit, setResumeNameEdit] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [isInfoOpen, setIsInfoOpen] = React.useState(false);
  const [isTitle, setIsTitle] = React.useState(false);
  const [infoKey, setInfoKey] = React.useState('');
  const rows = [
    createData('Employer', dialogField || 'N/A', dialogField1),
    createData('Job Title', dialogField || 'N/A', dialogField1),
    createData(
      'Education',
      props.data?.ats?.ats_data?.education_match?.requires || 'N/A',
    ),
    createData(
      'Experience',
      isNaN(props.data?.ats?.ats_data?.job_level_match?.years_required)
        ? 'N/A'
        : props.data?.ats?.ats_data?.job_level_match?.years_required + ' Years',
    ),
  ];
  const handleInfoModal = (key) => {
    setIsInfoOpen(true);
    setInfoKey(key);
    if (key === 'company') {
      dispatch(getCompanyInfo(dialogField));
    }
    if (key === 'job') {
      dispatch(getOnetData(dialogField1));
    }
  };
  const onCloseInfoModal = (key) => {
    setIsInfoOpen(false);
    // setInfoKey(key);
  };
  const changeHandler = () => {
    if (!dialogField) {
      return;
    }
    if (isTitle) {
      let data = {
        oid: props.data?.oid,
        jobTitle: jobTitleEdit,
      };
      setDialogField1(jobTitleEdit);
      dispatch(updateJobTitle(data));
    } else {
      let data = {
        oid: props.data?.oid,
        employer: resumeNameEdit,
      };
      setDialogField(resumeNameEdit);
      dispatch(updateJobTitle(data));
    }
    dialogClose();
  };
  const dialogOpen = () => {
    setJobTitleEdit(dialogField1);
    setResumeNameEdit(dialogField);
    setOpen(true);
  };
  const dialogClose = () => {
    setOpen(false);
  };
  const editHandler = (data) => {
    if (data.name === 'Job Title') {
      setDialogField1(data.current2);
      setIsTitle(true);
      dialogOpen();
    } else {
      dialogOpen();
      setDialogField(data.current);
      setIsTitle(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={dialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            &nbsp;
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={dialogClose}
          />
        </div>
        {isTitle ? (
          <TextField
            value={jobTitleEdit}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Description style={{ color: '#4267B2' }} />
                </InputAdornment>
              ),
            }}
            style={{ marginTop: 0, width: 500, padding: '0px 20px 20px 20px' }}
            id="filled-helperText"
            placeholder={'Enter Job Title'}
            onChange={(e) => setJobTitleEdit(e.target.value)}
            inputProps={{
              maxLength: 20,
            }}
          />
        ) : (
          <TextField
            value={resumeNameEdit}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Description style={{ color: '#4267B2' }} />
                </InputAdornment>
              ),
            }}
            style={{ marginTop: 0, width: 500, padding: '0px 20px 20px 20px' }}
            id="filled-helperText"
            placeholder={'Enter Employer Name'}
            onChange={(e) => setResumeNameEdit(e.target.value)}
            inputProps={{
              maxLength: 20,
            }}
          />
        )}
        <DialogActions>
          <Button
            onClick={changeHandler}
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              borderRadius: '3px',
              fontSize: '14px',
              marginBottom: 5,
            }}
          >
            {isTitle ? 'Change Job Title' : 'Change Employer'}
          </Button>
          {/* <button
            onClick={dialogClose}
            style={{
              backgroundColor: colors.red,
              color: colors.white,
              borderRadius: '3px',
              fontSize: '14px',
            }}>
            Cancel
          </button> */}
        </DialogActions>
      </Dialog>

      <InfoModal
        isOpen={isInfoOpen}
        onCloseModal={onCloseInfoModal}
        infoKey={infoKey}
        companyInfo={companyInfo}
        jobTitle={dialogField1}
        loader={infoLoader}
        onetData={onetData}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">
                  {index < 2 && (
                    <Edit
                      style={{ cursor: 'pointer', marginRight: 5 }}
                      onClick={() => {
                        editHandler(row);
                      }}
                    />
                  )}
                  {row.name === 'Job Title' ? row.current2 : row.current}
                </TableCell>
                <TableCell align="right">
                  {index < 2 && (
                    <button
                      style={{
                        display: 'flex',
                        width: 120,
                        padding: '5px 0',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        borderRadius: 5,
                        backgroundColor: '#F38322',
                        color: '#FFFFFF',
                      }}
                      onClick={() =>
                        handleInfoModal(index === 0 ? 'company' : 'job')
                      }
                    >
                      {index === 0 ? 'Company View' : 'Job View'}
                    </button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
