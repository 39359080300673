import React from 'react';
import styles from './Questions.module.scss';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SolrAutoSuggestion from '../../../../commonComponents/jobSuggestion';
import {
  AddScanResuma,
  UpdateResuma,
} from '../../../../commonRedux/scan/action';
import { getFormatedTextFromBuilder } from '../../../../utils/getFormatedTextFromBuilder';
import {
  changeNameHandler,
  getResumeScan,
  onBlurField,
} from '../../../resumeBuilder/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { updateHowToStart } from '../../redux/actions';
// import AutoComplete from '../../../../commonComponents/autoCompleteCompany/AutoCompleteCompany';

function JobInformation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    resumeData,
    resumeExist,
    resumeName,
    templateIndex,
    builderResumeData: { oid: resumeId } = {},
  } = useSelector((state) => state.editorReducer);
  const builderSelectedTitle = useSelector(
    (store) => store?.fileScanReducer?.builderSelectedTitle,
  );
  const [jobTitle, setJobTitle] = React.useState(builderSelectedTitle);
  const [nextLoader, setNextLoader] = React.useState(false);
  const getResume = () => {
    let data = {
      builderResume: true,
      is_resume: true,
      filters: '',
    };

    dispatch(getResumeScan(data));
  };
  React.useEffect(() => {
    getResume();
  }, []);

  const isValid = jobTitle ? true : false;
  const timestamp = new Date().getTime();
  const first6Digits = Math.floor(timestamp / 1000)
    .toString()
    .slice(0, 6);

  const addJobTitle = () =>
    new Promise((resolve, reject) => {
      if (!resumeExist) {
        dispatch(
          AddScanResuma(
            {
              data: { data: resumeData },
              resumeName: `${resumeName}_${first6Digits}`,
              builderResume: true,
              templateIndex: templateIndex,
              resumeText: getFormatedTextFromBuilder(resumeData),
            },
            (data) => {
              localStorage.setItem('builderPage', 'form');
              localStorage.removeItem('builderVisited');
              localStorage.setItem('builderIndex', 0);
              localStorage.setItem('formIndex', 0);

              resolve(data);
            },
            (error) => {
              reject(error);
            },
          ),
        );
        dispatch(changeNameHandler(resumeName));
        dispatch(updateHowToStart('BuilderVisited'));
      } else {
        dispatch(
          UpdateResuma(
            {
              oid: resumeId,
              data: { data: resumeData },
              templateIndex: templateIndex,
              resumeText: getFormatedTextFromBuilder(resumeData),
              resumeName: `${resumeName}_${first6Digits}`,
            },
            (data) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            },
          ),
        );
        dispatch(updateHowToStart('BuilderVisited'));
      }
    });

  const handleNext = async () => {
    try {
      setNextLoader(true);
      handleJobTitle();
      await addJobTitle();
      history.push('/builder');
    } catch (error) {
      console.log('Error', error);
    } finally {
      setNextLoader(false);
    }
  };

  const handleJobTitle = () => {
    dispatch(onBlurField(jobTitle, 'profile.jobTitle'));
  };

  return (
    <div className={styles.main1_container}>
      <div className={styles.main_container}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <div className={styles.text_container}>
              <div className={styles.experience_text}>
                What is the job title you plan to apply?
              </div>
              <div className={styles.volunteer_text}>
                Enter the job title and company name you plan to apply for.
              </div>
              <div className={styles.div_col}>
                <div>
                  <label>Job Title</label>
                  <SolrAutoSuggestion
                    onChangeTitle={(val) => {
                      setJobTitle(val);
                    }}
                    value={jobTitle}
                    onBlur={handleJobTitle}
                  />
                </div>
                {/* <div>
                                    <label>Company Name</label>
                                    <AutoComplete
                                        focus={true}
                                        value={employer}
                                        placeHolder=""
                                        customClass={styles.input}
                                        style={{
                                            height: 40,
                                        }}
                                        handleChange={(company) => {
                                            setEmployer(company);
                                        }}
                                        handleSelect={(fullAddress) => {
                                            const company = fullAddress?.split(',')?.[0] || '';
                                            setEmployer(company);
                                        }}
                                    />
                                </div> */}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={5}
            style={{
              display: 'flex',
              width: 'fir-content',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div className={styles.img_div}>
              <div className={styles.img_container}></div>
            </div>
          </Grid>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <button
              style={{
                marginRight: 20,
                width: 80,
                height: 35,
                borderRadius: 5,
                backgroundColor: '#4167b2',
                color: '#FFFFFF',
              }}
              onClick={() => history.push('/questionnaire/highest_education')}
            >
              Back
            </button>
            <button
              disabled={!isValid || nextLoader}
              style={{
                marginRight: 20,
                width: 80,
                height: 35,
                borderRadius: 5,
                backgroundColor: !isValid ? 'grey' : '#4167b2',
                color: '#FFFFFF',
              }}
              onClick={handleNext}
            >
              {nextLoader ? (
                <div>
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                'Next'
              )}
            </button>
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default JobInformation;
