import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import styles from './Questions.module.scss';
import { useHistory } from 'react-router-dom';
import Education1 from "./svg's/Education1";
import Education2 from "./svg's/Education2";
import Education3 from "./svg's/Education3";
const data = [
  {
    title: 'High School',
    link: '/builder',
    IconComponent: Education1,
  },
  {
    title: 'Associate Degree',
    link: '/builder',
    IconComponent: Education2,
  },
  {
    title: 'Bachelors Degree',
    link: '/builder',
    IconComponent: Education2,
  },
  {
    title: 'Master’s Degree',
    link: '/builder',
    IconComponent: Education2,
  },
  {
    title: 'Advanced',
    details: 'MD/JD/PhD/etc',
    link: '/builder',
    IconComponent: Education3,
  },
  {
    title: 'Others',
    details: 'Vocational / Certified',
    link: '/builder',
    IconComponent: Education3,
  },
];
export default function HighestEducation() {
  const history = useHistory();
  const [checked, setChecked] = React.useState('');

  const selectEduHandler = (item, index) => {
    // if (checked === item) {
    //   setChecked('');
    //   return;
    // }
    localStorage.setItem('educationCardIndex', index)
    history.push('/questionnaire/job_information')
    setChecked(item);
  };

  const checkIsIncluded = (edu) => {
    return checked === edu;
  };
  useEffect(() => {
    if (localStorage.getItem('educationCardIndex')) {
      setChecked(data[localStorage.getItem('educationCardIndex')]);
    }
  }, []);


  return (
    <div className={styles.main1_container}>
      <div className={styles.main_container}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <div className={styles.text_container}>
              <div className={styles.experience_text}>
                What is your educational level?
              </div>
              <div className={styles.volunteer_text}>
                Select your degree completed and check mark any attended
              </div>
              <div className={styles.div_container}>
                {data.map((item, index) => {
                  const isIncluded = checkIsIncluded(item);
                  return (
                    <div
                      className={`${styles.divgraduation} ${checked?.title === item?.title ? styles.isIncluded : ''
                        }`}
                      key={index}
                      onClick={() => selectEduHandler(item, index)}
                    >
                      <div
                        style={{
                          color: isIncluded ? '#2f7264' : '#606060',
                        }}
                      >
                        <item.IconComponent />
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          color: isIncluded ? '#2f7264' : '#606060',
                        }}
                      >
                        {item.title}
                      </div>
                      <div
                        style={{ color: isIncluded ? '#2f7264' : '#606060' }}
                      >
                        {item?.details}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.volunteer_text}>
                Highest degree completed or expected to complete in the next
                6-months.
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={5}
            style={{
              display: 'flex',
              width: 'fir-content',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
          </Grid>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <button
              style={{
                marginRight: 20,
                width: 80,
                height: 35,
                borderRadius: 5,
                backgroundColor: "#4167b2",
                color: '#FFFFFF',
              }}
              onClick={() => history.push('/questionnaire/work_experience')}
            >
              Back
            </button>
            <button
              disabled={!checked}
              style={{
                marginRight: 20,
                width: 80,
                height: 35,
                borderRadius: 5,
                backgroundColor: !checked ? 'grey' : '#4167b2',
                color: '#FFFFFF',
              }}
              onClick={() => history.push('/questionnaire/job_information')}
            >
              Next
            </button>
          </div>
        </Grid>
      </div>
    </div>
  );
}
