import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useStyles } from '../downloadUserDoc/DownloadUserDocStyle';
import { CircularProgress, Grid } from '@material-ui/core';
import H from '../../assets/H.webp';
import toastr from 'toastr';
import url from '../../config/endpoint';

function DownloadReport(props) {
  const styles = useStyles();
  const [code, setCode] = useState('');
  const [branch, setBranch] = useState('');
  const [title, setTitle] = useState('');
  const [docxLink, setDocxLink] = useState('');
  const [pdfLink, setPdfLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedSubtitles, setselectedSubtitles] = useState('');

  useEffect(async () => {
    try {
      setLoading(true);
      const userId =
        new URLSearchParams(props.location.search).get('oid') || '';
      const response = await axios.post(`${url}/get-milXwalk-report-detail`, {
        userId,
      });
      if (response?.data?.status === 200) {
        setCode(response?.data?.data?.code);
        setTitle(response?.data?.data?.title);
        setBranch(response?.data?.data?.branch);
        setDocxLink(response?.data?.data?.docxLink);
        setPdfLink(response?.data?.data?.pdfLink);
        setselectedSubtitles(response?.data?.data?.selectedSubtitles);
      } else {
        toastr.error(response?.data?.message || 'Something went wrong');
      }
    } catch (error) {
      console.log(error);
      toastr.error(error?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }, []);

  let downloadReport = (type) => {
    const url = `${process.env.REACT_APP_API_URL}/${
      type === 'docx' ? docxLink : pdfLink
    }`;
    window.open(url);
  };

  return (
    <div className={styles.mainColor}>
      <div className={styles.body}>
        <div>
          <div className={styles.logo}>
            <img src={H} height={25} width={100} />
          </div>
          <div className={styles.reportContainer}>
            <center>
              <h4
                style={{
                  paddingBottom: '65px',
                }}
              >
                milXwalk Report
              </h4>
            </center>
            {loading ? (
              <div
                style={{
                  minHeight: '20vh',
                  display: 'flex',
                  width: '250px',
                  alignItems: 'center',
                  margin: 'auto',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress size={30} color="primary" />
              </div>
            ) : (
              <div>
                <table className={styles.table}>
                  <tr>
                    <th className={styles.td}>Military Branch</th>
                    <td className={styles.emptyMiddle}></td>
                    <td className={styles.td}>{branch || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th className={styles.td}>MOC Code</th>
                    <td className={styles.emptyMiddle}></td>
                    <td className={styles.td}>{code || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th className={styles.td}>Military Title</th>
                    <td className={styles.emptyMiddle}></td>
                    <td className={styles.td}>{title || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th className={styles.td}>Civilian Job Occupation:</th>
                    <td className={styles.emptyMiddle}></td>
                    <td className={styles.td}>{selectedSubtitles || 'N/A'}</td>
                  </tr>
                </table>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                      <button
                        className={styles.buttonpdf}
                        onClick={() => {
                          downloadReport('pdf');
                        }}
                      >
                        Download PDF
                      </button>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                      <button
                        className={styles.buttonDocx}
                        onClick={() => {
                          downloadReport('docx');
                        }}
                      >
                        Download DOC
                      </button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadReport;
