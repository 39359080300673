import React from "react";
import ContentLoader from "react-content-loader";

export default function RecentScanCard(props) {
  return (
    <ContentLoader viewBox="0 0 476 50" {...props}>
      <rect x="50" y="10" width="410" height="6" rx="4" />
      <rect x="50" y="20" width="410" height="6" rx="3" />
      <rect x="50" y="30" width="410" height="6" rx="3" />
      <circle cx="25" cy="25" r="20" />
    </ContentLoader>
  );
}
