import { useState } from 'react';
import toastr from 'toastr';
import { useDispatch, useSelector } from 'react-redux';
import { sendTextToEmailOrMobile } from '../../commonRedux/scan/action';
import { validateEmail, validatePhone } from '../../utils/emailValidation';

export function useInviteFriends() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const user = useSelector((state) => state.authUser.user);

  const splitter = (value) => {
    let splittedArray = [];
    splittedArray = value.split(',');
    return splittedArray;
  };
  const cb = (v) => {
    if (v == 'OK') {
      setLoading(false);
    }
  };
  const sendInvite = (closeModal) => {
    if (emails == '' && phoneNumbers == '') {
      toastr.error('Please add at least One Email Or Phone Number');
      setLoading(false);
      return;
    }

    setLoading(true);
    let emailList = splitter(emails);
    emailList = emailList
      ?.filter((email) => email !== '')
      ?.map((email) => email?.trim());

    let phoneList = splitter(phoneNumbers);
    phoneList = phoneList
      ?.filter((phone) => phone !== '')
      ?.map((phone) => phone.trim());

    const isValidEmail = emailList?.every((email) => validateEmail(email));
    const isValidPhone = phoneList?.every((phone) => validatePhone(phone));
    if (emails !== '') {
      if (!isValidEmail) {
        toastr.error('Please add valid email address');
        setLoading(false);
        return;
      }
      let emailData = {
        type: 'email',
        body: [
          'Invitation',
          "Hey i'm inviting you to join resumás.",
          `https://app.resumas.com/signup?invited_by=${user._id}&email=${emailList}`,
        ],
        user_contacts: emailList,
      };
      dispatch(sendTextToEmailOrMobile(emailData, cb));
      closeModal(false);
    }

    if (phoneNumbers !== '') {
      if (!isValidPhone) {
        toastr.error('Please add valid phone number');
        setLoading(false);
        return;
      }
      let smsData = {
        type: 'mobile',
        body: [
          'Invitation',
          "Hey i'm inviting you to join resumás. Thank You!",
          `https://app.resumas.com/signup?invited_by=${user._id}`,
        ],
        user_contacts: phoneList,
      };
      dispatch(sendTextToEmailOrMobile(smsData, cb));
      closeModal(false);
    }
  };
  return {
    loading,
    emails,
    setEmails,
    phoneNumbers,
    setPhoneNumbers,
    sendInvite,
  };
}
