import React, { useEffect } from 'react';
import styles from './HowToStart.module.scss';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateHowToStart } from '../redux/actions';
import VideoModel from '../../../commonComponents/videoModel';
export default function HowToStart() {
  const dispatch = useDispatch();
  const onCardPress = (path, index) => {
    localStorage.setItem('howToStartCardIndex', index);
    if (path === '/optimize') {
      dispatch(updateHowToStart('Optimize'));
    }
  };
  const videos = useSelector((state) => state.fileScanReducer?.videoLinks);
  const howToStartCards = [
    {
      title: 'I DO have my resume',
      subtitle: 'Optimize resume for a job',
      description: 'Ready to apply for jobs',
      path: '/optimize',
    },
    {
      title: "I don't have my resume",
      subtitle: 'Build New Resume',
      description: 'Not ready to apply for jobs',
      path: '/questionnaire/work_experience',
    },
    {
      title: 'I DO have my resume but',
      subtitle: 'Update existing resume',
      description: 'Not ready to apply for jobs',
      path: '/resume_builder/drop_zone',
    },
    {
      title: 'I want to explore',
      subtitle: 'Military to Civilian Careers',
      description: 'Not ready to apply for jobs',
      path: '/milXwalk',
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let firstTimeLogin = localStorage.getItem('howToStartVideo') || null;
  useEffect(() => {
    if (firstTimeLogin) {
      handleOpen();
      localStorage.removeItem('howToStartVideo');
    }
  }, [firstTimeLogin]);
  return (
    <div>
      <VideoModel
        closeAble
        open={open}
        setOpen={handleClose}
        video={videos?.IntroVideo}
        onEnded={() => handleClose()}
      />
      <div className={styles.mainClass}>
        <div className={styles.main_container}>
          <div className={styles.text_container}>
            How would you like us to begin?
          </div>
          <div>
            <Grid container spacing={2}>
              {howToStartCards.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={3}
                  style={{
                    marginTop: 30,
                  }}
                  key={index}
                >
                  <Link to={item.path}>
                    <div
                      className={styles.card}
                      style={{
                        borderColor:
                          localStorage.getItem('howToStartCardIndex') ===
                          index.toString()
                            ? '#2f7264'
                            : '#E0E0E0',
                      }}
                      onClick={() => onCardPress(item.path, index)}
                    >
                      <div className={styles.text_wrapper}>
                        <div className={styles.resume_box_text}>
                          {item.title}
                        </div>
                        <div className={styles.resume_text}>
                          {item.subtitle}
                        </div>
                        <div className={styles.resume_box_text}>
                          {item.description}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
