import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from 'react-ios-switch';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import './style.css';
import ReactPaginate from 'react-paginate';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import {
  updateMasterResuma,
  getResumaResults,
  addNotesAction,
  deleteResume,
  getResumaId,
} from '../../commonRedux/scan/action';
import {
  getPdf,
  setPdfStatusFalse,
  getDoc,
} from '../../commonRedux/template/action';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import Model from './notes-model';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Loader from 'react-loader-spinner';
import { styles } from './style';
import TableLoader from '../../commonComponents/tableLoader';
import InputAdornment from '@material-ui/core/InputAdornment';
import DescriptionIcon from '@material-ui/icons/Description';
import { colors } from '../../commonComponents/colors';
import { DialogTitle, IconButton, Button as ButtonR } from '@material-ui/core';
import { useDispatch } from 'react-redux';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: 0,
  },
  container: {
    maxHeight: 440,
  },
});
var n = 0;
function ResumeHistoryTable({
  userResumas,
  resumaId,
  getResumaId,
  updateMasterResuma,
  getResumaResults,
  deleteResume,
  addNotesAction,
  getResumasStatus,
  templateLinks,
  getPdfStatus,
  setPdfStatusFalse,
  getPdf,
  total_page,
  getDocStatus,
  getDocLink,
}) {
  const classes = useStyles();
  const [isSelected, setSelected] = React.useState(
    localStorage.getItem('masterTemplate'),
  );

  // useEffect(() => {
  //   setSelected(resumaId);
  // }, [resumaId]);
  const [delLoading, setDelLoading] = React.useState(false);
  const [checkLoader, setCheckLoader] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [oid, setOid] = React.useState('');
  const [docLoader, setDocLoader] = React.useState(false);
  const [linkLoader, setLinkLoader] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [docs, setDocs] = React.useState(false);
  const [showResumeNameModal, setShowResumeNameModal] = React.useState(false);
  const [resumeName, setResumeName] = React.useState('');
  const [pageSelected, setPageSelected] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItems, setMenuItems] = React.useState({});
  const [isPageLink, setIsPageLink] = useState(false);
  const [isDocLink, setIsDocLink] = useState(false);
  const cb = () => {
    setCheckLoader('');
    setDocLoader(false);
    setLoader(false);
  };
  const dispatch = useDispatch();
  const generateTemplate = async (type, createdOid) => {
    setCheckLoader(createdOid);
    if (type === 'docx') {
      setDocLoader(true);
      setDocs(true);
      setIsDocLink(true);
      dispatch(
        getDoc(
          {
            oid: createdOid,
            is_scan: 0,
            temp_id: localStorage.getItem('tempTemplate'),
            token: '',
          },
          cb,
        ),
      );
    } else if (type === 'link') {
      setIsPageLink('link');
      setLinkLoader(true);
      setTimeout(() => {
        setLinkLoader(false);
        setDocs(false);
      }, 1000);
      getPdf(
        {
          oid: createdOid,
          is_scan: 0,
          temp_id: localStorage.getItem('tempTemplate'),
          token: '',
        },
        cb,
      );
    } else {
      setLoader(true);
      setDocs(false);
      setIsPageLink('pdf');
      getPdf(
        {
          oid: createdOid,
          is_scan: 0,
          temp_id: localStorage.getItem('tempTemplate'),
          token: '',
        },
        cb,
      );
    }
  };
  React.useEffect(() => {
    setLoader(false);
    setDocLoader(false);
    if (getPdfStatus === 'done') {
      if (isPageLink === 'link') {
        window.open(
          templateLinks.pdf
            ? `${process.env.REACT_APP_WEB_URL}/${templateLinks.download}`
            : templateLinks.cloundinary,
          '_blank',
        );
        setIsPageLink('');
        setPdfStatusFalse();
        return;
      } else if (isPageLink === 'pdf') {
        window.open(
          templateLinks.pdf
            ? `${process.env.REACT_APP_API_URL}/${templateLinks.pdf}`
            : templateLinks.cloundinary,
          '_blank',
        );
        setIsPageLink('');
      }
      setPdfStatusFalse();
      setDocs(false);
    }
    if (getDocStatus === 'done') {
      if (isDocLink) {
        window.open(
          getDocLink
            ? `${process.env.REACT_APP_API_URL}/${getDocLink.doc}`
            : templateLinks.cloundinary,
          '_blank',
        );
        setIsDocLink(false);
        return;
      }
      setPdfStatusFalse();
      setDocs(false);
    } else {
      setLoader(false);
      setDocLoader(false);
    }
  }, [getPdfStatus, getDocStatus, getDocLink]);

  const _getResumaData = (page) => {
    getResumaResults(page, '');
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    n += 1;
    if (n === 1) {
      getResumaId();
      _getResumaData(1);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setOid(id);
  };
  const handleNameModal = (id) => {
    setShowResumeNameModal(true);
    setOid(id);
  };
  const closeResumeModal = () => {
    _getResumaData(1);
    setShowResumeNameModal(false);
    setLoader(false);
  };
  const handleResumeName = () => {
    setLoader(true);
    let data = {
      type: '',
      // note: notes,
      oid: oid.oid,
      resumeName: resumeName,
    };
    addNotesAction(data, closeResumeModal);
  };
  const handleClick = (event, items) => {
    setMenuItems(items);
    setAnchorEl(event.currentTarget);
  };
  const handlePageClick = (data) => {
    let num = data.selected + 1;
    _getResumaData(num);
    setPageSelected(data.selected);
  };

  const columns = [
    { id: 'Company', label: 'Company Name', minWidth: 150 },
    { id: 'JobTitle', label: 'Job Title' },
    { id: 'ResumeSaved', label: 'Resume Name' },
    {
      id: 'Created',
      label: 'Saved',
    },
    { id: 'JobListing', label: 'Source', minWidth: 150 },

    {
      id: 'masterResume',
      label: 'Default',
    },
    {
      id: 'ActionUse',
      label: 'Action Use',
      minWidth: 150,
    },
  ];

  function createData(
    Company,
    JobTitle,
    ResumeSaved,
    JobListing,
    masterResume,
    Created,
    ActionUse,
  ) {
    return {
      Company,
      JobTitle,
      ResumeSaved,
      JobListing,
      masterResume,
      Created,
      ActionUse,
    };
  }

  const onChangeR = (i) => {
    if (localStorage.getItem('masterTemplate') !== i) {
      updateMasterResuma({ oid: i });
      localStorage.setItem('masterTemplate', i);
      setSelected(i);
    }
  };
  const linkHandler = () => {
    setIsPageLink('link');
    generateTemplate('link', menuItems.oid);
  };

  const rows = userResumas
    ?.sort((a, b) => {
      return new Date(b?.createdAt) - new Date(a?.createdAt);
    })
    ?.map((item) =>
      createData(
        item?.company,
        item?.jobTitle
          ? item?.jobTitle
          : item?.data?.profile?.jobTitle || 'No job title',

        item.resumeName
          ? `${item.resumeName}_${item.company}_${moment(item.createdAt).format(
              'MMDDYY',
            )}`?.replaceAll(' ', '')
          : `no_job_title_${item.company}_${moment(item.createdAt).format(
              'MMDDYY',
            )}`?.replaceAll(' ', ''),
        <span>{item?.builderResume ? 'Resume Builder' : 'Review'}</span>,
        <Switch
          checked={isSelected === item['oid']}
          className="switch"
          onColor="rgb(43,125,227)"
          onChange={() => onChangeR(item['oid'])}
        />,
        moment(item.createdAt).format('MMM DD, YYYY'),

        <MoreVertIcon
          style={{ marginLeft: 10 }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(event) => handleClick(event, item)}
          className="hovdel"
        />,
      ),
    );

  if (getResumasStatus) {
    return <TableLoader />;
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={showResumeNameModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowResumeNameModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <span onClick={() => setShowResumeNameModal(false)}>
          <CancelIcon style={styles.closeBtn} />
        </span>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DescriptionIcon style={{ color: '#4267B2' }} />
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 20,
          }}
          fullWidth
          style={{ marginTop: 10, padding: 20 }}
          id="filled-helperText"
          placeholder={'Enter Resume Name'}
          onChange={(e) => setResumeName(e.target.value)}
        />
        <DialogActions>
          <Button
            onClick={handleResumeName}
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
            }}
            color={colors.blue}
          >
            {loader ? 'Updating' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
            paddingTop: '10px',
          }}
        >
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={handleClose}
          />
        </div>
        <DialogTitle style={{ ...styles.h3, padding: '0px 10px 0px 15px' }}>
          Are you sure, you want to delete?
        </DialogTitle>
        <DialogActions>
          {/* <button
            onClick={handleClose}
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              borderRadius: '3px',
              fontSize: '14px',
            }}
          >
            Cancel
          </button> */}
          <ButtonR
            disabled={delLoading}
            onClick={() => {
              setDelLoading(true);
              deleteResume(oid, () => {
                setOpen(false);
                setDelLoading(false);
              });
            }}
            style={{
              backgroundColor: colors.blue,
              fontSize: '14px',
              color: colors.white,
              borderRadius: '3px',
              margin: '0px 3px 6px 0px',
            }}
          >
            Delete
          </ButtonR>
        </DialogActions>
      </Dialog>
      <div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {userResumas.length && rows.length ? (
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align="center"
                              style={{ borderBottom: 'none' }}
                            >
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={linkHandler}>
                      {linkLoader ? (
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={15}
                          width={15}
                          style={{ display: 'flex' }}
                        />
                      ) : (
                        <span>Link</span>
                      )}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleMenuClose();
                        handleNameModal(menuItems);
                      }}
                    >
                      Update Resume Name
                    </MenuItem>
                    <MenuItem>
                      <Model
                        addNotesAction={addNotesAction}
                        oid={menuItems.oid}
                        handleClose={handleMenuClose}
                        type="resume"
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClickOpen(menuItems.oid);
                        handleMenuClose();
                      }}
                    >
                      Delete Resume
                    </MenuItem>
                    <MenuItem>
                      {docLoader && checkLoader === menuItems.oid ? (
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={15}
                          width={15}
                          style={{ display: 'flex' }}
                        />
                      ) : (
                        <span
                          onClick={() =>
                            generateTemplate('docx', menuItems.oid)
                          }
                        >
                          Download Word
                        </span>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {' '}
                      {loader && checkLoader === menuItems.oid ? (
                        <Loader
                          type="TailSpin"
                          color="#00BFFF"
                          height={15}
                          width={15}
                          style={{ display: 'flex' }}
                        />
                      ) : (
                        <span
                          onClick={() => generateTemplate('pdf', menuItems.oid)}
                        >
                          Download PDF
                        </span>
                      )}
                    </MenuItem>
                  </Menu>
                </TableBody>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div
                      className="resume_main_container"
                      style={{
                        ...styles.flex,
                        ...styles.justifyContentCenter,
                        ...styles.alignItemsCenter,
                        ...styles.tableCell,
                        ...styles.textAlignCenter,
                      }}
                    >
                      No Data Found!
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
          {rows.length >= 5 ? (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={Math.ceil(total_page / 5)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
              forcePage={pageSelected}
            />
          ) : (
            ''
          )}
          <div style={{ height: 2 }}></div>
        </Paper>
      </div>
    </div>
  );
}

const mapStateToProps = ({ fileScanReducer, authUser, templateReducer }) => {
  return {
    userResumas: fileScanReducer.userResumas,
    page: fileScanReducer.current_page,
    total_page: fileScanReducer.total_page,
    resumaId: fileScanReducer.resumaId,
    getResumasStatus: fileScanReducer.getResumasStatus,
    rId: authUser.user !== null ? authUser.user.resumeId : '',
    templateLinks: templateReducer.pdfLink,
    getPdfStatus: templateReducer.getPdfStatus,
    getDocStatus: templateReducer.getDocStatus,
    getDocLink: templateReducer.docLink,
  };
};

export default connect(mapStateToProps, {
  updateMasterResuma,
  getResumaResults,
  deleteResume,
  addNotesAction,
  getResumaId,
  setPdfStatusFalse,
  getPdf,
})(ResumeHistoryTable);
