import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  warningColor: {
    backgroundColor: '#FFCC00',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 40,
    color: 'white',
  },
  dangerColor: {
    backgroundColor: '#F45B40',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 40,
    color: 'white',
  },
}));

export default useStyles;
