import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import TopNav from './topnav';
import Footer from './footer';
import Sidebar from './sidebar';
import { connect } from 'react-redux';
import { styles } from './style';
import NotFound from '../../notFound/NotFoundPage';

class AppLayout extends Component {
  state = {
    componentExist: false,
    pathname: '',
  };
  checkComponent = () => {
    const { children, location } = this.props;
    const pathname = location.pathname;
    const allChildren = children.props.children;
    const componentExist = allChildren.some(
      (item) => item.props.path?.toLowerCase() === pathname?.toLowerCase(),
    );
    this.setState({
      pathname: pathname,
      componentExist,
    });
  };
  componentDidMount() {
    this.checkComponent();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.checkComponent();
    }
  }
  render() {
    const { containerClassnames } = this.props;

    return (
      <>
        <div className="wrapper" style={styles.background}>
          <div
            id="app-container"
            className={containerClassnames}
            style={styles.background}
          >
            <TopNav history={this.props.history} />
            <Sidebar />
            <main style={styles.pt_120}>
              <div className="container-fluid">
                {this.state.componentExist ? this.props.children : <NotFound />}
              </div>
            </main>
          </div>
        </div>
        <Footer className="footer-class" />
      </>
    );
  }
}

const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};

export default withRouter(connect(mapStateToProps)(AppLayout));
