import React from 'react';
import { Col } from 'reactstrap';
import { styles } from './helpStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import TelegramIcon from '@material-ui/icons/Telegram';
import SubjectIcon from '@material-ui/icons/Subject';
import Button from '@material-ui/core/Button';
import { useHelp } from './useHelp';
import Loader from 'react-loader-spinner';
import toastr from 'toastr';
import { colors } from '../../commonComponents/colors';
import Popup from 'reactjs-popup';
export default function Help({ onClose, open }) {
  const {
    subject,
    message,
    loading,
    versions,
    helpModal,
    width,
    setSubject,
    setMessage,
    handleFile,
    sendMessage,
    handleModal,
  } = useHelp();

  return (
    <Popup
      open={open ? open : helpModal}
      onClose={onClose ? onClose : handleModal}
      contentStyle={{
        width: width < 768 ? '95%' : '50%',
        marginTop: 120,
        borderRadius: 10,
        padding: 10,
      }}
      modal
      position="center"
      closeOnDocumentClick
      lockScroll
    >
      <div style={{ alignSelf: 'center' }}>
        <>
          <span onClick={onClose ? onClose : handleModal}>
            <CancelIcon style={styles.closeBtn} />
          </span>
          <Col style={{ backgroundColor: 'white' }} className="text-center">
            <h3
              style={{
                color: 'black',
                fontWeight: 'bold',
              }}
              className="f-s-23"
            >
              Contact Support
            </h3>
            <p
              style={{ fontFamily: 'Nunito', fontSize: 14 }}
              className="text-muted para-desc mb-0 mx-auto f-s-19"
            >
              Get in touch by filling out the form below or by <br />
              emailing us at support@resumas.com
            </p>

            <div className="section-title" style={styles.pb_10}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SubjectIcon style={{ color: '#4267B2' }} />
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: 10, width: '100%' }}
                // placeholder="Enter subject"
                id="filled-helperText"
                label="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                variant="outlined"
              />
            </div>

            <div className="section-title" style={styles.pb_10}>
              <TextField
                InputProps={{
                  style: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    paddingTop: 10,
                    paddingLeft: 35,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <TelegramIcon
                        style={{
                          color: '#4267B2',
                          position: 'absolute',
                          top: '10px',
                          left: 13,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: 10, width: '100%' }}
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={4}
                // placeholder="Write your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant="outlined"
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <p style={{ fontFamily: 'Nunito' }} className="f-s-19">
                Web Version {versions?.web || 'n/a'}, Mobile Version{' '}
                {versions?.mobile || 'n/a'}
              </p>
            </div>
            <div className="section-title" style={styles.pb_10}>
              <TextField
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <AttachmentIcon style={{ color: "#4267B2" }} /> */}
                      <input
                        id="file-input"
                        type="file"
                        title=" "
                        accept="image/*,.pdf"
                        onChange={handleFile}
                        style={{ width: '300px' }}
                      />
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: 10, width: '100%' }}
                id="filled-helperText"
                label="Attachment (Only pdf and image files with .png, .jpg are allowed)"
                value=""
                variant="outlined"
              />

              <span style={{ width: '100%' }} className="image-upload"></span>
            </div>
            <div className="section-title" style={styles.pb_10}>
              <Button
                disabled={loading}
                onClick={() => {
                  if (subject === '') {
                    toastr.error('Please enter subject');
                  } else if (message === '') {
                    toastr.error('Please enter message');
                  } else {
                    sendMessage(onClose);
                  }
                }}
                color="primary"
                variant="contained"
                style={{
                  width: '30%',
                  backgroundColor: colors.blue,
                  color: colors.white,
                  height: '40px',
                }}
              >
                {loading ? (
                  <Loader type="TailSpin" color="#fff" height={15} width={15} />
                ) : (
                  'Send'
                )}
              </Button>
            </div>
          </Col>
        </>
      </div>
    </Popup>
  );
}
