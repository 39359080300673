import React from 'react';
import { Card, CardBody } from 'reactstrap';

import Megaphone from '../assets/megaphone.webp';

function InfoCard({ text = '', marginBottom = 15 }) {
  return (
    <Card
      style={{
        borderRadius: 10,
        backgroundColor: '#edf0f7',
        borderWidth: 0,
        marginBottom,
      }}
    >
      <CardBody style={{ padding: 20, paddingTop: 5, paddingBottom: 5 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <img
            alt=""
            src={Megaphone}
            style={{
              width: 40,
              height: 40,
              marginRight: 20,
              color: '#576baa',
              backgroundColor: '"#576baa"',
            }}
          />

          <div>
            <p
              style={{
                fontSize: 12,
                color: '#576baa',
                marginTop: 20,
                fontWeight: 'bold',
              }}
            >
              {text}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
export default InfoCard;
