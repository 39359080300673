export default (
  state = {
    pdfLink: "",
    docLink: "",
    updator: new Date(),
    getPdfStatus: "not done",
    coverLetters: [],
    coverAdminLetters: [],
    total_page: "",
    current_page: "",
    coverLetterStatus: false,
    docURL: "",
    pdfURL: "",
    docStatus: false,
    pdfStatus: false,
  },
  action
) => {
  switch (action.type) {
    case "PDF_START":
      return {
        ...state,
        pdfStatus: true,
      };
    case "BUILDER_PDF_LINK":
      return {
        ...state,
        pdfURL: action.payload,
        pdfStatus: false,
      };
    case "PDF_FAILED":
      return {
        ...state,
        pdfStatus: false,
      };
    case "DOCS_START":
      return {
        ...state,
        docStatus: true,
      };

    case "BUILDER_DOCX_LINK":
      return {
        ...state,
        docURL: action.payload,
        docStatus: false,
      };

    case "DOCS_FAILED":
      return {
        ...state,
        docStatus: false,
      };

    case "COVER_LETTERS":
      return {
        ...state,
        coverLetters: action.payload.data,
        total_page: action.payload.page_total,
        current_page: action.payload.page,
      };
    case "COVER_ADMIN_LETTERS":
      return {
        ...state,
        coverAdminLetters: action.payload,
      };
    case "GET_LETTERS_STARTED":
      return {
        ...state,
        coverLetterStatus: true,
      };
    case "GET_LETTERS_SUCCESS":
      return {
        ...state,
        coverLetterStatus: false,
      };
    case "GET_LETTERS_FAILED":
      return {
        ...state,
        coverLetterStatus: false,
      };
    case "PDF_LINK":
      return {
        ...state,
        pdfLink: action.payload,
        updator: new Date(),
        getPdfStatus: "done",
      };
    case "DOC_LINK":
      return {
        ...state,
        docLink: action.payload,
        updator: new Date(),
        getDocStatus: "done",
      };
    case "PDF_STATUS_FALSE":
      return {
        ...state,
        updator: new Date(),
        getPdfStatus: "not done",
      };

    case "TEMPLATE_STATUS":
      return {
        ...state,
        getPdfStatus: "not done",
        pdfLink: "",

        updator: new Date(),
      };
    default:
      return state;
  }
};
