import Select from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import React from 'react';
import { onBlurField } from '../../redux/actions';

function SelectTag(props) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState({
    label: undefined,
    value: undefined,
  });
  const [placeHolder, setPlaceHolder] = React.useState('');
  React.useEffect(() => {
    if (props.value) {
      setValue({
        label: props.value,
        value: props.value,
      });
    }
  }, [props.value]);
  React.useEffect(() => {
    if (props.placeHolder) {
      setPlaceHolder(props.placeHolder);
    }
  }, [props.placeHolder]);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: 14,
      minHeight: '30px',
      // height: '30px',
      border: state.isFocused ? '0.5px solid #4267b3' : '0.5px solid #ccc',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '0.5px solid #ccc',
        boxShadow: 'none',
      },
      '&:onFocus': {
        border: '0.5px solid #4267b3',
        boxShadow: 'none',
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '43px',
      padding: '0 6px',
    }),
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled ? 'red' : blue,
        color: '#000',
        fontSize: 15,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
  };
  return (
    <Select
      placeholder={placeHolder}
      isCreatable={props.isCreatable || false}
      // isMulti
      options={props.options?.map((n) => ({
        label: n,
        value: n,
      }))}
      //  styles={customStyles}
      onChange={(tags) => {
        setValue(tags);
        dispatch(onBlurField(tags.label, props.path));
      }}
      value={props.value ? value : ''}
      styles={customStyles}
    />
  );
}
export default SelectTag;
