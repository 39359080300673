import axios from 'axios';
import url from '../../../config/endpoint';

export const getLinkedInData = (cb) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/get-linkedin`);
    dispatch({
      type: 'GET_LINKEDIN_DATA',
      payload: res.data,
    });
    cb();
  } catch (error) {
    cb();
  }
};

export const updateLinkedinData = (data, cb) => async (dispatch) => {
  try {
    let newData = {
      ...data,
      userId: data.oid,
    };
    await axios.post(`${url}/update-linkedin`, newData);
    dispatch({
      type: 'UPDATE_LINKEDIN_DATA',
      payload: data,
    });
    cb('OK');
    // getLinkedInData(cb);
  } catch (error) {
    cb('OK');
  }
};
