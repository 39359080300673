import { useWindowWidth } from '@react-hook/window-size';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { colors } from '../../commonComponents/colors';
import InfoIcon from '../../commonComponents/InfoIcon';
import TemplateSelector from '../../commonComponents/templateSelector/TemplateSelector';
import VideoModel from '../../commonComponents/videoModel';
import { getSuggestions } from '../../commonRedux/scan/action';
import FineTuningCard from '../scanresult/fineTuningCard';
import Editor from './editor';
import { styles } from './style';

function mapStateToProps({ fileScanReducer }) {
  return {
    scanResult: fileScanReducer.scanResult,
    selectedTitle: fileScanReducer.selectedTitle,
    scanResultStatus: fileScanReducer.scanResultStatus,
    updator: fileScanReducer.updator,
    content_loaders: fileScanReducer.content_loaders,
    resume: fileScanReducer.resume,
    scanReloadStatus: fileScanReducer.scanReloadStatus,
    showJobReplaceSection: fileScanReducer.showJobReplaceSection,
    VideoLinks: fileScanReducer.videoLinks,
  };
}
const checkIfDateErrors = (resume_quality) => {
  var isError = true;
  resume_quality.forEach((n) => {
    if (n['Level'] === 'Data Missing') {
      isError = false;
    }
  });
  return isError;
};

export default function FineTuning(props) {
  const { scanResult, resume, selectedTitle, VideoLinks } =
    useSelector(mapStateToProps);
  const is_parsed = scanResult?.is_parsed;
  const dispatch = useDispatch();
  const [suggestionsLoader, setSuggestionsLoader] = useState(false);
  const [isVideoModal, setIsVideoModal] = useState(false);

  const monthsOfExperience =
    resume?.other_info?.experience_summary?.MonthsOfWorkExperience || 0;
  React.useEffect(() => {
    dispatch(getSuggestions(selectedTitle, monthsOfExperience));
  }, []);
  // const skills =
  //   props?.suggestions?.Skills?.split('|')
  //     .filter((n) => n.trim() !== 'none')
  //     .filter((n) => !arr.includes(n.trim())) || [];
  const width = useWindowWidth();
  return (
    <div>
      <VideoModel
        open={isVideoModal}
        setOpen={setIsVideoModal}
        video={VideoLinks?.FineTune}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '15px',
          flexDirection: width < 500 ? 'column' : 'row',
          alignItems: 'center',
        }}
      >
        <InfoIcon
          onClick={() => setIsVideoModal(true)}
          text={'How To'}
          icon={'Play'}
          height={18}
          width="70px"
          marginTop={8}
          marginRight={5}
        />
        <div style={styles.resumeTitle(width)}>Fine-Tune to your Industry</div>
      </div>
      <Row>
        <Col lg="4" md="4" style={{ padding: '0 0 10px 10px' }}>
          <div
            style={{
              width: '100%',
              height: '25px',
              backgroundColor: colors.blue,
              borderRadius: '50px',
              border: '1px solid lightgray',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // padding: '1px 0',
            }}
          >
            <p
              style={{
                fontSize: width <= 767 ? '2.5vw' : '1vw',
                fontWeight: 600,
                color: colors.white,
                margin: 0,
              }}
            >
              Highlight your industry experience below
            </p>
          </div>
        </Col>
        <Col
          lg="8"
          md="8"
          style={{
            padding: 0,
            paddingBottom: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              // flexDirection: "row",
              flexWrap: width < 1050 ? 'wrap' : 'nowrap',
              alignItems: 'flex-start',
              gap: '10px',
              justifyContent: 'flex-end',
              marginLeft: '5px',
            }}
          >
            {/* <InfoIcon
              onClick={() => this.setState({ modelOpen: true })}
              text={'PRO TIP'}
              icon={'Bulb'}
              height={20}
              width="80px"
              marginTop={0}
            /> */}
            <span
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                padding: '2px 5px',
                borderRadius: '5px',
                border: '1px solid gray',
                backgroundColor: '#ffffff',
                minWidth: '260px',
                // marginTop: '7px',
              }}
            >
              {/* In the editor below add missing skills or keywords in the right
              context in the work experience section */}
              Using the editor below, add missing keywords in the appropriate
              context to the "Experience" section.
            </span>
            <span
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                padding: '2px 5px',
                borderRadius: '5px',
                border: '1px solid gray',
                backgroundColor: '#ffffff',
                minWidth: '260px',
              }}
            >
              Update missing items, format resume & go to NEXT step
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          lg="4"
          md="4"
          style={{ padding: width < 500 ? '0px' : '0px 0px 10px 10px' }}
        >
          <FineTuningCard
            cardTitle={'Industry Skills'}
            cardTitleKey={'Skills'}
            // suggestionsSkill={skills && skills}
            suggestions={props.suggestions?.['generalSkills']}
            oid={scanResult?.oid}
            splited="splited"
            loading={suggestionsLoader}
            setSuggestionsLoader={setSuggestionsLoader}
          />
          <FineTuningCard
            cardTitle={'Software Skills'}
            cardTitleKey={'Additional Skills'}
            suggestions={props?.suggestions?.['softwareSkills']}
            oid={scanResult?.oid}
            loading={suggestionsLoader}
            setSuggestionsLoader={setSuggestionsLoader}
          />
          <FineTuningCard
            cardTitle={'Industry Experience'}
            cardTitleKey={'Experience'}
            suggestions={props.suggestions?.['experience']}
            oid={scanResult?.oid}
            loading={suggestionsLoader}
            setSuggestionsLoader={setSuggestionsLoader}
          />
          <FineTuningCard
            cardTitle={'Certifications'}
            cardTitleKey={'Certifications'}
            suggestions={props.suggestions?.['certifications']}
            oid={scanResult?.oid}
            loading={suggestionsLoader}
            setSuggestionsLoader={setSuggestionsLoader}
          />
          <FineTuningCard
            cardTitle={'Associations'}
            cardTitleKey={'Associations'}
            suggestions={props.suggestions?.['associations']}
            oid={scanResult?.oid}
            loading={suggestionsLoader}
            setSuggestionsLoader={setSuggestionsLoader}
          />
        </Col>
        <Col lg="8" md="8">
          <Editor
            disableEditing={true}
            description={{
              resume: scanResult?.resumeText,
              job: scanResult?.jobDescription,
              is_parsed: is_parsed,
            }}
            oid={scanResult?.oid}
            resume={resume}
            step={props.step}
            getChance={
              <div
                style={{
                  height: 42,
                  backgroundColor: colors.blue,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p style={{ color: '#FFFFFF', fontweight: 'bold', margin: 0 }}>
                  You will get the chance to edit on next page
                </p>
              </div>
            }
          />
          {/* <TemplateSelector /> */}
        </Col>
      </Row>
    </div>
  );
}
