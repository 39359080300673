import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import './style.css';
import { templateList } from '../../helper/templates';
import { Col } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import checkedImg from '../../../../assets/green_checked.svg';
import { colors } from '../../../../commonComponents/colors';
import { useWindowSize } from '@react-hook/window-size';

const useStyles = makeStyles(() => ({
  img: {
    // border: '1px solid #4267B2',
    // transition: 'transform .5s ease',
    // boxShadow: '0 0 10px rgba(0,0,0,0.6)',
    // '-moz-box-shadow': '0 0 10px rgba(0,0,0,0.6)',
    // '-webkit-box-shadow': '0 0 10px rgba(0,0,0,0.6)',
    // '-o-box-shadow': '0 0 10px rgba(0,0,0,0.6)',
    // '&:hover': {
    //   transform: 'scale(1.2)',
    // },
    marginInline: 'auto',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: 'Roboto, sans-serif',
    color: '#4A4A4A',
    marginBottom: 10,
  },
  templateTitle: {
    fontSize: 15,
    marginTop: 5,
    marginBottom: 20,
    textAlign: 'left',
    fontWeight: 'bold',
  },
}));

export default ({ changeTemp, selectedTemp }) => {
  const classes = useStyles();
  // const defaultTemp = localStorage.getItem('defaultTemp');
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    spacing: 10,
    slidesPerView: 4,
    centered: false,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });
  const [width] = useWindowSize();
  return (
    <>
      <div
      // className="navigation-wrapper"
      >
        {/* <h2 className={classes.title}>Choose Template</h2> */}
        <div
          ref={sliderRef}
          className="keen-slider"
          style={{
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {templateList?.map((i, index) => {
            return (
              <>
                <div
                  key={index}
                  // className={'keen-slider__slide'}
                  style={{
                    cursor: 'pointer',
                    // backgroundColor: colors.white,
                    // width: '100%',
                  }}
                >
                  {/* <Col xxs="12" xs="6" md="4" lg="4"> */}
                  <div
                    style={{
                      padding: 10,
                      overflow: 'hidden',
                      // width: '260px',
                      width: 'fit-content',
                    }}
                    onClick={() => changeTemp(i.index)}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <h3 className={classes.templateTitle}>{i?.title}</h3>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: colors.fontGray,
                          fontSize: 8,
                          fontWeight: 'bold',
                        }}
                      >
                        Downloadable in:
                        <span
                          style={{
                            marginLeft: 5,
                            color: colors.white,
                            backgroundColor: colors.fontGray,
                            fontSize: 8,
                            fontWeight: 'lighter',
                            borderRadius: 9999,
                            padding: '2px 5px',
                          }}
                        >
                          WORD/PDF
                        </span>
                      </div>
                    </div>
                    <img
                      alt={i.id}
                      src={i.image}
                      width={
                        width < 760
                          ? 180
                          : width < 1080
                          ? 220
                          : width < 1400
                          ? 320
                          : 350
                      }
                      style={{
                        border:
                          selectedTemp === i.index
                            ? '1px solid #4267B2'
                            : 'none',
                      }}
                    />
                    {selectedTemp === i.index ? (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 5,
                          margin: '5px 0',
                        }}
                      >
                        <img
                          src={checkedImg}
                          style={{ width: 20, height: 20 }}
                        />
                        <div style={{ fontWeight: 'bold', fontSize: 14 }}>
                          Selected
                        </div>
                      </span>
                    ) : (
                      <div style={{ height: 30 }}></div>
                    )}
                  </div>
                  {/* </Col> */}
                </div>
              </>
            );
          })}
        </div>
        {/* {slider && (
          <>
            <ArrowLeft
              onClick={(e) => e.stopPropagation() || slider.prev()}
              disabled={currentSlide === 0}
            />

            <ArrowRight
              onClick={(e) => e.stopPropagation() || slider.next()}
              disabled={currentSlide === slider.details().size - 1}
            />
          </>
        )} */}
      </div>
    </>
  );
};

// function ArrowLeft(props) {
//   const disabeld = props.disabled ? ' arrow--disabled' : '';
//   return (
//     <svg
//       onClick={props.onClick}
//       className={'arrow arrow--left' + disabeld}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//     >
//       <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
//     </svg>
//   );
// }

// function ArrowRight(props) {
//   const disabeld = props.disabled ? ' arrow--disabled' : '';
//   return (
//     <svg
//       onClick={props.onClick}
//       className={'arrow arrow--right' + disabeld}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//     >
//       <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
//     </svg>
//   );
// }
