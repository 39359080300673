import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { SentryRoute } from '../../config/sentry';
const howToStartHandle = () => {
  return localStorage.getItem('howToStartKey');
};
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const howToStart = howToStartHandle();
  const { user } = useSelector((state) => state?.authUser);
  let shouldNavigate = [
    '/linkedin',
    '/jobmatcher',
    '/builder',
    '/milxwalk',
    '/dashboards',
  ];
  return (
    <SentryRoute
      exact
      {...rest}
      render={(props) =>
        localStorage.getItem('clientAccessToken') && restricted ? (
          localStorage.getItem('userType') ? (
            <Redirect to="/employerDashboard" />
          ) : user?.how_to_start || howToStart ? (
            <Redirect
              to={
                user?.last_visited
                  ? shouldNavigate.includes(user?.last_visited)
                    ? user?.last_visited
                    : '/optimize'
                  : '/optimize'
              }
            />
          ) : (
            <Redirect to="/how-to-start" />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
