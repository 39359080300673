import React from "react";
import STEP1 from "../assets/steps/step1.webp";
import STEP2 from "../assets/steps/step2.webp";
import STEP3 from "../assets/steps/step3.webp";
import STEP4 from "../assets/steps/step4.webp";
import STEP5 from "../assets/steps/step5.webp";
import STEP6 from "../assets/steps/step6.webp";
import STEP7 from "../assets/steps/step7.webp";
import STEP8 from "../assets/steps/step8.webp";
export function GetStepImage({ step, style }) {
  switch (step) {
    case 1:
      return (
        <img
          src={STEP1}
          style={{ ...style, width: 50, height: 25, objectFit: "center" }}
          alt="step1"
        />
      );
    case 2:
      return (
        <img
          src={STEP2}
          style={{ ...style, width: 50, height: 25, objectFit: "center" }}
          alt="step2"
        />
      );
    case 3:
      return (
        <img
          src={STEP3}
          style={{ ...style, width: 50, height: 25, objectFit: "center" }}
          alt="step3"
        />
      );
    case 4:
      return (
        <img
          src={STEP4}
          style={{ ...style, width: 50, height: 25, objectFit: "center" }}
          alt="step4"
        />
      );
    case 5:
      return (
        <img
          src={STEP5}
          style={{ ...style, width: 50, height: 25, objectFit: "center" }}
          alt="step5"
        />
      );
    case 6:
      return (
        <img
          src={STEP6}
          style={{ ...style, width: 50, height: 25, objectFit: "center" }}
          alt="step6"
        />
      );
    case 7:
      return (
        <img
          src={STEP7}
          style={{ ...style, width: 50, height: 25, objectFit: "center" }}
          alt="step7"
        />
      );
    case 8:
      return (
        <img
          src={STEP8}
          style={{ ...style, width: 50, height: 25, objectFit: "center" }}
          alt="step8"
        />
      );

    default:
      break;
  }
}
