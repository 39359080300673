import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { colors } from './colors';

const NotificationDialog = ({
  isOpen: showModal,
  onClose: setShowModal,
  notification,
}) => {
  if (!notification) {
    return null;
  }

  return (
    <Dialog
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        overflow: 'hidden',
        borderRadius: 10,
      }}
    >
      {/* Title */}
      <div
        style={{
          padding: '10px',
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h2
          style={{
            width: '100%',
            marginBottom: '10px',
            color: 'black',
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'Nunito'
          }}
        >
          {notification?.title}
        </h2>
        <CancelIcon
          onClick={() => {
            setShowModal(false);
          }}
          style={{
            color: colors.red,
            cursor: 'pointer',
            fontSize: 24,
          }}
        />
      </div>
      <DialogContent
        style={{
          //   minHeight: '200px',
          minWidth: '300px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {/* Message */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <p
            style={{
              color: colors.fontGray,
              fontSize: '1rem',
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
          >
            {notification?.description}
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationDialog;
