import LinkedInJobLocations from "../../assets/linkedinJobLocations.webp";
import LinkedInJobFind from "../../assets/linkedinJobFind.webp";
import LinkedInJobTitles from "../../assets/linkedinJobTitles.webp";
import LinkedinWorkPlace from "../../assets/linkedinWorkPlace.webp";
import LinkedinStartDate from "../../assets/linkedinStartDate.webp";
export const InfoHelper = {
  step1: {
    title: "Copy Linkedin Profile",
    p1: "1: If you have not copied to your LinkedIn profile, click 'previous' button to go back and complete.",
  },

  copyContent: {
    title: "Copied Content to LinkedIn Profile",
    p1: "1: If you have not copied to your LinkedIn profile, click 'previous' button to go back and complete.",
    p2: "2: Go to your LinkiedIn profile page Me>View Profile",
    p3: "3: Go to “Your Dashboard” section",
    p4: "4: Click “Open to” & “Finding a new job”",
    p5: "5: All star status mean you completed your profile",
  },
  profilePhoto: {
    title: "Update profile photo with a professional picture?",
    p1: "1: Click on LinkedIn profile photo",
    p2: "2: You will be presented option to upload new photo.",
    p3: "3: Once uploaded you can edit save.",
    p4: "4: Easiest way to create a professional picture is to stand against a plain wall in your house that’s directly facing a window. Have someone take a picture at eyelevel or use timer option to take your own picture.",
    p5: "5: Wear “business casual” or “business professional” attire depending on your industry.",
  },
  profilePage: {
    title: "Update setting: “Open to” & “Finding a new job”.",
    p1: "1: Go to your LinkiedIn profile page Me>View Profile.",
    p2: "2: Under your name, look for blue button named “Open to.",
    p3: "3: Click that and click “Finding a new job",
    p4: "4: Fill each of the options desired in your new position.",
    p5: "5: Important fields are “Job Titles” and “Location(s)”, for locations always select larger area such as “San Francisco Bay Area” or “Los Angeles Metro Area” or “New York City Metro Area.",
  },
  allStar: {
    title: "Verify LinkedIn profile status",
    p1: "1: Go to your LinkiedIn profile page Me>View Profile",
    p2: "2: Go to “Your Dashboard” sectio.",
    p3: "3: On the right side you should see “All Star” icon",
    p4: "4: All star status mean you completed your profile",
  },
};

export const getLinkedInImage = (name) => {
  let title = "";
  let image = "";
  let text = "";

  switch (name) {
    case "JobTitles":
      title = "Job Titles";
      image = LinkedInJobTitles;
      text =
        "Add job titles you’re looking for. Type titles in the grey bubble called Add title +";
      break;
    case "WorkPlaces":
      title = "Work Places";
      image = LinkedinWorkPlace;
      text =
        "Select workplaces you are looking for. If you are looking for “Remote” only work, please select Remove and remove On-site and Hybrid. +";
      break;
    case "JobLocations":
      title = "Job Locations";
      image = LinkedInJobLocations;
      text =
        "Please add locations you are interested in for the new job. Select “greater” or “metro area” of the  region to get more exposure. ";

      break;
    case "StartDate":
      title = "Start Date";
      image = LinkedinStartDate;
      text = "Add your start date preference.";
      break;
    case "WhoSees":
      title = "Choose who sees you're open";
      image = LinkedInJobFind;
      text =
        "Finally, you will have the option to Choose who sees you’re open. Choose from All LinkedIn members or just Recruiters. ";
      break;
    default:
      image = "";
      break;
  }
  return {
    image,
    text,
    title,
  };
};
