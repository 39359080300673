import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from 'antd';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CreateIcon from '@material-ui/icons/Create';
// import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
// import { EditorState, ContentState } from 'draft-js';
import { styles } from './style';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import TagsInput from 'react-tagsinput';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import CoverLetterEditor from '../scan/coverLetterEditor';
import CancelIcon from '@material-ui/icons/Cancel';
import { Tooltip } from '@material-ui/core';
import { colors } from '../../commonComponents/colors';

let convertedNotification = {};

function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [title, setTitle] = React.useState(props.detail?.title);
  // const [convertedNotification, setconvertedNotification] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [newText, setNewText] = useState('');
  React.useEffect(() => {
    setNewText(props.detail?.description);
    if (props.detail?.tags) setTags(props.detail?.tags);
    convertedNotification[props.detail.oid] = props.detail?.description;
  }, []);
  const handleClickOpen = () => {
    // if (props.detail.description.trim() !== '') {
    //   const setprocessedHTML = DraftPasteProcessor.processHTML(
    //     props.detail.description,
    //   );
    //   const setcontentState =
    //     ContentState.createFromBlockArray(setprocessedHTML);
    //   setEditorState = EditorState.createWithContent(setcontentState);
    //   setEditorState = EditorState.moveFocusToEnd(setEditorState);
    // } else {
    //   // setEditorState = EditorState.createEmpty();
    // }
    // setconvertedNotification(setEditorState);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const onEditorStateChange = (convertedNotification) => {
  //   setconvertedNotification(convertedNotification);
  // };

  const updateText = (text) => {
    setNewText(text);
  };

  const updateResume = () => {
    // let descConverted = draftToHtml(
    //   convertToRaw(convertedNotification.getCurrentContent())
    // );
    if (props.detail?.isDefault) {
      let data = {
        title: title,
        tags: tags,
        description: newText,
      };
      props.addCoverLetter(data);
      // props.getCoverData(1);
    } else {
      setLoader(true);
      let data = {
        oid: props.detail.oid,
        title: title,
        tags: tags,
        description: newText,
      };

      props.UpdateCoverLetter(data, () => setLoader(false));
      props.getCoverData(1);
    }
    setOpen(false);
  };

  return (
    <div style={styles.notesModel}>
      <Tooltip title='Edit'>
        {props.detail?.isDefault ? (
          <FileCopyIcon
            onClick={handleClickOpen}
            className="hovdel"
            style={styles.notesModelIcon}
          />
        ) : (
          <CreateIcon
            onClick={handleClickOpen}
            className="hovdel"
            style={styles.notesModelIcon}
          />
        )}
      </Tooltip>
      <Dialog
        open={open}
        // fullScreen
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito'
            }}
          >
            Your Cover Letter
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Add Title"
          />
          <InputGroup style={{ ...styles.inputGroup, marginTop: 10 }}>
            <InputGroupAddon addonType="prepend">
              <span className="input-group-text">Tags</span>
            </InputGroupAddon>

            <TagsInput
              value={tags}
              onChange={(tags) => {
                setTags(tags);
              }}
              addOnBlur
              inputProps={{
                id: 'title',
                placeholder: 'Add Tags',
              }}
            />
          </InputGroup>
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              width: '100%',
              height: '90vh',
              paddingRight: 30,
              paddingLeft: 5,
            }}
          >
            <CoverLetterEditor
              onSubmitFun={updateText}
              loader={loader}
              defaultText={convertedNotification[props.detail?.oid] || ''}
            />
            {/* <Col lg="12" style={{ padding: 0 }}>
              <CustomEditor
                comingFrom={"showCoverLetter"}
                oid={props?.detail?.oid}
                defaultText={convertedNotification[props?.detail?.oid] || ""}
              />
            </Col> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateResume} color="primary" variant="contained" style={{
            backgroundColor: colors.blue,
            color: colors.white,
            height: '40px',
          }}
          >
            {props.detail?.isDefault ? 'Add' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;
