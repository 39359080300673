import axios from 'axios';
import toastr from 'toastr';

import {
  GET_SUGGESTED_INFORMATION,
  JOB_OCCUPATION_FAILED,
  JOB_OCCUPATION_LOADER,
  JOB_OCCUPATION_UPDATE,
  SUGGESTED_INFORMATION_LOADER,
  MILITARY_LOADING,
  GET_SUBTITLES,
  ON_ADD_SELECTED_SUBTITLE,
  UPDATE_MILXWALK_DATA,
  MILITARY_DATA_API_STATUS,
  RESET_LOADING,
  CLEAR_MILITARY_DATA,
  GET_MILXWALK_DETAIL,
  GET_MILXWALK_DETAIL_LOADING,
  JOB_SUBTITLE_LOADER,
  UPDATE_MILXWALK_ACTIVE_STEP,
  JOB_OCCUPATION_EMPTY,
} from './actions.constants';
import url from '../../../config/endpoint';
export const getSubtitles = (data, cb) => async (dispatch) => {
  try {
    dispatch({
      type: JOB_SUBTITLE_LOADER,
    });
    const res = await axios.post(`${url}/get-milXwalk-subtitles`, data);
    if (res.status === 200) {
      const subTitles = res?.data?.subTitles;

      dispatch({
        type: GET_SUBTITLES,
        payload: subTitles,
      });
      if (subTitles?.length === 1) {
        dispatch({
          type: ON_ADD_SELECTED_SUBTITLE,
          payload: subTitles[0],
        });
        localStorage.setItem('selectedSubtitle', JSON.stringify(subTitles));
        localStorage.setItem('milXWalkData', JSON.stringify(data));
        cb && cb();
      }
    } else {
      toastr.error(res?.data?.message || 'Something went wrong!');
    }
  } catch (error) {
    console.error('Error while getting Subtitles:', error);
    toastr.error(error?.message || 'Something went wrong!');
  } finally {
    dispatch({
      type: JOB_SUBTITLE_LOADER,
    });
  }
};
export const getMilxWalkDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MILXWALK_DETAIL_LOADING,
    });
    const res = await axios.post(`${url}/milXwalk-detail-data`, data);
    if (res?.data?.status === 200) {
      dispatch({
        type: GET_MILXWALK_DETAIL,
        payload: res?.data?.data,
      });
    } else {
      toastr.error(res.data?.message || 'Something went wrong!');
    }
  } catch (err) {
    console.error('error while getting military detail:', err);
    toastr.error('Something went wrong!');
  } finally {
    dispatch({
      type: GET_MILXWALK_DETAIL_LOADING,
    });
  }
};
export const getJobOccupation =
  (data = {}, cb) =>
  async (dispatch) => {
    try {
      dispatch({
        type: JOB_OCCUPATION_LOADER,
        payload: true,
      });

      let res = await axios.post(`${url}/get-job-occupations`, data);

      if (res?.data?.status === 200) {
        if (res?.data?.jobOccupations?.length === 0) {
          dispatch({
            type: JOB_OCCUPATION_EMPTY,
            payload: true,
          });
          localStorage.setItem('milXWalkActiveStep', 0);
          dispatch({
            type: UPDATE_MILXWALK_ACTIVE_STEP,
            payload: 0,
          });
          setTimeout(() => {
            dispatch({
              type: JOB_OCCUPATION_LOADER,
              payload: false,
            });
          }, 200);
          return;
        } else {
          dispatch({
            type: JOB_OCCUPATION_UPDATE,
            payload: res?.data?.jobOccupations,
          });
          setTimeout(() => {
            dispatch({
              type: JOB_OCCUPATION_LOADER,
              payload: false,
            });
          }, 200);
          cb && cb();
        }
      } else {
        toastr.error(res?.data?.message || 'Something went wrong!');
        dispatch({
          type: JOB_OCCUPATION_FAILED,
          payload: res?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: JOB_OCCUPATION_FAILED,
        payload: error,
      });
    }
  };
export const updateMilitaryInfo = (data, cb) => async (dispatch) => {
  try {
    let res = await axios.post(`${url}/update-milXwalk-refresh-data`, data);
  } catch (error) {
    console.error('error while updating data', error);
  }
};

export const getMilitaryInfo = (data, cb) => async (dispatch) => {
  try {
    dispatch({
      type: MILITARY_DATA_API_STATUS,
      payload: true,
    });
    dispatch({
      type: MILITARY_LOADING,
      payload: true,
    });
    let res = await axios.post(`${url}/get-milXwalk-refresh-data`, data);

    if (res?.data?.status === 200 && res?.data?.data) {
      dispatch({
        type: UPDATE_MILXWALK_DATA,
        payload: res?.data?.data,
      });
    } else {
      dispatch({
        type: CLEAR_MILITARY_DATA,
      });
      localStorage.setItem('milXWalkActiveStep', '0');
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({
      type: MILITARY_LOADING,
      payload: false,
    });
  }
};

export const resetMilitaryInfo = (data, cb) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_LOADING,
      payload: true,
    });

    let res = await axios.post(`${url}/reset-milXwalk-refresh-data`, data);
    if (res?.data?.status === 200) {
      cb && cb();
    } else {
      toastr.error(res?.data?.message || 'Something went wrong while reset');
    }
  } catch (error) {
    console.log('error while reset:', error);
    toastr.error(error?.message || 'Something went wrong while reset');
  } finally {
    dispatch({
      type: RESET_LOADING,
      payload: false,
    });
  }
};

export const getSuggestedInformation = (data, cb) => async (dispatch) => {
  try {
    dispatch({
      type: SUGGESTED_INFORMATION_LOADER,
      payload: true,
    });

    let res = await axios.post(`${url}/suggested-information`, data);
    if (res?.data?.status === 200) {
      dispatch({
        type: GET_SUGGESTED_INFORMATION,
        payload: res?.data,
      });
      setTimeout(() => {
        dispatch({
          type: SUGGESTED_INFORMATION_LOADER,
          payload: false,
        });
      }, 10000);
    } else {
      toastr.error(
        res?.data?.message ||
          'Something went wrong while getting Suggested Information',
      );
      dispatch({
        type: SUGGESTED_INFORMATION_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.error('Error while getting Suggested Information:', error);
  } finally {
    dispatch({
      type: MILITARY_LOADING,
      payload: false,
    });
    cb && cb();
  }
};
