import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardTitle,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import { Popup } from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CreateIcon from '@material-ui/icons/Create';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateLinkedinData } from '../redux/actions';
import uuid from 'react-uuid';

import { useWindowSize } from '@react-hook/window-size';
import moment from 'moment';
const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40px',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};

export default function Certifications(props) {
  const [width] = useWindowSize();
  const [item, setItem] = useState({});
  const [copied, setCopied] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [issuer, setIssuer] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const linkedinData = useSelector(
    (state) => state.linkedinReducer.linkedinData,
  );
  const certificationsModal = useSelector(
    (state) => state.linkedinReducer.certificationsModal,
  );
  const certificationsModalMode = useSelector(
    (state) => state.linkedinReducer.certificationsModalMode,
  );

  useEffect(() => {
    if (certificationsModalMode === 'new')
      setItem({
        id: uuid(),
      });
    setId(uuid());
    setTitle('');
    setIssuer('');
    setIssueDate('');
  }, [certificationsModalMode]);

  const dispatch = useDispatch();
  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };

  const cb = (v) => {
    dispatch({
      type: 'CERTIFICATIONS_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Certifications Updated');
    } else {
      toastr.error('Error Updating Certifications');
    }
  };
  const handleUpdate = () => {
    setLoading(true);
    let certificationAndLicense = linkedinData?.certificationAndLicense?.filter(
      (item) => item.id !== id,
    );

    const newCertData = {
      ...item,
      title,
      issuer,
      issueDate,
    };
    certificationAndLicense.push(newCertData);

    let data = { ...linkedinData, certificationAndLicense };

    dispatch(updateLinkedinData(data, cb));
  };

  const clearCopied = () => {
    setCopied([]);
  };

  return (
    <Card body className="text-left mb-4" style={styles.border_radius_5}>
      <CardTitle className="font-weight-bold">
        {/* <h5>Certifications</h5> */}
      </CardTitle>
      {props.certData?.length
        ? props.certData.map((item, index) => {
            return (
              <Row key={index} className="mt-2">
                <Col
                  xs={10}
                  lg={11}
                  xl={11}
                  md={11}
                  className="border-bottom p-5"
                >
                  <p style={styles.title}>{item?.title}</p>
                  <p style={styles.sub}>{item?.issuer}</p>
                  {item?.issueDate && (
                    <p style={styles.years}>
                      Date: {moment(item?.issueDate).format('MM/DD/YY')}
                    </p>
                  )}
                </Col>
                <Col
                  xs={2}
                  lg={1}
                  xl={1}
                  md={1}
                  className="border-bottom p-2 d-flex align-items-center justify-content-center"
                >
                  <span
                    style={styles.copyIcon}
                    onClick={() => {
                      setTitle(item?.title);
                      setIssuer(item?.issuer);
                      setIssueDate(item?.issueDate);
                      setId(item?.id);

                      setItem(item);

                      dispatch({
                        type: 'CERTIFICATIONS_MODAL',
                        payload: {
                          open: true,
                          mode: 'edit',
                        },
                      });
                    }}
                  >
                    {props.stepIndex === 1 ? (
                      <CreateIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    ) : (
                      <FileCopyIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    )}
                  </span>
                </Col>
              </Row>
            );
          })
        : 'NO DATA AVAILABLE'}

      <Popup
        open={certificationsModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'CERTIFICATIONS_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'CERTIFICATIONS_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5>Certifications</h5>

        <Row>
          <Col>
            <Label for="exampleEmail">Name</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(title) ? (
                    <CopyToClipboard
                      text={title}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(title);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Issuing organization
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={issuer}
                onChange={(e) => setIssuer(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(issuer) ? (
                    <CopyToClipboard
                      text={issuer}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(issuer);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Issue date
            </Label>
            <InputGroup>
              <Input
                type="date"
                style={{ height: '50px' }}
                value={issueDate}
                onChange={(e) => setIssueDate(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(issueDate) ? (
                    <CopyToClipboard
                      text={issueDate}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(issueDate);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={loading}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  {loading ? 'Saving' : 'Save'}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}
