import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';

import { Colxx } from '../../../commonComponents/customBootstrap';
import { MdClear } from 'react-icons/md';
import { GoDiffRemoved, GoDiffAdded } from 'react-icons/go';
import {
  addSkillsAction,
  removeSkillsAction,
} from '../../../commonRedux/scan/action';
import { styles } from '../style';
import { colors } from '../../../commonComponents/colors';

class Hardskills extends Component {
  state = {
    showButtons: '',
  };

  addSkills = (key) => {
    const res = { oid: this.props.oid, skills: { ...this.props.skills } };
    res['skills'][key].exists = true;
    this.props.addSkillsAction(res, key);
    this.setState({ showButtons: '' });
  };

  removeSkills = (key) => {
    const res = { oid: this.props.oid, skills: { ...this.props.skills } };
    delete res['skills'][key];
    this.props.removeSkillsAction(res, key);
  };
  render() {
    return (
      <div>
        <Colxx xxs="12" sm="12" lg="12">
          <Card className="mb-4">
            <CardBody>
              <CardTitle style={styles.fontWeight_400}>HARD SKILLS</CardTitle>
              <Table hover style={{ fontSize: '.75rem' }}>
                <thead>
                  <tbody style={styles.fontWeight_400}>
                    <th>SKILL</th>

                    <th style={styles.textAlignCenter}>RESUME</th>
                    <th style={styles.textAlignCenter}> JOB DESCRIPTION</th>
                  </tbody>
                </thead>
                <tbody>
                  {Object.keys(this.props.skills)?.map((key, index) => {
                    return (
                      <tr
                        key={index}
                        onMouseEnter={() => this.setState({ showButtons: key })}
                        onMouseLeave={() => this.setState({ showButtons: '' })}
                      >
                        <td style={{ color: '#145388', ...styles.relative }}>
                          <span>
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </span>
                          {this.state.showButtons === key ? (
                            this.props.skills[key].wanted &&
                            !this.props.skills[key].exists ? (
                              <GoDiffAdded
                                style={{
                                  ...styles.absolute,
                                  ...styles.left50,
                                  ...styles.inline,
                                }}
                                size="1.7em"
                                color={colors.green}
                                onClick={() => this.addSkills(key)}
                              />
                            ) : !this.props.skills[key].wanted &&
                              this.props.skills[key].exists ? (
                              <GoDiffRemoved
                                style={{
                                  ...styles.absolute,
                                  ...styles.left50,
                                  ...styles.inline,
                                }}
                                size="1.7em"
                                color={colors.red}
                                onClick={() => this.removeSkills(key)}
                              />
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </td>

                        <td style={styles.textAlignCenter}>
                          {this.props.skills[key].ResumeCount === 0 ? (
                            <MdClear color={colors.red} style={styles.inline} />
                          ) : (
                            this.props.skills[key].ResumeCount
                          )}
                        </td>
                        <td style={styles.textAlignCenter}>
                          {this.props.skills[key].JobCount}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Colxx>
      </div>
    );
  }
}

export default connect(null, { addSkillsAction, removeSkillsAction })(
  Hardskills,
);
