import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { styles } from "./style";

export default class Jobs extends Component {
  state = {
    num: "6",
  };
  render() {
    const { job } = this.props;

    const goTo = (url) => {
      window.open(`${url}`, "_blank").focus();
    };

    return (
      <div>
        <div className="text-center">
          <div className="content">
            <div style={styles.content}>
              <center>
                <table style={styles.table} cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td align="left" valign="center" style={styles.td}>
                        <h3 style={{ fontWeight: 'bold' }}>{job.name}</h3>
                        <h3 style={styles.h3}>
                          {job.salary_min && job.salary_max
                            ? `$ ${job.salary_min} to ${job.salary_max} `
                            : ""}
                          {job.salary_interval}
                        </h3>
                      </td>
                    </tr>

                    <tr>
                      <td style={styles.td2}>
                        <i className="simple-icon-calendar" />
                        <span style={styles.span}>
                          Posted time: {job.posted_time.slice(0, 10)} ({job.posted_time_friendly
                          })
                        </span>

                        <p style={styles.p}>{ReactHtmlParser(job.snippet?.replace('...', ""))}</p>

                        <span style={styles.span2}>
                          {job.hiring_company.name}
                        </span>
                        <span style={styles.span3}>{job.location}</span>
                      </td>
                    </tr>

                    <tr>
                      <td style={{ colSpan: 2 }}>
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          style={styles.table2}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <table
                                  cellPadding="0"
                                  style={styles.table3}
                                  cellSpacing="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          align: "left",
                                        }}
                                        valign="left"
                                      >
                                     
                                          <button
                                            onClick={() => {
                                              goTo(job.url);
                                              // this.props.pushToReview.push(
                                              //   "/",
                                              //   {
                                              //     joburl: job.url,
                                              //     cameFromJobMatcher: "yes",
                                              //   }
                                              // );
                                            }}
                                            title="Click NOW"
                                            style={styles.btnJob}
                                          >
                                            Apply Now
                                          </button>
                                       
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
