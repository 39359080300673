import React from 'react';
import { Modal, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UPGRADE_PLAN_MODAl } from '../../modules/auth/redux/constants';
import './style.css';
import CancelIcon from '@material-ui/icons/Cancel';

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showUpgradeModal } = useSelector((state) => ({
    showUpgradeModal: state?.authUser?.showUpgradeModal,
  }));

  const onUpgrade = () => {
    dispatch({
      type: UPGRADE_PLAN_MODAl,
      payload: false,
    });
    history.push('/account?subscription');
  };
  return (
    <Modal
      centered
      isOpen={showUpgradeModal}
      toggle={() => {
        dispatch({
          type: UPGRADE_PLAN_MODAl,
          payload: false,
        });
      }}
      size="md"
    >
      <div className="upgrade-plane-main">
        <CancelIcon
          className="cross-icon"
          onClick={() => {
            dispatch({
              type: UPGRADE_PLAN_MODAl,
              payload: false,
            });
          }}
        />
        <h2 className="upgrade-heading">
          Access this feature and <br /> fast-track your job search!
        </h2>
        <Button style={{ marginTop: 20 }} onClick={onUpgrade} color="primary">
          Upgrade Now
        </Button>
      </div>
    </Modal>
  );
};
