import React from 'react';
import { Text, Dnd } from '../../component';
import { useStyles } from '../style';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateOrder, addNewObj, deleteObjInArray } from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import { colors } from '../../../../commonComponents/colors';

export default function NewAdditionalSkills(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'additional_skills.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;

  const handledData = (() => {
    if (!data?.length) {
      return [
        {
          Name: '',
        },
      ];
    }

    // Check if data is Array of Strings
    if (typeof data[0] === 'string') {
      return data?.map((item) => {
        return {
          Name: item,
        };
      });
    }

    return data;
  })();

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ fontSize: 16, fontWeight: 'bold' }}
          >
            Additional Skills
          </label>
        </div>
        <Dnd
          data={handledData}
          reorder={(e) => onOrderUpdate(e)}
          additem={_addNewItem}
          removeitem={(index) => _removeItem(index)}
          renderItem={(item, index) => (
            <div>
              <label className={classes.label}>Additional Skill Name</label>
              <Text
                value={item?.Name}
                placeholder="Additional Skill Name"
                customclass={classes.input}
                path={`${path}.${index}.Name`}
              />
            </div>
          )}
        />
        <div
          onClick={_addNewItem}
          style={{
            color: colors.blue,
            marginTop: 40,
            fontWeight: 'bold',
            fonSize: 18,
            cursor: 'pointer',
          }}
        >
          + Add one more Additional Skill
        </div>
      </Grid>
    </>
  );
}
