import React from 'react'
import { Colxx } from '../../commonComponents/customBootstrap'
import { Row, Card, CardBody, Table, CardTitle } from "reactstrap";
import { styles } from './style';
import { useWindowWidth } from '@react-hook/window-size';
import { colors } from '../../commonComponents/colors';
import { MdCheck, MdClear } from 'react-icons/md';

export default function WordsToAvoid(props) {
    const width = useWindowWidth();
    let headingStyle = { fontSize: 16 };

    return (
        <div>
            <Row>
                <Colxx xxs="12" sm="12" lg="12">
                    <Card className="mb-2">
                        <CardBody style={{
                            ...styles.recruiter_cardBody,
                            background: '#EDF0F7',
                            border: '1px solid lightgray',
                            paddingTop: 15,
                            paddingBottom: 15,
                        }}>
                            <CardTitle style={{
                                maxWidth: width < 500 ? "220px" : "400px",
                                ...styles.fontWeight_heading,
                                ...headingStyle,
                                marginBottom: 7,
                            }}>
                                WORDS TO AVOID
                            </CardTitle>
                            <Table style={{ tableLayout: "fixed", overflow: 'auto' }}>
                                <tbody style={{ display: "block" }}>
                                    <tr style={{
                                        ...styles.colorGrey,
                                        fontWeight: 'bold',
                                        display: "flex",
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}>
                                        {
                                            props.scores?.avoiding_words?.length ? <>
                                                <MdClear size="18px" color={colors.red} style={{
                                                    marginRight: 10,
                                                }} />
                                                {"Avoid using \"" + props.scores?.avoiding_words.join(', ') + "\""}
                                            </> : <>
                                                <MdCheck size="18px" color={colors.green} style={{
                                                    marginRight: 10,
                                                }} />
                                                NO WORDS TO AVOID IN THE RESUME.
                                            </>
                                        }
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        </div>
    )
}