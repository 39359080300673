const data = [
  {
    id: 'MilxWalk',
    icon: 'simple-icon-magnifier',
    label: 'milXwalk',
    to: '/milxwalk',
  },
  {
    id: 'REVIEW',
    icon: 'simple-icon-magnifier',
    label: 'Optimize',
    to: '/optimize',
  },
  {
    id: 'DASHBOARD',
    icon: 'simple-icon-reload',
    label: 'Dashboard',
    to: '/dashboards',
  },
  {
    id: 'LINKEDIN',
    icon: 'simple-icon-social-linkedin',
    label: 'LinkedIn',
    to: '/linkedin',
  },
  {
    id: 'JOBMATCHER',
    icon: 'simple-icon-magnifier',
    label: 'Job Finder',
    to: '/jobmatcher',
  },
  {
    id: 'BUILDER',
    icon: 'simple-icon-graduation',
    label: 'Builder',
    to:
      localStorage.getItem('builderVisited') === 'visited'
        ? '/how-to-start'
        : '/questionnaire/work_experience',
  },

  // {
  //   id: "MANAGE",
  //   icon: "simple-icon-handbag",
  //   label: "Manage",
  //   to: "/templates",
  // },
];
export default data;
