import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function Loader({ size }) {
  return (
    <div
      style={{
        width: '80vw',
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={size ? size : 80} />
    </div>
  );
}
