import React from 'react';
import PlayIcon from '../assets/PlayIcon.svg';
import BulbIcon from '../assets/BulbIcon.svg';
import HowToIcon from '../assets/img/howto.svg';
import ProTipIcon from '../assets/img/protip.svg';

export default function InfoIcon({
  icon,
  height,
  width,
  marginTop,
  marginLeft,
  marginRight,
  onClick,
}) {
  width = width ? width : 'auto';
  height = height ? height : 'auto';
  return icon === 'Play' ? (
    <img
      onClick={onClick ? onClick : () => { }  }
      src={HowToIcon}
      alt="icon"
      style={{
        width: width,
        height: height,
        marginTop: marginTop,
        ...(marginLeft && { marginLeft: marginLeft }),
        ...(marginRight && { marginRight: 5 }),
        cursor: 'pointer',
      }}
    />
  ) : (
    <img
      onClick={onClick}
      src={ProTipIcon}
      alt="icon"
      style={{
        width: width - 3,
        height: height - 5,
        marginTop: marginTop,
        marginLeft: marginLeft,
      }}
    />
  );

  // <div
  //   style={{
  //     width: width,
  //     marginTop: marginTop,
  //     marginLeft: '5px',
  //     height: height,
  //     backgroundColor: '#fff',
  //     border: '3px solid #ff8f3a',
  //     display: 'flex',
  //     justifyContent: 'space-between',
  //     alignItems: 'center',
  //     textAlign: 'center',
  //     borderRadius: '6px',
  //     cursor: 'pointer',
  //   }}
  //   onClick={onClick}
  // >
  //   <div
  //     style={{
  //       display: 'flex',

  //       justifyContent: 'center',
  //       alignItems: 'center',
  //     }}
  //   >
  //     <span style={styles.textContainer}> {text}</span>
  //   </div>

  //   <div style={styles.iconContainer}>
  //     {icon === 'Play' ? (
  //       <img
  //         src={PlayIcon}
  //         alt="Play Icon"
  //         height={'20px'}
  //         style={{ width: '8px', height: '8px' }}
  //       />
  //     ) : (
  //       <img
  //         src={BulbIcon}
  //         alt="Bulb Icon"
  //         height={'20px'}
  //         style={{ width: '13px', height: '13px', marginRight: '-1px' }}
  //       />
  //     )}
  //   </div>
  // </div>
}
