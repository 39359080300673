import React from "react";
import ContentLoader from "react-content-loader";
import { Grid } from "@material-ui/core";

export default function formDataLoader(props) {
  return (
    <Grid container spacing={3}>
      <Grid item md={8} lg={8} xl={8}>
        <ContentLoader
          width={"100%"}
          height={"100%"}
          viewBox="0 0 400 150"
          backgroundColor="#d9d9d9"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="15" y="15" rx="4" ry="4" width="130" height="10" />
          <rect x="155" y="15" rx="3" ry="3" width="130" height="10" />
          <rect x="295" y="15" rx="3" ry="3" width="90" height="10" />
          <rect x="15" y="50" rx="3" ry="3" width="90" height="10" />
          <rect x="115" y="50" rx="3" ry="3" width="60" height="10" />
          <rect x="185" y="50" rx="3" ry="3" width="200" height="10" />
          <rect x="15" y="90" rx="3" ry="3" width="130" height="10" />
          <rect x="160" y="90" rx="3" ry="3" width="120" height="10" />
          <rect x="290" y="90" rx="3" ry="3" width="95" height="10" />
          <rect x="15" y="130" rx="3" ry="3" width="130" height="10" />
          <rect x="160" y="130" rx="3" ry="3" width="225" height="10" />
        </ContentLoader>
      </Grid>
      <Grid item md={4} lg={4} xl={4}>
        <ContentLoader
          viewBox="0 0 500 420"
          height={420}
          width={400}
          {...props}
        >
          <rect x="32" y="17" rx="0" ry="0" width="360" height="400" />
        </ContentLoader>
      </Grid>
    </Grid>
  );
}
