import React from 'react';
import { Card, CardBody } from 'reactstrap';
import DashBoard from '../recent-history';
import DashboardTable from '../recent-history/table';
import { styles } from './style';

export default function MostRecentReviews(props) {
  return (
    <div>
      {props.data.length ? (
        <div>
          {props.sliceMostRecent
            ? props.sliceMostRecent.map((details, index) => {
                return (
                  <DashBoard key={index} details={details} index={index} />
                );
              })
            : ''}
          <div>
            <DashboardTable data={props.reverseData} />
            {/* <DashboardTable data={reverseData.slice(1)} /> */}
          </div>
        </div>
      ) : (
        <Card>
          <CardBody style={styles.fontWeight_500}>No Scans to Show!</CardBody>
        </Card>
      )}
    </div>
  );
}
