import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import ATSfindings from '../scanresult/atsfindings';
import JobRequirementsMatch from '../scanresult/JobRequirementsMatch';
import ResumeResult from '../scanresult/resume-results';
import HardSkills from '../scanresult/hardskills';
import TopProgress from '../resumeBuilder/component/topProgress';
import Editor from './editor';
import { styles } from './style';
import RightIconButton from '../../commonComponents/RightIconButton';
import Bulb from '../../assets/bulb.webp';
import VideoModel from '../../commonComponents/videoModel';
import WordsToAvoid from '../scanresult/wordsToAvoid';
import howToIcon from '../../assets/How-to.svg';
import InfoIcon from '../../commonComponents/InfoIcon';
import { useWindowWidth } from '@react-hook/window-size';
import { Dialog } from '@material-ui/core';

function mapStateToProps({ fileScanReducer }) {
  return {
    scanResult: fileScanReducer.scanResult,
    scanResultStatus: fileScanReducer.scanResultStatus,
    updator: fileScanReducer.updator,
    content_loaders: fileScanReducer.content_loaders,
    resume: fileScanReducer.resume,
    scanReloadStatus: fileScanReducer.scanReloadStatus,
    showJobReplaceSection: fileScanReducer.showJobReplaceSection,
    VideoLinks: fileScanReducer.videoLinks,
  };
}

const checkIfDateErrors = (resume_quality) => {
  var isError = true;
  resume_quality.forEach((n) => {
    if (n['Level'] === 'Data Missing') {
      isError = false;
    }
  });
  return isError;
};

function UpdateEditEditor(props) {
  const [totalSkills, setTotalSkills] = React.useState(0);
  const { scanResult, resume, VideoLinks } = useSelector(mapStateToProps);
  const [modelOpen, setModelOpen] = React.useState(false);
  const is_parsed = scanResult?.is_parsed;
  const isMeaurable = 50;
  const width = useWindowWidth();
  const dateError = checkIfDateErrors(resume?.other_info?.resume_quality ?? []);
  const monthsOfExperience =
    resume?.other_info?.experience_summary?.MonthsOfWorkExperience || 0;
  React.useEffect(() => {
    setTotalSkills(
      Object.keys(scanResult?.skillsData?.hard_skills)?.length &&
        Object.keys(scanResult?.skillsData?.soft_skills)?.length,
    );
  }, []);

  React.useEffect(() => {
    if (scanResult?.jobTitle && !props.fineTuneSkills?.length) {
      props.getSuggestions(scanResult?.jobTitle, monthsOfExperience);
    }
  }, [scanResult?.jobTitle]);

  let allTrue = [
    scanResult?.ats?.ats_data?.sections?.length,
    dateError,
    scanResult?.ats?.ats_data?.job_level_match?.isMatched,
    scanResult?.ats?.ats_data?.creative_bullets,
    isMeaurable,
    scanResult?.ats?.email,
    scanResult?.ats?.mobile_number,
    resume?.data?.profile?.linkedinURL,
    !(
      scanResult?.readableScore?.counts?.word_count > 1200 ||
      scanResult?.readableScore?.counts?.word_count < 800
    ),
  ].every((element) => element);

  return (
    <div>
      <VideoModel
        open={modelOpen}
        setOpen={setModelOpen}
        video={VideoLinks?.CustomizeResume}
      />
      <div
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '15px',
            flexDirection: width < 500 ? 'column' : 'row',
            alignItems: 'center',
          }}
        >
          <InfoIcon
            onClick={() => setModelOpen(true)}
            text={'How To'}
            icon={'Play'}
            height={18}
            width="70px"
            marginTop={7}
            marginRight={5}
          />
          <div style={styles.resumeTitle(width)}>
            Customize to Job Description
          </div>
        </div>
      </div>
      <Row>
        <Col lg="4" md="4" style={{ padding: '0 0 10px 10px' }}>
          <TopProgress showJustProgress isModal />
        </Col>
        <Col
          lg="8"
          md="8"
          style={{
            padding: 0,
            paddingBottom: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              // flexDirection: "row",
              flexWrap: width < 1050 ? 'wrap' : 'nowrap',
              alignItems: 'flex-start',
              gap: '10px',
              justifyContent: width < 500 ? 'center' : 'flex-end',
              marginLeft: '5px',
            }}
          >
            <span
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                padding: '2px 5px',
                borderRadius: '5px',
                border: '1px solid gray',
                backgroundColor: '#ffffff',
                minWidth: '260px',
                // marginTop: '7px',
              }}
            >
              {/* In the editor below add missing skills or keywords in the right
              context in the work experience section */}
              Using the editor below, add missing keywords in the appropriate
              context to the "Experience" section.
            </span>
            <InfoIcon
              onClick={() => this.setState({ modelOpen: true })}
              text={'PRO TIP'}
              icon={'Bulb'}
              height={20}
              width="80px"
              marginTop={0}
            />
            <span
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                padding: '2px 5px',
                borderRadius: '5px',
                border: '1px solid gray',
                backgroundColor: '#ffffff',
                minWidth: '260px',
              }}
            >
              Update missing items, format resume & go to NEXT step
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          lg="4"
          md="4"
          style={{ padding: width < 500 ? 0 : '0px 0px 10px 10px' }}
        >
          {totalSkills > 0 && (
            <HardSkills
              showInfoCard={true}
              showOnlyMissing={true}
              totalScore={scanResult?.total_score || {}}
              description={{
                fontFamily:
                  resume?.other_info?.heading_font_family ||
                  scanResult?.fontFamily,
                resume: scanResult?.resumeText,
                job: scanResult?.jobDescription,
                is_parsed: is_parsed,
              }}
              ats={scanResult?.ats}
              resumeId={resume?.oid || resume?.resume_id}
              resume={resume}
              skills={scanResult?.skillsData}
              company={scanResult?.company}
              jobTitle={scanResult?.jobTitle}
              oid={scanResult?.oid}
              fromState={'yes'}
              fineTuneSkills={props.fineTuneSkills || []}
            />
          )}

          <ATSfindings
            showOnlyMissing
            company={scanResult?.company}
            jobTitle={scanResult?.jobTitle}
            resumeId={resume?.oid || resume?.resume_id}
            totalScore={scanResult?.total_score || {}}
            is_parsed={is_parsed}
            resume={scanResult?.resumeText}
            job={scanResult?.jobDescription}
            skills={scanResult?.skillsData}
            title={scanResult?.jobTitle}
            ats={scanResult?.ats}
            other_info={resume?.other_info}
            data={resume?.data}
            oid={scanResult?.oid}
            fromState={'yes'}
          />
          <JobRequirementsMatch
            showOnlyMissing
            company={scanResult?.company}
            scores={scanResult?.readableScore || {}}
            jobTitle={scanResult?.jobTitle}
            resumeId={resume?.oid || resume?.resume_id}
            totalScore={scanResult?.total_score || {}}
            is_parsed={is_parsed}
            resume={scanResult?.resumeText}
            job={scanResult?.jobDescription}
            skills={scanResult?.skillsData}
            title={scanResult?.jobTitle}
            recruiterFindings={scanResult?.recruiterFindings}
            ats={scanResult?.ats}
            data={resume?.data}
            oid={scanResult?.oid}
            fromState={'yes'}
          />
          <WordsToAvoid
            showOnlyMissing
            company={scanResult?.company}
            scores={scanResult?.readableScore || {}}
            jobTitle={scanResult?.jobTitle}
            resumeId={resume?.oid || resume?.resume_id}
            totalScore={scanResult?.total_score || {}}
            is_parsed={is_parsed}
            resume={scanResult?.resumeText}
            job={scanResult?.jobDescription}
            skills={scanResult?.skillsData}
            title={scanResult?.jobTitle}
            recruiterFindings={scanResult?.recruiterFindings}
            ats={scanResult?.ats}
            data={resume?.data}
            oid={scanResult?.oid}
            fromState={'yes'}
          />
          {allTrue ? (
            <div />
          ) : (
            <ResumeResult
              showOnlyMissing
              scores={scanResult?.readableScore || {}}
              email={scanResult?.ats?.email || ''}
              is_parsed={is_parsed}
              phone={scanResult?.ats?.mobile_number || ''}
              company={scanResult?.company}
              jobTitle={scanResult?.jobTitle}
              resumeId={resume?.oid || resume?.resume_id}
              other_info={resume?.other_info}
              totalScore={scanResult?.total_score || {}}
              resume={scanResult?.resumeText}
              job={scanResult?.jobDescription}
              skills={scanResult?.skillsData}
              title={scanResult?.jobTitle}
              recruiterFindings={scanResult?.recruiterFindings}
              ats={scanResult?.ats}
              data={resume?.data}
              oid={scanResult?.oid}
              fromState={'yes'}
              dateError={dateError}
              isMeaurable={isMeaurable}
            />
          )}
        </Col>
        {/* <Col lg="8" md="8" style={{ paddingLeft: "20px" }}> */}
        <Col style={{ ...(width < 500 && { padding: 0 }) }} lg="8" md="8">
          <Editor
            description={{
              resume: scanResult?.resumeText,
              job: scanResult?.jobDescription,
              is_parsed: is_parsed,
            }}
            oid={scanResult?.oid}
            resume={resume}
          />
          {/* <TemplateSelector /> */}
        </Col>
      </Row>
    </div>
  );
}

export default UpdateEditEditor;
