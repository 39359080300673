import { sampleData, softSkills } from '../constants/sampleData';

export const skillMatcher = (
  resume_skills = sampleData.resume.data.skills.items,
  job_skills = sampleData.job_parsed.skills,
  soft_skills = softSkills,
  skillsData = sampleData.response.skillsData,
  resume_additional_skills = sampleData.resume.data.additional_skills.items,
  job_parsed_softSkills = []
) => {
  job_parsed_softSkills = job_parsed_softSkills?.map((skill) => skill.toLowerCase())

  const resumeSkills = resume_skills.reduce(
    //eslint-disable-next-line
    (a, b) => ((a[b.toLowerCase()] = {}), a),
    {},
  );

  // Resume Additional Skills
  let resumeAdditionalSkills = {};
  try {
    resume_additional_skills.forEach((a) => {
      if (a.name) resumeAdditionalSkills[a.name.toLowerCase()] = {};
    });
  } catch (error) {
  }

  const jobSkills = job_skills.reduce(
    //eslint-disable-next-line
    (a, b) => ((a[b.toLowerCase()] = {}), a),
    {},
  );

  const jobSkillsSoft = job_parsed_softSkills.reduce(
    //eslint-disable-next-line
    (a, b) => ((a[b.toLowerCase()] = {}), a),
    {},
  );

  let skill;
  let final_skills = {
    hard_skills: {},
    soft_skills: {},
  };

  soft_skills.forEach((skill) => {

    final_skills['soft_skills'][skill] = {
      exists: false,
      missing: 'yes',
    };
  });
  job_parsed_softSkills.forEach((skill) => {

    final_skills['soft_skills'][skill] = {
      exists: false,
      missing: 'yes',
    };
  });
  // job_skills.forEach((skill) => {
  //   //temporary solution
  //   final_skills['hard_skills'][skill] = {
  //     exists: false,
  //   };
  // });

  for (skill in jobSkills) {
    if (resumeSkills[skill]) {
      final_skills['hard_skills'][skill] = {
        exists: true,
        missing: false,
      };
    } else {
      final_skills['hard_skills'][skill] = {
        exists: false,
        missing: 'yes',
      };
    }
  }

  for (skill in jobSkillsSoft) {

    if (resumeSkills[skill]) {
      final_skills['soft_skills'][skill] = {
        exists: true,
        missing: false,
      };
    } else {
      final_skills['soft_skills'][skill] = {
        exists: false,
        missing: 'yes',
      };
    }
  }

  (Object.keys(skillsData['hard_skills'] || {}) || []).forEach((skill) => {
    final_skills['hard_skills'][skill] = skillsData['hard_skills'][skill];
  });

  (Object.keys(skillsData['soft_skills'] || {}) || []).forEach((skill) => {
    final_skills['soft_skills'][skill] = skillsData['soft_skills'][skill];
  });

  return final_skills;
};
