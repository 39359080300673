import React, { forwardRef } from 'react';
import { Icon } from '@material-ui/core';
import { colors } from './colors';
import proTip from '../assets/Pro-Tip.svg';
import { useWindowWidth } from '@react-hook/window-size';

const styles = {
  parent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    height: '16px',
  },
  heading: (width, text) => {
    return {
      fontSize:
        text === 'RECRUITER FOCUS' ? (width < 500 ? '7px' : '8px') : '8px',
      fontWeight: 'bolder',
      padding:
        text === 'RECRUITER FOCUS'
          ? width < 500
            ? '0px 4px 0px 2px'
            : '0px 8px'
          : '0px 8px',
      whiteSpace: 'nowrap',
    };
  },
  iconStyle: {
    color: colors.white,
    height: '100%',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: (width, text) => {
    return {
      width: text === 'RECRUITER FOCUS' ? (width < 500 ? 14 : 18) : '18px',
      height: text === 'RECRUITER FOCUS' ? (width < 500 ? 14 : 18) : '18px',
      padding: text === 'RECRUITER FOCUS' ? (width < 500 ? 2 : 4) : 4,
      objectFit: 'contain',
      objectPosition: 'center',
      imageRendering: ' -webkit-optimize-contrast',
    };
  },
};

const RightIconButton = forwardRef((props, ref) => {
  const {
    color = colors.orange,
    text = 'How to',
    iconName = 'play_arrow',
    imgsrc = '',
    fontColor = colors.fontGray,
  } = props;
  const width = useWindowWidth();
  if (text === 'PRO TIP') {
    return (
      <div>
        <img
          src={proTip}
          style={{
            width: '75px',
            height: '44px',
            objectFit: 'contain',
            // marginTop: '-10px',
            // imageRendering: ' -webkit-optimize-contrast',
          }}
        />
      </div>
    );
  } else {
    return (
      <div {...props} ref={ref} style={props.style}>
        <div
          style={{
            border: `2px solid ${color}`,
            borderRadius: '8px',
            cursor: text === 'RECRUITER FOCUS' ? 'default' : 'pointer',
            backgroundColor: 'white',
          }}
        >
          <div
            onClick={props.onClick}
            style={{
              width:
                text === 'RECRUITER FOCUS' ? (width < 500 ? 90 : 115) : '65px',
              ...styles.parent,
            }}
          >
            <span style={{ ...styles.heading(width, text), color: fontColor }}>
              {text}
            </span>
            <span style={{ ...styles.iconStyle, backgroundColor: color }}>
              {imgsrc ? (
                <img
                  style={styles.imageStyle(width, text)}
                  src={imgsrc}
                  alt={imgsrc}
                />
              ) : (
                <Icon
                  style={{
                    fontSize: '16px',
                  }}
                >
                  {iconName}
                </Icon>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
});
RightIconButton.displayName = 'RightIconButton';
export default RightIconButton;
