import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainColor: {
    backgroundColor: '#f0f2f7',
    width: '100vw',
    height: '100vh',
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center',
  },
  iconQuestion: {
    fontSize: 18,
  },
  logo: {
    fontSize: '24px',
    color: 'rgb(82, 83, 88)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '30px',
    fontWeight: 500,
  },
  matchScore: {
    fontWeight: 900,
    fontFamily: "'Nunito',sans-serif",
  },
  downloadContainer: {
    padding: '30px',
    backgroundColor: 'white',
    width: '32.5vw',
    height: 'fit-content',
    borderRadius: '7px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    '@media (min-width: 992px) and (max-width: 1100px)': {
      width: '60vw',
    },
    '@media (min-width: 762px) and (max-width: 992px)': {
      width: '60vw',
    },
    '@media (min-width: 520px) and (max-width: 761px)': {
      width: '60vw',
    },
    '@media (min-width: 320px) and (max-width: 519px)': {
      width: '100vw',
    },
  },
  reportContainer: {
    padding: '30px',
    backgroundColor: 'white',
    minWidth: '40vw',
    height: 'fit-content',
    borderRadius: '7px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  imageLogo: {
    padding: '0px 0px 60px 0px',
  },
  resumeText: {
    fontSize: '14px',
    color: '#1a1a1a80',
    fontWeight: 600,
  },
  score: {
    fontSize: '36.5px',
    fontWeight: 600,
  },
  buttonDocx: {
    backgroundColor: '#0074d4',
    borderRadius: '5px',
    fontSize: '15px',
    fontWeight: 600,
    padding: '6px 30px 6px 30px',
    fontFamily: "'Nunito', sans-serif",
    color: 'white',
    width: '100%',
    cursor: 'pointer',
  },

  user: {
    fontSize: '15px',
    fontFamily: "'Nunito', sans-serif",
    display: 'flex',
    color: 'gray',
  },
  details: {
    fontSize: '15px',
    fontFamily: "'Nunito', sans-serif",
    display: 'flex',
    color: 'black',
    justifyContent: 'flex-start',
  },
  buttonpdf: {
    border: '1px solid #0074d4',
    backgroundColor: 'white',
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: '5px',
    padding: '6px 30px 6px 30px',
    fontFamily: "'Nunito', sans-serif",
    color: '#0074d4',
    width: '100%',
    cursor: 'pointer',
  },
  downloadIcon: {
    fontSize: '80px',
  },
  emptyMiddle: {
    border: 'none',
    width: 10,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '10px',
  },
  td: {
    padding: '5px 2px',
  },
}));
