import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import JobView from './JobView';
import CompanyView from './CompanyView';
import styles from './infoModalStyle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Spinner } from 'reactstrap';
import { colors } from '../colors';
import { useWindowWidth } from '@react-hook/window-size';
export default function InfoModal(props) {
  const {
    isOpen,
    onCloseModal,
    infoKey,
    companyInfo,
    loader,
    jobTitle,
    onetData,
  } = props;
  const windowWidth = useWindowWidth();
  return (
    <Dialog
      open={isOpen}
      keepMounted
      onClose={() => onCloseModal('')}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div
        style={{
          ...styles.container,
          ...(infoKey === 'job' &&
            !loader && {
              ...{ width: 600 },
              ...(windowWidth < 700 && { width: 550 }),
              ...(windowWidth < 600 && { width: 450 }),
              ...(windowWidth < 500 && { width: 'auto' }),
            }),
        }}
      >
        {loader ? (
          <Spinner />
        ) : (
          <>
            <div
              id="alert-dialog-slide-title"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <h5
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: windowWidth < 500 ? 18 : 23,
                  fontFamily: 'Nunito',
                }}
              >
                {infoKey === 'job'
                  ? 'Job Info'
                  : infoKey === 'company'
                  ? 'Company Info'
                  : ''}
              </h5>
              <CancelIcon
                style={{
                  color: colors.red,
                  cursor: 'pointer',
                  fontSize: windowWidth < 500 ? 20 : 24,
                }}
                onClick={() => onCloseModal('')}
              />
            </div>
            <DialogContent
              style={{
                ...(windowWidth < 500 && {
                  padding: 0,
                  fontSize: 13,
                }),
              }}
            >
              {infoKey === 'job' ? (
                <JobView jobTitle={jobTitle} onetData={onetData} />
              ) : infoKey === 'company' ? (
                <CompanyView companyInfo={companyInfo} loader={loader} />
              ) : null}
            </DialogContent>
          </>
        )}
      </div>
    </Dialog>
  );
}
