export const styles = {
  flex: {
    display: "flex",
  },
  justifyContentEnd: {
    justifyContent: "flex-end",
  },
  width_100_percent: {
    width: "100%",
  },
  width_80_percent: {
    width: "80%",
  },
  fontWeight_600: {
    fontWeight: 600,
  },
  fontWeight_bold: {
    fontWeight: "bold",
  },
  fontSize_85: {
    fontSize: "85%",
  },
  bb_ligthGrey: {
    border: "0.5px dashed lightgray",
  },
  mt_3: {
    marginTop: 3,
  },
  mt_15: {
    marginTop: 15,
  },
  mt_30: { marginTop: 30 },
  mt_20: {
    marginTop: 20,
  },
  mb_15: {
    marginBottom: 15,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_90: {
    marginBottom: 90,
  },
  pt_3: {
    paddingTop: 1,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  zIndex: {
    zIndex: 1,
  },
  colorBlue: {
    color: "white",
    backgroundColor: '#4267b2',
    fontWeight: 'bold'
  },
  height_120: {
    height: 120,
  },
  span: {
    display: "inline-block",
    marginLeft: 10,
  },
  mainSection: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
