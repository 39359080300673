import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import toastr from 'toastr';
import { styles } from './style';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '../../commonComponents/colors';
import { Slide } from '@material-ui/core';
import clip from '../../assets/clipboard.png';
import { scanHistory } from '../../commonRedux/scan/action';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import baseUrl from '../../config/endpoint';
import axios from 'axios';
import CancelIcon from '@material-ui/icons/Cancel';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [noteText, setNoteText] = React.useState('');
  const [notesList, setNotesList] = React.useState([]);
  const [updateNoteData, setUpdateNoteData] = React.useState(null);
  const [deleteModal, setDeleteModal] = React.useState({
    modal: false,
    oid: '',
  });

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    getNotes();
    setOpen(true);
    if (props.handleClose) {
      props.handleClose();
    }
  };
  const getNotes = async () => {
    try {
      const response = await axios.post(`${baseUrl}/get-notes`, {
        scanId: props.oid || '',
      });

      setNotesList(
        response?.data?.resumeNotes?.length
          ? response?.data?.resumeNotes?.sort(
              (a, b) => new Date(b?.note?.date) - new Date(a?.note?.date),
            )
          : [],
      );
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const addNotes = async () => {
    try {
      if (!noteText?.trim()) {
        return toastr.error('Please add some note!');
      }
      let duplicateNotes = null;
      if (notesList.length > 0) {
        // check if note is already exist in note list
        duplicateNotes = notesList.find(
          (item) => item?.note?.note === noteText,
        );
      }
      if (duplicateNotes) {
        toastr.error('Note Already exists!');
        setNoteText('');
      } else {
        const newData = {
          note: noteText,
          date: moment().format(),
        };
        const response = await axios.post(`${baseUrl}/add-note`, {
          scanId: props.oid || '',
          notes: newData,
        });

        const responseObj = response?.data;

        if (responseObj?.status === 200) {
          setNotesList([
            {
              note: newData,
              noteId: responseObj?.noteId,
              scanId: props.oid,
            },
            ...notesList,
          ]);
          setNoteText('');
        }
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const deleteNote = async (deleteNoteId) => {
    try {
      const response = await axios.post(`${baseUrl}/delete-note`, {
        noteId: deleteNoteId,
      });

      const responseObj = response?.data;

      if (responseObj?.status === 200) {
        const newNotesList = notesList?.filter(
          (item) => item?.noteId !== deleteNoteId,
        );

        setNotesList(newNotesList);
        deleteModalClose();
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const updateNote = async () => {
    try {
      if (!noteText?.trim()) {
        return toastr.error('Please add some note!');
      }
      const newUpdateData = {
        note: noteText,
        date: moment().format(),
      };
      const response = await axios.post(`${baseUrl}/update-notes`, {
        noteId: updateNoteData?.noteId,
        note: newUpdateData,
      });

      const responseObj = response?.data;

      if (responseObj?.status === 200) {
        const newNotesList = notesList?.map((item) => {
          if (item?.noteId === updateNoteData?.noteId) {
            return {
              ...item,
              note: newUpdateData,
            };
          }
          return item;
        });

        setNotesList(newNotesList);
        setUpdateNoteData(null);
        setNoteText('');
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    if (props.oid) {
      getNotes();
    }
  }, [props.oid]);

  const handleClose = () => {
    setOpen(false);
    setLoader(false);
    dispatch(scanHistory());
  };

  const ctaUpdateNote = ({ noteId: updateNoteId, note: updateNoteObj }) => {
    setUpdateNoteData({
      noteId: updateNoteId,
      note: updateNoteObj,
    });
    setNoteText(updateNoteObj?.note);
  };

  const deleteModalOpen = (oid) => {
    setDeleteModal({
      ...deleteModal,
      modal: true,
      oid: oid,
    });
  };
  const deleteModalClose = () => {
    setDeleteModal({
      modal: false,
      oid: '',
    });
  };

  return (
    <>
      <div style={styles.notesModel}>
        {props.clip === 'clip' ? (
          <img
            src={clip}
            height={props.size?.height || 30}
            width={props.size?.width || 30}
            style={{
              marginLeft: 10,
              cursor: 'pointer',
              marginTop: props.size?.height === 40 ? 15 : 0,
            }}
            onClick={handleClickOpen}
          />
        ) : (
          <div style={{ width: '100%' }} onClick={handleClickOpen}>
            Add Notes
          </div>
        )}

        <Dialog
          fullWidth
          maxWidth={'sm'}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div
            id="alert-dialog-slide-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '10px 20px',
            }}
          >
            <h5
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontFamily: 'Nunito',
              }}
              className="f-s-23"
            >
              Notes
            </h5>
            <CancelIcon
              style={{
                color: colors.red,
                cursor: 'pointer',
                fontSize: 24,
              }}
              onClick={handleClose}
            />
          </div>
          <DialogContent>
            <div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: 10,
                }}
              >
                <input
                  value={noteText}
                  onChange={(e) => setNoteText(e.target.value)}
                  style={{
                    width: '100%',
                    height: 40,
                    border: '1px solid #ccc',
                    borderRadius: 5,
                    padding: '0px 10px',
                  }}
                />
                <Button
                  disabled={loader}
                  variant="contained"
                  onClick={updateNoteData?.noteId ? updateNote : addNotes}
                  color="primary"
                  style={{
                    width: '100%',
                    height: 40,
                    backgroundColor: colors.blue,
                  }}
                >
                  {updateNoteData?.noteId ? 'Update Note' : 'Add Note'}
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  marginTop: 10,
                }}
              ></div>
            </div>

            {notesList?.length ? (
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <ul
                  style={{
                    listStyleType: 'none',
                    paddingLeft: 0,
                  }}
                >
                  {notesList?.length &&
                    notesList?.map(({ note, noteId }, index) => {
                      return (
                        <li
                          key={index + note?.note}
                          style={{
                            marginBottom: 10,
                            padding: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #ccc',
                            borderRadius: 5,
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: colors.fontBlack,
                              marginBottom: 5,
                              overflowWrap: 'break-word',
                            }}
                          >
                            {note?.note}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: 12,
                              color: colors.fontGray,
                            }}
                          >
                            <div
                              style={{
                                fontWeight: 600,
                                fontSize: 12,
                                color: colors.grey,
                                flex: 1,
                              }}
                            >
                              {moment(note?.date).format('dddd, DD MMM YYYY') +
                                ' at ' +
                                moment(note?.date).format('h:mm a')}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: 10,
                                gap: 10,
                              }}
                            >
                              <div>
                                <EditIcon
                                  onClick={() =>
                                    ctaUpdateNote({
                                      noteId,
                                      note,
                                    })
                                  }
                                  style={{
                                    color: colors.green,
                                    fontSize: 16,
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                              <div>
                                <DeleteIcon
                                  onClick={() => deleteModalOpen(noteId)}
                                  style={{
                                    color: colors.red,
                                    fontSize: 16,
                                    cursor: 'pointer',
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            ) : null}
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={deleteModal?.modal}
        TransitionComponent={Transition}
        keepMounted
        onClose={deleteModalClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '5px',
            paddingTop: '5px',
          }}
        >
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={deleteModalClose}
          />
        </div>
        <DialogContent>
          Are you sure you want to delete this note?
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: colors.red, color: 'white' }}
            variant="contained"
            onClick={() => deleteNote(deleteModal.oid)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default FormDialog;
