import React, { useState, memo, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import './style.css';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import '../save&rescan-modal/style.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';

let convertedNotification = '';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const CustomEditor = memo(({ updateResumeTextFun, defaultText = '' }) => {
  const [content, setContent] = useState(true);
  const [isContentChanged, setContentChanged] = useState(1);
  useEffect(() => {
    if (defaultText) {
      convertedNotification = defaultText;
    }
    setTimeout(() => {
      setContent(false);
    }, 1000);
    return function remove() {
      convertedNotification = '';
    };
  }, []);

  useEffect(() => {
    if (defaultText) {
      convertedNotification = defaultText;
      setContentChanged(isContentChanged + 1);
    }
  }, [defaultText]);

  const onChange = (newContent) => {
    convertedNotification = newContent;
    updateResumeTextFun(newContent);
  };
  const configFroalaEditor = {
    heightMax: '1122.519685px',
    toolbarSticky: false,
    fontSizeSelection: true,
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'insertLink',
          'fontFamily',
          'formatUL',
          'align',
          'outdent',
          'indent',
          'fontSize',
        ],
        buttonsVisible: 10,
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'Update & Rescan', 'Save'],
        align: 'right',
        buttonsVisible: 6,
      },
    },
    charCounterCount: false,
    attribution: false,
    htmlUntouched: true,
    key: 'CTD5xB1C2B2C1A7B5wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4A3C6E2F2F4G1C1==',
  };
  if (content) {
    return (
      <center>
        <div style={{ marginTop: '20%' }}>
          <BeatLoader
            className="loader"
            css={override}
            sizeUnit={'px'}
            size={10}
            color={'#D5641C'}
            loading={true}
          />
        </div>
      </center>
    );
  }

  return (
    <center>
      <div>
        <FroalaEditorComponent
          config={configFroalaEditor}
          model={convertedNotification || defaultText}
          onModelChange={onChange}
        />
      </div>
    </center>
  );
});

const ModalRescan = (props) => {
  const [desc, setDesc] = React.useState('');
  React.useEffect(() => {
    if (props.description) {
      let resumeDiscription = props.description.replace(/<img[^>]*>/g, '');
      setDesc(resumeDiscription);
    }
  }, [props.description]);

  const updateResumeTextFun = () => {
    props.onSubmitFun(convertedNotification);
  };
  return (
    <div className="rescan-main-container">
      <Row>
        <Col lg="12" style={{ padding: 0, position: 'relative' }}>
          <CustomEditor
            updateResumeTextFun={updateResumeTextFun}
            defaultText={desc}
          />
          {props.loader ? (
            <div className="editor-saving-mode">
              <h3 className="editor-saving-mode-text">Loading...</h3>
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default ModalRescan;
