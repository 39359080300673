import React from "react";
import { useDispatch } from "react-redux";

export default function Finalize() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: "GET_RESUME_BUILDER_CHOICE",
      payload: "editor",
    });
  }, []);
  return <div></div>;
}