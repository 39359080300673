import React, { useEffect } from 'react';
import { Text } from '../../component';
import { Grid } from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
//eslint-disable-next-line
import { useStyles } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { PatternFormat } from 'react-number-format';
import { colors } from '../../../../commonComponents/colors';

const PatternInput = ({ value, handleChange, tabIndex }) => {
  const [focus, setFocus] = React.useState(false);
  const [phone, setPhone] = React.useState(value);

  const toggleFocus = () => {
    setFocus(!focus);
  };

  return (
    <PatternFormat
      type="tel"
      tabIndex={tabIndex}
      style={{
        border: '1px solid',
        borderColor: focus ? colors.blue : colors.grey,
        backgroundColor: focus ? '#D5D5D5' : colors.white,
        width: '100%',
        fontSize: '14px',
        height: 42,
        paddingLeft: 10,
        boxSizing: 'border-box',
        borderRadius: 4,
      }}
      format="+1 (###) ###-####"
      value={phone}
      placeholder="Phone"
      mask="_"
      onValueChange={(value) => setPhone(value.formattedValue)}
      onFocus={toggleFocus}
      onBlur={() => {
        toggleFocus();
        handleChange(phone);
      }}
    />
  );
};

export default function Profile() {
  let { resumeData } = useSelector((state) => state.editorReducer);
  // let { selectedTitle } = useSelector((state) => state.fileScanReducer);
  const dispatch = useDispatch();
  const [places, setPlaces] = React.useState('');
  const [focus, setFocus] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [multiLocations, setMultiLocations] = React.useState([]);
  const classes = useStyles();

  const toggleFocus = () => {
    setFocus(!focus);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: 13,
      minHeight: '30px',
      // height: '30px',
      border: state.isFocused ? '0.5px solid #4267b3' : '0.5px solid #ccc',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '0.5px solid #ccc',
        boxShadow: 'none',
      },
      '&:onFocus': {
        border: '0.5px solid #4267b3',
        boxShadow: 'none',
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '43px',
      padding: '0 6px',
    }),
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled ? 'red' : blue,
        color: '#000',
        fontSize: 15,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
  };

  const handleChange = (address) => {
    setPlaces(address);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        if (multiLocations?.length) {
          setMultiLocations(() => [...multiLocations]);
        }
        setMultiLocations([results[0].address_components[0].short_name]);
      })

      .catch((error) => console.error('error', error));
  };

  useEffect(() => {
    setMultiLocations({
      label: resumeData?.profile?.address?.city,
      value: resumeData?.profile?.address?.city,
    });
  }, [resumeData?.profile?.address?.city]);

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Profile
          </label>
        </div>
      </Grid>

      <Row style={{ margin: 0, padding: 0 }}>
        <Col style={{ margin: 0, padding: 5 }} md={12} xl={6}>
          <div>
            <label className={classes.label}>First Name</label>
            <Text
              tabIndex={1}
              value={resumeData?.profile?.firstName}
              placeholder="First Name"
              customclass={classes.input}
              path={'profile.firstName'}
            />
          </div>
          <div>
            <label className={classes.label}>Email Address</label>
            <Text
              value={resumeData?.profile?.email}
              placeholder="Email Address"
              tabIndex={3}
              customclass={classes.input}
              path={'profile.email'}
            />
          </div>
          <div>
            <label className={classes.label}>City</label>
            {/* <Text
              value={resumeData?.profile?.address?.city}
              placeholder="City"
              tabIndex={5}
              customclass={classes.input}
              path={'profile.address.city'}
            /> */}
            <PlacesAutocomplete
              searchOptions={{
                types: ['(cities)'],
                // componentRestrictions: { country: 'us' },
              }}
              value={places}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, loading }) => {
                return (
                  <div style={{ paddingBottom: 16 }}>
                    <Select
                      // isMulti
                      isLoading={loading}
                      options={suggestions?.map((n) => ({
                        label:
                          n?.formattedSuggestion?.mainText +
                          ', ' +
                          n?.formattedSuggestion?.secondaryText,
                        value:
                          n?.formattedSuggestion?.mainText +
                          ', ' +
                          n?.formattedSuggestion?.secondaryText,
                        id: n?.placeId,
                      }))}
                      styles={customStyles}
                      // set tabIndex to 5
                      tabIndex={5}
                      // {...getInputProps({
                      //   placeholder: 'Select City ',
                      //   // className: 'location-search-input',
                      // })}
                      placeholder="Select City"
                      onChange={async (tags) => {
                        const newZip = await geocodeByPlaceId(tags.id);
                        const postalCode = newZip[0]?.address_components?.find(
                          (c) => c.types.includes('postal_code'),
                        );
                        const stateName = newZip[0]?.address_components?.find(
                          (c) =>
                            c.types.includes('administrative_area_level_1'),
                        );
                        dispatch(
                          onBlurField(
                            postalCode?.long_name,
                            'profile.address.zipcode',
                          ),
                        );
                        dispatch(
                          onBlurField(
                            stateName?.long_name,
                            'profile.address.state',
                          ),
                        );

                        dispatch(
                          onBlurField(
                            tags?.label?.split(',')[0] || '',
                            `profile.address.city`,
                          ),
                        );
                        setMultiLocations(tags);
                      }}
                      onInputChange={(text) =>
                        getInputProps().onChange({
                          target: { value: text },
                        })
                      }
                      value={multiLocations}
                    />
                  </div>
                );
              }}
            </PlacesAutocomplete>
          </div>

          <div style={{ marginTop: -4 }}>
            <label className={classes.label}>LinkedIn Profile</label>
            <Text
              value={resumeData?.profile?.linkedinURL}
              placeholder="LinkedIn Profile"
              tabIndex={8}
              customclass={classes.input}
              path={'profile.linkedinURL'}
            />
          </div>
        </Col>

        <Col style={{ margin: 0, padding: 5 }} md={12} xl={6}>
          <div>
            <label className={classes.label}>Last Name</label>
            <Text
              value={resumeData?.profile?.lastName}
              placeholder="Last Name"
              tabIndex={2}
              customclass={classes.input}
              path={'profile.lastName'}
            />
          </div>
          <div style={{ marginTop: 3 }}>
            <label className={classes.label}>Phone</label>
            <div>
              <PatternInput
                value={resumeData?.profile?.phone}
                handleChange={(val) =>
                  dispatch(onBlurField(val, 'profile.phone'))
                }
                tabIndex={4}
              />
            </div>
          </div>
          <Row style={{ marginTop: 18 }}>
            <Col item md={6} lg={6} xl={6}>
              <div>
                <label className={classes.label}>State</label>
                <Text
                  value={resumeData?.profile?.address?.state}
                  placeholder="State"
                  tabIndex={6}
                  customclass={classes.input}
                  path={'profile.address.state'}
                />
              </div>
            </Col>
            <Col item md={6} lg={6} xl={6}>
              <div>
                <label className={classes.label}>Zip Code</label>
                <Text
                  value={resumeData?.profile?.address?.zipcode}
                  placeholder="Zip Code"
                  tabIndex={7}
                  customclass={classes.input}
                  path={'profile.address.zipcode'}
                />
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col item md={12} lg={12} xl={12}>
              <div>
                <label className={classes.label}>Job Title</label>
                <SolrAutoSuggestion
                  onBlur={() =>
                    dispatch(onBlurField(selectedTitle, 'profile.jobTitle'))
                  }
                />
              </div>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </>
  );
}
