import React from "react";
import { styles } from "./style";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

const PayNowSuccess = ({ isEmployee }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 10,
          paddingBottom: "20px",
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            marginTop: "30px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <CheckCircleRoundedIcon style={styles.successBtn} />
          </center>
        </div>
        <h3 style={{ textAlign: "center" }}> THANK YOU!</h3>
        <p
          style={{
            width: "80%",
            textAlign: "center",
            position: "relative",
            margin: "auto",
            paddingBottom: "20px",
            fontSize: "16px",
          }}
        >
          {isEmployee
            ? "An email with subscription details has been sent to your separated employee(s) directly."
            : "An email with subscription details has been sent to your email. Please check your email."}
        </p>
        <center></center>
      </div>
    </>
  );
};
export default PayNowSuccess;
