export const styles = {
  flex: {
    display: "flex",
  },
  justifyContentEnd: {
    justifyContent: "flex-end",
  },
  width_100_percent: {
    width: "100%",
  },
  width_80_percent: {
    width: "80%",
  },
  fontWeight_600: {
    fontWeight: 600,
  },
  fontWeight_bold: {
    fontWeight: "bold",
  },
  fontSize_85: {
    fontSize: "85%",
  },
  fontSize1_1_rem: {
    fontSize: "1.1rem",
  },
  mt_5: {
    marginTop: 5,
  },
  mt_30: { marginTop: 30 },
  mt_20: {
    marginTop: 20,
  },
  mb_5: {
    marginBottom: 5,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_90: {
    marginBottom: 90,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  jobDescView: {
    color: "#4267B2",
    cursor: "pointer",
  },
  jobActions: {
    cursor: "pointer",
    fontSize: "1.1rem",
    marginLeft: 7,
    marginRight: 6,
  },
  fileCopyIcon: {
    cursor: "pointer",
    fontSize: "1.1rem",
    marginLeft: 7,
    marginRight: 6,
  },
  url: {
    color: "#4267B2",
    textDecoration: "underline",
    cursor: "pointer",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  icon: {
    fontSize: "1.1rem",
    marginLeft: 5,
    marginRight: 5,
  },
  scrollBar: {
    width: "100%",
    height: "460px",
    padding: "10px",
    border: "1px solid #A9A9A9",
    fontSize: 13,
    color: "black",
  },
  h3: {
    color: "#145388",
    fontSize: "1.2rem",
  },
  row: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  textArea: {
    width: "100%",
    height: "460px",
    padding: "10px",
    resize: "none",
    overflow: "auto",
    border: "1px solid #A9A9A9",
    fontSize: 13,
    color: "black",
  },
  getAppIcon: {
    fontSize: "1.1rem",
  },
};
