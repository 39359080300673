import React from 'react';
import { Text, Dnd } from '../../component';
import { Grid } from '@material-ui/core';
//eslint-disable-next-line
import { useStyles } from '../style';
import { useDispatch } from 'react-redux';

import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import InputText from '../../component/inputText';
import RichEditor from '../helper/RichEditor';
import Accordian from '../helper/Accordian';
import moment from 'moment';
import { colors } from '../../../../commonComponents/colors';

export default function Trainings(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'training.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };
  let newData = {
    Description: '',
    EndDate: {
      Year: '',
    },
    Entity: '',
    Qualifications: {
      Qualification: [],
    },
    TrainingName: '',
    Type: '',
  };
  const _addNewItem = () => {
    dispatch(addNewObj(newData, path, true));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;
  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Trainings
          </label>
        </div>
      </Grid>
      <Dnd
        data={data}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => {
          return (
            <Accordian
              defaultExpanded={false}
              summary={
                <>
                  <label
                    className={
                      item?.TrainingName || item?.institution
                        ? classes.summaryLabel
                        : classes.summaryLabelGrey
                    }
                  >
                    {item.TrainingName
                      ? item.TrainingName
                      : 'Add Training Details'}{' '}
                    {'\n '}
                  </label>
                  <br />
                  <label className={classes.summarySubLabel}>
                    {item?.StartDate?.YearMonth &&
                      item?.StartDate?.YearMonth + ' - '}{' '}
                    {item?.EndDate?.YearMonth}
                  </label>
                </>
              }
            >
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  {/* <div>
                  <label className={classes.label}>Training Type</label>
                  <Text
                    value={item['Type']}
                    placeholder="Training Type"
                    customclass={classes.input}
                    path={`${path}.${index}.Type`}
                  />
                </div> */}
                  {/* <div>
                  <label className={classes.label}>Entity</label>
                  <Text
                    value={item['Entity']}
                    placeholder="Entity"
                    customclass={classes.input}
                    path={`${path}.${index}.Entity`}
                  />
                </div> */}
                  <div>
                    <label className={classes.label}>Training Name</label>
                    <Text
                      value={item['TrainingName']}
                      placeholder="Training Name"
                      customclass={classes.input}
                      path={`${path}.${index}.TrainingName`}
                    />
                  </div>
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                      <div>
                        <label className={classes.label}>Start Date</label>
                        <InputText
                          type="month"
                          value={
                            (item['StartDate']
                              ? item['StartDate'].YearMonth
                              : '') ?? ''
                          }
                          placeholder="MM / YYYY"
                          customclass={classes.input}
                          path={`${path}.${index}.StartDate.YearMonth`}
                        />
                      </div>
                    </Grid>
                    <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                      <div>
                        <label className={classes.label}>End Date</label>
                        <InputText
                          type="month"
                          value={
                            (item['EndDate']
                              ? item['EndDate'].YearMonth
                              : '') ?? ''
                          }
                          placeholder="MM / YYYY"
                          customclass={classes.input}
                          path={`${path}.${index}.EndDate.YearMonth`}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div>
                <label className={classes.label}>Description</label>
                <RichEditor
                  value={item.Description}
                  placeHolder="Description"
                  customclass={`${classes.input} ${classes.summaryInput}`}
                  path={`${path}.${index}.Description`}
                  onBlurField={onBlurField}
                />
              </div>
              {data.length > 1 && (
                <hr
                  style={{ borderTop: 'dashed 1px #9E9E9E', marginTop: 20 }}
                />
              )}
            </Accordian>
          );
        }}
      />
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more training
      </div>
    </>
  );
}
