import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSuggestionAgainstSelectedTitle } from '../commonRedux/scan/action';
import SearchIcon from '@material-ui/icons/Search';
import getJobTitleSuggestions from '../utils/getJobTitleSuggestions';

const SolrAutoSuggestion = (props) => {
  const {
    height,
    borderRadius,
    onChangeTitle,
    onBlur,
    place,
    value,
    titleType,
  } = props;
  const dispatch = useDispatch();
  const { jobTitle } = useSelector((store) => store?.fileScanReducer);
  const [suggestionList, setSuggestionList] = useState([]);
  const [addBorder, setAddBorder] = React.useState(false);

  const onSelectHandler = (title) => {
    if (title) {
      if (onChangeTitle) {
        onChangeTitle(title);
      }
      dispatch(getSuggestionAgainstSelectedTitle(title, titleType));
      setSuggestionList([]);
      if (onBlur) {
        onBlur(title);
      }
    }
  };

  const toggleBorder = () => {
    setAddBorder(!addBorder);
    if (onBlur) {
      onBlur();
    }
  };

  const handleAutocomplete = async (e) => {
    if (onChangeTitle) {
      const title = e.target.value;
      onChangeTitle(title);

      try {
        if (title === '') {
          setSuggestionList([]);
          return;
        }

        const suggestions = await getJobTitleSuggestions(title);
        setSuggestionList(suggestions);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSelectHandler(event.target.value);
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        border: '1px solid',
        borderColor: addBorder ? '#4267b2' : 'rgba(0, 0, 0, 0.23)',
        height: height ? height : 43,
        borderRadius: borderRadius ? borderRadius : 5,
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        paddingRight: 10,
      }}
    >
      <>
        <input
          type="text"
          value={value}
          onKeyDown={handleKeyPress}
          placeholder="Job Title"
          onChange={handleAutocomplete}
          onFocus={toggleBorder}
          onBlur={toggleBorder}
          style={{
            borderRadius: borderRadius ? borderRadius : 5,
            width: '100%',
            height: '100%',
            color: '#000000',
            paddingLeft: 10,
          }}
        />
        {place !== 'optimize' && (
          <SearchIcon
            style={{ cursor: 'pointer' }}
            onClick={() => onSelectHandler(jobTitle)}
          />
        )}
      </>
      {suggestionList?.length > 0 && (
        <ul
          style={{
            backgroundColor: '#FFFFFF',
            color: '#000000',
            fontSize: '14px',
            listStyle: 'none',
            padding: '0',
            margin: '0',
            border: '1px solid #ccc',
            maxHeight: '200px',
            overflowY: 'auto',
            width: '100%',
            zIndex: '9999',
            position: 'absolute',
            top: '100%',
            left: '0',
          }}
        >
          {suggestionList?.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => onSelectHandler(item?.value)}
                style={{
                  padding: '5px 10px',
                  cursor: 'pointer',
                }}
              >
                {item?.value}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SolrAutoSuggestion;
