import { INTRO_MILXWALK_VIDEO_MODAL } from '../../militaryXWalk/redux/actions.constants';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGOUT_USER,
  CURRENT_USER,
  ERROR_OCCURED,
  CURRENT_USER_LOADING,
  SEND_EMAIL,
  SEND_EMAIL_FAIL,
  REQUEST_FOR_VERIFY,
  VERIFIED_SUCCESSFULLY,
  NOT_VERIFIED,
  VERFIED_FAILED,
  SAVE_TOKEN,
  CHANGE_SYNC,
  UPGRADE_PLAN_MODAl,
  UPDATE_HOWTOSTART,
  CONTACT_MODAL,
} from './constants';

const INIT_STATE = {
  user: {},
  loading: false,
  pVCode: '',
  eVCode: '',
  couponVerification: '',
  token: '',
  isSync: false,
  selectedTemplate: '',
  dropBoxToken: '',
  currentUserStatus: false,
  showUpgradeModal: false,
  isPremium: false,
  hasSubscription: false,
  msgSignup: false,
  couponExpiry: false,
  pastInvoices: [],
  contactSupportModal: false,
  suggestionEmails: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'CURRENT_USER_START':
      return {
        ...state,
        currentUserStatus: true,
      };
    case LOGIN_USER:
      return { ...state, loading: true };
    case REQUEST_FOR_VERIFY:
      return { ...state, couponVerification: 'requested' };
    case VERIFIED_SUCCESSFULLY:
      return {
        ...state,
        couponVerification: 'success',
        msgSignup: action.payload.msgSignup,
        couponExpiry: action.payload.couponExpiry,
      };
    case NOT_VERIFIED:
      return { ...state, couponVerification: 'notVerified' };
    case VERFIED_FAILED:
      return { ...state, couponVerification: 'verifyFailed' };

    case UPGRADE_PLAN_MODAl:
      return { ...state, showUpgradeModal: action.payload };
    case 'GET_PAST_INVOICES':
      return {
        ...state,
        pastInvoices: action.payload,
      };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case 'UPDATE_PROFILE_STATUS':
      return { ...state, user: { ...state.user, ...action.payload } };

    case CURRENT_USER:
      return { ...state, user: action.payload, currentUserStatus: false };
    case CURRENT_USER_LOADING:
      return { ...state, user: null, currentUserStatus: false };
    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false };
    case 'DOWNGRADE_USER_ACCOUNT':
      return { ...state, user: { ...state.user, ...action.payload } };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_SYNC:
      return {
        ...state,
        isSync: action.payload,
      };
    case SEND_EMAIL: {
      let payload = action.payload;

      return {
        ...state,
        eVCode: payload.eVCode,
      };
    }

    case SEND_EMAIL_FAIL: {
      let payload = action.payload;

      if (payload.type === 'email') {
        return {
          ...state,
          eVCode: 'error',
        };
      } else {
        return {
          ...state,
          pVCode: 'error',
        };
      }
    }
    case 'SAVE_DROPBOX_TOKEN':
      return {
        ...state,
        dropBoxToken: action.payload,
      };
    case 'DEFAULT_TEMPLATE':
      return {
        ...state,
        selectedTemplate: action.payload,
      };
    case LOGOUT_USER:
      return { ...state, user: null };
    case SAVE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case 'GET_SUGGESTION_EMAILS':
      return {
        ...state,
        suggestionEmails: action.payload,
      };

    case 'ADD_SUGGESTION_EMAIL':
      return {
        ...state,
        suggestionEmails: [...state.suggestionEmails, action.payload],
      };

    case 'DEL_SUGGESTION_EMAILS':
      const delMailId = action.payload;
      const updatedSuggestions = state?.suggestionEmails?.filter(
        (item) => item?.mailId !== delMailId,
      );

      return {
        ...state,
        suggestionEmails: updatedSuggestions,
      };

    case 'PUT_SUGGESTION_EMAILS':
      const { email, mailId } = action.payload;
      const newEmailSuggestions = state?.suggestionEmails?.map((item) => {
        if (item?.mailId === mailId) {
          return {
            ...item,
            senderMail: email,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        suggestionEmails: newEmailSuggestions,
      };
    case CONTACT_MODAL:
      return {
        ...state,
        contactSupportModal: action.payload,
      };
    case UPDATE_HOWTOSTART:
      return {
        ...state,
        user: {
          ...state.user,
          how_to_start: action.payload,
        },
      };
    case INTRO_MILXWALK_VIDEO_MODAL:
      return {
        ...state,
        user: {
          ...state.user,
          milXwalkVideoModal: action.payload,
        },
      };

    default:
      return { ...state };
  }
};
