import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from '../style';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import uniqid from 'uniqid';
import styles from '../../component/Text/text.module.scss';
import { useDispatch } from 'react-redux';
import { addNewSkillObj, deleteObjInArray } from '../../redux/actions';

export default function AdditionalSkills(props) {
  const classes = useStyles();
  const [skill, setSkill] = useState('');

  const dispatch = useDispatch();
  const path = 'additional_skills.items';
  let skillEndElement;
  //eslint-disable-next-line
  const _addNewItem = () => {
    if (skill.length) {
      let newSkill = {
        id: uniqid(),
        name: skill,
      };

      dispatch(addNewSkillObj(newSkill, path));
      //eslint-disable-next-line
      skillEndElement.scrollIntoView &&
        skillEndElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setSkill('');
    }
  };
  //eslint-disable-next-line
  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };

  let { data } = props;

  const handledData = (() => {
    if (!data?.length) {
      return [
        {
          name: '',
        },
      ];
    }

    // Check if data is Array of Strings
    if (typeof data[0] === 'string') {
      return data?.map((item) => {
        return {
          name: '',
        };
      });
    }

    return data;
  })();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={6} lg={6} xl={6}>
          <div>
            <label className={classes.label}>Add Skill &nbsp;&nbsp;</label>

            <input
              value={skill}
              type="text"
              onChange={(e) => setSkill(e.target.value)}
              placeholder="Ex: Cashier"
              className={styles.contentEditableContainer + ' ' + classes.input}
            />
          </div>
          <div style={{ textAlign: 'end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => _addNewItem()}
              className={classes.button}
              style={{ marginRight: 0 }}
            >
              Add
            </Button>
          </div>
        </Grid>
        <Grid item md={6} lg={6} xl={6} className={classes.skillList}>
          {handledData?.map((item, index) => (
            <div
              key={index}
              className={classes.skillItem}
              title="Click to Remove"
            >
              <Grid container spacing={0}>
                <Grid item md={9} lg={9} xl={9}>
                  <div className={classes.verticalCenter}>
                    <label
                      className={`${classes.label} ${classes.headTextLabel}`}
                    >
                      {item?.name}
                    </label>
                  </div>
                </Grid>
                <Grid item md={3} lg={3} xl={3}>
                  <div
                    className={`${classes.verticalCenter}`}
                    style={{ position: 'relative' }}
                  >
                    <div className={classes.actionContainer}>
                      <label
                        className={`${classes.actionLabel} ${classes.labelTranform}`}
                        onClick={() => {
                          _removeItem(index);
                        }}
                      >
                        <DeleteIcon />
                      </label>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
          <div
            className={classes.skillEnd}
            ref={(el) => {
              skillEndElement = el;
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
