import { colors } from '../../../commonComponents/colors';

export const styles = {
  flex: {
    display: 'flex',
  },
  background: {
    background: '#F7F7F7',
  },
  gradientCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  gradientSpan: (width) => {
    return {
      fontSize: width < 400 ? 12 : 15,
      position: 'relative',
      top: 1,
    };
  },
  pt_120: {
    paddingTop: 120,
  },
  zIndex: {
    zIndex: '999',
  },
  fontWeight: {
    fontWeight: 550,
  },
  topNavLinks: {
    fontSize: '.85rem',
    color: 'black',
  },
  active: {
    color: 'black',
    fontWeight: 800,
  },
  modal: {
    position: 'absolute',
    alignSelf: 'center',
    width: 600,
    // backgroundColor: "white",
    borderRadius: 10,
    top: '20%',
    left: '27%',
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    color: colors.red,
  },
};

export const useStyles = () => ({
  topNavLinks: {
    fontSize: '.85rem',
    color: 'black',
  },
  active: {
    color: '#4267B2',
    fontWeight: 800,
  },
});
