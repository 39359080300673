// React Basic and Bootstrap
import React, { useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import useResponsiveFontSize from '../StripeElements/useResponsiveFonts';
import { connect } from 'react-redux';
import { registerUser, verifyCouponCodeAction } from '../redux/actions';
import { getSkills } from '../../../commonRedux/jobs/action';
import toastr from 'toastr';
import signupImage from '../../../assets/signup.webp';
import { BeatLoader, ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { MdCheck, MdClear } from 'react-icons/md';
import { styles } from '../style';
import Footer from '../../layout/authLayout/Footer';
import useDimenstion from '../useDimension';
import axios from 'axios';
import TopBar from '../../layout/authLayout/Topbar';
import { colors } from '../../../commonComponents/colors';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  );

  return options;
};

const RightImage = ({ signupImage }) => {
  const size = useDimenstion();
  if (size.width < 1000 && size.width > 700) {
    return null;
  }
  return (
    <Col
      lg={6}
      className="offset-lg-6 padding-less img order-1"
      style={{
        backgroundImage: `url(${signupImage})`,
        backgroundSize: 'contain',
      }}
    ></Col>
  );
};
const PageCoverSignup = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [showMessage, setShowMessage] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState('');
  const [verfiedCoupon, setVerifiedCounpon] = React.useState('');
  const [userIp, setUserIp] = React.useState('');
  useEffect(() => {
    if (props.couponVerification === 'success') {
      setVerifiedCounpon(couponCode);
    }
  }, [props.couponVerification]);

  useEffect(() => {
    let isFinal = localStorage.getItem('isFinal', '');

    if (isFinal !== 'yes') {
      props.history.push('/signup');
    }
  });

  const handleCodeBuilder = () => {
    setShowMessage(true);
  };

  const verifyCode = () => {
    if (couponCode === '') {
      toastr.error('please enter the code!');
    } else {
      let data = {
        couponId: couponCode,
      };
      props.verifyCouponCodeAction(data);
    }
  };

  useEffect(async () => {
    try {
      const userIp = await axios.get(
        'https://www.cloudflare.com/cdn-cgi/trace',
      );
      const ipData = userIp.data
        .split('\\')
        .filter((item) => item.includes('ip='));
      setUserIp(ipData);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onUserRegister = async () => {
    if (props.loading) {
      return;
    }
    const data = props.location.state;
    const card = elements.getElement(CardNumberElement);
    const res = await stripe.createToken(card);

    if (res.error) {
      toastr.error(res.error.message);
      return;
    }

    const { firstName, lastName, email, password, isStudent, mobile } =
      data.data;

    if (firstName === '' || lastName === '') {
      toastr.error('firstname and lastname can not be empty');
    } else {
      props.registerUser(
        {
          firstName,
          lastName,
          email,
          password,
          isStudent,
          mobile,
          token: res.token.id,
          coupon: verfiedCoupon,
          userIp: userIp,
        },
        () => {
          props.history.push('/login');
        },
      );
    }
  };

  return (
    <>
      <TopBar />
      <React.Fragment>
        <div className="back-to-home rounded d-none d-sm-block"></div>
        <section className="cover-user bg-white">
          <div className="container-fluid">
            <Row className="position-relative">
              <Col lg={4} className="cover-my-30 order-2">
                <div className="cover-user-img d-flex align-items-center">
                  <Row>
                    <Col>
                      <div className="login_page">
                        <div className="text-center">
                          <h4 style={styles.height_120} className="mb-4">
                            &nbsp;
                          </h4>
                        </div>
                        <form className="login-form">
                          <Row>
                            <Col md={12}>
                              <div className="form-group position-relative">
                                <label>
                                  Card Number{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <CardNumberElement
                                  options={options}
                                  onReady={() => {
                                    //
                                  }}
                                  onChange={() => {
                                    //
                                    //   "CardNumberElement [change]",
                                    //   event
                                    // );
                                  }}
                                  onBlur={() => {
                                    //
                                  }}
                                  onFocus={() => {
                                    //
                                  }}
                                />
                                {/* <input type="email" className="form-control pl-5" placeholder="Email" onChange={this.getValue} value={this.state.email} name="email" required="" /> */}
                              </div>
                            </Col>
                            <Col md={12} style={styles.mt_15}>
                              <div className="form-group position-relative">
                                <label>
                                  Expiration date{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <CardExpiryElement
                                  options={options}
                                  onReady={() => {
                                    //
                                  }}
                                  onChange={() => {
                                    //
                                    //   "CardNumberElement [change]",
                                    //   event
                                    // );
                                  }}
                                  onBlur={() => {
                                    //
                                  }}
                                  onFocus={() => {
                                    //
                                  }}
                                />
                              </div>
                            </Col>

                            <Col md={12} style={styles.mt_15}>
                              <div className="form-group position-relative">
                                <label>
                                  CVC <span className="text-danger">*</span>
                                </label>
                                <CardCvcElement
                                  style={{
                                    borderWidth: 1,
                                  }}
                                  options={options}
                                  onReady={() => {
                                    //
                                  }}
                                  onChange={() => {
                                    //
                                    //   "CardNumberElement [change]",
                                    //   event
                                    // );
                                  }}
                                  onBlur={() => {
                                    //
                                  }}
                                  onFocus={() => {
                                    //
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="form-group position-relative">
                                <label>
                                  Enter Coupon Code{' '}
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <i className="mdi mdi-lock ml-3 icons"></i>
                                <input
                                  type="text"
                                  className="form-control pl-5"
                                  placeholder="code"
                                  onBlur={handleCodeBuilder}
                                  onChange={(e) =>
                                    setCouponCode(e.target.value)
                                  }
                                  // value={this.state.email}
                                  // name="email"
                                  required=""
                                />
                                <span className="changeIcon">
                                  <i className="icons">
                                    {props.couponVerification ===
                                    'requested' ? (
                                      <ClipLoader
                                        sizeUnit={'px'}
                                        size={20}
                                        color={'#4267B2'}
                                        loading={true}
                                      />
                                    ) : props.couponVerification ===
                                      'success' ? (
                                      <MdCheck
                                        style={{ color: colors.green }}
                                      />
                                    ) : props.couponVerification ===
                                      'notVerified' ? (
                                      <MdClear style={{ color: colors.red }} />
                                    ) : null}
                                  </i>
                                </span>
                              </div>

                              {showMessage ? (
                                props.couponVerification === 'success' ? (
                                  <div className="text-primary verificationMessage">
                                    Verified
                                  </div>
                                ) : (
                                  <div
                                    className="text-primary verificationMessage"
                                    onClick={verifyCode}
                                  >
                                    Verify your code
                                  </div>
                                )
                              ) : null}
                            </Col>

                            <Col md={12} style={styles.mt_15}>
                              <div className="form-group">
                                {/* <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck1"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck1"
                                >
                                  I Accept{" "}
                                  <Link to="#" className="text-primary">
                                    Terms And Condition
                                  </Link>
                                </label>
                              </div> */}
                              </div>
                            </Col>

                            <Col md={12}>
                              <button
                                onClick={onUserRegister}
                                type="button"
                                className="btn btn-primary w-100"
                              >
                                {props.loading ? (
                                  <BeatLoader
                                    css={override}
                                    sizeUnit={'px'}
                                    size={10}
                                    color={'white'}
                                    loading={true}
                                  />
                                ) : (
                                  'Register'
                                )}
                              </button>
                            </Col>

                            <div className="mx-auto">
                              <p className="mb-0 mt-3">
                                <small className="text-dark mr-2">
                                  Already have an account ?
                                </small>{' '}
                                <Link
                                  to="/login"
                                  className="text-dark font-weight-bold"
                                >
                                  Sign in
                                </Link>
                              </p>
                            </div>
                          </Row>
                        </form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <RightImage signupImage={signupImage} />
            </Row>
          </div>
        </section>
      </React.Fragment>
      <Footer removeContent />
    </>
  );
};

const mapStateToProps = ({ authUser }) => {
  const { user, loading, couponVerification } = authUser;
  return { user, loading, couponVerification };
};

export default connect(mapStateToProps, {
  registerUser,
  getSkills,
  verifyCouponCodeAction,
})(PageCoverSignup);
