import { createTheme } from "@material-ui/core/styles";

const THEME = createTheme({
  typography: {
    fontFamily: `"Nunito", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 550,
  },
});

export default THEME;
