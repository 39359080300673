import React from 'react';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import { useDispatch } from 'react-redux';
import { updateOrder, addNewObj, deleteObjInArray } from '../../redux/actions';
import { styles } from './style';

function Skills(props) {
  const dispatch = useDispatch();
  const path = 'additional_skills.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  const { data } = props;

  const handledData = (() => {
    if (!data?.length) {
      return [
        {
          Name: '',
        },
      ];
    }

    // Check if data is Array of Strings
    if (typeof data[0] === 'string') {
      return data?.map((item) => {
        return {
          Name: item,
        };
      });
    }

    return data;
  })();

  return (
    <div
      className="manager-template-row manager-template-text-center"
      style={styles.justifyContentCenter}
    >
      <Dnd
        direction="horizontal"
        data={handledData}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <div className="manager-template-row manager-template-text-center manager-f-sub-row">
            <Text
              value={item?.Name}
              placeholder="Additional Skill"
              customclass={'manager-fName'}
              path={`${path}.${index}.Name`}
            />
            {data.length - 1 !== index && <p className="aPadding">|</p>}
          </div>
        )}
      />
    </div>
  );
}
export default Skills;
