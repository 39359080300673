import React from 'react';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import { useDispatch } from 'react-redux';
import { updateOrder, addNewObj, deleteObjInArray } from '../../redux/actions';
import { styles } from './style';

function Courses(props) {
  const dispatch = useDispatch();
  const path = 'courses.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };

  const { data } = props;
  return (
    <div style={styles.pt_20}>
      <Dnd
        data={data}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <div style={{ marginBottom: 20 }}>
            <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>Course: </p>
              <Text
                value={item.course}
                placeholder="Course"
                customclass={
                  'manager-fName manager-template-text-bold manager-mb-bottom-0'
                }
                path={`${path}.${index}.title`}
              />
            </div>
            <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>Institution: </p>
              <Text
                value={item.institution}
                placeholder="Institution"
                customclass={
                  'manager-fName manager-template-text-bold manager-mb-bottom-0'
                }
                path={`${path}.${index}.title`}
              />
            </div>
            <div className="manager-template-row" style={styles.workItem}>
              <p style={styles.paraStyles(true)}>From</p>
              <Text
                value={item.startDate}
                placeholder="Start Date"
                customclass={'manager-fName'}
                path={`${path}.${index}.startDate`}
              />
              <p style={styles.paraStyles(true)}> to </p>
              <Text
                value={item.endDate}
                placeholder="End Date"
                customclass={'manager-fName'}
                path={`${path}.${index}.endDate`}
                max="9999-12-31"
              />
            </div>
          </div>
        )}
      />
    </div>
  );
}
export default Courses;
