import React, { Component } from 'react';
import { Row, Card, CardBody, CardTitle, Table } from 'reactstrap';
import { Colxx } from '../../../commonComponents/customBootstrap';
import { MdCheck, MdClear, MdWarning } from 'react-icons/md';
import { styles } from '../style';
import { colors } from '../../../commonComponents/colors';

export default class RECRUITERFINDINGS extends Component {
  render() {
    return (
      <div>
        <Row>
          <Colxx xxs="12" sm="12" lg="12">
            <Card className="mb-4">
              <CardBody
                style={{ ...styles.fontSize_75_rem, ...styles.fontWeight_400 }}
              >
                <CardTitle>RECRUITER FINDINGS</CardTitle>
                <Table hover>
                  <tr>
                    <th scope="row">WORD COUNT</th>
                    <td>
                      {this.props.recruiterFindings.length.current > 1200 ||
                      this.props.recruiterFindings.length.current < 800 ? (
                        <MdWarning size="1.7em" color="orange" />
                      ) : (
                        <MdCheck size="1.7em" color={colors.green} />
                      )}
                    </td>

                    {this.props.recruiterFindings.length.current > 1200 &&
                    this.props.recruiterFindings.length.current > 800 ? (
                      <td style={styles.colorGrey}>
                        There are
                        <span
                          style={{
                            color:
                              this.props.recruiterFindings.length.current >
                                1200 &&
                              this.props.recruiterFindings.length.current > 800
                                ? colors.red
                                : colors.green,
                          }}
                        >
                          {this.props.recruiterFindings.length.current}
                        </span>
                        words in your resume. If you are not applying to
                        executive-level, government, or Australia-based jobs,
                        consider reducing your resume length to under{' '}
                        <span style={{ color: colors.green }}>
                          {this.props.recruiterFindings.length.allowed}
                        </span>{' '}
                        words to increase focus and for ease of reading by
                        recruiters.
                      </td>
                    ) : (
                      <td style={styles.colorGrey}>
                        There are
                        <span
                          style={{
                            color:
                              this.props.recruiterFindings.length.current >
                                1200 &&
                              this.props.recruiterFindings.length.current > 800
                                ? colors.red
                                : colors.green,
                          }}
                        >
                          {this.props.recruiterFindings.length.current}
                        </span>
                        words in your resume. This length of words is good for
                        the resume as it is easy for people to read and
                        understand
                      </td>
                    )}
                  </tr>

                  <tbody>
                    <tr>
                      <th scope="row">MEASURABLE RESULTS</th>
                      <td>
                        {!this.props.recruiterFindings.measureableResults ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                      {!this.props.recruiterFindings.measureableResults ? (
                        <td style={styles.colorGrey}>
                          There are no mentions of measurable results in your
                          resume. Not Good!
                        </td>
                      ) : (
                        <td style={styles.colorGrey}>
                          There are five or more mentions of measurable results
                          in your resume. Keep it up - employers like to see the
                          impact and results that you had on the job.
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th scope="row">JOB LEVEL MATCH</th>
                      <td>
                        {!this.props.recruiterFindings.jobLevelMatch ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                      {!this.props.recruiterFindings.jobLevelMatch ? (
                        <td style={styles.colorGrey}>
                          You are applying to{' '}
                          <span style={{ color: '#145388' }}>
                            {' '}
                            {this.props.ats.jobTitle}
                          </span>{' '}
                          role. Which dose not match your experience
                        </td>
                      ) : (
                        <td style={styles.colorGrey}>
                          You are applying to{' '}
                          <span style={{ color: '#145388' }}>
                            {' '}
                            {this.props.ats.jobTitle}
                          </span>{' '}
                          role. Based on your experience, this is a good fit.
                        </td>
                      )}
                    </tr>
                    <tr>
                      <th scope="row">WORDS TO AVOID</th>
                      <td>
                        {this.props.recruiterFindings.wordsToAvoid ? (
                          <MdClear size="1.7em" color={colors.red} />
                        ) : (
                          <MdCheck size="1.7em" color={colors.green} />
                        )}
                      </td>
                      {this.props.recruiterFindings.wordsToAvoid ? (
                        <td style={styles.colorGrey}>
                          We've found some negative phrases or cliches in your
                          resume: ".. management team player with .." - Give
                          examples of collaborative results instead.
                        </td>
                      ) : (
                        <td style={styles.colorGrey}>
                          We did not find any cliches and negative phrases in
                          your resume Great!
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </div>
    );
  }
}
