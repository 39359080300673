import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import { BeatLoader } from 'react-spinners';
import { resetPassword } from '../redux/actions';
// import images
import loginImage from '../../../assets/signup.webp';
import { css } from '@emotion/core';
import { styles } from '../style';
import useDimenstion from '../useDimension';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Link } from 'react-router-dom';
import Footer from '../../layout/authLayout/Footer';
const inputStyles = {
  visibilityIconStyle: {
    width: '30px',
    height: '20px',
    position: 'absolute',
    right: 10,
    top: 43,
    cursor: 'pointer',
  },
};
const RightImage = ({ signupImage }) => {
  const size = useDimenstion();
  if (size.width < 1000 && size.width > 700) {
    return null;
  }
  return (
    <Col
      lg={8}
      className="offset-lg-5 padding-less img order-1"
      style={{
        backgroundImage: `url(${signupImage})`,
        backgroundSize: 'contain',
      }}
    ></Col>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const ResetPage = (props) => {
  console.log('tokens:', props.match.params['reset_token']);
  const dispatch = useDispatch();
  const [state, setStateData] = useState({
    confirm_password: '',
    password: '',
    showPassword: false,
    showConfirmPassword: false,
  });
  const [loading, setLoader] = useState(false);

  const setState = (data) => {
    setStateData({
      ...state,
      ...data,
    });
  };

  const togglePassword = () =>
    setStateData({ ...state, showPassword: !state.showPassword });
  const toggleConfirmPassword = () =>
    setStateData({ ...state, showConfirmPassword: !state.showConfirmPassword });

  const getValue = (e) => {
    setState({ [e.target.name]: e.target.value });
  };
  const callBack = (success) => {
    if (success) {
      props.history.push('/');
    }
    setLoader(false);
  };

  const onUserLogin = () => {
    if (loading) {
      return;
    }
    if (state.password === '') {
      toastr.error("Password can't be empty");
    } else if (state.confirm_password === '') {
      toastr.error("Confirm password can't be empty");
    } else if (state.password !== state.confirm_password) {
      toastr.error('Password not matched');
    } else {
      setLoader(true);
      dispatch(
        resetPassword(
          {
            password: state.confirm_password,
            reset_token: props.match.params['reset_token'],
          },
          callBack,
        ),
      );

      //   this.props.loginUser();
    }
  };
  return (
    <>
      <div className="back-to-home rounded d-sm-block p-3">
        <Link to="/" className="text-white rounded d-inline-block text-center">
          <i className="mdi mdi-home"></i>
        </Link>
      </div>
      <section
        className="cover-user bg-white d-flex"
        style={styles.mainSection}
      >
        <Row className="position-relative container-fluid">
          <Col lg={6} className="cover-my-30 order-5">
            <div className="cover-user-img d-flex align-items-center">
              <div
                className="login-page position-relative"
                style={styles.zIndex}
              >
                <div className="text-center">
                  <h4 className="mb-4">Reset Password</h4>
                </div>
                <form className="login-form">
                  <Row>
                    <Col lg={12}>
                      <div className="form-group position-relative">
                        <label>
                          Password <span className="text-danger">*</span>
                        </label>
                        <i className="mdi mdi-account ml-3 icons"></i>
                        <input
                          value={state.password}
                          onChange={getValue}
                          type={state.showPassword ? 'text' : 'password'}
                          className="form-control pl-5"
                          placeholder="Password"
                          name="password"
                          required=""
                        />
                        {state.showPassword ? (
                          <span onClick={togglePassword}>
                            {' '}
                            <VisibilityOffIcon
                              style={inputStyles.visibilityIconStyle}
                            />
                          </span>
                        ) : (
                          <span onClick={togglePassword}>
                            {' '}
                            <VisibilityIcon
                              style={inputStyles.visibilityIconStyle}
                            />
                          </span>
                        )}
                        {state.password.length > 0 &&
                        state.password.length < 8 ? (
                          <span style={{ fontSize: 10, color: 'red' }}>
                            Password must be 8 digits
                          </span>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="form-group position-relative">
                        <label>
                          Confirm Password{' '}
                          <span className="text-danger">*</span>
                        </label>
                        <i className="mdi mdi-key ml-3 icons"></i>

                        <input
                          value={state.confirm_password}
                          onChange={getValue}
                          type={state.showConfirmPassword ? 'text' : 'password'}
                          name="confirm_password"
                          className="form-control pl-5"
                          placeholder="Confirm Password"
                          required=""
                        />
                        {state.showConfirmPassword ? (
                          <span onClick={toggleConfirmPassword}>
                            {' '}
                            <VisibilityOffIcon
                              style={inputStyles.visibilityIconStyle}
                            />
                          </span>
                        ) : (
                          <span onClick={toggleConfirmPassword}>
                            {' '}
                            <VisibilityIcon
                              style={inputStyles.visibilityIconStyle}
                            />
                          </span>
                        )}
                        {state.confirm_password.length > 0 &&
                        state.confirm_password.length < 8 ? (
                          <span style={{ fontSize: 10, color: 'red' }}>
                            Password must be 8 digits
                          </span>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12} className="mb-0">
                      <button
                        onClick={onUserLogin}
                        type="button"
                        disabled={
                          loading ||
                          state.password.length < 8 ||
                          state.confirm_password.length < 8
                        }
                        className="btn btn-primary w-100"
                      >
                        {loading ? (
                          <BeatLoader
                            css={override}
                            sizeUnit={'px'}
                            size={10}
                            color={'white'}
                            loading={true}
                          />
                        ) : (
                          'Change Password'
                        )}
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </Col>
          <RightImage signupImage={loginImage} />
        </Row>
      </section>
      <Footer removeContent />
    </>
  );
};

export default ResetPage;
