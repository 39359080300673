import React, { useState, memo, useEffect } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import TopProgress from '../resumeBuilder/component/topProgress';
import { matchWords } from '../../utils';
import {
  fileParser,
  AnalyzParsedResume,
  updateResumeTextAction,
  downloadResumePdf,
  addSkillsAction,
} from '../../commonRedux/scan/action';
import { connect, useDispatch } from 'react-redux';
import { styles } from './style';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import './style.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';

let convertedNotification = '';
let isEditResume = false;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const CustomEditor = memo(({ checkWord, defaultText = '' }) => {
  const [content, setContent] = useState(true);
  useEffect(() => {
    if (defaultText) {
      convertedNotification = defaultText;
      checkWord(true);
    }
    setTimeout(() => {
      setContent(false);
    }, 1000);
    return function remove() {
      convertedNotification = '';
    };
  }, []);
  const onChange = (newContent) => {
    isEditResume = true;
    convertedNotification = newContent;
    checkWord();
  };
  const configFroalaEditor = {
    width: '650',
    height: '80vh',
    toolbarSticky: false,
    fontSizeSelection: true,
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline', 'fontFamily'],
      },
      moreParagraph: {
        buttons: ['formatUL'],
      },
      moreRich: {
        buttons: ['insertLink'],
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'print'],
        align: 'right',
        buttonsVisible: 3,
      },
    },
    charCounterCount: false,
    attribution: false,
    key: 'CTD5xB1C2B2C1A7B5wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4A3C6E2F2F4G1C1==',
  };
  if (content) {
    return (
      <center>
        <BeatLoader
          className="loader"
          css={override}
          sizeUnit={'px'}
          size={10}
          color={'#D5641C'}
          loading={true}
        />
      </center>
    );
  }

  return (
    <center>
      <div>
        <FroalaEditorComponent
          config={configFroalaEditor}
          model={convertedNotification || defaultText}
          onModelChange={onChange}
        />
      </div>
    </center>
  );
});

const ModalRescan = (props) => {
  const [modalLarge, setModalLarge] = React.useState(false);
  const dispatch = useDispatch();
  const [desc, setDesc] = React.useState('');

  React.useEffect(() => {
    if (props.description.resume) {
      let resumeDiscription = props.description.resume.replace(
        /<img[^>]*>/g,
        '',
      );
      setDesc(resumeDiscription);
    }
  }, [props.description]);

  function checkWord(hideToaster = false) {
    if (convertedNotification) {
      const hardSkillsOriginal = props.skills?.hard_skills;
      if (hardSkillsOriginal) {
        const filterWords = Object.keys(hardSkillsOriginal).filter(
          (n) => !hardSkillsOriginal[n].exists,
        );
        const matchingWords = matchWords(convertedNotification, filterWords);

        const res = {
          oid: props.oid,
          skills: { ...props.skills },
          isHave: false,
          hideToaster: hideToaster,
        };
        const updateTo = 'hard_skills';
        const newSkills = [];
        matchingWords.forEach((n) => {
          if (hardSkillsOriginal[n] && !hardSkillsOriginal[n].exists) {
            newSkills.push({ id: 99, name: n });
            res.skills[updateTo][n].exists = true;
            res.isHave = true;
          }
        });

        if (res.isHave) {
          dispatch(addSkillsAction(res, newSkills, convertedNotification));
        }
      }
    }
  }

  const toggleLarge = () => {
    if (props.goBuilder) {
      props.goBuilder();
    }
  };

  const modalCloseCalback = () => {
    setModalLarge(false);
  };

  const onSubmitFun = () => {
    let data = {
      resumeText: convertedNotification || desc,
      jobDescription: props.description.job,
      is_parsed: false,
      fileType: 'text',
      scan_id: props.oid,
      resume_id: props.resumeId,
      jobTitle: props.jobTitle,
      company: props.company,
    };
    props.AnalyzParsedResume(data, modalCloseCalback);
  };
  const updateResumeTextFun = () => {
    if (convertedNotification && isEditResume) {
      let data = {
        oid: props.oid,
        resumeText: convertedNotification,
        isEditResume,
      };
      props.updateResumeTextAction(data);
    }
  };

  return (
    <div className="rescan-main-container">
      <Modal
        isOpen={modalLarge}
        size="lg"
        toggle={toggleLarge}
        style={styles.modal}
      >
        <ModalHeader
          toggle={toggleLarge}
          style={{ padding: 15 }}
          className="linear-background"
        >
          <div>
            <Button style={styles.modalBtn} size="small" onClick={toggleLarge}>
              Cancel
            </Button>

            <span style={{ paddingLeft: 5, paddingRight: 5 }}></span>
            <Button
              disabled={!!props.updateResumeTextStatus}
              style={styles.modalBtn}
              size="small"
              onClick={updateResumeTextFun}
            >
              {props.updateResumeTextStatus ? 'loading...' : 'SAVE'}
            </Button>
            <span style={{ paddingLeft: 5, paddingRight: 5 }}></span>
            <Button
              disabled={!!props.parsedAnalyzStatus}
              style={styles.modalBtn}
              onClick={onSubmitFun}
              size="small"
            >
              {props.parsedAnalyzStatus ? (
                <BeatLoader
                  className="loader"
                  css={override}
                  sizeUnit={'px'}
                  size={10}
                  color={'#FFFFFF'}
                  loading={true}
                />
              ) : (
                <>
                  <RefreshIcon style={{ fontSize: 18 }} /> Update & Rescan
                </>
              )}
            </Button>
          </div>
        </ModalHeader>
        <ModalBody style={styles.modalBody}>
          <div>
            <TopProgress isModal />
            <Row>
              <Col lg="12" style={{ padding: 0 }}>
                <CustomEditor checkWord={checkWord} defaultText={desc} />
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ fileScanReducer }) => {
  return {
    resumeDescription: fileScanReducer.parsefile,
    parsedAnalyzStatus: fileScanReducer.parsedAnalyzStatus,
    updateResumeStatus: fileScanReducer.updateResumeStatus,
    updateResumeTextStatus: fileScanReducer.updateResumeTextStatus,
  };
};

export default connect(mapStateToProps, {
  fileParser,
  AnalyzParsedResume,
  updateResumeTextAction,
  downloadResumePdf,
})(ModalRescan);
