import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import queryString from 'query-string';
import {
  saveToken,
  saveDropBoxToken,
  changeSyncStatus,
  userAcountActions,
  currentUser,
} from '../auth/redux/actions';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';
import { styles } from './style';
import { withStyles } from '@material-ui/styles';
import Loader from '../account/loader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  root2: {
    width: '100%',
    maxWidth: '50ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  checkBoxLabel: {
    fontSize: '0.89rem',
  },
}));

const Setting = (props) => {
  const classes = useStyles();
  const [checked, setchecked] = React.useState(false);
  const [isDropBoxChecked, setIsDropBoxChecked] = React.useState(false);
  const [saveResume, setSaveResume] = React.useState(true);
  const [saveReviews, setSaveReviews] = React.useState(false);
  const [pdf, setPdf] = React.useState(false);
  const [wordAllReviewed, setWordAllReviewed] = React.useState(false);
  const [word, setWord] = React.useState(false);
  const [SaveMonthlyReport, setSaveMonthlyReport] = React.useState(false);
  const [dropboxToken, setDropBoxToken] = React.useState('');
  const [driveToken, setDriveToken] = React.useState('');
  const width = useWindowWidth();
  const user = useSelector((state) => state.authUser.user);

  React.useEffect(() => {
    if (user) {
      localStorage.setItem('currentUser', JSON.stringify(user));

      const { saveEvents, token } = props.user;
      setPdf(saveEvents?.pdf);
      setSaveMonthlyReport(saveEvents?.saveMonthlyReport);
      setSaveResume(saveEvents?.saveResume);
      setSaveReviews(saveEvents?.saveReview);
      setWord(saveEvents?.word);
      setWordAllReviewed(saveEvents?.wordAllReview);
      setchecked(saveEvents?.checked);
      setIsDropBoxChecked(saveEvents?.isDropBoxChecked);

      setDriveToken(token?.driveToken);
      setDropBoxToken(token?.dropboxToken);
    }
  }, [props.user]);

  React.useEffect(() => {
    props.currentUser();
  }, []);

  const CustomSwitch = withStyles({
    switchBase: {
      '&$checked': {
        color: '#4267B2',
      },
      '&$checked + $track': {
        backgroundColor: '#4267B2',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const googleInstance = axios.create({
    baseURL: `https://oauth2.googleapis.com`,

    transformRequest: [
      (data, headers) => {
        //
        delete headers.common.Authorization;
        return data;
      },
    ],
  });

  const getToken = () => {
    if (window.location.href.includes('?')) {
      let data = queryString.parse(window.location.href.split('?')[1]);
      let code = data.code;

      googleInstance({
        method: 'POST',
        //online
        url: `/token?code=${code}&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&client_secret=${process.env.REACT_APP_GOOGLE_CLIENT_SECRET_KEY}&redirect_uri=https://resumas.com/account&grant_type=authorization_code`,
      })
        .then((response) => {
          setchecked(true);

          props.userAcountActions({
            cronJobs: props.user && props.user.cronJobs,
            emailNotifications: props.user && props.user.emailNotifications,
            smsNotifications: props.user && props.user.smsNotifications,
            tokens: {
              dropboxToken: props.user && props.user.token.dropboxToken,
              driveToken: response.data.access_token,
            },
            events: {
              pdf: pdf,
              saveMonthlyReport: SaveMonthlyReport,
              saveResume: props.user && props.user.saveEvents.saveResume,
              saveReview: saveReviews,
              word: word,
              wordAllReview: wordAllReviewed,
              checked: true,
              isDropBoxChecked:
                props.user && props.user.saveEvents.isDropBoxChecked,
            },
          });
        })
        .catch((err) => {
        });
    }
  };

  const dropInstance = axios.create({
    baseURL: `https://api.dropbox.com`,

    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization;
        return data;
      },
    ],
  });

  //get token from drop box
  const getDropBoxtoken = () => {
    if (window.location.href.includes('?')) {
      let data = queryString.parse(window.location.href.split('?')[1]);
      let code = data.code;

      dropInstance({
        method: 'POST',
        //online
        url: `/oauth2/token?code=${code}&client_id=${process.env.REACT_APP_DROP_BOX_CLIENT_ID}&client_secret=${process.env.REACT_APP_DROP_BOX_CLIENT_SECRET_KEY}&redirect_uri=https://resumas.com/account&grant_type=authorization_code`,
      })
        .then((responce) => {
          setIsDropBoxChecked(true);

          props.userAcountActions({
            cronJobs: props.user && props.user.cronJobs,
            emailNotifications: props.user && props.useremailNotifications,
            smsNotifications: props.user && props.usersmsNotifications,
            tokens: {
              dropboxToken: responce.data.access_token,
              driveToken: props.user && props.user.token.driveToken,
            },
            events: {
              pdf: pdf,
              saveMonthlyReport: SaveMonthlyReport,
              saveResume: props.user && props.user.saveEvents.saveResume,
              saveReview: saveReviews,
              word: word,
              wordAllReview: wordAllReviewed,
              checked: props.user && props.user.saveEvents.checked,
              isDropBoxChecked: true,
            },
          });
        })
        .catch((err) => {
        });
    }
  };

  const onChangeDropBox = () => {
    if (!isDropBoxChecked) {
      if (dropboxToken) {
        props.userAcountActions({
          cronJobs: props.user && props.user.cronJobs,
          emailNotifications: props.user && props.user.emailNotifications,
          smsNotifications: props.user && props.user.smsNotifications,
          tokens: {
            dropboxToken: dropboxToken,
            driveToken: driveToken,
          },
          events: {
            pdf: pdf,
            saveMonthlyReport: SaveMonthlyReport,
            saveResume: saveResume,
            saveReview: saveReviews,
            word: word,
            wordAllReview: wordAllReviewed,
            checked: checked,
            isDropBoxChecked: true,
          },
        });

        setIsDropBoxChecked(true);
        // }
      } else {
        window.open(
          //online
          `https://www.dropbox.com/1/oauth2/authorize?client_id=${process.env.REACT_APP_DROP_BOX_CLIENT_ID}&response_type=code&redirect_uri=https://resumas.com/account&state=abcd43erfsdf`,
          '_self',
        );
        setIsDropBoxChecked(true);
      }
    } else {
      props.userAcountActions({
        cronJobs: props.user && props.user.cronJobs,
        emailNotifications: props.user && props.user.emailNotifications,
        smsNotifications: props.user && props.user.smsNotifications,
        tokens: {
          dropboxToken: dropboxToken,
          driveToken: driveToken,
        },
        events: {
          pdf: pdf,
          saveMonthlyReport: SaveMonthlyReport,
          saveResume: saveResume,
          saveReview: saveReviews,
          word: word,
          wordAllReview: wordAllReviewed,
          checked: checked,
          isDropBoxChecked: false,
        },
      });
      setIsDropBoxChecked(false);
    }
  };

  React.useEffect(() => {
    getToken();
    getDropBoxtoken();
  }, []);

  React.useEffect(() => {
    let checkIsDropSync = localStorage.getItem('isDropSync');
    if (checkIsDropSync === 'true') {
      setIsDropBoxChecked(true);
    } else if (checkIsDropSync === 'false') {
      setIsDropBoxChecked(false);
    }
  }, []);

  const onChangeR = () => {
    if (!checked) {
      if (driveToken) {
        props.userAcountActions({
          cronJobs: props.user && props.user.cronJobs,
          emailNotifications: props.user && props.user.emailNotifications,
          smsNotifications: props.user && props.user.smsNotifications,
          tokens: {
            dropboxToken: dropboxToken,
            driveToken: driveToken,
          },
          events: {
            pdf: pdf,
            saveMonthlyReport: SaveMonthlyReport,
            saveResume: saveResume,
            saveReview: saveReviews,
            word: word,
            wordAllReview: wordAllReviewed,
            checked: true,
            isDropBoxChecked: isDropBoxChecked,
          },
        });

        setchecked(true);
        // }
      } else {
        setchecked(true);

        //online
        window.open(
          `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file&redirect_uri=https%3A%2F%2Fresumas.com%2Faccount`,
          '_self',
        );
      }
    } else {
      setchecked(false);
      props.userAcountActions({
        cronJobs: props.user && props.user.cronJobs,
        emailNotifications: props.user && props.user.emailNotifications,
        smsNotifications: props.user && props.user.smsNotifications,
        tokens: {
          dropboxToken: dropboxToken,
          driveToken: driveToken,
        },
        events: {
          pdf: pdf,
          saveMonthlyReport: SaveMonthlyReport,
          saveResume: saveResume,
          saveReview: saveReviews,
          word: word,
          wordAllReview: wordAllReviewed,
          checked: false,
          isDropBoxChecked: isDropBoxChecked,
        },
      });
    }
  };

  if (props.currentUserStatus) {
    return <Loader />;
  } else
    return (
      <div>
        <div style={styles.fontWeight_bold}>Save to my Drive</div>
        <div style={styles.bb_ligthGrey}></div>
        <Row>
          <Col
            lg="12"
            style={{
              overflow: 'hidden',
            }}
          >
            <List className={classes.root2}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://cdn4.iconfinder.com/data/icons/logos-brands-in-colors/48/google-drive-512.webp"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                      }}
                    >
                      <p style={styles.h5}>Sync to my Google Drive</p>
                      <CustomSwitch checked={checked} onChange={onChangeR} />
                      {width > 500 && (
                        <h6 style={{ marginLeft: 10, marginTop: 10 }}>
                          Connect
                        </h6>
                      )}
                    </div>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://images.vexels.com/media/users/3/137252/isolated/preview/4dd30992028be809395fc0a11c20d5a5-dropbox-icon-logo-by-vexels.webp"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div
                      style={{
                        ...styles.flex,
                        ...styles.flexRow,
                      }}
                    >
                      <p style={styles.h5}>Sync to my Dropbox</p>
                      <CustomSwitch
                        checked={isDropBoxChecked}
                        onChange={onChangeDropBox}
                      />
                      {width > 500 && (
                        <h6 style={{ marginLeft: 10, marginTop: 10 }}>
                          Connect
                        </h6>
                      )}
                    </div>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
          </Col>
        </Row>
      </div>
    );
};

const mapStateToProps = ({ authUser }) => {
  return {
    isSync: authUser.isSync,
    user: authUser.user,
    currentUserStatus: authUser.currentUserStatus,
  };
};
export default connect(mapStateToProps, {
  saveToken,
  changeSyncStatus,
  saveDropBoxToken,
  userAcountActions,
  currentUser,
})(Setting);
