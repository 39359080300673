import React from 'react';

function GoogleTrendsGraph(props) {
  const jobTitle = props.jobTitle.toUpperCase();
  const url = `https://trends.google.com:443/trends/embed/explore/TIMESERIES?req=%7B%22comparisonItem%22%3A%5B%7B%22keyword%22%3A%22${jobTitle}%22%2C%22geo%22%3A%22US%22%2C%22time%22%3A%22today%2012-m%22%7D%5D%2C%22category%22%3A0%2C%22property%22%3A%22%22%7D&tz=-300&eq=geo%3DUS%26q%3D${jobTitle}%26date%3Dtoday%2012-m`;

  return (
    <div
      style={{
        width: '100%',
        height: 350,
      }}
    >
      <iframe
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        src={url}
      ></iframe>
    </div>
  );
}

export default GoogleTrendsGraph;
