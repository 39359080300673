import { combineReducers } from 'redux';
import menu from '../modules/layout/redux/reducer';
import authUser from '../modules/auth/redux/reducer';
import { linkReducer } from './currentNav/reducer';
import { jobRducer } from './jobs/reducer';
import templateReducer from './template/reducer';
import { fileScanReducer } from './scan/reducer';
import { profileReducer } from '../modules/profile/redux/reducer.js';
import editorReducer from '../modules/resumeBuilder/redux/reducer';
import { LOGOUT_USER } from '../modules/auth/redux/constants';
import { linkedinReducer } from '../modules/linkedin/redux/reducer';
import docEditorReducer from '../commonComponents/syncFusion/redux/reducer';
import { militaryReducer } from '../modules/militaryXWalk/redux/reducer';
const reducers = combineReducers({
  authUser,
  jobRducer,
  menu,
  linkReducer,
  templateReducer,
  fileScanReducer,
  profileReducer,
  editorReducer,
  linkedinReducer,
  docEditorReducer,
  militaryReducer,

  //   settings,
  //   todoApp,
  //   chatApp,
  //   surveyListApp,
  //   surveyDetailApp,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    //eslint-disable-next-line
    state.fileScanReducer.videoLinks = action.payload;
    state.fileScanReducer.historyData = [];
    state.fileScanReducer.builderSelectedTitle = '';
  }

  return reducers(state, action);
};
export default rootReducer;
