import React from 'react';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import { useDispatch } from 'react-redux';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';
import { styles } from './style';
import RichEditor from '../../formBuilder/helper/RichEditor';

function Trainings(props) {
  const dispatch = useDispatch();
  const path = 'training.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };

  const { data } = props;

  return (
    <Dnd
      data={data}
      reorder={(e) => onOrderUpdate(e)}
      additem={_addNewItem}
      removeitem={(index) => _removeItem(index)}
      renderItem={(item, index) => {
        return (
          <div>
            {/* <Text
              value={item['Type']}
              path={`${path}.${index}.Type`}
              placeholder="Training Type"
              customclass={'manager-fName manager-template-text-bold'}
            /> */}
            {/* <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>Entity: </p>
              <Text
                value={item['Entity']}
                path={`${path}.${index}.Entity`}
                placeholder="Entity"
                customclass={'manager-fName'}
              />
            </div> */}
            <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>Training Name: </p>
              <Text
                value={item['TrainingName']}
                path={`${path}.${index}.TrainingName`}
                placeholder="Training Title"
                customclass={'manager-fName manager-template-text-bold '}
              />
            </div>
            <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>From </p>
              <Text
                value={item['StartDate'] ? item['StartDate'].YearMonth : ''}
                path={`${path}.${index}.StartDate.YearMonth`}
                placeholder="2018-09"
                customclass={'manager-fName manager-mb-bottom-0'}
              />
              <p style={styles.paraStyles(true)}>to</p>
              <Text
                value={item['EndDate'] ? item['EndDate'].YearMonth : ''}
                path={`${path}.${index}.EndDate.Year`}
                placeholder="2020"
                customclass={'manager-fName manager-mb-bottom-0'}
              />
            </div>
            <div>
              <strong>Description</strong>
              {/* <Text
                value={item['Description']}
                path={`${path}.${index}.Description`}
                placeholder="Description"
                customclass={'manager-fName'}
                style={{ lineHeight: 1.6 }}
              /> */}
              <RichEditor
                value={item['Description'] || ''}
                enableInline
                placeHolder="Description"
                path={`${path}.${index}.Description`}
                onBlurField={onBlurField}
                heightMax={true}
              />
            </div>
          </div>
        );
      }}
    />
  );
}

export default Trainings;
