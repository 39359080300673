import React from 'react';
const Education1 = () => {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="45"
      viewBox="0 0 39 36"
    >
      <g fill-rule="nonzero" fill="currentColor">
        <path
          d="M38.428 34.802h-1.26v-15.95h1.26a.573.573 0 0 0 .483-.88l-4.274-6.717a.572.572 0 0 0-.483-.265H22.79l-2.718-2.717V6.029h5.534a.572.572 0 0 0 .476-.89l-1.01-1.514 1.01-1.514a.572.572 0 0 0-.476-.89h-5.534V.572a.572.572 0 0 0-1.144 0v7.7L16.21 10.99H4.846c-.195 0-.377.1-.483.265L.09 17.972a.573.573 0 0 0 .483.88h1.26v15.95H.572a.572.572 0 0 0 0 1.145h37.856a.572.572 0 0 0 0-1.145zm-4.589-22.667l3.546 5.571h-7.879l-5.571-5.571h9.904zm-9.303-9.77l-.628.943a.572.572 0 0 0 0 .635l.628.942h-4.464v-2.52h4.464zm-19.375 9.77h9.904l-5.571 5.571H1.615l3.546-5.571zm-2.184 6.716H9.73a.566.566 0 0 0 .057-.003l.024-.003.031-.005.027-.007.027-.006.027-.01.026-.01.024-.01.027-.013.023-.014.025-.015.025-.018.02-.016a.552.552 0 0 0 .042-.038L19.5 9.32l9.364 9.364a.603.603 0 0 0 .042.039l.02.015.025.018.025.015.023.014.026.012.025.012.026.01.027.009.026.006.028.007.03.005c.009.001.017.003.026.003a.582.582 0 0 0 .056.003h6.754v15.951H23.736v-7.975c0-.99-.805-1.794-1.794-1.794h-4.884c-.99 0-1.794.805-1.794 1.794v7.975H2.977v-15.95zm19.614 8.625h-6.182v-.65a.65.65 0 0 1 .649-.648h4.884a.65.65 0 0 1 .649.649v.649zm-6.182 1.144h2.519v6.182h-2.519V28.62zm3.663 0h2.519v6.182h-2.519V28.62z"
          fill="currentColor"
        ></path>
        <path
          d="M19.5 23.125a4.24 4.24 0 0 0 4.236-4.236 4.24 4.24 0 0 0-4.236-4.235 4.24 4.24 0 0 0-4.236 4.235 4.24 4.24 0 0 0 4.236 4.236zm0-7.327a3.094 3.094 0 0 1 3.091 3.091A3.094 3.094 0 0 1 19.5 21.98a3.094 3.094 0 0 1-3.091-3.09 3.094 3.094 0 0 1 3.091-3.092z"
          fill="currentColor"
        ></path>
        <path d="M19.5 19.462a.572.572 0 0 0 .572-.573v-1.831a.572.572 0 0 0-1.144 0v1.259h-1.26a.572.572 0 0 0 0 1.145H19.5zM13.394 28.086h-3.053a.572.572 0 0 0-.572.573v3.663c0 .316.256.572.572.572h3.053a.572.572 0 0 0 .573-.572v-3.663a.572.572 0 0 0-.573-.573zm-.572 1.145v.687h-1.908v-.687h1.908zm-1.908 2.519v-.687h1.908v.687h-1.908zM7.9 28.086H4.845a.572.572 0 0 0-.572.573v3.663c0 .316.256.572.572.572H7.9a.572.572 0 0 0 .573-.572v-3.663a.572.572 0 0 0-.573-.573zm-.573 1.145v.687H5.419v-.687h1.908zM5.419 31.75v-.687h1.908v.687H5.419zM13.204 20.76h-2.672a.764.764 0 0 0-.763.763v4.083c0 .316.256.572.572.572h3.053a.572.572 0 0 0 .573-.572v-4.083a.764.764 0 0 0-.763-.764zm-.382 1.144v1.298h-1.908v-1.298h1.908zm-1.908 3.13v-.688h1.908v.687h-1.908zM7.708 20.76h-2.67a.764.764 0 0 0-.764.763v4.083c0 .316.256.572.572.572H7.9a.572.572 0 0 0 .573-.572v-4.083a.764.764 0 0 0-.764-.764zm-.381 1.144v1.298H5.419v-1.298h1.908zm-1.908 3.13v-.688h1.908v.687H5.419zM31.1 32.894h3.054a.572.572 0 0 0 .572-.572v-3.663a.572.572 0 0 0-.572-.573H31.1a.572.572 0 0 0-.573.573v3.663c0 .316.257.572.573.572zm.573-1.144v-.687h1.908v.687h-1.908zm1.908-2.52v.688h-1.908v-.687h1.908zM25.606 32.894h3.053a.572.572 0 0 0 .572-.572v-3.663a.572.572 0 0 0-.572-.573h-3.053a.572.572 0 0 0-.573.573v3.663c0 .316.257.572.573.572zm.572-1.144v-.687h1.908v.687h-1.908zm1.908-2.52v.688h-1.908v-.687h1.908zM31.1 26.178h3.054a.572.572 0 0 0 .572-.572v-4.083a.764.764 0 0 0-.763-.764h-2.671a.764.764 0 0 0-.764.764v4.083c0 .316.257.572.573.572zm.573-1.145v-.687h1.908v.687h-1.908zm1.908-3.129v1.298h-1.908v-1.298h1.908zM25.606 26.178h3.053a.572.572 0 0 0 .572-.572v-4.083a.764.764 0 0 0-.763-.764h-2.672a.764.764 0 0 0-.763.764v4.083c0 .316.257.572.573.572zm.572-1.145v-.687h1.908v.687h-1.908zm1.908-3.129v1.298h-1.908v-1.298h1.908z"></path>
      </g>
    </svg>
  );
};
export default Education1;
