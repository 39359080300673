import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateLinkedinData } from '../redux/actions';

import { useWindowSize } from '@react-hook/window-size';
import moment from 'moment';

export default function Courses(props) {
  const [width] = useWindowSize();

  const [courseName, setCourseName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [fieldIndex, setIndex] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [copied, setCopied] = useState([]);

  const dispatch = useDispatch();
  const linkedinData = props.linkedinData;
  const coursesModal = useSelector(
    (state) => state.linkedinReducer.coursesModal,
  );
  const coursesModalMode = useSelector(
    (state) => state.linkedinReducer.coursesModalMode,
  );
  useEffect(() => {
    if (coursesModalMode === 'new') {
      setCourseName('');
      setStartDate('');
      setEndDate('');
    }
  }, [coursesModalMode]);

  const cb = (v) => {
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Volunteer Experience Updated');
    } else {
      toastr.error('Error Updating Volunteer Experience');
    }
  };
  const handleUpdate = () => {
    try {
      setLoading(true);
      if (isEdit) {
        linkedinData.trainingHistory.Trainings[fieldIndex] = {
          Text: courseName,
          StartDate: {
            Date: startDate,
          },
          EndDate: {
            Date: endDate,
          },
        };
      } else {
        if (Array.isArray(linkedinData?.trainingHistory?.Trainings)) {
        linkedinData.trainingHistory.Trainings.push({
          Text: courseName,
          StartDate: {
            Date: startDate,
          },
          EndDate: {
            Date: endDate,
          },
        });
      } else {
        linkedinData.trainingHistory = {
          Trainings: [
            {
              Text: courseName,
              StartDate: {
                Date: startDate,
              },
              EndDate: {
                Date: endDate,
              },
            },
          ],
        };
      }
      }

      dispatch(updateLinkedinData(linkedinData, cb));
      setIsEdit(false);

      dispatch({
        type: 'COURSES_MODAL',
        payload: {
          open: false,
          mode: 'edit',
        },
      });
    } catch (error) {
    }
    finally {
      setLoading(false)
    }
  };

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };

  return (
    <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
      {Array.isArray(linkedinData?.trainingHistory?.Trainings)
        ? linkedinData?.trainingHistory?.Trainings?.map((item, index) => {
          return (
            <Row key={index} className="mt-2">
              <Col
                xs={10}
                lg={11}
                xl={11}
                md={11}
                className="border-bottom p-5"
              >
                <p style={styles.title}>{item.Text.slice(0, 70) + '...'}</p>
                {item.StartDate && (
                  <p style={styles.years}>
                    Start Date : {item?.StartDate?.Date && moment(item?.StartDate?.Date).format("MM/DD/YY")}
                  </p>
                )}
                {item.EndDate && (
                  <p style={styles.years}>End Date : {item?.EndDate?.Date && moment(item?.EndDate?.Date).format("MM/DD/YY")}</p>
                )}
              </Col>
              <Col
                xs={2}
                lg={1}
                xl={1}
                md={1}
                className="border-bottom p-2 d-flex align-items-center justify-content-center"
              >
                <span
                  style={styles.copyIcon}
                  onClick={() => {
                    setCourseName(item.Text);
                    setStartDate(item?.StartDate?.Date);
                    setEndDate(item?.EndDate?.Date);
                    setIndex(index);
                    setIsEdit(true);

                    dispatch({
                      type: 'COURSES_MODAL',
                      payload: {
                        open: true,
                        mode: 'edit',
                      },
                    });
                  }}
                >
                  {props.stepIndex === 1 ? (
                    <CreateIcon
                      style={styles.visibilityIcon}
                      className="hovdel"
                    />
                  ) : (
                    <FileCopyIcon
                      style={styles.visibilityIcon}
                      className="hovdel"
                    />
                  )}
                </span>
              </Col>
            </Row>
          );
        })
        : 'NO DATA AVAILABLE'}

      <Popup
        open={coursesModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'COURSES_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto'
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'COURSES_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5>Courses</h5>

        <Row>
          <Col>
            <Label for="exampleEmail">Course Name</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
              />{' '}
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(courseName) ? (
                    <CopyToClipboard
                      text={courseName}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(courseName);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Start Date
            </Label>
            <InputGroup>
              <Input
                type="date"
                style={{ height: '50px', color: "black" }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(startDate) ? (
                    <CopyToClipboard
                      text={startDate}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(startDate);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              End Date
            </Label>
            <InputGroup>
              <Input
                type="date"
                style={{ height: '50px', color: "black" }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(endDate) ? (
                    <CopyToClipboard
                      text={endDate}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(endDate);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={loading}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  {loading ? 'Saving' : 'Save'}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};
