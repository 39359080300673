import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ResumeHistoryTable from "../recent-history/table";
import { scanHistory } from "../../commonRedux/scan/action";
import TableLoader from "../../commonComponents/tableLoader";
import { styles } from "./style";
const mapStateToProps = ({ fileScanReducer }) => {
  return {
    historyStauts: fileScanReducer.historyStauts,
    getUniqueResumas: fileScanReducer.userResumas,
    data: fileScanReducer.historyData,
    updatortwo: fileScanReducer.updatortwo,
    getScanHistoryStatus: fileScanReducer.getScanHistoryStatus,
  };
};
const ResumeHistory = () => {
  const { data, getScanHistoryStatus } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(scanHistory());
  }, []);
  let reverseData = data || [];
  if (!reverseData.length && getScanHistoryStatus) {
    return (
      <>
        <div
          style={{
            background: "white",
            borderRadius: 5,
          }}
        >
          <TableLoader />
        </div>
      </>
    );
  } else if (!data.length) {
    return (
      <div
        className="resume_main_container"
        style={{
          ...styles.flex,
          ...styles.justifyContentCenter,
          ...styles.alignItemsCenter,
          ...styles.tableCell,
          ...styles.textAlignCenter,
        }}
      >
        No Data Found!
      </div>
    );
  }
  return (
    <div>
      <ResumeHistoryTable data={data} />
    </div>
  );
};

export default ResumeHistory;
