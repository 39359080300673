import React from 'react';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import { useDispatch } from 'react-redux';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';
import { styles } from './style';
import RichEditor from '../../formBuilder/helper/RichEditor';

function EducationHistory(props) {
  const dispatch = useDispatch();
  const path = 'education.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  const { data } = props;

  const handledData = (() => {
    if (!data?.length) {
      return [
        {
          location: '',
          institution: '',
          degree: '',
          month: '',
        },
      ];
    }

    // Check if data is Array of Strings
    if (typeof data[0] === 'string') {
      return data?.map((item) => {
        return {
          location: '',
          institution: '',
          degree: item,
          month: '',
        };
      });
    }

    return data;
  })();

  return (
    <div>
      <Dnd
        data={handledData}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <>
            <div className="manager-template-row">
              <Text
                value={item?.degree}
                placeholder="Degree"
                customclass={'manager-fName manager-template-text-bold'}
                path={`${path}.${index}.degree`}
              />

              <p style={styles.paraStyles(false)}>in</p>
              <Text
                value={item?.major}
                placeholder="Major Field"
                customclass={'manager-fName'}
                path={`${path}.${index}.major`}
              />
              <p style={styles.paraStyles(false)}>, Minor -</p>
              <Text
                value={item?.minor}
                placeholder="Minor Field"
                customclass={'manager-fName'}
                path={`${path}.${index}.minor`}
              />
            </div>
            <div className="manager-template-row">
              {/* <p style={styles.paraStyles(true)}>CGPA</p> */}
              {/* <Text
                value={item.gpa}
                placeholder="GPA/CGPA"
                customclass={"manager-fName"}
                path={`${path}.${index}.gpa`}
              /> */}
            </div>
            <div className="manager-template-row">
              {/* <Text
                value={item.minor}
                placeholder="Major/Major"
                customclass={'manager-fName'}
                path={`${path}.${index}.minor`}
              />, */}
              <Text
                value={item.institution}
                placeholder="Institution"
                customclass={'manager-fName'}
                path={`${path}.${index}.institution`}
              />
              ,
              <Text
                value={item.location}
                placeholder="Location"
                customclass={'manager-fName'}
                path={`${path}.${index}.location`}
              />
            </div>
            <div className="manager-template-row">
              {/* <Text
                value={item.minor}
                placeholder="Major/Major"
                customclass={'manager-fName'}
                path={`${path}.${index}.minor`}
              />, */}
              <Text
                value={item.month}
                placeholder="Month"
                customclass={'manager-fName'}
                path={`${path}.${index}.month`}
              />
            </div>
            {/* <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>Field of Study: </p>
              <Text
                value={item.field}
                placeholder="Field of Study"
                customclass={'manager-fName'}
                path={`${path}.${index}.field`}
              />
            </div> */}
            {/* <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>Courseworks: </p>
              <Text
                value={item.courseworks}
                placeholder="Courseworks"
                customclass={'manager-fName'}
                path={`${path}.${index}.courseworks`}
              />
            </div> */}
            {/* <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>Awards: </p>
              <Text
                value={item.awards}
                placeholder="Awards"
                customclass={'manager-fName'}
                path={`${path}.${index}.awards`}
              />
            </div> */}
            {/* <div className="manager-template-row">
              <p style={styles.paraStyles(true)}>
                Extracurricular Activities:{' '}
              </p>
              <Text
                value={item.extracurricular}
                placeholder="Extracurricular Activities"
                customclass={'manager-fName'}
                path={`${path}.${index}.extracurricular`}
              />
            </div> */}
            <div>
              <strong>Summary</strong>

              <RichEditor
                value={item.summary}
                enableInline
                placeHolder="Description (Include GPA, awards & Extracurricular activities)"
                path={`${path}.${index}.summary`}
                onBlurField={onBlurField}
                heightMax={true}
              />
            </div>
          </>
        )}
      />
    </div>
  );
}
export default EducationHistory;
