import React, { useState } from 'react';
import {
  saveToken,
  saveDropBoxToken,
  changeSyncStatus,
  userAcountActions,
  currentUser,
  dailEmailUpdateAll
} from '../auth/redux/actions';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-loader-spinner';
import { Row, Col } from 'reactstrap';
import { styles } from './style';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/styles';
import Loader from '../account/loader';

const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#4267B2',
    },
    '&$checked + $track': {
      backgroundColor: '#4267B2',
    },
  },
  checked: {},
  track: {},
})(Switch);

const Setting = (props) => {
  const [emailLoading, setEmailLoading] = useState(false);

  const dispatch = useDispatch();
  const [emailNotifications, setEmailNotifications] = React.useState(true);
  const [smsNotifications, setsmsNotifications] = React.useState(true);
  const [cronJobs, setCronJobs] = React.useState(true);
  const [dailyEmailLoading, setDailyEmailLoading] = React.useState(false);
  const [dailyEmail, setDailyEmail] = React.useState(true);


  React.useEffect(() => {
    if (props.user) {
      setEmailNotifications(props.user?.emailNotifications );
      setsmsNotifications(props.user?.smsNotifications );
      setCronJobs(props.user?.cronJobs );
      setDailyEmail(props.user?.dailyEmail );
    }
  }, [props.user]);

  const onChangeEmail = () => {
    dispatch(
      userAcountActions(
        {
          cronJobs: cronJobs,
          emailNotifications: !emailNotifications,
          smsNotifications: smsNotifications,
          tokens: props.user.token,
          events: props.user.saveEvents,
        },
        setEmailLoading,
        setEmailNotifications,
        emailNotifications,
      ),
    );
  };
  const onChangeDailyEmail =()=>{
    dispatch(
      dailEmailUpdateAll(
        {
          dailyEmail: !dailyEmail,
        },
        setDailyEmailLoading,
        setDailyEmail,
      ),
    );
  }
  return props.loading ? (
    <Loader />
  ) : (
    <div>
      <div style={styles.fontWeight_bold}>Notification</div>
      <div style={styles.bb_ligthGrey}></div>
      <Row>
        <Col lg="12" style={{ padding: 0 }}>
          <div>
            <div
              style={{
                ...styles.flex,
                ...styles.flexRow,
                // ...styles.justifyContentCenter,
                ...styles.textAlignCenter,
              }}
            >
              <p style={styles.h5}>Send email with review info &amp;	Resume copy&nbsp;</p>
              {emailLoading === true ? (
                <Spinner
                  type="TailSpin"
                  color="#4267b2"
                  height={25}
                  width={25}
                  style={{ marginLeft: 10 }}
                />
              ) : (
                <CustomSwitch
                  checked={emailNotifications}
                  onChange={onChangeEmail}
                />
              )}
            </div>
            <div
              style={{
                ...styles.flex,
                ...styles.flexRow,
                // ...styles.justifyContentCenter,
                ...styles.textAlignCenter,
              }}
            >
              <p style={styles.h5}>Job Search email daily&nbsp;</p>
              {dailyEmailLoading === true ? (
                <Spinner
                  type="TailSpin"
                  color="#4267b2"
                  height={25}
                  width={25}
                  style={{ marginLeft: 10 }}
                />
              ) : (
                <CustomSwitch
                checked={dailyEmail}
                onChange={onChangeDailyEmail}
                />
              )}
            </div>

            {/* <div
              style={{
                ...styles.flex,
                ...styles.flexRow,
                // ...styles.justifyContentCenter,
                ...styles.textAlignCenter,
              }}
            >
              <p style={styles.h5}>Send SMS after each optimization</p>
              {smsLoading ? (
                <Spinner
                  type="TailSpin"
                  color="#4267b2"
                  height={25}
                  width={25}
                  style={{ marginLeft: 10 }}
                />
              ) : (
                <CustomSwitch
                  checked={smsNotifications}
                  onChange={onChangeSms}
                />
              )}
            </div>
            <div
              style={{
                ...styles.flex,
                ...styles.flexRow,
                // ...styles.justifyContentCenter,
                ...styles.textAlignCenter,
              }}
            >
              <p style={styles.h5}>
                Enable cron job alerts &nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              {cronJobsLoading ? (
                <Spinner
                  type="TailSpin"
                  color="#4267b2"
                  height={25}
                  width={25}
                  style={{ marginLeft: 10 }}
                />
              ) : (
                <CustomSwitch checked={cronJobs} onChange={onchangeCronJobs} />
              )}
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ authUser }) => {
  return {
    isSync: authUser.isSync,
    user: authUser.user,
    currentUserStatus: authUser.currentUserStatus,
  };
};
export default connect(mapStateToProps, {
  saveToken,
  changeSyncStatus,
  saveDropBoxToken,
  userAcountActions,
  dailEmailUpdateAll,
  currentUser,
})(Setting);
