import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../../../layout/appLayout/footer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployerInvoices } from '../../redux/actions';
import { useHistory } from 'react-router-dom';

import Header from './Header';
import Loader from 'react-loader-spinner';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Tag } from 'antd';
import { colors } from '../../../../commonComponents/colors';
const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
});

export default function PastInvoices() {
  const [loading, setLoading] = useState(true);

  const pastInvoices = useSelector((state) => state.authUser.pastInvoices);
  const sortPastInvoices = pastInvoices.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  const user = useSelector((state) => state.authUser.user);
  const history = useHistory();

  const dispatch = useDispatch();
  const classes = useStyles();

  const cb = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (user) {
      dispatch(getEmployerInvoices(cb));
    }
  }, [user]);

  return (
    <>
      <Header user={user} history={history} />
      <div
        style={{
          marginTop: 20,
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <center>
          <h3 className="mt-4">Past Invoices</h3>
        </center>
        {loading ? (
          <span
            className="mt-5"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader type="TailSpin" color="#00BFFF" height={50} width={50} />
            <h5 className="mt-2">Getting Invoices</h5>
          </span>
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: '#fbfbfb' }}>
                  <TableCell align="left" style={{ paddingLeft: '40px' }}>
                    Invoice #
                  </TableCell>
                  <TableCell align="left" style={{ paddingLeft: '40px' }}>
                    Amount
                  </TableCell>
                  <TableCell align="left">Quantity</TableCell>
                  <TableCell align="left">Created</TableCell>
                  {/* <TableCell align="left">Company Name</TableCell> */}
                  <TableCell align="left">
                    Coupon Codes{' '}
                    <span style={{ textAlign: 'center', fontSize: 10 }}>
                      ({' '}
                      <FiberManualRecordIcon
                        style={{ color: 'green', fontSize: 10 }}
                      />{' '}
                      Not Used |{' '}
                      <FiberManualRecordIcon
                        style={{ color: colors.red, fontSize: 10 }}
                      />{' '}
                      Used )
                    </span>
                  </TableCell>
                  <TableCell align="left">Emails</TableCell>
                </TableRow>
              </TableHead>
              {sortPastInvoices.length ? (
                <TableBody>
                  {sortPastInvoices.map((invoice) => (
                    <TableRow key={invoice._id}>
                      <TableCell align="left">{invoice._id}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ paddingLeft: '40px' }}
                      >
                        ${invoice.total}
                      </TableCell>
                      <TableCell align="left">{invoice.qty}</TableCell>
                      <TableCell align="left">{invoice.createdAt}</TableCell>
                      <TableCell align="left">
                        {invoice?.coupons?.map((coupon) => {
                          let color = coupon.isUsed === false ? 'green' : 'red';
                          return (
                            <Tag color={color} key={coupon?.coupon}>
                              {coupon?.coupon.toUpperCase()}
                            </Tag>
                          );
                        })}
                      </TableCell>
                      <TableCell align="left">
                        {invoice?.deliveryOption === 'sendEmails'
                          ? invoice?.emails?.map((email, index) => {
                            return (
                              <Tag key={index} color={'rgb(66, 103, 178)'}>
                                {typeof email === 'string'
                                  ? email
                                  : email?.email}
                              </Tag>
                            );
                          })
                          : "Sent to employer's email"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      No Invoices Found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </div>
      <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <Footer />
      </div>
    </>
  );
}
