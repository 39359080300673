import React, { Component } from 'react';
import { connect } from 'react-redux';
import Jobmatchrate from './jobmatchrate';
import ATSfindings from './atsfindings';
import RECRUITERFINDINGS from './recruiterfindings';
import Hardskills from './hardskills';
import { Colxx } from '../../commonComponents/customBootstrap';
import { Row, CardBody, Card } from 'reactstrap';
import {
  scanResultsReload,
  saveScanResult,
  SaveActiveIndex,
} from '../../commonRedux/scan/action';
import ResumeResult from './resume-results';
import { styles } from './style';
import cleanText from '../../utils/cleanText';

class Scanresult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanResult: {},
      loader: false,
      oid: '',
      isMeaurable: 50,
    };
    this.exampleRef = React.createRef();
    if (props.location && props.location.state) {
      props.scanResultsReload('not done', props.location.state.oid);
    } else if (props.scanResult !== null && props.scanResult !== undefined) {
      this.setState({
        scanResult: props.scanResult,
        oid:
          props.scanResult.oid !== undefined && props.scanResult.oid !== null
            ? props.scanResult.oid
            : localStorage.getItem('myScanId'),
      });
    }
  }
  static getDerivedStateFromProps = (nextProps) => {
    if (nextProps.scanResult === null) {
      let get = localStorage.getItem('myScanId');
      nextProps.scanResultsReload(
        nextProps.location && nextProps.location.state ? 'done' : 'not done',
        get,
      );
    }

    if (nextProps.scanResult) {
      if (!nextProps.location) {
        localStorage.setItem(
          'myScanId',
          // eslint-disable-next-line
          nextProps.scanResult.oid
            ? nextProps.scanResult.oid
            : nextProps.location.state
              ? nextProps.location.state.oid
              : '',
        );
      }
      return {
        scanResult: nextProps.scanResult,
        oid: nextProps.scanResult.oid,
        loader: false,
      };
    }
  };
  goDown = () => {
    // if (this.exampleRef.current) {
    //   this.scrollToBottom({
    //     behavior: 'smooth',
    //     block: 'center',
    //   });
    // }
    // scroll to bottom of the page
    if (window) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  openResumeBuilder = () => {
    const oid =
      this.state !== undefined
        ? this.state.oid
          ? this.state.oid
          : this.props.location.state
            ? this.props.location.state.oid
            : ''
        : '';

    this.props.history.push('/builder', {
      isScan: true,
      data: {},
      oid: oid,
    });
  };

  goBuilder = () => {
    if (this.props.setActiveStep) {
      this.props.setActiveStep(2);
    }
  };

  checkIfDateErrors = (resume_quality) => {
    var isError = true;
    resume_quality.forEach((n) => {
      if (n['Level'] === 'Data Missing') {
        isError = false;
      }
    });
    return isError;
  };

  render() {
    const is_parsed = this.props.location
      ? this.props.location?.state?.is_parsed
      : this.state.scanResult?.is_parsed;

    const dateError = this.checkIfDateErrors(
      this.props.resume?.other_info?.resume_quality ?? [],
    );

    if (!this.props.scanResult) {
      return <div className="loading"></div>;
    } else
      return (
        <div className="scan_result_main_container">
          {this.props.scanResult === undefined ? (
            <Card style={styles.scanResultCard}>
              <CardBody>You did not scan any resume yet!</CardBody>
            </Card>
          ) : (
            <Row>
              <Colxx lg="12" sm="12" xs="12" xxs="12">
                <div className="mb-2">
                  <Jobmatchrate
                    goBuilder={this.goBuilder}
                    is_parsed={is_parsed}
                    goDown={this.goDown}
                    getSuggestions={this.props.getSuggestions}
                    suggestions={this.props.suggestions}
                    scanReloadStatus={this.props.scanReloadStatus}
                    resumeData={this.props.resume}
                    resume={
                      this.props.location
                        ? this.props.location.state.resumeText
                        : this.state.scanResult.resumeText
                    }
                    job={
                      this.props.location
                        ? this.props.location.state.jobDescription
                        : this.state.scanResult.jobDescription
                    }
                    company={
                      this.props.location
                        ? this.props.location.state.data.company
                        : this.state.scanResult.company
                    }
                    jobTitle={
                      this.props.location
                        ? this.props.location.state.data.jobTitle
                        : this.state.scanResult.jobTitle
                    }
                    skills={this.state.scanResult.skillsData}
                    fromState={
                      this.props.location && this.props.location.state
                        ? 'yes'
                        : 'no'
                    }
                    ats={this.state.scanResult.ats}
                    scanResultsReload={this.props.scanResultsReload}
                    resumeId={
                      this.props.resume && this.props.resume.oid
                        ? this.props.resume.oid
                        : this.props.resume && this.props.resume.resume_id
                          ? this.props.resume.resume_id
                          : ''
                    }
                    data={
                      this.props.resume
                        ? this.props.resume.data
                        : this.props.location &&
                        this.props.location.state.resume.data
                    }
                    oid={
                      this.state !== undefined
                        ? this.state.oid
                          ? this.state.oid
                          : this.props.location.state
                            ? this.props.location.state.oid
                            : ''
                        : ''
                    }
                    matchRate={{
                      totalScore: this.state.scanResult
                        ? this.state.scanResult.total_score
                        : {},
                      scores: this.state.scanResult
                        ? this.state.scanResult.scores
                        : {},
                      resumeScore: this.state.scanResult
                        ? this.state.scanResult.readableScore
                        : {},
                    }}
                    styleCloud={this.props.styleCloud}
                  />
                </div>

                <Hardskills
                  showInfoCard={false}
                  goBuilder={this.props.setActiveStep && this.goBuilder}
                  totalScore={
                    this.state?.scanResult
                      ? this.state?.scanResult?.total_score
                      : {}
                  }
                  description={(() => {
                    const resumeText = cleanText(this.state.scanResult?.resumeText) ?? ''
                    const jobDescription = cleanText(this.state.scanResult?.jobDescription) ?? ''
                    return {
                      resume: resumeText,
                      job: jobDescription,
                      is_parsed: is_parsed,
                    }
                  })()}
                  resumeId={
                    this.props.resume && this.props.resume?.oid
                      ? this.props.resume?.oid
                      : this.props.resume && this.props.resume?.resume_id
                        ? this.props.resume?.resume_id
                        : ''
                  }
                  skills={this.state.scanResult?.skillsData}
                  company={
                    this.props.location
                      ? this.props.location?.state?.data?.company
                      : this.state.scanResult?.company
                  }
                  jobTitle={
                    this.props.location
                      ? this.props.location?.state?.data?.jobTitle
                      : this.state.scanResult?.jobTitle
                  }
                  openResumeBuilder={this.openResumeBuilder}
                  handleMoveToCustomize={this.props.handleMoveToCustomize && this.props.handleMoveToCustomize}
                  oid={
                    this.state.oid
                      ? this.state.oid
                      : this.props.location?.state
                        ? this.props.location?.state?.oid
                        : ''
                  }
                  ats={this.state.scanResult?.ats}
                />

                <ATSfindings
                  comingFrom={'review'}
                  company={
                    this.props.location
                      ? this.props.location.state.data.company
                      : this.state.scanResult.company
                  }
                  goBuilder={this.goBuilder}
                  jobTitle={
                    this.props.location
                      ? this.props.location.state.data.jobTitle
                      : this.state.scanResult.jobTitle
                  }
                  resumeId={
                    this.props.resume && this.props.resume.oid
                      ? this.props.resume.oid
                      : this.props.resume && this.props.resume.resume_id
                        ? this.props.resume.resume_id
                        : ''
                  }
                  totalScore={
                    this.state.scanResult
                      ? this.state.scanResult.total_score
                      : {}
                  }
                  is_parsed={is_parsed}
                  resume={
                    this.props.location
                      ? this.props.location.state.resumeText
                      : this.state.scanResult.resumeText
                  }
                  job={
                    this.props.location
                      ? this.props.location.state.jobDescription
                      : this.state.scanResult.jobDescription
                  }
                  skills={this.state.scanResult.skillsData}
                  title={this.state.scanResult.jobTitle}
                  ats={this.state.scanResult.ats}
                  other_info={this.props.resume?.other_info}
                  data={
                    this.props.resume
                      ? this.props.resume.data
                      : this.props.location &&
                      this.props.location.state.resume.data
                  }
                  oid={
                    this.state.oid
                      ? this.state.oid
                      : this.props.location.state
                        ? this.props.location.state.oid
                        : ''
                  }
                  fromState={
                    this.props.location && this.props.location.state
                      ? 'yes'
                      : 'no'
                  }
                />

                <RECRUITERFINDINGS
                  company={
                    this.props.location
                      ? this.props.location.state.data.company
                      : this.state.scanResult.company
                  }
                  goBuilder={this.goBuilder}
                  scores={
                    this.state.scanResult
                      ? this.state.scanResult.readableScore
                      : {}
                  }
                  jobTitle={
                    this.props.location
                      ? this.props.location.state.data.jobTitle
                      : this.state.scanResult.jobTitle
                  }
                  resumeId={
                    this.props.resume && this.props.resume.oid
                      ? this.props.resume.oid
                      : this.props.resume && this.props.resume.resume_id
                        ? this.props.resume.resume_id
                        : ''
                  }
                  totalScore={
                    this.state.scanResult
                      ? this.state.scanResult.total_score
                      : {}
                  }
                  is_parsed={is_parsed}
                  resume={
                    this.props.location
                      ? this.props.location.state.resumeText
                      : this.state.scanResult.resumeText
                  }
                  job={
                    this.props.location
                      ? this.props.location.state.jobDescription
                      : this.state.scanResult.jobDescription
                  }
                  skills={this.state.scanResult.skillsData}
                  title={this.state.scanResult.jobTitle}
                  recruiterFindings={this.state.scanResult.recruiterFindings}
                  ats={this.state.scanResult.ats}
                  data={
                    this.props.resume
                      ? this.props.resume.data
                      : this.props.location &&
                      this.props.location.state.resume.data
                  }
                  oid={
                    this.state.oid
                      ? this.state.oid
                      : this.props.location.state
                        ? this.props.location.state.oid
                        : ''
                  }
                  fromState={
                    this.props.location && this.props.location.state
                      ? 'yes'
                      : 'no'
                  }
                />

                <ResumeResult
                  ref_fun={this.exampleRef}
                  scores={
                    this.state.scanResult
                      ? this.state.scanResult.readableScore
                      : {}
                  }
                  goBuilder={this.goBuilder}
                  email={
                    this.state.scanResult
                      ? this.state.scanResult.ats.email
                      : this.props.location
                        ? this.props.location.state.data.ats.email
                        : ''
                  }
                  is_parsed={is_parsed}
                  phone={
                    this.state.scanResult
                      ? this.state.scanResult.ats.mobile_number
                      : this.props.location
                        ? this.props.location.state.data.ats.mobile_number
                        : ''
                  }
                  company={
                    this.props.location
                      ? this.props.location.state.data.company
                      : this.state.scanResult.company
                  }
                  jobTitle={
                    this.props.location
                      ? this.props.location.state.data.jobTitle
                      : this.state.scanResult.jobTitle
                  }
                  resumeId={
                    this.props.resume && this.props.resume.oid
                      ? this.props.resume.oid
                      : this.props.resume && this.props.resume.resume_id
                        ? this.props.resume.resume_id
                        : ''
                  }
                  other_info={this.props.resume?.other_info}
                  totalScore={
                    this.state.scanResult
                      ? this.state.scanResult.total_score
                      : {}
                  }
                  resume={
                    this.props.location
                      ? this.props.location.state.resumeText
                      : this.state.scanResult.resumeText
                  }
                  job={
                    this.props.location
                      ? this.props.location.state.jobDescription
                      : this.state.scanResult.jobDescription
                  }
                  skills={this.state.scanResult.skillsData}
                  title={this.state.scanResult.jobTitle}
                  recruiterFindings={this.state.scanResult.recruiterFindings}
                  ats={this.state.scanResult.ats}
                  data={
                    this.props.resume
                      ? this.props.resume.data
                      : this.props.location &&
                      this.props.location.state.resume.data
                  }
                  oid={
                    this.state.oid
                      ? this.state.oid
                      : this.props.location.state
                        ? this.props.location.state.oid
                        : ''
                  }
                  fromState={
                    this.props.location && this.props.location.state
                      ? 'yes'
                      : 'no'
                  }
                  isMeaurable={this.state.isMeaurable}
                  dateError={dateError}
                />
              </Colxx>
            </Row>
          )}
        </div>
      );
  }
}

const mapStateToProps = ({ fileScanReducer }) => {
  return {
    scanResult: fileScanReducer.scanResult,
    scanResultStatus: fileScanReducer.scanResultStatus,
    updator: fileScanReducer.updator,
    content_loaders: fileScanReducer.content_loaders,
    resume: fileScanReducer.resume,
    styleCloud: fileScanReducer.styleCloud,
    scanReloadStatus: fileScanReducer.scanReloadStatus,
  };
};

export default connect(mapStateToProps, {
  scanResultsReload,
  saveScanResult,
  SaveActiveIndex,
})(Scanresult);
