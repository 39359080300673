import React from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useAbout } from './useAbout';
import { useWindowSize } from '@react-hook/window-size';
import TextIcon from '../../../assets/text.webp';
import RightIconButton from '../../../commonComponents/RightIconButton';
import { colors } from '../../../commonComponents/colors';

export default function About(props) {
  const [width] = useWindowSize();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    headline,
    setHeadline,
    country,
    setCountry,
    city,
    setCity,
    copied,
    loading,
    handleCopied,
    handleUpdate,
    clearCopied,
    phone,
    setPhone,
    setValid,
    item,
    setItem,
    additionalName,
    setAdditionalName,
    industry,
    setIndustry,
    dispatch,
    aboutModal,
  } = useAbout(props);

  const RecruiterLabel = () => {
    return (
      <RightIconButton
        text="RECRUITER FOCUS"
        color={colors.darkYellow}
        imgsrc={TextIcon}
        style={{
          alignSelf: 'center',
          marginLeft: '40px',
          cursor: 'default',
        }}
      />
    );
  };

  return (
    <Card body className="text-left mb-4" style={styles.border_radius_5}>
      <Row className="mt-2">
        <Col xs={10} lg={11} xl={11} md={11} className={width< 500? 'p-2':"p-5"}>
          <p style={styles.title}>{firstName + ' ' + lastName}</p>
          <p style={styles.sub}>{email}</p>
        </Col>
        <Col
          xs={2}
          lg={1}
          xl={1}
          md={1}
          className="p-2 d-flex align-items-center justify-content-center"
        >
          <span
            style={styles.copyIcon}
            onClick={() => {
              setItem(item);
              dispatch({
                type: 'ABOUT_MODAL',
                payload: true,
              });
            }}
          >
            {props.stepIndex === 2 && (
              <FileCopyIcon style={styles.visibilityIcon} className="hovdel" />
            )}
          </span>
        </Col>
      </Row>

      <Popup
        open={aboutModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'ABOUT_MODAL',
            payload: false,
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'scroll',
        }}
        modal
        position="center"
        // closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'ABOUT_MODAL',
              payload: false,
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5 style={{ fontSize: 23 }}>Intro</h5>

        <Row>
          <Col>
            <Label>First Name</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(firstName) ? (
                    <CopyToClipboard
                      text={firstName}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(firstName);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }}>Last Name</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(lastName) ? (
                    <CopyToClipboard
                      text={lastName}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        setValid(true);
                        handleCopied(lastName);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }}>Additional Name</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={additionalName}
                onChange={(e) => setAdditionalName(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(additionalName) ? (
                    <CopyToClipboard
                      text={additionalName}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        setValid(true);
                        handleCopied(additionalName);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>

            <h5 className="mt-4" style={{ fontSize: 23 }}>
              Current Position
            </h5>
            <span style={{ display: 'flex' }}>
              <Label style={{ marginTop: 10 }}>Headline</Label>
              <RecruiterLabel />
            </span>

            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={headline || ''}
                onChange={(e) => setHeadline(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(headline) ? (
                    <CopyToClipboard
                      text={headline}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        setValid(true);
                        handleCopied(headline);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }}>Industry</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={industry || ''}
                onChange={(e) => setIndustry(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(industry) ? (
                    <CopyToClipboard
                      text={industry}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        setValid(true);
                        handleCopied(industry);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }}>Country / Region</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(country) ? (
                    <CopyToClipboard
                      text={country}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        setValid(true);
                        handleCopied(country);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <span style={{ display: 'flex' }}>
              <Label style={{ marginTop: 10 }}>City/District</Label>
              <RecruiterLabel />
            </span>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(city) ? (
                    <CopyToClipboard
                      text={city}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        setValid(true);
                        handleCopied(city);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <h5 className="mt-4" style={{ fontSize: 23 }}>
              Contact Info
            </h5>
            <Label style={{ marginTop: 15 }}>Email</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(email) ? (
                    <CopyToClipboard
                      text={email}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        setValid(true);
                        handleCopied(email);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }}>Phone</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(phone) ? (
                    <CopyToClipboard
                      text={phone}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        setValid(true);
                        handleCopied(phone);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
          </Col>
          <Col
            xs={12}
            xl={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {props.stepIndex === 1 && (
              <Button
                onClick={handleUpdate}
                className="mt-4"
                loading={loading}
                style={{
                  backgroundColor: colors.blue,
                  color: colors.white,
                }}
                shape="round"
                size={'large'}
              >
                {loading ? 'Saving' : 'Save'}
              </Button>
            )}
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '20px',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};
