import { Menu, MenuItem } from '@material-ui/core';
import React, { forwardRef, useEffect } from 'react';
import { colors } from './colors';
import moment from 'moment';
import { MoreHoriz } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const NotificationOptions = forwardRef(
  ({ id, onMarkAsRead, isSeened }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <div
          ref={ref}
          style={{
            paddingRight: 10,
          }}
          onClick={handleClick}
        >
          <MoreHoriz
            style={{
              color: colors.fontGray,
              cursor: 'pointer',
            }}
          />
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              if (!isSeened) {
                onMarkAsRead(id);
              }
              handleClose();
            }}
            style={{
              color: colors.fontGray,
              fontSize: 14,
              fontWeight: 500,
              padding: 10,
            }}
          >
            Mark as read
          </MenuItem>
        </Menu>
      </>
    );
  },
);

export default function NotificationManu(props) {
  const onboardingToggle = useSelector(
    (state) => state?.fileScanReducer?.showOnboardingToggle,
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    props.getNotification(props.email._id);
  }, []);

  const updateNotification = (notificationId) => {
    const data = {
      oid: props.email._id,
      notificationId: notificationId,
    };

    props.updateUserNotification(data);
    setTimeout(() => {
      props.getNotification(props.email._id);
    }, 2000);
  };

  const notificationList = props.notification?.sort((a, b) => {
    return moment(b?.createdAt).diff(moment(a?.createdAt));
  });

  const nonSeenNotification = notificationList?.filter(
    (obj) => obj?.seen === false,
  );

  const markAllAsRead = () => {
    if (!nonSeenNotification?.length) {
      return;
    }
    const data = {
      oid: props.email._id,
      multiple: true,
      notificationId: notificationList?.map((obj) => obj?.notificationId),
    };

    props.updateUserNotification(data);
    setTimeout(() => {
      props.getNotification(props.email._id);
    }, 2000);
  };
  useEffect(() => {
    if (onboardingToggle) {
      setAnchorEl(null);
    }
  }, [onboardingToggle]);
  
  if (!props.email) {
    return null;
  }
  return (
    <div>
      <style jsx>
        {`
          .css-11l1l6j-Header {
            display: none;
          }
          .css-1g4r00t-StyledFooter {
            display: none;
          }
        `}
      </style>
      <div>
        <div
          style={{ position: 'relative', cursor: 'pointer' }}
          onClick={handleClick}
        >
          <img
            src={props.NotificationIcon}
            title={'Notifications'}
            style={{
              width: 20,
              height: 25,
              objectFit: 'center',
              marginLeft: 7,
              marginRight: 3,
            }}
          />
          <span
            style={{
              color: colors.blue,
              fontSize: 10,
              fontWeight: 700,
              borderRadius: '50%',
              backgroundColor: colors.yellow,
              height: 14,
              width: 14,
              position: 'absolute',
              border: '1px solid white',
              top: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              right: 0,
              zIndex: 1,
            }}
          >
            {nonSeenNotification?.length}
          </span>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          PaperProps={{
            style: {
              position: 'relative',
              width: '400px',
              height: '400px',
              overflowY: 'scroll',
              marginTop: 80,
              marginLeft: -40,
              padding: 7,
              borderRadius: 10,
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 10,
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 10,
                  marginTop: -6,
                }}
              >
                <div
                  style={{
                    textAlign: 'left',
                    fontWeight: 600,
                    padding: '0px 7px',
                    fontSize: 20,
                  }}
                >
                  Notifications
                </div>
                <span
                  style={{
                    width: 25,
                    height: 25,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // marginTop: -2,
                    borderRadius: '50%',
                    backgroundColor: colors.blue,
                    color: '#fff',
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  {nonSeenNotification?.length}
                </span>
              </div>
              <div
                style={{
                  marginLeft: 'auto',
                }}
              >
                <span
                  onClick={markAllAsRead}
                  style={{
                    color: nonSeenNotification?.length
                      ? colors.blue
                      : colors.fontGray,
                    fontSize: 14,
                    fontWeight: 700,
                    cursor: nonSeenNotification?.length ? 'pointer' : 'default',
                  }}
                >
                  Mark all as read
                </span>
              </div>
            </div>
            {notificationList?.length > 0 ? (
              notificationList?.map((item, index) => {
                return (
                  <>
                    <div
                      key={index}
                      style={{
                        borderLeft: `5px solid ${colors.blue}`,
                        borderTopLeftRadius: 7,
                        borderBottomLeftRadius: 7,
                        width: '100%',
                        padding: '0px 0px 10px 0px',
                        backgroundColor: item?.seen ? '#fff' : '#f2f9f4',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                        marginBottom: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (!item?.seen) {
                          updateNotification(item?.notificationId);
                        }
                        props.selectNotification(item);
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 5,
                            alignItems: 'flex-end',
                            width: 'fit-content',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              color: '#000000',
                              fontSize: 14,
                              padding: '10px 10px 0px 10px',
                              fontWeight: 700,
                              fontFamily: "'Montserrat' sans-serif",
                            }}
                          >
                            {item?.title}
                          </div>
                          <div
                            style={{
                              color: colors.grey,
                              fontSize: 11,
                              lineHeight: '17px',
                              fontWeigth: 700,
                              paddingBottom: 1,
                              fontFamily: "'Montserrat' sans-serif",
                            }}
                          >
                            {moment(item?.createdAt)?.fromNow() ?? ''}
                          </div>
                        </div>
                        <div
                          style={{
                            color: colors.grey,
                            fontSize: 12,
                            lineHeight: '17px',
                            width: '100%',
                            textAlign: 'left',
                            fontWeight: 500,
                            padding: '5px 10px 0px 10px',
                            fontFamily: "'Montserrat' sans-serif",
                            wordBreak: 'break-word',
                          }}
                        >
                          {item.description.length > 100
                            ? item.description.slice(0, 100) + '...'
                            : item.description}
                        </div>
                      </div>
                      <NotificationOptions
                        id={item?.notificationId}
                        isSeened={item?.seen}
                        onMarkAsRead={(id) => {
                          updateNotification(id);
                        }}
                      />
                    </div>
                  </>
                );
              })
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginTop: '150px',
                  color: colors.grey,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                <div>No Notification Found.</div>
              </div>
            )}
          </div>
        </Menu>
      </div>
    </div>
  );
}
