import React, { useState, useEffect } from 'react';
import Card from 'react-credit-cards';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseRounded from '@material-ui/icons/CloseRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { confirmAlert } from 'react-confirm-alert';
import Switch from 'react-ios-switch';
import 'react-credit-cards/es/styles-compiled.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Dialog, DialogContent, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { colors } from '../../commonComponents/colors';

const StyledDialog = withStyles({
  root: {
    position: 'fixed',
    zIndex: '7 !important',
    right: '0px',
    bottom: '0px',
    top: '0px',
    left: '0px',
  },
})(Dialog);
export default ({
  dashboard,
  deleteUserCardAction,
  changeUserCardAction,
  name,
  default_source,
  // usersCards = [],
}) => {
  const [deleteLoading, setDeletingLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const usersCards = useSelector((state) => state.profileReducer.usersCards);
  const [defaultCard, setDefaultCard] = useState('');

  useEffect(() => {
    if (usersCards.length > 0) {
      setDefaultCard(usersCards[0].id);
    }
  }, [usersCards]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callback = () => {
    setDeletingLoading(false);
  };

  const onDelete = (card) => {
    confirmAlert({
      title: 'Delete Card',
      message: 'Are you sure to delete this card?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setDeletingLoading(card.id);
            deleteUserCardAction(
              {
                card_id: card.id,
              },
              callback,
            );
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };


  function onDefaultCard(card_id) {
    if (default_source == card_id) {
      alert("It can't be unchecked");
    } else {
      changeUserCardAction({
        card_id,
      });
    }
  }

  // check data is object or array
  const isObject = (obj) => {
    return obj && typeof obj === 'object' && !Array.isArray(obj);
  };
  let filteredObj = [];

  if (!isObject(usersCards)) {
    filteredObj = usersCards?.find((item) => item.id === default_source);
  }

  return (
    <table style={{ width: '100%', margin: '20px 0 10px' }}>
      <tbody>
        {usersCards?.length ? (
          <>
            <tr
              style={{
                borderBottom: '1px solid #e0e0e0',
                display: dashboard ? 'flex' : '',
                alignItems: dashboard ? 'center' : '',
                justifyContent: dashboard ? 'space-between' : '',
              }}
            >
              <td
                style={{
                  padding: '10px 2px',
                  display: dashboard ? 'none' : 'block',
                }}
              >
                {'Payment Method.'}
              </td>
              <td
                style={{
                  textAlign: 'right',
                }}
              >
                {`
                ${usersCards?.length ? usersCards[0].brand : ''
                  } card ending in ${usersCards[0] ? usersCards[0].last4 : ''}
                , exp: ${usersCards[0] ? usersCards[0].exp_month : ''}/${usersCards[0] ? usersCards[0].exp_year : ''
                  }
                `}
              </td>
              <td>
                <>
                  <span
                    style={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      color: '#4267b2',
                      marginLeft: '5px',
                    }}
                    onClick={handleClickOpen}
                  >
                    Update
                  </span>
                  <StyledDialog
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                  >
                    <div className="d-flex justify-content-between">
                      <h5 className="mt-3 ml-3" style={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 20,
                        fontFamily: 'Nunito'
                      }}>
                        <strong>All Cards</strong>
                      </h5>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{ color: 'white' }}
                      >
                        <CloseRounded
                          style={{
                            backgroundColor: colors.red, borderRadius: 50,
                            fontSize: 24,
                          }}
                        />
                      </IconButton>
                    </div>
                    <DialogContent
                      style={{
                        padding: '20px 20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {usersCards.map((card) => {
                        return (
                          <div className="credit-cards-div" key={card['id']}>
                            <div style={{ position: 'relative' }}>
                              <Card
                                type={card?.brand}
                                number={`0000 0000 0000 ${card.last4}`}
                                name={name}
                                expiry={`0${card?.exp_month}/${card?.exp_year}`}
                                cvc={'000'}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  position: 'absolute',
                                  top: 10,
                                  right: 15,
                                }}
                              >
                                <Switch
                                  checked={default_source === card['id']}
                                  className="switch"
                                  disabled={usersCards.length === 1}
                                  // onColor="rgb(43,0125,227)"
                                  offColor="lightgray"
                                  size="small"
                                  onChange={() => onDefaultCard(card['id'])}
                                />
                                <IconButton
                                  color="secondary"
                                  component="span"
                                  onClick={() => onDelete(card)}
                                  disabled={deleteLoading === card['id']}
                                  className="credit-cards-div-button"
                                >
                                  {deleteLoading === card['id'] ? (
                                    <CircularProgress color="secondary" />
                                  ) : (
                                    <DeleteIcon />
                                  )}
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </DialogContent>
                  </StyledDialog>
                </>
              </td>
            </tr>
          </>
        ) : (
          <center>
            <h5>You don't have any card</h5>
          </center>
        )}
      </tbody>
    </table>
  );
};
