import { sampleData } from '../constants/sampleData';

export function stripHtml(html) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

export const getFormatedTextFromBuilder = (data = sampleData.resume.data) => {
  let text = '';
  if (data) {
    // all sections variables
    // 1.
    let education = '';
    // 2.
    let achievements = '';
    // 3.
    let associations = '';
    // 4.
    let certifications = '';
    // 5.
    let courses = '';
    let military = '';
    // 6.
    let patents = '';
    // 7.
    let projects = '';
    // 8.
    let publications = '';
    // 9.
    let additional_skills = '';
    // 10.
    let security_creds = '';
    // 11.
    let skills = '';
    // 12.
    let speaking_engagements = '';
    // 13.
    let training = '';
    // 14.
    let work = '';
    // 15.
    let custom = '';
    // 17.

    const profile = `
          ${data.profile?.heading}
            ${data.profile?.firstName} ${data.profile?.lastName}
            ${data.profile?.phone} ${data.profile?.email}
            ${data.profile?.address.city}, ${data.profile?.address.state} ${data.profile?.address.zipcode}
            ${data.profile?.linkedinURL} ${data.profile?.website} ${data.profile?.subtitle}

      `;

    const objective = data?.objective?.body
      ? `
    ${data.objective?.heading}
    ${data.objective?.body}
    
    `
      : '';

    // 1.Education
    if (data.education) {
      education = `  Education`;
      data.education.items.forEach((n) => {
        education += `
            ${n?.degree || ''}, CGPA ${n?.gpa || ''}, ${n?.month || ''}, ${
          n?.endDate || ''
        } ${n?.institution || ''}, ${n?.location || ''}
            ${n?.field || ''}
            ${n?.courseworks || ''}
            ${n?.awards || ''}
            ${n?.extracurricular || ''}
            ${n?.summary || ''}

        `;
      });
    }

    // 2.achievements
    if (data.achievements) {
      achievements = `  Achievements`;
      data.achievements.items.forEach((n) => {
        achievements += `
            ${n?.Description || n || ''}

        `;
      });
    }
    // 3.associations
    if (data.associations) {
      associations = `  Associations`;
      data.associations.items.forEach((n) => {
        associations += `
            ${n?.Name || n?.Organization || ''}

        `;
      });
    }
    // 4.certifications
    if (data.certifications) {
      certifications = `  Certifications`;
      data.certifications.items.forEach((n) => {
        certifications += `
            ${n?.title || ''}, ${n?.date || ''}
            ${n?.summary || ''}

        `;
      });
    }

    if (data.courses) {
      courses = `  Courses`;
      data.courses.items.forEach((n) => {
        courses += `
            ${n?.title || ''}, ${n?.date || ''}
            ${n?.summary || ''}

        `;
      });
    }
    // 5.certifications
    if (data.military) {
      military = `  Military`;
      data.military.items.forEach((n) => {
        const branch = n['branch'] || n['Service']?.Branch || '';
        const RankAchieved = n?.RankAchieved?.CurrentOrEndRank || '';
        const StartDate =
          n?.DatesOfService?.StartDate?.YearMonth || n?.StartDate?.Date || '';
        const EndDate =
          n?.DatesOfService?.EndDate?.YearMonth || n?.EndDate?.Date || '';
        military += `
            ${branch}, ${RankAchieved}
            From ${StartDate} to ${EndDate}

        `;
      });
    }
    // 6. patents
    if (data?.patents) {
      patents = `Patents`;
      const patentsData = data?.patents;
      if (Array.isArray(patentsData?.items)) {
        data.patents.items.forEach((n) => {
          patents += `
              ${n?.PatentTitle || ''}
              ${n?.Inventors?.InventorName?.[0] || ''}
              ${n?.Description || ''}
  
          `;
        });
      } else {
        patents += `
              ${patentsData?.heading || ''}
              ${patentsData?.items || ''}
  
          `;
      }
    }

    // 7. projects
    if (data.projects) {
      projects = `Projects`;
      data.projects.items.forEach((n) => {
        projects += `
            ${n?.Title || ''}
            ${n?.TechnologiesUsed || ''}
            ${n?.Company || ''}, ${n?.Duration || ''}
            ${n?.Summary || ''}

        `;
      });
    }
    // 8. publications
    if (data.publications) {
      publications = `Publications`;
      const publicationsData = data?.publications;
      if (Array.isArray(publicationsData?.items)) {
        data.publications.items.forEach((n) => {
          publications += `
              ${n?.Title || ''}
              ${n?.JournalOrSerialName || ''}
              ${n?.Issue || ''}
  
            `;
        });
      } else {
        publications += `
              ${publicationsData?.heading || ''}
              ${publicationsData?.items || ''}
  
            `;
      }
    }

    // 9. additional_skills
    if (data.additional_skills) {
      additional_skills = `Additonal Skills`;
      data.additional_skills.items.forEach((n) => {
        additional_skills += `
          ${n?.Name || ''}

          `;
      });
    }
    // 10. security_creds
    if (data.security_creds) {
      security_creds = `Security Credentials`;
      data.security_creds.items.forEach((n) => {
        security_creds += `
            ${n?.Name || ''}
            Issued Date: ${n?.EffectiveDate?.FirstIssuedDate?.Year || ''}

          `;
      });
    }
    // 11. skills
    if (data.skills) {
      skills = `Skills`;
      data.skills.items.forEach((n) => {
        skills += `${n?.name || n} | `;
      });
      skills += `

      `;
    }
    // 12. speaking_engagements
    if (data.speaking_engagements) {
      speaking_engagements = `Speaking Engagements`;
      data.speaking_engagements.items.forEach((n) => {
        speaking_engagements += `
            ${n?.EventName || ''},${n?.EventType || ''}
            ${n?.Description || n?.SpeakingEngagements || ''}

          `;
      });
    }
    // 13. training
    if (data.training) {
      training = `Training`;
      const trainingData =
        data?.training?.items?.Trainings ?? data?.training?.items;
      trainingData.forEach((n) => {
        const Description = n?.['Description'] || n?.['Text'] || '';
        const EndDate = n?.['EndDate']?.['YearMonth'] || '';
        const Type = n?.['Type'] || '';
        const StartDate = n?.['StartDate']?.['YearMonth'] || '';
        const Entity = n?.['Entity'] || '';
        const TrainingName = n?.['TrainingName'] || '';
        training += `
            ${TrainingName || ''}
            ${Type || ''}
            ${Entity || ''}
            From ${StartDate || ''} to ${EndDate || ''}
            ${Description || ''}
        `;
      });
    }
    // 14. work
    if (data.work) {
      work = `    Work Experience`;
      const workData = data?.work?.items;
      const uniqueWorkData = workData?.filter((obj, index, self) => {
        return index === self.findIndex((t) => t.company === obj.company);
      });
      uniqueWorkData.forEach((n) => {
        work += `
            ${n?.company || ''}, ${n?.location || ''}
            ${n?.position || ''}
            From ${n?.startDate || ''} to ${n?.endDate || ''}
            ${n?.summary || ''}

        `;
      });
    }
    // 15. custom
    if (data.custom?.body) {
      custom = `
          ${data?.custom?.heading || ''}
            ${data?.custom?.body || ''}

        `;
    }

    text += profile;
    text += objective && objective;

    // other all
    text += skills;
    text += work;
    text += education;
    text += projects;
    text += certifications;
    text += achievements;
    text += associations;
    text += military;
    text += patents;
    text += publications;
    text += security_creds;
    text += speaking_engagements;
    text += training;
    text += additional_skills;
    text += custom;
  }
  return stripHtml(text);
};
