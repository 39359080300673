export const jsStyles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  stripeDiv: (width) => {
    return {
      position: 'fixed',
      left: 0,
      right: 0,
      backgroundColor: 'rgb(247, 247, 247)',
      padding: '15px 15px 0',
      zIndex: '500',
      top: width > 1130 ? 190 : 260,
      alignItems: 'center',
      width: '100%',
    };
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100vw',
    width: '88vw',
    backgroundColor: '#4267B2',
    padding: '5px 0',
    margin: 'auto',
    overflow: 'auto',
  },
  nameDiv: {
    color: 'white',
    marginLeft: '10px',
    fontWeight: 'bold',
    width: '13%',
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  toolsMain: {
    color: 'white',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  width_100px: { width: '13%' },
};
