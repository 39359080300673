import React from 'react';
import { Text, Dnd } from '../../component';
import { Grid } from '@material-ui/core';
import { useStyles } from '../style';
import { useDispatch } from 'react-redux';

import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
} from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import InputText from '../../component/inputText';
import RichEditor from '../helper/RichEditor';
import { colors } from '../../../../commonComponents/colors';

export default function Patents(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'patents.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props?.data?.items || [], data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props?.data?.items[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;
  function handleStringItems() {
    if (!data?.items?.length) {
      return [
        {
          Description: '',
          Inventors: {
            InventorName: '',
          },
          PatentTitle: '',
        },
      ];
    }

    return data?.items;
  }
  const getData = handleStringItems();

  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Patents
          </label>
        </div>
      </Grid>
      <Dnd
        data={getData}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <div>
            <div>
              <label className={classes.label}>Title</label>
              <Text
                value={item?.PatentTitle}
                placeholder="Method and Apparatus for Removing Corn Kernels From Dentures"
                customclass={classes.input}
                path={`${path}.${index}.PatentTitle`}
              />
            </div>
            <div>
              <label className={classes.label}>Inventor Name</label>
              <InputText
                value={item.Inventors?.InventorName}
                maxLength={100}
                placeholder="Inventor Name"
                customclass={classes.input}
                path={`${path}.${index}.Inventors.InventorName`}
              />
            </div>
            <div>
              <label className={classes.label} style={{ marginTop: 14 }}>
                Description
              </label>
              {/* <Text
              value={item?.Description}
              placeholder="Description"
              customclass={`${classes.input} ${classes.summaryInput}`}
              path={`${path}.${index}.Description`}
            /> */}

              <RichEditor
                value={item?.Description}
                placeHolder="Description"
                customclass={`${classes.input} ${classes.summaryInput}`}
                path={`${path}.${index}.Description`}
                onBlurField={onBlurField}
              />
            </div>
            {data?.items?.length > 1 && (
              <hr style={{ borderTop: 'dashed 1px #9E9E9E', marginTop: 20 }} />
            )}
          </div>
        )}
      />
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more patent
      </div>
    </>
  );
}
