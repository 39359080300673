import { debounceAsync } from './debounce';
const getJobTitleSuggestions = debounceAsync(async (title) => {
    const response = await fetch(
        `https://solrpoint.com:8983/solr/core1/suggest?q=${title}`,
        { method: 'GET' }
    );
    const { similar_jobs_comp } = (await response.json()).suggest;
    const [firstKey] = Object.keys(similar_jobs_comp);
    const suggestionList = similar_jobs_comp[firstKey].suggestions.map(({ term }) => ({
        label: term,
        value: term,
    }));
    return suggestionList.sort((a, b) => a.label.length - b.label.length);
}, 300);

export default getJobTitleSuggestions;