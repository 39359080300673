import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Connect from './Connect';
import OptimizedProfile from './OptimizedProfile';
import FinalOptions from './FinalOptions';
import { ColorlibConnector, ColorlibStepIcon } from './stepperConfig';
import { colors } from '../../commonComponents/colors';
import VideoModel from '../../commonComponents/videoModel';
import howToIcon from '../../assets/How-to.svg';
import InfoIcon from '../../commonComponents/InfoIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getResumaResults } from '../../commonRedux/scan/action';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
function getSteps() {
  return [
    'LinkedIn Connect',
    'Review/Edit',
    'Ready to Copy',
    'Final Options',
    // "You Update Profile",
  ];
}
function getStepContent(
  stepIndex,
  handleNext,
  showDialog,
  setShowDialog,
  dialogLoading,
) {
  switch (stepIndex) {
    case 0:
      return (
        <Connect
          handleNext={handleNext}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          dialogLoading={dialogLoading}
        />
      );
    case 1:
      return <OptimizedProfile stepIndex={stepIndex} />;
    case 2:
      return <OptimizedProfile stepIndex={stepIndex} />;
    case 3:
      return <FinalOptions stepIndex={stepIndex} />;
    default:
      return (
        <Connect
          handleNext={handleNext}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          dialogLoading={dialogLoading}
        />
      );
  }
}
const styles = {
  h1: {
    // fontFamily: "Montserrat",
    // fontWeight: "bold",
  },
  title: {
    fontWeight: 800,
    fontSize: '22px',
    lineHeight: '34.13px',
    fontFamily: 'Montserrat',
    color: '#4A4A4A',
  },
};

export default function LinkedIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const VideoLinks = useSelector((state) => state.fileScanReducer.videoLinks);
  const user = useSelector((state) => state.authUser.user);
  const userResumas = useSelector((state) => state.fileScanReducer.userResumas);
  const [activeStep, setActiveStep] = React.useState(0);
  const [modelOpen, setModelOpen] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(true);
  const steps = getSteps();

  const loader = useSelector((state) => state.linkedinReducer.linkedInLoader);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    localStorage.setItem('linkedinStepperIndex', activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    localStorage.setItem('linkedinStepperIndex', activeStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (Object.keys(user || {})?.length) {
      dispatch(getResumaResults('', null, 'scan')).finally(() => {
        setDialogLoading(false);
      });
    }
  }, [user]);

  useEffect(() => {
    if (userResumas.length === 0) {
      setShowDialog(true);
      return;
    }
    setShowDialog(false);
  }, [userResumas?.length]);

  useEffect(() => {
    const linkedinStepperIndex = localStorage.getItem('linkedinStepperIndex');
    const LinkedIn_Token = localStorage.getItem('LinkedIn_Token');
    if (linkedinStepperIndex && LinkedIn_Token) {
      setActiveStep(Number(linkedinStepperIndex));
    }
  }, []);

  return (
    <Row>
      <VideoModel
        open={modelOpen}
        setOpen={setModelOpen}
        video={VideoLinks?.LinkedIn}
      />
      <Col sm={12} xs={12} lg={12} className="text-center mb-2">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <InfoIcon
            onClick={() => setModelOpen(true)}
            text={'How To'}
            icon={'Play'}
            height={18}
            width="70px"
            marginTop={8}
            marginRight={5}
          />
          <h4 style={styles.title}>LinkedIn Profile Content</h4>
        </div>
        <span
          style={{
            fontSize: '16px',
          }}
        >
          Let recruiters on LinkedIn easily find you! <br />
          We provide optimized content to update your LinkedIn profile based on
          your resume and job descriptions.
        </span>
      </Col>
      <Col sm={12} xs={12} lg={12} className="text-center">
        <Stepper
          connector={<ColorlibConnector />}
          activeStep={activeStep}
          style={{
            overflow: 'auto',
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <StepButton
                  disabled
                  style={{
                    overflow: 'hidden',
                    width: '100%',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <b
                    style={{
                      color: colors.fontGray,
                    }}
                  >
                    {label}
                  </b>
                </StepButton>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep !== 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              // marginTop: width < 830 ? "10px" : "-50px",
              // paddingLeft: "10px",
              // paddingRight: "10px",
              // paddingBottom: "30px",
              padding: '10px 0px',
            }}
          >
            {activeStep > 1 ? (
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                // className={classes.backButton}
                style={{
                  backgroundColor: colors.blue,
                  color: 'white',
                }}
              >
                Back
              </Button>
            ) : (
              <div></div>
            )}
            {activeStep < 4 && !loader ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: colors.blue,
                  color: 'white',
                }}
                // color=colors.blue
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            ) : (
              <div></div>
            )}
          </div>
        )}

        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography
                className={classes.instructions}
                style={{
                  marginTop: '10px',
                  marginBottom: '50px',
                }}
              >
                <h4 className="font-weight-bold">
                  Congratulations, you completed your LinkedIn profile update!
                </h4>
              </Typography>
              <Button
                variant="contained"
                style={{
                  backgroundColor: colors.blue,
                  color: 'white',
                }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(
                  activeStep,
                  handleNext,
                  showDialog,
                  setShowDialog,
                  dialogLoading,
                )}
              </Typography>
              {activeStep !== 0 && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '60px',
                    padding: '10px 0px',
                  }}
                >
                  {activeStep > 1 ? (
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      // className={classes.backButton}
                      style={{
                        backgroundColor: colors.blue,
                        color: 'white',
                      }}
                    >
                      Back
                    </Button>
                  ) : (
                    <div></div>
                  )}
                  {activeStep < 4 && !loader ? (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: colors.blue,
                        color: 'white',
                      }}
                      // color=colors.blue
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}
