import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateLinkedinData } from '../redux/actions';
import uuid from 'react-uuid';
import { useWindowSize } from '@react-hook/window-size';
import TextIcon from '../../../assets/text.webp';
import RightIconButton from '../../../commonComponents/RightIconButton';
import { colors } from '../../../commonComponents/colors';
import moment from 'moment';

export default function Experience(props) {
  const [width] = useWindowSize();
  const [item, setItem] = useState({});
  const [copied, setCopied] = useState([]);
  const [position, setPosition] = useState('');
  const [company, setCompany] = useState('');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const linkedinData = useSelector(
    (state) => state.linkedinReducer.linkedinData,
  );
  const experienceModal = useSelector(
    (state) => state.linkedinReducer.experienceModal,
  );
  const experienceModalMode = useSelector(
    (state) => state.linkedinReducer.experienceModalMode,
  );

  const RecruiterLabel = () => {
    return (
      <RightIconButton
        text="RECRUITER FOCUS"
        color={colors.darkYellow}
        imgsrc={TextIcon}
        style={{
          alignSelf: 'center',
          marginLeft: '40px',
          width: '118px',
          cursor: 'default',
        }}
      />
    );
  };

  //remove html tags
  const removeHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<[^>]*>?/gm, '');
  };
  useEffect(() => {
    if (experienceModalMode === 'new') {
      setItem({
        id: uuid(),
      });
      setId(uuid());

      setPosition('');
      setCompany(removeHtmlTags(''));
      setStartDate('');
      setEndDate('');
      setDescription(removeHtmlTags(''));
      setLocation('');
    }
  }, [experienceModalMode]);
  const dispatch = useDispatch();
  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };
  const cb = (v) => {
    dispatch({
      type: 'EXPERIENCE_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Experience Updated');
    } else {
      toastr.error('Error Updating Experience');
    }
  };
  const handleUpdate = () => {
    setLoading(true);
    let work = linkedinData?.work?.length
      ? linkedinData?.work?.filter((item) => item.id !== id)
      : [];

    const newWork = {
      ...item,
      position,
      company,
      location,
      startDate,
      endDate,
      description,
    };
    work.push(newWork);

    let data = { ...linkedinData, work };

    dispatch(updateLinkedinData(data, cb));
  };

  return (
    <Card body className="text-left mb-4  " style={styles.border_radius_5}>
      {props.workData?.length
        ? props.workData.map((item, index) => {
            return (
              <Row key={index} className="mt-2  ">
                <Col
                  xs={8}
                  lg={11}
                  xl={11}
                  md={11}
                  className="border-bottom p-5 "
                >
                  <p style={styles.title}>{item?.position}</p>
                  <p style={styles.sub}>{item?.company}</p>
                  <p style={styles.years}>
                    Start Date :{' '}
                    {item?.startDate &&
                      moment(item?.startDate).format('MM/DD/YY')}
                  </p>
                  <p style={styles.years}>
                    End Date :{' '}
                    {item?.endDate && moment(item?.endDate).format('MM/DD/YY')}
                  </p>
                </Col>
                <Col
                  xs={4}
                  lg={1}
                  xl={1}
                  md={1}
                  className="border-bottom p-2 d-flex align-items-center justify-content-center "
                >
                  <span
                    style={styles.copyIcon}
                    onClick={() => {
                      setId(item?.id);
                      setPosition(item?.position);
                      setCompany(removeHtmlTags(item?.company));
                      setStartDate(item?.startDate);
                      setEndDate(item?.endDate);
                      setDescription(removeHtmlTags(item?.summary));
                      setLocation(item?.location);
                      setItem(item);
                      dispatch({
                        type: 'EXPERIENCE_MODAL',
                        payload: {
                          open: true,
                          mode: 'edit',
                        },
                      });
                    }}
                  >
                    {props.stepIndex === 1 ? (
                      <CreateIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    ) : (
                      <FileCopyIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    )}
                  </span>
                </Col>
              </Row>
            );
          })
        : 'NO DATA AVAILABLE'}

      <Popup
        open={experienceModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'EXPERIENCE_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'EXPERIENCE_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5 style={{ fontSize: 23 }}>Experience</h5>

        <Row>
          <Col>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Label
                style={{
                  marginTop: 10,
                }}
                for="exampleEmail"
              >
                Title
              </Label>
              <RecruiterLabel />
            </div>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(position) ? (
                    <CopyToClipboard
                      text={position}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(position);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Company Name
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(company) ? (
                    <CopyToClipboard
                      text={company}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(company);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Location
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(location) ? (
                    <CopyToClipboard
                      text={location}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(location);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Row>
              <Col xs={6}>
                <Label style={{ marginTop: 15 }} for="exampleEmail">
                  Start Date
                </Label>
                <InputGroup>
                  <Input
                    type="date"
                    style={{ height: '50px' }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  {props.stepIndex === 2 && (
                    <InputGroupText>
                      {!copied.includes(startDate) ? (
                        <CopyToClipboard
                          text={startDate}
                          onCopy={() => {
                            toastr.success('Text Copied!');
                            handleCopied(startDate);
                          }}
                        >
                          <FileCopyIcon className="hovdel" />
                        </CopyToClipboard>
                      ) : (
                        <CheckIcon style={{ color: 'green' }} />
                      )}
                    </InputGroupText>
                  )}
                </InputGroup>
              </Col>
              <Col xs={6}>
                <Label style={{ marginTop: 15 }} for="exampleEmail">
                  End Date
                </Label>
                <InputGroup>
                  <Input
                    type="date"
                    style={{ height: '50px' }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  {props.stepIndex === 2 && (
                    <InputGroupText>
                      {!copied.includes(endDate) ? (
                        <CopyToClipboard
                          text={endDate}
                          onCopy={() => {
                            toastr.success('Text Copied!');
                            handleCopied(endDate);
                          }}
                        >
                          <FileCopyIcon className="hovdel" />
                        </CopyToClipboard>
                      ) : (
                        <CheckIcon style={{ color: 'green' }} />
                      )}
                    </InputGroupText>
                  )}
                </InputGroup>
              </Col>
            </Row>

            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Description
            </Label>
            <InputGroup>
              <Input
                style={{ height: '150px' }}
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(description) ? (
                    <CopyToClipboard
                      text={description}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(description);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={loading}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  {loading ? 'Saving' : 'Save'}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40px',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
  AddIcon: {
    cursor: 'pointer',
    fontSize: 35,
  },
};
