// React Basic and Bootstrap
import React from "react";
import { Row, Col } from "reactstrap";
import { styles } from "./style";

export default () => {
  return (
    <>
      <section className=" p-5 " style={{backgroundColor:'#f8f9fa'}}>
        <div className="container">
          <Row className="justify-content-center">
            <Col className="text-center">
              <div className="section-title" style={styles.pb_30}>
                <h4
                  style={{ fontFamily: "Montserrat" }}
                  className="main-title mb-4"
                >
                  Partners
                </h4>
                <p
                  style={{ fontFamily: "Nunito" }}
                  className="text-muted  mb-0 mx-auto"
                >
                  Career Coaching and Career Services Organizations
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="">
              {/* <p className="text-muted para-desc  mb-10 mx-auto">Helping Counselling professionals serving students and unemployed clients reach success!</p> */}
              <p style={{textAlign:'center'}} className="text-muted   mb-10 mx-auto">
                Use our platform to serve your job seeker in a whole new way by
                saving time and getting results. Offer resume optimization 
                service as part of your career and employment coaching services, and
                improve operational effciencies and results accross your
                organization.
              </p>
              <p style={{textAlign:'center'}} className="text-muted  mb-10 mx-auto">
                Package includes bulk discounts for job seeker license and
                integeration support. Please contact support@resumas.com for
                details.{" "}
              </p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};
