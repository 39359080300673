import React from 'react';

const WordIcon = ({ height, width }) => {
  return (
    <svg
      width={width ? width : '45'}
      height={height ? height : '45'}
      viewBox="0 0 360 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M350 40H190V320H350C355.53 320 360 315.53 360 310V50C360 44.47 355.53 40 350 40Z"
        fill="#DDDDDD"
      />
      <path
        d="M190 90.01H330V110H190V90.01ZM190 130H330V150H190V130ZM190 170.01H330V190.01H190V170.01ZM190 210.01H330V230H190V210.01ZM190 250H330V270.01H190V250Z"
        fill="#0D47A1"
      />
      <path d="M210 360L0 320V40L210 0V360Z" fill="#0D47A1" />
      <path
        d="M151.67 250.12H124.5L106.48 160.24C105.5 155.47 104.93 150.28 104.74 144.48H104.42C103.99 150.85 103.32 156.1 102.45 160.24L83.9499 250.12H55.6799L27.08 109.98H53.8299L69.1899 203.26C69.8099 207.3 70.2999 212.64 70.6199 219.33H71.0399C71.2299 214.35 72.0199 208.82 73.2699 202.88L92.9699 109.97H119.19L137.04 204.01C137.66 207.49 138.23 212.47 138.74 219.12H139.05C139.25 213.97 139.78 208.77 140.65 203.49L155.68 109.97H180.36L151.67 250.12Z"
        fill="white"
      />
    </svg>
  );
};

export default WordIcon;
