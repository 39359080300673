import axios from 'axios';
import url from '../../../config/endpoint';
import toastr from 'toastr';
import { logoutUser, updatePhoneNo } from '../../auth/redux/actions';
import { CURRENT_USER } from '../../auth/redux/constants';

export const basicInfo = (data, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-basic-details`, data);

    if (res.data.response === 'success') {
      // dispatch({ type: "PROFILE_STATUS" });
      dispatch(updatePhoneNo({ type: 'verified', mobile: data.phone }, cb));
      toastr.success('Basic info saved');
    }
  } catch (error) {
    cb();
    // dispatch({ type: "PROFILE_STATUS_ERROR" });
    toastr.error(`error`);
  }
};
export const sendSupportMessage = (cb, files, onClose) => async () => {
  try {
    const res = await axios.post(`${url}/customer-support`, files);

    if (res.data.status === 200) {
      toastr.success(
        'Thanks for contacting us. We will get back to you as soon as possible',
      );
      cb('OK');
      // toaster.success("Mobile Verified Successfully");
      // Toast(res.data.message, 'success', 'top', 3000);
      onClose && onClose();
    } else {
      cb('ERROR');
    }
  } catch (error) {
    cb('ERROR');
    toastr.error(error);
  }
};

export const subscribePlan = (data, loader) => async (dispatch) => {
  try {
    loader({
      plan: data?.planId ? data.planId : '',
      loader: true,
    });

    const res = await axios.post(`${url}/create-user-subscription`, data);
    // eslint-disable-next-line
    dispatch(GetUserDetailAction());
    dispatch({
      type: 'DOWNGRADE_USER_ACCOUNT',
      payload: {
        subscription: true,
      },
    });
    if (res?.data?.status === 500) {
      toastr.error(res.data.message);
    } else {
      toastr.success('Plan has been subscribed successfully');
    }
  } catch (error) {
    toastr.error(error);
  } finally {
    loader({
      plan: data?.planId,
      loader: false,
    });
  }
};

export const moreSpecificInfo = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-specific-details`, data);

    if (res.data.response === 'success') {
      dispatch({ type: 'PROFILE_STATUS' });
      toastr.success('specific info saved');
    }
  } catch (error) {
    toastr.error(`error`);
    dispatch({ type: 'PROFILE_STATUS_ERROR' });
  }
};

export const educationInfo = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-educational-details`, data);

    if (res.data.response === 'success') {
      dispatch({ type: 'PROFILE_STATUS' });
      toastr.success('edu info saved');
    }
  } catch (error) {
    toastr.error(`error`);
    dispatch({ type: 'PROFILE_STATUS_ERROR' });
  }
};

export const jobInfo = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-job-info`, data);

    if (res.data.response === 'success') {
      dispatch({ type: 'PROFILE_STATUS' });
      // dispatch({ type: "CURRENT_USER" });
      toastr.success('Job info saved');
    }
  } catch (error) {
    toastr.error(`error`);
    dispatch({ type: 'PROFILE_STATUS_ERROR' });
  }
};

export const jobMatchPreferencesInfo = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-preferences`, data);

    if (res.data.response === 'success') {
      dispatch({ type: 'PROFILE_STATUS' });
      toastr.success('jobMatchPreferencesInfo info saved');
    }
  } catch (error) {
    toastr.error(`error`);
    dispatch({ type: 'PROFILE_STATUS_ERROR' });
  }
};

export const changePasswordAction = (data, cb) => async (dispatch) => {
  try {
    dispatch({
      type: 'START_LOADER',
    });
    const res = await axios.post(`${url}/change-password`, data);

    if (res.data.message === 'Wrong old credentials') {
      toastr.error(res.data.message);
      dispatch({
        type: 'STOP_LOADER',
      });
    } else if (res.data.message === 'Successfully Changed Password!') {
      toastr.success(res.data.message);
      dispatch({
        type: 'STOP_LOADER',
      });
      cb && cb();
    }
  } catch (error) {
    toastr.error(error);
    dispatch({
      type: 'STOP_LOADER',
    });
  }
};

export const changeUserCardAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: 'SAVE_DEFAULT_CARD',
      payload: data.card_id,
    });
    const res = await axios.post(`${url}/default-user-card`, data);

    if (res.data.status === 200) {
      toastr.success(res.data.message);
      // cb(true);
    } else {
      toastr.error(res.data.message);
      // cb(false);
    }
  } catch (error) {
    toastr.error(error);
    // cb(false);
  }
};
export const deleteUserCardAction = (data, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/delete-user-card`, data);

    if (res.data.status === 200) {
      toastr.success(res.data.message);
      // eslint-disable-next-line
      dispatch(GetUserCardsAction(() => null));
      cb(true);
    } else {
      toastr.error(res.data.message);
      cb(false);
    }
  } catch (error) {
    toastr.error(error);
    cb(false);
  }
};

export const GetUserCardsAction = () => async (dispatch) => {
  try {
    let getToken = localStorage.getItem('clientAccessToken');

    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }

    const res = await axios.get(`${url}/get-user-cards`);
    //
    if (res.data.status === 200) {
      dispatch({
        type: 'ACCOUNT_CARDS_DETAIL',
        payload: res.data.payDetails,
      });
    }
  } catch (error) {
    toastr.error(error);
  }
};
export const deleteUser = (user, feedback, history, cb) => async (dispatch) => {
  let data = {
    email: user.email,
    feedback,
  };
  try {
    const res = await axios.post(`${url}/admin/delete-user`, data);
    console.log(res);
    if (res) {
      toastr.success('Account deleted successfully');
      dispatch(logoutUser(history));
    }
  } catch (error) {
    toastr.error(error.message);
  } finally {
    if (cb) cb();
  }
};

export const deleteUserAccount = (cb) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/delete-user-account`);

    if (res.data) {
      dispatch({
        type: 'DOWNGRADE_USER_ACCOUNT',
        payload: {
          subscription: false,
        },
      });
      // eslint-disable-next-line
      dispatch(GetUserDetailAction());
      toastr.success('Account has been downgraded successfully');
      cb('OK');
    }
  } catch (error) {}
};

export const GetUserDetailAction = (setLoader) => async (dispatch) => {
  try {
    if (setLoader) {
      setLoader(true);
    }
    let getToken = localStorage.getItem('clientAccessToken');

    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }

    const res = await axios.get(`${url}/get-user-details`);

    if (res.data.status === 200) {
      dispatch({
        type: 'ACCOUNT_DETAIL',
        payload: res.data.user,
      });
      dispatch({
        type: CURRENT_USER,
        payload: res.data.user,
      });
    } else {
      dispatch({
        type: 'ACCOUNT_ISSUE',
      });
      toastr.error('having some issue in user!');
    }

    if (setLoader) {
      setLoader(false);
    }
  } catch (error) {
    toastr.error(error);
    if (setLoader) {
      setLoader(false);
    }
  }
};

export const addUserCardAction = (data, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/create-user-card`, data);

    if (res.data.status === 200) {
      cb(res.data);
      if (data?.addPaymentMethod) {
        dispatch(GetUserDetailAction());
      }
      dispatch(GetUserCardsAction(() => null));
      toastr.success(res.data?.message);
    } else {
      cb(res.data);
      toastr.error(res.data?.message);
    }
  } catch (error) {
    cb();
    toastr.error(error);
  }
};

export const addUserCardDetail = (data, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-phone`, data);

    if (res.data) {
      cb(res.data);
      dispatch(GetUserCardsAction(() => null));
      // toastr.success(res.data?.message);
    } else {
      // cb(res.data);
      toastr.error(res.data?.message);
    }
  } catch (error) {
    cb();
    // toastr.error(error);
  }
};

export const verifyDiscountCoupon = (data, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/coupon-verification`, data);

    if (res.data?.status === 200) {
      toastr.success(res.data?.message || 'Coupon Applied');
      dispatch(GetUserDetailAction());

      cb(res.data);
    } else {
      cb();
      toastr.error(res.data?.message);
    }
  } catch (error) {
    cb();
    toastr.error(error);
  }
};

export const getVersions = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/get-current-versions`);

    if (res?.data?.status === 200) {
      dispatch({
        type: 'GET_VERSIONS',
        payload: res?.data?.versions[0],
      });
    }
  } catch (error) {
    console.log(error);
  }
};
