import Axios from 'axios';
import React from 'react';
import url from '../../config/endpoint';

function useEditor({ oid, token }) {
  const [editorJson, setEditorJson] = React.useState(null);
  const [fontFamily, setFontFamily] = React.useState([]);

  const getEditorJson = async () => {
    try {
      let res = await Axios.post(
        `${url}/get-sfdt`,
        { oid },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (res.status === 200) {
        setEditorJson(res.data.editorJson);
        setFontFamily(res.data.fontFamily);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getEditorJson();
  }, [oid]);

  return {
    editorJson,
    fontFamily,
  };
}

export default useEditor;
