import React from 'react';
import { Text } from '../../component';
import { useStyles } from '../style';
import { useSelector } from 'react-redux';
import RichEditor from '../helper/RichEditor';
import { onBlurField } from '../../redux/actions';

export default function Custom() {
  const classes = useStyles();
  let { resumeData } = useSelector((state) => state.editorReducer);

  return (
    <div>
      <div>
        <label className={classes.label}>Title</label>
        <Text
          value={resumeData?.custom?.title}
          placeholder="Title"
          customclass={classes.input}
          path={`custom.title`}
        />
      </div>
      <label className={classes.label}>Description</label>

      <RichEditor
        value={resumeData?.custom?.body}
        placeHolder="Description"
        customclass={`${classes.input} ${classes.summaryInput}`}
        path={'custom.body'}
        onBlurField={onBlurField}
      />
    </div>
  );
}
