import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../commonComponents/colors';

export const styles = {
  stepperContainer: (width) => {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '100%',
      width: '100%',
      backgroundColor: 'white',
      padding: width <= 600 ? '0px 3px' : '0px 10px',
      borderRadius: 10,
    };
  },
  stepBtn: {
    backgroundColor: colors.blue,
    color: colors.white,
    display: 'flex',
    alignSelf: 'center',
  },
  stepperBtn: {
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  heading: (width) => {
    return {
      color: colors.fontGray,
      fontWeight: 800,
      fontSize: width < 390 ? 15 : 22,
      fontFamily: 'Montserrat',
      margin: '10px 0px',
    };
  },
  infoHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: 20,
  },
  container: (width) => {
    return {
      backgroundColor: colors.white,
      padding: width <= 375 ? '5px' : '10px',
      borderRadius: 5,
      margin: 0,
    };
  },
  codeInput: {
    padding: '10px',
    height: '40px',
    borderRadius: '5px',
    border: '1px solid lightgray',
    outline: 'none',
    fontSize: '16px',
  },
  searchBtn: {
    width: '100%',
    padding: '10px 20px',
    height: '40px',
    borderRadius: 50,
    border: '1px solid lightgray',
    outline: 'none',
    fontSize: '16px',
    backgroundColor: colors.blue,
    color: colors.white,
    cursor: 'pointer',
  },
  chip: (width) => {
    return {
      margin: 3,
      borderRadius: 5,
      fontSize: width < 400 ? 12 : 14,
      marginTop: 10,
      ...(width < 400 && { whiteSpace: 'pre-wrap' }),
    };
  },
  containerJobs: (width) => {
    return {
      background: colors.lightGray,
      padding: width < 400 ? '3px' : '20px',
      margin: '20px 0px',
      borderRadius: '5px',
    };
  },
  noFound: (width) => {
    return {
      background: colors.lightGray,
      padding: '20px',
      margin: '20px 0px',
      borderRadius: '5px',
      textAlign: 'center',
      width: '100%',
      fontSize: width < 400 ? 12 : 14,
    };
  },

  loader: {
    size: 20,
    color: '#fff',
  },
  labelStyle: {
    color: '#000',
    fontWeight: 500,
    margin: '0px 0px 3px 0px',
    fontSize: 15,
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomButtonContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 0',
  },
  congratsContainer: {
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 45,
  },
  animationStyle: {
    width: 70,
    marginTop: -10,
  },
  emailText: {
    backgroundColor: colors.blue,
    padding: '5px',
    color: colors.white,
    borderRadius: '50%',
    display: 'flex',
  },
  resumeNameText: {
    paddingBottom: '15px',
    marginTop: 10,
    fontSize: 16,
  },
  copyUrl: {
    paddingBottom: '15px',
    marginTop: 60,
    fontSize: 16,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  copyIcon: {
    cursor: 'pointer',
    marginLeft: '20px',
  },
  downloadWrapper: {
    borderBottom: '1px solid #F0F0F0',
    marginTop: '16px',
  },
  docIcon: {
    padding: '16px 28px',
    borderLeft: '1px solid #F0F0F0',
    cursor: 'pointer',
  },
  rootWrapper: (width) => {
    return {
      display: 'flex',
      flexDirection: width < 1150 ? 'column' : 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 20,
    };
  },
  pdfIcon: (width) => {
    return {
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: width < 650 ? '20px' : '28px',
      borderLeft: '1px solid #F0F0F0',
      cursor: 'pointer',
    };
  },
  inputUrl: (width) => {
    return {
      width: width < 650 ? 'calc(200px - 50px)' : 'calc(308px - 50px)',
      color: colors.blue,
    };
  },
  renameLoading: (loading) => {
    return {
      background: loading ? colors.grey : colors.blue,
      color: colors.white,
      padding: '3.5px 15px',
      textTransform: 'capitalize',
    };
  },
  reportContainer: (width) => {
    return {
      width: width < 1150 ? '100%' : '60%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    };
  },
  reportWrapper: (width) => {
    return {
      marginTop: width < 1150 ? '30px' : '0',
      border: '1px solid #F0F0F0',
    };
  },
  savedNamedContainer: (width) => {
    return {
      display: width < 650 ? 'flex' : 'block',
      flexDirection: width < 650 ? 'column' : 'row',
      width: width < 1310 ? '100%' : '90%',
      padding: '30px 25px',
    };
  },
  newReportContainer: (width) => {
    return {
      marginTop: width < 1150 ? '30px' : '0',
      width: width < 1150 ? '100%' : '35%',
      border: '1px solid #F0F0F0',
    };
  },
};

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
  completed: {
    color: `${colors.green} !important`,
  }, //needed so that the &$active tag works
  connectorLine: {
    borderColor: 'black',
    height: '50px',
  },
}));
