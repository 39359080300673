export const profileReducer = (
  state = {
    profileStatus: 'not done',
    updator: new Date(),
    loaderStatus: false,
    accountDetail: {},
    usersCards: [],
    detailStatus: 'not-done',
    versions: {},
  },
  action,
) => {
  switch (action.type) {
    case 'PROFILE_STATUS':
      return { ...state, profileStatus: 'done', updator: new Date() };
    case 'PROFILE_STATUS_ERROR':
      return {
        ...state,
        profileStatus: 'not done',
        updator: new Date(),
      };

    case 'SAVE_DEFAULT_CARD':
      let accountDetail = { ...state.accountDetail };
      accountDetail.payDetails = {
        ...accountDetail?.payDetails,
        default_source: action.payload,
      };

      return {
        ...state,
        accountDetail: accountDetail,
      };
    case 'ACCOUNT_CARDS_DETAIL':
      return {
        ...state,
        usersCards: action.payload,
      };
    case 'ACCOUNT_DETAIL':
      return {
        ...state,
        accountDetail: action.payload,
        detailStatus: 'done',
      };
    case 'ACCOUNT_ISSUE':
      return {
        ...state,
        detailStatus: 'done',
      };
    case 'START_LOADER':
      return {
        ...state,
        loaderStatus: true,
      };
    case 'STOP_LOADER':
      return {
        ...state,
        loaderStatus: false,
      };
    case 'GET_VERSIONS':
      return {
        ...state,
        versions: action.payload,
      };
    default:
      return state;
  }
};
