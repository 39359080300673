export const JOB_OCCUPATION_UPDATE = 'JOB_OCCUPATION_UPDATE';

export const JOB_OCCUPATION_LOADER = 'JOB_OCCUPATION_LOADER';

export const JOB_OCCUPATION_FAILED = 'JOB_OCCUPATION_FAILED';

export const ON_ADD_SELECTED_JOB_OCCUPATION = 'ON_ADD_SELECTED_JOB_OCCUPATION';

export const ON_REMOVE_SELECTED_JOB_OCCUPATION =
  'ON_REMOVE_SELECTED_JOB_OCCUPATION';

export const SUGGESTED_INFORMATION_LOADER = 'GET_SUGGESTED_INFORMATION_LOADER';

export const GET_SUGGESTED_INFORMATION = 'GET_SUGGESTED_INFORMATION';

export const CLEAR_MILITARY_DATA = 'CLEAR_MILITARY_DATA';

export const UPDATE_MILITARY_CODE = 'UPDATE_MILITARY_CODE';

export const UPDATE_MILITARY_BRANCH = 'UPDATE_MILITARY_BRANCH';

export const ON_REMOVE_SELECTED_JOB = 'ON_REMOVE_SELECTED_JOB';

export const SET_JOB_OCCUPATIONS = 'SET_JOB_OCCUPATIONS';

export const ON_REMOVE_SELECTED_JOB_SUGGESTION =
  'ON_REMOVE_SELECTED_JOB_SUGGESTION';
export const ON_REMOVE_SELECTED_SUBTITLE = 'ON_REMOVE_SELECTED_SUBTITLE';
export const ON_ADD_SELECTED_SUBTITLE = 'ON_ADD_SELECTED_SUBTITLE';

export const MILITARY_LOADING = 'MILITARY_LOADING';

export const UPDATE_FILE_NAME = 'UPDATE_FILE_NAME';

export const GET_SUBTITLES = 'GET_SUBTITLES';

export const CLEAR_SELECTED_SUBTITLE = 'CLEAR_SELECTED_SUBTITLE';

export const UPDATE_MILXWALK_ACTIVE_STEP = 'UPDATE_MILXWALK_ACTIVE_STEP';

export const UPDATE_MILXWALK_DATA = 'UPDATE_MILXWALK_DATA';

export const MILITARY_DATA_API_STATUS = 'MILITARY_DATA_API_STATUS';

export const RESET_LOADING = 'RESET_LOADING';

export const MILITARY_TAB_VALUE = 'MILITARY_TAB_VALUE';

export const GET_MILXWALK_DETAIL = 'GET_MILXWALK_DETAIL';

export const GET_MILXWALK_DETAIL_LOADING = 'GET_MILXWALK_DETAIL_LOADING';

export const JOB_SUBTITLE_LOADER = 'JOB_SUBTITLE_LOADER';

export const INTRO_MILXWALK_VIDEO_MODAL = 'INTRO_MILXWALK_VIDEO_MODAL';
export const JOB_OCCUPATION_EMPTY = 'JOB_OCCUPATION_EMPTY';
