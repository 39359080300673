import React, { memo, useEffect, useState } from 'react';
import { Progress, Row, Card, CardBody, CardTitle } from 'reactstrap';
import { Colxx } from '../../commonComponents/customBootstrap';
import { MdDone, MdClear } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import GraphTable from './dasboard-graph-table';
import TopStat from './top-stat';
import { styles } from './style';
import GaugeChart from '../../commonComponents/react-advanced-gauge-chart';
import { colors } from '../../commonComponents/colors';
import { useWindowWidth } from '@react-hook/window-size';
import VideoModel from '../../commonComponents/videoModel';
import InfoIcon from '../../commonComponents/InfoIcon';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';

const getSoftSkillsScore = (soft_skills) => {
  let count_soft_skills_false = 0;
  let count_soft_skills_true = 0;

  let j;
  for (j in soft_skills) {
    if (!soft_skills[j].missing) {
      count_soft_skills_true += 1;
    } else {
      count_soft_skills_false += 1;
    }
  }
  return {
    exists: count_soft_skills_true,
    total: count_soft_skills_true + count_soft_skills_false,
    'not-exists': count_soft_skills_false,
  };
};

const ProgressCustomComponent = ({ title, skills_score, tooltipTxt = '' }) => {
  const total_score = (skills_score.exists / skills_score.total) * 100;
  return (
    <div
      style={{
        marginBottom: '40px',
      }}
    >
      <p className="mb-2">
        {title}
        {tooltipTxt && (
          <Tooltip title={tooltipTxt} arrow>
            <HelpIcon style={styles.cursorPointer} />
          </Tooltip>
        )}

        <span
          className="float-right text-muted"
          style={{ ...styles.flex, ...styles.alignItemsCenter }}
        >
          {skills_score.exists < skills_score['not-exists'] ? (
            <MdClear color={colors.red} style={styles.inline} />
          ) : (
            <MdDone color={colors.green} style={styles.inline} />
          )}
          {skills_score.exists}/{skills_score.total}
        </span>
      </p>
      <Progress
        className={
          total_score < 50
            ? 'less_50'
            : total_score >= 50 && total_score <= 80
            ? 'greater_50_less_80'
            : total_score > 80
            ? 'greater_80'
            : ''
        }
        value={total_score || 2.5}
      />
    </div>
  );
};

const PipeSymbol = () => {
  return <span>&nbsp;|&nbsp;</span>;
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const CareerCard = ({ title, list }) => {
  return (
    <div
      style={{
        border: '1px solid rgb(238, 238, 238)',
        borderRadius: 5,
        boxShadow: '1px 1px 1px 1px rgb(238,238,238)',
        padding: '12px',
      }}
    >
      <div
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          marginBottom: '10px',
        }}
      >
        {title}:
      </div>
      <div
        style={{
          fontSize: '12px',
          fontWeight: 400,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {list?.length ? (
          list?.sort()?.map((item, index) => {
            return item ? (
              <span
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  whiteSpace: 'wrap',
                }}
              >
                {`${item} ${index !== list.length - 1 ? '|' : ''}`}&nbsp;
              </span>
            ) : null;
          })
        ) : (
          <span>No Data</span>
        )}
      </div>
    </div>
  );
};

const Jobmatchrate = memo((props) => {
  const [score, setScore] = useState(0);
  const [modelOpen, setModelOpen] = useState(false);
  const VideoLinks = useSelector((state) => state.fileScanReducer.videoLinks);
  const suggestionLoader = useSelector(
    (state) => state.fileScanReducer.suggestionLoader,
  );
  const width = useWindowWidth();
  const MainJobTitle = useSelector(
    (state) => state.fileScanReducer.job_parsed.MainJobTitle,
  );
  const monthsOfExperience =
    props.resumeData?.other_info?.experience_summary?.MonthsOfWorkExperience ||
    0;
  const jobTitle = MainJobTitle || props.jobTitle || '';

  useEffect(() => {
    if (jobTitle) {
      props.getSuggestions(jobTitle, monthsOfExperience);
    }
  }, [jobTitle]);

  useEffect(() => {
    if (props.matchRate && props.matchRate.totalScore !== 'NaN') {
      setScore(props.matchRate.totalScore);
    }
  }, [props.matchRate]);

  const soft_skills_score = getSoftSkillsScore(props.skills['soft_skills']);

  let atsExist = props.matchRate.scores.ats.exists;
  let atsTotal = props.matchRate.scores.ats.total;
  let rfindingsExist = props.matchRate.scores.rfindings.exists;
  let rfindingsTotal = props.matchRate.scores.rfindings.total;

  return (
    <div className="profile_status_container">
      <VideoModel
        open={modelOpen}
        setOpen={setModelOpen}
        video={VideoLinks?.ReviewResults}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '15px',
        }}
      >
        <InfoIcon
          onClick={() => setModelOpen(true)}
          text={'How To'}
          icon={'Play'}
          height={18}
          width="70px"
          marginTop={8}
          marginRight={5}
        />
        <div style={styles.reviewTitle}>Review Results</div>
      </div>
      <style jsx>{`
        .progress {
          width: 100%;
          height: 25px;
          background-color: #eff4ff;
          border-radius: 50px;
          // border: 1px solid #a9a9a9;
        }
        .progress-bar {
          border-radius: 50px;
        }
        .less_50 .progress-bar {
          background-color: ${colors.red} !important;
        }
        .greater_50_less_80 .progress-bar {
          background-color: ${colors.lightBlue} !important;
        }
        .greater_80 .progress-bar {
          background-color: ${colors.green} !important;
        }
      `}</style>
      <Row>
        <Colxx
          className="dashoboard-left-card-Container"
          lg={{
            size: 5,
          }}
        >
          <Card className="h-100">
            <CardBody style={styles.p_none}>
              <CardTitle style={styles.fontWeight_heading}>
                <h5 style={{ fontWeight: 'bold' }}>Job Requirements</h5>
              </CardTitle>

              <GraphTable scores={props.matchRate.resumeScore} data={props} />

              <div style={styles.cardAction}>
                <h5 style={{ fontWeight: 'bold' }}>Resume Info</h5>
              </div>
              <TopStat scores={props.matchRate.resumeScore} />
              <div style={{ ...styles.cardAction, marginTop: '30px' }}>
                <h5
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Career Advancement Ideas{' '}
                  <span
                    style={{
                      display: 'inline',
                      fontSize: '12px',
                    }}
                  >
                    &nbsp;(Updated monthly)
                  </span>
                </h5>
              </div>
              {suggestionLoader ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <BeatLoader
                    css={override}
                    sizeUnit={'px'}
                    size={10}
                    color={'orange'}
                    loading={true}
                  />
                </div>
              ) : (
                <div
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '16px',
                    flex: 1,
                  }}
                >
                  <CareerCard
                    title="Explore the following certification"
                    list={props.suggestions?.certifications?.slice(0, 6) || []}
                  />
                  <CareerCard
                    title="Explore related job titles"
                    list={
                      props.suggestions?.similarJobTitles?.slice(0, 6) || []
                    }
                  />
                  <CareerCard
                    title="Explore the following associations to join"
                    list={props.suggestions?.associations?.slice(0, 6) || []}
                  />
                </div>
              )}

              {/* <SpiderChart styleCloud={props.styleCloud} /> */}
            </CardBody>
          </Card>
        </Colxx>
        <Colxx
          lg={{
            size: 7,
          }}
        >
          <Card className="h-100">
            <CardBody>
              <Row
                style={{
                  alignItems: 'center',
                  marginTop: '30px',
                  marginBottom: '30px',
                }}
              >
                <Colxx
                  lg={{
                    size: 8,
                  }}
                >
                  <GaugeChart
                    id="gauge-chart3"
                    nrOfLevels={3}
                    textColor={'#4c4c4c'}
                    colors={[colors.red, colors.lightBlue, colors.green]}
                    arcsLength={[50, 30, 20]}
                    percent={score / 100}
                  />
                </Colxx>
                <Colxx
                  lg={{
                    size: 4,
                  }}
                  style={{
                    display: 'flex',
                    marginBottom: '20px',
                    alignItems: width < 992 ? 'center' : 'flex-start',
                    justifyContent: width < 992 ? 'center' : 'flex-start',
                    padding: 0,
                  }}
                >
                  <div
                    style={{
                      maxWidth: '270px',
                    }}
                  >
                    <center>
                      <h5 style={{ fontWeight: 'bold' }}>
                        MatchScore <sup>TM</sup>
                        <Tooltip
                          title='MatchScore is the match of key parameters in your resume compared to the "job description" and is based on skills, experience, job title and education.
Goal is to optimize resume to reach MatchScore of 80% or more.'
                          arrow
                        >
                          <HelpIcon style={styles.cursorPointer} />
                        </Tooltip>
                      </h5>
                      <h5 style={{ paddingTop: 5 }}>
                        Resume Vs Job Description
                      </h5>
                    </center>
                    <div style={styles.progess}>
                      <div style={styles.styleRow}>
                        <div
                          style={{
                            ...styles.info_box,
                            ...{
                              backgroundColor: colors.red,
                            },
                          }}
                        />
                        <span style={{ ...styles.ml_3, ...styles.mr_3 }}>
                          {' '}
                          {'Score < 50%'}
                        </span>
                      </div>
                      <div style={styles.styleRow}>
                        <div
                          style={{
                            ...styles.info_box,
                            ...{
                              backgroundColor: colors.lightBlue,
                            },
                          }}
                        />
                        <span style={{ ...styles.ml_3, ...styles.mr_3 }}>
                          {'Score = 51% ~ 79%'}
                        </span>
                      </div>
                      <div style={styles.styleRow}>
                        <div
                          style={{
                            ...styles.info_box,
                            ...{
                              backgroundColor: colors.green,
                            },
                          }}
                        />
                        <span style={{ ...styles.ml_3, ...styles.mr_3 }}>
                          {'Score > 80%'}
                        </span>
                      </div>
                    </div>
                  </div>
                </Colxx>
              </Row>
              <CardTitle
                style={{
                  ...styles.fontWeight_heading,
                  marginTop: '20px',
                }}
              >
                Review Summary
              </CardTitle>

              {props.matchRate &&
                props.matchRate.scores &&
                Object.keys(props.matchRate.scores).map((key, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginBottom: '40px',
                      }}
                    >
                      <p className="mb-2">
                        {key === 'ats' ? (
                          <>
                            MANAGEMENT LEVEL MATCH
                            <Tooltip
                              title="Most Applicant Tracking System (ATS) (applicant tracking system) look for
                              job titles, skills and experience relevant to job description."
                              arrow
                            >
                              <HelpIcon style={styles.cursorPointer} />
                            </Tooltip>
                          </>
                        ) : key === 'rfindings' ? (
                          <>
                            EXPERIENCE LEVEL MATCH
                            <Tooltip
                              title="Recruiters look for specific items and patterns and listed here."
                              arrow
                            >
                              <HelpIcon style={styles.cursorPointer} />
                            </Tooltip>
                          </>
                        ) : key === 'skills' ? (
                          <>
                            HARD SKILLS
                            <Tooltip
                              title="Most Applicant Tracking System (ATS) (applicant tracking system) search
                          for skills or keywords in your resume and compare to the job description.
                          Recommend not to include ALL keywords identified in the review, but
                          must have most hard skills identified."
                              arrow
                            >
                              <HelpIcon style={styles.cursorPointer} />
                            </Tooltip>
                          </>
                        ) : (
                          ''
                        )}
                        <span
                          className="float-right text-muted"
                          style={{ ...styles.flex, ...styles.alignItemsCenter }}
                        >
                          {!props.matchRate.scores[key].missing <
                          props.matchRate.scores[key]['not-exists'] ? (
                            <MdClear color={colors.red} style={styles.inline} />
                          ) : (
                            <MdDone
                              color={colors.green}
                              style={styles.inline}
                            />
                          )}
                          {props.matchRate.scores[key].exists}/
                          {props.matchRate.scores[key].total}
                        </span>
                      </p>
                      <Progress
                        className={
                          (props.matchRate.scores[key].exists /
                            props.matchRate.scores[key].total) *
                            100 <
                          50
                            ? 'less_50'
                            : (props.matchRate.scores[key].exists /
                                props.matchRate.scores[key].total) *
                                100 >=
                                50 &&
                              (props.matchRate.scores[key].exists /
                                props.matchRate.scores[key].total) *
                                100 <=
                                80
                            ? 'greater_50_less_80'
                            : (props.matchRate.scores[key].exists /
                                props.matchRate.scores[key].total) *
                                100 >
                              80
                            ? 'greater_80'
                            : ''
                        }
                        value={
                          (props.matchRate.scores[key].exists /
                            props.matchRate.scores[key].total) *
                            100 || 2.5
                        }
                      />
                    </div>
                  );
                })}

              <div
                style={{
                  marginBottom: '40px',
                }}
              >
                <p className="mb-2">
                  SOFT SKILLS
                  <Tooltip
                    title="Most Applicant Tracking System (ATS) (applicant tracking system) search
                          for skills or keywords in your resume and compare to the job description.
                          Recommend not to include ALL keywords identified in the review, but
                          must have most hard skills identified."
                    arrow
                  >
                    <HelpIcon style={styles.cursorPointer} />
                  </Tooltip>
                  <span
                    className="float-right text-muted"
                    style={{ ...styles.flex, ...styles.alignItemsCenter }}
                  >
                    {soft_skills_score.exists <
                    soft_skills_score['not-exists'] ? (
                      <MdClear color={colors.red} style={styles.inline} />
                    ) : (
                      <MdDone color={colors.green} style={styles.inline} />
                    )}
                    {soft_skills_score.exists}/{soft_skills_score.total}
                  </span>
                </p>
                <Progress
                  className={
                    (soft_skills_score.exists / soft_skills_score.total) * 100 <
                    50
                      ? 'less_50'
                      : (soft_skills_score.exists / soft_skills_score.total) *
                          100 >=
                          50 &&
                        (soft_skills_score.exists / soft_skills_score.total) *
                          100 <=
                          80
                      ? 'greater_50_less_80'
                      : (soft_skills_score.exists / soft_skills_score.total) *
                          100 >
                        80
                      ? 'greater_80'
                      : ''
                  }
                  value={
                    (soft_skills_score.exists / soft_skills_score.total) *
                      100 || 2.5
                  }
                />
              </div>

              <ProgressCustomComponent
                title="JOB TITLE MATCH"
                tooltipTxt="If job title is not matched exactly for the job you are applying for, you
can change or add that job title in your work experience if roles were
similar."
                skills_score={{
                  total: 1,
                  exists:
                    (props.ats?.ats_data?.job_title_matched?.isMatched && 1) ||
                    0,
                  'not-exists':
                    (!props.ats?.ats_data?.job_title_matched?.isMatched && 1) ||
                    0,
                }}
              />
              <ProgressCustomComponent
                title="EDUCATION MATCH"
                tooltipTxt="Add a note If you have a degree that is not an exact match to the job description."
                skills_score={{
                  total: 1,
                  exists:
                    (props.ats?.ats_data?.education_match?.isMatched && 1) || 0,
                  'not-exists':
                    (!props.ats?.ats_data?.education_match?.isMatched && 1) ||
                    0,
                }}
              />
              <ProgressCustomComponent
                title="RESUME ATS MATCH"
                tooltipTxt="Average of MANAGEMENT LEVEL & EXPERIENCE LEVEL"
                skills_score={{
                  total: atsTotal + rfindingsTotal,
                  exists: atsExist + rfindingsExist,
                  'not-exists':
                    atsTotal + rfindingsTotal - (atsExist + rfindingsExist),
                }}
              />
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    </div>
  );
});
export default withRouter(Jobmatchrate);
