import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import './style.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Colxx } from '../../../commonComponents/customBootstrap';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  deleteHistoryItem,
  EmailParserForScanTables,
  addNotesAction,
  updateQuestionsAction,
  SaveActiveIndex,
} from '../../../commonRedux/scan/action';
import { connect, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import useStyles from './style';
import { styles } from '../style';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  getPdf,
  setPdfStatusFalse,
} from '../../../commonRedux/template/action';
import PrintIcon from '../../../assets/svg/PrintIcon';
import FedexIcon from '../../../assets/fedex.webp';
import StoreIcon from '@material-ui/icons/Store';
import {
  CircularProgress,
  DialogContent,
  Select,
  IconButton,
  Button as ButtonR,
} from '@material-ui/core';
import Model from '../../manage/notes-model';
import { colors } from '../../../commonComponents/colors';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { id: 'Company', label: 'Company Name', minWidth: 150 },

  { id: 'JobTitle', label: 'Job Title' },
  { id: 'ResumeSaved', label: 'Resume Name' },

  {
    id: 'Created',
    label: 'Saved',
  },

  { id: 'Status', label: 'Status' },

  {
    id: 'ActionUse',
    label: 'Action Use',
    minWidth: 150,
  },
];

function createData(
  Company,
  JobTitle,
  ResumeSaved,
  Created,
  Status,
  ActionUse,
) {
  return {
    Company,
    JobTitle,
    ResumeSaved,
    Created,
    Status,
    ActionUse,
  };
}

function DashboardTable(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [resume, setResume] = React.useState('');
  const [jobdescription, setjobDescription] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');
  const [modalLarge, setModelLarge] = React.useState(false);
  const [openOkDialog, setopenOkDialog] = React.useState(false);
  const [fedexMessage, setfedexMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [oid, setOid] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [statusUpdateModel, setStatusUpdateModel] = React.useState(false);
  const [applyStatus, setApplyStatus] = React.useState('no');
  const [interviewStatus, setInterviewStatus] = React.useState('no');
  const [loader, setLoader] = React.useState(false);
  const [linkLoader, setLinkLoader] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState({});
  const [printModal, setPrintModal] = React.useState(false);
  const [printType, setPrintType] = React.useState('local');
  const [updateStatusLoader, setUpdateStatusLoader] = React.useState(false);
  const printLoader = false;
  const dispatch = useDispatch();
  const openPdf = (val, viewDownloadPage) => {
    if (val) {
      setLoader(false);
      setPrintModal(false);
      if (viewDownloadPage) {
        window.open(
          `${process.env.REACT_APP_WEB_URL}${val?.download}` ||
            props.pdf?.cloundinary,
          '_blank',
        );

        return;
      } else {
        window.open(
          `${process.env.REACT_APP_API_URL}/${val?.pdf}` ||
            props.pdf?.cloundinary,
          '_blank',
        );
      }
    }
    setLoader(false);
    setLinkLoader(false);
    props.setPdfStatusFalse();
  };
  const handleCloseOkDialog = () => {
    setopenOkDialog(false);
  };
  const generatePdf = async (id) => {
    setLoader(true);
    props.getPdf(
      {
        oid: id,
        is_scan: 1,
        temp_id: 'functional',
        token: '',
      },
      openPdf,
    );
  };

  const generatePdfbyLink = async (id) => {
    const viewDownloadPage = true;

    setLinkLoader(true);
    props.getPdf(
      {
        oid: id,
        is_scan: 1,
        temp_id: 'functional',
        token: '',
      },
      (val) => openPdf(val, viewDownloadPage),
    );
  };

  const handleClick = (event, items) => {
    setMenuItems(items);
    setAnchorEl(event.currentTarget);
  };

  const statusCloseModel = () => {
    setUpdateStatusLoader(false);
    setStatusUpdateModel(false);
  };
  const handleAppliedStatus = () => {
    let jobAppliedData = {
      isTrue: applyStatus,
      oid: oid,
      type: 'jobApplied',
    };
    let interviewData = {
      isTrue: interviewStatus,
      oid: oid,
      type: 'interview',
    };
    setUpdateStatusLoader(true);
    props.updateJobAppliedStatus(jobAppliedData);
    props.updateJobAppliedStatus(interviewData, statusCloseModel);
    setTimeout(() => {
      statusCloseModel();
    }, 3000);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenOkDialog = (open, message) => {
    setopenOkDialog(open);
    setfedexMessage(message);
  };

  const handleClickOpen = (id) => {
    handleMenuClose();
    setOpen(true);
    setOid(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteHistory = () => {
    props.deleteHistoryItem(oid);
    setOpen(false);
  };
  const toggleLarge = (resume, jobDescription, jobTitle) => {
    setResume(resume);
    setjobDescription(jobDescription);
    setJobTitle(jobTitle);
    setModelLarge(!modalLarge);
  };

  const handlePageClick = (data) => {
    setPage(data.selected);
  };

  const rows = props.data
    ?.sort((a, b) => {
      return new Date(b?.createdAt) - new Date(a?.createdAt);
    })
    ?.map((items) => {
      return createData(
        items.data.company,
        items.resume.MainJobTitle ? items.resume.MainJobTitle : 'No job title',
        items.resume.MainJobTitle
          ? `${items.resume.MainJobTitle}_${items.data.company}_${moment(
              items.data.createdAt,
            ).format('MMDDYY')}`?.replaceAll(' ', '')
          : items.data.resumeName
          ? `${items.data.resumeName}_${items.data.company}_${moment(
              items.data.createdAt,
            ).format('MMDDYY')}`?.replaceAll(' ', '')
          : `no_job_title_${items.data.company}_${moment(
              items.data.createdAt,
            ).format('MMDDYY')}`?.replaceAll(' ', ''),
        moment(items.createdAt).format('MMM DD, YYYY'),
        items?.data?.jobApplied === 'no'
          ? 'No Applied'
          : items?.data?.jobApplied === 'yes'
          ? 'Applied' +
            (items?.data?.isInterview === 'yes'
              ? ' - Interview'
              : ' - No Interview')
          : 'No Applied',
        <span style={styles.tableSpan}>
          <MoreVertIcon
            style={{ marginLeft: 10 }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => handleClick(event, items)}
            className="hovdel"
          />
        </span>,
      );
    });

  const reviewSummaryHandler = async () => {
    await localStorage.setItem('myScanId', menuItems?.oid);
    dispatch(SaveActiveIndex(4));
    props.history.push('/optimize');
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={printModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPrintModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            Print Options
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => {
              setPrintModal(false);
            }}
          />
        </div>
        <div
          style={{
            // width: 300,
            display: 'flex',
            flexDirection: 'column',
            // width: 500,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <span
            onClick={() => setPrintType('local')}
            style={{
              marginTop: 15,
              fontWeight: 'bold',
              border: printType === 'local' ? '1px solid #4267B2' : '',
              padding: 5,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <PrintIcon /> To Local Printer
          </span>
          <span
            onClick={() => setPrintType('staples')}
            style={{
              marginTop: 15,
              fontWeight: 'bold',
              border: printType === 'staples' ? '1px solid #4267B2' : '',
              padding: 5,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <StoreIcon /> Print to OfficeDepot or Staples (Any location)
          </span>
          <span
            onClick={() => setPrintType('fedex')}
            style={{
              marginTop: 15,
              fontWeight: 'bold',
              border: printType === 'fedex' ? '1px solid #4267B2' : '',
              padding: 5,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          >
            <img src={FedexIcon} width={30} height={15} /> Print to FedEx Office
            (Any location)
          </span>
        </div>
        <DialogActions>
          {loader || printLoader ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              onClick={() => {
                if (printType === 'local') {
                  generatePdf(menuItems.oid);
                  return;
                } else {
                  let data = {
                    is_scan: 1,
                    oid: menuItems.oid,
                    temp_id: '',
                    printType,
                  };
                  setPrintModal(false);
                  props.EmailParserForScanTables(data, handleClickOpenOkDialog);
                }
              }}
              color="primary"
              variant="contained"
            >
              Print
            </Button>
          )}
          {/* <Button
            onClick={() => {
              setPrintModal(false);
            }}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openOkDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseOkDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{fedexMessage}</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleCloseOkDialog}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
            paddingTop: '10px',
          }}
        >
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={handleClose}
          />
        </div>
        <DialogTitle style={{ ...styles.h3, padding: '0px 10px 0px 15px' }}>
          <div className="f-s-19">Are you sure, you want to delete?</div>
        </DialogTitle>
        <DialogActions>
          <ButtonR
            onClick={deleteHistory}
            style={{
              backgroundColor: colors.blue,
              color: colors.white,
              borderRadius: '3px',
              fontSize: '14px',
              margin: '0px 3px 6px 0px',
            }}
          >
            Delete
          </ButtonR>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={statusUpdateModel}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setStatusUpdateModel(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 23,
              fontFamily: 'Nunito',
            }}
          >
            Update Status
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={() => setStatusUpdateModel(false)}
          />
        </div>
        <DialogContent>
          <Select
            fullWidth
            style={{ marginBottom: '20px' }}
            value={applyStatus}
            onChange={(e) => setApplyStatus(e.target.value)}
            className="f-s-19"
          >
            <MenuItem value={'yes'}>Applied</MenuItem>
            <MenuItem value={'no'}>Not Applied</MenuItem>
          </Select>
          {applyStatus === 'yes' && (
            <Select
              fullWidth
              style={{ marginBottom: '10px' }}
              value={interviewStatus}
              onChange={(e) => setInterviewStatus(e.target.value)}
              className="f-s-19"
            >
              <MenuItem value={'yes'}>Interview</MenuItem>
              <MenuItem value={'no'}>No Interview</MenuItem>
            </Select>
          )}
        </DialogContent>
        <DialogActions>
          {updateStatusLoader ? (
            <Button>
              <CircularProgress />
            </Button>
          ) : (
            <Button
              onClick={handleAppliedStatus}
              style={{
                backgroundColor: colors.blue,
                color: colors.white,
                // marginRight: 5,
              }}
            >
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Modal isOpen={modalLarge} size="lg" toggle={toggleLarge}>
        <div
          id="alert-dialog-slide-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '20px 20px 0px',
          }}
        >
          <h5
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 20,
              fontFamily: 'Nunito',
            }}
          >
            {jobTitle}
          </h5>
          <CancelIcon
            style={{
              color: colors.red,
              cursor: 'pointer',
              fontSize: 24,
            }}
            onClick={toggleLarge}
          />
        </div>
        <ModalBody>
          <Row style={styles.row}>
            <Colxx
              lg={{
                size: 6,
              }}
              md={{
                size: 6,
              }}
              sm="12"
              xs="12"
            >
              <div>
                <h3 style={styles.h3}>JOB DESCRIPTION</h3>
                <textarea
                  className="scrollBar"
                  placeholder="Paste the entire job description text - Exclude the 'About company' section, but include the job title."
                  style={styles.textArea}
                  name="jobdescription"
                  value={jobdescription}
                />
              </div>
            </Colxx>
            <Colxx
              lg={{
                size: 6,
              }}
              md={{
                size: 6,
              }}
              sm="12"
              xs="12"
            >
              <div>
                <h3 style={styles.h3}>RESUME</h3>
                <textarea
                  className="scrollBar"
                  placeholder="Paste your resume"
                  style={styles.scrollBar_textArea}
                  name="resume"
                  value={resume}
                />
              </div>
            </Colxx>
          </Row>
        </ModalBody>
      </Modal>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="center"
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * 5, page * 5 + 5).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align="center"
                          style={{
                            borderBottom: 'none',
                          }}
                          className={
                            column.id === 'scanInformation'
                              ? 'scan_history'
                              : ''
                          }
                        >
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setOid(menuItems?.oid);
                    setAnchorEl(null);
                    setStatusUpdateModel(true);
                    setApplyStatus(menuItems?.data?.jobApplied || 'no');
                    setInterviewStatus(menuItems?.data?.isInterview || 'no');
                  }}
                >
                  Update Status
                </MenuItem>
                <MenuItem>
                  <Model
                    addNotesAction={props.addNotesAction}
                    oid={menuItems?.resume?.oid}
                    type="resume"
                    handleClose={handleMenuClose}
                  />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    generatePdfbyLink(menuItems.oid);
                  }}
                >
                  {linkLoader ? (
                    <Loader
                      style={{ alignSelfL: 'center', marginLeft: 10 }}
                      type="TailSpin"
                      color="#00BFFF"
                      height={15}
                      width={15}
                    />
                  ) : (
                    'Link'
                  )}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    setPrintModal(true);
                  }}
                >
                  Print
                </MenuItem>
                <MenuItem onClick={() => handleClickOpen(menuItems.oid)}>
                  Delete
                </MenuItem>
                <MenuItem onClick={() => generatePdf(menuItems.oid)}>
                  {loader ? (
                    <Loader
                      style={{ alignSelfL: 'center', marginLeft: 10 }}
                      type="TailSpin"
                      color="#00BFFF"
                      height={15}
                      width={15}
                    />
                  ) : (
                    'View PDF'
                  )}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    reviewSummaryHandler();
                    // props.history.push(
                    //   '/save-and-apply',
                    //   menuItems,
                    //   // isScan: true,
                    //   // data: SAMPLE_RESUME,
                    //   // oid: items.oid,
                    //   // temp: "applied",
                    // );
                  }}
                >
                  Review Summary
                </MenuItem>
                {/* <MenuItem>Save to Drive</MenuItem> */}
              </Menu>
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length >= 5 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            marginPagesDisplayed={2}
            pageCount={Math.ceil(rows?.length / 5)}
            pageRangeDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            previousLinkClassName={'pagination__link'}
            nextLinkClassName={'pagination__link'}
            disabledClassName={'pagination__link--disabled'}
            activeClassName={'pagination__link--active'}
            forcePage={page}
          />
        )}
        <div style={{ height: 2 }}></div>
      </Paper>
    </div>
  );
}
const mapStateToProps = ({ fileScanReducer, templateReducer }) => {
  return {
    fedexStatusfortable: fileScanReducer.fedexStatusfortable,
    pdf: templateReducer.pdfLink,
    getPdfStatus: templateReducer.getPdfStatus,
  };
};
export default connect(mapStateToProps, {
  deleteHistoryItem,
  EmailParserForScanTables,
  addNotesAction,
  getPdf,
  setPdfStatusFalse,
  updateJobAppliedStatus: updateQuestionsAction,
})(withRouter(DashboardTable));
