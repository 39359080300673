import React from 'react';
import { Text, Dnd } from '../../component';
import { useStyles } from '../style';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateOrder, addNewObj, deleteObjInArray } from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import { colors } from '../../../../commonComponents/colors';

export default function Publications(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'publications.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };
  //eslint-disable-next-line
  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };
  //eslint-disable-next-line
  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;
  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Publications
          </label>

        </div>
        <Dnd
          data={data}
          reorder={(e) => onOrderUpdate(e)}
          additem={_addNewItem}
          removeitem={(index) => _removeItem(index)}
          renderItem={(item, index) => (
            <Grid container>
              <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                <label className={classes.label}>Title</label>
                <Text
                  value={item.Title}
                  placeholder="Title"
                  customclass={classes.input}
                  path={`${path}.${index}.Title`}
                />
              </Grid>
              <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                <label className={classes.label}>Journal / SerialName</label>
                <Text
                  value={item.JournalOrSerialName}
                  placeholder="Journal / SerialName"
                  customclass={classes.input}
                  path={`${path}.${index}.JournalOrSerialName`}
                />
              </Grid>
              <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                <label className={classes.label}>Issue</label>
                <Text
                  value={item.Issue}
                  placeholder="Volume 1, Number 4"
                  customclass={classes.input}
                  path={`${path}.${index}.Issue`}
                />
              </Grid>
              {data.length > 1 && (
                <hr style={{ borderTop: 'dashed 1px #9E9E9E', marginTop: 20 }} />
              )}
            </Grid>
          )}
        />
      </Grid>
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more publication
      </div>
    </>
  );
}
