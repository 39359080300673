import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import { Popup } from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CreateIcon from '@material-ui/icons/Create';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateLinkedinData } from '../redux/actions';

import { useWindowSize } from '@react-hook/window-size';
const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40px',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};

export default function Patents(props) {
  const [width] = useWindowSize();
  const [copied, setCopied] = useState([]);
  const [title, setTitle] = useState('');
  const [fieldIndex, setIndex] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const linkedinData = useSelector(
    (state) => state.linkedinReducer.linkedinData,
  );
  const patentsModal = useSelector(
    (state) => state.linkedinReducer.patentsModal,
  );

  const cb = (v) => {
    dispatch({ type: 'PATENTS_MODAL', payload: false });
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Patents Updated');
    } else {
      toastr.error('Error Updating Patents');
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    if (Array.isArray(props.petData)) {
      let patents = props.petData;
      if (fieldIndex) {
        patents.splice(fieldIndex, 1, title);
      } else {
        patents.push(title);
      }
      const data = { ...linkedinData, patents };
      dispatch(updateLinkedinData(data, cb));
    } else {
      let patents = '';
      if (isEdit) {
        patents = [title];
        setIsEdit(false);
      } else {
        patents = props.petData ? [props.petData, title] : [title];
      }
      const data = { ...linkedinData, patents };
      dispatch(updateLinkedinData(data, cb));
    }
  };

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };

  return (
    <Card body className="text-left mb-4" style={styles.border_radius_5}>
      {Array.isArray(props.petData) ? (
        props.petData.map((item, index) => {
          return (
            <Row key={index} className="mt-2">
              <Col
                xs={10}
                lg={11}
                xl={11}
                md={11}
                className="border-bottom p-5"
              >
                <p style={styles.title}>{item}</p>
              </Col>
              <Col
                xs={2}
                lg={1}
                xl={1}
                md={1}
                className="border-bottom p-2 d-flex align-items-center justify-content-center"
              >
                <span
                  style={styles.copyIcon}
                  onClick={() => {
                    setTitle(item);
                    setIndex(index);
                    dispatch({ type: 'PATENTS_MODAL', payload: true });
                  }}
                >
                  {props.stepIndex === 1 ? (
                    <CreateIcon
                      style={styles.visibilityIcon}
                      className="hovdel"
                    />
                  ) : (
                    <FileCopyIcon
                      style={styles.visibilityIcon}
                      className="hovdel"
                    />
                  )}
                </span>
              </Col>
            </Row>
          );
        })
      ) : props.petData ? (
        <Row className="mt-2">
          <Col xs={10} lg={11} xl={11} md={11} className="border-bottom p-5">
            <p style={styles.title}>{props.petData}</p>
            {/* <p style={styles.sub}>{item?.issuer}</p>
        <p style={styles.years}>Date: {item?.date}</p> */}
          </Col>
          <Col
            xs={2}
            lg={1}
            xl={1}
            md={1}
            className="border-bottom p-2 d-flex align-items-center justify-content-center"
          >
            <span
              style={styles.copyIcon}
              onClick={() => {
                setTitle(props.petData);
                setIsEdit(true);
                dispatch({ type: 'PATENTS_MODAL', payload: true });
              }}
            >
              {props.stepIndex === 1 ? (
                <CreateIcon style={styles.visibilityIcon} className="hovdel" />
              ) : (
                <FileCopyIcon
                  style={styles.visibilityIcon}
                  className="hovdel"
                />
              )}
            </span>
          </Col>
        </Row>
      ) : (
        'NO DATA AVAILABLE'
      )}

      <Popup
        open={patentsModal}
        onClose={() => {
          clearCopied();
          setTitle('');
          setIndex();
          dispatch({ type: 'PATENTS_MODAL', payload: false });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({ type: 'PATENTS_MODAL', payload: false });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5>Patents</h5>

        <Row>
          <Col>
            <Label for="exampleEmail">Patent title</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(title) ? (
                    <CopyToClipboard
                      text={title}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(title);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={loading}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  {loading ? 'Saving' : 'Save'}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}
