import React from 'react';
import { Chip } from '@material-ui/core';
import { styles } from './MilitaryXWalkStyle';
import { GetStepImage } from '../../utils/GetStepImage';
import { HiSun } from 'react-icons/hi';

const Chips = ({
  items = [],
  type,
  onRemoveItem,
  onAddItem,
  selectedItems = [],
  heading,
  subtitle,
  emptyContent,
  stepImg,
  isEmptyShow,
  stepNo,
  width,
}) => {
  let data = [];
  if (type === 'string') {
    data = [
      ...selectedItems,
      ...items?.filter((item) => !selectedItems?.includes(item)),
    ];
  } else if (type === 'object') {
    data = [
      ...selectedItems,
      ...items?.filter((item) => selectedItems[0]?.subTitle !== item?.subTitle),
    ];
  }

  return data?.length > 0 && !isEmptyShow ? (
    <div style={styles.containerJobs(width)}>
      {stepImg && (
        <GetStepImage step={stepNo} style={{ marginBottom: -3, zIndex: 10 }} />
      )}
      <h4 style={{ fontSize: width <= 375 ? 17 : '1.5rem' }}>
        {heading && heading}
      </h4>
      <p>{subtitle && subtitle}</p>
      {data?.map((item, index) => {
        const isInclude = selectedItems[0]?.subTitle
          ? selectedItems[0]?.subTitle === item?.subTitle
          : selectedItems?.includes(item);

        const onDeleteHandle = isInclude
          ? {
              onDelete: () => onRemoveItem(item),
            }
          : {};

        return (
          <Chip
            label={item?.subTitle || item}
            onClick={(e) => {
              if (isInclude) e.preventDefault();
              else onAddItem(item);
            }}
            size={width < 400 ? 'small' : 'medium'}
            icon={
              item?.outlook === 'Bright Outlook' ? (
                <HiSun color="#FDB911" size={20} />
              ) : null
            }
            key={index}
            style={styles.chip(width)}
            color={isInclude ? 'primary' : ''}
            {...onDeleteHandle}
          />
        );
      })}
    </div>
  ) : (
    <div style={styles.noFound(width)}>{emptyContent && emptyContent}</div>
  );
};
export default Chips;
