import React from 'react';
import Util from '../../../../utils/templateUtils';
import { Text, Dnd } from '../../component';
import { useDispatch } from 'react-redux';
import { updateOrder, addNewObj, deleteObjInArray } from '../../redux/actions';

function Associations(props) {
  const dispatch = useDispatch();
  const path = 'associations.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');
    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };

  const { data } = props;

  const handledData = (() => {
    if (data?.length === 0) {
      return [{ Name: '' }];
    }
    return data;
  })();

  return (
    <div style={{ paddingTop: 10 }}>
      <Dnd
        data={handledData}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => {
          return (
            <Text
              customclass={'manager-fName'}
              value={item.Name}
              path={`${path}.${index}.Name`}
              placeholder="Name"
            />
          );
        }}
      />
    </div>
  );
}

export default Associations;
