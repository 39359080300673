import { actionTypes } from './constants';
import {
  updateObject,
  insertObj,
  deleteObj,
  updateObjectPush,
  deleteObjectPush,
} from '../../../utils/updateResumeObj';
import axios from 'axios';
import url from '../../../config/endpoint';
import uniqid from 'uniqid';
import objectPath from 'object-path';
import toastr from 'toastr';
import { sampleData } from './sample';
export const PDF_DATA = 'PDF_DATA';
export const PDF_DATA_ERROR = 'PDF_DATA_ERROR';

export const pdfUploadData = (file, cb, setError) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('pdfFile', file);
    const res = await axios.post(`${url}/update-resume-parse`, formData);
    const statusCode = res?.data?.status;

    if (statusCode === 200) {
      cb();
    } else {
      setError(res?.data?.message || 'Something went wrong');
      dispatch({
        type: PDF_DATA_ERROR,
      });
    }
  } catch (err) {
    setError(err?.response?.data?.message || 'Something went wrong');
    dispatch({
      type: PDF_DATA_ERROR,
    });
  }
};
export const handleAccordionAction = (panel, isExpanded) => (dispatch) => {
  dispatch({
    type: 'ACCORDION_CHANGE',
    payload: isExpanded ? panel : false,
  });
};
const randomId = () => {
  const currentTime = new Date().getTime();
  const randomNumber = Math.random() * 3;
  return `${currentTime}-${randomNumber}`;
};

export const updateUserData = (data) => {
  return {
    type: actionTypes.UPDATE_USER_DATA,
    payload: data,
  };
};

export const updateTheme = (data) => {
  return {
    type: actionTypes.UPDATE_THEME,
    payload: data,
  };
};

export const updateItemStatus = (data) => {
  return {
    type: actionTypes.UPDATE_ITEM_STATUS,
    payload: data,
  };
};

export const updateWorkExperience = (data) => {
  return {
    type: actionTypes.UPDATE_WORK_EXPERIENCE,
    payload: data,
  };
};

export const addNewWorkExperience = () => {
  const id = randomId();
  const data = {
    id,
    date: '',
    jobTitle: '',
    companyName: '',
    companyText: '',
    experienceText: '',
  };

  return {
    type: actionTypes.ADD_NEW_WORK_EXPERIENCE,
    payload: data,
  };
};

export const updateWorkExperienceData = (id, data) => {
  return {
    type: actionTypes.UPDATE_WORK_EXPERIENCE_DATA,
    payloadId: id,
    payload: data,
  };
};

export const deleteWorkExperienceData = (id) => {
  return {
    type: actionTypes.DELETE_WORK_EXPERIENCE_DATA,
    payload: id,
  };
};

export const addDeletedWorkExperienceItem = (data) => {
  return {
    type: actionTypes.ADD_DELETED_WORK_EXPERIENCE_ITEM,
    payload: data,
  };
};

export const addEducation = () => {
  const id = randomId();
  const data = {
    id,
    date: '',
    title: '',
  };

  return {
    type: actionTypes.ADD_NEW_EDUCATION,
    payload: data,
  };
};

export const updateEducation = (data) => {
  return {
    type: actionTypes.UPDATE_EDUCATION,
    payload: data,
  };
};

export const updateEducationData = (id, data) => {
  return {
    type: actionTypes.UPDATE_EDUCATION_DATA,
    payloadId: id,
    payload: data,
  };
};

export const deleteEducationData = (id) => {
  return {
    type: actionTypes.DELETE_EDUCATION_DATA,
    payload: id,
  };
};

export const addDeletedEducationItem = (data) => {
  return {
    type: actionTypes.ADD_DELETED_WORK_EDUCATION_ITEM,
    payload: data,
  };
};

export const addSkill = () => {
  const id = randomId();
  const data = {
    id,
    title: '',
  };

  return {
    type: actionTypes.ADD_NEW_SKILL,
    payload: data,
  };
};

export const updateSkill = (data) => {
  return {
    type: actionTypes.UPDATE_SKILL,
    payload: data,
  };
};

export const updateSkillData = (id, data) => {
  return {
    type: actionTypes.UPDATE_SKILL_DATA,
    payloadId: id,
    payload: data,
  };
};

export const deleteSkillData = (id) => {
  return {
    type: actionTypes.DELETE_SKILL_DATA,
    payload: id,
  };
};

export const addDeletedSkillItem = (data) => {
  return {
    type: actionTypes.ADD_DELETED_WORK_SKILL_ITEM,
    payload: data,
  };
};

export const exportUserData = () => {
  return (dispatch, getState) => {
    const userData = getState().userData;
    const workExperience = getState().workExperience;
    const education = getState().education;
    const skills = getState().skills;
    const theme = getState().theme;
    const itemStatus = getState().itemStatus;

    let data = [];
    data = {
      userData,
      workExperience,
      education,
      skills,
      theme,
      itemStatus,
    };

    return data;
  };
};

export const uploadImageAction = (image) => {
  return () =>
    new Promise(() => {
      const formData = new FormData();
      formData.append('image', image);
    });
};

export const onBlurField = (data, path) => (dispatch, getState) => {
  let resumeObj = getState().editorReducer.resumeData;
  let newObject = updateObject(resumeObj, path, data);
  dispatch({
    type: actionTypes.ON_BLUR_FILED,
    payload: newObject,
  });
};

export const onBlurFieldWithShift =
  (data, path, rootPath) => (dispatch, getState) => {
    let resumeObj = getState().editorReducer.resumeData;
    let newObject = updateObject(
      insertObj(resumeObj, rootPath, {}),
      path,
      data,
    );
    dispatch({
      type: actionTypes.ON_BLUR_FILED,
      payload: newObject,
    });
  };

export const resetBuilderResume =
  (userId, setLoader, cb) => async (dispatch) => {
    try {
      const res = await axios.post(`${url}/clean-current-resume`, {
        oid: userId,
      });

      if (res.data.status === 200) {
        dispatch({
          type: actionTypes.RESET_BUILD_RESUME,
        });
        localStorage.removeItem('builderVisited');
        localStorage.setItem('builderIndex', 0);

        if (cb) {
          cb();
        }
      } else {
        toastr.error(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      // toastr.error('Error', 'Something went wrong!');
    } finally {
      setLoader(false);
    }
  };

export const onBlurField2 = (data, path) => (dispatch, getState) => {
  let resumeObj = getState().editorReducer.resumeData;
  let summary = resumeObj.objective['body'];
  let newObject = updateObjectPush(resumeObj, path, summary, data);
  dispatch({
    type: actionTypes.ON_BLUR_FILED,
    payload: newObject,
  });
};
export const delField2 = (data, path) => (dispatch, getState) => {
  let resumeObj = getState().editorReducer.resumeData;
  let summary = resumeObj.objective['body'];
  let newSummary = summary
    .toLowerCase()
    .replace(`<li>${data}</li>`.toLowerCase(), ' ');
  let newObject = deleteObjectPush(resumeObj, path, newSummary);
  dispatch({
    type: actionTypes.ON_BLUR_FILED,
    payload: newObject,
  });
};
export const onBlurField1 =
  (data, path, title, expanded) => (dispatch, getState) => {
    let resumeObj = getState().editorReducer.resumeData;
    let summary =
      title === 'Experience'
        ? resumeObj?.work?.items?.map((item) => {
            return item?.summary;
          })
        : resumeObj?.certifications?.items?.map((item) => {
            return item?.summary;
          });
    let newObject = updateObjectPush(resumeObj, path, summary[expanded], data);
    dispatch({
      type: actionTypes.ON_BLUR_FILED,
      payload: newObject,
    });
  };
export const delField =
  (data, path, title, expanded) => (dispatch, getState) => {
    let resumeObj = getState().editorReducer.resumeData;
    let summary =
      title === 'Experience'
        ? resumeObj?.work?.items?.map((item) => {
            return item?.summary;
          })
        : resumeObj?.certifications?.items?.map((item) => {
            return item?.summary;
          });
    let newSummary = summary[expanded]?.replace(`<li>${data}</li>`, '');
    let newObject = deleteObjectPush(resumeObj, path, newSummary);
    dispatch({
      type: actionTypes.ON_BLUR_FILED,
      payload: newObject,
    });
  };
export const updateOrder = (data, path) => (dispatch, getState) => {
  let resumeObj = getState().editorReducer.resumeData;
  let newObject = updateObject(resumeObj, path, data);

  dispatch({
    type: actionTypes.ON_UPDATE_ORDER,
    payload: newObject,
  });
};
export const addNewObj = (old_data, path, isData) => (dispatch, getState) => {
  let resumeObj = getState().editorReducer.resumeData;
  const index_value = objectPath.get(resumeObj, path).length;

  let data;
  if (isData) data = old_data;
  else data = { ...objectPath.get(sampleData, path)[0] };

  let newObject = insertObj(resumeObj, path, data, index_value);

  dispatch({
    type: actionTypes.ON_ADD_OBJECT,
    payload: newObject,
  });
};

export const addNewSkillObj = (data, path) => (dispatch, getState) => {
  let resumeObj = getState().editorReducer.resumeData;
  const index_value = objectPath.get(resumeObj, path).length;
  let newObject = insertObj(resumeObj, path, data, index_value);
  dispatch({
    type: actionTypes.ON_ADD_OBJECT,
    payload: newObject,
  });
};
export const deleteObjInArray = (path) => (dispatch, getState) => {
  let resumeObj = getState().editorReducer.resumeData;

  let newObject = deleteObj(resumeObj, path);

  dispatch({
    type: actionTypes.DELETE_OBJECT,
    payload: newObject,
  });
};

export const clearStyles = () => {
  return {
    type: actionTypes.CLEAR_STYLES,
  };
};

export const changeTheme = (name) => {
  return {
    type: actionTypes.CHANGE_THEME,
    payload: name,
  };
};

export const changeNameHandler = (name) => {
  return {
    type: actionTypes.GET_RESUME_NAME,
    payload: name,
  };
};

export const setDefaultResume = (data, callback) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_RESUME_SCAN_STATUS',
      payload: 'loading',
    });

    let getToken = localStorage.getItem('clientAccessToken');

    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }
    const res = await axios.post(`${url}/set-default-resume`, data);
    if (res.data.status === 200) {
      const allBuildResumes = res?.data?.data || [];
      const currentResume = allBuildResumes?.find(
        (item) => item?.currentBuilderResume === true,
      );

      const allBuildResumesWithoutCurrentResume = allBuildResumes?.filter(
        (item) => item?.currentBuilderResume !== true,
      );
      dispatch({
        type: actionTypes.SET_ALL_BUILD_RESUMES,
        payload: allBuildResumesWithoutCurrentResume,
      });

      let res_data = {};

      if (!allBuildResumes?.length || !currentResume) {
        dispatch({
          type: actionTypes.NEW_BUILD_RESUME,
        });
        dispatch({
          type: 'GET_RESUME_SCAN_STATUS',
          payload: 'done',
        });
        dispatch({
          type: 'RESUME_EXIST',
          payload: false,
        });
        dispatch({
          type: 'GET_BUILDER_RESUME_DATA',
          payload: {},
        });

        localStorage.removeItem('builderResumeOid');
        res_data = sampleData;
      } else {
        res_data = currentResume?.data;
        res_data['skills']['items'] = res_data['skills']['items']?.map((n) => ({
          id: uniqid(),
          name: n,
        }));
        if (currentResume?.oid) {
          callback && callback(currentResume?.oid);
          localStorage.setItem('builderResumeOid', currentResume.oid);
        }

        dispatch({
          type: 'RESUME_EXIST',
          payload: true,
        });
        dispatch({
          type: 'GET_RESUME_SCAN',
          payload: res_data,
        });
        dispatch({
          type: 'SET_TEMPLATE_INDEX',
          payload: currentResume?.templateIndex,
        });
        dispatch({
          type: 'SELECTED_BUILDER_TITLE',
          payload: res_data?.profile?.jobTitle,
        });
        dispatch({
          type: 'GET_BUILDER_RESUME_DATA',
          payload: currentResume,
        });
        if (currentResume?.resumeName) {
          dispatch({
            type: actionTypes.GET_RESUME_NAME,
            payload: currentResume.resumeName,
          });
        }
        dispatch({
          type: 'GET_RESUME_SCAN_STATUS',
          payload: 'done',
        });
      }
    } else {
      dispatch({
        type: 'GET_RESUME_SCAN_STATUS',
        payload: 'not-done',
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_RESUME_SCAN_STATUS',
      payload: 'not-done',
    });
  }
};
export const getResumeScan = (data, noCurrent) => async (dispatch) => {
  try {
    let getToken = localStorage.getItem('clientAccessToken');

    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }
    const res = await axios.post(`${url}/get-resume-scan`, data);
    if (res.data.status === 200) {
      const allBuildResumes = res?.data?.data || [];
      const currentResume = allBuildResumes?.find(
        (item) => item?.currentBuilderResume === true,
      );

      const allBuildResumesWithoutCurrentResume = allBuildResumes?.filter(
        (item) => item?.currentBuilderResume !== true,
      );

      dispatch({
        type: actionTypes.SET_ALL_BUILD_RESUMES,
        payload: allBuildResumesWithoutCurrentResume,
      });

      let res_data = {};

      if (!allBuildResumes?.length || !currentResume) {
        dispatch({
          type: actionTypes.NEW_BUILD_RESUME,
        });
        dispatch({
          type: 'GET_RESUME_SCAN_STATUS',
          payload: 'done',
        });
        dispatch({
          type: 'RESUME_EXIST',
          payload: false,
        });
        dispatch({
          type: 'GET_BUILDER_RESUME_DATA',
          payload: {},
        });

        localStorage.removeItem('builderResumeOid');
        res_data = sampleData;
        if (noCurrent) {
          noCurrent();
        }
      } else {
        res_data = currentResume?.data;
        res_data['skills']['items'] = res_data['skills']['items']?.map((n) => ({
          id: uniqid(),
          name: n,
        }));
        if (currentResume?.oid) {
          localStorage.setItem('builderResumeOid', currentResume.oid);
        }

        dispatch({
          type: 'GET_RESUME_SCAN_STATUS',
          payload: 'done',
        });
        dispatch({
          type: 'RESUME_EXIST',
          payload: true,
        });
        dispatch({
          type: 'GET_RESUME_SCAN',
          payload: res_data,
        });
        dispatch({
          type: 'SET_TEMPLATE_INDEX',
          payload: currentResume?.templateIndex,
        });
        dispatch({
          type: 'SELECTED_BUILDER_TITLE',
          payload: res_data?.profile?.jobTitle,
        });
        dispatch({
          type: 'GET_BUILDER_RESUME_DATA',
          payload: currentResume,
        });
        if (currentResume?.resumeName) {
          dispatch({
            type: actionTypes.GET_RESUME_NAME,
            payload: currentResume.resumeName,
          });
        }
      }
    } else {
      dispatch({
        type: 'GET_RESUME_SCAN_STATUS',
        payload: 'not-done',
      });
    }
  } catch (error) {
    dispatch({
      type: 'GET_RESUME_SCAN_STATUS',
      payload: 'not-done',
    });
  }
};

export const addExpSummary = (data) => (dispatch) => {
  dispatch({
    type: 'SAVE_SUMMARY',
    payload: data,
  });
};
export const onAddSummaryHandler = (data) => {
  return {
    type: 'ADD_SUMMARY',
    payload: data,
  };
};
