import React, { useState, memo, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import './style.css';
import {
  fileParser,
  AnalyzParsedResume,
  updateResumeTextAction,
  downloadResumePdf,
} from '../../commonRedux/scan/action';
import { connect, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import '../save&rescan-modal/style.css';
import { WordEditor } from '../../commonComponents/syncFusion/index';
import moment from 'moment';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const CustomEditor = memo(
  ({
    oid,
    step,
    jobTitle,
    editorJson,
    companyName,
    fontFamilies,
    defaultText = '',
    disableEditing,
  }) => {
    const { editorLoader } = useSelector((state) => state.fileScanReducer);
    const [content, setContent] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setContent(false);
      }, 1000);
    }, []);

    const [scrollPosition, setScrollPosition] = useState({ top: 0, left: 0 });
    const handleScroll = () => {
      const Yposition = window.pageYOffset;
      const Xposition = window.pageXOffset;
      setScrollPosition({ top: Yposition, left: Xposition });
    };

    useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    useEffect(() => {
      window.scrollTo({
        top: scrollPosition.top,
        left: scrollPosition.left,
        behavior: 'auto',
      });
    }, [editorLoader]);
    return (
      <center>
        {editorLoader || content ? (
          <div
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.07)',
            }}
          >
            <BeatLoader
              className="loader"
              css={override}
              sizeUnit={'px'}
              size={20}
              color={'#D5641C'}
              loading={true}
            />
          </div>
        ) : (
          <WordEditor
            disableEditing={disableEditing}
            step={step}
            data={editorJson || defaultText}
            oid={oid}
            isMobile={false}
            fontFamilies={fontFamilies}
            resumeName={`${jobTitle}_${companyName}_${moment(new Date()).format(
              'MMDDYY',
            )}`?.replaceAll(' ', '')}
          />
        )}
      </center>
    );
  },
);

const ModalRescan = memo((props) => {
  const [desc, setDesc] = React.useState('');
  React.useEffect(() => {
    if (props.description.resume) {
      let resumeDiscription = props.description.resume.replace(
        /<img[^>]*>/g,
        '',
      );
      setDesc(resumeDiscription);
    }
  }, []);

  return (
    <div className="rescan-main-container">
      <Row>
        <Col
          lg="12"
          style={{ width: '100%', padding: 0, position: 'relative' }}
        >
          {props.getChance && props.getChance}
          <CustomEditor
            disableEditing={props.disableEditing}
            step={props.step}
            oid={props.oid}
            defaultText={desc}
            editorJson={props.resume?.editorJson}
            fontFamilies={props.resume?.fontFamily}
            jobTitle={
              props.scanResult?.ats?.ats_data?.job_title_matched?.requires
            }
            companyName={
              props.scanResult?.ats?.ats_data?.job_level_match?.EmployerNames
            }
          />
          {props.updateResumeTextStatus || props.parsedAnalyzStatus ? (
            <div className="editor-saving-mode">
              <h3 className="editor-saving-mode-text">
                {props.updateResumeTextStatus
                  ? 'Loading...'
                  : 'Updating & ReScanning...'}
              </h3>
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
});

const mapStateToProps = ({ fileScanReducer }) => {
  return {
    resumeDescription: fileScanReducer.parsefile,
    parsedAnalyzStatus: fileScanReducer.parsedAnalyzStatus,
    updateResumeStatus: fileScanReducer.updateResumeStatus,
    resume: fileScanReducer.resume,
    scanResult: fileScanReducer.scanResult,
    updateResumeTextStatus: fileScanReducer.updateResumeTextStatus,
  };
};

export default connect(mapStateToProps, {
  fileParser,
  AnalyzParsedResume,
  updateResumeTextAction,
  downloadResumePdf,
})(ModalRescan);
