import React, { useEffect } from "react";
import { Col } from "reactstrap";
import { styles } from "./helpStyles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import { Button } from "reactstrap";
import { useInviteFriends } from "./useInviteFriends";
import { colors } from "../../commonComponents/colors";
import countryMobileCode from "../../constants/phoneCode.json"
import { useWindowWidth } from "@react-hook/window-size";


export default function InviteFriends(props) {
  const {
    loading,
    setEmails,
    phoneNumbers,
    setPhoneNumbers,
    sendInvite,
  } = useInviteFriends();
  const { closeModal } = props;
  const [countryPhoneCode, setCountryPhoneCode] = React.useState("0");
const width = useWindowWidth()


  const getData = async () => {

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch("https://geolocation-db.com/json/", requestOptions)
      .then(response => response.json())
      .then((result) => {
        fetch(`https://ipinfo.io/${result.IPv4}?token=d20a378d34ca3d`)
          .then(response => response.json())
          .then((result) => {
            setCountryPhoneCode(`+${countryMobileCode[result.country]}`)
          }
          )
          .catch(error => {});
      })
      .catch(error => {});


  }

  useEffect(() => {
    getData()


  }, []);
  const closeHandler = () => {
    sendInvite(closeModal)
   
  }
  return (
    <Col
      style={{ backgroundColor: "white", padding: 30 }}
      className="text-center"
    >
      <div className="section-title">
        <h3 style={{ fontFamily: "Montserrat" }} className="main-title">
          Invite Friends
        </h3>
        <p
          style={{ fontFamily: "Nunito", fontSize: 18 }}
          className="text-muted para-desc mb-0 mx-auto"
        >
          {/* (Get $5 gift card if your friend(s) signup for a paid plan.) */}
        </p>
        <TextField
          InputProps={{
            startAdornment:
              <InputAdornment position="start">
                <EmailIcon style={{ color: "#4267B2" }} />
              </InputAdornment>
            ,
          }}
          style={{ marginTop: 10, width: "100%" }}
          id="filled-helperText"
          label="Email Addresses"
          placeholder={"Enter Email Addresses seperated by commas"}
          onChange={(e) => setEmails(e.target.value)}
          variant="outlined"
        />
      </div>
      <div className="section-title" style={styles.pb_10}>
        <TextField
          InputProps={{
            startAdornment:
              <InputAdornment position="start">
                <PhoneIcon style={{ color: "#4267B2" }} />
              </InputAdornment>
            ,
          }}
          style={{ marginTop: 10, width: "100%" }}
          id="filled-helperText"
          label="Phone Numbers"
          placeholder={
            `Enter Phone Numbers seperated by commas (e.g. ${countryPhoneCode} 555 555 5555, ${countryPhoneCode} 555 555 5555)`
          }
                      defaultValue={phoneNumbers}
          onChange={(e) => setPhoneNumbers(e.target.value)}
          variant="outlined"
        />
      </div>

      <div className="section-title" style={styles.pb_10}>
        <Button
          disabled={loading}
          onClick={() => {
            closeHandler()
          }}
          style={{
            marginTop: '10px',
            width: width <500?'100%': "30%",
            color: colors.white,
            backgroundColor: colors.blue
          }}
          color={colors.blue}
        >
          {loading ? "Sending" : "Send Invites"}
        </Button>
      </div>
    </Col>
  );
}
