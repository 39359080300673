import React, { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import mainLogoImage from '../../../assets/H.webp';
import { Link as Lk } from 'react-scroll';
import { withRouter } from 'react-router';
import { useFooter } from './useFooter';
import ios from '../../../assets/landingPage/ios.webp';
import qri from '../../../assets/landingPage/android.png';
import iosqr from '../../../assets/landingPage/ios.png';
import android from '../../../assets/landingPage/android.webp';
const Footer = memo((props) => {
  const [Contactvisible, setContactvisible] = useState(false);
  const {
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    sendMessage,
    loading,
  } = useFooter();

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMessage();
  };

  const checkingforLink = (routeName, offestV) => {
    if (props.location.pathname !== '/') {
      return (
        <Link
          className="text-foot"
          to={{
            pathname: '/',
            query: { route: { routeName, offestV } },
          }}
        >
          <i className="mdi mdi-chevron-right mr-1"></i>
          {routeName.charAt(0).toUpperCase() + routeName.slice(1)}
        </Link>
      );
    } else {
      return (
        <Lk
          to={routeName}
          spy={true}
          smooth={true}
          duration={500}
          activeClass="text-foot"
          offset={offestV}
        >
          <i className="mdi mdi-chevron-right mr-1"></i>
          {routeName.charAt(0).toUpperCase() + routeName.slice(1)}
        </Lk>
      );
    }
  };

  return (
    <>
      {!props.removeContent && (
        <>
          <style jsx="true">
            {`
              .fb-Icon:hover {
                background-color: #4267b2;
              }
              .tw-Icon:hover {
                background-color: #00acee;
              }
              .insta-Icon:hover {
                background-color: #833ab4;
              }
            `}
          </style>
          <footer className="footer">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                  <Link className="logo-footer" to="#">
                    <img
                      src={mainLogoImage}
                      className="logof img-fluid  d-block"
                      alt=""
                      height="35"
                      width="110"
                      style={{ marginTop: 15 }}
                    />
                  </Link>
                  <p className="mt-4">
                    Resumás is an innovative application accessible on web and
                    mobile platforms, dedicated to enhancing resumes and
                    accelerating job seekers' interview prospects. Our job
                    seeker’s platform allows customization of your resume per
                    each job, or even creation of a new resume from scratch.
                    Utilize Resumás to augment your LinkedIn profile visibility
                    via optimized content, making it effortless for recruiters
                    to find you. You will also have access to daily local job
                    listings. Additionally, our unique “Military Crosswalk”
                    feature simplifies exploring civilian job roles for military
                    personnel and veterans using MOS & MOC codes. Resumás equips
                    you with the necessary tools to achieve success in your job
                    search.
                  </p>
                  <ul className="list-unstyled social-icon social mb-0 mt-4">
                    <li className="list-inline-item">
                      <a
                        href="https://web.facebook.com/resumasapp/"
                        className="rounded mr-1 fb-Icon"
                      >
                        <i className="mdi mdi-facebook" title="Facebook"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://www.linkedin.com/company/resumas/"
                        className="rounded tw-Icon"
                      >
                        <i className="mdi mdi-linkedin" title="LinkedIn"></i>
                      </a>
                    </li>
                  </ul>
                  <div
                    style={{
                      marginTop: '-30px',
                      marginLeft: ' 50px',
                    }}
                  >
                    <div
                      style={{
                        margin: '8px 0px 0px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <a href="https://play.google.com/store/apps/details?id=com.resumas">
                        <img
                          style={{
                            width: '108px',
                            height: '40px',
                            margin: '0px',
                            cursor: 'pointer',
                          }}
                          loading="lazy"
                          src={android}
                          alt="download button"
                        />
                      </a>
                      <a href="https://apps.apple.com/us/app/resumas/id1640272499">
                        <img
                          style={{
                            width: '108px',
                            height: '40px',
                            cursor: 'pointer',
                            margin: '0px -20px 0px 10px',
                          }}
                          loading="lazy"
                          src={ios}
                          alt="download button"
                        />
                      </a>
                    </div>
                    <div
                      style={{
                        margin: '8px 0px 0px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{
                          marginLeft: '7px',
                          width: '100px',
                          height: '100px',
                        }}
                        loading="lazy"
                        src={qri}
                        alt="QR code"
                      />
                      <img
                        style={{
                          margin: '0px -15px 0px 20px',
                          width: '100px',
                          height: '100px',
                        }}
                        loading="lazy"
                        src={iosqr}
                        alt="QR code"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h4 className="text-light footer-head">Support</h4>
                  <ul className="list-unstyled footer-list mt-4">
                    <li>
                      <Link to="/signup" className="text-foot">
                        <i className="mdi mdi-chevron-right mr-1"></i>SignUp
                      </Link>
                    </li>
                    <li>
                      <Link to="/login" className="text-foot">
                        <i className="mdi mdi-chevron-right mr-1"></i> Login
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://resumas.com/#pricing"
                        className="text-foot"
                      >
                        <i className="mdi mdi-chevron-right mr-1"></i> Pricing
                      </a>
                    </li>{' '}
                    <li>
                      <a
                        href="https://resumas.com/#features"
                        className="text-foot"
                      >
                        <i className="mdi mdi-chevron-right mr-1"></i> Features
                      </a>
                    </li>
                    <li>
                      <Link to="/faq" className="text-foot">
                        <i className="mdi mdi-chevron-right mr-1"></i> FAQ
                      </Link>
                    </li>
                    <li>
                      <Link to="/milXwalk" className="text-foot">
                        <i className="mdi mdi-chevron-right mr-1"></i> milXwalk
                      </Link>
                    </li>
                    <li>
                      <Link to="/employerSignup" className="text-foot">
                        <i className="mdi mdi-chevron-right mr-1"></i>Employers
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://resumas.com/#partners"
                        className="text-foot"
                      >
                        <i className="mdi mdi-chevron-right mr-1"></i> Partners
                      </a>
                    </li>
                    <li>
                      <a href="https://resumas.com/#blog" className="text-foot">
                        <i className="mdi mdi-chevron-right mr-1"></i> Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://resumas.com/alternative-to-jobscan/"
                        className="text-foot"
                      >
                        <i className="mdi mdi-chevron-right mr-1"></i> Compare
                        to Jobscan
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <h4 className="text-light footer-head">Contact Us</h4>

                  <div className="container ">
                    <Row className="align-items-center">
                      <Col lg={12} md={12} className=" order-2 order-md-1">
                        <div className="mt-2">
                          <div id="message"></div>
                          <Alert
                            color="info"
                            isOpen={Contactvisible}
                            toggle={() => setContactvisible(!Contactvisible)}
                          >
                            Contact details send successfully.
                          </Alert>
                          <form
                            method="post"
                            onSubmit={handleSubmit}
                            name="contact-form"
                            id="contact-form"
                          >
                            <Row>
                              <Col lg={6}>
                                <div className="form-group position-relative">
                                  <label>
                                    Your Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <i className="mdi mdi-account ml-3 icons"></i>
                                  <input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    name="name"
                                    id="name"
                                    type="text"
                                    className="form-control pl-5"
                                    placeholder="First and Last Name :"
                                    required
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="form-group position-relative">
                                  <label>
                                    Your Email
                                    <span className="text-danger">*</span>
                                  </label>
                                  <i className="mdi mdi-email ml-3 icons"></i>
                                  <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name="email"
                                    id="email"
                                    type="email"
                                    className="form-control pl-5"
                                    placeholder="Your Email :"
                                    required
                                  />
                                </div>
                              </Col>
                              <Col lg={12}>
                                <div className="form-group position-relative">
                                  <label>Comments</label>
                                  <i className="mdi mdi-comment-text-outline ml-3 icons"></i>
                                  <textarea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    name="comments"
                                    id="comments"
                                    rows="4"
                                    className="form-control pl-5"
                                    placeholder="Your Message :"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <div className="col-sm-12 text-center">
                                <input
                                  type="submit"
                                  id="submit"
                                  name="send"
                                  className="submitBnt btn btn-primary btn-block"
                                  value={loading ? 'Sending' : 'Send Message'}
                                />
                                <div id="simple-msg"></div>
                              </div>
                            </Row>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <hr />
        </>
      )}
      <footer className="lastfoot footer footer-bar">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-sm-6 col-lg-8">
              <div className="text-muted text-sm-left">
                <p className="mb-0 text-link">
                  Resumás, MatchScore and "Job Seeker's Platform" are registered
                  trademarks of WorkScaler Inc.
                </p>
                <a
                  href="https://resumas.com/third-party/"
                  className="text-link"
                >
                  <p>
                    © {new Date().getFullYear()} WorkScaler inc. | All third
                    party trademarks are the property of their respective
                    owners.
                  </p>
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <ul className="list-unstyled payment-cards text-sm-right mb-0">
                <a
                  href="https://resumas.com/california-privacy-rights/"
                  className="text-foot"
                >
                  California Privacy Rights
                </a>{' '}
                |{' '}
                <a
                  href="https://resumas.com/privacy-policy/"
                  className="text-foot"
                >
                  Privacy Policy
                </a>{' '}
                |{' '}
                <a
                  href="https://resumas.com/terms-services/"
                  className="text-foot"
                >
                  Terms of Services
                </a>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
});
Footer.displayName = 'Footer';

export default withRouter(Footer);
