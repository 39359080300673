import React, { useState } from 'react';
import { Text, Dnd } from '../../component';
import { Grid } from '@material-ui/core';
//eslint-disable-next-line
import { useStyles } from '../style';
import { useDispatch } from 'react-redux';
import Accordian from '../helper/Accordian';
import { updateOrder, addNewObj, deleteObjInArray } from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import InputText from '../../component/inputText';
import { colors } from '../../../../commonComponents/colors';
import moment from 'moment';

export default function Courses(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'courses.items';
  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');

    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;
  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Courses
          </label>
        </div>
      </Grid>
      <Dnd
        data={data}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <Accordian
            defaultExpanded={false}
            summary={
              <>
                {' '}
                <label
                  className={
                    item?.course || item?.institution
                      ? classes.summaryLabel
                      : classes.summaryLabelGrey
                  }
                >
                  {item?.course || item?.institution
                    ? item?.course
                    : 'Add Course Details'}{' '}
                  {'\n '}
                </label>
                <br />
                <label className={classes.summarySubLabel}>
                  {/* {item.startDate &&
                    moment(item.startDate).format('MMMM YYYY') + ' - '}{' '}
                  {moment(item.endDate).format('MMMM YYYY')} */}
                  {item.startDate &&
                    moment(item.startDate, 'MMMM YYYY').format('MMMM YYYY') +
                    ' - '}{' '}
                </label>
                <label className={classes.summarySubLabel}>
                  {item?.endDate &&
                    moment(item?.endDate, 'MMMM YYYY').format('MMMM YYYY')}{' '}
                </label>
              </>
            }
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div>
                  <label className={classes.label}>Course</label>
                  <Text
                    value={item.course}
                    placeholder="Course"
                    customclass={classes.input}
                    path={`${path}.${index}.course`}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div>
                  <label className={classes.label}>Institution</label>
                  <Text
                    value={item.institution}
                    placeholder="Institution"
                    customclass={classes.input}
                    path={`${path}.${index}.institution`}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{ marginTop: -10 }}
              >
                {/* <label className={classes.label}>Start & End Date</label> */}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div>
                      <label className={classes.label}>Start Date</label>
                      <InputText
                        type="month"
                        value={item?.startDate ?? ''}
                        placeholder="MM / YYYY"
                        customclass={classes.input}
                        path={`${path}.${index}.startDate`}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div>
                      <label className={classes.label}>End Date</label>
                      <InputText
                        type="month"
                        value={item?.endDate ?? ''}
                        placeholder="MM / YYYY"
                        customclass={classes.input}
                        path={`${path}.${index}.endDate`}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Accordian>
        )}
      />
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more course
      </div>
    </>
  );
}
