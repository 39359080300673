import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { updateLinkedinData } from '../redux/actions';

import { useWindowSize } from '@react-hook/window-size';

export default function TestScores(props) {
  const [width] = useWindowSize();
  const [title, setTitle] = useState('');
  const [titleId, setTitleId] = useState('');
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState([]);
  const linkedinData = props.linkedinData;

  const dispatch = useDispatch();
  const testsModal = useSelector((state) => state.linkedinReducer.testsModal);
  const testsModalMode = useSelector(
    (state) => state.linkedinReducer.testsModalMode,
  );
  useEffect(() => {
    if (testsModalMode === 'new') {
      setTitle('');
    }
  }, [testsModalMode]);
  const cb = (v) => {
    dispatch({
      type: 'TESTS_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      setLoading(false);
      toastr.success('Test Score Updated');
    } else {
      toastr.error('Error Updating Test logic');
    }
  };
  const handleUpdate = () => {
    
    if(title === ''){
      toastr.error('Please enter score');
      return;
    }
    setLoading(true);
    if (testsModalMode === 'new') {
      dispatch(
        updateLinkedinData(
          { ...linkedinData, resume_score: [...linkedinData?.resume_score, {id:uuid(), item:title}] },
          cb,
        ),
      );

    } else {
      dispatch(
        updateLinkedinData(
          {
            ...linkedinData,
            resume_score: linkedinData?.resume_score?.map((item) => {
              if (item?.id === titleId) {
                return {id:titleId, item:title};
              }
              return item;
            }),
          },
          cb,
        ),
      );
    }
    

  };

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };

  return (
    <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
      {
        Array.isArray(linkedinData?.resume_score) ? 
        linkedinData?.resume_score.map((data)=>{
          return(
            <Row key={true} className="mt-2">
          <Col xs={10} lg={11} xl={11} md={11} className="border-bottom p-5">
            {data.item}
          </Col>
          <Col
            xs={2}
            lg={1}
            xl={1}
            md={1}
            className="border-bottom p-2 d-flex align-items-center justify-content-center"
          >
            <span
              style={styles.copyIcon}
              onClick={() => {
                setTitle(data.item);
                setTitleId(data.id);
                dispatch({
                  type: 'TESTS_MODAL',
                  payload: {
                    open: true,
                    mode: 'edit',
                  },
                });
              }}
            >
              {props.stepIndex === 1 ? (
                <CreateIcon style={styles.visibilityIcon} className="hovel" />
              ) : (
                <FileCopyIcon
                  style={styles.visibilityIcon}
                  className="hovdel"
                />
              )}
            </span>
          </Col>
        </Row>
          )
        })
        :
        "NO DATA AVAILABLE"
      }
      <Popup
        open={testsModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'TESTS_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'TESTS_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5>Test Scores</h5>

        <Row>
          <Col>
            <Label for="exampleEmail">Test Score</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />{' '}
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(title) ? (
                    <CopyToClipboard
                      text={title}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(title);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={false}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  Save
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};
