// React Basic and Bootstrap
import React, { memo, useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';

//import images
import Image1 from '../../assets/landingPage/image-1.webp';
import Image4 from '../../assets/landingPage/link1.webp';
import Image5 from '../../assets/landingPage/image-5.webp';
import Image6 from '../../assets/landingPage/image-6.webp';
import roundWhite from '../../assets/landingPage/round-white.webp';
import { styles } from './style';
import './index.css';

export default memo(() => {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <section className="section pt-0 bg-light">
        <div className="container  mt-60">
          <Row className="justify-content-center">
            <Col className="text-center">
              <div className="section-title mb-60">
                <h4
                  style={{ fontFamily: 'Montserrat' }}
                  className="main-title mb-4"
                >
                  How can Resumás help in my job search?
                </h4>
                <p
                  style={{ fontFamily: 'Nunito' }}
                  className="text-muted para-desc mb-0 mx-auto"
                >
                  Our AI-based platform ensures you get MORE interviews. Resume
                  optimization, resume tracking, and job applications are all
                  included. Search local jobs and pick up printed resume
                  locally.
                </p>

                <Row className="mt-4 justify-content-center">
                  <Col
                    lg={12}
                    md={12}
                    className="text-center"
                    style={styles.mt_30}
                  >
                    <Nav
                      pills
                      id="navnav"
                      className="nav-justified flex-column flex-sm-row text-center align-items-center justify-content-center"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === '1',
                          })}
                          onClick={() => {
                            toggle('1');
                          }}
                        >
                          <div className="jobSearchSectionButton">
                            <h6
                              style={{
                                marginTop: 5,
                                textAlign: 'center',
                                width: '100%',
                              }}
                            >
                              Optimize Resume
                            </h6>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === '2',
                          })}
                          onClick={() => {
                            toggle('2');
                          }}
                        >
                          <div className="jobSearchSectionButton">
                            <h6
                              style={{
                                marginTop: 5,
                                textAlign: 'center',
                                width: '100%',
                              }}
                              //  className="title font-weight-normal mb-0"
                            >
                              Optimize LinkedIn Profile
                            </h6>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === '3',
                          })}
                          onClick={() => {
                            toggle('3');
                          }}
                        >
                          <div className="jobSearchSectionButton">
                            <h6
                              style={{
                                marginTop: 5,
                                textAlign: 'center',
                                width: '100%',
                              }}
                              //  className="title font-weight-normal mb-0"
                            >
                              Job Search
                            </h6>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === '4',
                          })}
                          onClick={() => {
                            toggle('4');
                          }}
                        >
                          <div className="jobSearchSectionButton">
                            <h6
                              style={{
                                marginTop: 5,
                                textAlign: 'center',
                                width: '100%',
                              }}
                              //  className="title font-weight-normal mb-0"
                            >
                              Resume Builder
                            </h6>
                          </div>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col md={12} lg={6}>
                      <img
                        src={Image1}
                        style={{ width: '70%' }}
                        className="img-fluid mx-auto d-block"
                        alt=""
                      />
                    </Col>

                    <Col md={12} lg={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4">
                          <i className="mdi mdi-chevron-double-right text-primary"></i>
                          Resume Optimization
                        </h4>
                        <p className="text-muted">
                          When you apply for a job, the first review of your
                          application is performed by an Applicant Tracking
                          Systems (ATS), which determines which applicants get
                          interview based on how well your resume matches
                          keywords included in the job description. Our platform
                          ensures that your resume includes the keywords and
                          phrases the ATS will be looking for.
                        </p>
                        <div className="d-flex flex-column text-muted">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Review and keyword optimization.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Calculate MatchScore<sup>TM</sup>&nbsp;to improve
                            resume.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Tailor resume to the job description.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Improve Key Sections: Education, Experience &
                            Skills.
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col md={6}>
                      <img
                        src={Image4}
                        className="img-fluid mx-auto d-block"
                        alt=""
                      />
                    </Col>

                    <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4">
                          <i className="mdi mdi-chevron-double-right text-primary"></i>
                          Linkedln Profile Optimization
                        </h4>
                        <p className="text-muted">
                          LinkedIn is a great way for job seekers to find jobs
                          and get ahead in their career. It is where more than
                          87% of job recruiters and HR talent scouts are looking
                          for candidates. LinkedIn is a useful tool for your job
                          search and it's important to make sure you create a
                          profile that will draw in potential employers.
                        </p>
                        <div className="d-flex flex-column text-muted">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Increase your visibility on LinkedIn.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Show up in Recruiter searches easily.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Optimize keywords in your LinkedIn profile.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Get hired by companies you wouldn't normally have
                            reached.
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col md={6}>
                      <img
                        src={Image5}
                        className="img-fluid mx-auto d-block"
                        alt=""
                      />
                    </Col>
                    <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4">
                          <i className="mdi mdi-chevron-double-right text-primary"></i>
                          Job Search
                        </h4>
                        <p className="text-muted">
                          The Job Search lists local jobs openings by comparing
                          your skills with the job descriptions for job openings
                          in your area. We source these jobs through
                          ZipRecruiter API.
                        </p>
                        <div className="d-flex flex-column text-muted">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Personalized job listing results in your area based
                            on skills.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Apply directly from the list.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Alerts for new job openings.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Dynamics search parameters and preferences.
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col md={6}>
                      <img
                        src={Image6}
                        className="img-fluid mx-auto d-block"
                        alt=""
                      />
                    </Col>

                    <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4">
                          <i className="mdi mdi-chevron-double-right text-primary"></i>
                          Build Resume In Minutes
                        </h4>
                        <p className="text-muted">
                          Our easy-to-use online resume builder puts you on the
                          professional fast track & helps you build a powerful
                          resume from scratch.
                        </p>
                        <div className="d-flex flex-column text-muted">
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Build resume from scratch in minutes.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            All sections needed for Applicant Tracking Systems.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            View, save and export your finished resume.
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-checkbox-marked-circle text-success h4 mr-2"></i>
                            Print & pick up at FedEx &#174; and OfficeDepo
                            &#174; locations.
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
        <div className="container-fluid">
          <Row>
            <div className="home-shape-bottom">
              <img
                src={roundWhite}
                alt=""
                className="img-fluid mx-auto d-block"
              />
            </div>
          </Row>
        </div>
      </section>
    </>
  );
});
