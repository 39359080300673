import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import toastr from 'toastr';
import CheckIcon from '@material-ui/icons/Check';
import { updateLinkedinData } from '../redux/actions';
import Popup from 'reactjs-popup';
import uuid from 'react-uuid';
import 'reactjs-popup/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';

import { useWindowSize } from '@react-hook/window-size';
import moment from 'moment';

export default function Volunteer(props) {
  const [width] = useWindowSize();
  const [organization, setOrganization] = useState('');
  const [volunteerId, setVolunteerId] = useState('');
  const [role, setRole] = useState('');
  const [cause, setCause] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  const [copied, setCopied] = useState([]);

  const dispatch = useDispatch();
  const linkedinData = props.linkedinData;
  const volunterrModal = useSelector(
    (state) => state.linkedinReducer.volunteerModal,
  );
  const volunteerModalMode = useSelector(
    (state) => state.linkedinReducer.volunteerModalMode,
  );
  useEffect(() => {
    if (volunteerModalMode === 'new') {
      setCause('');
      setStartDate('');
      setEndDate('');
      setDescription('');
      setOrganization('');
      setRole('');
    }
  }, [volunteerModalMode]);


  const cb = (v) => {
    dispatch({
      type: 'VOLUNTEER_MODAL',
      payload: {
        open: false,
        mode: 'edit',
      },
    });
    if (v === 'OK') {
      toastr.success('Volunteer Updated');
    } else {
      toastr.error('Error Updating Volunteer');
    }
  };

  const handleUpdate = () => {
    if (volunteerModalMode === 'new') {
      const newVolunteer = {
        cause,
        startDate,
        endDate,
        description,
        organization,
        role,
        id: uuid(),
      };
      const newLinkedinData = {
        ...linkedinData,
        VolunteerExperience: [...linkedinData?.VolunteerExperience, newVolunteer],
      };
      dispatch(updateLinkedinData(newLinkedinData, cb));
    } else {
      const newLinkedinData = {
        ...linkedinData,
        VolunteerExperience: linkedinData?.VolunteerExperience.map((volunteer) => {
          if (volunteer.id === volunteerId) {
            return {
              id: volunteer.id,
              cause: cause,
              startDate: startDate,
              endDate: endDate,
              description: description,
              organization: organization,
              role: role,
            };
          }
          return volunteer;
        }),
      };
      dispatch(updateLinkedinData(newLinkedinData, cb));
    }
  };

  const handleCopied = (v) => {
    setCopied([...copied, v]);
  };
  const clearCopied = () => {
    setCopied([]);
  };

  return (
    <Card body className="text-left mb-4 w-100" style={styles.border_radius_5}>
      {linkedinData?.VolunteerExperience?.length
        ? linkedinData?.VolunteerExperience.map((item, index) => {
            return (
              <Row key={index} className="mt-2">
                <Col
                  xs={10}
                  lg={11}
                  xl={11}
                  md={11}
                  className="border-bottom p-5"
                >
                  <p style={styles.title}>{item.organization}</p>
                  <p style={styles.sub}>
                    {item?.startDate && moment(item?.startDate).format("MM/DD/YY")} -
                    {item?.endDate && moment(item?.endDate).format("MM/DD/YY")}
                  </p>
                  <p style={styles.years}>{item?.role}</p>
                  <p style={styles.years}>{item?.description}</p>
                </Col>
                <Col
                  xs={2}
                  lg={1}
                  xl={1}
                  md={1}
                  className="border-bottom p-2 d-flex align-items-center justify-content-center"
                >
                  <span
                    style={styles.copyIcon}
                    onClick={() => {
                      setOrganization(item?.organization);
                      setStartDate(item?.startDate);
                      setEndDate(item?.endDate);
                      setRole(item?.role);
                      setCause(item?.cause);
                      setDescription(item.description);
                      setVolunteerId(item.id);
                      dispatch({
                        type: 'VOLUNTEER_MODAL',
                        payload: {
                          open: true,
                          mode: 'edit',
                        },
                      });
                    }}
                  >
                    {props.stepIndex === 1 ? (
                      <CreateIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    ) : (
                      <FileCopyIcon
                        style={styles.visibilityIcon}
                        className="hovdel"
                      />
                    )}
                  </span>
                </Col>
              </Row>
            );
          })
        : 'NO DATA AVAILABLE'}

      <Popup
        open={volunterrModal}
        onClose={() => {
          clearCopied();
          dispatch({
            type: 'VOLUNTEER_MODAL',
            payload: {
              open: false,
              mode: 'edit',
            },
          });
        }}
        contentStyle={{
          marginTop: 120,
          borderRadius: 5,
          padding: 30,
          width: width < 768 ? '90%' : '60%',
          maxHeight: width < 768 ? '90%' : '75%',
          overflow: 'auto',
        }}
        modal
        position="center"
        closeOnDocumentClick
      >
        <span
          onClick={() => {
            clearCopied();
            dispatch({
              type: 'VOLUNTEER_MODAL',
              payload: {
                open: false,
                mode: 'edit',
              },
            });
          }}
        >
          <CloseIcon style={styles.closeBtn} />
        </span>
        <h5>Volunteer Experience</h5>

        <Row>
          <Col>
            <Label for="exampleEmail">Organization*</Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
              />{' '}
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(organization) ? (
                    <CopyToClipboard
                      text={organization}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(organization);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Role*
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(role) ? (
                    <CopyToClipboard
                      text={role}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(role);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Cause
            </Label>
            <InputGroup>
              <Input
                style={{ height: '50px' }}
                value={cause}
                onChange={(e) => setCause(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(cause) ? (
                    <CopyToClipboard
                      text={cause}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(cause);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <Row>
              <Col xs={6}>
                <Label style={{ marginTop: 15 }} for="exampleEmail">
                  Start Date
                </Label>
                <InputGroup>
                  <Input
                    type="date"
                    style={{ height: '50px' }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  {props.stepIndex === 2 && (
                    <InputGroupText>
                      {!copied.includes(startDate) ? (
                        <CopyToClipboard
                          text={startDate}
                          onCopy={() => {
                            toastr.success('Text Copied!');
                            handleCopied(startDate);
                          }}
                        >
                          <FileCopyIcon className="hovdel" />
                        </CopyToClipboard>
                      ) : (
                        <CheckIcon style={{ color: 'green' }} />
                      )}
                    </InputGroupText>
                  )}
                </InputGroup>
              </Col>
              <Col xs={6}>
                <Label style={{ marginTop: 15 }} for="exampleEmail">
                  End Date
                </Label>
                <InputGroup>
                  <Input
                    type="date"
                    style={{ height: '50px' }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  {props.stepIndex === 2 && (
                    <InputGroupText>
                      {!copied.includes(endDate) ? (
                        <CopyToClipboard
                          text={endDate}
                          onCopy={() => {
                            toastr.success('Text Copied!');
                            handleCopied(endDate);
                          }}
                        >
                          <FileCopyIcon className="hovdel" />
                        </CopyToClipboard>
                      ) : (
                        <CheckIcon style={{ color: 'green' }} />
                      )}
                    </InputGroupText>
                  )}
                </InputGroup>
              </Col>
            </Row>

            <Label style={{ marginTop: 15 }} for="exampleEmail">
              Description
            </Label>
            <InputGroup>
              <Input
                style={{ height: '150px' }}
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {props.stepIndex === 2 && (
                <InputGroupText>
                  {!copied.includes(description) ? (
                    <CopyToClipboard
                      text={description}
                      onCopy={() => {
                        toastr.success('Text Copied!');
                        handleCopied(description);
                      }}
                    >
                      <FileCopyIcon className="hovdel" />
                    </CopyToClipboard>
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </InputGroupText>
              )}
            </InputGroup>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {props.stepIndex === 1 && (
                <Button
                  onClick={handleUpdate}
                  className="mt-4"
                  loading={false}
                  style={{ backgroundColor: '#4267b2', color: 'white' }}
                  shape="round"
                  size={'large'}
                >
                  Save
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Popup>
    </Card>
  );
}

const styles = {
  copyIcon: {
    cursor: 'pointer',
    // position: "absolute",
    // right: "10px",
    // top: "10px",
    width: '10px',
  },
  visibilityIcon: {
    fontSize: 18,
    position: 'absolute',
    right: '40',
    top: '45px',
  },
  border_radius_5: {
    borderRadius: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
  },
  sub: {
    margin: 0,
  },
  years: {
    color: 'grey',
    margin: 0,
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 99,
    cursor: 'pointer',
    // backgroundColor: "black",
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 50,
    // color: "#ff0e0e",
  },
};
