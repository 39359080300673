import axios from 'axios';
import url from '../../../config/endpoint';
import toaster from 'toastr';
import { SaveActiveIndex } from '../../../commonRedux/scan/action';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  CURRENT_USER,
  ERROR_OCCURED,
  CURRENT_USER_LOADING,
  SEND_EMAIL,
  SEND_EMAIL_FAIL,
  REQUEST_FOR_VERIFY,
  VERIFIED_SUCCESSFULLY,
  NOT_VERIFIED,
  VERFIED_FAILED,
  SAVE_TOKEN,
  CHANGE_SYNC,
  UPDATE_HOWTOSTART,
} from './constants';

export const createEmployerOrder = (data, setSuccessPopup, cb) => async () => {
  try {
    const res = await axios.post(`${url}/employer-purchase`, data);
    if (res?.data?.status === 200) setSuccessPopup(true);
    cb();
  } catch (error) {
    toaster.error('Failed to place the order');
    cb();
  }
};

export const getEmployerInvoices = (cb) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/past-invoices`);
    if (res?.data?.status === 200) {
      dispatch({
        type: 'GET_PAST_INVOICES',
        payload: res?.data?.invoicesList,
      });
    }
    if (cb) cb();
  } catch (error) {
    toaster.error('Failed to get invoices');
    cb();
  }
};
// login all code using redux thunk
export const updatePhoneNo = (data, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-phone`, data);

    if (res.data.response === 'success') {
      dispatch({
        type: 'UPDATE_PROFILE_STATUS',
        payload: { vType: data.type, phone: data.mobile },
      });
      // toaster.success("Mobile Verified Successfully");
    }
  } catch (error) {
    dispatch({ type: 'PROFILE_STATUS_ERROR' });
    toaster.error(`${error}`);
  } finally {
    cb && cb();
  }
};

export const forgetPassword = (user, cb) => async () => {
  try {
    const res = await axios.post(`${url}/forget-password`, user);
    if (res.data.status === 200) {
      toaster.success(`${res.data.message}`);
      cb(true);
    } else {
      cb();
      toaster.error(`${res.data.message}`);
    }
  } catch (error) {
    cb();
    if (error) {
      toaster.error(`${error}`);
    }
  }
};

export const resetPassword = (user, cb) => async () => {
  try {
    const res = await axios.post(`${url}/reset-password`, user);
    if (res.data.status === 200) {
      toaster.success(`${res.data.message}`);
      cb(true);
    } else {
      cb();
      toaster.error(`${res.data.message}`);
    }
  } catch (error) {
    cb();
    if (error) {
      toaster.error(`${error}`);
    }
  }
};

export const loginUser = (user, cb) => async (dispatch) => {
  dispatch({
    type: LOGIN_USER,
  });
  try {
    const res = await axios.post(`${url}/login`, user);
    if (res?.data?.emailDNExist) {
      cb();
      // toaster.error(`${res.data.message}`);
      dispatch({
        type: ERROR_OCCURED,
      });
    }
    if (res.data.user) {
      cb();
      const user = res.data.user;
      localStorage.setItem('clientAccessToken', res.data.access_token);
      localStorage.setItem('howToStart', res?.data?.user?.how_to_start);
      if (res?.data?.user?.userType) {
        localStorage.setItem('userType', res.data.user.userType);
      }
      localStorage.setItem('defaultTemp', res.data.user.tempId);

      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: res.data.user,
      });
      // setTimeout(() => {
      //   localStorage.setItem('newUser', true);
      // }, 3000);

      dispatch(currentUser());
    }
    if (!res.data.user) {
      cb();
      if (res.data.message) {
        toaster.error(`${res.data.message}`);
      } else {
        toaster.error(`Invalid Username / Password`);
      }
      dispatch({
        type: ERROR_OCCURED,
      });
    }
  } catch (error) {
    cb();
    if (error) {
      toaster.error(`${error}`);
      dispatch({
        type: ERROR_OCCURED,
      });
    }
  }
};
export const getUserIdData = (userId, cb) => async () => {
  try {
    const res = await axios.post(`${url}/get-user-data`, { userId });
    if (res.data.status === 200) {
      cb(res.data);
    }
  } catch (error) {
    cb();
    if (error) {
      toaster.error(`${error}`);
    }
  }
};

// getting Logined user
export const currentUser = (cb) => async (dispatch) => {
  try {
    dispatch({
      type: 'CURRENT_USER_START',
    });
    let getToken = localStorage.getItem('clientAccessToken');

    if (getToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }

    const res = await axios.get(`${url}/get-user-details`);

    if (res.data.status !== 200) {
      dispatch({
        type: CURRENT_USER_LOADING,
      });
      toaster.error(`Session has been expired`, 'Logout');
      localStorage.clear();
      dispatch({ type: 'LOGOUT_USER' });
      window.location.replace('/');
      axios.defaults.headers.common['Authorization'] = ``;
    } else {
      cb && cb();
      localStorage.setItem('defaultTemp', res.data.user.tempId);
      localStorage.setItem('howToStart', res?.data?.user?.how_to_start);
      localStorage.setItem(
        '@AsyncStorage:isSync',
        res.data.user.saveEvents.checked,
      );

      localStorage.setItem('helpPopupVideo', res?.data?.user?.helpVideoPopup);
      localStorage.setItem('howToStartKey', res?.data?.user?.how_to_start);
      localStorage.setItem('user_id', res.data?.user?._id);
      if (res.data.user.tourActivity) {
        dispatch({
          type: 'SET_TOUR_ACTIVITY',
          payload: res.data.user.tourActivity,
        });
      } else {
        dispatch({ type: 'SHOW_TOUR_SECTION', payload: 0 });
      }
      dispatch({
        type: CURRENT_USER,
        payload: res.data.user,
      });
      dispatch({
        type: 'DEFAULT_TEMPLATE',
        payload: res.data.user['tempId'],
      });
    }
  } catch (error) {
    if (error) {
      // toaster.error(`${error}`);
      dispatch({
        type: CURRENT_USER_LOADING,
      });
      if (error.response && error.response.status === 422) {
        toaster.error(`Session has been expired`, 'Logout');
        setTimeout(() => {
          localStorage.clear();
          dispatch({ type: 'LOGOUT_USER' });
          window.location.replace('/');
          axios.defaults.headers.common['Authorization'] = ``;
        }, 3000);
      }
    }
  }
};

// register all code using redux thunk
export const registerUser = (user, cb) => async (dispatch) => {
  dispatch({
    type: REGISTER_USER,
  });

  try {
    const loginCredentials = {
      email: user.email,
      password: user.password,
    };
    const res = await axios.post(`${url}/register`, user);

    if (res.data.emailExists) {
      cb(false);
      toaster.error(`${res.data.message}`);
      dispatch({
        type: ERROR_OCCURED,
      });
    }
    if (res.data.status === 400) {
      cb(false);
      toaster.error(`${res.data.message}`);
      dispatch({
        type: ERROR_OCCURED,
      });
    }
    if (
      res.data.message === 'err' ||
      res.data.message === 'Something wrong please try again later!' ||
      res.data.status === 500
    ) {
      cb(false);
      toaster.error('Something wrong please try again later!');
      dispatch({
        type: ERROR_OCCURED,
      });
    }
    if (res.data.user) {
      await dispatch(loginUser(loginCredentials, cb));
      localStorage.setItem('howToStartVideo', true);
      // cb(true);
      toaster.success(`${res.data.message}`);
      dispatch({
        type: REGISTER_USER_SUCCESS,
      });
    }
  } catch (error) {
    cb(false);
    if (error) {
      cb(false);
      toaster.error(`${error}`);
      dispatch({
        type: ERROR_OCCURED,
      });
    }
  }
};

export const logoutUser =
  (history, currentPage = '') =>
  async (dispatch) => {
    try {
      if (currentPage) {
        await axios.post(`${url}/last-visited-user`, {
          last_visited: `/${currentPage}`,
        });
      }

      dispatch(SaveActiveIndex(0));
      localStorage.clear();
      const response = await axios.get(`${url}/get-video`);
      dispatch({ type: LOGOUT_USER, payload: response.data.data });
      history.push('/');
    } catch (error) {
      if (error) {
        toaster.error(`${error}`);
      }
    }
  };

export const changeEamil = () => async (dispatch) => {
  try {
    dispatch({
      type: SEND_EMAIL,
      payload: {
        eVCode: false,
      },
    });
  } catch (error) {
    if (error) {
      toaster.error(`${error}`);
    }
  }
};

export const verifyEmailOtp = (email, code) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.post(`${url}/verifyCode`, {
      email,
      code: Number(code),
    });
    if (res.data.status === 200) {
      resolve(res.data);
    }
    reject(res.data);
  });
};

export const verifyUserCode = (email, code, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/verifyCode`, { email, code });
    cb && cb();
    if (res.data.status === 200) {
      dispatch({
        type: SEND_EMAIL,
        payload: {
          eVCode: true,
        },
      });
      toaster.success(`${res.data.message}`);
    } else {
      dispatch({
        type: SEND_EMAIL,
        payload: {
          eVCode: false,
        },
      });
      toaster.error(`${res.data.message}`);
    }
  } catch (error) {
    if (error) {
      toaster.error(`${error}`);
      cb && cb();
    }
  }
};

export const sendOtpEmail = (email) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.post(`${url}/varify-user`, {
      type: 'email',
      user_info: email,
    });
    if (res.data.status === 200) {
      resolve(res.data);
    }
    reject(res.data);
  });
};

// send email code
export const sendVCode = (type, email) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/varify-user`, {
      type,
      user_info: email,
    });

    if (res.data.status === 200) {
      // dispatch({
      //   type: SEND_EMAIL,
      //   payload: {
      //     type,
      //     vCode: res.data.varificationCode,
      //   },
      // });
      toaster.success(`${res.data.message}`);
    } else {
      toaster.error(`${res.data.message}`);

      // dispatch({
      //   type: SEND_EMAIL_FAIL,
      //   payload: {
      //     type,
      //   },
      // });
    }
  } catch (error) {
    if (error) {
      toaster.error(`${error}`);

      // dispatch({
      //   type: SEND_EMAIL_FAIL,
      //   payload: {
      //     type,
      //   },
      // });
    }
  }
};

// verify coupon code
export const verifyCouponCodeAction = (code) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_FOR_VERIFY,
    });
    const res = await axios.post(`${url}/valid-coupon`, code);
    if (res.data.status === 200) {
      toaster.success(`${res.data.message}`);
      dispatch({
        type: VERIFIED_SUCCESSFULLY,
        payload: {
          msgSignup: res.data.msgSignup,
          couponExpiry: res?.data?.redeem_by || '',
        },
      });
    } else if (res.data.status === 400) {
      toaster.error(`${res.data.message}`);
      dispatch({
        type: NOT_VERIFIED,
      });
    }
  } catch (err) {
    toaster.error(`${err}`);
    // toaster.error(`${res.data.message}`);
    dispatch({
      type: VERFIED_FAILED,
    });
  }
};

export const saveToken = (token) => {
  return {
    type: SAVE_TOKEN,
    payload: token,
  };
};

export const userAcountActions = (data, loading) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/add-info-in-user-profile`, data);

    if (res.data.response === 'success') {
      toaster.success(`Successfully Added`);
      // // setState(!state);
      // dispatch(currentUser());
      dispatch({
        type: 'UPDATE_PROFILE_STATUS',
        payload: data,
      });
    }
    loading(false);
  } catch (error) {}
};
export const dailEmailUpdateAll =
  (data, loading, setDailyEmail) => async (dispatch) => {
    try {
      const res = await axios.post(`${url}/job-finder-update-all`, data);

      if (res.data.response === 'success') {
        toaster.success(`Successfully Added`);
      }
      dispatch({
        type: 'UPDATE_PROFILE_STATUS',
        payload: data,
      });
      loading(false);
      setDailyEmail(false);
    } catch (error) {}
  };

export const backupYouResumeAction = (obj) => async () => {
  try {
    await axios.post(`${url}/apply-cv-template`, obj);
  } catch (error) {
    if (error) {
      toaster.error(`${error}`);
    }
  }
};

export const saveDropBoxToken = (token) => {
  return {
    type: 'SAVE_DROPBOX_TOKEN',
    payload: token,
  };
};

export const changeSyncStatus = (sync) => {
  return {
    type: CHANGE_SYNC,
    payload: sync,
  };
};

export const updateHowToStart = (data, cb) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/update-how-to-start`, { path: data });
    if (res?.data?.status === 200) {
      dispatch({
        type: UPDATE_HOWTOSTART,
        payload: data,
      });
      if (cb) {
        cb();
      }
    }
  } catch (error) {
    console.log('error', error);
  }
};
