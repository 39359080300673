import * as React from 'react';
import {
  DocumentEditorContainerComponent,
  Print,
  SfdtExport,
  WordExport,
  TextExport,
  Selection,
  Search,
  Editor,
  ImageResizer,
  EditorHistory,
  ContextMenu,
  OptionsPane,
  HyperlinkDialog,
  TableDialog,
  BookmarkDialog,
  TableOfContentsDialog,
  PageSetupDialog,
  StyleDialog,
  ListDialog,
  ParagraphDialog,
  BulletsAndNumberingDialog,
  FontDialog,
  TablePropertiesDialog,
  BordersAndShadingDialog,
  TableOptionsDialog,
  CellOptionsDialog,
  StylesDialog,
} from '@syncfusion/ej2-react-documenteditor';
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import useWordEditor from './useWordEditor';
import { Menu, MenuItem, Select } from '@material-ui/core';
import { fontFamilies, fontSizies } from './constants';
import { useWindowWidth } from '@react-hook/window-size';
import { IoMdCloudDone } from 'react-icons/io';
import { AiOutlineSync } from 'react-icons/ai';
DocumentEditorContainerComponent.Inject(
  Print,
  SfdtExport,
  WordExport,
  TextExport,
  Selection,
  Search,
  Editor,
  ImageResizer,
  EditorHistory,
  ContextMenu,
  OptionsPane,
  HyperlinkDialog,
  TableDialog,
  BookmarkDialog,
  TableOfContentsDialog,
  PageSetupDialog,
  StyleDialog,
  ListDialog,
  ParagraphDialog,
  BulletsAndNumberingDialog,
  FontDialog,
  TablePropertiesDialog,
  BordersAndShadingDialog,
  TableOptionsDialog,
  CellOptionsDialog,
  StylesDialog,
);
export const WordEditor = React.memo((props) => {
  const {
    docEditor,
    propertiesPane,
    HandleTitleBar,
    initDocEditor,
    breakMenu,
    setBreakMenu,
    onContentChange,
    alignMenu,
    setAlignMenu,
    onSelectionChange,
    currentFontSize,
    currentFontFamily,
    sfdtLoading,
  } = useWordEditor(props);
  const width = useWindowWidth();
  const handleOpen = (sfdt) => {
    docEditor.documentEditor.open(sfdt);
    docEditor.documentEditor.documentChange = () => {
      docEditor.documentEditor.focusIn();
    };
    docEditor.documentEditor.addEventListener('click', (e) => {
      e.preventDefault();
    });
    docEditor.documentEditor.resize();
    docEditor.documentEditor.isReadOnly = props.disableEditing;
    docEditor.documentEditor.selection.paragraphFormat.keepLinesTogether = true;
    docEditor.documentEditor.selection.paragraphFormat.keepLinesTogetherIn = true;
    docEditor.documentEditor.selection.paragraphFormatIn.keepLinesTogetherIn = true;
    docEditor.documentEditor.pageOutline = '#E0E0E0';
    docEditor.documentEditor.selection.sectionFormat.bottomMargin = 36;
    docEditor.documentEditor.selection.sectionFormat.topMargin = 36;
    docEditor.documentEditor.selection.sectionFormat.rightMargin = 36;
    docEditor.documentEditor.selection.sectionFormat.leftMargin = 36;
    docEditor.documentEditor.selection.sectionFormat.pageWidth = 612;
    docEditor.documentEditor.selection.sectionFormat.pageHeight = 792;
    docEditor.documentEditor.setDefaultCharacterFormat({
      fontFamily: 'Arial',
    });
    if (props.isMobile) {
      // docEditor.documentEditor.fitPage("FitPageWidth");
      docEditor.documentEditor.zoomFactor = 0.75;
      docEditor.documentEditor.pageOutline = 'white';
      docEditor.documentEditor.selection.sectionFormat.bottomMargin = 0.1;
      docEditor.documentEditor.selection.sectionFormat.topMargin = 0;
      docEditor.documentEditor.selection.sectionFormat.rightMargin = 0;
      docEditor.documentEditor.selection.sectionFormat.leftMargin = 0;
      docEditor.documentEditor.selection.sectionFormat.pageWidth = 0;
      docEditor.documentEditor.selection.sectionFormat.pageHeight = 0;
    }
  };
  React.useEffect(() => {
    if (docEditor) {
      let wordSFDT = props.data;
      handleOpen(wordSFDT);
    }
  }, [props.data]);

  function onCreated() {
    let wordSFDT = props.data;
    handleOpen(wordSFDT);
  }

  const allFontSizies = [
    ...new Set([...fontSizies, ...(props.fontFamilies || [])]),
  ];

  const disabledStyle = {
    opacity: 0.9, // reduce the opacity of the div
    // pointerEvents: 'none', // prevent pointer events on the div
    userSelect: 'none', // prevent text selection on the div
  };
  const abledStyle = {
    opacity: 1,
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {props.step === 2 ? (
        ''
      ) : (
        <div
          style={{
            // display: props?.topbar ? "none" : "",
            backgroundColor: '#4267B2',
            color: 'white',
            overflow: 'auto',
            width: '100%',
            padding: '5px 0',
            position: props.isMobile ? 'fixed' : '',
            top: 0,
            zIndex: props.isMobile ? 999 : 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: width < 1120 ? 'flex-start' : 'center',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            {' '}
            <Tooltip
              style={{
                margin: '0 10px',
              }}
              title={sfdtLoading ? 'Saving...' : 'Saved'}
            >
              <span className="pr-3">
                {sfdtLoading ? (
                  <AiOutlineSync
                    className="rotating-icon"
                    stroke="2"
                    size={20}
                  />
                ) : (
                  <IoMdCloudDone size={20} />
                )}
              </span>
            </Tooltip>{' '}
            <Tooltip title="Undo">
              <span
                onClick={() => HandleTitleBar('undo')}
                className="e-icons undo mr-3 ml-3"
              ></span>
            </Tooltip>
            <Tooltip title="Redo">
              <span
                onClick={() => HandleTitleBar('redo')}
                className="e-icons redo mr-3"
              ></span>
            </Tooltip>
            <span>
              <Select
                value={currentFontFamily}
                style={{
                  marginRight: '10px',
                  fontSize: '16px',
                  color: 'white',
                  maxWidth: '165px',
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={(e) => {
                  HandleTitleBar('fontFamily', e.target.value);
                }}
              >
                {fontFamilies?.map((font, index) => {
                  return (
                    <MenuItem key={index} value={font}>
                      {font}
                    </MenuItem>
                  );
                })}
              </Select>
            </span>
            <span>
              <Select
                value={currentFontSize}
                onChange={(e) => {
                  HandleTitleBar('fontSize', e.target.value);
                }}
                style={{
                  marginRight: '10px',
                  fontSize: '16px',
                  color: 'white',
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {allFontSizies?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </span>
            {/* <span>
          <Select
            defaultValue="Normal"
            onChange={(e) => {
              HandleTitleBar("styleList", e.target.value);
            }}
            style={{
              marginRight: "10px",
              fontSize: "16px",
              color: "white",
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {
              styleLists.map((item, index) => {
                return <MenuItem value={item} key={index}>{item}</MenuItem>
              })
            }
          </Select>
        </span> */}
            <Tooltip title="Bold">
              <span
                onClick={() => HandleTitleBar('bold')}
                className="e-icons boldIcon mr-3"
              ></span>
            </Tooltip>
            <Tooltip title="Italic">
              <span
                onClick={() => HandleTitleBar('italic')}
                className="e-icons italicIcon mr-3"
              ></span>
            </Tooltip>
            <Tooltip title="Underline">
              <span
                onClick={() => HandleTitleBar('underline')}
                className="e-icons underlineIcon mr-3"
              ></span>
            </Tooltip>
            <Tooltip title="Align Text">
              <span
                onClick={(e) => setAlignMenu(e.currentTarget)}
                className="e-icons textAlignLeft mr-3"
              ></span>
            </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={alignMenu}
              keepMounted
              open={Boolean(alignMenu)}
              onClose={() => {
                setAlignMenu(null);
              }}
            >
              <div className="d-flex p-2 justify-content-between">
                <Tooltip title="Left">
                  <span
                    onClick={() => HandleTitleBar('textAlignLeft')}
                    className="e-icons textAlignLeft mr-3"
                  ></span>
                </Tooltip>
                <Tooltip title="Center">
                  <span
                    onClick={() => HandleTitleBar('textAlignCenter')}
                    className="e-icons textAlignCenter mr-3"
                  ></span>
                </Tooltip>
                <Tooltip title="Right">
                  <span
                    onClick={() => HandleTitleBar('textAlignRight')}
                    className="e-icons textAlignRight mr-3"
                  ></span>
                </Tooltip>
                <Tooltip title="Justify">
                  <span
                    onClick={() => HandleTitleBar('textAlignJustify')}
                    className="e-icons textAlignJustify"
                  ></span>
                </Tooltip>
              </div>
            </Menu>
            <Tooltip title="Unorder List">
              <span
                onClick={() => HandleTitleBar('bullets')}
                className="e-icons unorderedList mr-3"
              ></span>
            </Tooltip>
            <Tooltip title="Add Hyperlink">
              <span
                onClick={() => HandleTitleBar('hyperLink')}
                className="e-icons hyperLink mr-3"
              ></span>
            </Tooltip>
            <Tooltip title="Setup Page">
              <span
                onClick={() => HandleTitleBar('pageSetup')}
                className="e-icons pageSetup mr-3"
              ></span>
            </Tooltip>
            <Tooltip title="Page Number">
              <span
                onClick={() => HandleTitleBar('pageNumber')}
                className="e-icons pageNumber mr-3"
              ></span>
            </Tooltip>
            <Tooltip title="Break">
              <span
                className="e-icons break mr-3"
                onClick={(e) => setBreakMenu(e.currentTarget)}
              ></span>
            </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={breakMenu}
              keepMounted
              open={Boolean(breakMenu)}
              onClose={() => {
                setBreakMenu(null);
              }}
            >
              <MenuItem onClick={() => HandleTitleBar('pageBreak')}>
                <span className="e-icons pageBreak mr-2"></span>
                <p style={{ paddingTop: '16px' }}>Page Break</p>
              </MenuItem>
              <MenuItem onClick={() => HandleTitleBar('sectionBreak')}>
                <span className="e-icons sectionBreak mr-2"></span>
                <p style={{ paddingTop: '16px' }}>Section Break</p>
              </MenuItem>
            </Menu>
            <Tooltip title="Find">
              <span
                onClick={() => HandleTitleBar('find')}
                className="e-icons find mr-3"
              ></span>
            </Tooltip>
            <Tooltip title="Export as Word">
              <span
                onClick={() => HandleTitleBar('wordExport')}
                className="e-icons export-word mr-3"
              ></span>
            </Tooltip>
            <Tooltip title="Export as PDF">
              <span
                onClick={() => HandleTitleBar('pdfExport')}
                className="e-icons export-pdf pr-3"
              ></span>
            </Tooltip>
            {/* <Tooltip title="Export as Txt">
          <span
            onClick={() => HandleTitleBar("txtExport")}
            className="e-icons export-txt mr-3"
          ></span>
        </Tooltip>
        <Tooltip title="Print">
          <span
            onClick={() => HandleTitleBar("print")}
            className="e-icons print-2 mr-5"
          ></span>
        </Tooltip>

        <Tooltip title="Show/Hide properties pane">
          <span
            onClick={() => setPropertiesPane(!propertiesPane)}
            className="e-icons show-hide-panel mr-3"
          ></span>
        </Tooltip> */}
          </div>
        </div>
      )}
      <div style={props.step === 2 ? disabledStyle : abledStyle}>
        <DocumentEditorContainerComponent
          id="container"
          ref={initDocEditor}
          style={{ display: 'block', marginTop: props.isMobile ? 40 : 0 }}
          height="150vh"
          locale="en-US"
          created={onCreated}
          showPropertiesPane={propertiesPane}
          selectionChange={() => onSelectionChange()}
          enableToolbar={false}
          contentChange={onContentChange}
          enableEditing={false}
          serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
          isReadOnly={true}
          enablePrint={true}
          enableSelection={true}
          enableEditor={true}
          enableEditorHistory={true}
          enableContextMenu={true}
          enableSearch={true}
          enableOptionsPane={true}
          enableBookmarkDialog={true}
          enableBordersAndShadingDialog={true}
          enableFontDialog={true}
          enableTableDialog={true}
          enableParagraphDialog={true}
          enableHyperlinkDialog={true}
          enableImageResizer={true}
          enableListDialog={true}
          enablePageSetupDialog={true}
          enableSfdtExport={true}
          enableStyleDialog={true}
          enableTableOfContentsDialog={true}
          enableTableOptionsDialog={true}
          enableTablePropertiesDialog={true}
          enableTextExport={true}
          enableWordExport={true}
          enableTrackChanges={true}
        />
      </div>
    </div>
  );
});
