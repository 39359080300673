import React from 'react';
import { Text, Dnd } from '../../component';
import { Grid } from '@material-ui/core';
//eslint-disable-next-line
import { useStyles } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateOrder,
  addNewObj,
  deleteObjInArray,
  onBlurField,
  handleAccordionAction,
} from '../../redux/actions';
import Util from '../../../../utils/templateUtils';
import InputText from '../../component/inputText';
import Accordian from '../helper/Accordian';
import RichEditor from '../helper/RichEditor';
import moment from 'moment';
import { colors } from '../../../../commonComponents/colors';

export default function Certifications(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = 'certifications.items';

  const onOrderUpdate = (data) => {
    const storeReorder = Util.mapOrder(props.data, data, 'id');

    dispatch(updateOrder(storeReorder, path));
  };

  const _addNewItem = () => {
    dispatch(addNewObj(props.data[0], path));
  };

  const _removeItem = (index) => {
    let deletedPath = `${path}.${index}`;
    dispatch(deleteObjInArray(deletedPath));
  };
  let { data } = props;
  const expanded = useSelector(state => state.editorReducer.accordion)
  const handleAccordion = (panel) => (event, isExpanded) => {
    dispatch(handleAccordionAction(panel, isExpanded))
  }
  return (
    <>
      <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
        <div>
          <label
            className={classes.label}
            style={{ marginRight: 10, fontSize: 16, fontWeight: 'bold' }}
          >
            Certifications
          </label>

        </div>
      </Grid>
      <Dnd
        data={data}
        reorder={(e) => onOrderUpdate(e)}
        additem={_addNewItem}
        removeitem={(index) => _removeItem(index)}
        renderItem={(item, index) => (
          <Accordian
            // defaultExpanded={false}
            expanded={expanded === index} onChange={() => handleAccordion(index)}
            summary={
              <>
                <label
                  className={
                    item?.title || item?.institution
                      ? classes.summaryLabel
                      : classes.summaryLabelGrey
                  }
                >
                  {item?.title || item?.institution
                    ? item?.title
                    : 'Add Certification Details'}
                  {'\n '}
                </label>
                <br />
                <label className={classes.summarySubLabel}>
                  {item?.date && moment(item?.date, "MMMM YYYY").format('MMMM YYYY')}
                </label>
              </>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div>
                  <label className={classes.label}>Title</label>
                  <Text
                    value={item?.title}
                    placeholder="Title"
                    customclass={classes.input}
                    path={`${path}.${index}.title`}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div>
                  <label className={classes.label}>Issued Date</label>
                  <InputText
                    type="month"
                    value={item?.date ?? ''}
                    placeholder="MM / YYYY"
                    customclass={classes.input}
                    path={`${path}.${index}.date`}
                  />
                </div>
              </Grid>
            </Grid>
            <label className={classes.label}>Description</label>
            {/* <Text
            value={item.summary}
            placeholder="Description"
            customclass={`${classes.input} ${classes.summaryInput}`}
            path={`${path}.${index}.summary`}
          /> */}

            <RichEditor
              value={item?.summary}
              placeHolder="Description"
              customclass={`${classes.input} ${classes.summaryInput}`}
              path={`${path}.${index}.summary`}
              onBlurField={onBlurField}
            />
          </Accordian>
        )}
      />
      <div
        onClick={_addNewItem}
        style={{
          color: colors.blue,
          marginTop: 40,
          fontWeight: 'bold',
          fonSize: 18,
          cursor: 'pointer',
        }}
      >
        + Add one more certification
      </div>
    </>
  );
}
