import React from 'react';
import MillXWalkTabs from './Tabs';
import {
  CircularProgress,
  Grid,
  LinearProgress,
  styled,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useWindowSize } from '@react-hook/window-size';
import ScanAnimation from '../../../commonComponents/scanLoader/index';
export const CommonCard = ({
  cardData,
  title,
  type,
  Icon,
  style,
  sourceText,
}) => {
  const [width] = useWindowSize();
  return (
    <>
      <div
        variant="elevation"
        style={{
          boxShadow: `rgb(238, 238, 238) 1px 1px 1px 1px`,
          borderRadius: 5,
          border: '1px solid rgb(238, 238, 238)',
          ...style,
        }}
      >
        <h4
          style={{
            marginBottom: 5,
            fontSize: 18,
            padding: '10px 0',
            backgroundColor: '#f7f7f7',
            padding: '14px 10px',
            borderRadius: 5,
          }}
        >
          {title}
        </h4>
        <div
          style={{
            padding: 10,
          }}
        >
          {(cardData?.length > 0 &&
            (cardData[1]?.title?.length || cardData[1]?.length)) ||
          cardData[0]?.length ||
          cardData[0]?.title?.length ? (
            cardData?.map((item, index) => {
              !cardData[0]?.length > 0 && type === 'ol' && index--;
              if (item?.length > 0 || item?.title?.length)
                switch (type) {
                  case 'icon':
                    return (
                      <>
                        <span
                          key={index}
                          style={{
                            margin: 0,
                            fontSize: 14,
                          }}
                        >
                          <Icon size={20} />
                          {item?.trim()}
                        </span>
                        {index !== cardData.length - 1 ? ',' : ''} &nbsp;
                      </>
                    );
                  case 'ol':
                    return (
                      <>
                        <p
                          key={index}
                          style={{
                            margin: 0,
                            fontSize: 14,
                          }}
                        >
                          {`${index + 1}. ${item?.trim()}`}
                        </p>
                      </>
                    );
                  case 'pipeline':
                    return (
                      <>
                        <span
                          key={index}
                          style={{
                            margin: 0,
                            fontSize: 14,
                          }}
                        >
                          {`${item?.trim()} ${
                            index !== cardData?.length - 1 ? '|' : ''
                          }`}
                          &nbsp;
                        </span>
                      </>
                    );
                  case 'slider':
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: width < 600 ? 'flex-start' : 'center',
                            gap: width < 600 ? 10 : 20,
                            justifyContent: 'space-between',
                            flexDirection:
                              width < 600 ? 'column-reverse' : 'row',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                              width: width < 290 ? 160 : 200,
                            }}
                          >
                            <b
                              style={{
                                textAlign: 'left',
                                width: 50,
                                fontWeight: '500',
                                fontSize: 14,
                              }}
                            >
                              {item?.value}
                            </b>

                            <LinearProgress
                              variant="determinate"
                              value={+item?.value?.replace('%', '')}
                              style={{
                                height: 10,
                                borderRadius: 50,
                                minWidth: width < 600 ? '100%' : '130px',
                              }}
                            />
                          </div>
                          <div
                            style={{
                              textAlign: width < 600 ? 'left' : 'right',
                              fontSize: 14,
                            }}
                          >
                            {item?.title}
                          </div>
                        </div>
                        {index === cardData?.length - 1 ? null : (
                          <hr style={{ margin: '8px 0' }} />
                        )}
                      </>
                    );
                  case 'keyvalue':
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 20,
                            justifyContent: 'space-between',
                          }}
                        >
                          <b style={{ fontWeight: '500', fontSize: 14 }}>
                            {item?.title}
                          </b>
                          <p style={{ margin: 0 }}>{item?.value}</p>
                        </div>
                        {index === cardData?.length - 1 ? null : (
                          <hr style={{ margin: '8px 0' }} />
                        )}
                      </>
                    );
                  default:
                    return (
                      <>
                        <p
                          key={index}
                          style={{
                            margin: 0,
                            fontSize: 14,
                          }}
                        >
                          {item?.trim()}
                        </p>
                        {index === cardData?.length - 1 ? null : (
                          <hr style={{ margin: '8px 0' }} />
                        )}
                      </>
                    );
                }
            })
          ) : (
            <center>No Information Found</center>
          )}
          {sourceText && (
            <div
              style={{
                fontSize: 12,
              }}
            >
              <hr style={{ margin: '8px 0' }} />
              {sourceText}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ReviewComponent = ({ data }) => {
  return (
    <>
      {/* Industry Skills Card */}
      <Grid container spacing={3}>
        <Grid xs={12} sm={6} lg={6} item>
          <CommonCard cardData={data['8Certs']} title="Certifications" />
        </Grid>
        <Grid xs={12} sm={6} lg={6} item>
          <CommonCard cardData={data['8Assoc']} title="Associations" />
        </Grid>
        <Grid lg={8} sm={6} xs={12} item>
          <CommonCard
            cardData={data['20SWSkills']}
            title="Software Skills"
            type="pipeline"
            style={{
              marginBottom: 24,
            }}
          />
          <CommonCard
            cardData={data['50ExpInt']}
            title="Industry Experience"
            type="ol"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <CommonCard cardData={data['50GenSkills']} title="General Skills" />
        </Grid>
      </Grid>
    </>
  );
};

const ReviewMillxWalk = ({ milXWalkLoading }) => {
  const {
    suggestedInformationLoader,
    suggestedInformation,
    milXwalkDetailLoading,
    milXwalkDetail,
    tabValue,
  } = useSelector((state) => state?.militaryReducer);
  if (suggestedInformationLoader || milXwalkDetailLoading) {
    return (
      <div
        style={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ScanAnimation isMilXwalk={true} />
      </div>
    );
  }
  return (
    <div>
      <MillXWalkTabs
        tabValue={tabValue}
        data={suggestedInformation?.data}
        Component={ReviewComponent}
        milXwalkDetail={milXwalkDetail}
      />
    </div>
  );
};

export default ReviewMillxWalk;
