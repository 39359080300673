import _ from 'lodash';
import set from 'lodash/set';
import {
  ZIPRECRUITER_REQUEST,
  ZIPRECRUITER_REQUEST_FAILED,
  FETCHED_ZIPRECRUITER,
} from './action';
import uniqid from 'uniqid';

let default_value = localStorage.getItem('tourActivity');
if (default_value) {
  default_value = JSON.parse(default_value);
}
const TOUR_ACTIVITY_DEFAULT = default_value || {
  productTour: 0,
  // profileTour: 0,
  // videoTour: 0,
  editTour: 0,
  jobSearchTour: 0,
  linkedinTour: 0,
};

export const fileScanReducer = (
  state = {
    defaultFiles: {
      resume: '',
      jobDescription: '',
    },
    parsefile: '',
    scanResult: null,
    scanResultStatus: 'not-Done',
    resume: null,
    addedSuggestions: [],
    styleCloud: '',
    editorLoader: false,
    userResumas: [],
    total_page: '',
    current_page: '',
    resumaId: '',
    cover_id: '',
    historyStauts: 'not done',
    historyData: [],
    manageHistoryData: [],
    historyDeleteStatus: 'not done',
    updator: new Date(),
    updatortwo: new Date(),
    zipRecruiterDescription: '',
    zipRecruiterRequest: false,
    skillsFromLatest: '',
    activeIndex: 0,
    notification: [],
    reload: 'not done',
    scanId: '',
    selected_temp: '',
    getResumasStatus: false,
    getScanHistoryStatus: false,
    fedexStatus: false,
    fedexStatusfortable: false,
    sendTextToStatus: '',
    avoidWords: [],
    parsedAnalyzStatus: false,
    savedResumeId: '',
    softSkills: [],
    sovren_counts: {},
    similar_job_title: {
      titles: [],
    },
    jobTitleMatch: '',
    jobTitlePrompt: true,
    updateResumeStatus: false,
    updateResumeTextStatus: false,
    analyzStatus: false,
    scanReloadStatus: false,
    stepperLoader: false,
    job_parsed: {},
    count_managements: {},
    content_loaders: {
      readabilty: false,
      score: false,
      skills: false,
    },
    tourActivity: TOUR_ACTIVITY_DEFAULT,
    showTourSection: -1,
    showOnboardingToggle: false,
    showJobReplaceSection: true,
    userDefinedJobTitle: false,
    videoLinks: {},
    videoLinksStatus: false,
    isMailSent: false,
    suggestions: null,
    templateId: 1,
    templates: [],
    showLinkedInDialog: false,
    summarySuggestion: '',
    companyInfo: {},
    infoLoader: false,
    selectedTitle: '',
    builderSelectedTitle: '',
    jobTitle: '',
    suggestionLoader: false,
    onetData: {},
    sfdtLoading: false,
    followUpEmail: '',
  },
  action,
) => {
  switch (action.type) {
    case 'SAVE_RESUME_TEXT':
      // eslint-disable-next-line
      const resumeText = action.payload;
      // eslint-disable-next-line
      const scanResult = state.scanResult;
      scanResult['resumeText'] = resumeText;
      return {
        ...state,
        scanResult,
      };

    case 'SET_ONBOARDING_TOGGLE':
      // eslint-disable-next-line
      const showOnboardingToggle = action.payload;
      return {
        ...state,
        showOnboardingToggle,
      };
    case 'SET_JOB_TITLE_MATCH':
      return {
        ...state,
        jobTitleMatch: action.payload,
      };
    case 'MAIL_SENT':
      return {
        ...state,
        isMailSent: true,
      };
    case 'NOTIFICATION':
      return {
        ...state,
        notification: action.payload,
      };
    case 'USER_CANCEL_JOB_TITLE_MATCH':
      return {
        ...state,
        jobTitlePrompt: false,
        // jobTitleMatch: action.payload,
      };
    case 'SET_JOB_REPLACE_FALSE':
      return {
        ...state,
        showJobReplaceSection: false,
      };
    case 'SET_USER_DEFINED_JOB_TITLE':
      return {
        ...state,
        userDefinedJobTitle: true,
      };

    case 'SHOW_TOUR_SECTION':
      // eslint-disable-next-line
      const showTourSection = action.payload;
      return {
        ...state,
        showTourSection,
      };
    case 'SET_TOUR_ACTIVITY':
      // eslint-disable-next-line
      const tourActivity = {
        ...state.tourActivity,
        ...action.payload,
      };
      localStorage.setItem('tourActivity', JSON.stringify(tourActivity));
      return {
        ...state,
        tourActivity,
        showTourSection: -1,
      };

    case 'SCAN_RELOAD_START':
      return {
        ...state,
        scanReloadStatus: true,
      };

    case 'SAVE_COUNT_MANAGEMENTS':
      return {
        ...state,
        count_managements: action.payload,
      };
    case 'SIMILAR_JOB_TITLE':
      return {
        ...state,
        similar_job_title: action.payload,
      };
    case 'CONTENT_LOADERS':
      return {
        ...state,
        content_loaders: {
          ...state.content_loaders,
          ...action.payload,
        },
      };

    case 'STEPPER_LOADER':
      return {
        ...state,
        stepperLoader: action.payload,
      };
    case 'SAVE_JOB_PARSED':
      return {
        ...state,
        job_parsed: action.payload,
      };
    case 'SCAN_RESULT_START':
      return {
        ...state,
        analyzStatus: true,
      };
    case 'UPDATE_RESUME_TEXT_START':
      return {
        ...state,
        updateResumeTextStatus: true,
      };
    case 'UPDATE_RESUME_TEXT_SUCCESS':
      return {
        ...state,
        updateResumeTextStatus: false,
      };
    case 'UPDATE_RESUME_TEXT_FAILED':
      return {
        ...state,
        updateResumeTextStatus: false,
      };
    case 'FEDEX_START_FOR_TABLE':
      return {
        ...state,
        fedexStatusfortable: true,
      };
    case 'FEDEX_SUCCESS_FOR_TABLE':
      return {
        ...state,
        fedexStatusfortable: false,
      };
    case 'FEDEX_FAILED_FOR_TABLE':
      return {
        ...state,
        fedexStatusfortable: false,
      };
    case 'UPDATE_START':
      return {
        ...state,
        updateResumeStatus: true,
      };
    case 'UPDATE_SUCCESS':
      return {
        ...state,
        updateResumeStatus: false,
      };
    case 'UPDATE_FAILED':
      return {
        ...state,
        updateResumeStatus: false,
      };

    case 'SAVE_SOFT_SKILLS':
      return {
        ...state,
        softSkills: action.payload,
      };

    case 'SAVE_SOVREN':
      return {
        ...state,
        sovren_counts: action.payload,
      };
    case 'SAVED_RESUME_ID':
      return {
        ...state,
        savedResumeId: action.payload,
      };
    case 'PARSED_ANALYZ_START':
      return {
        ...state,
        parsedAnalyzStatus: true,
      };
    case 'PARSED_ANALYZ_SUCCESS':
      return {
        ...state,
        parsedAnalyzStatus: false,
      };
    case 'PARSED_ANALYZ_FAILED':
      return {
        ...state,
        parsedAnalyzStatus: false,
      };

    case 'SAVE_AVOID_WORDS':
      return {
        ...state,
        avoidWords: action.payload,
      };
    case 'SEND_TEXT_TO_START':
      return {
        ...state,
        sendTextToStatus: 'start',
      };
    case 'SEND_TEXT_TO_SUCCESS':
      return {
        ...state,
        sendTextToStatus: 'done',
      };
    case 'SEND_TEXT_TO_FAILED':
      return {
        ...state,
        sendTextToStatus: 'failed',
      };
    case 'FEDEX_START':
      return {
        ...state,
        fedexStatus: true,
      };
    case 'FEDEX_SUCCESS':
      return {
        ...state,
        fedexStatus: false,
      };
    case 'FEDEX_FAILED':
      return {
        ...state,
        fedexStatus: false,
      };
    case 'SCAN_STARTING':
      return {
        ...state,
        getScanHistoryStatus: true,
      };
    case 'SCAN_FAILED':
      return {
        ...state,
        getScanHistoryStatus: false,
      };
    case 'GET_RESUMES_STARTED':
      return {
        ...state,
        getResumasStatus: true,
      };
    case 'GET_RESUMES_FAILED':
      return {
        ...state,
        getResumasStatus: false,
      };
    case 'PARSE_FILE':
      return {
        ...state,
        parsefile: action.payload,
        updator: new Date(),
        // scanResult: null,
        // scanResultStatus: "not-Done",
      };
    case 'SELECTED_TEMPLATE':
      return {
        ...state,
        selected_temp: action.payload,
      };
    case 'SCAN_ID_FROM_LATEST':
      return {
        ...state,
        scanId: action.payload,
      };
    case 'RELOAD_DONE':
      return {
        ...state,
        reload: 'done',
      };
    case 'FILE_PARSE_ERROR':
      return {
        ...state,
        parsingError: true,
        parsefile: '',
        updator: new Date(),
        scanResult: null,
        scanResultStatus: 'not-Done',
      };

    case 'SAVED_USER_RESUME_DATA':
      return {
        ...state,
        userResumas: action.payload,
        updator: new Date(),
        getResumasStatus: false,
      };

    case 'SAVED_USER_RESUME':
      return {
        ...state,
        userResumas: action.payload.data,
        total_page: action.payload.page_total,
        current_page: action.payload.page,
        updator: new Date(),
        getResumasStatus: false,
      };

    case 'SKILLS_FROM_LATEST_SCAN':
      return {
        ...state,
        skillsFromLatest: action.payload,
      };

    case 'SAVED_USER_COVER_ID':
      return {
        ...state,
        cover_id: action.payload,
      };
    case 'SAVED_USER_RESUMA_ID':
      return {
        ...state,
        resumaId: action.payload,
      };

    ///////////
    case 'SAVED_RESUME':
      return {
        ...state,
        resume: {
          ...state.resume,
          ...action.payload,
        },
      };
    case 'SAVED_TEMPLATES':
      return {
        ...state,
        templates: action.payload,
      };
    case 'SCAN_RESULT':
      return {
        ...state,
        scanResult: action.payload,
        scanResultStatus: 'done',
        analyzStatus: false,
        scanReloadStatus: false,
        updator: new Date(),
      };

    case 'SAVE_LATEST_SCAN':
      return {
        ...state,
        scanResult: action.payload,
      };

    case 'SCAN_RESULT_STATUS':
      return {
        ...state,
        scanResultStatus: 'not-Done',
        updator: new Date(),
        scanResult: null,
        analyzStatus: false,
        scanReloadStatus: false,
      };
    case 'CLEAR_SCAN_RESULT':
      return {
        ...state,
        scanResultStatus: 'not-Done',
        scanResult: null,
        parsefile: '',
      };

    case 'CLEAR_LATEST_SCAN':
      return {
        ...state,
        scanResultStatus: 'not-Done',
        scanResult: null,
        parsefile: '',
        selectedTitle: '',
        companyInfo: {},
        onetData: {},
        jobTitle: '',
        suggestions: null,
      };

    case 'CLEAR_SAVED_RESUME':
      return {
        ...state,
        resume: null,
        addedSuggestions: [],
      };
    case 'ADD_SKILLS_ADDITIONAL':
      var key = action.payload;
      // eslint-disable-next-line
      let skillsD_ = state.resume.data?.additional_skills?.items || [];
      // eslint-disable-next-line
      let newSkill = {
        id: uniqid(),
        name: key,
      };
      skillsD_.push(newSkill);
      return {
        ...state,
        resume: set(
          { ...state.resume },
          `data.additional_skills.items`,
          skillsD_,
        ),
      };
    case 'ADD_SKILLS':
      var item = action.payload;
      // eslint-disable-next-line
      let skillsD = state.resume.data.skills.items;
      skillsD.push(item);
      return {
        ...state,
        resume: set({ ...state.resume }, `data.skills.items`, skillsD),
      };

    case 'REMOVE_SKILLS':
      // eslint-disable-next-line
      let clone = Object.assign({}, state['scanResult'].skillsData);
      // eslint-disable-next-line
      let skills = state.resume.data.skills.items;
      _.remove(skills, function (n) {
        return n.toLowerCase() === action.payload.toLowerCase();
      });
      delete clone[action.payload];

      return {
        ...state,
        resume: set({ ...state.resume }, `data.skills.items`, skills),
        scanResult: {
          ...state['scanResult'],
          skillsData: clone,
        },
      };

    ///// history reducers

    case 'HISTORY_STATUS':
      return {
        ...state,
        historyStauts: 'not done',
        updatortwo: new Date(),
      };
    case 'MANAGE_HISTORY_DATA':
      return {
        ...state,
        historyStauts: 'done',
        manageHistoryData: action.payload,
        updatortwo: new Date(),
        getScanHistoryStatus: false,
      };

    case 'HISTORY_DATA':
      return {
        ...state,
        historyStauts: 'done',
        historyData: action.payload,
        updatortwo: new Date(),
        getScanHistoryStatus: false,
      };

    //// history delete item reducer
    case 'HISTORY_DELETE_STATUS':
      return {
        ...state,
        historyDeleteStatus: 'not done',
        updatortwo: new Date(),
      };

    case ZIPRECRUITER_REQUEST:
      return {
        ...state,
        zipRecruiterRequest: true,
      };

    case ZIPRECRUITER_REQUEST_FAILED:
      return {
        ...state,
        zipRecruiterRequest: false,
      };

    case FETCHED_ZIPRECRUITER:
      return {
        ...state,
        zipRecruiterDescription: action.payload,
        zipRecruiterRequest: false,
      };
    case 'SAVE_STEP_ACTIVE_INDEX':
      return {
        ...state,
        activeIndex: action.payload,
      };

    case 'REMOVE_STEP_ACTIVE_INDEX':
      return {
        ...state,
        activeIndex: action.payload,
      };

    case 'DELETE_HISTORY':
      return {
        ...state,
        historyData: state.historyData.filter(
          (obj) => obj.oid !== action.payload,
        ),
        historyDeleteStatus: 'done',
        updatortwo: new Date(),
      };

    case 'UPDATE_EDITOR':
      return {
        ...state,
        resume: { ...state.resume, ...action.payload },
      };
    case 'UPDATE_SUGGESTIONS_EDITOR':
      const editorJson = { editorJson: action.payload.editorJson };
      const alreadyAdded = action.payload.addedSuggestions;
      return {
        ...state,
        resume: { ...state.resume, ...editorJson },
        addedSuggestions: [...alreadyAdded],
      };
    case 'SAVE_SUGGESTIONS':
      const saveSuggestion = action.payload;
      return {
        ...state,
        addedSuggestions: [...saveSuggestion],
      };
    case 'EDITOR_STATUS':
      return {
        ...state,
        editorLoader: action.payload,
      };
    case 'CLOUD_IMAGE':
      return {
        ...state,
        styleCloud: action.payload,
      };
    case 'GET_DEFAULT_FILES':
      return {
        ...state,
        defaultFiles: action.payload,
      };
    case 'VIDEO_LINKS_STATE':
      return {
        ...state,
        videoLinks: action.payload,
      };
    case 'VIDEO_LINKS_STATUS':
      return {
        ...state,
        videoLinksStatus: !state.videoLinksStatus,
      };
    case 'GET_SUGGESTIONS':
      return {
        ...state,
        suggestions: action.payload,
      };

    case 'TEMPLATE_SELECTION':
      const { temp, tempId } = action.payload;
      return {
        ...state,
        resume: {
          ...state.resume,
          editorJson: temp,
        },
        templateId: tempId,
      };

    case 'GET_COVER_LETTER':
      const coverLetter = action.payload.coverLetter;
      const response = action.payload.data;
      return {
        ...state,
        resume: {
          ...state.resume,
          response,
          coverLetter,
        },
      };
    case 'SAVE_COVER_LETTER':
      const description = action.payload.description;
      const oid = action.payload.oid;
      const res = action.payload.data;
      return {
        ...state,
        resume: {
          ...state.resume,
          response: {
            ...state.resume.response,
            ...res,
          },
          coverLetter: {
            ...state.resume.coverLetter,
            description,
            oid,
          },
        },
      };
    case 'UPDATE_COVER_LETTER':
      return {
        ...state,
        resume: {
          ...state.resume,
          coverLetter: {
            ...state.resume.coverLetter,
            title: action.payload?.title,
            description: action.payload?.description,
          },
        },
      };
    case 'SHOW_LINKEDIN_DIALOG':
      return {
        ...state,
        showLinkedInDialog: true,
      };
    case 'HIDE_LINKEDIN_DIALOG':
      return {
        ...state,
        showLinkedInDialog: false,
      };
    case 'SAVE_SUMMARY':
      return {
        ...state,
        summarySuggestion: action.payload,
      };
    case 'EMAIL_COVER_LETTER':
      const email = action.payload;
      return {
        ...state,
        resume: {
          ...state.resume,
          email,
        },
      };
    case 'COMPANY_INFO':
      return {
        ...state,
        companyInfo: action.payload,
      };
    case 'INFO_LOADER':
      return {
        ...state,
        infoLoader: action.payload,
      };
    case 'ONET_DATA':
      return {
        ...state,
        onetData: action.payload,
      };
    case 'SELECTED_TITLE':
      return {
        ...state,
        selectedTitle: action.payload,
      };
    case 'UPDATE_SCAN_JOB_TITLE':
      return {
        ...state,
        scanResult: {
          ...state.scanResult,
          jobTitle: action.payload,
        },
      };
    case 'SELECTED_BUILDER_TITLE':
      return {
        ...state,
        builderSelectedTitle: action.payload,
      };
    case 'CLEAR_BUILDER_TITLE':
      return {
        ...state,
        builderSelectedTitle: '',
      };
    case 'SUGGESTION_LOADER':
      return {
        ...state,
        suggestionLoader: action.payload,
      };

    case 'FOLLOWUP_EMAIL':
      return {
        ...state,
        followUpEmail: action.payload,
      };
    case 'SFDT_LOADING':
      return {
        ...state,
        sfdtLoading: action.payload,
      };
    case 'SAVE_EDITOR_JSON':
      return {
        ...state,
        resume: {
          ...state.resume,
          savedEditorJson: action.payload,
        },
      };
    default:
      return state;
  }
};
