import React, { Component } from 'react';
import { connect } from 'react-redux';
import Jobmatchrate from './jobmatchrate';
import ATSfindings from './atsfindings';
import RECRUITERFINDINGS from './recruiterfindings';
import Hardskills from './hardskills';
import { Colxx } from '../../../commonComponents/customBootstrap';
import { Row } from 'reactstrap';
import { scanResultsReload } from '../../../commonRedux/scan/action';

class Scanresult extends Component {
  state = {
    scanResult: {},
    loader: false,
  };
  UNSAFE_componentWillMount() {
    if (this.props.location.state) {
      this.setState({
        scanResult: this.props.location.state,
      });
    }
  }

  render() {
    if (this.state.loader) {
      return <div className="loading" />;
    } else
      return (
        this.state.scanResult.data.totalScore !== undefined && (
          <div>
            <Row>
              <Colxx
                style={{
                  ...(this.props.width < 500 && {
                    paddingLeft: 0,
                    paddingRight: 0,
                  }),
                }}
                lg="12"
                sm="12"
                xs="12"
                xxs="12"
              >
                <div className="mb-5">
                  <Jobmatchrate
                    matchRate={{
                      totalScore: this.state.scanResult.data.totalScore,
                      scores: this.state.scanResult.data.scores,
                    }}
                  />
                </div>
                <Hardskills
                  showInfoCard={false}
                  skills={this.state.scanResult.data.skillsData}
                  oid={this.state.scanResult.oid}
                  ats={this.state.scanResult.data.ats}
                />
                <ATSfindings
                  ats={this.state.scanResult.data.ats}
                  oid={this.state.scanResult.oid}
                />
                <RECRUITERFINDINGS
                  recruiterFindings={
                    this.state.scanResult.data.recruiterFindings
                  }
                  ats={this.state.scanResult.data.ats}
                />
              </Colxx>
            </Row>
          </div>
        )
      );
  }
}

const mapStateToProps = ({ fileScanReducer }) => {
  return {
    scanResult: fileScanReducer.scanResult,
    scanResultStatus: fileScanReducer.scanResultStatus,
    updator: fileScanReducer.updator,
  };
};

export default connect(mapStateToProps, { scanResultsReload })(Scanresult);
