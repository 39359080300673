import React, { memo, useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Feature from './Feature';
import ShowCase from './ShowCase';
import Pricing from './Pricing';
import Testi from './Testi';
import Partners from './partners';
import { withRouter } from 'react-router-dom';
import roundLight from '../../assets/landingPage/round-light.webp';
import { styles } from './style';
import android from '../../assets/landingPage/android.webp';
import ios from '../../assets/landingPage/ios.webp';
import { useWindowSize } from '@react-hook/window-size';
import VimeoPlayer from '../../commonComponents/VimeoPlayer';
import { useSelector } from 'react-redux';

const landingPage = memo((props) => {
  const [width] = useWindowSize();
  const VideoLinks = useSelector((state) => state.fileScanReducer.videoLinks);
  useEffect(() => {
    const { query } = props.location;
    if (query) {
      scroller.scrollTo(query.route.routeName, {
        duration: 500,
        spy: true,
        smooth: true,
        activeClass: 'active',
        offset: query.route.offestV,
      });
    }
    document.body.classList = '';
    window.addEventListener('scroll', scrollNavigation, true);

    return () => {
      window.removeEventListener('scroll', scrollNavigation);
    };
  }, []);

  const [render, setRender] = React.useState(1);

  useEffect(() => {
    if ((Object.keys(VideoLinks) ?? [])?.length) {
      setRender(Math.random() * 10 + 1);
    }
  }, [VideoLinks]);

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    try {
      if (top > 80) {
        document.getElementById('topnav').classList.add('nav-sticky');
      } else {
        document.getElementById('topnav').classList.remove('nav-sticky');
      }
    } catch (e) {}
  };

  return (
    <>
      <Element id="home" name="home">
        <section className="bg-half-170" id="home" style={styles.background}>
          <div className="home-center">
            <div className="home-desc-center">
              <div style={styles.flexColumn}>
                <h1 style={styles.titleFont}>
                  Job Seeker's Platform
                  <sup>
                    <h5 style={{ display: 'inline' }}>&trade;</h5>
                  </sup>
                  {/* Job Seeker's Platform<sup>&trade;</sup> */}
                </h1>
                <div style={styles.detailsMainDiv(width)}>
                  <div style={styles.detailsDiv(width)}>
                    <h2 style={styles.subHeading}>
                      Unlock the door for Job interviews.
                    </h2>
                    <h2 style={styles.subHeading}>
                      Optimize your resume for success!
                    </h2>
                    <p className="para-desc text-muted">
                      Not getting interviews? Companies use Applicant Tracking
                      Systems (ATS) to short-list applicants based on keyword
                      matching. If your resume is not optimized for the job you
                      apply to your chances of landing an interview are pretty
                      low. Your chances of getting an interview depend on your
                      resume making it through Applicant Tracking Systems (ATS),
                      and our AI-based platform makes it easier to get through
                      the process! <br />{' '}
                    </p>
                    <h2 style={styles.subHeading}>
                      Let LinkedIn<sup>&#174;</sup> recruiters easily find you!
                    </h2>
                    <h2 style={styles.subHeading}>
                      Optimized content for your LinkedIn<sup>&#174;</sup>{' '}
                      profile.
                    </h2>

                    <div>
                      <Link to="#">
                        <img
                          alt="ios"
                          src={ios}
                          style={{ width: 130, height: 50 }}
                        />
                      </Link>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          window.open(
                            'https://play.google.com/store/apps/details?id=com.resuma',
                            '_blank',
                          )
                        }
                      >
                        <img
                          alt="play"
                          src={android}
                          style={{ width: 130, height: 50 }}
                        />
                      </span>
                    </div>
                  </div>
                  <div style={styles.frameMain(width)}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <VimeoPlayer
                        key={render}
                        width={width < 450 ? 350 : width < 1200 ? 400 : 500}
                        height={width > 768 ? 280 : 250}
                        video={VideoLinks?.LandingPage}
                        // video={{
                        //   "autoPlay": false,
                        //   "key": "LandingPage",
                        //   "loop": false,
                        //   "muted": false,
                        //   "pageName": "Landing Page",
                        //   "showControls": true,
                        //   "videoID": "686093864"
                        // }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <Row>
                  <div className="home-shape-bottom">
                    <img
                      src={roundLight}
                      alt=""
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </section>
      </Element>
      <Element id="feature" name="feature">
        <Feature />
      </Element>
      <ShowCase />
      <Element id="pricing" name="pricing">
        <Pricing />
      </Element>
      <Element id="partners" name="partners">
        <Partners />
      </Element>
      <Testi />
    </>
  );
});

export default withRouter(landingPage);
