import {
  SUGGESTED_INFORMATION_LOADER,
  JOB_OCCUPATION_FAILED,
  JOB_OCCUPATION_LOADER,
  JOB_OCCUPATION_UPDATE,
  ON_ADD_SELECTED_JOB_OCCUPATION,
  ON_REMOVE_SELECTED_JOB_OCCUPATION,
  ON_ADD_SELECTED_SUBTITLE,
  GET_SUGGESTED_INFORMATION,
  CLEAR_MILITARY_DATA,
  UPDATE_MILITARY_CODE,
  UPDATE_MILITARY_BRANCH,
  ON_REMOVE_SELECTED_JOB,
  SET_JOB_OCCUPATIONS,
  MILITARY_LOADING,
  UPDATE_FILE_NAME,
  GET_SUBTITLES,
  ON_REMOVE_SELECTED_SUBTITLE,
  CLEAR_SELECTED_SUBTITLE,
  UPDATE_MILXWALK_ACTIVE_STEP,
  UPDATE_MILXWALK_DATA,
  MILITARY_DATA_API_STATUS,
  RESET_LOADING,
  MILITARY_TAB_VALUE,
  GET_MILXWALK_DETAIL,
  GET_MILXWALK_DETAIL_LOADING,
  JOB_SUBTITLE_LOADER,
  JOB_OCCUPATION_EMPTY,
} from './actions.constants';

let initialState = {
  jobOccupationLoader: false,
  jobSubtitleLoader: false,
  suggestedInformationLoader: false,
  error: false,
  errorMessage: '',
  suggestedInformation: {},
  jobOccupation: [],
  suggestionDataExist: false,
  selectedJobOccupations: [],
  branch: '',
  code: '',
  subtitles: [],
  selectedSubtitles: [],
  milXWalkLoading: false,
  milXWalkActiveStep: 0,
  isGetApi: false,
  resetLoading: false,
  tabValue: 0,
  milXwalkDetail: {},
  milXwalkDetailLoading: false,
  milXwalkDetailExist: false,
  isEmptyOccupations: false,
};

export const militaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_OCCUPATION_UPDATE:
      return {
        ...state,
        error: false,
        jobOccupation: action.payload,
      };

    case JOB_OCCUPATION_FAILED:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        jobOccupationLoader: false,
      };

    case JOB_OCCUPATION_LOADER:
      return {
        ...state,
        jobOccupationLoader: action.payload,
      };
    case JOB_OCCUPATION_EMPTY:
      return {
        ...state,
        isEmptyOccupations: action.payload,
      };

    case JOB_SUBTITLE_LOADER:
      return {
        ...state,
        jobSubtitleLoader: !state.jobSubtitleLoader,
      };

    case ON_ADD_SELECTED_JOB_OCCUPATION:
      return {
        ...state,
        selectedJobOccupations: [
          ...state.selectedJobOccupations,
          action.payload,
        ],
        suggestedInformation: {},
        suggestionDataExist: false,
      };
    case CLEAR_SELECTED_SUBTITLE:
      return {
        ...state,
        selectedSubtitles: [],
      };
    case ON_REMOVE_SELECTED_SUBTITLE:
      return {
        ...state,
        selectedSubtitles: state.selectedSubtitles.filter(
          (item) => item?.subTitle !== action.payload?.subTitle,
        ),
      };
    case ON_ADD_SELECTED_SUBTITLE:
      localStorage.setItem(
        'selectedSubtitle',
        JSON.stringify([action.payload]),
      );
      return {
        ...state,
        selectedSubtitles: [action.payload],
        suggestionDataExist: false,
        selectedJobOccupations: [],
        suggestedInformation: {},
        jobOccupation: [],
      };

    case ON_REMOVE_SELECTED_JOB_OCCUPATION:
      return {
        ...state,
        selectedJobOccupations: state.selectedJobOccupations.filter(
          (item) => item !== action.payload,
        ),
      };

    case SUGGESTED_INFORMATION_LOADER:
      return {
        ...state,
        suggestedInformationLoader: action.payload,
      };

    case GET_SUGGESTED_INFORMATION:
      return {
        ...state,
        suggestedInformation: action.payload,
        suggestionDataExist: true,
      };

    case CLEAR_MILITARY_DATA:
      return { ...initialState, isGetApi: state.isGetApi };

    case UPDATE_MILITARY_CODE:
      return {
        ...state,
        code: action.payload,
        subtitles: [],
        selectedSubtitles: [],
        isEmptyOccupations: false,
      };

    case UPDATE_MILITARY_BRANCH:
      return {
        ...state,
        branch: action.payload,
        subtitles: [],
        isEmptyOccupations: false,
      };
    case SET_JOB_OCCUPATIONS:
      return {
        ...state,
        selectedJobOccupations: action.payload,
      };
    case ON_REMOVE_SELECTED_JOB:
      return {
        ...state,
        selectedJobOccupations: [],
      };

    case MILITARY_LOADING:
      return {
        ...state,
        milXWalkLoading: action.payload,
      };
    case UPDATE_FILE_NAME:
      return {
        ...state,
        suggestedInformation: {
          ...state.suggestedInformation,
          fileName: action.payload,
        },
      };
    case GET_SUBTITLES:
      return {
        ...state,
        subtitles: action.payload,
      };
    case UPDATE_MILXWALK_ACTIVE_STEP:
      return {
        ...state,
        milXWalkActiveStep: action.payload,
      };
    case UPDATE_MILXWALK_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case MILITARY_DATA_API_STATUS:
      return {
        ...state,
        isGetApi: action.payload,
      };

    case RESET_LOADING:
      return {
        ...state,
        resetLoading: action.payload,
      };
    case MILITARY_TAB_VALUE:
      return {
        ...state,
        tabValue: action.payload,
      };

    case GET_MILXWALK_DETAIL:
      return {
        ...state,
        milXwalkDetail: action.payload,
        milXwalkDetailExist: true,
      };

    case GET_MILXWALK_DETAIL_LOADING:
      return {
        ...state,
        milXwalkDetailLoading: !state.milXwalkDetailLoading,
      };

    default:
      return state;
  }
};
