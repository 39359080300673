import React, { useEffect } from 'react';
import Header from './Header';
import './dashboard.css';
import { Row, Col, Card, CardBody, Modal } from 'reactstrap';
import { Collapse, Tag } from 'antd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateIcon from '@material-ui/icons/Create';
import FormControl from '@material-ui/core/FormControl';
import {
  logoutUser,
  getEmployerInvoices,
  createEmployerOrder,
} from '../../../auth/redux/actions';

import {
  GetUserDetailAction,
  addUserCardAction,
  GetUserCardsAction,
  deleteUserCardAction,
  changeUserCardAction,
  subscribePlan,
  deleteUserAccount,
  verifyDiscountCoupon,
} from '../../../profile/redux/action';
import toaster from 'toastr';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCard from '../../../account/addCard';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { useWindowSize } from '@react-hook/window-size';

import { connect, useDispatch, useSelector } from 'react-redux';
import PastInvoices from './PastInvoices';
import Footer from '../../../layout/appLayout/footer';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { styles } from './style';
import PayNowSuccess from './PayNowSuccess';
import { CircularProgress, TextField } from '@material-ui/core';
import moment from 'moment';
import { validateEmail } from '../../../../utils/emailValidation';
import { colors } from '../../../../commonComponents/colors';
import VimeoPlayer from '../../../../commonComponents/VimeoPlayer';
import UserCards from '../../../account/userCards';
import rocPlan from '../../../../assets/roc-plan-overview.png';
const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

function DashBoard(props) {
  const { accountDetail } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.authUser.user);
  const pastInvoices = useSelector((state) => state.authUser.pastInvoices);
  const userCards = useSelector((state) => state.profileReducer.usersCards);
  const VideoLinks = useSelector((state) => state.fileScanReducer.videoLinks);
  const [value, setValue] = React.useState('sendCodes');
  const [defaultCard, setDefaultCard] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [qty, setQty] = React.useState(1);
  const [modalLarge2, setmodalLarge2] = React.useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = React.useState(false);
  const [successPopup, setSuccessPopup] = React.useState(false);
  const [emailList, setEmailList] = React.useState([]);
  const [userPhone, setUserPhone] = React.useState('');
  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [discountPrice, setDiscountPrice] = React.useState('');
  const [discountId, setDiscountId] = React.useState('');
  const [discountCoupon, setDiscountCoupon] = React.useState('');
  const [discountCouponLoader, setDiscountCouponLoader] = React.useState(false);
  const { Panel } = Collapse;
  const [width] = useWindowSize();
  const [state, setState] = React.useState({
    email: '',
    date: '',
    firstName: '',
    lastName: '',
  });

  const [render, setRender] = React.useState(1);

  useEffect(() => {
    if ((Object.keys(VideoLinks) ?? [])?.length) {
      setRender(Math.random());
    }
  }, [VideoLinks]);

  useEffect(() => {
    if (userCards && userCards.length > 0) {
      setDefaultCard(userCards[0].id);
    }
  }, [userCards]);

  const latestInvoices = pastInvoices.length
    ? pastInvoices[pastInvoices.length - 1]
    : [];

  const stateHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const dateChangeHandler = (e) => {
    let date = e.target.value;
    if (date === moment(new Date()).format('MM-DD-YYYY')) {
      setState({
        ...state,
        date: moment(date).format('MM-DD-YYYY'),
        isToday: true,
      });
    } else {
      setState({
        ...state,
        date: moment(date).format('MM-DD-YYYY'),
        isToday: false,
      });
    }
  };
  const handleCoupon = (e) => {
    e.preventDefault();
    setDiscountCoupon(e.target.value);
    setDiscountPrice('');
  };
  const couponCallBack = (data) => {
    setDiscountCouponLoader(false);
    if (data) {
      setDiscountPrice(data?.balance);
      setDiscountId(data?.data?.id);
    }
  };

  const submitDiscountCoupon = () => {
    setDiscountCouponLoader(true);
    let data = {
      coupon: discountCoupon,
      planType: 150 * qty,
    };
    dispatch(verifyDiscountCoupon(data, couponCallBack));
  };

  const cb = () => {
    setLoading(false);
  };
  const toggleLarge = () => {
    setmodalLarge2(!modalLarge2);
  };
  const toggleInvoiceModal = () => {
    setOpenInvoiceModal(!openInvoiceModal);
  };

  const payNow = () => {
    // if (!userCards?.length) {
    //   toaster.error(`Please add a card for payment!`);
    //   return;
    // }

    if (value === 'sendEmails') {
      if (!emailList.length) {
        toaster.error(`Please add email address!`);
        return;
      }
      if (emailList.length !== parseInt(qty)) {
        toaster.error(`Please provide ${qty} email(s)!`);
        return;
      }
      setLoading(true);

      let data = {
        cardId: defaultCard || userCards.length ? userCards[0].id : '',
        companyName: user?.companyName,
        deliveryOption: value,
        emails: emailList,
        qty: parseInt(qty),
        total: 150 * qty,
        discountCoupon: discountCoupon,
      };
      dispatch(
        createEmployerOrder(data, setSuccessPopup, () => {
          dispatch(getEmployerInvoices());

          cb();
        }),
      );
      setEmailList([]);
    } else {
      setLoading(true);
      let data = {
        cardId: defaultCard,
        companyName: user?.companyName,
        deliveryOption: 'sendCodes',
        emails: null,
        qty: parseInt(qty),
        total: 150 * qty,
        discountCoupon: discountCoupon,
      };
      dispatch(
        createEmployerOrder(data, setSuccessPopup, () => {
          dispatch(getEmployerInvoices());
          cb();
        }),
      );
    }
  };
  const confirmOrder = () => {
    payNow();
  };
  const handleQty = (e) => {
    if (e.target.value.includes('-')) {
      setQty(1);
    } else {
      setQty(e.target.value);
    }
  };
  const onBlurHandleQty = () => {
    if (qty < 1) {
      setQty(1);
    }
  };
  const addEmailHandler = () => {
    if (!state.firstName) {
      toaster.error(`Please enter first name!`);
      return;
    } else if (!state.lastName) {
      toaster.error(`Please enter last name!`);
      return;
    } else if (!state.email) {
      toaster.error(`Please enter a email address!`);
      return;
    } else if (!validateEmail(state.email)) {
      toaster.error(`Please enter a valid email address!`);
      return;
    } else if (!state.date) {
      emailList.push({
        ...state,
        date: moment(new Date()).format('MM-DD-YYYY'),
        isToday: true,
      });
    } else {
      emailList.push(state);
    }
    setQty(emailList?.length);
    setEmailList([...emailList]);
    setState({
      email: '',
      date: '',
      firstName: '',
      lastName: '',
    });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleCardChange = (e) => {
    setDefaultCard(e.target.value);
  };
  const deleteEmailFromList = (index) => {
    emailList.splice(index, 1);
    setEmailList([...emailList]);
  };
  const editEmailList = (index) => {
    setState({
      ...emailList[index],
    });
    deleteEmailFromList(index);
  };
  useEffect(() => {
    if (user) {
      if (!user?.userType && user?.userType !== 'employer') {
        // dispatch(logoutUser(history))

        return;
      }

      dispatch(GetUserCardsAction(cb));
      dispatch(getEmployerInvoices(cb));
    }
  }, [user]);
  const downloadPdf = () => {
    fetch('./assets/roc-plan-overview.pdf').then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'ROC Plan Overview.pdf';
        alink.click();
      });
    });
  };
  return (
    <>
      <Header
        user={user}
        history={history}
        toggleInvoiceModal={toggleInvoiceModal}
      />
      <div className="container">
        <Row style={{ marginTop: 25 }} className="align-items-center">
          <Col xl={6} lg={6}>
            <div className="title-heading mt-4 ">
              <h1
                style={{ lineHeight: 1.1, fontWeight: 'bold' }}
                className=" mb-0 heading"
              >
                Let the separated employees focus on their future, not their
                past!
              </h1>
              <div
                style={{
                  fontWeight: 800,
                  fontSize: 22,
                  marginTop: 5,
                  fontFamily: 'Montserrat',
                }}
              >
                {/* Resume Outplacement Core (ROC) plan */}
                Safeguard Your Business's Reputation!
              </div>
              <p
                className="para-desc text-muted"
                style={{ lineHeight: '25px' }}
              >
                <br /> 1. Resume Optimization for Each job. <br /> 2. Content to
                optimize your LinkedIn profile.
                <br /> 3. AI-based Resume Builder.
                <br /> 4. Receive local job listing daily.
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 28,
                }}
              >
                <img src={rocPlan} width={197} height={260} />
                <div>
                  <div
                    style={{
                      fontWeight: 800,
                      fontSize: 22,
                      marginTop: 5,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    ROC Plan Overview
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: 22,
                      marginTop: 5,
                      fontFamily: 'Montserrat',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={downloadPdf}
                  >
                    Download Now
                  </div>
                </div>
              </div>
              <div className="mt-4 pt-2"></div>
            </div>
          </Col>
          <Col xl={6} lg={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0 ">
            <center>
              <div
                className="container"
                style={{ height: '280px', width: '100%' }}
              >
                <VimeoPlayer
                  key={render}
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 10 }}
                  video={VideoLinks?.Employer}
                />
              </div>
            </center>
          </Col>
        </Row>
      </div>

      <div className="home-center">
        <div
          style={{
            marginTop: 50,
          }}
          // className="home-desc-center"
        >
          <div>
            <Col lg={12} md={12}>
              <center>
                {/* <div
                  style={{
                    fontWeight: 800,
                    fontSize: 25,
                    fontFamily: 'Montserrat',
                  }}
                >
                  Safeguard Your Business's Reputation
                
                </div> */}
                <div
                  style={{
                    marginTop: -7,
                    marginBottom: 15,
                    fontWeight: 800,
                    fontSize: 25,
                    fontFamily: 'Montserrat',
                  }}
                >
                  {/* Give separated employees a chance to look forward! */}
                  Resumas Outplacement Core (ROC) Plan
                </div>
                <Card style={{ width: '85%', marginBottom: 20 }}>
                  <CardBody>
                    <Row>
                      <Col>
                        <Col style={{ backgroundColor: '#4267B2' }}>
                          <h6
                            style={{
                              color: 'white',
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            COMPANY INFO
                          </h6>
                        </Col>
                        <Row>
                          <Col>
                            <p>Company</p>
                            {/* <p>Address</p> */}
                            <p>Email</p>
                            <p>Phone</p>
                          </Col>
                          <Col>
                            <p>
                              {user?.companyName
                                ? user?.companyName?.length > 20
                                  ? user?.companyName.slice(0, 20) + '...'
                                  : user?.companyName
                                : 'N/A'}
                            </p>
                            {/* <p>10 Downing Street</p> */}
                            <p>{user?.email}</p>
                            <p>{userPhone === '' ? user?.phone : userPhone}</p>
                          </Col>
                        </Row>

                        <Col style={{ marginBottom: 10 }}>
                          {width > 500 && (
                            <Collapse style={styles.mt_30}>
                              <Panel
                                header={`Purchase Digital Delivery : 
                            Invoice# ${latestInvoices?._id || ''}`}
                                key="1"
                              >
                                <TableContainer component={Paper}>
                                  <Table
                                    className={classes.table}
                                    aria-label="simple table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="font-weight-bold">
                                          #
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="font-weight-bold"
                                        >
                                          Code
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="font-weight-bold"
                                        >
                                          Link (Share this link)
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="font-weight-bold"
                                        >
                                          Status
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {latestInvoices?.coupons?.map(
                                        (row, index) => (
                                          <TableRow key={row.name}>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {index + 1}
                                            </TableCell>
                                            <TableCell align="center">
                                              <Tag color={'rgb(66, 103, 178)'}>
                                                {' '}
                                                {row.coupon}
                                              </Tag>
                                            </TableCell>
                                            <TableCell align="center">
                                              {`${window.location.hostname}/signup?coupon=${row.coupon}`}
                                            </TableCell>
                                            <TableCell align="center">
                                              {row.isUsed ? (
                                                <Tag color={'red'}>Used</Tag>
                                              ) : (
                                                <Tag color={'green'}>
                                                  Not Used
                                                </Tag>
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        ),
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Panel>
                            </Collapse>
                          )}

                          <br />
                          <span
                            onClick={() => history.push('/pastInvoices')}
                            style={{
                              color: 'rgb(66, 103, 178)',
                              cursor: 'pointer',
                            }}
                          >
                            View All Invoices
                          </span>
                          <br />
                        </Col>
                      </Col>
                      <Col>
                        <Col style={{ backgroundColor: '#4267B2' }}>
                          <h6
                            style={{
                              color: 'white',
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            PURCHASE
                          </h6>
                        </Col>

                        <Row>
                          <Col>
                            <p
                              style={{
                                textAlign: 'start',
                                paddingLeft: '5px',
                                fontSize: 16,
                              }}
                            >
                              ROC core plan 6-months
                            </p>
                          </Col>
                          <Col>
                            <p style={{ marginBottom: 60, fontSize: 16 }}>
                              $150
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p
                              style={{ textAlign: 'start', paddingLeft: '5px' }}
                            >
                              Number of Employees (QTY)
                            </p>
                          </Col>
                          <Col>
                            <input
                              autoFocus
                              style={{
                                width: 50,
                                textAlign: 'center',
                                border: '1px solid #4267B2',
                                padding: '5px',
                              }}
                              value={qty}
                              onChange={handleQty}
                              onBlur={onBlurHandleQty}
                              type="number"
                              // className="pass_change_input"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p
                              style={{
                                textAlign: 'start',
                                paddingLeft: '5px',
                                marginTop: '10px',
                              }}
                            >
                              {' '}
                              Total
                            </p>
                          </Col>
                          <Col>
                            <p
                              style={{ fontWeight: 'bold', marginTop: '10px' }}
                            >
                              ${150 * qty}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Col style={{ backgroundColor: '#4267B2' }}>
                          <h6
                            style={{
                              color: 'white',
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            PAYMENT METHOD
                          </h6>
                        </Col>
                        <UserCards
                          name={`${accountDetail?.firstName || ''} ${
                            accountDetail?.lastName || ''
                          }`}
                          dashboard="dashboard"
                          changeUserCardAction={props.changeUserCardAction}
                          default_source={
                            accountDetail?.payDetails?.default_source
                          }
                          deleteUserCardAction={props.deleteUserCardAction}
                          usersCards={props.usersCards}
                          GetUserCardsAction={props.GetUserCardsAction}
                          width={width}
                        />
                        {/* <Button
                          onClick={toggleLarge}
                          size="medium"
                          variant="outlined"
                          // color="#4267B2"
                          style={{
                            color: '#4267B2',
                            borderColor: '#4267B2',
                          }}
                        >
                          {'5'}
                        </Button> */}
                        <Row>
                          <Col>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={value}
                                onChange={handleCardChange}
                              >
                                {userCards?.length
                                  ? userCards?.map((card, index) => {
                                      return (
                                        <FormControlLabel
                                          key={index}
                                          checked={card?.id === defaultCard}
                                          value={card?.id}
                                          control={<Radio />}
                                          label={`${card?.brand} - ${card?.last4}`}
                                        />
                                      );
                                    })
                                  : ''}
                              </RadioGroup>
                            </FormControl>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Col style={{ backgroundColor: '#4267B2' }}>
                          <h6
                            style={{
                              color: 'white',
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            DELIVERY
                          </h6>
                        </Col>
                        <Row>
                          <Col>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={value}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="sendCodes"
                                  control={<Radio />}
                                  label=" Email codes so we can email employees."
                                />
                                <FormControlLabel
                                  value="sendEmails"
                                  control={<Radio />}
                                  label="Email subscription licenses and we will email to employees."
                                />
                              </RadioGroup>
                            </FormControl>
                            {value === 'sendEmails' && (
                              <>
                                <div style={styles.listContainer(width)}>
                                  <TextField
                                    name="firstName"
                                    type="text"
                                    variant="outlined"
                                    placeholder="First Name"
                                    value={state.firstName}
                                    onChange={stateHandler}
                                    style={styles.inputFields(width)}
                                  />
                                  <TextField
                                    name="lastName"
                                    type="text"
                                    variant="outlined"
                                    placeholder="Last Name"
                                    value={state.lastName}
                                    onChange={stateHandler}
                                    style={styles.inputFields(width)}
                                  />
                                  <div
                                    style={{
                                      width: '63px',
                                      margin: '5px',
                                      display: width < 1250 ? 'none' : 'block',
                                    }}
                                  >
                                    &nbsp;
                                  </div>
                                </div>
                                <div style={styles.listContainer(width)}>
                                  <TextField
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    placeholder="Email"
                                    value={state.email}
                                    onChange={stateHandler}
                                    style={styles.inputFields(width)}
                                  />
                                  <TextField
                                    name="date"
                                    type="date"
                                    variant="outlined"
                                    value={moment(
                                      state?.date,
                                      'MM-DD-YYYY',
                                    ).format('YYYY-MM-DD')}
                                    onChange={dateChangeHandler}
                                    style={styles.inputFields(width)}
                                  />
                                  <Button
                                    onClick={addEmailHandler}
                                    size="medium"
                                    variant="outlined"
                                    style={styles.addButton}
                                  >
                                    Add
                                  </Button>
                                </div>
                                <div style={styles.emailList}>
                                  {emailList?.map((item, index) => {
                                    return (
                                      <div key={index} style={styles.listMain}>
                                        <div style={styles.emailDiv}>
                                          <span style={styles.email}>
                                            <span style={styles.listNumber}>
                                              {index + 1}.
                                            </span>{' '}
                                            {item.email}
                                          </span>
                                          <span style={styles.date}>
                                            {item.date}
                                          </span>
                                        </div>
                                        <span>
                                          <CreateIcon
                                            onClick={() => editEmailList(index)}
                                            style={{
                                              fontSize: 20,
                                              marginLeft: 10,
                                              cursor: 'pointer',
                                            }}
                                          />
                                          <RemoveCircleOutlineIcon
                                            onClick={() =>
                                              deleteEmailFromList(index)
                                            }
                                            style={{
                                              fontSize: 20,
                                              marginLeft: 10,
                                              cursor: 'pointer',
                                            }}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      marginRight: 10,

                      textAlign: 'right',
                    }}
                  >
                    <p>**We do not store employee information.</p>
                  </div>
                </Card>
                <Button
                  variant="outlined"
                  size="large"
                  className="default mb-2 review-butn analyz-button"
                  style={{
                    background: colors.blue,
                    border: 'none',
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    toggleLarge();
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{
                        color: '#FFFFFF',
                      }}
                    />
                  ) : (
                    'Pay Now'
                  )}
                </Button>
              </center>
            </Col>
          </div>
        </div>
        <Modal isOpen={modalLarge2} size="lg">
          <AddCard
            isEmployer={true}
            discountPrice={discountPrice}
            disableSubscriptionPlans={true}
            addPaymentMethod={false}
            addUserCardAction={addUserCardAction}
            handleCoupon={handleCoupon}
            qty={qty}
            cardLength={userCards?.length}
            submitDiscountCoupon={submitDiscountCoupon}
            userPlan={qty * 150}
            userDetails={{
              name: `${user?.firstName || ''} ${user?.lastName || ''}`,
              email: user?.email || '',
              phone: userPhone === '' ? user?.phone : userPhone || '',
            }}
            toggle={toggleLarge}
            phoneUpdate={(phone) => {
              setUserPhone(phone);
            }}
            handlePlanPopup={() => {
              if (qty * 150 !== 0) {
                payNow();
              }
            }}
          />
        </Modal>
        <Modal isOpen={openInvoiceModal} size="lg">
          <span onClick={() => setOpenInvoiceModal(false)}>
            <CancelIcon style={styles.closeBtn} />
          </span>
          <div style={{ width: '100%' }}>
            <PastInvoices />
          </div>
        </Modal>
        <Modal isOpen={successPopup} size="lg">
          <span onClick={() => setSuccessPopup(false)}>
            <CancelIcon style={styles.closeBtn} />
          </span>
          <PayNowSuccess isEmployee={value === 'sendCodes' ? false : true} />
        </Modal>
        <Dialog
          open={confirmationModal}
          onClose={() => setConfirmationModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Order Confirmation'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Subscription(s) : <strong>{qty}</strong>
              <br />
              Amount :{' '}
              <strong>
                {' '}
                {discountPrice !== '' ? discountPrice : 150 * qty}
              </strong>
              <br />
              <input
                onChange={handleCoupon}
                type={'text'}
                value={discountCoupon}
                placeholder="Enter Discount Code"
                id="claimCode"
                variant="outlined"
                style={{
                  marginTop: '10px',
                  fontSize: '14px',
                  height: '30px',
                  border: '1px solid #4267B2',
                  borderRadius: '5px',
                  width: width < 600 ? '200px' : '330px',
                  marginLeft: width < 1180 ? '20px' : '0px',
                }}
              />
              <button
                onClick={submitDiscountCoupon}
                style={{
                  marginLeft: '20px',
                  background: '#4267b2',
                  height: '30px',
                  borderRadius: '5px',
                  width: width < 600 ? '200px' : '70px',
                  color: '#fff',
                  marginBottom: '10px',
                }}
                // disabled={discountPrice !== ''}
              >
                {discountCouponLoader ? (
                  <CircularProgress
                    style={{
                      color: '#fff',
                      height: '15px',
                      width: '15px',
                      marginTop: 4,
                    }}
                  />
                ) : (
                  'Apply'
                )}
              </button>
              <br />
              <br />
              <strong>I confirm the order and ready to pay.</strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmationModal(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                confirmOrder();
                setConfirmationModal(false);
              }}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = ({ profileReducer }) => {
  return {
    accountDetail: profileReducer.accountDetail,
    detailStatus: profileReducer.detailStatus,
    usersCards: profileReducer.usersCards,
  };
};
export default connect(mapStateToProps, {
  GetUserDetailAction,
  addUserCardAction,
  GetUserCardsAction,
  deleteUserCardAction,
  changeUserCardAction,
})(DashBoard);
