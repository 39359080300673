export const styles = {
  flex: {
    display: "flex",
  },
  flexColumn: {
    flexDirection: "column",
  },
  wrap: {
    flexWrap: "wrap",
  },
  inline: {
    display: "inline",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  justifyContentEnd: {
    justifyContent: "flex-end",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  spaceAround: {
    justifyContent: "space-around",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  left50: { left: "50%" },
  width_100_percent: {
    width: "100%",
  },
  width_80_percent: {
    width: "80%",
  },
  fontWeight_400: {
    fontWeight: 400,
  },
  fontWeight_500: {
    fontWeight: 500,
  },
  fontWeight_bold: {
    fontWeight: "bold",
  },
  fontSize_75_rem: {
    fontSize: ".75rem",
  },
  mt_5: {
    marginTop: 5,
  },
  mt_10: {
    marginTop: 10,
  },
  mt_20: {
    marginTop: 20,
  },
  mt_30: { marginTop: 30 },
  mb_5: {
    marginBottom: 5,
  },
  mb_0: {
    marginBottom: 0,
  },
  mb_10: {
    marginBottom: 10,
  },
  mb_20: {
    marginBottom: 20,
  },
  mb_40: {
    marginBottom: 40,
  },
  mb_90: {
    marginBottom: 90,
  },
  textAlignCenter: {
    textAlign: "center",
  },
  h1: {
    fontWeight: 800,
    fontSize: "22px",
    fontFamily: "Montserrat",
    color: "#4A4A4A",
  },
  h6: {
    fontSize: "1.2rem",
    display: "inline-block",
    fontWeight: "bold",
    fontFamily: "inherit",
    letterSpacing: "3px",
    color: "#4A4A4A",
    textAlign: "left",
  },
  borderRadius_75: {
    borderRadius: ".75rem",
    
  },
  borderRadius_751: {
    borderRadius: ".75rem",
  },
  historyItemContainer: {
    padding: "10px",
    background: "white",
    marginTop: "40px",
  },
  actions: {
    position: "absolute",
    right: "20px",
    top: "-40px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "5px",
  },
  btnContainer: {
    verticalAlign: "center",
    marginTop: "15px",
  },
  row: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  scrollBar: {
    width: "100%",
    height: "460px",
    padding: "10px",
    resize: "none",
    overflow: "auto",
  },
  scrollBar1: {
    width: "100%",
    height: "460px",
    padding: "10px",
  },
  noteModel: {
    position: "relative",
    // bottom: 5,s
  },
  actionIcon: {
    cursor: "pointer",
    fontSize: "1.1rem",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  customTextHeight:{
    height:50
  }
};
